import { Map } from 'immutable'
import { createSelector } from 'reselect'

const settlementSummarySelector = (state) =>
  state.settlement.summary ? state.settlement.summary : Map()

/**
 * The payout currently being processed - this is the next settlement
 */
export const processingPayout = createSelector(settlementSummarySelector, (summary) =>
  summary.get('processingPayout')
)

/**
 * The amount of the next settlement
 */
export const processingPayoutAmount = createSelector(
  processingPayout,
  (payout) => (payout && payout.get('displayAmount')) || 0
)

export const processingPayoutRawAmount = createSelector(
  processingPayout,
  (payout) => (payout && payout.get('amount')) || 0
)

/** The expected date when the next payout should be in the merchant's bank account */
export const processingPayoutExpectedDate = createSelector(
  processingPayout,
  (payout) => (payout && payout.get('expectedReceiveDate')) || undefined
)

/**
 * Your previous settlement
 */
export const lastPayout = createSelector(settlementSummarySelector, (summary) =>
  summary.get('lastPayout')
)

/**
 * Amount of the previous settlement
 */
export const lastPayoutAmount = createSelector(
  lastPayout,
  (payout) => (payout && payout.get('displayAmount')) || 0
)

/**
 * Date when last settlement was processed
 */
export const lastPayoutDate = createSelector(lastPayout, (payout) => payout && payout.get('date'))

export const balance = createSelector(
  settlementSummarySelector,
  (summary) => (summary && summary.get('balance')) || Map()
)

export const balanceRawAmount = createSelector(balance, (b) => (b && b.get('amount')) || 0)

export const balanceAmount = createSelector(balance, (b) => (b && b.get('displayAmount')) || 0)
