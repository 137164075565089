import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import * as forms from 'libs/forms'
import { callInvoicesJSONAPI } from 'libs/api'
import { track } from 'libs/analytics'
import { submitResult, showSuccess, showError } from 'redux/modules/forms'

import { dueInSelectOptions } from '../constants'

import { FormNames, FormFieldNames } from './constants'
import { useInitialiseFormValues } from './Create.hooks'
import { normalizeFormData } from './utils'
import renderFormFields from './renderFormFields'

function CreateInvoicesSettings({ dispatch, formInitialValues, onCreated }) {
  useInitialiseFormValues(formInitialValues)

  return (
    <forms.Form
      name={FormNames.Create}
      noWrap
      onSubmit={(data) => {
        track('invoices_set_up_save_button_clicked', {
          name: 'invoices_set_up_save_button_clicked',
        })

        callInvoicesJSONAPI('business', 'POST', normalizeFormData(data))
          .then((response) => {
            dispatch(submitResult(FormNames.Create, response.data))
            dispatch(showSuccess(FormNames.Create, { message: 'Invoice settings saved!' }))
            onCreated?.()
          })
          .catch(() => {
            dispatch(submitResult(FormNames.Create, {}))
            dispatch(showError(FormNames.Create, 'Failed to save invoice settings'))
          })
      }}
      scrollToTop={false}
      showLoader
      loaderClass='spinner-container'
      showSuccess
      showError
    >
      {renderFormFields(true)}
    </forms.Form>
  )
}

CreateInvoicesSettings.propTypes = {
  dispatch: PropTypes.func.isRequired,
  formInitialValues: PropTypes.object.isRequired,
  onCreated: PropTypes.func,
}

export default connect(({ session }) => ({
  formInitialValues: {
    [FormFieldNames.BusinessName]: session.getIn(['business', 'displayName']),
    [FormFieldNames.Email]: session.getIn(['user', 'email']),
    [FormFieldNames.Phone]: session.getIn(['business', 'info', 'telephoneNumber']),
    [FormFieldNames.VAT]: session.getIn(['business', 'info', 'vatNumber']),
    [FormFieldNames.InitialInvoiceNumber]: '1',
    [FormFieldNames.DueIn]: dueInSelectOptions[1].value,
  },
}))(CreateInvoicesSettings)
