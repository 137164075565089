/* eslint-disable array-callback-return */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import { List, ListItem } from 'libs/list'
import { formatCurrency } from 'libs/utils'

import { FormName } from './constants'

export const invoiceTotals = (invoice) => {
  const totals = {
    subTotal: 0,
    discountTotal: 0,
    vatTotal: 0,
    total: 0,
  }

  invoice.billEntries?.map((entry) => {
    const subTotal = entry.price * entry.quantity
    const discountTotal = entry.discountPercentage
      ? subTotal * (entry.discountPercentage / 100)
      : entry.discount
    const total = subTotal - discountTotal
    const vatTotal = entry.salesTaxes
      ? entry.salesTaxes.reduce((r, tax) => {
          return r + total * tax.taxPercentageOfTotal
        }, 0)
      : 0

    totals.subTotal += subTotal
    totals.discountTotal += discountTotal
    totals.vatTotal += vatTotal
    totals.total += total
  })

  return totals
}

function Totals({ formData }) {
  const [invoiceSubtotalStr, invoiceDiscountStr, invoiceVATStr, invoiceTotalStr] = useMemo(() => {
    if (formData) {
      const { subTotal, discountTotal, vatTotal, total } = invoiceTotals(formData.toJS())

      return [
        formatCurrency(subTotal, 'ZAR'),
        discountTotal ? `- ${formatCurrency(discountTotal, 'ZAR')}` : '',
        formatCurrency(vatTotal, 'ZAR'),
        formatCurrency(total, 'ZAR'),
      ]
    }

    return ['', '', '', '']
  }, [formData])

  return (
    <List>
      <ListItem
        title='Subtotal (incl. VAT):'
        description={invoiceSubtotalStr}
        style={{ border: 'none', padding: '8px 0' }}
      />
      <ListItem
        title='Discount:'
        description={invoiceDiscountStr}
        style={{ border: 'none', padding: '8px 0' }}
      />
      <ListItem
        title='VAT (15%):'
        description={invoiceVATStr}
        style={{ border: 'none', padding: '8px 0' }}
      />
      <ListItem
        title='Total:'
        description={invoiceTotalStr}
        style={{ border: 'none', padding: '8px 0' }}
      />
    </List>
  )
}

Totals.propTypes = {
  formData: PropTypes.object.isRequired,
}

export default connect(({ forms }) => ({
  formData: forms.getIn([FormName, 'data'], Map({})),
}))(Totals)
