/* eslint-disable */

/* eslint-disable no-continue,no-restricted-syntax,no-prototype-builtins */
export default function keyMirror(obj, prefix = '') {
  if (!(obj instanceof Object && !Array.isArray(obj))) {
    throw new Error('keyMirror(...): Argument must be an object.')
  }

  const ret = {}
  for (const key in obj) {
    if (!obj.hasOwnProperty(key)) {
      continue
    }

    ret[key] = `${prefix}${key}`
  }

  return ret
}
