import React, { Component } from 'react'
import PropTypes from 'prop-types'

import classes from './cards.module.scss'

export default class FormCard extends Component {
  getHeader() {
    if (this.props.header) {
      return (
        <span>
          <div className={classes.formCardHeader}>{this.props.header}</div>
          <div className={classes.headerText}>{this.props.headerText}</div>
        </span>
      )
    }
    return undefined
  }

  render() {
    return (
      <div
        className={`${classes.formCard}${this.props.noPadding ? ` ${classes.noPadding}` : ''}`}
        style={this.props.style}
      >
        {this.getHeader()}
        {this.props.children}
      </div>
    )
  }
}

FormCard.propTypes = {
  header: PropTypes.string,
  headerText: PropTypes.string,
  noPadding: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.object,
}
