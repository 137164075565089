/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Carousel } from 'react-responsive-carousel'

import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import BlockHeader from 'components/headers/BlockHeader'
import { Table } from 'libs/tables'
import FlatButton from 'components/buttons/FlatButton'
import ConfirmPopup from 'components/popups/ConfirmPopup'
import classes from 'containers/business/cardReader.module.scss'
import { callJSONApi } from 'libs/api'
import { showMessage } from 'redux/modules/notifications'
import { submitForm } from 'redux/modules/forms'
import ROLES, { userHasRequiredRoles } from 'libs/roles'
import { trackEvent } from 'libs/utils'
import SplitBlock from 'components/blocks/SplitBlock'
import * as forms from 'libs/forms'
import FormCard from 'components/cards/FormCard'
import { hasFeature } from 'libs/features'
import { refreshTable } from 'redux/modules/tables'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import goSerial from 'assets/images/card-machines/go_serial_instruction@2x.png'
import neoSerial from 'assets/images/card-machines/neo_serial_instruction@2x.png'
import proSerial from 'assets/images/card-machines/pro_serial_instruction@2x.png'

import success from 'assets/images/card-machines/happy@2x.png'

const TABLE_NAME = 'readers'
const FORM_NAME = 'cardMachine'
const howToConnectLink =
  'https://support.yoco.help/s/article/How-to-connect-pair-your-Bluetooth-card-machine-with-the-Yoco-App'

const images = [goSerial, neoSerial, proSerial]

class CardReadersPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showRemoveCardMachinePopup: false,
      serialNumberToRemove: undefined,
      showingSplit: false,
      cardReaderSuccess: false,
      cardReaderFail: false,
      cardReaderDoesNotExist: true,
    }
  }

  onSerialChange(value) {
    if (!value) {
      this.setState({
        cardReaderDoesNotExist: false,
      })
    }
  }

  onConfirmRemoveCardMachine() {
    trackEvent('remove_initiated', 'card_readers')
    callJSONApi(
      `/readers/${this.state.serialNumberToRemove}/deallocate/`,
      'POST',
      {},
      (response) => {
        if (response.status === 200) {
          this.props.dispatch(showMessage('Card Machine Successfully Removed'))
          this.hideRemoveCardMachinePopup()
          window.location.reload()
        } else {
          this.props.dispatch(showMessage('Oops something has gone wrong - try again', 'warning'))
          this.hideRemoveCardMachinePopup()
        }
      },
      (error) => {
        this.props.dispatch(showMessage(`Error removing card machine: ${error}`, 'danger'))
        this.hideRemoveCardMachinePopup()
      }
    )
  }

  onCancelRemoveCardMachine() {
    trackEvent('remove_cancelled', 'card_readers')
    this.hideRemoveCardMachinePopup()
  }

  getTableHeader() {
    return (
      <tr className='visible-header'>
        <th>Product name</th>
        <th>Serial Number</th>
        <th />
      </tr>
    )
  }

  getTableRow(index, rowData) {
    let trClass = ''
    if (!(index % 2)) {
      trClass = ` row-background`
    }

    return (
      <tr className={trClass} key={`row-${index}`}>
        <td>{rowData.get('productName')}</td>
        <td>{rowData.get('serialNumber')}</td>
        <td>{this.renderRemoveButton(rowData)}</td>
      </tr>
    )
  }

  savePressed() {
    if (this.state.cardReaderSuccess) {
      this.splitViewClosed()
    } else if (this.state.showingSplit || this.state.cardReaderFail) {
      this.props.dispatch(submitForm(FORM_NAME))
    }
  }

  addCardMachine() {
    this.setState({
      showingSplit: true,
      cardReaderSuccess: false,
      cardReaderFail: false,
    })
  }

  splitViewClosed() {
    this.setState({
      showingSplit: false,
      cardReaderSuccess: false,
      cardReaderFail: false,
      cardReaderDoesNotExist: false,
    })
    this.props.dispatch(refreshTable(TABLE_NAME))
  }

  showSuccess() {
    this.setState({
      cardReaderSuccess: true,
    })
  }

  showFailure(response) {
    if (
      response.status === 400 &&
      response.message &&
      response.message.includes('Reader not found')
    ) {
      this.setState({
        cardReaderDoesNotExist: true,
      })
    } else {
      this.setState({
        cardReaderFail: true,
      })
    }
  }

  addCardMachineButton() {
    return (
      <FlatButton
        className='blueBackground'
        label='Add Card Machine'
        onClick={() => this.addCardMachine()}
      />
    )
  }

  showRemoveCardMachinePopup(serialNumber) {
    this.setState({
      showRemoveCardMachinePopup: true,
      serialNumberToRemove: serialNumber,
    })
  }

  hideRemoveCardMachinePopup() {
    this.setState({
      showRemoveCardMachinePopup: false,
    })
  }

  renderSplitContent() {
    if (this.state.cardReaderSuccess) {
      trackEvent('portal_card_machine_click_add_button_successful', 'card_readers')
      return this.renderAddCardMachineSuccess()
    }
    return this.renderAddCardMachineForm()
  }

  renderAllocationError() {
    if (this.state.cardReaderFail) {
      trackEvent('portal_card_machine_click_add_button_failure', 'card_readers')
      return (
        <div className={classes.formError}>
          {`We couldn't connect this card machine to your business`}
        </div>
      )
    }
    if (this.state.cardReaderDoesNotExist) {
      return (
        <div className={classes.formError}>
          We unfortunately do not recognize the serial number.
        </div>
      )
    }
    return <div />
  }

  renderAddCardMachineForm() {
    return (
      <div>
        <forms.Form
          action='/readers/addCardMachine/'
          name={FORM_NAME}
          showHeader={false}
          onSuccess={(response) => this.showSuccess(response)}
          onFail={(response) => this.showFailure(response)}
        >
          <FormCard>
            <div className={classes.formCard}>
              <CardMachineCarousel images={images} />
              {this.renderDescription(
                'Link a Card Machine',
                'Connect a card machine to your business by adding the',
                'serial number at the back of your new card machine.'
              )}
              {this.renderAllocationError()}
              <forms.TextField
                name='serialNumber'
                label='Serial Number'
                isFullWidth
                placeholder='000000000'
                validators={[new forms.RequiredValidator('You must provide a serial number')]}
                onChange={(event) => this.onSerialChange(event.target.value)}
              />
            </div>
          </FormCard>
        </forms.Form>
      </div>
    )
  }

  renderAddCardMachineSuccess() {
    return (
      <div>
        <FormCard>
          <div className={classes.formCard}>
            <img src={success} alt='success' />
            {this.renderDescription(
              'Nice!',
              'Do you want to transact with a new card machine? Great!',
              <a target='_blank' rel='noopener noreferrer' href={howToConnectLink}>
                {' '}
                You can find guidance on pairing here.
              </a>
            )}
          </div>
        </FormCard>
      </div>
    )
  }

  renderDescription(heading, descriptionLine1, descriptionLine2) {
    return (
      <div>
        <div className={classes.description}>
          <h3>{heading}</h3>
          <hr className={classes.divider} />
          <p>
            {descriptionLine1}
            <br />
            {descriptionLine2}
          </p>
        </div>
      </div>
    )
  }

  renderConfirmRemoveCardMachinePopup() {
    return (
      <ConfirmPopup
        showing={this.state.showRemoveCardMachinePopup}
        title='Remove Card Machine'
        label='Remove'
        iconType={classes.deleteIconType}
        actionType='destructive'
        subtitle={
          <div>
            Remove your card machine to transfer ownership to someone else. If your card machine is
            broken, stolen, lost or you wish to remove it for another reason
            {/* eslint-disable-next-line no-script-url */}
            <a href='javascript:$zopim.livechat.window.show();'> chat to support</a>
          </div>
        }
        onConfirm={() => this.onConfirmRemoveCardMachine()}
        onCancel={() => this.onCancelRemoveCardMachine()}
      />
    )
  }

  renderRemoveButton(rowData) {
    const requiresAllRoles = [ROLES.MANAGE_BUSINESS]
    const requiresOneRole = [ROLES.MANAGE_BUSINESS]
    const isAllowedToRemoveCardMachine = userHasRequiredRoles(this.props.user, {
      requiresAllRoles,
      requiresOneRole,
    })
    return isAllowedToRemoveCardMachine ? (
      <FlatButton
        label='Remove'
        type='button'
        className='redBackground'
        onClick={() => this.showRemoveCardMachinePopup(rowData.get('serialNumber'))}
      />
    ) : null
  }

  render() {
    return (
      <SplitBlock
        showingSplit={this.state.showingSplit}
        formName={FORM_NAME}
        onDismiss={() => this.splitViewClosed()}
        header='Add a Card Machine'
        saveLabel='Done'
        showCancel={!(this.state.cardReaderFail || this.state.cardReaderSuccess)}
        showSave
        renderSplitContent={() => this.renderSplitContent()}
        savePressed={() => this.savePressed()}
      >
        <MaxWidthBlock>
          <BlockHeader
            title='Card Machines'
            actions={this.props.hasPortalAllocationFeature && this.addCardMachineButton()}
          />
          <Table
            name={TABLE_NAME}
            waitForFilterLoad={false}
            api='/readers/'
            useOffset
            getHeader={() => this.getTableHeader()}
            getRow={(rowData, index) => this.getTableRow(index, rowData)}
          />
          {this.renderConfirmRemoveCardMachinePopup()}
        </MaxWidthBlock>
      </SplitBlock>
    )
  }
}

function CardMachineCarousel({ images }) {
  return (
    <Carousel
      showThumbs={false}
      showStatus={false}
      renderArrowPrev={(onClickHandler, hasPrev, label) => (
        <LeftCarouselButton onClickHandler={onClickHandler} hasPrev={hasPrev} label={label} />
      )}
      renderArrowNext={(onClickHandler, hasNext, label) => (
        <RightCarouselButton onClickHandler={onClickHandler} hasNext={hasNext} label={label} />
      )}
    >
      {/* eslint-disable-next-line */}
      {images.map((image) => (
        <CarouselImage image={image} key={image} />
      ))}
    </Carousel>
  )
}

CardMachineCarousel.propTypes = {
  images: PropTypes.array,
}

function CarouselImage({ image }) {
  return (
    <div>
      <img className={classes.carouselImage} src={image} alt='carousel' />
    </div>
  )
}

CarouselImage.propTypes = {
  image: PropTypes.string,
}

function LeftCarouselButton({ onClickHandler, hasPrev, label }) {
  return (
    hasPrev && (
      <button
        className={classes.arrowButtonLeft}
        type='button'
        onClick={onClickHandler}
        title={label}
      >
        <i className={classes.arrowLeft} />
      </button>
    )
  )
}

LeftCarouselButton.propTypes = {
  onClickHandler: PropTypes.func,
  hasPrev: PropTypes.bool,
  label: PropTypes.string,
}

function RightCarouselButton({ onClickHandler, hasNext, label }) {
  return (
    hasNext && (
      <button
        className={classes.arrowButtonRight}
        type='button'
        onClick={onClickHandler}
        title={label}
      >
        <i className={classes.arrowRight} />
      </button>
    )
  )
}

RightCarouselButton.propTypes = {
  onClickHandler: PropTypes.func,
  hasNext: PropTypes.bool,
  label: PropTypes.string,
}

CardReadersPage.propTypes = {
  user: PropTypes.object,
  hasPortalAllocationFeature: PropTypes.bool,
  dispatch: PropTypes.func,
}

const mapStateToProps = (state) => ({
  user: state.session.get('user'),
  hasPortalAllocationFeature: hasFeature(state, 'allocateAdditionalCardMachineThroughPortal'),
  dispatch: state.dispatch,
})

export default connect(mapStateToProps)(CardReadersPage)
