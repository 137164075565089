import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@yoco/design-system-icons/dist/react'

import { ListItem } from 'libs/list'
import { formatCurrency } from 'libs/utils'
import DropDownButton from 'components/buttons/DropDownButton'

import { calculateInvoiceEntryTotals } from '../Invoices'

import QuantityInput from './QuantityInput'
import { buildLineItemDiscountLine } from './utils'
import classes from './LineItem.module.scss'

const deleteButtonProps = {
  style: { color: 'red' },
}

function LineItem({ item, onQuantityChange, onDiscountPress, onRemovePress, testID }) {
  const { subTotal } = calculateInvoiceEntryTotals(item)

  return (
    <ListItem
      key={item.get('clientBillEntryIdentifier')}
      accessoryLeft={
        <QuantityInput min={1} value={item.get('quantity')} onChange={onQuantityChange} />
      }
      title={item.getIn(['tile', 'name'])}
      subTitle={item.get('variantChoiceValues', []).join(', ')}
      description={formatCurrency(subTotal, 'ZAR')}
      subDescription={buildLineItemDiscountLine(item)}
      accessoryRight={
        <DropDownButton
          label={<Icon name='elipses' size={24} />}
          buttonClass={classes.dropDownButton}
          actions={[
            {
              label: 'Discount',
              onClick: onDiscountPress,
            },
            {
              label: 'Remove',
              onClick: onRemovePress,
              buttonProps: deleteButtonProps,
            },
          ]}
        />
      }
      testID={testID}
    />
  )
}

LineItem.propTypes = {
  item: PropTypes.object.isRequired,
  onQuantityChange: PropTypes.func,
  onDiscountPress: PropTypes.func,
  onRemovePress: PropTypes.func,
  testID: PropTypes.string,
}

export default LineItem
