/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@yoco/design-system-icons/dist/react'

import layout from 'ui/layout/layout.module.scss'
import { semanticColors } from 'ui/colors'
import Spacer from 'ui/layout/Spacer'

import classes from './notifications.module.scss'

function UserAlert({
  userAlert: { alertType, htmlMessage },
  style,
  dismissUserAlert,
  onLinkClick,
}) {
  return (
    <div className={classes.userAlertContainer} style={style}>
      <div className={`${classes.userAlertHeader} user-alert-header-${alertType}`}>
        <div className={`${classes.userAlertIcon} type-${alertType}`}>
          <Icon name={`alert-${alertType}`} size={24} color={semanticColors.danger} />
        </div>
        <Spacer size='xs' isHorizontal />
        <div className={layout.flexGrow}>Please Note</div>
        <Spacer size='xs' isHorizontal />
        <div className={classes.alertDismissButton} onClick={dismissUserAlert}>
          <Icon name='cross' size={24} />
        </div>
      </div>
      {onLinkClick ? (
        <div onClick={onLinkClick} dangerouslySetInnerHTML={{ __html: htmlMessage }} />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: htmlMessage }} />
      )}
    </div>
  )
}

UserAlert.propTypes = {
  userAlert: PropTypes.object,
  dismissUserAlert: PropTypes.func,
  style: PropTypes.object,
  onLinkClick: PropTypes.func,
}

export default UserAlert
