import React from 'react'
import styles from './MaxWidthBlock.module.scss'

interface Props {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

const MaxWidthBlock: React.FunctionComponent<Props> = ({ className, children, style }) => {
  return (
    <div id='outerBlock' className={`${styles.container}`} data-testid='MaxWidthBlock'>
      <div className={`${styles.maxWidthBlock} ${className}`} style={style}>
        {children}
      </div>
    </div>
  )
}

export default MaxWidthBlock
