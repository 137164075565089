import React from 'react'

import { Metric } from 'redux/modules/capital/types'
import TitleCard from 'components/cards/TitleCard'
import styles from './CapitalQualificationBlock.module.scss'
import CapitalQualificationCriteriaBlock from '../CapitalQualificationCriteriaBlock'
import { QualificationType } from '../CapitalQualificationCriteriaBlock/types'

interface Props {
  months: Metric
  transactions: Metric
  volume: Metric
}

const CapitalQualificationBlock: React.FunctionComponent<Props> = ({
  months,
  transactions,
  volume,
}) => {
  return (
    <TitleCard title='Do I qualify for capital?'>
      <div className={styles.container} data-testid='CapitalQualificationBlock'>
        <div className={styles.criteriaContainer}>
          <CapitalQualificationCriteriaBlock
            title='Months with Yoco'
            actual={months.actual}
            target={months.target}
            type={QualificationType.Months}
          />
          <CapitalQualificationCriteriaBlock
            title='Monthly card turnover'
            actual={volume.actual}
            target={volume.target}
            type={QualificationType.Volume}
            isMiddleBlock
          />
          <CapitalQualificationCriteriaBlock
            title='Monthly card transactions'
            actual={transactions.actual}
            target={transactions.target}
            type={QualificationType.Transactions}
          />
        </div>
        <div className={styles.footer}>
          <p className={styles.footerText}>
            These requirements are an average of your card turnover and transactions per month, for
            <strong> 3 consecutive months</strong>.
          </p>
        </div>
      </div>
    </TitleCard>
  )
}

export default CapitalQualificationBlock
