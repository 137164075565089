import React from 'react'
import PropTypes from 'prop-types'
import { useAsyncDebounce } from 'react-table'
import { Icon } from '@yoco/design-system-icons/dist/react'

import classes from './referrals.module.scss'

function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce((val) => {
    setGlobalFilter(val || undefined)
  }, 200)

  return (
    <span className={classes.searchBar}>
      <input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
        placeholder={`Search ${count} records...`}
        style={{ border: '0' }}
      />
      <span style={{ paddingTop: 10 }}>
        <Icon name='search' size='30' />
      </span>
    </span>
  )
}

GlobalFilter.propTypes = {
  preGlobalFilteredRows: PropTypes.array,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func,
}

export default GlobalFilter
