import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import * as forms from 'libs/forms'
import FlatButton from 'components/buttons/FlatButton'
import { clearForm } from 'redux/modules/forms'
import CONSTANTS from 'libs/constants'
import UpdateStockPopup from 'components/popups/UpdateStockPopup'
import FormCard from 'components/cards/FormCard'
import * as formats from 'libs/formats'
import { getStockCount } from 'libs/pos/utils'
import ConnectedObjectComponent from 'libs/firebase/ConnectedObjectComponent'

import classes from './store.module.scss'

class UnconnectedEditStockForm extends ConnectedObjectComponent {
  constructor(props) {
    super(props)

    this.state.showStockModal = false
  }

  onUpdateItem() {
    if (this.props.closeSplitView) {
      this.props.closeSplitView()
    }
  }

  onUpdateStock() {
    this.setState({
      showStockModal: true,
    })
  }

  updateStockPopup() {
    const formPopupName = 'updateStockForm'

    return (
      <UpdateStockPopup
        showing={this.state.showStockModal}
        api='/stock/setStockAtLocation'
        sku={(this.getConnectedObject() || Map()).getIn(['sku', 'sku'])}
        skuUUID={(this.getConnectedObject() || Map()).get('skuUUID')}
        stockInfo={this.getConnectedObject()}
        formName={formPopupName}
        currentStock={getStockCount(this.getConnectedObject())}
        onEsc={() => {
          this.setState({
            showStockModal: false,
          })
          this.props.dispatch(clearForm(formPopupName))
        }}
      />
    )
  }

  getStockCountItem() {
    return (
      <div className={classes.stockCountItem}>
        <div className={classes.stockDetails}>
          <span className={classes.stockLabel}>Current Stock</span>
          <span className={classes.stockCount}>
            <formats.Number number={getStockCount(this.getConnectedObject())} />
          </span>
        </div>
        <FlatButton
          label='Update'
          type='button'
          className={`${classes.cardButton} blueBackground`}
          onClick={() => this.onUpdateStock()}
        />
      </div>
    )
  }

  getStockAtLocations() {
    if (this.props.storeLocations && this.props.storeLocations.size > 1) {
      return <div />
    }
    return this.getStockCountItem()
  }

  renderStockAtLocations() {
    return <FormCard header='Stock at Locations'>{this.getStockAtLocations()}</FormCard>
  }

  renderStockAlertsForm() {
    const shownRow = this.getConnectedObject()
    return (
      <FormCard header='Stock Alerts'>
        <forms.Form
          name={this.props.formName}
          action={`/stock/${shownRow.getIn(['sku', 'sku'])}/updateStockInfo`}
          initialData={this.getConnectedObject()}
          onSuccess={() => this.onUpdateItem()}
        >
          <div className='clearfix'>
            <div className={classes.stockCountItem}>
              <forms.TextField
                name='alertLevel'
                label='Low Stock Alert'
                placeholder='0'
                validators={[new forms.NumberValidator()]}
              />
            </div>
          </div>
          {`Alert me when stock falls below this level. `}
          <Link
            style={{ color: CONSTANTS.YOCO_BLUE, cursor: 'pointer' }}
            to='/business/email-notifications?notification=stock'
          >
            Configure who gets these alerts.
          </Link>
        </forms.Form>
      </FormCard>
    )
  }

  render() {
    return (
      <div>
        {this.renderStockAtLocations()}
        {this.renderStockAlertsForm()}
        {this.updateStockPopup()}
      </div>
    )
  }
}

const EditStockForm = connect((state, props) => ({
  reduxItem: state.forms.getIn([props.formName, 'data'], Map({})),
}))(UnconnectedEditStockForm)

export default EditStockForm

UnconnectedEditStockForm.propTypes = {
  dispatch: PropTypes.func,
  closeSplitView: PropTypes.func,
  formName: PropTypes.string.isRequired,
}
