import { useEffect, useState } from 'react'

import { callJSONApi } from 'libs/api'
import { CoreResponseEnvelope } from 'types/types'
import {
  StorePage,
  StoreConfigHookReturnType,
  PublishStoreHookReturnType,
  StoreApiResponseData,
} from './types'

const defaultPages: StorePage[] = [
  {
    pageType: 'home',
    path: '/',
    name: 'Shop',
    sections: [
      {
        sectionType: 'hero',
      },
    ],
  },
  {
    pageType: 'custom',
    path: '/about',
    name: 'About',
    sections: [
      {
        sectionType: 'content',
      },
    ],
  },
]

const defaultColor = '#3B82F6'

export function useStore(): StoreConfigHookReturnType {
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [primaryColor, setPrimaryColor] = useState(defaultColor)
  const [backgroundColor, setBackgroundColor] = useState<string>()
  const [logoURL, setLogoURL] = useState<string>()
  const [showBusinessName, setShowBusinessName] = useState(false)
  const [dataFetched, setDataFetched] = useState(false)

  const [pages, setPages] = useState(defaultPages)

  const [url, setURL] = useState<string>()
  const [isEnabled, setIsEnabled] = useState(false)
  // Making this easier while we don't actually have pages

  const loadData = () => {
    setIsLoading(true)
    setDataFetched(false)
    callJSONApi(
      '/store/',
      'GET',
      null,
      (data: CoreResponseEnvelope<StoreApiResponseData>) => {
        if (data.data) {
          const { config } = data.data
          if (config) {
            setPrimaryColor(config.theme.primaryColor)
            setBackgroundColor(config.theme.backgroundColor)
            setLogoURL(config.header.logoURL)
            setShowBusinessName(config.header.showBusinessName)
            setPages(config.pages ?? defaultPages)
          }
          // For some reason the prod core instance returns this url without https
          setURL(data.data.url && data.data.url.replace(/^http:/, 'https:'))
          setIsEnabled(data.data.isEnabled)
        }

        setIsLoading(false)
        setDataFetched(true)
      },
      (prettyError) => {
        setIsLoading(false)
        setErrorMessage(prettyError)
      }
    )
  }

  useEffect(() => {
    loadData()
  }, [])

  return {
    // Store state
    url,
    isEnabled,
    setIsEnabled,
    config: {
      theme: {
        primaryColor,
        setPrimaryColor,
        backgroundColor,
        setBackgroundColor,
      },
      header: {
        logoURL,
        setLogoURL,
        showBusinessName,
        setShowBusinessName,
      },
      pages,
      setPages,
    },
    // Internal state
    isLoading,
    errorMessage,
    setErrorMessage,
    dataFetched,
    reloadData: loadData,
  }
}

/* Util for getting/ setting the published state of the online store */
export function usePublishStore(): PublishStoreHookReturnType {
  const store = useStore()
  const [isSaving, setIsSaving] = useState(false)
  // We need the store config data for the initial isEnabled state.
  const [dataLoaded, setDataLoaded] = useState(false)
  const [isPublished, setIsPublished] = useState(false)
  const [errorMessage, setErrorMessage] = useState(store.errorMessage)

  useEffect(() => {
    if (!store.isLoading) {
      setIsPublished(store.isEnabled)
      setDataLoaded(true)
    }
  }, [store.isEnabled, store.isLoading])

  const saveEnabled = () => {
    setIsSaving(true)
    callJSONApi(
      `/store/setEnabled`,
      'POST',
      { isEnabled: !isPublished },
      () => {
        setIsPublished((isEnabled) => !isEnabled)
        setIsSaving(false)
      },
      (prettyError) => {
        setIsSaving(false)
        setErrorMessage(prettyError)
        console.error(`Failed setting store published state`, prettyError)
      }
    )
  }

  const togglePublished = () => {
    saveEnabled()
  }

  return {
    dataLoaded,
    isPublished,
    togglePublished,
    isSaving,
    publishError: errorMessage,
  }
}
