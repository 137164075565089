/* eslint-disable no-param-reassign */
import { FormFieldNames } from './constants'

export const normalizeInitialFormData = (data) => {
  if (data.dueIn === null) {
    data.dueIn = ''
  }

  if (!data.paymentMethod) {
    data.paymentMethod = []
  }

  // Attaching intermediate field
  data[FormFieldNames.IncludeEFTDetails] = data.paymentMethod.includes('ManualEFT')

  return data
}

export const normalizeFormData = (formData) => {
  if (formData.has('paymentMethod') && formData.has(FormFieldNames.IncludeEFTDetails)) {
    const paymentMethods = [...formData.get('paymentMethod')]
    const includeEFTDetails = formData.get(FormFieldNames.IncludeEFTDetails)

    if (includeEFTDetails) {
      if (!paymentMethods.includes('ManualEFT')) {
        paymentMethods.push('ManualEFT')
      }
    } else {
      const manualEFTIndex = paymentMethods.findIndex((item) => item === 'ManualEFT')
      if (manualEFTIndex > -1) {
        paymentMethods.splice(manualEFTIndex, 1)
      }
    }
    return formData.set('paymentMethod', paymentMethods).delete(FormFieldNames.IncludeEFTDetails)
  }

  return formData
}
