/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import ItemPopover from 'components/items/ItemPopover'
import { multipleFieldsUpdated } from 'redux/modules/forms'

import classes from './items.module.scss'

class ItemIconEditor extends Component {
  getContent() {
    const overlay = (
      <Popover title='Edit Item Icon' id='edit icon'>
        <ItemPopover
          itemData={this.props.itemData}
          iconChanged={(data) => {
            this.props.dispatch(multipleFieldsUpdated(this.props.name, data))
          }}
          hideItemPopover={() => {
            // eslint-disable-next-line react/no-string-refs
            this.refs.popover.hide()
          }}
          handleFileChanged={(data) => {
            this.props.dispatch(
              multipleFieldsUpdated(this.props.name, {
                imageFileUUID: data.data.uuid,
                imageURL: data.data.url,
                removeImage: false,
              })
            )
          }}
        />
      </Popover>
    )

    const imageURL = this.props.itemData ? this.props.itemData.get('imageURL') : ''
    const backgroundColor = this.props.itemData
      ? this.props.itemData.get('backgroundColour', this.props.itemData.get('backgroundColor'))
        ? `#${this.props.itemData.get('backgroundColour' || 'backgroundColor')}`
        : '#0694DC'
      : '#0694DC'

    let backgroundStyle = {
      background: backgroundColor,
    }

    if (this.props.itemData && this.props.itemData.get('imageURL')) {
      backgroundStyle = {
        background: `url("${imageURL}")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      }
    }

    return (
      <div className={classes.itemIcon} style={{ margin: '0px auto 40px' }}>
        <div
          className={`${classes.itemBackground} ${classes.inner}`}
          style={backgroundStyle}
          onClick={this.imageClicked.bind(this)}
        >
          {this.getIcon()}
        </div>

        <OverlayTrigger
          container={this}
          // eslint-disable-next-line react/no-string-refs
          ref='popover'
          trigger='click'
          placement='bottom'
          overlay={overlay}
        >
          <a className={classes.editItem} id='edit-item'>
            Edit Icon
          </a>
        </OverlayTrigger>
      </div>
    )
  }

  getIcon() {
    if (this.props.itemData) {
      if (this.props.itemData.get('imageURL')) {
        return ''
      }
    }
    return <div className={`${classes.itemText} ${classes.inner}`}>{this.imageText()}</div>
  }

  imageText() {
    let imageText = null
    let name = null

    if (this.props.itemData) {
      imageText = this.props.itemData.get('imageText')
        ? this.props.itemData.get('imageText').replace(/_/g, '')
        : ''
      name = this.props.itemData.get('name') ? this.props.itemData.get('name').substring(0, 2) : ''
    }
    if (this.props.productData) {
      imageText = this.props.productData.getIn(['tile', 'imageText'])
        ? this.props.productData.getIn(['tile', 'imageText']).replace(/_/g, '')
        : ''
      name = this.props.productData.getIn(['tile', 'name'])
        ? this.props.productData.getIn(['tile', 'name']).substring(0, 2)
        : ''
    }

    if (imageText) {
      return imageText
    }

    if (name) {
      return name
    }
    return ''
  }

  imageClicked() {
    // eslint-disable-next-line react/no-string-refs
    this.refs.popover.show()
  }

  optionClicked(option) {
    if (this.props.optionClicked) {
      this.props.optionClicked(option)
    }
  }

  render() {
    return this.getContent()
  }
}

ItemIconEditor.propTypes = {
  productData: PropTypes.object,
  dispatch: PropTypes.func,
  name: PropTypes.string.isRequired,
  itemData: PropTypes.object,
  optionClicked: PropTypes.func,
}

export default connect((state, props) => ({
  itemData: state.forms.getIn([props.name, 'data'], Map({})),
}))(ItemIconEditor)
