/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import classes from './errorAccordion.module.scss'

export default class ErrorAccordion extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      arrowDirection: 'icon2-chevron-down',
    }
  }

  toggleAccordion() {
    this.setState((state) => ({
      open: state.open,
      arrowDirection: state.open ? 'icon2-chevron-down' : 'icon2-chevron-up',
    }))
  }

  checkForContent() {
    if (this.props.content && this.state.open) {
      return <div className={classes.contentInner}>{this.props.content}</div>
    }
    return null
  }

  displayExpander() {
    if (this.props.content) {
      return (
        <div style={{ display: 'inline' }}>
          <i className={`${this.state.arrowDirection} ${classes.expandIconLayout}`} />
          <span className={classes.expanderText}>EXPAND</span>
        </div>
      )
    }
    return null
  }

  render() {
    return (
      <div>
        <div className={classes.errorTitle} onClick={() => this.toggleAccordion()}>
          <div style={{ display: 'inline' }}>{this.props.title}</div>
          {this.displayExpander()}
        </div>
        {this.checkForContent()}
      </div>
    )
  }
}

ErrorAccordion.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
}
