import React from 'react'
import PropTypes from 'prop-types'

import { TableRow } from 'libs/tables'
import ItemIcon from 'components/items/ItemIcon'
import classes from 'containers/online/online.module.scss'

export default function OnlineProductRow({ onlineProduct, index, onClick }) {
  return (
    <TableRow rowIndex={index} onClick={() => onClick(onlineProduct)}>
      <td className={classes.tileColumn}>
        <ItemIcon tile={onlineProduct.getIn(['product', 'tile'])} />
      </td>
      <td>{onlineProduct.get('productName')}</td>
      <td className={classes.priceColumn}>{onlineProduct.getIn(['product', 'defaultPrice'])}</td>
    </TableRow>
  )
}

OnlineProductRow.propTypes = {
  onlineProduct: PropTypes.object,
  index: PropTypes.number,
  onClick: PropTypes.func,
}
