import Immutable from 'immutable'
import { combineReducers } from '@reduxjs/toolkit'
import { routerReducer as routing } from 'react-router-redux'
import ldRedux from 'ld-redux'

import forms from 'redux/modules/forms'
import session from 'redux/modules/session'
import tables from 'redux/modules/tables'
import filters from 'redux/modules/filters'
import notifications from 'redux/modules/notifications'
import routeBadges from 'redux/modules/routeBadges'
import imports from 'redux/modules/imports'
import { onboardingReducer as onboarding } from 'redux/modules/onboarding'
import invoices from 'redux/modules/invoices'
import capital from 'redux/modules/capital'
import dashboard from 'redux/modules/dashboard'
import performance from 'redux/modules/sales/performance'
import settlement from 'redux/modules/sales/settlement'
import plugin from 'redux/modules/plugin'
import profile from 'redux/modules/profile'

const reducer = combineReducers({
  routing,
  routeBadges,
  forms,
  session,
  tables,
  filters,
  notifications,
  imports,
  onboarding,
  capital,
  dashboard,
  performance,
  settlement,
  plugin,
  invoices,
  launchDarkly: ldRedux.reducer(),
  profile,
})

export default function (state = {}, action) {
  if (action.type === '@@INIT' || action.type === '@@redux/INIT') {
    const newState = { ...state }
    // eslint-disable-next-line no-restricted-syntax
    for (const key in newState) {
      if (
        // eslint-disable-next-line no-prototype-builtins
        newState.hasOwnProperty(key) &&
        key !== 'routing' &&
        key !== 'reduxAsyncConnect'
      ) {
        newState[key] = Immutable.fromJS(state[key])
      }
    }
    return reducer(newState, action)
  }

  return reducer(state, action)
}
