export const BUSINESS_INFO_ACTIONS = {
  UPDATE_TRADING_ADDRESS: 'UPDATE_TRADING_ADDRESS',
  DISPLAY_BUSINESS_INFO_POPUP: 'DISPLAY_BUSINESS_INFO_POPUP',
}

export const updateTradingAddress = (payload) => ({
  type: BUSINESS_INFO_ACTIONS.UPDATE_TRADING_ADDRESS,
  payload,
})

export const openBusinessInfoPopup = () => ({
  type: BUSINESS_INFO_ACTIONS.DISPLAY_BUSINESS_INFO_POPUP,
  payload: true,
})

export const closeBusinessInfoPopup = () => ({
  type: BUSINESS_INFO_ACTIONS.DISPLAY_BUSINESS_INFO_POPUP,
  payload: false,
})
