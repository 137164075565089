const collectionIcons = [
  'scissor',
  'power',
  'ink',
  'cutter',
  'binocular',
  'lock',
  'pen-1',
  'pen-2',
  'pencil',
  'skull-1',
  'tag',
  'bookmark',
  'palette',
  'brush-2',
  'stationery',
  'ruler',
  'present',
  'ribbon',
  'heart',
  'trophy',
  'camera-symbol',
  'profile-nurse-1',
  'profile-graduate',
  'profile-chef',
  'profile-athlete',
  'profile-nurse-2',
  'profile-doctor',
  'new',
  'wallet',
  'camera-pro',
  'camera-compact',
  'camera-top',
  'camera-quick',
  'polaroid',
  'video-camera',
  'headphone',
  'gramophone',
  'gramophone-record',
  'tape',
  'alarm-clock',
  'watch',
  'clock',
  'calendar',
  'shop',
  'treasure-map',
  'castle',
  'mouse',
  'memory-card',
  'joystick',
  'gameboy',
  'smartphone',
  'chef-hat',
  'academic-cap',
  'shirt-1',
  'shirt-2',
  'shirt-3',
  'sneakers',
  'snorkel',
  'monocle-1',
  'monocle-2',
  'glasses-1',
  'fins',
  'glasses-2',
  'beanie',
  'bike-helmet',
  'bow',
  'cap',
  'necktie',
  'safety-helmet',
  'tall-hat',
  'trousers',
  'walking-stick',
  'glasses-3',
  'baseball-helmet',
  'timer',
  'apple',
  'beer',
  'boil',
  'bottle',
  'bread',
  'burger-1',
  'burger-2',
  'cake-1',
  'cake-2',
  'champagne',
  'cheese',
  'cocktail',
  'cocktail-1',
  'coffee-pot',
  'cup-1',
  'cup-2',
  'energy-drink',
  'espresso-machine',
  'food-dome',
  'fork-and-knife',
  'grape',
  'grater',
  'grill',
  'hot-drinks-glass',
  'hotdog',
  'ice-cream-1',
  'ice-cream-2',
  'ice-cream-3',
  'ice-drinks-galss',
  'juicer',
  'kitchen-timer',
  'milk',
  'orange',
  'oven',
  'pan-fry',
  'pizza',
  'popcorn',
  'serving',
  'soda',
  'soda-can-1',
  'soda-can-2',
  'steam',
  'tea-pot',
  'thermometer-high',
  'thermometer-low',
  'thermometer-medium',
  'water',
  'wine',
  'plaster',
  'ticket-1',
  'ticket-2',
  'suitcase',
  'cards',
  'balloon',
  'smileys',
  'rain-lightning',
  'snow',
  'heavy-rain',
  'moon-cloud',
  'sun-cloud',
  'sun',
  'waning-crescent-moon',
  'road-bike',
  'bicycle',
  'scooter',
  'cactus',
  'clover',
  'flower',
  'potted-plant',
  'bag-shopping-2',
  'bag-shopping-1',
  'bag-shopping-3',
  'briefcase',
  'handbag-1',
  'handbag-2',
  'purse-2',
  'wallet-2',
  'wallet-3',
]

export default collectionIcons
