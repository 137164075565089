/* eslint-disable max-classes-per-file */
import React from 'react'
import PropTypes from 'prop-types'

import OnOffSwitch from 'components/buttons/OnOffSwitch'
import CheckboxButton from 'components/buttons/CheckboxButton'

import { FormField } from './base'
import classes from './forms.module.scss'

export class SwitchField extends FormField {
  onClick() {
    this.props.onClick?.()
    this.onChange({
      target: {
        value: !this.props.value,
      },
    })
  }

  getDivClassName() {
    const className = super.getDivClassname()
    return `${className} ${classes.checkboxField}`
  }

  renderInput() {
    return (
      <div className={classes.checkboxWrapper}>
        <OnOffSwitch
          name={this.props.name}
          // eslint-disable-next-line react/jsx-no-bind
          onChange={this.onClick.bind(this)}
          checked={this.props.value}
          ariaLabel={this.props.ariaLabel}
          testID={this.props.testID}
        />
      </div>
    )
  }
}

SwitchField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.bool,
  onClick: PropTypes.func,
  ariaLabel: PropTypes.string,
  testID: PropTypes.string,
}

export class CheckBoxField extends FormField {
  getDivClassName() {
    return `${super.getDivClassname()} ${classes.checkboxField}`
  }

  onClick(event) {
    event.stopPropagation()
    this.onChange({ target: { value: !this.props.value } })
  }

  render() {
    return (
      <div className={this.getDivClassName()}>
        <div className={classes.checkboxLabelWrapper}>
          {this.renderInput()}
          <span className={classes.checkboxLabel} style={this.props.labelStyle}>
            {this.props.label}
          </span>
        </div>
        <div className='help'>{this.renderHelpText()}</div>
      </div>
    )
  }

  renderInput() {
    return (
      <CheckboxButton
        name={this.props.name}
        onClick={(event) => {
          if (!this.props.disabled) {
            this.onClick(event)
          }
        }}
        disabled={this.props.disabled}
        className={classes.checkbox}
        selected={this.props.value}
        style={{ float: 'left' }}
      />
    )
  }
}
