import React from 'react'
import { Icon } from '@yoco/design-system-icons/dist/react'
import { isSmallDevice, makeTestID } from 'libs/utils'
import styles from './SplitHeader.module.scss'

interface Props {
  title: string
  actions?: React.ReactNode
  style?: React.CSSProperties
  children?: React.ReactNode
  closeSplitView: () => void
  testID?: string
}
const baseTestID = 'splitHeader'
const SplitHeader: React.FunctionComponent<Props> = ({
  title,
  actions,
  style,
  children,
  closeSplitView,
  testID = '',
}) => {
  const iconType = isSmallDevice() ? 'close' : 'chevron-right'

  return (
    <div
      role='heading'
      aria-level={1}
      className={styles.container}
      data-testid={makeTestID(testID, baseTestID)}
    >
      <div className={styles.header} style={style}>
        <button
          type='button'
          className={styles.closeButton}
          onClick={closeSplitView}
          data-testid='splitHeader-close'
        >
          <Icon name={iconType} size={24} />
        </button>
        <div className={styles.title} data-testid={makeTestID(testID, baseTestID, 'title')}>
          {title}
        </div>
        <div className={styles.actions}>{actions}</div>
      </div>
      {children}
    </div>
  )
}

export default SplitHeader
