/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { makeTestID } from '../utils'

import classes from './List.module.scss'

const List = ({ hasOutlineBorders, data, renderItem, className, children, testID, ...props }) => (
  <div
    className={classNames(classes.root, className, {
      [classes.outlineBorders]: hasOutlineBorders,
    })}
    data-testid={makeTestID(testID, 'list')}
    {...props}
  >
    {Array.isArray(data) && data.map(renderItem)}
    {children}
  </div>
)

List.propTypes = {
  data: PropTypes.array,
  hasOutlineBorders: PropTypes.bool,
  renderItem: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
  testID: PropTypes.string,
}

export default List
