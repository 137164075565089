import { React, useCallback, useState } from 'react'
import Alert from 'components/notifications/Alert'
import { callJSONApi } from 'libs/api'
import { connect } from 'react-redux'
import FlatButton from 'components/buttons/FlatButton'
import PropTypes from 'prop-types'
import { selectBankAccountUpdateRequest } from 'redux/modules/onboarding/bankDetails/selectors'
import { BANK_REQUEST_STATUSES } from 'redux/modules/onboarding/bankDetails'

function VerificationAlert({ bankAccountUpdateRequest, onCancelVerification }) {
  const [messageType, setMessageType] = useState('info')
  const [message, setMessage] = useState(undefined)

  const onCancelVerificationClick = useCallback(() => {
    callJSONApi(
      `/business/bank-account/updates/${bankAccountUpdateRequest.uuid}/cancel`,
      'POST',
      {},
      (response) => {
        if (response.status === 200) {
          onCancelVerification()
        } else {
          setMessageType('danger')
        }
      },
      () => {
        setMessage('Something went wrong. Please refresh the page to retry.')
        setMessageType('danger')
      }
    )
  }, [onCancelVerification, bankAccountUpdateRequest, setMessage, setMessageType])
  return (
    <Alert messageType={messageType} closable={false}>
      {message || (
        <>
          <b>We’re busy verifying your bank details</b>
          <br />
          <br />
          <b>Bank:</b> {bankAccountUpdateRequest.updateInfo?.bank}
          <br />
          <b>Account Number:</b> {bankAccountUpdateRequest.updateInfo?.accountNumber}
          <br />
          <br />
          <p>Until then you’ll receive funds in the account below.</p>
          <br />
          {bankAccountUpdateRequest.updateState === BANK_REQUEST_STATUSES.MANUALLY_EXECUTED && (
            <FlatButton
              label='Cancel Verification'
              type='reset'
              className='blueBackground'
              onClick={onCancelVerificationClick}
            />
          )}
        </>
      )}
    </Alert>
  )
}

VerificationAlert.propTypes = {
  onCancelVerification: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  bankAccountUpdateRequest: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  bankAccountUpdateRequest: selectBankAccountUpdateRequest(state).toJS(),
})

export default connect(mapStateToProps)(VerificationAlert)
