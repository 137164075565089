import React from 'react'
import classnames from 'classnames'

import { PopupComponent } from 'libs/modals'
import popupClasses from 'libs/modals/popups.module.scss'
import layout from 'ui/layout/layout.module.scss'
import apple from 'assets/images/apple.png'
import play from 'assets/images/play.png'
import hauwei from 'assets/images/hauwei-1.png'

export default class DownloadAppPopup extends PopupComponent {
  getContent() {
    return (
      <div>
        <h4>GET YOCO ON YOUR PHONE OR TABLET</h4>
        <div className={popupClasses.listBlock}>
          <p>
            <strong>Next steps</strong>
          </p>
          <p>
            1. Download the app on your phone or tablet. <br />
            2. Login with the email and password you signed up with. <br />
            3. Send your first invoice. <br />
            4. Complete your setup to get paid.
          </p>
        </div>
        <div className={classnames(layout.itemsStretch, layout.flex, popupClasses.spaceBetween)}>
          <a
            className={classnames(layout.flex, layout.pt2)}
            href='https://itunes.apple.com/za/app/yoco/id892816357?mt=8'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img height='35px' src={apple} alt='Apple' />
          </a>
          <a
            className={classnames(layout.flex, layout.pt2)}
            href='https://play.google.com/store/apps/details?id=za.co.yoco&hl=en'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img height='35px' src={play} alt='Play' />
          </a>
          <a
            className={classnames(layout.flex, layout.pt2)}
            href='https://appgallery.huawei.com/#/app/C102766851'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img height='35px' src={hauwei} alt='Hauwei' />
          </a>
        </div>
      </div>
    )
  }
}
