/* eslint-disable max-classes-per-file */
import React from 'react'
import { Map } from 'immutable'
import PropTypes from 'prop-types'

import { If } from 'libs/formats'

import { FormField } from './base'
import classes from './forms.module.scss'

export class TextField extends FormField {
  renderMaxLength() {
    if (this.props.maxLength && this.props.showRemaining) {
      return (
        <div className={classes.characters}>
          {`Characters remaining ${this.props.maxLength - (this.props.value || '').length}`}
        </div>
      )
    }

    return undefined
  }

  renderInput() {
    const backgroundColor = this.props.disabled ? '#efefef' : ''
    const color = this.props.disabled ? '#949ba5' : ''
    const maxLength = this.props.maxLength || 10000
    return (
      <div>
        <div className={classes.inputWrapper}>
          <input
            onChange={this.onChange.bind(this)}
            type={this.props.type}
            value={this.props.value || ''}
            label={this.props.label}
            name={this.props.name}
            placeholder={this.props.placeholder || ''}
            onFocus={this.props.onFocus ? this.props.onFocus : this.onFocus.bind(this)}
            onBlur={this.onBlur.bind(this)}
            disabled={this.props.disabled}
            maxLength={maxLength}
            style={{ backgroundColor, color }}
            aria-label={this.props.ariaLabel}
            data-testid={this.props.testID}
          />
          <If condition={!!this.props.subTitle}>
            <span className={classes.subTitle}>{this.props.subTitle}</span>
          </If>
        </div>
        {this.renderMaxLength()}
      </div>
    )
  }
}

TextField.defaultProps = {
  showRemaining: true,
}

export class TextareaField extends FormField {
  renderInput() {
    const disableResizeStyle = this.props.disableResize ? { resize: 'none' } : null
    return (
      <textarea
        onChange={this.onChange.bind(this)}
        type={this.props.type}
        value={this.props.value || ''}
        placeholder={this.props.placeholder || ''}
        onFocus={this.onFocus.bind(this)}
        onBlur={this.onBlur.bind(this)}
        rows={this.props.rows || 1}
        style={disableResizeStyle}
        aria-label={this.props.ariaLabel}
        data-testid={this.props.testID}
      />
    )
  }
}

export class HiddenField extends TextField {
  getDivClassname() {
    return 'hidden'
  }
}

HiddenField.defaultProps = {
  type: 'hidden',
}

export class CurrencyTextField extends TextField {
  onChange(event) {
    let { value } = event.target
    const isDecimal = (str) => {
      const pattern = /^[\d.-]+$/
      return pattern.test(str)
    }

    if (!isDecimal(value)) {
      value = value.substr(0, value.length - 1)
    }
    super.onChange(event)
  }

  onBlur(event) {
    super.onBlur(event)
    if (this.props.value) {
      super.onChange(event)
    }
  }

  renderInput() {
    let { value } = this.props
    if (typeof value !== 'string' && value !== undefined && value !== null) {
      value = value.toFixed(2)
    }

    if (typeof value !== 'string' && value !== undefined && value !== null) {
      if (this.props.localisation.get('currencyAllowsDecimals')) {
        value = value.toFixed(2)
      } else {
        value = value.toString()
      }
    }

    let prefix = null
    let suffix = null
    let textClassName = 'text-left'
    let positionalClass = 'left'
    switch (this.props.localisation.get('currency')) {
      case 'RWF':
        suffix = this.props.localisation.get('currencySymbol')
        textClassName = 'text-right'
        positionalClass = 'right'
        break
      case 'ZAR':
      default:
        prefix = this.props.localisation.get('currencySymbol', 'R')
    }

    return (
      <div className={`${classes.currencyInputWrapper} input-group`} style={this.props.style}>
        {prefix && <span className={`${positionalClass} input-group-addon`}>{prefix}</span>}
        <input
          onChange={this.onChange.bind(this)}
          type={this.props.localisation.get('currencyAllowsDecimals') ? 'text' : 'number'}
          value={this.props.value || ''}
          placeholder={this.props.placeholder || ''}
          onFocus={this.onFocus.bind(this)}
          onBlur={this.onBlur.bind(this)}
          className={textClassName}
          data-testid={this.props.testID}
        />
        {suffix && <span className={`${positionalClass} input-group-addon`}>{suffix}</span>}
        {this.props.subTitle ? <span className={classes.subTitle}>{this.props.subTitle}</span> : ''}
      </div>
    )
  }
}

CurrencyTextField.propTypes = {
  localisation: PropTypes.objectOf(Map),
}

export class PasswordField extends TextField {}

PasswordField.defaultProps = {
  type: 'password',
}

export class EmailField extends TextField {}

EmailField.defaultProps = {
  type: 'email',
}

TextField.propTypes = {
  testID: PropTypes.string,
  ariaLabel: PropTypes.string,
}
