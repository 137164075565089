/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Icon } from '@yoco/design-system-icons/dist/react'

import classes from './ListItem.module.scss'
import { makeTestID } from '../utils'

const Accessory = ({ content, cssClass }) => {
  if (!content) {
    return null
  }

  if (typeof content === 'string') {
    return (
      <div className={cssClass}>
        <Icon name={content} size={24} />
      </div>
    )
  }

  return <div className={cssClass}>{content}</div>
}
Accessory.propTypes = {
  content: PropTypes.node,
  cssClass: PropTypes.string,
}

const ListItem = ({
  className,
  accessoryLeft,
  title,
  subTitle,
  description,
  subDescription,
  accessoryRight,
  onClick,
  testID,
  children,
  ...props
}) => (
  <div
    className={classNames(classes.root, className)}
    data-testid={makeTestID(testID, 'listItem')}
    {...props}
  >
    <div className={classes.content} onClick={onClick}>
      <Accessory content={accessoryLeft} cssClass={classes.left} />
      <div className={classes.middle}>
        {(title || subTitle) && (
          <div className={classes.middleLeft}>
            {title && <div className={classes.title}>{title}</div>}
            {subTitle && <div className={classes.subTitle}>{subTitle}</div>}
          </div>
        )}
        {(description || subDescription) && (
          <div className={classes.middleRight}>
            {description && <div className={classes.description}>{description}</div>}
            {subDescription && <div className={classes.subDescription}>{subDescription}</div>}
          </div>
        )}
      </div>
      <Accessory content={accessoryRight} cssClass={classes.right} />
    </div>
    {children}
  </div>
)

ListItem.propTypes = {
  title: PropTypes.node.isRequired,
  className: PropTypes.string,
  accessoryLeft: PropTypes.node,
  subTitle: PropTypes.node,
  description: PropTypes.node,
  subDescription: PropTypes.node,
  accessoryRight: PropTypes.node,
  onClick: PropTypes.func,
  testID: PropTypes.string,
  children: PropTypes.node,
}

export default ListItem
