import Immutable, { Map, List } from 'immutable'

export const ACTIONS = {
  FILTER_SET: 'FILTER_SET',
  FILTER_SHOWN: 'FILTER_SHOWN',
  HIDE_FILTERS: 'HIDE_FILTERS',
  FILTERS_FINISHED_INITIALIZING: 'FILTERS_FINISHED_INITIALIZING',
  SEARCH_SET: 'FILTER_SEARCH_SET',
  TOGGLE_SET: 'FILTER_TOGGLE_SET',
  CLEAR_SET: 'FILTER_CLEAR_SET',
}

export function filterSet(batch, filterKey, selectedKeys) {
  return {
    type: ACTIONS.FILTER_SET,
    batch,
    filterKey,
    selectedKeys,
  }
}

export function filterShown(batch, filterKey, shown, isMobile) {
  return {
    type: ACTIONS.FILTER_SHOWN,
    batch,
    filterKey,
    shown,
    isMobile,
  }
}

export function hideFilters(batch) {
  return {
    type: ACTIONS.HIDE_FILTERS,
    batch,
  }
}

export function filtersFinishedInitializing(batch) {
  return {
    type: ACTIONS.FILTERS_FINISHED_INITIALIZING,
    batch,
  }
}

export function searchSet(batch, searchString) {
  return {
    type: ACTIONS.SEARCH_SET,
    batch,
    searchString,
  }
}

export function toggleSet(batch, toggled, api) {
  return {
    type: ACTIONS.TOGGLE_SET,
    batch,
    toggled,
    api,
  }
}

export function clearSet(batch) {
  return {
    type: ACTIONS.CLEAR_SET,
    batch,
  }
}

const initialState = Map()

export default function Filters(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.FILTER_SET: {
      let selectedKeys = List()
      if (action.selectedKeys) {
        selectedKeys = Immutable.fromJS(action.selectedKeys)
      }

      return state.setIn([action.batch, 'filters', action.filterKey, 'values'], selectedKeys)
    }
    case ACTIONS.FILTER_SHOWN: {
      const key = action.isMobile ? 'mobileShownFilter' : 'shownFilter'
      if (action.shown) {
        return state.setIn([action.batch, key], action.filterKey)
      }
      if (state.getIn([action.batch, key]) === action.filterKey) {
        return state.setIn([action.batch, key], undefined)
      }

      return state
    }
    case ACTIONS.HIDE_FILTERS: {
      return state.setIn([action.batch, 'shownFilter'], undefined)
    }
    case ACTIONS.FILTERS_FINISHED_INITIALIZING: {
      return state.setIn([action.batch, 'initialized'], true)
    }
    case ACTIONS.SEARCH_SET: {
      let searchString = ''
      if (action.searchString) {
        searchString = action.searchString
      }

      return state.setIn([action.batch, 'search', 'value'], searchString)
    }
    case ACTIONS.TOGGLE_SET: {
      console.log('redux', action.api)
      return state
        .setIn([action.batch, 'toggle', 'value'], action.toggled || false)
        .setIn([action.batch, 'toggle', 'api'], action.api)
    }
    case ACTIONS.CLEAR_SET: {
      return state.delete(action.batch)
    }
    default: {
      return state
    }
  }
}
