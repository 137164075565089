import { Icon } from '@yoco/design-system-icons/dist/react'
import React from 'react'
import { Link } from 'react-router'

import CardTitle from 'ui/components/CardTitle'
import Card from 'ui/containers/Card'
import FlatButton from 'components/buttons/FlatButton'

import Classes from './OnlineStoreStepCard.module.scss'
import { Props } from './types'

const OnlineStoreStepsCard: React.FunctionComponent<Props> = ({
  heading,
  blurb,
  iconName,
  btnLabel,
  btnDisabled,
  todoTag,
  btnLink,
  btnOnPress,
}) => {
  return (
    <Card className={Classes.cardContainer}>
      <div className={Classes.cardDirection}>
        <div className={Classes.iconBlock}>
          <Icon name={iconName} size={32} color='grey' data-testid='icon' />
        </div>
        <div className={Classes.headingContainer}>
          <p className={Classes.heading} data-testid='heading'>
            {heading}
          </p>
          <CardTitle>{blurb}</CardTitle>
          {todoTag && (
            <div className={Classes.todoTag} data-testid='todoTag'>
              TO DO
            </div>
          )}
        </div>
        <div className={Classes.btnContainer}>
          {btnOnPress || (
            <Link
              to={{ pathname: btnLink, state: { prevPath: '/online/store' } }}
              style={{ textDecoration: 'none' }}
            >
              <FlatButton
                label={btnLabel}
                disabled={btnDisabled}
                type='tertiary'
                className={[Classes.btn, 'blueBackground', 'primary']}
              />
            </Link>
          )}
        </div>
      </div>
    </Card>
  )
}

export default OnlineStoreStepsCard
