/* eslint-disable no-restricted-syntax */
import { callJSONApi } from 'libs/api'

import Filter from './Filter'

export default class ApiFilter extends Filter {
  loadAvailableOptions(defaults) {
    const offset = 5 * this.pageNumber
    let url = `${this.props.api}?pageSize=5&offset=${offset}`
    if (this.props.additionalParameters) {
      url = `${url}&${this.props.additionalParameters}`
    }
    if (this.state.searchString) {
      url = `${url}&search=${this.state.searchString}`
    }
    if (this.state.defaults) {
      url = `${url}&included=${encodeURIComponent(JSON.stringify(this.state.defaults))}`
    }

    this.requestIndex += 1
    const index = this.requestIndex

    callJSONApi(
      url,
      'GET',
      undefined,
      (data) => {
        if (index === this.requestIndex) {
          this.setState({
            loading: false,
          })

          if (data.status > 299) {
            this.showError(data.message)
          } else {
            let newKeys = []
            let newOptions = {}
            if (offset > 0) {
              newKeys = this.state.availableOptionKeys
              newOptions = this.state.availableOptions
            } else {
              newOptions = this.addSelected(newKeys, newOptions)
            }
            let list = []

            if (this.props.defaultPath) {
              list = data.data[this.props.defaultPath]
            } else {
              const key = Object.keys(data.data)[0]
              if (key !== '0') {
                list = data.data[key]
              } else {
                list = data.data
              }
            }

            for (const item of list) {
              newOptions = this.addDisplayableOption(item, newKeys, newOptions)
            }

            if (defaults) {
              let found = false
              const keys = Object.keys(newOptions)
              keys.forEach((key) => {
                if (defaults.indexOf(key) >= 0) {
                  found = true
                  newOptions[key].selected = true
                }
              })

              if (!found && defaults[0]) {
                const dummyItem = this.generateDummyItemFromParams(defaults[0])
                if (dummyItem) {
                  newOptions = this.addDisplayableOption(dummyItem, newKeys, newOptions)

                  newOptions[defaults[0]].selected = true
                }
              }
            }

            this.setState({
              availableOptions: newOptions,
              availableOptionKeys: newKeys,
              showLoadMore: list.length === 5,
              error: null,
            })

            this.initializeFilter(this.state.defaults)
            this.setState({
              loading: false,
            })
          }
        }
      },
      (prettyError) => {
        if (index === this.requestIndex) {
          this.setState({
            loading: false,
          })

          this.showError(prettyError)
        }
      }
    )
  }

  showError(errorMessage) {
    console.log('Error in loading')
    console.log(errorMessage)
    this.setState({
      error: errorMessage,
    })
  }

  addDisplayableOption(option, newKeys, newOptions) {
    const optionKey = option.uuid
    const updatedNewOptions = { ...newOptions }
    if (newKeys.indexOf(optionKey) === -1) {
      newKeys.push(optionKey)
      let subtext = option.email
      if (option.info && option.info.staffNumber) {
        subtext = option.info.staffNumber
      }
      updatedNewOptions[optionKey] = {
        title: option[this.props.titleKey],
        // styles require subtext being there so an empty line is better than nothing
        subtext: subtext || `${option[this.props.titleKey]} transactions`,
        selected: false,
        ...(this.props.iconKey && { icon: `${option[this.props.iconKey].icon}` }),
      }
    }

    return updatedNewOptions
  }

  generateDummyItemFromParams() {
    return undefined
  }

  addSelected(newKeys, newOptions) {
    const updatedNewOptions = { ...newOptions }
    for (let i = 0; i < this.state.availableOptionKeys.length; i += 1) {
      const key = this.state.availableOptionKeys[i]
      if (this.state.availableOptions[key].selected) {
        newKeys.push(key)
        updatedNewOptions[key] = this.state.availableOptions[key]
      }
    }

    return updatedNewOptions
  }
}

ApiFilter.defaultProps = {
  emptySelectedString: 'Show all',
  searchable: true,
  placeholder: 'Filter',
  filterIcon: 'icon-globe-2',
  startLoading: true,
  titleKey: 'fullName',
}
