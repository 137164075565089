/* eslint-disable max-classes-per-file */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Link } from 'react-router'
import Immutable, { Map, List } from 'immutable'

import { FirebaseHandler, features } from 'libs/firebase'
import * as forms from 'libs/forms'
import FlatButton from 'components/buttons/FlatButton'
import AddItemsPopup from 'components/popups/AddItemsPopup'
import AdvancedOptionsPopup from 'components/popups/AdvancedOptionsPopup'
import ConfirmPopup from 'components/popups/ConfirmPopup'
import TileEditorFormField from 'components/tiles/TileEditorFormField'
import { fieldUpdated, clearForm, multipleFieldsUpdated } from 'redux/modules/forms'
import { addItem, itemUpdated } from 'redux/modules/tables'
import { variantsFromVariantGroups } from 'libs/pos/variants'
import FormCard from 'components/cards/FormCard'
import ConnectedObjectComponent from 'libs/firebase/ConnectedObjectComponent'
import CONSTANTS from 'libs/constants'
import classes from 'containers/store/store.module.scss'
import localisation from 'libs/localisation/localisation'
import onlineProductCard from 'containers/online/OnlineProductCard'
import ShareOnlineProduct from 'containers/online/components/ShareOnlineProduct'
import { makeTestID } from 'libs/utils'

const baseTestID = makeTestID('productsPage', 'addProduct')

class UnconnectedEditProductForm extends ConnectedObjectComponent {
  constructor(props) {
    super(props)

    this.state.tileColor = this.props.shownRow
      ? this.props.shownRow.get('backgroundColor')
      : CONSTANTS.YOCO_DEFAULT_COLOR
    this.state.showAddItemsModal = false
    this.state.showAdvancedOptionsModal = false
    this.state.showRedirectModal = false
    this.state.filterItemsOn = []

    this.showVariants = false
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.localObject !== this.props.localObject ||
      nextProps.showing !== this.props.showing
    ) {
      this.setState({
        showVariants: false,
      })
    }
    if (nextProps.reduxItem !== this.props.reduxItem) {
      this.registerUUID(nextProps)
    }
  }

  closeView() {
    if (this.props.closeSplitView) {
      this.props.closeSplitView()
    }
  }

  onAddVariantGroup(size) {
    this.setState({
      showVariants: true,
    })

    let variantGroups = this.props.reduxItem.get('variantGroups', List())

    let canAdd = true
    if (variantGroups) {
      if (variantGroups.size > 0) {
        variantGroups.forEach((item) => {
          if (!item.get('choiceName') || !item.get('choiceOptions', List()).size > 0) {
            // If the user has not yet entered a variant or option
            // in the available open fields then they cannot add a new variant
            // We display an error message to notify them of this
            canAdd = false
          }
        })
      }
    }

    if (canAdd && size < 3) {
      // If the user has not yet reached 3 variant groups then they can still add more
      // If they have reached their limit the add button will display 'limit reached'
      variantGroups = variantGroups.push(Immutable.fromJS({ choiceName: '', choiceOptions: [] }))
      this.props.dispatch(fieldUpdated(this.props.formName, 'variantGroups', variantGroups))
    }
  }

  onDeleteVariantGroup(index) {
    let variantGroups = this.props.reduxItem.get('variantGroups', Map())

    if (variantGroups) {
      variantGroups = variantGroups.splice(index, 1)
      this.props.dispatch(fieldUpdated(this.props.formName, 'variantGroups', variantGroups))
    }

    setTimeout(() => {
      this.tagsUpdated()
    })
  }

  onSaveProduct(response) {
    if (!this.props.localObject && response && response.data && response.data.product) {
      // This was a new item we must make sure to add it to our table
      this.props.dispatch(addItem(this.props.formName, Immutable.fromJS(response.data.product)))
    } else {
      // We need to update our item in our table
      this.props.dispatch(itemUpdated(this.props.formName, Immutable.fromJS(response.data.product)))
    }

    this.closeView()
  }

  onTagCollectionToProduct(fieldName, rowData) {
    if (rowData) {
      const collectionField = this.state.filterItemsOn[0] === 'brand' ? 'brand' : 'productCategory'
      this.props.dispatch(fieldUpdated(this.props.formName, fieldName, rowData.get('targetUUID')))
      this.props.dispatch(
        fieldUpdated(this.props.formName, collectionField, Map({ tile: rowData }))
      )
      this.hideModal('showAddItemsModal')
    }
  }

  removeCollectionFromProduct(collectionType) {
    if (this.props.reduxItem && this.props.reduxItem.get(collectionType)) {
      const fieldName = collectionType === 'brand' ? 'brandUUID' : 'productCategoryUUID'
      this.props.dispatch(
        multipleFieldsUpdated(
          this.props.formName,
          Map().set(fieldName, '').set(collectionType, null)
        )
      )
    }
  }

  collectionTileLookup(collectionType) {
    if (this.props.reduxItem && this.props.reduxItem.get(collectionType)) {
      return this.props.reduxItem.getIn([collectionType, 'tile', 'name'])
    }
    return undefined
  }

  hideModal(modal) {
    switch (modal) {
      case 'showAddItemsModal':
        this.setState({
          showAddItemsModal: false,
          filterItemsOn: [],
        })
        break
      case 'showAdvancedOptionsModal':
        this.setState({
          showAdvancedOptionsModal: false,
        })
        break
      case 'showRedirectModal':
        this.setState({
          showRedirectModal: false,
        })
        break
      default:
        break
    }
  }

  showModal(modal, filter) {
    switch (modal) {
      case 'showAddItemsModal':
        this.setState({
          showAddItemsModal: true,
          filterItemsOn: filter,
        })
        break
      case 'showAdvancedOptionsModal':
        this.setState({
          showAdvancedOptionsModal: true,
        })
        break
      case 'showRedirectModal':
        this.setState({
          showRedirectModal: true,
        })
        break
      default:
        break
    }
  }

  redirectPopUp() {
    return (
      <ConfirmPopup
        showing={this.state.showRedirectModal}
        onCancel={() => this.hideModal('showRedirectModal')}
        icon='icon-information'
        title='Update Stock'
        label='Go to Stock Page'
        subtitle={`
          You have not yet saved this product. Are you sure you want to redirect to the stock page?
        `}
        onConfirm={() => {
          this.props.dispatch(clearForm(this.props.formName))
          this.props.dispatch(push('/store/stock'))
        }}
      />
    )
  }

  tagsAvailable() {
    const tagsAvailable = 36
    const usedTags = this.getVariantGroups()
      .map((group) => {
        return group.get('choiceOptions', List()).size
      })
      .reduce((prev, current) => prev + current)

    return Math.max(tagsAvailable - usedTags, 0)
  }

  /**
   * When the name of our attribute is updated, we must set the correct `choiceGroups`
   * on each of our variants
   */
  attributeNameUpdated(index, e) {
    /*
      I would really have liked to do this as a Saga, or not have this stuff be
      calculated in redux at all - but for now I'm not going to try and clean
      up everything and just implement this in the style which is used everywhere
      else.
     */
    const variantGroups = this.getVariantGroups()
    const variants = this.props.reduxItem.get('variants', Map())

    const choiceGroups =
      variantGroups.map((group) => {
        return group.get('choiceName')
      }) || List()

    const newChoiceGroups = choiceGroups.set(index, e.target.value)

    const newVariants = variants.map((variant) => variant.set('choiceGroups', newChoiceGroups))

    this.props.dispatch(fieldUpdated(this.props.formName, 'variants', newVariants))
  }

  tagsUpdated() {
    const variantGroups = this.getVariantGroups()
    const variants = this.props.reduxItem.get('variants', Map())

    const choiceGroups = variantGroups.map((group) => {
      return group.get('choiceName')
    })

    // variantsFromVariantGroups does a cross product and returns all the variants we need
    const variantChoiceValues = variantsFromVariantGroups(variantGroups)
    // Now we either need to return our existing variant to preserve SKU and enabled,
    // or create a new one
    const newVariants = variantChoiceValues.map((choices) => {
      return variants
        .filter((variant) => {
          return (variant.get('choiceValues') || List()).equals(choices)
        })
        .get(
          0,
          Map({
            choiceValues: choices,
            enabled: true,
            choiceGroups,
          })
        )
    })

    this.props.dispatch(fieldUpdated(this.props.formName, 'variants', newVariants))
  }

  advancedOptionsPopup() {
    return (
      <AdvancedOptionsPopup
        formName={this.props.formName}
        showing={this.state.showAdvancedOptionsModal}
        onCancel={() => this.hideModal('showAdvancedOptionsModal')}
      />
    )
  }

  addItemsPopUp() {
    const tableName = `search${(this.state.filterItemsOn[0] || '')
      .replace('_', '')
      .charAt(0)
      .toUpperCase()}`
    const fieldName = this.state.filterItemsOn[0] === 'brand' ? 'brandUUID' : 'productCategoryUUID'

    return (
      <AddItemsPopup
        showing={this.state.showAddItemsModal}
        tableName={tableName}
        filter={this.state.filterItemsOn}
        showCheckbox={false}
        onCancel={() => this.hideModal('showAddItemsModal')}
        onClick={(rowData) => this.onTagCollectionToProduct(fieldName, rowData)}
      />
    )
  }

  getVariantItem(index) {
    const price = this.props.reduxItem.get('defaultPrice')
    const variantName = []

    this.props.reduxItem.getIn(['variants', index, 'choiceValues'], List()).forEach((item, i) => {
      const color = { color: CONSTANTS.VARIANT_COLORS[i] }
      if (i === 0) {
        variantName.push(
          <span key={item} style={color}>
            {item}
          </span>
        )
      } else {
        variantName.push(<span key={`${item}-spacer`}> / </span>)
        variantName.push(
          <span key={item} style={color}>
            {item}
          </span>
        )
      }
    })

    if (this.props.reduxItem && this.props.reduxItem.get('trackStockAtVariantLevel')) {
      return (
        <div key={`variants${index}`} className={classes.variantGroups}>
          <div className={classes.variantName}>{variantName}</div>
          <forms.SwitchField
            name={`variants.${index}.enabled`}
            label='Enabled'
            className={classes.variantItemSwitch}
          />
          <forms.CurrencyTextField
            name={`variants.${index}.price`}
            label='Price'
            placeholder={price}
            className={classes.variantItemPrice}
            localisation={this.props.localisation}
          />
          <forms.TextField
            name={`variants.${index}.sku.sku`}
            label='SKU'
            placeholder='Autogenerate'
            className={classes.variantProductCode}
          />
        </div>
      )
    }

    return (
      <div key={`variants${index}`} className={classes.variantGroups}>
        <div className={classes.variantName}>{variantName}</div>
        <forms.SwitchField
          name={`variants.${index}.enabled`}
          label='Enabled'
          className={classes.variantItemSwitch}
        />
        <forms.CurrencyTextField
          name={`variants.${index}.price`}
          label='Price'
          placeholder={price}
          className={classes.variantItemPriceFull}
          localisation={this.props.localisation}
        />
      </div>
    )
  }

  getVariantGroupItem(index) {
    const nameTextFieldName = `variantGroups.${index}.choiceName`
    const taggedFieldName = `variantGroups.${index}.choiceOptions`

    const categoryPlaceholders = ['eg. Shirt Size', 'eg. Colour', 'eg. Material']
    const optionPlaceholders = [
      'eg. Small, Med, Large',
      'eg. Red, Blue, Green',
      'eg. Leather, Cotton, Denim',
    ]

    return (
      <div key={`variantGroup${index}`} className={classes.variantGroups}>
        <div className={`${classes.variantGroupsOptions} clearfix`}>
          <forms.TextField
            label='Attribute name'
            name={nameTextFieldName}
            placeholder={categoryPlaceholders[index]}
            className={classes.variantGroupsName}
            onChange={(e) => {
              this.attributeNameUpdated(index, e)
            }}
            validators={[
              new forms.BothOrNoneValidator(
                `${taggedFieldName}.0`,
                'You must provide a name if you have values'
              ),
            ]}
          />
          <FlatButton
            label='Remove Attribute'
            type='button'
            className={classes.deleteButton}
            tabIndex='-1'
            onClick={() => {
              this.onDeleteVariantGroup(index)
            }}
          />
        </div>
        <div className='clearfix'>
          <forms.TaggedTextField
            name={taggedFieldName}
            label='Attribute value(s)'
            isFullWidth
            placeholder={optionPlaceholders[index]}
            variantIndex={index}
            index={index}
            tagsAvailable={this.tagsAvailable.bind(this)}
            errorMessage='Option limit reached'
            validators={[
              new TaggedAndNameValidator(
                nameTextFieldName,
                'You must provide some values if you have a name'
              ),
            ]}
            tagsUpdated={() => {
              this.tagsUpdated()
            }}
          />
        </div>
      </div>
    )
  }

  getVariantGroups(nextProps) {
    const props = nextProps || this.props
    return props.reduxItem.get('variantGroups', Map()).toSetSeq().toList()
  }

  renderVariants() {
    if (this.props.reduxItem.getIn(['variantGroups', '0', 'choiceOptions', '0'])) {
      return (
        <div>
          {(this.props.reduxItem.get('variants') || Map()).map((item, index) => {
            return this.getVariantItem(index)
          })}
        </div>
      )
    }

    return undefined
  }

  renderVariantGroups() {
    const variantGroups = this.getVariantGroups()
    const { size } = variantGroups

    const addVariantGroupButton =
      this.props.reduxItem.get('variantGroups', List()).size === 3 ? (
        <span />
      ) : (
        <div className={classes.variantGroupsActions}>
          <FlatButton
            label='Add New Attribute'
            className='secondary'
            type='button'
            onClick={() => this.onAddVariantGroup(size)}
          />
        </div>
      )

    return (
      <div style={{ fontSize: '13px' }}>
        Create multiple variants of your products based on size, colour, material and other
        categories. Separate options with a comma.
        {this.getVariantGroups().map((item, index) => {
          if (
            (item.get('choiceName') && item.get('choiceOptions', List()).size > 0) ||
            this.state.showVariants
          ) {
            return this.getVariantGroupItem(index)
          }

          return undefined
        })}
        {addVariantGroupButton}
      </div>
    )
  }

  getStockItems() {
    if (this.props.reduxItem && this.props.reduxItem.get('trackStockForProduct')) {
      const placeholder = this.props.reduxItem.get('variantsHaveDifferentStockAlerts', false)
        ? 'Multiple alert levels set, setting this will change all variants'
        : '0'

      return (
        <div>
          <forms.SwitchField
            name='trackStockAtVariantLevel'
            label='Track Stock at Variant Level'
            isFullWidth
            description={`If the stock for variants of this product are tracked separately, or you have separate SKU's you should enable this option.`}
            onChange={() => {
              if (this.props.reduxItem.get('trackStockAtVariantLevel')) {
                this.setState({
                  confirmStopVariantLevelTrack: true,
                })
              } else {
                this.props.reduxItem.get('variants', List()).forEach((variant, index) => {
                  this.props.dispatch(
                    fieldUpdated(this.props.formName, `variants.${index}.sku`, undefined)
                  )
                })
              }
            }}
          />
          <forms.TextField
            name='stockAlertLevel'
            label='Low Stock Alert'
            placeholder={placeholder}
            isFullWidth
            validators={[new forms.NumberValidator()]}
            description={
              <div>
                {`Alert me when stock falls below this level. `}
                <Link
                  style={{ color: CONSTANTS.YOCO_BLUE, cursor: 'pointer' }}
                  to='/business/email-notifications?notification=stock'
                >
                  Configure who gets these alerts.
                </Link>
              </div>
            }
          />
        </div>
      )
    }

    return undefined
  }

  confirmPopup() {
    return (
      <ConfirmPopup
        showing={this.state.confirmStopVariantLevelTrack}
        onCancel={() => {
          this.setState({
            confirmStopVariantLevelTrack: false,
          })
          this.props.dispatch(fieldUpdated(this.props.formName, 'trackStockAtVariantLevel', true))
        }}
        icon='icon-alert'
        title='Remove Variant Level Stock Tracking'
        iconType={classes.deleteIconType}
        actionType='destructive'
        subtitle={`
          Disabling variant level stock tracking will loose all information you have about stock on a variant level for this product. This action cannot be reversed.
        `}
        onConfirm={() => {
          this.setState({
            confirmStopVariantLevelTrack: false,
          })
        }}
      />
    )
  }

  renderOnlineProduct() {
    /*
     Because of the shitty way the forms library works, I have to do this
     all in this component, and cannot structure it better. If we
     update to using context in the forms library this could
     be refactored :(
     */
    if (this.props.features && this.props.features.getIn(['onlineProducts', 'enabled'])) {
      const onlineProduct = (this.props.reduxItem || Map()).get('onlineProduct', Map())
      const hashid = onlineProduct.get('hashid')
      const isEnabled = onlineProduct.get('isEnabled')

      return (
        <div>
          {onlineProductCard(onlineProduct, (images) => {
            this.props.dispatch(fieldUpdated(this.props.formName, 'onlineProduct.images', images))
          })}

          {hashid && isEnabled ? <ShareOnlineProduct hashid={hashid} /> : null}
        </div>
      )
    }

    return null
  }

  renderStockTracking() {
    if (this.props.features && this.props.features.getIn(['stock', 'enabled'])) {
      return (
        <div style={{ lineHeight: '30px', marginBottom: '20px' }}>
          <forms.SwitchField
            name='trackStockForProduct'
            label='Enable Stock Tracking'
            isFullWidth
          />
          {this.getStockItems()}
        </div>
      )
    }

    return undefined
  }

  processInitialData(data) {
    let newData = data
    let variantGroups = newData.get(
      'variantGroups',
      List([
        Immutable.fromJS({
          choiceName: '',
          choiceOptions: {},
        }),
      ])
    )
    variantGroups = variantGroups.sort((a, b) => {
      if (a.get('priority') > b.get('priority')) {
        return 1
      }
      if (a.get('priority') !== b.get('priority')) {
        return -1
      }

      return 0
    })

    newData = newData.set('variantGroups', variantGroups)

    const stockAlertLevel = newData.get('variantsHaveDifferentStockAlerts', false)
      ? undefined
      : newData.getIn(['stockInfo', 'alertLevel'])

    let addToStorefrontDefault
    if (!data.get('uuid')) {
      addToStorefrontDefault = true
    }

    return newData.merge(
      Map({
        addToStorefront: addToStorefrontDefault,
        imageText: newData.getIn(['tile', 'imageText']),
        imageIcon: newData.getIn(['tile', 'imageIcon']),
        tileType: 'product',
        name: newData.getIn(['tile', 'name']),
        imageFileUUID: newData.getIn(['tile', 'imageFileUUID']),
        imageURL: newData.getIn(['tile', 'imageURL']),
        backgroundColor: newData.getIn(['tile', 'backgroundColor']) || CONSTANTS.YOCO_DEFAULT_COLOR,
        defaultSku: newData.getIn(['sku', 'sku']),
        description: newData.getIn(['tile', 'description']),
        variants: newData.get('variants', List()),
        productUUID: newData.get('uuid'),
        salesTaxUUID: newData.getIn(['appliedSalesTaxes', 0, 'salesTaxUUID']),
        stockAlertLevel,
      })
    )
  }

  processData(originalData) {
    let data = originalData

    // salesTaxes must be a list
    if (data.get('salesTaxUUID')) {
      data = data.set('salesTaxUUIDs', List([data.get('salesTaxUUID')]))
    }

    let variantGroups = data.get('variantGroups', List())
    variantGroups = variantGroups
      .map((item, index) => {
        if (!item.get('priority')) {
          return item.set('priority', index)
        }
        return item
      })
      .map((group) => {
        // If we have deleted each individual variant option,
        // then we need to be sure the variant name is deleted (null) as well
        if (group.get('choiceName') && group.get('choiceOptions', List()).size === 0) {
          return null
        }
        return group
      })
      .filter((group) => {
        if (group) {
          return group.get('choiceName') && group.get('choiceName').length > 0
        }
        return group
      })

    // if we have changed the imageText for the tile we need to replace the old one
    data = data.set('imageText', data.getIn(['tile', 'imageText'], data.get('imageText')))

    // we need to delete the brand and productCategory,
    // this is not required to update the product and it also
    // causes a 413 error (Payload too large)
    // if you have 100's of products linked to a single brand / category
    data = data.delete('brand')
    data = data.delete('productCategory')

    return data.set('variantGroups', variantGroups)
  }

  getSalesTaxDescription(salesTax) {
    return `${salesTax.get('taxName')} (${(salesTax.get('taxPercentage') * 100).toFixed()}%)`
  }

  salesTaxes() {
    return (this.props.salesTaxes || Map())
      .map((salesTax) => {
        return {
          label: this.getSalesTaxDescription(salesTax),
          value: salesTax.get('uuid'),
        }
      })
      .set('default', {
        label: this.salesTaxTooltip(),
        value: undefined,
        placeholder: true,
      })
      .toSet()
      .reverse()
      .toArray()
  }

  salesTaxTooltip() {
    const defaultSalesTax = (this.props.salesTaxes || Map())
      .filter((salesTax) => salesTax.get('isEnabledByDefault'))
      .map((salesTax) => this.getSalesTaxDescription(salesTax))
      .toSeq()
      .first()
    return `Default: ${defaultSalesTax}`
  }

  render() {
    const shownRow = this.getConnectedObject()
    return (
      <div className={classes.viewPanel}>
        <forms.Form
          action='/product/'
          name={this.props.formName}
          initialData={shownRow}
          initialDataProcessor={(data) => this.processInitialData(data)}
          dataProcessor={(data) => this.processData(data)}
          onSuccess={(response) => this.onSaveProduct(response)}
        >
          <FormCard header='Basic Info' style={{ minHeight: '300px' }}>
            <div className='clearfix'>
              <forms.TextField
                name='name'
                placeholder='Name'
                required
                isFullWidth
                validators={[new forms.RequiredValidator('You must provide a name')]}
                testID={makeTestID(baseTestID, 'name')}
              />
              <div className='clearfix'>
                <forms.CurrencyTextField
                  name='defaultPrice'
                  label='Price'
                  placeholder='Price'
                  required
                  localisation={this.props.localisation}
                  validators={[
                    new forms.RequiredValidator('You must provide a price'),
                    new forms.NumberValidator('Value must be a number'),
                  ]}
                  testID={makeTestID(baseTestID, 'price')}
                />
                <forms.SelectField
                  name='salesTaxUUID'
                  label='Tax'
                  isHalfWidth
                  options={this.salesTaxes()}
                  placeholder={this.salesTaxTooltip()}
                />
              </div>
              <forms.TextField
                name='description'
                label='Description'
                isFullWidth
                placeholder='Description'
              />
              <forms.SelectButtonField
                name='brandUUID'
                label='Product Brand'
                placeholder='Select a brand'
                firebaseJoin='brand'
                onClick={this.showModal.bind(this, 'showAddItemsModal', ['brand'])}
                onDelete={() => this.removeCollectionFromProduct('brand')}
                valueLookup={() => this.collectionTileLookup('brand')}
              />
              <forms.SelectButtonField
                name='productCategoryUUID'
                label='Product Category'
                placeholder='Select a category'
                firebaseJoin='productCategory'
                onClick={() => this.showModal('showAddItemsModal', ['product_category'])}
                onDelete={() => this.removeCollectionFromProduct('productCategory')}
                valueLookup={() => this.collectionTileLookup('productCategory')}
                isHalfWidth
              />
              <FlatButton
                label='Advanced Options'
                type='button'
                className='secondary'
                onClick={() => this.showModal('showAdvancedOptionsModal')}
                testID={makeTestID(baseTestID, 'advancedOptions')}
              />
            </div>
          </FormCard>
          <FormCard
            header='Product Image'
            headerText='For best results, your image should be square and at least 1200x1200 pixels.'
            style={{ minHeight: '250px' }}
          >
            <div className={classes.iconInfo} />
            <TileEditorFormField
              tile={this.props.reduxItem}
              formName={this.props.formName}
              mouseDownHandler={this.props.mouseDownHandler}
              mouseUpHandler={this.props.mouseUpHandler}
            />
          </FormCard>
          <FormCard header='Stock' style={{ minHeight: '100px' }}>
            <div className='clearfix'>
              <forms.TextField
                name='defaultSku'
                label='Product Code (SKU)'
                placeholder='Autogenerate'
              />
              <forms.TextField
                name='defaultCostPrice'
                label='Default Cost Price'
                placeholder='0.00'
                isHalfWidth
                validators={[new forms.NumberValidator('Value must be a number')]}
                testID={makeTestID(baseTestID, 'defaultCostPrice')}
              />
            </div>
            {this.renderStockTracking()}
          </FormCard>
          <FormCard header='Variants' style={{ minHeight: '180px' }}>
            {this.renderVariantGroups()}
            {this.renderVariants()}
          </FormCard>
          {this.renderOnlineProduct()}
        </forms.Form>
        {this.redirectPopUp()}
        {this.advancedOptionsPopup()}
        {this.confirmPopup()}
        {this.addItemsPopUp()}
      </div>
    )
  }
}

const ReduxConnectedEditProductForm = connect((state, props) => ({
  reduxItem: state.forms.getIn([props.formName, 'data'], Map({})),
  localisation: localisation(state),
}))(UnconnectedEditProductForm)

export default FirebaseHandler.connect(
  ReduxConnectedEditProductForm,
  Map({
    business: {
      path: 'readOnly/store/business',
    },
    features,
    salesTaxes: {
      path: 'readOnly/store/salesTaxes',
    },
  })
)

UnconnectedEditProductForm.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
  dispatch: PropTypes.func,
  closeSplitView: PropTypes.func,
}

class TaggedAndNameValidator {
  constructor(nameFieldName, failedMessage) {
    this.nameFieldName = nameFieldName
    this.failedMessage = failedMessage
  }

  validate(value, formData) {
    const valueDefined = !(value === undefined || value.size === 0)

    const nameValue = formData.getIn(this.nameFieldName.split('.'))
    const nameValueDefined = !(nameValue === undefined || nameValue.length === 0)

    if (valueDefined !== nameValueDefined) {
      return this.failedMessage
    }

    return undefined
  }
}
