import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import FlatButton from 'components/buttons/FlatButton'
import Alert from 'components/notifications/Alert'
import {
  cancelUserUpdateRequest,
  resendUserUpdateRequest,
  selectUpdatedFields,
  selectErrorMessage,
  selectConfirmationSentTo,
  selectUUID,
  selectReferenceNumber,
} from 'redux/modules/profile'
import { connect } from 'react-redux'
import { formatCamelCaseToSentenceCase } from 'libs/utils'
import { updateUserUpdateRequest } from 'redux/modules/profile/actions'

function ConfirmationRequiredAlert({
  uuid,
  errorMessage,
  updatedFields,
  confirmationSentTo,
  referenceNumber,
  onCancelComplete,
  onCancelUserUpdateRequest,
  onResendUserUpdateRequest,
}) {
  const [isResendDisabled, setResendDisabled] = useState(false)

  const disableResendFor30Seconds = useCallback(() => {
    setResendDisabled(true)
    setTimeout(() => {
      setResendDisabled(false)
    }, 30000)
  }, [])

  const onResendClick = useCallback(() => {
    onResendUserUpdateRequest(uuid)
    disableResendFor30Seconds()
  }, [disableResendFor30Seconds, onResendUserUpdateRequest, uuid])

  const onCancelClick = useCallback(() => {
    onCancelUserUpdateRequest(uuid, onCancelComplete())
  }, [onCancelUserUpdateRequest, uuid, onCancelComplete])

  const messageType = useMemo(() => (errorMessage ? 'danger' : 'info'), [errorMessage])

  return (
    <Alert messageType={messageType} closable={false}>
      <>
        {confirmationSentTo ? (
          <b>
            A confirmation SMS has been sent to {confirmationSentTo}. Please follow the link in this
            SMS to confirm your new user details.
          </b>
        ) : (
          <b>Please check your phone for an SMS to confirm your new user details.</b>
        )}
        <br />
        <br />
        <b>Reference Number:</b> {referenceNumber}
        <br />
        {updatedFields.map((field) => (
          <>
            <b>{formatCamelCaseToSentenceCase(field[0])}: </b> {field[1]} <br />
          </>
        ))}
        <br />
      </>
      <>
        <FlatButton className='secondary' onClick={onResendClick} disabled={isResendDisabled}>
          Resend confirmation
        </FlatButton>
        <FlatButton className='dismiss' onClick={onCancelClick}>
          Cancel change
        </FlatButton>
      </>
    </Alert>
  )
}

ConfirmationRequiredAlert.propTypes = {
  uuid: PropTypes.string,
  errorMessage: PropTypes.string,
  updatedFields: PropTypes.array,
  confirmationSentTo: PropTypes.string,
  referenceNumber: PropTypes.string,
  onCancelComplete: PropTypes.func,
  onCancelUserUpdateRequest: PropTypes.func,
  onResendUserUpdateRequest: PropTypes.func,
}

ConfirmationRequiredAlert.defaultProps = {
  onCancelComplete: () => window.location.reload(),
}

const mapStateToProps = (state) => ({
  uuid: selectUUID(state),
  errorMessage: selectErrorMessage(state),
  updatedFields: selectUpdatedFields(state),
  confirmationSentTo: selectConfirmationSentTo(state),
  referenceNumber: selectReferenceNumber(state),
})

const mapDispatchToProps = (dispatch) => ({
  onUpdateUserUpdateRequest: (payload) => dispatch(updateUserUpdateRequest(payload)),
  onCancelUserUpdateRequest: (uuid, onSuccess) =>
    dispatch(cancelUserUpdateRequest(uuid, onSuccess)),
  onResendUserUpdateRequest: (uuid) => dispatch(resendUserUpdateRequest(uuid)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationRequiredAlert)
