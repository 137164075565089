import React from 'react'

import { dueInSelectOptions } from 'containers/invoices/components/constants'
import * as forms from 'libs/forms'
import { track } from 'libs/analytics'
import FormCard from 'components/cards/FormCard'
import { LineItemsField } from 'containers/invoices/components/LineItems'
import { CustomerSelectField } from 'containers/store/components/CustomerSelect'
import { makeTestID } from 'libs/utils'

import Totals from './Totals'
import { FormFieldNames } from './constants'

const baseTestID = 'invoiceForm'

const renderFormFields = (isNewInvoice) => (
  <FormCard>
    <CustomerSelectField
      name={FormFieldNames.Customer}
      label='Customer information'
      placeholder='Add a customer'
      validators={[new forms.RequiredValidator('You must provide customer information')]}
      trackingEvents={
        isNewInvoice
          ? {
              trackAddCustomerClick: () => {
                track('invoices_new_invoice_add_cusotmer_button_clicked', {
                  name: 'invoices_new_invoice_add_cusotmer_button_clicked',
                })
              },
            }
          : undefined
      }
      testID={makeTestID(baseTestID, FormFieldNames.Customer)}
      isFullWidth
    />
    <LineItemsField
      name={FormFieldNames.BillEntries}
      label='Line items'
      placeholder='Add a product'
      validators={[new forms.RequiredValidator('You must add at least one item')]}
      trackingEvents={
        isNewInvoice
          ? {
              trackAddProductClick: () => {
                track('invoices_new_invoice_add_product_clicked', {
                  name: 'invoices_new_invoice_add_product_clicked',
                })
              },
              trackAddProductSaveClick: () => {
                track('invoices_new_product_add_product_button_clicked', {
                  name: 'invoices_new_product_add_product_button_clicked',
                })
              },
            }
          : undefined
      }
      testID={makeTestID(baseTestID, FormFieldNames.BillEntries)}
      isFullWidth
    />
    <forms.SelectField
      name={FormFieldNames.DueIn}
      label='Due date'
      options={dueInSelectOptions}
      testID={makeTestID(baseTestID, FormFieldNames.DueIn)}
      isFullWidth
    />
    <forms.TextField
      name={FormFieldNames.Note}
      label='Notes and terms (optional)'
      testID={makeTestID(baseTestID, FormFieldNames.Note)}
      isFullWidth
    />
    <hr />
    <Totals />
  </FormCard>
)

export default renderFormFields
