import React from 'react'
import { withRouter } from 'react-router'
import PreventBeforeFullyOnboarded from 'containers/onboarding/PreventBeforeFullyOnboarded'
import { InvoicesList } from './components/Invoices'
import { withOnboarding } from './components/Onboarding'

const List = withRouter(withOnboarding(InvoicesList))

const InvoicesPage = () => (
  <>
    <PreventBeforeFullyOnboarded />
    <List />
  </>
)

export default InvoicesPage
