/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Immutable, { Map, List } from 'immutable'
import { Icon } from '@yoco/design-system-icons/dist/react'

import { callJSONApi } from 'libs/api'
import * as Tables from 'redux/modules/tables'
import LoadingView from 'components/loaders/LoadingView'
import Spinner from 'components/loaders/Spinner'
import { FilteredComponent } from 'libs/filters/FilteredComponent'
import CheckboxButton from 'components/buttons/CheckboxButton'
import Spacer from 'ui/layout/Spacer'

import EmptyTableView from './EmptyTableView'
import classes from './tables.module.scss'
import { getShouldTableUpdate } from './Table.utils'

export class Table extends FilteredComponent {
  constructor(props) {
    super(props)

    this.requestIndex = 0
    this.pageNumber = 0
    this.didLoadMore = false

    this.state = {}
  }

  UNSAFE_componentWillMount() {
    super.UNSAFE_componentWillMount()

    if (!this.props.waitForFilterLoad) {
      this.loadData(true)
    }

    if (!this.sortInitialized()) {
      this.props.dispatch(Tables.sortByColumn(this.props.name, this.props.defaultSort))
    }
  }

  sortInitialized(nextProps) {
    const props = nextProps || this.props

    if (!props.defaultSort) {
      return true
    }

    return props.sortedColumns && props.sortedColumns.get('sortedColumn')
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    super.UNSAFE_componentWillReceiveProps(nextProps)

    const nextSortParamsString = this.getSortParamString(nextProps)
    const currSortParamsString = this.getSortParamString()
    const filtersFinishedInitializing = this.filtersFinishedInitializing(nextProps)

    const shouldTableUpdate = getShouldTableUpdate(
      nextProps,
      this.props,
      nextSortParamsString,
      currSortParamsString,
      filtersFinishedInitializing
    )

    if (shouldTableUpdate) {
      this.loadData(true, nextProps)
    }
  }

  /* The header of the table you can define column sizes here (using classes)
   Remember to use <th> and not <td> */
  getHeader() {
    if (this.data().size === 0) {
      return undefined
    }

    if (this.props.getHeader) {
      return this.props.getHeader()
    }

    return undefined
  }

  /* Each individual row of the table, use this to render the data */
  getRow(rowData, index, groupIndex, lastSubrow, indentation) {
    if (this.props.getRow) {
      return this.props.getRow(rowData, index, groupIndex, lastSubrow, indentation)
    }

    return undefined
  }

  /* Row used to group items that are grouped together */
  getGroupHeader(group, index) {
    if (this.props.getGroupHeader) {
      return this.props.getGroupHeader(group, index)
    }

    return undefined
  }

  /* Footer displayed at the end of the table */
  getFooter() {
    /**
     * Check if there is table data to display.
     */
    if (
      this.props.table.get('hasNoData') ||
      !this.props.getFooter ||
      (this.props.footerAPI && !this.state.footerData)
    ) {
      return undefined
    }
    /**
     * Return getFooter with footer data if defined.
     */
    return this.props.getFooter(this.state.footerData)
  }

  /* End of functions you probably want to override */

  getNestedRows(row, index, indentation) {
    const group = []
    const { parentKey } = this.props
    let groupIndex = -1

    if (row.get('subItems')) {
      const lastIndex = row.get('subItems').size - 1

      if (indentation < 1) {
        group.push(this.getGroupHeader(row, index))
      } else {
        group.push(this.getRow(row, groupIndex, index, groupIndex === lastIndex, indentation))
      }
      /* eslint-disable no-param-reassign */
      row.get('subItems').forEach((subItem) => {
        subItem = subItem.set('parentUUID', row.get(parentKey))
        const parentListUUID = row.get('parentListUUID').push(row.get(parentKey))
        subItem = subItem.set('parentListUUID', parentListUUID.toSet().toList())
        /* eslint-ensable no-param-reassign */

        groupIndex += 1
        if (subItem.get('subItems')) {
          group.push(this.getNestedRows(subItem, index + 1, indentation + 1))
        } else {
          group.push(
            this.getRow(subItem, groupIndex, index, groupIndex === lastIndex, indentation + 1)
          )
        }
      })
    } else {
      group.push(this.getRow(row, groupIndex, index, 0, indentation))
    }
    return group
  }

  useSubItems(row, index) {
    const group = []
    let groupIndex = -1
    group.push(this.getGroupHeader(row, index))
    if (row.get('subItems')) {
      const lastIndex = row.get('subItems').size - 1
      row.get('subItems').forEach((subItem) => {
        groupIndex += 1
        group.push(this.getRow(subItem, groupIndex, index, groupIndex === lastIndex))
      })
    }
    return group
  }

  getRows() {
    let index = -1
    return (
      <tbody>
        {this.data().map((row) => {
          index += 1
          const parentListUUID = List()
          const indentation = 0
          const { parentKey } = this.props
          if (row && (row.get('subItems') || this.props.useSubItems)) {
            if (!parentKey || !row.get(parentKey)) {
              return this.useSubItems(row, index)
            }
            row = row.set('parentListUUID', parentListUUID)
            return this.getNestedRows(row, index, indentation)
          }
          return this.getRow(row, index, indentation)
        })}
      </tbody>
    )
  }

  getSelectAll() {
    if (this.props.table.get('hasSelectors') && !this.props.hasNoSelectAll) {
      if (this.data().size > 0) {
        return (
          <div className={classes.selectionHeader}>
            <span className={classes.selectAll}>Select All</span>
            <Spacer size='medium' isHorizontal />
            <CheckboxButton
              onClick={() => {
                this.props.dispatch(
                  Tables.selectAllItems(this.props.name, !this.props.table.get('allSelected'))
                )
              }}
              selected={this.props.table.get('allSelected', false)}
            />
          </div>
        )
      }
    }

    return undefined
  }

  getIsLastSubItem(groupIndex, index) {
    return this.data().get(index).get('subItems').size === groupIndex + 1
  }

  getOldestUUID() {
    const lastObject = this.data().last()
    if (lastObject) {
      if (lastObject.get('subItems')) {
        return lastObject.get('subItems').last().get('uuid')
      }

      return lastObject.get('uuid')
    }

    return ''
  }

  getFilterParameterString(nextProps) {
    const props = nextProps || this.props

    const serializedFilters = this.serializeFilters(props).filters
    if (serializedFilters.length > 0) {
      return `&filters=${serializedFilters}`
    }
    return serializedFilters
  }

  getSearchParameterString(nextProps) {
    const props = nextProps || this.props

    const serializedSearch = this.serializeFilters(props).search
    if (serializedSearch.length > 0) {
      return `&search=${serializedSearch}`
    }
    return ''
  }

  getSortParamString(nextProps) {
    const props = nextProps || this.props

    if (props.sortedColumns && props.sortedColumns.get('sortedColumn')) {
      const sortedColumn = props.sortedColumns.get('sortedColumn')
      return `&sort=${sortedColumn}&sortDirection=${
        props.sortedColumns.get(sortedColumn) ? 'asc' : 'desc'
      }`
    }
    return ''
  }

  getStartDate() {
    if (this.props.batch) {
      const startDate = this.props.batch.getIn(['filters', 'created', 'values', 0])
      if (startDate) {
        return moment(startDate).format('YYYY/MM/DD')
      }
    }

    return ''
  }

  getEndDate() {
    if (this.props.batch) {
      const startDate = this.props.batch.getIn(['filters', 'created', 'values', 1])
      if (startDate) {
        return moment(startDate).format('YYYY/MM/DD')
      }
    }

    return ''
  }

  getEmptyStringSuffix() {
    if (this.data().size > 0) {
      if (this.didLoadMore) {
        return 'No more results'
      }
      return ''
    }
    return 'No results found'
  }

  getEmptyString() {
    if (this.props.getEmptyString) {
      return this.props.getEmptyString()
    }
    if (this.props.waitForFilterLoad) {
      return `${this.getEmptyStringSuffix()} from ${this.getStartDate()} to ${this.getEndDate()}, you may need to adjust your filters`
    }

    return this.getEmptyStringSuffix()
  }

  filtersInitialized(nextProps) {
    if (this.props.waitForFilterLoad && nextProps.api) {
      this.loadData(true, nextProps)
    }
  }

  getPagingController() {
    if (this.props.showPaging) {
      if (this.props.table.get('busyLoading') && !this.props.table.get('busyRefreshing')) {
        return (
          <div className={classes.loadMore}>
            <Spinner blue />
          </div>
        )
      }
      if (this.props.table.get('canLoadMore') && !this.props.table.get('busyLoading')) {
        return (
          <div className={classes.loadMore} onClick={this.loadNext.bind(this)}>
            <Icon name='retry' size={24} />
            Load more
          </div>
        )
      }

      if (
        !this.props.hideNoResults &&
        this.props.showEmptyText &&
        !this.props.table.get('hasNoData') &&
        this.data().size === 0 &&
        !this.props.table.get('busyRefreshing')
      ) {
        return <div className={`${classes.loadMore} ${classes.empty}`}>{this.getEmptyString()}</div>
      }
    }

    return undefined
  }

  getTableClass() {
    let tableClass = classes.table
    if (this.props.rowsClickable) {
      tableClass += ` ${classes.tableClickable}`
    }
    return tableClass
  }

  retry() {
    this.props.dispatch(Tables.hideError(this.props.name))
    this.loadData(false)
  }

  getErrorContent() {
    if (this.props.table.get('error')) {
      return (
        <div
          className='alert alert-danger'
          style={{ cursor: 'pointer', margin: '16px' }}
          onClick={this.retry.bind(this)}
        >
          There was an error fetching results, click to retry
        </div>
      )
    }
    return <div />
  }

  getBlockClass() {
    let blockClass = classes.listBlock
    if (this.props.blockClass) {
      blockClass += ` ${this.props.blockClass}`
    }

    if (this.props.hasResponsive) {
      blockClass += ` ${classes.hideTableResponsive}`
    }

    return blockClass
  }

  getResponsiveRow(row, rowIndex) {
    if (this.props.getResponsiveRow) {
      return this.props.getResponsiveRow(row, rowIndex)
    }

    return <div />
  }

  getResponsiveContent() {
    if (this.props.hasResponsive) {
      let rowIndex = 0
      return (
        <div className={classes.responsiveTable}>
          {this.data().map((row) => {
            rowIndex += 1
            const parentListUUID = List()
            row = row.set('parentListUUID', parentListUUID)
            return this.getResponsiveRow(row, rowIndex)
          })}
        </div>
      )
    }
    return <div />
  }

  getEmptyContent() {
    if ((this.data().size === 0 || this.props.table.get('hasNoData')) && this.props.emptyTable) {
      return this.props.emptyTable
    }
    return <div />
  }

  loadData(refresh, nextProps) {
    const props = nextProps || this.props

    // If we have deleted some items, we need to adjust offset and pageSize
    // so that we don't skip items, and so that we get to a nice round number
    // If we are refreshing we don't care
    const numberOfPagesDeleted = refresh
      ? 0
      : Math.floor(props.table.get('removedCount', 0) / props.pageSize)
    const remainderItemsDeleted = refresh ? 0 : props.table.get('removedCount', 0) % props.pageSize
    // We need to select up to a full page
    const pageSize = props.pageSize + remainderItemsDeleted
    // If we have deleted more than a page worth of items
    // we should decrease our page number, so we are on the right page
    this.pageNumber -= numberOfPagesDeleted
    // We also need to change our offset down by remainder
    // because we have changed our pageSize up by remainder
    const offset = props.pageSize * this.pageNumber - remainderItemsDeleted
    // The redux handler sets removedCount back to 0 once we load more data

    this.props.dispatch(
      Tables.fetchData(props.name, props.useSubItems, refresh, pageSize, props.hasSelectors)
    )

    if (props.hasCustomData) {
      // This table uses custom data, and we should never try load data,
      // data will be passed in through the prop data
      /* TODO: figure our how we will handle filters.
          Probably the table will take the whole dataset passed into data
           and filter on that */
      this.props.dispatch(Tables.dataFetched(props.name, props.data, props.dataPath, true))
      return
    }

    if (
      !this.sortInitialized(props) ||
      (props.waitForFilterLoad && !this.filtersFinishedInitializing(props))
    ) {
      // We have not finished initializing, do not load data
      return
    }

    let url = `${props.api}${
      props.api.indexOf('?') < 0 ? '?' : '&'
    }pageSize=${pageSize}${this.getFilterParameterString(props)}`

    url += `${this.getSortParamString(props)}`
    url += `${this.getSearchParameterString(props)}`

    if (!refresh) {
      if (props.useOffset) {
        url += `&offset=${offset}`
      } else {
        url += `&oldestUUID=${this.getOldestUUID()}`
      }
    } else {
      this.pageNumber = 0
    }

    this.requestIndex += 1
    const index = this.requestIndex

    if (this.props.customApiCall) {
      this.props.customApiCall(url, props)
      return
    }

    callJSONApi(
      url,
      'GET',
      undefined,
      (response) => {
        if (index === this.requestIndex) {
          this.props.dispatch(Tables.dataFetched(props.name, response, props.dataPath, false))
          if (response && response.status === 200 && props.dataLoaded) {
            props.dataLoaded(Immutable.fromJS(response.data))
          }
        }
      },
      (prettyError) => {
        if (index === this.requestIndex) {
          this.props.dispatch(
            Tables.dataFetched(
              props.name,
              {
                status: 400,
                message: prettyError,
              },
              props.dataPath,
              false
            )
          )
        }
      }
    )

    if (props.footerAPI) {
      const parseURL = this.getFilterParameterString(props).substring(1)
      callJSONApi(`${props.footerAPI}?${parseURL}`, 'GET', undefined, (response) => {
        if (response && response.status === 200) {
          this.setState({
            footerData: response.data,
          })
        }
      })
    }
  }

  loadNext() {
    this.pageNumber += 1
    this.didLoadMore = true
    this.loadData(false)
  }

  filtersChanged(nextProps) {
    if (nextProps.api) {
      this.loadData(true, nextProps)
    }
  }

  getDataList() {
    let tableData = List()
    if (this.props.table && !this.props.table.get('hasNoData')) {
      tableData = this.props.table.get('data', List())

      if (this.props.hasCustomData && this.props.data) {
        // If the table hasCustomData then we use the data passed in as a prop
        tableData = this.props.data
      }

      tableData = tableData.concat(this.props.table.get('addedItems', List()))
      // Sort the data
      if (this.props.sortPath) {
        tableData = tableData.sortBy((row) => row.getIn(this.props.sortPath.split('.')))
      }

      // Filter the data
      if (this.props.filterData) {
        tableData = this.props.filterData(tableData)
      }
    }

    return tableData
  }

  processList(list) {
    return list
  }

  data() {
    // This fetches the data from redux, and joins it together
    const dataList = this.getDataList()
    // This preforms any additional processing on the list,
    // overwrite this method if you need some custom processing
    return this.processList(dataList)
  }

  render() {
    const isRefreshing = this.props.table.get('busyRefreshing')

    return (
      <div className={this.getBlockClass()}>
        {this.getErrorContent()}
        <div className={`${classes.tableContent} ${classes.reactTableContent}`}>
          {this.getSelectAll()}
          <table className={this.getTableClass()} style={this.props.style}>
            <thead>{this.getHeader()}</thead>
            {this.getRows()}
            <tfoot>{this.getFooter()}</tfoot>
          </table>
          {this.getPagingController()}
        </div>
        {this.getResponsiveContent()}
        {isRefreshing ? null : this.getEmptyContent()}
        <LoadingView loaderOnTable test={isRefreshing} />
      </div>
    )
  }
}

Table.defaultProps = {
  pageSize: 50,
  useOffset: false,
  useSubItems: false,
  hasResponsive: false,
  hasCustomData: false,
  hasSelectors: false,
  showEmptyText: true,
  waitForFilterLoad: true,
  emptyTable: <EmptyTableView />,
  showPaging: true,
  hasNoSelectAll: false,
  parentKey: null,
}

export default connect((state, props) => {
  const defaultSortedColumns = props.defaultSort
    ? Map({
        sortedColumn: props.defaultSort,
        [props.defaultSort]: false,
      })
    : Map()

  return {
    table: state.tables.get(props.name, Map()),
    batch: state.filters.get(props.filterName || props.name, Map()),
    sortedColumns: state.tables.getIn(['sortedColumns', props.name], defaultSortedColumns),
  }
})(Table)

Table.propTypes = {
  api: PropTypes.string,
  name: PropTypes.string,
  getHeader: PropTypes.func,
  getRow: PropTypes.func,
  emptyTable: PropTypes.element,
  pageSize: PropTypes.number,
  showPaging: PropTypes.bool,
  useOffset: PropTypes.bool,
  useSubItems: PropTypes.bool,
  hasResponsive: PropTypes.bool,
  hasCustomData: PropTypes.bool,
  customApiCall: PropTypes.func,
  showEmptyText: PropTypes.bool,
  waitForFilterLoad: PropTypes.bool,
  hasNoSelectAll: PropTypes.bool,
  rowsClickable: PropTypes.bool,
  hideNoResults: PropTypes.bool,
  blockClass: PropTypes.string,
}
