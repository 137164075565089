import React from 'react'
import { callOnboardingAPI } from 'libs/api'

const useOnboardingStatus = (checkOnboardingStatus: boolean): string | undefined => {
  const [onboardingStatus, setOnboardingStatus] = React.useState<string | undefined>(undefined)

  React.useEffect(() => {
    let isMounted = true

    if (!checkOnboardingStatus) {
      return
    }

    callOnboardingAPI(
      '/onboarding_status',
      'GET',
      {},
      (response) => {
        if (isMounted) {
          setOnboardingStatus(response.data.status)
        }
      },
      () => {
        if (isMounted) {
          setOnboardingStatus('unknown')
        }
      }
    )

    /* eslint-disable-next-line consistent-return */
    return () => {
      isMounted = false
    }
  }, [checkOnboardingStatus])

  return onboardingStatus
}

export default useOnboardingStatus
