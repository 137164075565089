import React from 'react'
import { connect } from 'react-redux'

import localisation from 'libs/localisation/localisation'
import { FilteredComponent } from 'libs/filters/FilteredComponent'
import { callJSONApi } from 'libs/api'
import InsightDataBlock from 'components/insights/InsightDataBlock'
import Insight from 'components/insights/Insight'

class SalesInsightsHeader extends FilteredComponent {
  constructor(props) {
    super(props)

    this.state = {}
  }

  filtersInitialized(nextProps) {
    this.filtersChanged(nextProps)
  }

  filtersChanged(nextProps) {
    this.setState({
      summary: null,
    })

    const url = `/sales/?filters=${this.serializeFilters(nextProps).filters}`
    callJSONApi(
      url,
      'GET',
      undefined,
      (data) => {
        this.setState({
          summary: data.data.summary,
        })
      },
      (prettyError) => {
        console.log(prettyError)
      }
    )

    const url2 = `/sales/insights/gross-sales?filters=${this.serializeFilters(nextProps).filters}`
    callJSONApi(
      url2,
      'GET',
      undefined,
      (data) => {
        this.setState({
          insightResult: data.data.insightResult,
        })
      },
      (prettyError) => {
        console.log(prettyError)
      }
    )
  }

  render() {
    let grossSalesDataBlock = <InsightDataBlock header='Gross Sales' loading />
    let numberDataBlock = <InsightDataBlock header='Number of Sales' loading />

    if (this.state.summary) {
      grossSalesDataBlock = (
        <InsightDataBlock
          header='Gross Sales'
          primaryValue={this.state.summary.grossSales}
          primaryIsCurrency
          alignRight={false}
          values={[
            {
              label: 'Total payments:',
              value: this.state.summary.total,
              isCurrency: true,
              showIfZero: true,
            },
            { label: 'Tips:', value: this.state.summary.tips * -1, isCurrency: true },
            {
              label: 'Vouchers bought*:',
              value: this.state.summary.vouchersBought * -1,
              isCurrency: true,
              showIfZero: false,
            },
            {
              label: 'Refunds:',
              value: this.state.summary.refunds,
              isCurrency: true,
              showIfZero: true,
            },
          ]}
        />
      )

      const paymentMethods = [
        {
          label: 'Cash:',
          value: this.state.summary.cashAmount,
          isCurrency: true,
        },
        {
          label: 'Vouchers redeemed:',
          value: this.state.summary.vouchersRedeemed,
          isCurrency: true,
          showIfZero: false,
        },
      ]

      const maybeAcceptsCardPayments = this.props.localisation.get('acceptsCardPayments', true)
      if (maybeAcceptsCardPayments) {
        paymentMethods.unshift({
          label: 'Card:',
          value: this.state.summary.cardAmount,
          isCurrency: true,
          showIfZero: true,
        })
      }

      numberDataBlock = (
        <InsightDataBlock
          header='Number of Sales'
          primaryValue={this.state.summary.numberOfSales}
          values={paymentMethods}
        />
      )
    }

    return (
      <div className='insights-banner'>
        <div className='insight-wrapper'>{grossSalesDataBlock}</div>
        <div className='insight-wrapper'>{numberDataBlock}</div>
        <div className='insight-wrapper insight-content-block'>
          <Insight
            insightResult={this.state.insightResult}
            title='Gross Sales'
            showCalculation={this.props.showCalculation}
            mouseDownEvent={this.props.mouseDownEvent}
            mouseUpEvent={this.props.mouseDownEvent}
          />
        </div>
      </div>
    )
  }
}

export default connect((state) => ({
  batch: state.filters.get('salesReport'),
  localisation: localisation(state),
}))(SalesInsightsHeader)
