/* eslint-disable max-classes-per-file */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { FriendlyAmount } from 'libs/formats'
import Spinner from 'components/loaders/Spinner'
import ToolTip from 'components/tooltips/Tooltips'

import Tabs from './Tabs'
import classes from './tabs.module.scss'

export class TabDashboard extends Tabs {
  getClass() {
    return classes.dashboardTabs
  }

  getTabHeader(title, insight, isActive) {
    if (insight) {
      return <TabInsight title={title} insight={insight} isActive={isActive} />
    }
    return (
      <div className={classes.tabItem}>
        <Spinner blue style={{ margin: '40px auto' }} />
      </div>
    )
  }
}

class TabInsight extends Component {
  constructor(props) {
    super(props)

    this.state = {
      insight: this.props.insight || {},
      title: this.props.title || 'Today',
    }
  }

  getArrow() {
    if (this.state.insight) {
      if (this.state.insight.isPositive) {
        return <i className='icon-arrow-up' style={{ textAlign: 'right' }} />
      }
      return <i className='icon-arrow-down' style={{ textAlign: 'right' }} />
    }
    return <div />
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  getStyle() {
    if (this.state.insight) {
      if (this.state.insight.isPositive) {
        return { color: '#4ACC6F' }
      }
      return { color: '#FE465C' }
    }
    return {}
  }

  render() {
    let periodHeader
    let periodAmount
    let insight = {}

    if (this.state.insight) {
      periodHeader = this.state.title
      periodAmount = this.state.insight.currentAmount
      insight = this.state.insight
    }

    let tabItemClass = classes.tabItem
    let shadowClass = classes.tabShadow

    let sentimentColor = null
    if (this.state.insight) {
      sentimentColor = this.state.insight.isPositive ? classes.positive : classes.negitive
    }

    if (this.props.isActive) {
      tabItemClass += ` ${classes.tabActive}`
      shadowClass = ''
    }

    return (
      <div className={tabItemClass}>
        <div className={classes.tabHeader}>
          <div className={classes.tabHeaderText}>{periodHeader}</div>
          <div className={classes.tabIcon}>
            <ToolTip insight={insight} />
          </div>
        </div>
        <div className={classes.tabLine}>___</div>
        <div className={classNames(classes.tabValue, sentimentColor)}>
          <span>
            <FriendlyAmount amount={periodAmount} />
          </span>
          {this.getArrow()}
        </div>
        <div className={shadowClass} />
      </div>
    )
  }
}

TabInsight.propTypes = {
  insight: PropTypes.object,
  title: PropTypes.string,
  isActive: PropTypes.bool,
}
