import React, { Component } from 'react'
import PropTypes from 'prop-types'

import classes from './pages.module.scss'

export default class CenteredContainerPage extends Component {
  getClassName() {
    let className = classes.pageContainer
    if (this.props.className) {
      className = `${this.props.className} ${classes.pageContainer}`
    }

    return className
  }

  render() {
    return (
      <div className={`${classes.outerBlock}`}>
        <div className={this.getClassName()}>{this.props.children}</div>
      </div>
    )
  }
}

CenteredContainerPage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}
