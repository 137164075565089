import React from 'react'
import PropTypes from 'prop-types'

import classes from 'components/loaders/LoadingView.module.scss'
import onlineClasses from 'containers/online/online.module.scss'

function FailedScreen({ image, title, subtitle, url, urlText }) {
  if (url && typeof window !== 'undefined') {
    setTimeout(function () {
      window.location.href = url
    }, 4000)
  }
  return (
    <div className={`${classes.fullScreenView} ${classes.bringToTop}`}>
      <div className={classes.center}>
        <img src={image} alt='Yoco logo' height={71} />
        <p className={onlineClasses.message}>{title}</p>
        <span style={{ marginTop: '8px' }}>
          {subtitle}
          <br />
        </span>
        <a href={url}>{urlText || ''}</a>
      </div>
    </div>
  )
}

FailedScreen.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  urlText: PropTypes.string.isRequired,
}

export default FailedScreen
