/* eslint-disable @typescript-eslint/no-shadow */
import React, { useMemo } from 'react'

import VStack from 'ui/layout/VStack'

import HeroDesignCard from './HeroDesignCard'
import ContentDesignCard from './ContentDesignCard'
import PageDesignCard from './PageDesignCard'
import { PageConfigurationProps } from './types'

const renderSection = (sectionConfig, setSectionConfig) => {
  if (sectionConfig.sectionType === 'hero') {
    return (
      <HeroDesignCard
        key='HeroDesignCard'
        sectionConfig={sectionConfig}
        setSectionConfig={setSectionConfig}
      />
    )
  }
  if (sectionConfig.sectionType === 'content') {
    return (
      <ContentDesignCard
        key='ContentDesignCard'
        sectionConfig={sectionConfig}
        setSectionConfig={setSectionConfig}
      />
    )
  }
  return null
}

const PageConfiguration: React.FunctionComponent<PageConfigurationProps> = ({
  pageConfig,
  setPageConfig,
}) => {
  const setSectionConfig = useMemo(() => {
    return (sectionIndex, sectionConfig) => {
      const setupPageSections = (sectionConfig, pageConfig) => {
        const pageConfigSections = pageConfig
          ? [
              ...pageConfig.sections.slice(0, sectionIndex),
              ...pageConfig.sections.slice(sectionIndex + 1),
            ]
          : []
        pageConfigSections.push(sectionConfig)
        return pageConfigSections
      }

      const sections = setupPageSections(sectionConfig, pageConfig)

      setPageConfig({
        ...pageConfig,
        sections,
      })
    }
  }, [pageConfig, setPageConfig])

  return (
    <VStack>
      {pageConfig &&
        pageConfig.sections.map((sectionConfig, sectionIndex) =>
          renderSection(sectionConfig, (sectionConfig) =>
            setSectionConfig(sectionIndex, sectionConfig)
          )
        )}
      {pageConfig && pageConfig.pageType === 'custom' ? (
        <PageDesignCard pageConfig={pageConfig} setPageConfig={setPageConfig} />
      ) : null}
    </VStack>
  )
}
export default PageConfiguration
