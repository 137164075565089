import React, { Component } from 'react'
import { browserHistory } from 'react-router'

import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import PaddedContent from 'ui/layout/PaddedContent'
import PageHeader from 'ui/components/PageHeader'
import Spacer from 'ui/layout/Spacer'
import typography from 'ui/typography/typography.module.scss'
import FlatButton from 'components/buttons/FlatButton'
import { callJSONApi } from 'libs/api'
import LoadingView from 'components/loaders/LoadingView'
import PreventBeforeFullyOnboarded from 'containers/onboarding/PreventBeforeFullyOnboarded'
import Alert from 'components/notifications/Alert'
import { makeTestID } from 'libs/utils'
import icon from 'assets/images/icon-payment-page@2x.png'
import { If } from 'libs/formats'

export default class OnlineActivatePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
    }
  }

  componentDidMount() {
    callJSONApi(
      '/paymentPage/',
      'GET',
      null,
      (data) => {
        const paymentPages = data.data.paymentPages || []

        if (paymentPages.length > 0) {
          const paymentPage = paymentPages[0]

          if (
            paymentPage.supportedPageTypes &&
            paymentPage.supportedPageTypes.indexOf('voucher') !== -1
          ) {
            browserHistory.push('/online/settings')
          } else {
            this.setState({
              isLoading: false,
              errorMessage: null,
            })
          }
        } else {
          this.setState({
            isLoading: false,
            errorMessage: null,
          })
        }
      },
      (prettyError) => {
        this.setState({
          isLoading: false,
          errorMessage: prettyError,
        })
      }
    )
  }

  onActivateClick() {
    callJSONApi(
      '/paymentPage/',
      'POST',
      { pageType: 'standard' },
      () => {
        browserHistory.push('/online/settings')
      },
      (prettyError) => {
        this.setState({
          isLoading: false,
          errorMessage: prettyError,
        })
      }
    )
  }

  render() {
    if (this.state.isLoading) {
      return <LoadingView />
    }

    return (
      <MaxWidthBlock>
        <PreventBeforeFullyOnboarded />
        <PaddedContent isTextCentered>
          <If condition={this.state.errorMessage}>
            <Alert
              messageType='danger'
              message={this.state.errorMessage}
              closable
              onClose={() => this.setState({ errorMessage: null })}
            />
          </If>

          <PageHeader>Online payments</PageHeader>

          <img src={icon} alt='Online payments' height='150px' />
          <Spacer size='xl' />

          <p>
            You can now easily accept online payments through any of Yoco&apos;s online products.
            <br />
            Sell your products online, send payment links and sell gift vouchers.
          </p>

          <Spacer size='large' />

          <FlatButton
            label='Activate online payments'
            className='blueBackground'
            onClick={() => this.onActivateClick()}
            testID={makeTestID('sellOnline', 'settings', 'activateOnlinePayments')}
          />

          <Spacer size='xl' />

          <p className={typography.textSmall}>
            Need help?
            <br />
            <a href='https://get.yoco.help/hc/en-za/articles/360002124357-Your-Online-Payment-Page'>
              Learn more about online payments
            </a>
          </p>
        </PaddedContent>
      </MaxWidthBlock>
    )
  }
}
