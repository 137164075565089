/* eslint-disable no-unused-expressions */
import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { submitForm } from 'redux/modules/forms'
import { FORM_NAMES } from 'redux/modules/onboarding'
import { track } from 'libs/analytics'
import PopupContentLayout from 'components/popups/OnboardingPopups/PopupContentLayout'
import BusinessInfoForm from 'components/popups/OnboardingPopups/BusinessInfoPopup/components/BusinessInfoForm'
import { connect } from 'react-redux'
import {
  selectCompanyRegistrationNumber,
  selectIsRegisteredBusiness,
} from 'redux/modules/session/selectors'
import { closeBusinessInfoPopup } from 'redux/modules/onboarding/businessDetails/actions'
import { isFlagship } from 'libs/utils'
import { wasRoutedFromApp } from 'redux/modules/routes'
import { fetchTradingAddress } from 'redux/modules/onboarding/businessDetails'
import LoadingView from '../../loaders/LoadingView'

const BusinessInfoPopup = ({
  show,
  dismissPopup,
  refreshTradingAddress,
  submitTradingAddressForm,
  submitCompanyRegistrationNumberForm,
  companyRegistrationNumber,
  isRegisteredBusiness,
  isInApp,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isTradingAddressFormVisible, setIsTradingAddressFormVisible] = useState(false)
  const [isCompanyRegistrationFormVisible, setIsCompanyRegistrationFormVisible] = useState(false)

  const dismissPopupAndWebView = useCallback(() => {
    if (isInApp && !isFlagship && window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('success')
    }
    dismissPopup()
  }, [dismissPopup, isInApp])

  useEffect(() => {
    setIsCompanyRegistrationFormVisible(isRegisteredBusiness && !companyRegistrationNumber)
  }, [isRegisteredBusiness, companyRegistrationNumber])

  useEffect(() => {
    setIsLoading(true)

    refreshTradingAddress(
      (tradingAddress) => {
        setIsTradingAddressFormVisible(!tradingAddress.get('firstLine'))

        // onSuccess
        setIsLoading(false)
      },
      () => {
        // onError
        setIsLoading(false)
        dismissPopupAndWebView()
      }
    )
  }, [dismissPopupAndWebView, refreshTradingAddress])

  const onSubmitButtonClick = () => {
    if (isTradingAddressFormVisible) {
      submitTradingAddressForm()
    }

    if (isCompanyRegistrationFormVisible) {
      submitCompanyRegistrationNumberForm()
    }

    track('portal_business_address_submit_button_clicked')
  }

  const onSuccess = () => {
    dismissPopupAndWebView()
  }

  if (isLoading) {
    return <LoadingView />
  }

  return (
    <PopupContentLayout
      headerTitle='Complete business details'
      onClosePopup={!isInApp ? dismissPopupAndWebView : undefined}
      showing={show}
      popupBodyContent={
        <BusinessInfoForm
          onSuccess={onSuccess}
          showTradingAddressForm={isTradingAddressFormVisible}
          showCompanyRegistrationForm={isCompanyRegistrationFormVisible}
        />
      }
      hideFooterContent={false}
      onFooterButtonClick={onSubmitButtonClick}
    />
  )
}

BusinessInfoPopup.propTypes = {
  show: PropTypes.bool,
  dismissPopup: PropTypes.func.isRequired,
  refreshTradingAddress: PropTypes.func.isRequired,
  submitTradingAddressForm: PropTypes.func.isRequired,
  submitCompanyRegistrationNumberForm: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  companyRegistrationNumber: PropTypes.string,
  isRegisteredBusiness: PropTypes.bool.isRequired,
  isInApp: PropTypes.bool.isRequired,
}

BusinessInfoPopup.defaultProps = {
  show: true,
  companyRegistrationNumber: undefined,
}

const mapStateToProps = (state) => ({
  companyRegistrationNumber: selectCompanyRegistrationNumber(state),
  isRegisteredBusiness: selectIsRegisteredBusiness(state),
  isInApp: wasRoutedFromApp(state),
})

const mapDispatchToProps = (dispatch) => ({
  dismissPopup: () => dispatch(closeBusinessInfoPopup()),
  refreshTradingAddress: (onSuccess, onError) => dispatch(fetchTradingAddress(onSuccess, onError)),
  submitTradingAddressForm: () => dispatch(submitForm(FORM_NAMES.BUSINESS_ADDRESS)),
  submitCompanyRegistrationNumberForm: () =>
    dispatch(submitForm(FORM_NAMES.COMPANY_REGISTRATION_NUMBER)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BusinessInfoPopup)
