import React from 'react'
import PropTypes from 'prop-types'

import * as misc from 'libs/formats'

function TransactionAmount({ transaction }) {
  let strike = false
  const style = { textAlign: 'right' }

  const transactionState = transaction.get('transactionState')
  if (['declined', 'error', 'aborted', 'refunded'].indexOf(transactionState) !== -1) {
    strike = true
  }

  let amount = transaction.get('amount', 0)
  if (transaction && transaction.get('payments')) {
    transaction.get('payments').forEach((item) => {
      amount += item.get('amount')
    })
  }

  return <misc.Amount amount={amount} strike={strike} style={style} />
}

TransactionAmount.propTypes = {
  transaction: PropTypes.object,
}

export default TransactionAmount
