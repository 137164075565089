/* eslint-disable no-param-reassign */
import { callInvoicesJSONAPI } from 'libs/api'
import store from 'redux/store'
import { dataFetched } from 'redux/modules/tables'

import { buildCustomersListUrlParams } from './utils'

export const handleCustomApiCall = (url, tableProps) => {
  const { name } = tableProps

  callInvoicesJSONAPI(`customers${buildCustomersListUrlParams(url, tableProps)}`, 'GET')
    .then(({ data: entries = [] }) => {
      // Table reducer check for duplicates using "uuid" (we don't have it)
      entries = entries.map((e) => ({
        ...e,
        uuid: e.id,
      }))

      store.dispatch(dataFetched(name, { data: { entries } }, 'entries', false))
    })
    .catch(() => {
      store.dispatch(
        dataFetched(
          name,
          {
            status: 400,
            error: 'Failed to fetch customers',
          },
          'error',
          false
        )
      )
    })
}
