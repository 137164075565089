/* eslint-disable consistent-return */
import React, { Component } from 'react'
import { Map } from 'immutable'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Icon } from '@yoco/design-system-icons/dist/react'

import { formatCurrency } from 'libs/utils'
import localisation from 'libs/localisation/localisation'
import { semanticColors } from 'ui/colors'
import Tooltip from 'ui/components/Tooltip'

class ToolTip extends Component {
  getTooltipPopoverInsight(insight) {
    const { currentAmount } = insight
    const { previousAmount } = insight
    const percentage =
      currentAmount && previousAmount
        ? ((currentAmount - previousAmount) / previousAmount) * 100
        : 0
    const value = percentage > 0 ? 'higher' : 'lower'

    let period = `day's`
    // eslint-disable-next-line default-case
    switch (insight.name) {
      case 'last7days':
        period = `7 day's`
        break
      case 'last30days':
        period = `30 day's`
        break
    }

    const percentageStyle = insight.isPositive
      ? { color: semanticColors.sentimentPositive }
      : { color: semanticColors.sentimentNegative }
    const displayAmount = formatCurrency(
      previousAmount,
      this.props.localisation.get('currency', 'ZAR'),
      this.props.localisation.get('currencyAllowsDecimals') || false
    )
    return (
      <div>
        <span style={percentageStyle}>
          <b>{`${parseFloat(percentage).toFixed(1)}%`}</b>
        </span>
        {` ${value} than the previous ${period} volume of ${displayAmount}`}
      </div>
    )
  }

  render() {
    if (this.props.insight) {
      return (
        <Tooltip content={this.getTooltipPopoverInsight(this.props.insight)}>
          <Icon name='information' size={24} />
        </Tooltip>
      )
    }
  }
}

ToolTip.propTypes = {
  insight: PropTypes.object,
  localisation: PropTypes.instanceOf(Map),
}

export default connect((state) => ({
  localisation: localisation(state),
}))(ToolTip)
