/* eslint-disable no-restricted-syntax */
export default {
  MANAGE_BUSINESS: 'userCanManageBusiness',
  MANAGE_INVENTORY: 'userCanManageInventory',
  MANAGE_REPORTS: 'userCanManageReports',
  MANAGE_STAFF: 'userCanManageStaff',
  CAN_REFUND_TRANSACTIONS: 'userCanRefundTransactions',
  CAN_REVERSE_LAST_TRANSACTION: 'userCanReverseLastTransaction',
  TRANSACT: 'userCanTransact',
  VIEW_FINANCIAL: 'userCanViewFinancial',
  VIEW_TRANSACTIONS: 'userCanViewTransactions',
  VIEW_PARTNERS: 'userCanPerformPartnerOperations',
}

function userHasOneOfRoles(user, oneOfRoles) {
  if (!oneOfRoles) {
    return true
  }

  for (const requiredRole of oneOfRoles) {
    if (user.getIn(['roles', requiredRole])) {
      return true
    }
  }

  return false
}

function userHasAllOfRoles(user, allOfRoles) {
  if (!allOfRoles) {
    return true
  }

  for (const requiredRole of allOfRoles) {
    if (!user.getIn(['roles', requiredRole])) {
      return false
    }
  }

  return true
}

export function userHasRequiredRoles(user, route) {
  const oneOfRoles =
    route.requiresOneRole && route.requiresOneRole.length > 0 ? route.requiresOneRole : undefined
  const allOfRoles =
    route.requiresAllRoles && route.requiresAllRoles.length > 0 ? route.requiresAllRoles : undefined

  // If we haven't synced user yet, return true if we don't require any roles.
  if (!user) {
    return !oneOfRoles && !allOfRoles
  }

  return userHasAllOfRoles(user, allOfRoles) && userHasOneOfRoles(user, oneOfRoles)
}
