import { useState } from 'react'

const BUSINESS_SETTINGS_ALERT_STATE = 'invoicesBusinessSettingsAlertState'
const HIDDEN_STATE = 'hidden'

function useBusinessSettingsAlert() {
  const [showAlert, setShowAlert] = useState(
    window?.yocoStorage?.getItem(BUSINESS_SETTINGS_ALERT_STATE) !== HIDDEN_STATE
  )

  const onCloseAlert = () => {
    window?.yocoStorage?.setItem(BUSINESS_SETTINGS_ALERT_STATE, HIDDEN_STATE)
    setShowAlert(false)
  }
  return [showAlert, onCloseAlert]
}

export default useBusinessSettingsAlert
