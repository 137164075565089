import { configureStore } from '@reduxjs/toolkit'
import type { PreloadedState } from '@reduxjs/toolkit'
import { routerMiddleware } from 'react-router-redux'
import { browserHistory } from 'react-router'
import * as Sentry from '@sentry/react'
import { track } from 'libs/analytics'
import rootReducer from './modules'
import {
  middleware as capitalMiddleware,
  selectors as capitalSelectors,
  capitalAnalyticsUtils,
} from './modules/capital'
import * as featureSelectors from '../libs/features'
import { selectBusinessUUID, selectIsDemoAccount } from './modules/session/selectors'
import { getTransformedState } from './modules/sentry/utils'

const getAnalyticsEventFromAction = capitalAnalyticsUtils.createAnalyticsEvent({
  selectors: {
    selectPrelimOffer: capitalSelectors.selectPrelimOffer,
    selectBusinessUUID,
    selectIsDemoAccount,
    selectCapitalOffer: capitalSelectors.selectCapitalOffer,
    selectPayoutDeductionPlan: capitalSelectors.selectCapitalOfferPayoutDeductionPlan,
    selectHasPayoutDeductionPlan: capitalSelectors.selectHasCapitalOfferPayoutDeductionPlan,
    selectFeatureFlag: featureSelectors.hasFeature,
  },
})

export type RootState = ReturnType<typeof rootReducer>

const capitalAnalyticsMiddleware = capitalMiddleware.createCapitalAnalyticsMiddleware({
  track,
  getAnalyticsEventFromAction,
})

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state: RootState) => getTransformedState(state),
})

type ConfiguredStore = ReturnType<typeof configureStore>

export const setupStore = (preloadedState?: PreloadedState<RootState>): ConfiguredStore =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    enhancers: [sentryReduxEnhancer],
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(
        routerMiddleware(browserHistory),
        capitalAnalyticsMiddleware
      ),
  })

const store = setupStore()

export default store
