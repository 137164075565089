import { formatParamsString, getParamAs, getSearchParam, getUrlParams } from 'libs/invoices/utils'

export const buildCustomersListUrlParams = (url, tableProps) => {
  const { batch } = tableProps

  const tableUrlParams = getUrlParams(url)

  const params = {
    ...getParamAs(tableUrlParams, 'pageSize', 'limit'),
    ...getParamAs(tableUrlParams, 'offset', 'offset'),
    ...getSearchParam(batch),
  }

  return formatParamsString(params)
}

export const getIsNewCustomer = (customer) => !customer.get('id')
