import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import * as forms from 'libs/forms'
import { callJSONApi } from 'libs/api'
import { clearForm, initializeForm } from 'redux/modules/forms'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import BlockHeader from 'components/headers/BlockHeader'
import FormCard from 'components/cards/FormCard'
import FlatButton from 'components/buttons/FlatButton'

import classes from './business.module.scss'

const formName = 'paymentWebhook'

class WebhooksPaymentsPage extends Component {
  buttonLabelText() {
    const buttonLabel = this.props.uuid ? `Update URL` : `Save URL`
    return buttonLabel
  }

  deleteWebhook(url) {
    callJSONApi(
      url,
      'POST',
      {},
      () => {
        this.props.dispatch(clearForm(formName))
        console.log('Webhook successfully deleted')
      },
      (error) => {
        console.warn('Failed to delete webhook: ', error)
      }
    )
  }

  refreshPageOnSaveUpdate(url) {
    callJSONApi(
      url,
      'GET',
      {},
      (response) => {
        if (response.status === 200) {
          this.props.dispatch(initializeForm(formName, response.data.webhook))
        }
      },
      (error) => {
        console.warn('Failed to fetch updated data: ', error)
      }
    )
  }

  pageInfoText() {
    return (
      <div>
        <h2>Request</h2>
        <p>We will POST a JSON object to your endpoint containing to following:</p>
        <h4>Timestamp</h4>
        <p>An ISO datetime</p>
        <h4>URL</h4>
        <p>The URL for your endpoint</p>
        <h4>UUID</h4>
        <p>Transaction UUID</p>
        <h4>Payment Method</h4>
        <p>
          The payment method for the transaction. Either
          <span className={classes.webhookInfo}> credit_card</span>,
          <span className={classes.webhookInfo}> cash </span>
          or
          <span className={classes.webhookInfo}> custom </span>
        </p>
        <h4>Transaction State</h4>
        <p>The state of the transaction, which can be one of the following:</p>
        <p className={classes.webhookInfo}>approved</p>
        <p className={classes.webhookInfo}>declined</p>
        <p className={classes.webhookInfo}>refunded</p>
        <h4>Amounts</h4>
        <p>An object with containing the following:</p>
        <p className={classes.webhookInfo}>amount</p>
        <p className={classes.webhookInfo}>vatAmount</p>
        <p className={classes.webhookInfo}>tipAmount</p>
        <h2>Retries</h2>
        <p>
          Your endpoint must respond with 200 (OK). If not, we will retry the request for up to 48
          hours, with an exponential delay between each retry
        </p>
      </div>
    )
  }

  render() {
    return (
      <MaxWidthBlock>
        <BlockHeader title='Payment Webhooks' />
        <p>
          This page allows you to specify the web URL that you would like Yoco to send transaction
          data to, as they happen.
        </p>
        <FormCard header='Web URL'>
          <forms.Form
            fetchAPI='/webhooks/'
            action={`/webhooks/${this.props.uuid}`}
            persistData
            dataProcessor={(data) => data.set('webhookType', 'transactionEvents')}
            onSuccess={(response) => {
              if (response.status === 200) {
                this.refreshPageOnSaveUpdate('/webhooks/')
              }
            }}
            name={formName}
          >
            <div className='clearfix'>
              <div className={classes.actionField}>
                <forms.TextField
                  name='url'
                  label='URL'
                  placeholder='Please enter your URL here'
                  type='text'
                  validators={[new forms.WebURLValidator()]}
                />
                <forms.TextField
                  className='hidden'
                  name='uuid'
                  label='UUID'
                  placeholder='Please enter your URL here'
                  type='text'
                />
                <FlatButton
                  label={this.buttonLabelText()}
                  className={`blueBackground ${classes.button}`}
                  type='submit'
                />
                <FlatButton
                  label='Delete URL'
                  className={`blueBackground ${classes.button}`}
                  type='button'
                  onClick={() => this.deleteWebhook(`/webhooks/${this.props.uuid}/delete`)}
                  disabled={!this.props.uuid}
                />
              </div>
            </div>
          </forms.Form>
        </FormCard>
        {this.pageInfoText()}
      </MaxWidthBlock>
    )
  }
}

WebhooksPaymentsPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  uuid: PropTypes.string,
}

export default connect((state) => ({
  uuid: state.forms.getIn(['paymentWebhook', 'data', 'uuid'], ''),
}))(WebhooksPaymentsPage)
