import React from 'react'
import { Route, IndexRedirect } from 'react-router'

import AccountPage from './AccountPage'
import LoginPage from './LoginPage'
import LogoutPage from './LogoutPage'
import ForgotPasswordPage from './ForgotPasswordPage'
import ResetPasswordPage from './ResetPasswordPage'

function renderRoute(route) {
  return (
    <Route path={route.path} key={route.path} component={route.component}>
      {route.indexRedirect}
      {(route.routes || []).map((childRoute) => {
        return renderRoute(childRoute)
      })}
    </Route>
  )
}

export default function getAccountRoutes() {
  return <Route>{renderRoute(getRoutes())}</Route>
}

function getRoutes() {
  return {
    path: 'account',
    component: AccountPage,
    indexRedirect: <IndexRedirect to='/profile' />,
    routes: [
      {
        path: 'login',
        component: LoginPage,
      },
      {
        path: 'logout',
        component: LogoutPage,
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordPage,
      },
      {
        path: 'reset-password/:token',
        component: ResetPasswordPage,
      },
    ],
  }
}
