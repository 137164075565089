/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'

import classes from './styles.module.scss'

function PlacesFieldResults({
  autoCompleteOptions,
  setSelectedAddress,
  placesService,
  updateAddressFields,
  clearAutoCompleteResults,
}) {
  return (
    <div className={classes.placesResultsContainer}>
      {autoCompleteOptions.map((address, index) => (
        <div
          className={classes.placesResults}
          key={`place-options-${index}`}
          onClick={() => {
            setSelectedAddress(
              address,
              placesService,
              updateAddressFields,
              clearAutoCompleteResults
            )
          }}
        >
          <div className={classes.mainText}>{address.structured_formatting.main_text}</div>
          <div className={classes.secondaryText}>
            {address.structured_formatting.secondary_text}
          </div>
        </div>
      ))}
    </div>
  )
}

PlacesFieldResults.propTypes = {
  autoCompleteOptions: PropTypes.array,
  setSelectedAddress: PropTypes.func,
  placesService: PropTypes.object,
  updateAddressFields: PropTypes.func,
  clearAutoCompleteResults: PropTypes.func,
}

export default PlacesFieldResults
