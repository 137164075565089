import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import layout from './layout.module.scss'
import Spacer from './Spacer'

export default function HStack({
  children,
  spacing = 'medium',
  alignItems = layout.itemsCenter,
  className,
}) {
  return (
    <div className={classnames(className, layout.flex, alignItems)}>
      {React.Children.map(children, (child, index) => {
        return (
          <>
            {index !== 0 ? <Spacer isHorizontal size={spacing} /> : null}
            {child}
          </>
        )
      })}
    </div>
  )
}

HStack.propTypes = {
  spacing: PropTypes.oneOf(['xs', 'small', 'medium', 'large', 'xl']),
  children: PropTypes.node,
  className: PropTypes.string,
  alignItems: PropTypes.string,
}
