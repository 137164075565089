import Immutable, { Map } from 'immutable'

const ACTIONS = {
  SET_ROUTE_BADGE: 'SET_ROUTE_BADGE',
  PUT_ROUTE_BADGES: 'PUT_ROUTE_BADGES',
  CLEAR_ROUTE_BADGE: 'CLEAR_ROUTE_BADGE',
}

export function putRoutesBadges(routesBadgesData) {
  return {
    type: ACTIONS.PUT_ROUTE_BADGES,
    routesBadgesData,
  }
}

export function setRouteBadge(routeName, badgeData) {
  return {
    type: ACTIONS.SET_ROUTE_BADGE,
    routeName,
    badgeData,
  }
}

export function clearRouteBadge(routeName) {
  return {
    type: ACTIONS.CLEAR_ROUTE_BADGE,
    routeName,
  }
}

const initialState = Map()

export default function RouteBadges(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.SET_ROUTE_BADGE:
      return state.set(action.routeName.toLowerCase(), action.badgeData)
    case ACTIONS.CLEAR_ROUTE_BADGE:
      return state.delete(action.routeName.toLowerCase())
    case ACTIONS.PUT_ROUTE_BADGES:
      return state.merge(Immutable.fromJS(action.routesBadgesData).mapKeys((k) => k.toLowerCase()))
    default:
      return state
  }
}
