import React, { useMemo, useState } from 'react'

import classes from 'libs/modals/popups.module.scss'

import useReferralStore from '../store'
import facebook from '../images/facebook.svg'
import linkedin from '../images/linkedin.svg'
import whatsapp from '../images/whatsapp.svg'
import twitter from '../images/twitter.svg'
import email from '../images/email.svg'

import ShareButton from './ShareButton'
import ReferralEmailPopup from '../../../components/popups/ReferralEmailPopup'

const useStore = () => ({
  referralLink: useReferralStore((state) => state?.referralLink),
  referralCode: useReferralStore((state) => state.referralCode),
})

function ShareCard() {
  const { referralLink, referralCode } = useStore()
  const [showShareEmailModal, setShowShareEmailModal] = useState(false)

  const shareLocations = useMemo(() => {
    const facebookURL = `https://www.facebook.com/dialog/feed?app_id=2144063878980530&link=${referralLink}&picture=https%3A%2F%2Fprismic-io.s3.amazonaws.com%2Fyoco%252F22978cf3-0e1e-4da6-be2c-d150bb11cfe2_image-fb.png&name=Join%20me%20in%20using%20Yoco%20to%20accept%20card%20payments.&caption=%20&description=Yoco%20has%20taken%20my%20business%20to%20the%20next%20level!%20Sign%20up%20with%20my%20link%20to%20get%20a%20card%20machine%20and%20grow%20with%20Yoco&redirect_uri=http%3A%2F%2Fwww.facebook.com%2F`
    const twitterURL = `http://twitter.com/intent/tweet?text=I+use+%40Yoco_ZA+and+I%E2%80%99m+not+going+to+lie%2C+they%E2%80%99re+awesome.+%F0%9F%98%8F+If+you%E2%80%99re+keen+to+try+them+too%2C+use+my+referral+link+and+you%E2%80%99ll+get+R100+off+when+you+buy+a+Yoco+card+machine.%20${referralLink}`
    const emailURL = `mailto:?subject=Get%20R100%20off%20your%20card%20machine.%20Coz%20Yoco%20loves%20you💙&body=Hey%2C%0A%0AJT%20said%20it%20best%20%E2%80%93%20%E2%80%9CWhat%20goes%20around%20comes%20back%20around%E2%80%A6%E2%80%9D
    %0A%0AThat%E2%80%99s%20why%20we%E2%80%99ve%20made%20it%20possible%20for%20our%20merchants%20to%20pay%20it%20forward.
    %0A%0AYou%E2%80%99ve%20been%20referred%20to%20Yoco,%20and%20for%20that%20reason,%20you%20get%20R100%20off%20your%20Yoco%20card%20machine!
    %0A%0ASign%20up%20with%20${referralCode}%20link%20here%20to%20claim%20your%20discount.%0A%0A${referralLink} %0A%0AYoco,%0A%0ALet%E2%80%99s%20Grow.`
    const linkedinURL = `https://www.linkedin.com/sharing/share-offsite/?url=${referralLink}`
    const whatsappUrl = `https://api.whatsapp.com/send/?text=I+use+%40Yoco_ZA+and+I%E2%80%99m+not+going+to+lie%2C+they%E2%80%99re+awesome.+%F0%9F%98%8F+If+you%E2%80%99re+keen+to+try+them+too%2C+use+my+referral+link+and+you%E2%80%99ll+get+R100+off+when+you+buy+a+Yoco+card+machine.%20${referralLink}&app_absent=0`

    return [
      {
        text: 'Post Tweet',
        icon: twitter,
        colour: '#61A4DB',
        url: twitterURL,
      },
      {
        text: 'Post to Facebook',
        icon: facebook,
        colour: '#435995',
        url: facebookURL,
      },
      {
        text: 'Send Email',
        icon: email,
        colour: '#018668',
        url: emailURL,
        onClick: () => {
          setShowShareEmailModal(true)
        },
      },
      {
        text: 'Share Update',
        icon: linkedin,
        colour: '#3E6FB4',
        url: linkedinURL,
      },
      {
        text: 'WhatsApp',
        icon: whatsapp,
        colour: '#4ED35D',
        url: whatsappUrl,
      },
    ]
  }, [referralCode, referralLink])

  return (
    <>
      {shareLocations.map((shareLocation) => {
        return (
          <ShareButton
            key={shareLocation.text}
            text={shareLocation.text}
            icon={shareLocation.icon}
            colour={shareLocation.colour}
            url={shareLocation.url}
            onClick={shareLocation.onClick}
          />
        )
      })}
      <ReferralEmailPopup
        customStyle={classes.emailPopupInside}
        showing={showShareEmailModal}
        onCancel={() => setShowShareEmailModal(false)}
        onSend={() => setShowShareEmailModal(false)}
      />
    </>
  )
}

export default ShareCard
