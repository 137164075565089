import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as formats from 'libs/formats'

import classes from './home.module.scss'

export default class SalesData extends Component {
  getValue() {
    return this.props.value === 'amount' ? (
      <formats.Amount
        amount={this.props.insight}
        style={{ textAlign: 'left' }}
        showRed={this.props.insight === 0}
      />
    ) : (
      this.props.insight
    )
  }

  render() {
    return (
      <div className={classNames([classes.salesData, this.props.className])}>
        <div className={classes.salesDataDetails}>
          <div className={classes.salesDataHeader}>{this.props.header}</div>
          <div className={classes.salesDataDivider}>__</div>
          <div className={classes.salesDataValue}>{this.getValue()}</div>
        </div>
      </div>
    )
  }
}

SalesData.propTypes = {
  header: PropTypes.string,
  value: PropTypes.string,
  insight: PropTypes.number,
  className: PropTypes.string,
}
