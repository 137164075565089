import React from 'react'

import SubHeading from 'ui/components/SubHeading'
import VStack from 'ui/layout/VStack'
import styles from './CapitalFrequentlyAskedQuestionsBlock.module.scss'
import { FAQS } from './data'
import CapitalFrequentlyAskedQuestionBlock from '../CapitalFrequentlyAskedQuestionBlock'

const CapitalFrequentlyAskedQuestionsBlock: React.FunctionComponent = () => {
  return (
    <section data-testid='CapitalFrequentlyAskedQuestionsBlock' className={styles.section}>
      <SubHeading>Some frequently asked questions</SubHeading>
      <div className={styles.faqContainer}>
        <VStack spacing='xs'>
          {FAQS.map((faq) => (
            <CapitalFrequentlyAskedQuestionBlock
              title={faq.title}
              description={faq.description}
              key={faq.title}
            />
          ))}
        </VStack>
      </div>
    </section>
  )
}

export default CapitalFrequentlyAskedQuestionsBlock
