import React from 'react'
import PropTypes from 'prop-types'

import MaxWidthBlock from 'components/blocks/MaxWidthBlock'

import ShopIFrame from './ShopIFrame'

function BuyCardMachinePage({ routeParams }) {
  const uuid = routeParams?.uuid || ''

  return (
    <MaxWidthBlock>
      <ShopIFrame cartId={uuid} />
    </MaxWidthBlock>
  )
}

BuyCardMachinePage.propTypes = {
  routeParams: PropTypes.shape({
    uuid: PropTypes.string,
  }),
}

BuyCardMachinePage.defaultProps = {
  routeParams: {
    uuid: '',
  },
}

export default BuyCardMachinePage
