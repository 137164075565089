/* eslint-disable react/no-unused-state */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-classes-per-file */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import S from 'string'

import * as forms from 'libs/forms'
import FlatButton from 'components/buttons/FlatButton'
import { callJSONApi } from 'libs/api'
import Spinner from 'components/loaders/Spinner'
import CheckboxButton from 'components/buttons/CheckboxButton'
import { showMessage, showLoader, hideLoader } from 'redux/modules/notifications'
import { clearForm } from 'redux/modules/forms'
import typography from 'ui/typography/typography.module.scss'

import classes from './business.module.scss'

class UnconnectedPreferenceForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      periods: props.periods || ['daily', 'weekly', 'monthly'],
    }
  }

  onFullOptionChanged(option) {
    this.setState({
      fullOption: option,
    })
  }

  getContent() {
    if (this.state.preferences) {
      let selfPreferenceLine = (
        <PreferenceLine
          preference={this.state.preferences.selfPreference}
          preferenceClicked={this.preferenceClicked.bind(this)}
          mouseDownEvent={this.props.mouseDownEvent}
          mouseUpEvent={this.props.mouseUpEvent}
          periods={this.state.periods}
        />
      )

      if (!this.state.preferences.selfPreference.email) {
        selfPreferenceLine = (
          <div className={classes.empty}>
            You do not have an email address configured. Please add an email address to{' '}
            <a href='/account/'>your profile</a> in order to receive this report.
          </div>
        )
      }

      return (
        <div className={classes.form}>
          <div className={classes.fieldset}>
            <div className={`${classes.formField} ${classes.preferencePanel}`}>
              <div className={classes.fieldLabel}>Send me this report</div>
              {selfPreferenceLine}
            </div>
            {this.getManagePanel()}
          </div>
        </div>
      )
    }

    this.loadDetails()
    return (
      <div>
        <Spinner spinnerName='pulse' noFadeIn />
      </div>
    )
  }

  getManagePanel() {
    this.hideValues = this.getUUIDHideList()
    if (this.state.preferences.canManageReports) {
      let emptyField = <span />
      if (this.state.preferences.preferences.length < 1) {
        emptyField = (
          <div className={classes.empty}>
            Once you add others to this report you will be able to manage them from here.
          </div>
        )
      }
      return (
        <div className={`${classes.formField} ${classes.preferencePanel}`}>
          <div className={classes.fieldLabel}>Send others this report</div>
          {this.state.preferences.preferences.map((preference) => {
            return (
              <PreferenceLine
                preference={preference}
                preferenceClicked={this.preferenceClicked.bind(this)}
                key={preference.email}
                mouseDownEvent={this.props.mouseDownEvent}
                mouseUpEvent={this.props.mouseUpEvent}
                periods={this.state.periods}
              />
            )
          })}
          {emptyField}
          <h2 className={typography.h2}>Add another person</h2>
          <div className='clearfix'>
            <div className={classes.formStyle}>
              <forms.Form
                onSubmit={(data) => {
                  this.addEmail(data, `staffMemberNotification${this.props.preferenceType}`)
                }}
                fullOption={this.state.fullOption}
                name={`staffMemberNotification${this.props.preferenceType}`}
                showHeader={false}
                errorBelowInput
              >
                <div className={classes.actionField}>
                  <forms.SelectField
                    name='userUUID'
                    label='Send to a staff member'
                    placeholder='Find a staff member'
                    searchable={this.props.searchable}
                    api='/emailPreferences/usersList'
                    onFullOptionChanged={this.onFullOptionChanged.bind(this)}
                    testDisabled={this.testDisabled.bind(this)}
                    className={classes.field}
                    validators={[
                      new forms.RequiredValidator('Please select a user'),
                      new forms.UniqueValidator(
                        this.getUserList(),
                        'This user is already subscribed to this report'
                      ),
                    ]}
                  />
                  <FlatButton
                    label='Add staff member'
                    className={`tertiary ${classes.button}`}
                    type='submit'
                  />
                </div>
              </forms.Form>
            </div>
            <div className={classes.formStyle}>
              <forms.Form
                onSubmit={(data) => {
                  this.addEmail(data, `emailNotification${this.props.preferenceType}`)
                }}
                name={`emailNotification${this.props.preferenceType}`}
                showHeader={false}
                errorBelowInput
              >
                <div className={classes.actionField}>
                  <forms.EmailField
                    placeholder='account@host.com'
                    name='email'
                    className={classes.field}
                    validators={[
                      new forms.RequiredValidator('Please provide an email address'),
                      new forms.EmailValidator('You must provide a valid email address'),
                      new forms.UniqueValidator(
                        this.getEmailList(),
                        'This email is already subscribed to this report'
                      ),
                    ]}
                  />
                  <FlatButton
                    label='Add email address'
                    className={`tertiary ${classes.button}`}
                    type='submit'
                  />
                </div>
              </forms.Form>
            </div>
          </div>
        </div>
      )
    }
  }

  getEmailList() {
    const list = []

    for (const preference of this.state.preferences.preferences) {
      if (preference.email) {
        list.push(preference.email)
      }
    }

    return list
  }

  getUUIDHideList() {
    const list = []
    for (const preference of this.state.preferences.preferences) {
      list.push(preference.userUUID)
    }

    if (this.state.preferences.selfPreference) {
      list.push(this.state.preferences.selfPreference.userUUID)
    }
    return list
  }

  getUserList() {
    const list = [this.state.preferences.selfPreference.userUUID]
    for (const preference of this.state.preferences.preferences) {
      if (preference.userUUID) {
        list.push(preference.userUUID)
      }
    }

    return list
  }

  getExampleButton() {
    if (this.props.hasShowExample) {
      if (this.props.exampleReport) {
        return (
          <a
            onClick={this.closeExample.bind(this)}
            onMouseDown={this.props.mouseDownEvent}
            onMouseUp={this.props.mouseUpEvent}
          >
            Hide the example.
          </a>
        )
      }

      return <a onClick={this.showExample.bind(this)}>See an example.</a>
    }
  }

  setPreferenceState(periodKey, userUUID, email, selected) {
    if (this.state.preferences.selfPreference.userUUID === userUUID) {
      // eslint-disable-next-line react/no-access-state-in-setstate
      const newPreference = this.state.preferences.selfPreference
      newPreference[periodKey] = selected
      this.setState({
        selfPreference: newPreference,
      })
    } else {
      this.setState((currentState) => {
        for (const preference of currentState.preferences.preferences) {
          if (preference.userUUID === userUUID && preference.email === email) {
            preference[periodKey] = selected
          }
        }

        return currentState
      })
    }
  }

  showExample() {
    this.props.dispatch(showLoader())
    callJSONApi(
      `/emailPreferences/${this.props.preferenceType}/example`,
      'GET',
      {},
      (response) => {
        this.props.dispatch(hideLoader())
        this.props.exampleFetched(response.data.report)
      },
      (prettyError) => {
        this.props.dispatch(hideLoader())
        this.props.dispatch(showMessage(`Failed to retrieve example: ${prettyError}`, 'danger'))
      }
    )
  }

  closeExample() {
    this.props.closeExample()
  }

  loadDetails() {
    callJSONApi(
      `/emailPreferences/${this.props.preferenceType}/details`,
      'GET',
      {},
      (response) => {
        this.setState({
          preferences: response.data,
        })
      },
      (prettyError) => {
        this.props.dispatch(showMessage(`Failed to retrieve settings: ${prettyError}`, 'danger'))
      }
    )
  }

  uploadPreferenceChange(periodKey, userUUID, email, selected) {
    this.props.dispatch(showLoader())
    callJSONApi(
      '/emailPreferences/update',
      'POST',
      {
        selected,
        emailAddress: email,
        emailFrequency: periodKey,
        emailType: this.props.preferenceType,
        userUUID,
      },
      (response) => {
        this.props.dispatch(hideLoader())
        if (response.status !== 200) {
          this.props.dispatch(showMessage(`Error updating settings: ${response.message}`, 'danger'))
          this.setPreferenceState(periodKey, userUUID, email, !selected)
        }
      },
      (prettyError) => {
        this.props.dispatch(hideLoader())
        this.props.dispatch(showMessage(`Error updating settings: ${prettyError}`, 'danger'))
        this.setPreferenceState(periodKey, userUUID, email, !selected)
      }
    )
  }

  preferenceClicked(periodKey, userUUID, email, selected) {
    this.setPreferenceState(periodKey, userUUID, email, selected)
    if (this.state.preferences.selfPreference.userUUID === userUUID) {
      this.props.dispatch(showLoader())

      callJSONApi(
        '/emailPreferences/self',
        'POST',
        {
          selected,
          emailFrequency: periodKey,
          emailType: this.props.preferenceType,
        },
        (response) => {
          this.props.dispatch(hideLoader())
          if (response.status !== 200) {
            this.props.dispatch(
              showMessage(`Error updating settings: ${response.message}`, 'danger')
            )
            this.setPreferenceState(periodKey, userUUID, email, !selected)
          }
        },
        (prettyError) => {
          this.props.dispatch(hideLoader())
          this.props.dispatch(
            showMessage(`Failed to update your settings: ${prettyError}`, 'danger')
          )
          this.setPreferenceState(periodKey, userUUID, email, !selected)
        }
      )
    } else {
      this.uploadPreferenceChange(periodKey, userUUID, email, selected)
    }
  }

  addEmail(data, formName) {
    let preference
    if (this.state.fullOption && this.state.fullOption.uuid === data.get('userUUID')) {
      preference = {
        email: this.state.fullOption.email,
        userUUID: this.state.fullOption.uuid,
        name: this.state.fullOption.onlyName,
      }
    } else {
      preference = { email: data.get('email') }
    }

    for (const periodKey of this.state.preferences.defaults) {
      preference[periodKey] = true
      this.uploadPreferenceChange(periodKey, preference.userUUID, preference.email, true)
    }

    this.setState((currentState) => {
      currentState.preferences.preferences.push(preference)

      return currentState
    })

    this.props.dispatch(clearForm(formName))
  }

  testDisabled(option) {
    if (!option.email) {
      return true
    }
    if (this.hideValues.indexOf(option.uuid) > -1) {
      return true
    }
    return false
  }

  render() {
    return (
      <div className={classes.preferencesContainer}>
        <div className={classes.description}>
          {this.props.description} {this.getExampleButton()}
        </div>
        {this.getContent()}
      </div>
    )
  }
}

UnconnectedPreferenceForm.defaultProps = {
  hasShowExample: true,
}

UnconnectedPreferenceForm.propTypes = {
  periods: PropTypes.string,
  preferenceType: PropTypes.string,
  closeExample: PropTypes.func,
  exampleFetched: PropTypes.func,
  hasShowExample: PropTypes.func,
  exampleReport: PropTypes.any,
  mouseDownEvent: PropTypes.func,
  mouseUpEvent: PropTypes.func,
  searchable: PropTypes.bool,
  description: PropTypes.string,
  dispatch: PropTypes.func,
}

const PreferenceForm = connect(() => ({}))(UnconnectedPreferenceForm)

export default PreferenceForm

class PreferenceLine extends Component {
  getName() {
    if (this.props.preference.name) {
      return `${this.props.preference.name} (${this.props.preference.email})`
    }
    return this.props.preference.email
  }

  getPeriodContainer(periodKey) {
    return (
      <div
        key={periodKey}
        className={classes.periodContainer}
        onMouseDown={this.props.mouseDownEvent}
        onMouseUp={this.props.mouseUpEvent}
      >
        <CheckboxButton
          selected={this.props.preference[periodKey]}
          onClick={this.optionClicked.bind(this, periodKey)}
        />
        <span> {S(periodKey).humanize().s} </span>
      </div>
    )
  }

  optionClicked(periodKey) {
    if (this.props.preferenceClicked) {
      this.props.preferenceClicked(
        periodKey,
        this.props.preference.userUUID,
        this.props.preference.email,
        !this.props.preference[periodKey]
      )
    }
  }

  render() {
    return (
      <div className={`${classes.preferenceLine} clearfix`}>
        <div className={classes.name}>{this.getName()}</div>
        {this.props.periods.map((periodKey) => {
          return this.getPeriodContainer(periodKey)
        })}
      </div>
    )
  }
}

PreferenceLine.propTypes = {
  periods: PropTypes.string,
  preference: PropTypes.object,
  mouseDownEvent: PropTypes.func,
  mouseUpEvent: PropTypes.func,
  preferenceClicked: PropTypes.func,
}
