import React from 'react'
import PropTypes from 'prop-types'

import classes from './Spinner.module.scss'

const getClassName = (blue, white, spinnerClassName) => {
  let className = classes.circle
  if (blue) {
    className = `${className} ${classes.blue}`
  }
  if (white) {
    className = `${className} ${classes.white}`
  }
  if (spinnerClassName) {
    className = `${className} ${spinnerClassName}`
  }
  return className
}

function Spinner({ blue, white, className: spinnerClassName, style }) {
  return <div className={getClassName(blue, white, spinnerClassName)} style={style} />
}

Spinner.propTypes = {
  blue: PropTypes.bool,
  white: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default Spinner
