import React from 'react'
import propTypes from 'prop-types'

import { EmptyTableView, Table } from 'libs/tables'
import { makeTestID } from 'libs/utils'

import { TableName, TablePageSize } from './constants'
import { CustomersTableHeader, CustomersTableRow } from './Table.components'
import { handleCustomApiCall } from './Table.utils'

const getCustomersTableHeader = () => <CustomersTableHeader />

const getCustomersTableRow = (onClick) =>
  // eslint-disable-next-line react/display-name
  function (row, index) {
    return (
      <CustomersTableRow
        index={index}
        key={index}
        onClick={onClick}
        row={row}
        testID={makeTestID('customers-table', 'row', index.toString())}
      />
    )
  }

function CustomersTable({ onRowClick }) {
  return (
    <Table
      api='/'
      name={TableName}
      waitForFilterLoad
      rowsClickable
      pageSize={TablePageSize}
      useOffset
      customApiCall={handleCustomApiCall}
      emptyTable={<EmptyTableView title='No customers found' />}
      getHeader={getCustomersTableHeader}
      getRow={getCustomersTableRow(onRowClick)}
    />
  )
}

CustomersTable.propTypes = {
  onRowClick: propTypes.func,
}

export default CustomersTable
