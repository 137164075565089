import React, { useCallback } from 'react'

import HStack from 'ui/layout/HStack'
import CheckboxButton from 'components/buttons/CheckboxButton'

import { makeTestID } from 'libs/utils'
import DesignCard from './DesignCard'
import { PageDesignCardProps } from './types'

const PageDesignCard: React.FunctionComponent<PageDesignCardProps> = ({
  pageConfig,
  setPageConfig,
}) => {
  const toggleIsEnabled = useCallback(() => {
    setPageConfig({ ...pageConfig, isEnabled: !pageConfig.isEnabled })
  }, [pageConfig, setPageConfig])

  return (
    <DesignCard testID={makeTestID('onlineStore', 'designPage', 'pageDesignCard')} title='About'>
      <HStack>
        <CheckboxButton selected={pageConfig.isEnabled} onClick={toggleIsEnabled} />
        <span>Show page</span>
      </HStack>
    </DesignCard>
  )
}

export default PageDesignCard
