import PropTypes from 'prop-types'
import React from 'react'

function PageHeader({ children }) {
  return (
    <div>
      <h1>{children}</h1>
    </div>
  )
}

export default PageHeader

PageHeader.propTypes = {
  children: PropTypes.node,
}
