import React from 'react'
import PropTypes from 'prop-types'

import { isFlagship } from 'libs/utils'

import classes from './styles.module.scss'

function PopupBody({ children }) {
  return (
    <div className={isFlagship ? classes.flagshipPopupBodyContainer : classes.popupBodyContainer}>
      {children}
    </div>
  )
}

PopupBody.propTypes = {
  children: PropTypes.node,
}

export default PopupBody
