import React, { useState } from 'react'
import CapitalApplicationProgress from 'components/capital/partials/CapitalApplicationProgress'
import CapitalHelpVideo from 'components/capital/partials/CapitalHelpVideo'
import CapitalHelpMessage from 'components/capital/partials/CapitalHelpMessage'
import CapitalHeaderBlock from 'components/capital/partials/CapitalHeaderBlock'
import CenteredContainerPage from 'components/pages/CenteredContainerPage'
import TitleCard from 'components/cards/TitleCard'
import ConfirmPopup from 'components/popups/ConfirmPopup'
import FlatButton from 'components/buttons/FlatButton'
import {
  offerQuotesToAdvances,
  offerQuotesToQuotes,
  formatMonths,
  quotesToMonths,
  getLendingPartnerDisplayName,
} from 'components/capital/utils'
import { ApplicationProgressSteps } from 'components/capital/partials/CapitalApplicationProgress/types'
import { LendingPartners, OfferQuote } from 'redux/modules/capital/types'
import CapitalSelectQuotesSlider from 'components/capital/partials/CapitalSelectQuotesSlider'
import CapitalQuoteBlock from 'components/capital/partials/CapitalQuoteBlock'
import CapitalConsentBlock from 'components/capital/partials/CapitalConsentBlock'
import { formatCurrencyNoDecimals } from 'libs/utils'
import capitalStyles from 'components/capital/capital.module.scss'
import LulalendReAdvanceSliderContainer from 'components/capital/partials/LulalendReAdvanceSliderContainer'
import styles from './CapitalApplicationSelectQuotesPage.module.scss'

interface Props {
  capitalOfferQuotes: OfferQuote[]
  uuid: string
  providerName: LendingPartners
  currency: string
  earlySettlementAmount?: number
  discountOnCurrentAdvanceOutstandingBalance?: number
  sendApplication: (applicationUUID: string, data: any) => void
  onSliderAmountChanged: (amount: number) => void
  onSliderMonthChanged: (term: number) => void
  onFindOutMoreClicked: () => void
}

const CapitalApplicationSelectQuotesPage: React.FunctionComponent<Props> = ({
  capitalOfferQuotes,
  uuid,
  providerName,
  currency,
  earlySettlementAmount,
  discountOnCurrentAdvanceOutstandingBalance,
  sendApplication,
  onSliderAmountChanged,
  onSliderMonthChanged,
  onFindOutMoreClicked,
}) => {
  const offerAdvances = offerQuotesToAdvances(capitalOfferQuotes)
  const offerQuotes = offerQuotesToQuotes(capitalOfferQuotes)

  const [offerAdvanceIndex, setOfferAdvanceIndex] = useState(offerAdvances.length - 1)
  const [offerQuoteIndex, setOfferQuoteIndex] = useState(0)
  const [consented, setConsented] = useState(false)
  const [validationError, setValidationError] = useState(false)

  const quotes = offerQuotes[offerAdvanceIndex]
  const firstQuote = quotes[0]

  const firstAdvance = offerAdvances[0]
  const lastAdvance = offerAdvances[offerAdvances.length - 1]

  const months = quotesToMonths(quotes)
  const firstMonth = months[0]
  const lastMonth = months[months.length - 1]

  const selectedMonth = months[offerQuoteIndex]
  const selectedQuote = quotes[offerQuoteIndex]
  const selectedAdvance = offerAdvances[offerAdvanceIndex]

  const maximumOfferAdvanceSliderValue = offerAdvances.length - 1
  const maximumQuotesSliderValue = months.length - 1

  const isMoreThanOneQuote = quotes.length > 1

  const isLulalendReAdvance = !(
    typeof earlySettlementAmount === 'undefined' ||
    typeof discountOnCurrentAdvanceOutstandingBalance === 'undefined'
  )

  const resetOfferQuoteIndex = () => {
    setOfferQuoteIndex(0)
  }

  const onSetOfferAdvanceIndex = (newIndex: number) => {
    setOfferAdvanceIndex(newIndex)
    resetOfferQuoteIndex()
    onSliderAmountChanged(offerAdvances[newIndex])
  }

  const onSetOfferQuoteIndex = (newIndex: number) => {
    setOfferQuoteIndex(newIndex)
    onSliderMonthChanged(offerQuotes[offerAdvanceIndex][newIndex].repaymentPeriod)
  }

  const onContinuePress = () => {
    const quote = offerQuotes[offerAdvanceIndex][offerQuoteIndex]
    const shouldSubmitApplication = quote && consented

    if (shouldSubmitApplication) {
      sendApplication(uuid, {
        consent: consented,
        repaymentPeriod: quote.repaymentPeriod,
        repaymentPercentage: quote.repaymentPercentage,
        dailyAmount: quote.dailyAmount,
        capitalAmount: offerAdvances[offerAdvanceIndex],
        providerName,
      })

      return
    }

    setValidationError(true)
  }

  const renderAmountSlider = () => {
    return isLulalendReAdvance ? (
      <LulalendReAdvanceSliderContainer
        amountSelected={selectedAdvance}
        earlySettlementAmount={earlySettlementAmount}
        discountOnCurrentAdvanceOutstandingBalance={discountOnCurrentAdvanceOutstandingBalance}
      >
        <CapitalSelectQuotesSlider
          max={maximumOfferAdvanceSliderValue}
          value={offerAdvanceIndex}
          leftText={formatCurrencyNoDecimals(firstAdvance, currency)}
          rightText={formatCurrencyNoDecimals(lastAdvance, currency)}
          onChange={onSetOfferAdvanceIndex}
          displayTextAboveSlider
        />
      </LulalendReAdvanceSliderContainer>
    ) : (
      <CapitalSelectQuotesSlider
        header={formatCurrencyNoDecimals(selectedAdvance, currency)}
        max={maximumOfferAdvanceSliderValue}
        value={offerAdvanceIndex}
        leftText={formatCurrencyNoDecimals(firstAdvance, currency)}
        rightText={formatCurrencyNoDecimals(lastAdvance, currency)}
        onChange={onSetOfferAdvanceIndex}
        displayTextAboveSlider={false}
      />
    )
  }

  const renderMonthSlider = () => {
    return isLulalendReAdvance ? (
      <div className={styles.reAdvanceSliderContainer}>
        <CapitalSelectQuotesSlider
          header={formatMonths(selectedMonth)}
          max={maximumQuotesSliderValue}
          value={offerQuoteIndex}
          leftText={formatMonths(firstMonth)}
          rightText={formatMonths(lastMonth)}
          onChange={onSetOfferQuoteIndex}
          displayTextAboveSlider
        />
      </div>
    ) : (
      <CapitalSelectQuotesSlider
        header={formatMonths(selectedMonth)}
        max={maximumQuotesSliderValue}
        value={offerQuoteIndex}
        leftText={formatMonths(firstMonth)}
        rightText={formatMonths(lastMonth)}
        onChange={onSetOfferQuoteIndex}
        displayTextAboveSlider={false}
      />
    )
  }

  const renderOneTermQuoteBlock = () => {
    return (
      <>
        <div className={styles.repaymentPeriod}>
          <h3>{`${firstQuote.repaymentPeriod} months`}</h3>
        </div>
        <div>
          <p className={styles.repaymentPeriodQuote}>This is the only available repayment period</p>
        </div>
      </>
    )
  }

  return (
    <CenteredContainerPage>
      <div className={capitalStyles.page}>
        <CapitalHeaderBlock
          title='Get a cash advance to grow your business...'
          description={`Yoco has partnered with ${getLendingPartnerDisplayName(
            providerName
          )}, the specialists in SME funding. ${getLendingPartnerDisplayName(
            providerName
          )}'s aim is provide small to medium-sized businesses with financial solutions that work with your business cash flow.`}
        />
        <CapitalApplicationProgress activeStep={ApplicationProgressSteps.chooseOffer} />
        <TitleCard title={"You're one step closer."}>
          <div className={styles.cardContentsContainer}>
            <h4>How much do you need?</h4>
            <p>Drag the slider to the amount you need and select a repayment period below </p>
            {renderAmountSlider()}
            {isMoreThanOneQuote ? renderMonthSlider() : renderOneTermQuoteBlock()}
            {selectedQuote && (
              <CapitalQuoteBlock
                repaymentPercentage={`${selectedQuote.repaymentPercentage}%`}
                dailyAmount={formatCurrencyNoDecimals(selectedQuote.dailyAmount, currency)}
              />
            )}
          </div>
          <CapitalConsentBlock
            consented={consented}
            providerName={providerName}
            onClick={() => setConsented((consentState) => !consentState)}
          />
          <div className={styles.continueButtonContainer}>
            <FlatButton label='Continue' onClick={onContinuePress} className='blueBackground' />
          </div>
        </TitleCard>
        <CapitalHelpVideo onFindOutMoreClicked={onFindOutMoreClicked} />
        <CapitalHelpMessage />
      </div>
      <ConfirmPopup
        showing={validationError}
        title='Oops!'
        label='Try again'
        subtitle={
          consented
            ? ''
            : `Please agree to the terms and conditions in order to proceed with your application`
        }
        onConfirm={() => setValidationError(false)}
        hideCancel
      />
    </CenteredContainerPage>
  )
}

export default CapitalApplicationSelectQuotesPage
