/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* global zE */
import * as React from 'react'
import PropTypes from 'prop-types'

import errorImage from 'assets/images/warning.png'

import classes from './errors.module.scss'

export default function GenericErrorPage({ error, hideDetails = false }) {
  React.useEffect(() => {
    console.error('Error caught at error boundary', error)
  }, [error])

  return (
    <div>
      <div className={`${classes.notFound}`}>
        <div className='inner'>
          <img className='img' src={errorImage} height='70px' alt='Error' />
          <div className='title2'>Something went wrong</div>
          <p>
            We&apos;re working to fix the error as soon as possible. Please contact support if the
            error keeps happening.
          </p>
          {!hideDetails ? (
            <div className='col'>
              <a href='/' className='btn btn-primary'>
                Back to home
              </a>
              {typeof zE !== 'undefined' ? (
                <a href='javascript:$zopim.livechat.window.show();'>Live Chat</a>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

GenericErrorPage.propTypes = {
  error: PropTypes.object,
  hideDetails: PropTypes.bool,
}
