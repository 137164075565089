import React from 'react'

import { PopupComponent } from 'libs/modals'
import Spacer from 'ui/layout/Spacer'

export default class InfoPopup extends PopupComponent {
  getContent() {
    return (
      <div>
        <h3>How will I know when the link has been paid?</h3>
        <Spacer size='medium' />
        <p>
          You will receive a payment notification email at
          <strong>{` ${this.props.email}`}</strong>
        </p>
        <p>
          The status of the link will change from unpaid to paid. All paid links will be listed
          under the paid tab.
        </p>
        <p> The completed transaction will be shown in your Sales History</p>
      </div>
    )
  }
}
