import React, { useState } from 'react'
import { Link } from 'react-router'
import * as forms from 'libs/forms'
import FlatButton from 'components/buttons/FlatButton'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import BlockHeader from 'components/headers/BlockHeader'

import classes from './elevatePermissions.module.scss'

export default (WrappedComponent) => {
  function ElevatePermissions({ ...props }) {
    const [hasToken, setToken] = useState()

    const onSuccess = (response) => setToken(response.data.token)

    if (hasToken) {
      return <WrappedComponent {...props} />
    }
    return (
      <MaxWidthBlock>
        <div className={`${classes.container}`}>
          <BlockHeader title='Password required' />
          <p>For security reasons we need you to re-enter your password.</p>
          <forms.Form
            name='elevatePermissions'
            action='/user/escalatePrivileges'
            onSuccess={onSuccess}
          >
            <forms.FormBlock>
              <forms.TextField
                className={`${classes.inputField}`}
                name='password'
                placeholder='Please enter your password'
                type='password'
                validators={[new forms.RequiredValidator('Please type in your password')]}
              />
            </forms.FormBlock>

            <div className={`${classes.buttonRow}`}>
              <Link to='/account/forgot-password'>Forgot password?</Link>
              <FlatButton label='Submit' type='submit' className='primary' />
            </div>
          </forms.Form>
        </div>
      </MaxWidthBlock>
    )
  }

  return ElevatePermissions
}
