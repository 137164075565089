import { FormName } from '../Form/constants'

import { headerAddCustomer, headerEditCustomer } from './constants'
import { getIsNewCustomer } from './utils'

export const getSplitViewProps = ({ customer, onDeletePressed, splitContent }) => {
  if (!customer) {
    return {
      showingSplit: false,
    }
  }

  const isNewCustomer = getIsNewCustomer(customer)
  return {
    showingSplit: true,
    formName: FormName,
    header: isNewCustomer ? headerAddCustomer : headerEditCustomer,
    showDelete: !isNewCustomer,
    deletePressed: onDeletePressed,
    renderSplitContent: splitContent,
  }
}
