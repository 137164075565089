import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'

import { isHubRoutingEnabled } from 'redux/modules/routes'
import * as forms from 'libs/forms'
import { loggedIn } from 'redux/modules/session'
import FlatButton from 'components/buttons/FlatButton'
import { getQueryParamByName } from 'libs/queryParams'
import { userLoggedIn } from 'libs/analytics'
import { saveSession } from 'libs/cookies'
import Alert from 'components/notifications/Alert'
import { makeTestID, getENV, isShopify } from 'libs/utils'

import { selectHasCompletedOnboarding } from 'redux/modules/session/selectors'
import { redirectHubOrNext } from 'containers/app/App'
import ShopifyLogin from './ShopifyLogin'
import classes from './account.module.scss'

const baseTestID = makeTestID('loginPage')

/**
 * In order to avoid content spoofing we will validate messages against a
 * list of known-good messages.
 *
 * https://owasp.org/www-community/attacks/Content_Spoofing
 */
const ALLOWED_MESSAGES = [
  'There was an error retrieving your permissions, please contact support@yoco.co.za',
  'Unauthorized',
  'Password successfully changed',
  'Password successfully set',
]

class LoginPage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  onSuccess(response) {
    const session = {
      user: response.data.user,
      business: response.data.business,
      token: response.data.token,
      merchant: response.data.merchant,
      features: response.data.features,
      roles: response.data.roles,
      hasLoggedInToHub: response.data.hasLoggedInToHub,
    }

    saveSession(session)
    userLoggedIn(session)
    this.props.dispatch(loggedIn(session))
    const validNext =
      getQueryParamByName('next') !== '' &&
      getQueryParamByName('next') !== '/' &&
      getQueryParamByName('next') !== undefined
    const defaultNext = this.props.hasCompletedOnboarding ? '/dashboard' : '/onboarding'
    const next =
      (validNext ? getQueryParamByName('next') : defaultNext) + this.props.location.search
    const returnTo = getQueryParamByName('return_to') || false
    if (returnTo) {
      // returnTo is set when Zendesk is trying to do SSO,
      // we should redirect to Core again,
      // so it can push us along with our JWT token back to Zendesk
      window.location.href = `${getENV(
        'CORE_URL'
      )}api/integration/v1/user/zendesk/sso?returnTo=${returnTo}`
    } else {
      this.props.dispatch(
        push(redirectHubOrNext(next, response.data.hasLoggedInToHub, this.props.routeToHub))
      )
    }
  }

  getMessage() {
    if (
      this.props.queryParams.message &&
      this.props.queryParams.message.length > 0 &&
      !this.state.hideMessage &&
      ALLOWED_MESSAGES.includes(this.props.queryParams.message)
    ) {
      return (
        <div className={classes.formAlert}>
          <Alert
            message={this.props.queryParams.message}
            messageType={this.props.queryParams.messageType || 'danger'}
            onClose={() => {
              this.setState({ hideMessage: true })
            }}
          />
        </div>
      )
    }

    return undefined
  }

  hideMessage = () => {
    this.setState({ hideMessage: true })
  }

  render() {
    return (
      <div>
        <h1 className={classes.slogan}>Let&apos;s Grow</h1>
        <h2 className={classes.tagline}>The easiest way to accept payments</h2>

        <div className={`${classes.formBlock} clearfix`}>
          {this.getMessage()}

          {isShopify() ? (
            <ShopifyLogin props={this.props} hideMessage={this.hideMessage} />
          ) : (
            <forms.Form
              name='login'
              action='/user/login'
              noWrap
              onSuccess={(data) => this.onSuccess(data)}
              addUTM
              loaderClass={classes.formLoader}
              onValidated={() => {
                this.setState({ hideMessage: true })
              }}
            >
              <forms.FormBlock>
                <forms.TextField
                  name='email'
                  label='Email address'
                  placeholder='Please enter your email address'
                  type='email'
                  testID={makeTestID(baseTestID, 'emailAddress')}
                  validators={[
                    new forms.RequiredValidator('You must provide a email address'),
                    new forms.EmailValidator('You must provide an email address'),
                  ]}
                />
                <forms.TextField
                  name='password'
                  placeholder='Please enter your password'
                  type='password'
                  testID={makeTestID(baseTestID, 'password')}
                  validators={[new forms.RequiredValidator('Please type in your password')]}
                />
              </forms.FormBlock>

              <span style={{ marginTop: '12px', display: 'inline-block' }}>
                <Link to='/account/forgot-password'>Forgot password?</Link>
              </span>

              <FlatButton
                label='Login'
                className={`${classes.loginButton} blueBackground`}
                type='submit'
                testID={makeTestID(baseTestID, 'login')}
              />
              <span style={{ marginTop: '12px', display: 'inline-block' }}>
                <a
                  href={`${getENV('HELLO_YOCO_URL')}signup?userJourney=mango`}
                  target='_blank'
                  rel='noopener noreferrer'
                  className={classes.externalLink}
                >
                  Don&apos;t have an account? Sign up here.
                </a>
              </span>
            </forms.Form>
          )}
        </div>
      </div>
    )
  }
}

LoginPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  queryParams: PropTypes.object,
  hasCompletedOnboarding: PropTypes.bool.isRequired,
  location: PropTypes.object,
  routeToHub: PropTypes.bool,
}

export default connect((state) => ({
  queryParams: state.routing.locationBeforeTransitions.query,
  hasCompletedOnboarding: selectHasCompletedOnboarding(state),
  routeToHub: isHubRoutingEnabled(state),
}))(LoginPage)
