import React, { Component } from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import { Icon } from '@yoco/design-system-icons/dist/react'
import classNames from 'classnames'

import * as formats from 'libs/formats'

import classes from './settlements.module.scss'

export default class SettlementBlock extends Component {
  getLink() {
    if (this.props.link) {
      return (
        <Link to={this.props.link}>
          {this.getText()}
          <Icon name='chevron-right' size={24} />
        </Link>
      )
    }
    if (this.props.href) {
      return (
        <a href={this.props.href} rel='noopener noreferrer' target='_blank'>
          {this.getText()}
          <Icon name='chevron-right' size={24} />
        </a>
      )
    }
    return null
  }

  getText() {
    return <span className={classes.headerText}>{this.props.text}</span>
  }

  getDate() {
    if (this.props.date) {
      return <span className={classes.settlementDate}>{` - ${this.props.date}`}</span>
    }
    return <span />
  }

  render() {
    const style = this.props.style ? this.props.style : {}

    return (
      <div className={classNames(classes.settlementWrapper, 'card-shadow')} style={style}>
        <div className={classes.settlementHeader}>
          <span>{this.props.header}</span>
          {this.getDate()}
          <div className={classes.headerLink}>{this.getLink()}</div>
        </div>
        <div className={classes.settlementBlockAmount}>
          <span>
            <formats.Amount amount={this.props.amount} alignLeft />
          </span>
        </div>
      </div>
    )
  }
}

SettlementBlock.propTypes = {
  amount: PropTypes.number.isRequired,
  header: PropTypes.string.isRequired,
  style: PropTypes.object,
  date: PropTypes.string,
  text: PropTypes.string,
  href: PropTypes.string,
  link: PropTypes.string,
}
