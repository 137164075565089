import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import layout from './layout.module.scss'

function CenteredContent({ children }) {
  return <div className={classnames(layout.marginCentered)}>{children}</div>
}

CenteredContent.propTypes = {
  children: PropTypes.element,
}

export default CenteredContent
