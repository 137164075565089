/* eslint-disable react/no-unstable-nested-components */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'
import { Link } from 'react-router'
import classNames from 'classnames'
import { Icon } from '@yoco/design-system-icons/dist/react'

import { LineGraph } from 'libs/graphs'
import { EmptyTableView, InlineTable } from 'libs/tables'
import * as formats from 'libs/formats'
import localisation from 'libs/localisation/localisation'
import * as dashboardActions from 'redux/modules/dashboard/actions'
import Spacer from 'ui/layout/Spacer'

import SalesData from './SalesData'
import classes from './home.module.scss'

class PeriodBlock extends Component {
  constructor(props) {
    super(props)

    this.state = {
      period: this.props.period || 'today',
    }
  }

  componentDidMount() {
    this.props.actions.fetchAllFacts(this.state.period)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.props.actions.fetchAllFacts(this.state.period)
    }
  }

  getFormattedPeriod() {
    switch (this.state.period) {
      case 'last7days':
        return 'in the last 7 days'
      case 'last30days':
        return 'in the last 30 days'
      default:
        return this.state.period
    }
  }

  renderItems() {
    const urlItems = `/dashboard/table/items/?period=${this.state.period}`
    const urlTips = `/dashboard/table/tips/?period=${this.state.period}`
    const period = this.getFormattedPeriod()

    if (this.props.iKentoo) {
      return (
        <div className={classes.graphWrapper}>
          <div className={classes.graphHeader}>Top Tips</div>
          <InlineTable
            api={urlTips}
            name={`popularProducts${this.state.period}`}
            pageSize={5}
            emptyTable={
              <EmptyTableView title={`No tips were received ${period}`}>
                <span>This block shows you which staff member made the most tips</span>
              </EmptyTableView>
            }
            getRow={(immutableRow, rowIndex) => {
              const style =
                rowIndex === 4 ? { height: '56px', borderBottom: 'none' } : { height: '56px' }
              return (
                <tr className={classes.underlined} style={style} key={rowIndex}>
                  <td className={classes.name}>{immutableRow.get('name')}</td>
                  <td className={classes.amount}>
                    <formats.Amount amount={immutableRow.get('amount')} />
                  </td>
                </tr>
              )
            }}
          />
        </div>
      )
    }
    return (
      <div className={classNames([classes.graphWrapper, classes.column, classes.tabBlock])}>
        <div className={classes.graphHeader}>
          <span>Popular Products</span>
          <div className={classes.headerLink}>
            <Link to='/sales/reports?report=products'>
              <span className={classes.headerText}>View More</span>
              <Icon name='chevron-right' size={24} />
            </Link>
          </div>
        </div>
        <InlineTable
          api={urlItems}
          name={`popularProducts${this.state.period}`}
          pageSize={5}
          emptyTable={
            <EmptyTableView title={`No products were sold ${period}`}>
              <span>
                This block shows your top selling products. Create and sell products in your
                <Link to='items'> store </Link> to get insights.
              </span>
            </EmptyTableView>
          }
          getRow={(immutableRow, rowIndex) => {
            const style =
              rowIndex === 4 ? { height: '56px', borderBottom: 'none' } : { height: '56px' }
            return (
              <tr className={classes.underlined} style={style} key={rowIndex}>
                <td className={classes.itemDetails}>{immutableRow.get('name')}</td>
                <td className={classes.itemCount}>{immutableRow.get('quantity')}</td>
              </tr>
            )
          }}
        />
      </div>
    )
  }

  render() {
    const urlTransactions = `/dashboard/graphs/transactions/?period=${this.state.period}`
    const urlStaff = `/dashboard/table/staff/?period=${this.state.period}`

    const wrapperClass = `${classes.graphWrapper}`
    const wrapperFlexTwo = `${wrapperClass} ${classes.flexTwo}`

    const period = this.getFormattedPeriod()
    const { isVisible } = this.props

    return (
      <div>
        <div className={classNames(classes.insightsBanner, classes.tabContent)}>
          <div className={classNames([classes.graphWrapper, classes.nested])}>
            <div className={classNames([classes.graphHeader, classes.noBottomBorder])}>
              Quick Facts
            </div>
            <SalesData
              header='Number of Sales'
              insight={this.props.dashboard.insights.totalSales}
              isVisible={isVisible}
              value='count'
              className={classNames([classes.first, classes.shadow])}
            />
            <SalesData
              header='Average Sale Amount'
              insight={this.props.dashboard.insights.averageSalesAmount}
              isVisible={isVisible}
              value='amount'
              className={classes.second}
            />
            <SalesData
              header='Average Number of Items per Sale'
              insight={this.props.dashboard.insights.averageItemsPerSale}
              isVisible={isVisible}
              value='average'
              className={classes.third}
            />
          </div>
          <div className={classNames([wrapperFlexTwo, classes.nested])}>
            <div className={classes.graphHeader}>
              <span>Transactions</span>
              <div className={classes.headerLink}>
                <Link style={{ color: '#fff', textTransform: 'capitalize' }} to='/sales/history'>
                  <span className={classes.headerText}>View History</span>
                  <i className='icon-arrow-circle-30' />
                </Link>
              </div>
            </div>
            <LineGraph
              url={urlTransactions}
              key={`TransactionsGraph-${this.state.period}`}
              isVisible={isVisible}
              period={this.state.period}
              localisation={this.props.localisation}
            />
          </div>
        </div>
        <Spacer size='large' />
        <div className={classNames(classes.insightsBanner)}>
          {this.renderItems()}
          <div className={classNames(classes.graphWrapper, classes.tabBlock)}>
            <div className={classes.graphHeader}>
              <span>Top Staff</span>
              <div className={classes.headerLink}>
                <Link to='/sales/reports?report=staff'>
                  <span className={classes.headerText}>View More</span>
                  <Icon name='chevron-right' size={24} />
                </Link>
              </div>
            </div>
            <InlineTable
              api={urlStaff}
              name={`topStaff${this.state.period}`}
              pageSize={5}
              emptyTable={
                <EmptyTableView title={`No staff members made sales ${period}`}>
                  <span>
                    This block shows which staff made the most sales. Add staff member in the
                    <Link style={{ color: '#00a2e0' }} to='business/staff'>
                      {' '}
                      manage staff{' '}
                    </Link>{' '}
                    page to get insights.
                  </span>
                </EmptyTableView>
              }
              getRow={(immutableRow, rowIndex) => {
                const style =
                  rowIndex === 4 ? { height: '56px', borderBottom: 'none' } : { height: '56px' }
                return (
                  <tr className={classes.underlined} style={style} key={rowIndex}>
                    <td className={classes.name}>{immutableRow.get('name')}</td>
                    <td className={classes.amount} style={{ width: '150px' }}>
                      <formats.Amount amount={immutableRow.get('amount')} />
                    </td>
                  </tr>
                )
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}

PeriodBlock.propTypes = {
  period: PropTypes.string,
  localisation: PropTypes.object,
  iKentoo: PropTypes.bool,
  isVisible: PropTypes.bool,
  dashboard: PropTypes.object,
  actions: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    localisation: localisation(state),
    dashboard: state.dashboard,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ ...dashboardActions }, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PeriodBlock)
