import { Map } from 'immutable'

import { generateUUID } from '../utils'

/*
 These are the functions for adding collections to firebase
 */
export function processLayout(layout) {
  const layoutTiles = layout
    .get('layoutTiles', Map())
    .filter((layoutTile) => !layoutTile.get('shouldRemove'))
    .valueSeq()

  let lastPosition = -1
  let sortedTiles = layoutTiles
    .sortBy((layoutTile) => layoutTile.get('position'))
    .map((layoutTile) => {
      if (layoutTile.get('position')) {
        if (layoutTile.get('position') <= lastPosition) {
          lastPosition += 1
          return layoutTile.set('position', lastPosition)
        }
        lastPosition = layoutTile.get('position')
        return layoutTile
      }
      lastPosition += 1
      return layoutTile.set('position', lastPosition)
    })
    .toList()

  let totalPages
  if (layout.get('layoutOrdering', 'alphabetical') === 'alphabetical') {
    totalPages = Math.max(
      1,
      Math.ceil(sortedTiles.filterNot((layoutTile) => layoutTile.get('placeholder')).count() / 30)
    )
  } else {
    // We can use last position here,
    // because if we have blank pages it will be preserved in the next call
    // We need to add +1 because position is 0 based
    if (lastPosition + 1 > 0) {
      totalPages = Math.max(1, Math.ceil((lastPosition + 1) / 30))
    } else {
      totalPages = 1
    }

    // On custom layouts we allow for blank pages
    totalPages = Math.max(totalPages, layout.get('totalPages'))
  }

  sortedTiles = Map(layoutTiles.map((layoutTile) => [layoutTile.get('uuid'), layoutTile]))

  return layout.merge({
    layoutTiles: sortedTiles,
    totalPages,
    layoutOrdering: layout.get('layoutOrdering', 'alphabetical'),
  })
}

/* Add a product/collection to a layout at a specific position (or at the end) */
export function addToLayout(layout, tile, layoutPosition = undefined) {
  const layoutTiles = layout.get('layoutTiles', Map())
  let updatedLayout = layout
  let position = layoutPosition

  if (layoutPosition === undefined) {
    // We want to add the item to the end of our layout
    const lastTile = layoutTiles
      .filterNot((layoutTile) => layoutTile.get('placeholder'))
      .maxBy((layoutTile) => layoutTile.get('position'))

    position = lastTile ? lastTile.get('position', -1) + 1 : 0

    console.log('We have not specified a position, add tile at', position)
  }

  const replaceTile = layoutTiles.find((layoutTile) => layoutTile.get('position') === position)
  if (replaceTile) {
    // We are replacing a placeholder tile that already exists
    updatedLayout = layout.set(
      'layoutTiles',
      layoutTiles.map((layoutTile) => {
        if (layoutTile === replaceTile) {
          if (tile.get('tileType') === 'action') {
            return Map({
              position: replaceTile.get('position'),
              uuid: replaceTile.get('uuid'),
              tileUUID: generateUUID(),
              type: 'action',
              name: tile.get('name'),
              backgroundColor: tile.get('backgroundColor'),
              imageIcon: tile.get('imageIcon'),
              action: tile.get('action'),
            })
          }

          return Map({
            position: replaceTile.get('position'),
            tile,
            uuid: replaceTile.get('uuid'),
            tileUUID: tile.get('uuid'),
          })
        }

        return layoutTile
      })
    )
  } else {
    // We are adding to the end, this could mean we are on a new page of tiles
    let updatedLayoutTiles = layoutTiles
    const UUID = generateUUID()

    if (tile.get('tileType') === 'action') {
      updatedLayoutTiles = layoutTiles.set(
        UUID,
        Map({
          position,
          uuid: UUID,
          tileUUID: generateUUID(),
          type: 'action',
          name: tile.get('name'),
          backgroundColor: tile.get('backgroundColor'),
          imageIcon: tile.get('imageIcon'),
          action: tile.get('action'),
        })
      )
    } else {
      updatedLayoutTiles = layoutTiles.set(
        UUID,
        Map({
          position,
          uuid: UUID,
          tile,
          tileUUID: tile.get('uuid'),
        })
      )
    }

    updatedLayout = processLayout(
      updatedLayout.merge({
        layoutTiles: updatedLayoutTiles,
      })
    )
  }

  return updatedLayout
}

/* Remove a product/collection from a layout by replacing it with a placeholder */
export function removeFromLayout(layout, tile) {
  let layoutTiles = layout.get('layoutTiles', Map())

  layoutTiles = layoutTiles.map((layoutTile) => {
    if (tile.get('uuid') === layoutTile.get('tileUUID')) {
      return layoutTile.set('placeholder', true).remove('tile').remove('tileUUID')
    }
    return layoutTile
  })

  return layout.merge({
    layoutTiles,
  })
}
