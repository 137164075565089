/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback, useEffect, useState } from 'react'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import PropTypes from 'prop-types'

import FlatButton from 'components/buttons/FlatButton'
import { callJSONApi } from 'libs/api'
import Alert from 'components/notifications/Alert'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import BlockHeader from 'components/headers/BlockHeader'
import LoadingView from 'components/loaders/LoadingView'
import Spacer from 'ui/layout/Spacer'

function ConfirmBankDetailsPage({ routeParams, onConfirmComplete }) {
  const token = routeParams?.hashid

  const [isLoading, setIsLoading] = useState(true)
  const [newBankDetails, setNewBankDetails] = useState(undefined)
  const [errorMessage, setErrorMessage] = useState(
    token ? null : 'Invalid confirmation token - please try again'
  )

  const confirmDetails = useCallback(() => {
    setIsLoading(true)

    callJSONApi(
      `/business/bank-account/updates/${token}/confirm`,
      'POST',
      {},
      (response) => {
        setIsLoading(false)
        if (response.status === 200) {
          onConfirmComplete()
        } else {
          setErrorMessage(response.message)
        }
      },
      (errorMessage) => {
        setErrorMessage(errorMessage)
        setIsLoading(false)
      }
    )
  }, [onConfirmComplete, token])

  useEffect(() => {
    if (token) {
      callJSONApi(
        `/business/bank-account/updates/${token}`,
        'GET',
        {},
        (response) => {
          if (response.data.updateRequest) {
            setNewBankDetails(response.data.updateRequest.updateInfo)
          } else {
            setErrorMessage(
              response.message
                ? response.message
                : 'Unable to confirm this bank account change - please try again'
            )
          }
          setIsLoading(false)
        },
        (errorMessage) => {
          setErrorMessage(errorMessage)
          setIsLoading(false)
        }
      )
    }
  }, [setNewBankDetails, setIsLoading, token])

  return (
    <MaxWidthBlock>
      <BlockHeader title='Confirm your new bank details' />

      {errorMessage ? <Alert messageType='danger'>{errorMessage}</Alert> : null}

      {isLoading ? <LoadingView /> : null}

      {newBankDetails ? (
        <div>
          <p>
            We received a request to update your bank account. If you approve this change, all of
            your Yoco transactions will be paid to:
          </p>
          <p>
            <b>Bank:</b>&nbsp;{newBankDetails.bank}
            <br />
            <b>Account Number:</b>&nbsp;{newBankDetails.accountNumber}
            <br />
          </p>
          <p>
            For your security, you are required to approve this change. If you have made a mistake,
            please&nbsp;
            <Link to='/business/bank-account'>edit your bank details</Link> again.
          </p>

          <FlatButton className='blueBackground' onClick={confirmDetails}>
            Confirm bank details
          </FlatButton>

          <Spacer size='medium' />

          <p>
            If you did not request changes to your bank details and believe someone may have
            accessed your account, we recommend you&nbsp;
            <Link to='https://portal.yoco.co.za/profile'>change your password</Link>
            &nbsp;immediately and review your security.
            <br />
            <br />
            <a href='https://support.yoco.help/s/article/What-to-do-if-you-think-your-account-has-been-compromised'>
              What to do if you think your account has been compromised
            </a>
          </p>
        </div>
      ) : null}
    </MaxWidthBlock>
  )
}

ConfirmBankDetailsPage.propTypes = {
  routeParams: PropTypes.object,
  onConfirmComplete: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => ({
  onConfirmComplete: () => dispatch(push('/business/bank-account')),
})

export default connect(undefined, mapDispatchToProps)(ConfirmBankDetailsPage)
