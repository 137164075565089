import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { labels } from './RouteLabel.constants'
import classes from './routeLabel.module.scss'

export function RouteLabel({ route }) {
  const label = useMemo(() => labels[route], [route])
  const labelClass = useMemo(() => classes[label.cssClass], [label.cssClass])
  return <div className={labelClass}>{label.text}</div>
}

RouteLabel.propTypes = {
  route: PropTypes.string,
}
