import React from 'react'
import Slider from 'rc-slider/lib/Slider'
import sliderHandle from 'assets/images/sliderHandle.png'
import 'rc-slider/assets/index.css'
import colors from 'ui/colors'
import styles from './CapitalSelectQuotesSlider.module.scss'

interface Props {
  header?: string
  max: number
  value: number
  leftText: string
  rightText: string
  onChange: (newIndex: number) => void
  displayTextAboveSlider: boolean
}

const CapitalSelectQuotesSlider: React.FunctionComponent<Props> = ({
  header,
  max,
  value,
  leftText,
  rightText,
  onChange,
  displayTextAboveSlider,
}) => {
  return (
    <div className={displayTextAboveSlider ? styles.sliderContainerAbove : styles.sliderContainer}>
      {header && !displayTextAboveSlider && <h3>{header}</h3>}
      {displayTextAboveSlider && (
        <div className={styles.textContainer}>
          <div className={styles.text}>{leftText}</div>
          {header && <h3>{header}</h3>}
          <div className={styles.text}>{rightText}</div>
        </div>
      )}
      <Slider
        min={0}
        max={max}
        railStyle={{ backgroundColor: colors['Gray-200'], height: 10 }}
        trackStyle={{ backgroundColor: colors['Green-300'], height: 10 }}
        handleStyle={{
          borderColor: '#00a2e0',
          borderWidth: '0px',
          height: 28,
          width: 28,
          marginLeft: 0,
          marginTop: -9,
          backgroundImage: `url(${sliderHandle})`,
          backgroundSize: 'cover',
          backgroundColor: 'rgba(0,0,0,0)',
        }}
        step={1}
        value={value}
        onChange={onChange}
      />
      {!displayTextAboveSlider && (
        <>
          <div className={styles.leftText}>{leftText}</div>
          <div className={styles.rightText}>{rightText}</div>
        </>
      )}
    </div>
  )
}

export default CapitalSelectQuotesSlider
