import keyMirror from 'libs/key-mirror'

export default keyMirror(
  {
    FETCH_QUALIFICATIONS_REQUEST: null,
    FETCH_QUALIFICATIONS_SUCCESS: null,
    FETCH_QUALIFICATIONS_FAILURE: null,
    RECEIVED_QUALIFICATIONS: null,

    FETCH_PRELIM_OFFERS_REQUEST: null,
    FETCH_PRELIM_OFFERS_SUCCESS: null,
    FETCH_PRELIM_OFFERS_FAILURE: null,
    RECEIVED_PRELIM_OFFERS: null,

    CAPITAL_OPT_IN_REQUEST: null,
    CAPITAL_OPT_IN_SUCCESS: null,
    CAPITAL_OPT_IN_FAILURE: null,

    SHOW_OFFERS: null,

    RE_APPLY: null,

    PROGRESS_OFFER_REQUEST: null,
    PROGRESS_OFFER_SUCCESS: null,
    PROGRESS_OFFER_FAILURE: null,
    RECEIVED_CAPITAL_OFFER: null,
    RECEIVED_CAPITAL_QUALIFYINGBUSINESS: null,

    DECLINED_CAPITAL_OFFER: null,
  },
  '@capital/'
)
