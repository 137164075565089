/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unused-class-component-methods */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'
import { Icon } from '@yoco/design-system-icons/dist/react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import * as filters from 'redux/modules/filters'
import { FirebaseHandler } from 'libs/firebase'
import FlatButton from 'components/buttons/FlatButton'

import { makeTestID } from '../utils'

import Filter from './Filter'
import classes from './filters.module.scss'

class FilterBatch extends Component {
  constructor(props) {
    super(props)
    this.filters = []
    this.state = {}
    this.filterKeys = List()
    this.getFilterKeys(<div>{this.props.children}</div>)
    this.checkFiltersFinishedInitializing(props.batch)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.checkFiltersFinishedInitializing(nextProps.batch)
  }

  componentWillUnmount() {
    this.props.dispatch(filters.clearSet(this.props.name))
  }

  onFilterChanged(filterKey, value, multiSelectEnabled) {
    if (!value) {
      this.props.dispatch(filters.filterSet(this.props.name, filterKey, []))
    } else if (multiSelectEnabled) {
      let values = this.props.batch.getIn(['filters', filterKey, 'values'], List())
      if (values.contains(value)) {
        values = values.delete(values.indexOf(value))
      } else {
        values = values.push(value)
      }
      this.props.dispatch(filters.filterSet(this.props.name, filterKey, values.toJS()))
    } else {
      this.props.dispatch(filters.filterSet(this.props.name, filterKey, [value]))
    }
  }

  onSearchChanged(value) {
    if (!value) {
      this.props.dispatch(filters.searchSet(this.props.name, ''))
    } else if (value !== this.props.batch.getIn(['search', 'value'])) {
      this.props.dispatch(filters.searchSet(this.props.name, value))
    }
  }

  getFilterKeys(parent) {
    React.Children.map(parent.props.children, (child) => {
      if (this.isToggle(child)) {
        // This is a toggle it does not filter
      } else if (this.isFilter(child) && child.props && child.props.filterKey) {
        if (!this.filterKeys.contains(child.props.filterKey)) {
          this.filterKeys = this.filterKeys.push(child.props.filterKey)
        }
      } else if (child) {
        this.getFilterKeys(child)
      }
    })
  }

  isFilter(child) {
    if (
      child === undefined ||
      child === null ||
      child.type === undefined ||
      typeof child.type !== 'function'
    ) {
      return false
    }

    const p = Object.getPrototypeOf(child)
    if (p && Object.prototype.isPrototypeOf.call(p, Filter)) {
      return true
    }

    return false
  }

  attachFilter(filter, isMobile) {
    let values
    let shown = false
    const { batch } = this.props

    if (batch && filter && filter.props && filter.props.filterKey) {
      const filterPath = filter.props.filterKey.split('.')
      filterPath.push('values')
      filterPath.unshift('filters')
      values = batch.getIn(filterPath)

      shown = batch.get(isMobile ? 'mobileShownFilter' : 'shownFilter') === filter.props.filterKey
    }

    const props = Map(filter.props)
      .set('batch', this)
      .set('values', values)
      .set('shown', shown)
      .set('isMobile', isMobile)
      .set('startOfBusinessDay', this.props.startOfBusinessDay || 0)
      .toObject()

    return React.cloneElement(filter, props)
  }

  isToggle(child) {
    if (
      child === undefined ||
      child === null ||
      child.type === undefined ||
      typeof child.type !== 'function'
    ) {
      return false
    }
    const p = Object.getPrototypeOf(child)
    if (p && Object.prototype.isPrototypeOf.call(p, Filter) && child.props.isToggle) {
      return true
    }

    return false
  }

  attachToggle(toggle, isMobile) {
    const props = Map(toggle.props)
      .set('batch', this)
      .setIn(['toggled', 'value'], toggle.props.toggled)
      .set('isMobile', isMobile)
      .toObject()

    return React.cloneElement(toggle, props)
  }

  attachFilters(parent, isMobile = false) {
    if (parent && parent.props) {
      return React.cloneElement(parent, {
        children: React.Children.map(parent.props.children, (child) => {
          if (this.isToggle(child)) {
            return this.attachToggle(child, isMobile)
          }
          if (this.isFilter(child)) {
            return this.attachFilter(child, isMobile)
          }
          if (child) {
            // If this is not a form field, we should check for nested fields
            return this.attachFilters(child, isMobile)
          }

          return undefined
        }),
      })
    }

    return parent
  }

  checkFiltersFinishedInitializing(nextBatch) {
    const batch = nextBatch || this.props.batch

    if (batch && batch.get('initialized') !== true) {
      const uninitializedFilters = this.filterKeys.filter((key) => {
        return batch.getIn(['filters', key]) === undefined
      })

      if (uninitializedFilters.size === 0) {
        this.props.dispatch(filters.filtersFinishedInitializing(this.props.name))
      }
    }
  }

  filterInitialized(filterKey, values, force = false) {
    const filter = this.props.batch.getIn(['filters', filterKey])
    if (!filter || force) {
      // We should not override filters that have already been picked
      this.props.dispatch(filters.filterSet(this.props.name, filterKey, values))
    }
  }

  searchInitialized(value, force = false) {
    const search = this.props.batch.get('search')
    if (!search || force) {
      // We should not override filters that have already been picked
      this.props.dispatch(filters.searchSet(this.props.name, value))
    }
  }

  toggleInitialized(value, api) {
    this.props.dispatch(filters.toggleSet(this.props.name, value, api))
  }

  filterToggled(filterKey, shown, isMobile) {
    this.props.dispatch(filters.filterShown(this.props.name, filterKey, shown, isMobile))
  }

  mobileClicked() {
    this.setState((prevState) => ({
      showMobileFilters: !prevState.showMobileFilters,
    }))
  }

  renderFilters(isMobile) {
    return this.attachFilters(
      <div className={classes.filtersRow}>{this.props.children}</div>,
      isMobile
    )
  }

  renderMobileFilters() {
    if (this.props.showMobileInline) {
      return (
        <div className={classNames(classes.filters, classes.mobile, classes.mobileInline)}>
          {this.renderFilters(true)}
        </div>
      )
    }

    return (
      <div
        className={classNames(
          classes.filters,
          classes.mobile,
          this.state.showMobileFilters && classes.shown
        )}
      >
        <div className={classes.filterHeader} onClick={this.mobileClicked.bind(this)}>
          <Icon name='filter' size={24} />
          &nbsp; Filters
          <div className='filler' />
          <span className={classes.dropdownArrow}>
            <Icon name='chevron-down' size={24} />
          </span>
        </div>
        <div className={classes.filtersContainer}>
          {this.renderFilters(true)}

          <div className='text-right'>
            <FlatButton
              className='blueBackground'
              label='Done'
              testID={makeTestID('filters', 'done')}
              onClick={() => {
                this.mobileClicked()
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  renderContent() {
    if (this.props.renderContent) {
      return this.props.renderContent(this.props, this.renderFilters())
    }

    let filterClass = classes.filters
    if (this.props.containerClass) {
      filterClass += ` ${this.props.containerClass}`
    }

    const className = classes.filtersContainer

    return (
      <div className={classes.outerFilterContainer}>
        <div className={filterClass}>
          <div className={className}>{this.renderFilters(false)}</div>
        </div>
        {this.renderMobileFilters()}
      </div>
    )
  }

  render() {
    return this.renderContent()
  }
}

const ReduxConnectedFilterBatch = connect((state, props) => ({
  batch: state.filters.get(props.name, Map()),
  table: state.tables.get(props.name, Map()),
}))(FilterBatch)

const ConnectedFilterBatch = FirebaseHandler.connect(
  ReduxConnectedFilterBatch,
  Map({
    startOfBusinessDay: {
      path: 'readOnly/store/business/businessConfig/config/startOfBusinessDay',
    },
  })
)

ConnectedFilterBatch.propTypes = {
  showMobileInline: PropTypes.bool,
  children: PropTypes.element,
  containerClass: PropTypes.string,
  name: PropTypes.string,
}

FilterBatch.propTypes = {
  dispatch: PropTypes.func.isRequired,
  batch: PropTypes.objectOf(Map),
  showMobileInline: PropTypes.bool,
  renderContent: PropTypes.func,
  children: PropTypes.node,
  containerClass: PropTypes.string,
  name: PropTypes.string,
  startOfBusinessDay: PropTypes.number,
}

export default ConnectedFilterBatch
