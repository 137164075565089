import React from 'react'
import PropTypes from 'prop-types'
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share'
import classnames from 'classnames'

import layout from 'ui/layout/layout.module.scss'
import interactions from 'ui/interactions/interactions.module.scss'
import HStack from 'ui/layout/HStack'

function ShareBlock({ url, title }) {
  return (
    <HStack spacing='small'>
      <WhatsappShareButton
        url={url}
        title={title}
        separator=':: '
        className={classnames(layout.inlineBlock, interactions.pointer)}
      >
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>

      <FacebookShareButton
        url={url}
        quote={title}
        className={classnames(layout.inlineBlock, interactions.pointer)}
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>

      <LinkedinShareButton
        url={url}
        title={title}
        className={classnames(layout.inlineBlock, interactions.pointer)}
      >
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>

      <TwitterShareButton
        url={url}
        title={title}
        className={classnames(layout.inlineBlock, interactions.pointer)}
      >
        <TwitterIcon size={32} round />
      </TwitterShareButton>

      <EmailShareButton
        url={url}
        subject={title}
        body={title}
        className={classnames(layout.inlineBlock, interactions.pointer)}
      >
        <EmailIcon size={32} round />
      </EmailShareButton>
    </HStack>
  )
}

ShareBlock.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
}

export default ShareBlock
