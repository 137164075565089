import React, { useState } from 'react'

import CustomersTable from './Table'
import CustomerListFilters from './ListFilters'
import CustomerListHeader from './ListHeader'
import CustomerListViewSplit from './ListViewSplit'

function CustomersList() {
  const [customer, setCustomer] = useState()

  return (
    <CustomerListViewSplit customer={customer} setCustomer={setCustomer}>
      <CustomerListHeader onNewCustomer={setCustomer} />
      <CustomerListFilters />
      <CustomersTable onRowClick={setCustomer} />
    </CustomerListViewSplit>
  )
}

export default CustomersList
