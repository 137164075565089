/* eslint-disable no-restricted-syntax */
import React from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'

import { callJSONApi, callCommonJSONAPI } from 'libs/api'

import { FormField } from './base.jsx'
import formClasses from './forms.module.scss'
import classes from './SelectField.module.scss'

export default class SelectField extends FormField {
  componentDidMount() {
    super.componentDidMount()

    // Force stock android searchable as it seems buggy if not.
    if (
      navigator.appVersion.indexOf('Android') !== -1 &&
      navigator.appVersion.indexOf('Chrome') === -1
    ) {
      this.setState({
        forceSearchable: navigator.appVersion,
      })
    }
  }

  constructor() {
    super()
    this.handleOnChange = this.onChange.bind(this)
    this.handleOnFocus = this.onFocus.bind(this)
    this.handleLoadOptions = this.loadOptions.bind(this)
    this.handleOnBlur = this.onBlur.bind(this)
  }

  loadOptions(input, callback) {
    if (this.props.propertyGroup) {
      const url = `/properties/${this.props.propertyGroup}`

      callCommonJSONAPI(
        url,
        'GET',
        {},
        (data) => {
          const options = []
          let currentGroup
          for (const property of data.data) {
            if (property[2] !== currentGroup && property[2] !== null) {
              options.push({
                value: -1,
                label: property[2],
              })
              // eslint-disable-next-line prefer-destructuring
              currentGroup = property[2]
            }
            options.push({
              value: property[0],
              label: property[1],
            })
          }
          callback(options)
        },
        () => {
          callback([])
        },
        // We use credentials to filter properties based on the locale of your user
        true
      )
    } else if (this.props.api) {
      // Add our search string
      let url = this.props.api
      if (input) {
        if (url.indexOf('?') !== -1) {
          url = `${url}&search=${encodeURIComponent(input)}`
        } else {
          url = `${url}?search=${encodeURIComponent(input)}`
        }
      }

      callJSONApi(
        url,
        'GET',
        {},
        (data) => {
          const d = data.data
          let options = []
          if (d[Object.keys(d)[0]] instanceof Array) {
            options = d[Object.keys(d)[0]].map((option) => {
              let disabled = false
              if (this.props.testDisabled) {
                disabled = this.props.testDisabled(option)
              }
              return {
                value: option.uuid,
                label: option.name,
                disabled,
              }
            })
            this.fullOptions = d[Object.keys(d)[0]]
          } else {
            options = d.map((option) => {
              let disabled = false
              if (this.props.testDisabled) {
                disabled = this.props.testDisabled(option)
              }
              return {
                ...option,
                value: this.props.useTargetUUID ? option.targetUUID : option.uuid,
                label: option.name,
                disabled,
              }
            })
            this.fullOptions = options
          }
          callback(options)
        },
        () => {
          callback([])
        }
      )
    }
  }

  onChange(value) {
    if (value.value === -1) {
      return
    }

    if (this.props.onChange) {
      this.props.onChange(value)
    } else if (this.props.onChangeAdditional) {
      this.props.onChangeAdditional(value)
      this.props.form.onFieldChange(this.props.name, value.value, this.props.dataGroup)
    } else {
      this.props.form.onFieldChange(this.props.name, value.value, this.props.dataGroup)
    }

    if (this.props.onFullOptionChanged) {
      for (const option of this.fullOptions) {
        if (option.uuid === value.value) {
          this.props.onFullOptionChanged(option)
        }
      }
    }
  }

  getSelectedOptionLabel = (value) => {
    const optionsArr = this.props.options || this.fullOptions || []
    const option = optionsArr.find((o) => o.value === value || o.uuid === value)
    return (option?.label || option?.name) ?? value
  }

  getSelect() {
    const selectedOption =
      // eslint-disable-next-line eqeqeq
      this.props.value != undefined
        ? { value: this.props.value, label: this.getSelectedOptionLabel(this.props.value) }
        : ''
    let searchable = false
    if (this.props.searchable) {
      searchable = true
    } else if (this.state && this.state.forceSearchable) {
      searchable = true
    }

    if (this.props.propertyGroup || this.props.api) {
      return (
        <AsyncSelect
          classNamePrefix='react-select'
          name={this.props.name}
          value={selectedOption}
          defaultOptions
          cacheOptions
          onChange={this.handleOnChange}
          onFocus={this.props.onFocus ? this.props.onFocus : this.handleOnFocus}
          loadOptions={this.handleLoadOptions}
          placeholder={this.props.placeholder || ''}
          isSearchable={searchable}
          isDisabled={this.props.disabled}
          onBlur={this.handleOnBlur}
          menuPosition='fixed'
          aria-label={this.props.ariaLabel}
        />
      )
    }

    return (
      <Select
        classNamePrefix='react-select'
        name={this.props.name}
        value={selectedOption}
        onChange={this.handleOnChange}
        onFocus={this.props.onFocus ? this.props.onFocus : this.handleOnFocus}
        options={this.props.options}
        isDisabled={this.props.disabled}
        placeholder={this.props.placeholder || ''}
        isSearchable={searchable}
        onBlur={this.handleOnBlur}
        menuPosition='fixed'
        aria-label={this.props.ariaLabel}
      />
    )
  }

  getClearContainer() {
    if (this.props.clearable && !this.props.disabled && this.props.value !== null) {
      return (
        <button
          type='button'
          className={classes.clearButton}
          onClick={() => this.onChange({ value: null })}
        >
          CLEAR
        </button>
      )
    }

    return null
  }

  renderInput() {
    return (
      <div className={formClasses.inputWrapper}>
        <div className={classes.root} data-testid={this.props.testID}>
          {this.getSelect()}
          {this.getClearContainer()}
        </div>
      </div>
    )
  }
}
