import React from 'react'
import { IndexRedirect } from 'react-router'
import ROLES from 'libs/roles'
import CapitalDeductionsPage from 'components/capital/CapitalDeductionsPage'
import { RouteLabel } from 'components/navbars/RouteLabel'
import { labelType } from 'components/navbars/RouteLabel.constants'

import CapitalPage from './CapitalPage'

// TODO: want to hide payments if you are not repaying a loan
export default function getRoutes() {
  return {
    path: 'capital',
    text: 'Capital',
    icon: 'capital',
    // eslint-disable-next-line react/display-name
    badgeElement: (number) => {
      return (
        number && typeof number === 'number' && number > 0 && <RouteLabel route={labelType.NEW} />
      )
    },

    onClick: (props) => props && props.clearCapitalRouteBadge('capital'),
    indexRedirect: <IndexRedirect to='/capital/offers' />,
    requiresAllRoles: [ROLES.MANAGE_BUSINESS, ROLES.VIEW_FINANCIAL],
    requiresOneOfFeatures: ['capital'],
    routes: [
      {
        path: 'offers',
        text: 'Offers',
        component: CapitalPage,
      },
      {
        path: 'payments',
        text: 'Deduction Plans',
        component: CapitalDeductionsPage,
      },
    ],
  }
}
