/* eslint-disable react/button-has-type */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import classes from './buttons.module.scss'

export default class FlatButton extends Component {
  getIcon() {
    return this.props.icon ? <i className={this.props.icon} /> : null
  }

  getLabel() {
    if (this.props.label) {
      if (!this.props.hideLabelOnMobile) {
        return this.props.label
      }
      return <span className={classes.hideOnMobile}>{this.props.label}</span>
    }
    return null
  }

  getClassName() {
    return classNames(classes.flatButton, this.props.className, {
      [classes.disabled]: this.props.disabled,
    })
  }

  render() {
    if (this.props.href) {
      if (this.props.disabled) {
        // remove actions from anchor if disabled
        this.props = { ...this.props, href: 'javascript:void(0);', target: '', onClick: '' } // eslint-disable-line
      }

      return (
        <a
          className={this.getClassName()}
          onClick={this.props.onClick}
          type={this.props.type}
          style={this.props.style}
          href={this.props.href}
          target={this.props.target}
          id={this.props.id}
          download={this.props.download}
          aria-label={this.props.ariaLabel}
          data-testid={this.props.testID || 'button'}
        >
          {this.getIcon()}
          {this.props.label}
        </a>
      )
    }
    return (
      <button // eslint-disable-line
        disabled={this.props.disabled}
        tabIndex={this.props.tabIndex}
        className={this.getClassName()}
        onClick={this.props.onClick}
        type={this.props.type}
        style={this.props.style}
        onMouseDown={this.props.onMouseDown}
        onMouseUp={this.props.onMouseUp}
        id={this.props.id}
        aria-label={this.props.ariaLabel}
        data-testid={this.props.testID || 'button'}
      >
        {this.getIcon()}
        {this.getLabel()}
        {this.props.children}
      </button>
    )
  }
}

FlatButton.propTypes = {
  icon: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  href: PropTypes.string,
  id: PropTypes.string,
  hideLabelOnMobile: PropTypes.bool,
  ariaLabel: PropTypes.string,
  testID: PropTypes.string,
  target: PropTypes.string,
  download: PropTypes.any,
  tabIndex: PropTypes.number,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  children: PropTypes.node,
}
