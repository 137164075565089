import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { makeTestID, mouseDownHandler, mouseUpHandler } from 'libs/utils'

import classes from './tables.module.scss'

export default class TableRow extends Component {
  getContent() {
    return undefined
  }

  getRowClass() {
    return ''
  }

  render() {
    return (
      <tr
        className={`${classes.tableRow} ${this.props.selected ? classes.selectedRow : ''} ${
          this.props.rowIndex % 2 === 0 ? classes.rowBackground : ''
        } ${this.props.onClick ? classes.clickable : ''} ${this.getRowClass()}`}
        onClick={this.props.onClick}
        onMouseDown={() => mouseDownHandler()}
        onMouseUp={() => mouseUpHandler()}
        data-testid={makeTestID(this.props.testID, 'tableRow')}
        aria-label={this.props.ariaLabel}
      >
        {this.getContent()}
        {this.props.children}
      </tr>
    )
  }
}

TableRow.propTypes = {
  selected: PropTypes.bool,
  rowIndex: PropTypes.number,
  onClick: PropTypes.func,
  children: PropTypes.node,
  testID: PropTypes.string,
  ariaLabel: PropTypes.string,
}

TableRow.defaultProps = {
  selected: false,
  rowIndex: 0,
}
