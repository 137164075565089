import { StyleSheet } from '@react-pdf/renderer'
import colors from 'ui/colors'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: colors.White,
  },
  logo: {
    position: 'absolute',
    width: '146px',
    height: '64px',
    left: '225px',
    top: '120px',
  },
  qr: {
    position: 'absolute',
    width: '440px',
    height: '440px',
    left: '78px',
    top: '204px',
  },
})

export default styles
