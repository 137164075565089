import React from 'react'
import PropTypes from 'prop-types'

import classes from './styles.module.scss'

function PlacesFieldContainer({ children }) {
  return <div className={classes.placesFieldContainer}>{children}</div>
}

PlacesFieldContainer.propTypes = {
  children: PropTypes.element,
}

export default PlacesFieldContainer
