import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { downloadFile } from 'libs/api'
import { FilteredComponent, serializeFilters } from 'libs/filters'
import { getENV } from 'libs/utils'

import classes from './buttons.module.scss'
import FlatButton from './FlatButton'

/*
 * An export button
 *
 * Note that you will need to provide the button with a name that matches the filterName.
 * It uses the name to get the date range filter.
 * @see {@link FilteredComponent} for details.
 */

function ExportButtonInner(props) {
  const onClick = () => {
    let exportURL = `${getENV('CORE_URL')}${getENV('API_ROOT_PATH')}${props.exportApi}`
    if (props.batch) {
      const startDate = encodeURIComponent(
        props.batch.getIn(['filters', 'created', 'values', 0], moment(0).format())
      )
      const endDate = encodeURIComponent(
        props.batch.getIn(['filters', 'created', 'values', 1], moment().format())
      )

      exportURL = `${exportURL}?filters=${
        serializeFilters(props.batch.get('filters')).filters
      }&startDate=${startDate}&endDate=${endDate}`
    }

    // Old portal seems to have potentially added a bad cookie that breaks export - unset it.
    // Our real auth cookie will use .yoco.co.za
    document.cookie = `yoco-auth-cookie=; path=/; domain=portal.yoco.co.za; expires = Thu, 01 Jan 1970 00:00:00 GMT`

    downloadFile(exportURL)

    if (props.onClick) {
      props.onClick()
    }
  }

  return (
    <FlatButton
      onClick={onClick}
      label={props.label ? props.label : 'Export'}
      className={props.buttonClass ? props.buttonClass : 'secondary'}
      style={{ lineHeight: '32px' }}
      disabled={props.disabled}
    />
  )
}

export default function ExportButton(props) {
  return (
    <div className={classnames([classes.export, props.className])} style={props.containerStyle}>
      <FilteredComponent renderContent={ExportButtonInner} {...props} />
    </div>
  )
}

ExportButtonInner.propTypes = {
  exportApi: PropTypes.string,
  batch: PropTypes.object,
  onClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  buttonClass: PropTypes.string,
}

ExportButton.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  tooltip: PropTypes.string,
  exportApi: PropTypes.string,
  disabled: PropTypes.bool,
  buttonClass: PropTypes.string,
  containerStyle: PropTypes.object,
  className: PropTypes.string,
}
