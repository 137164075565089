export const statusColorMap = {
  draft: '#222222',
  paid: '#FFFFFF',
  unpaid: '#FFFFFF',
  overdue: '#FFFFFF',
  default: '#FFFFFF',
}

export const statusBackgroundMap = {
  draft: '#C3C8D1',
  paid: '#67BFAA',
  unpaid: '#66CBEC',
  overdue: '#FA6D71',
  default: '#C3C8D1',
}
