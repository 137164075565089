import PropTypes from 'prop-types'
import { Map } from 'immutable'

import CONSTANTS from 'libs/constants'
import { ItemIcon } from 'components/items'

import classes from './tile.module.scss'

export default class TileIcon extends ItemIcon {
  getImageTextStyle() {
    if (this.props.tile.get('tileType') !== 'product') {
      // If we have a tile that is a collection
      return { lineHeight: '40px', fontSize: '30px' }
    }
    return { lineHeight: '70px', fontSize: '35px' }
  }

  getImageLayoutStyle(background) {
    const layoutBackground = background
    layoutBackground.transform = 'rotate(315deg)'
    layoutBackground.height = '80px'
    layoutBackground.width = '80px'
    layoutBackground.top = '-16px'
    layoutBackground.left = '-16px'
    return layoutBackground
  }

  getImageBorderRadius() {
    return '4px 4px 0 0'
  }

  getBackgroundColor() {
    if (this.props.tile) {
      if (this.props.tile.get('imageURL')) {
        return 'white'
      }

      if (this.props.tile.get('backgroundColor')) {
        const backgroundColor = this.props.tile.get('backgroundColor')
        return backgroundColor.indexOf('#') === 0 ? backgroundColor : `#${backgroundColor}`
      }
    }

    return CONSTANTS.YOCO_DEFAULT_COLOR
  }

  getBackgroundClass() {
    switch (this.props.tile.get('tileType')) {
      case 'brand':
        return `${classes.tileShape} ${classes.rounded}`
      case 'product_category':
        return `${classes.tileShape} ${classes.circle}`
      case 'layout':
      case 'collection':
        return `${classes.tileShape} ${classes.diamond}`
      default:
        return classes.tileShape
    }
  }

  getTextStyle() {
    if ((this.props.tile || Map()).get('tileType') === 'product') {
      return { lineHeight: '50px' }
    }
    if ((this.props.tile || Map()).get('tileType') === 'collection') {
      return { lineHeight: '36px' }
    }
    return { lineHeight: '40px' }
  }

  getImageClass(rotate = false) {
    if (rotate) {
      return `${classes.inner} ${classes.itemText} ${classes.rotate}`
    }
    return `${classes.inner} ${classes.itemText}`
  }

  getItemBackground() {
    return `${classes.tileIcon}`
  }

  getInnerBackgroundClass() {
    return `${classes.inner}`
  }
}
ItemIcon.defaultProps = {
  tile: Map(),
}

ItemIcon.propTypes = {
  style: PropTypes.object,
  tile: PropTypes.object,
}
