import React from 'react'
import { Icon } from '@yoco/design-system-icons/dist/react'
import baseClasses from './FormField.module.scss'

type FormFieldType = {
  label: string
  value: string
  name: string
  onChange: (any) => any
  classes?: string
  placeholder?: string
  errors?: string
  touched?: boolean
}

const FormField = ({
  value,
  label,
  onChange,
  classes,
  placeholder,
  name,
  errors,
  touched,
}: FormFieldType): JSX.Element => {
  return (
    <label htmlFor='input' className={`${baseClasses.formField} ${classes}`}>
      <div className={baseClasses.labelFormat}>
        {label}
        {errors && touched && (
          <div className={baseClasses.warning} hidden>
            <Icon name='alert-error' />
            {errors}
          </div>
        )}
      </div>
      <input
        id={label.toLocaleUpperCase()}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        type='text'
      />
    </label>
  )
}

export default FormField
