import React from 'react'

import FlatButton from 'components/buttons/FlatButton'
import errorImage from 'assets/images/error.svg'

import classes from './CapitalErrorBlock.module.scss'

interface Props {
  error: string
  title: string
  buttonInfo?: {
    text: string
    contactSupportURL: string
    onClick: () => void
  }
}

const CapitalErrorBlock: React.FunctionComponent<Props> = ({ error, title, buttonInfo }) => {
  return (
    <div className={classes.container} data-testid='CapitalErrorBlock'>
      <img className={classes.image} src={errorImage} alt='error' />
      <h1>{title}</h1>
      <h4>{error}</h4>
      {buttonInfo ? (
        <FlatButton
          label={buttonInfo.text}
          onClick={buttonInfo.onClick}
          href={buttonInfo.contactSupportURL}
          className={classes.button}
          target='_blank'
        />
      ) : null}
    </div>
  )
}

export default CapitalErrorBlock
