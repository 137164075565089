/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { fromJS } from 'immutable'

import { List } from 'libs/list'
import emptyBasket from 'assets/images/icon-empty-state.png'
import FlatButton from 'components/buttons/FlatButton'
import { makeTestID } from 'libs/utils'

import DiscountPopup from '../Discount'
import { calculateInvoiceEntryTotals } from '../Invoices'

import { AddProductPopup } from './AddProduct'
import LineItem from './LineItem'
import classes from './LineItems.module.scss'
import { applyItemDiscount } from './utils'

const baseTestID = 'lineItems'

function LineItems({ value, onChange, trackingEvents, testID = '' }) {
  const [isAddingProducts, setIsAddingProducts] = useState(false)
  const [discountingItem, setDiscountingItem] = useState()

  const handleAddProductClick = useCallback(() => {
    trackingEvents?.trackAddProductClick?.()
    setIsAddingProducts(true)
  }, [trackingEvents])

  const handleProductsAdded = useCallback(
    (addedProducts) => {
      setIsAddingProducts(false)
      if (addedProducts) {
        let selected = value || fromJS([])
        addedProducts.map((added) => {
          let exists = false
          selected = selected.map((existing) => {
            if (existing.get('variantUUID') === added.get('variantUUID')) {
              exists = true
              return existing.set('quantity', existing.get('quantity') + 1)
            }
            return existing
          })
          if (!exists) {
            selected = selected.push(added)
          }
        })

        onChange?.(selected)
      }
    },
    [value, onChange, setIsAddingProducts]
  )

  const handleChange = useCallback(
    (nextValue) => {
      onChange?.(nextValue && nextValue.size ? nextValue : undefined)
    },
    [onChange]
  )

  const handleQuantityChange = useCallback(
    (uuid, quantity) => {
      handleChange(
        (value || fromJS([])).map((item) => {
          if (uuid === item.get('variantUUID')) {
            item = item.set('quantity', quantity)

            // if there is a percentage discount, then recalculate discount for new item quantity
            if (item.get('discountPercentage') > 0) {
              const discountData = {
                discount: item.get('discount'),
                discountPercentage: item.get('discountPercentage'),
              }
              item = applyItemDiscount(item, discountData)
            }

            // Make sure discount isn't bigger than subtotal
            const { subTotal } = calculateInvoiceEntryTotals(item)

            if (item.get('discount') > subTotal - 0.01) {
              item = item.set('discount', subTotal - 0.01)
            }
          }

          return item
        })
      )
    },
    [value, handleChange]
  )

  const handleProductRemove = useCallback(
    (uuid) => {
      handleChange((value || fromJS([])).filter((item) => uuid !== item.get('variantUUID')))
    },
    [value, handleChange]
  )

  const handleProductDiscount = useCallback(
    (uuid, discountToApply) => {
      setDiscountingItem(undefined)

      handleChange(
        (value || fromJS([])).map((item) =>
          uuid === item.get('variantUUID') ? applyItemDiscount(item, discountToApply) : item
        )
      )
    },
    [value, handleChange]
  )

  const addProductButton = (
    <FlatButton
      type='button'
      onClick={handleAddProductClick}
      testID={makeTestID(testID, baseTestID, 'addProduct')}
    >
      Add a product
    </FlatButton>
  )

  return (
    <div className={classes.root}>
      {value?.size ? (
        <>
          <List
            data={value.toArray()}
            renderItem={(item) => (
              <LineItem
                key={item.get('clientBillEntryIdentifier')}
                item={item}
                onQuantityChange={(nextQuantity) => {
                  handleQuantityChange(item.get('variantUUID'), nextQuantity)
                }}
                onDiscountPress={() => setDiscountingItem(item)}
                onRemovePress={() => handleProductRemove(item.get('variantUUID'))}
                testID={makeTestID(testID, baseTestID)}
              />
            )}
            testID={makeTestID(testID, baseTestID)}
          />
          <div className={classes.footer}>{addProductButton}</div>
        </>
      ) : (
        <div className={classes.empty} data-testid={makeTestID(testID, baseTestID, 'emptyBasket')}>
          <img src={emptyBasket} alt='Empty Basket, indicating no products added' /> <br />
          <b>No items were added</b> <br />
          <br />
          {addProductButton}
        </div>
      )}
      <AddProductPopup
        showing={isAddingProducts}
        onEsc={() => setIsAddingProducts(false)}
        onDone={handleProductsAdded}
        trackingEvents={trackingEvents}
        testID={makeTestID(testID, baseTestID)}
      />
      <DiscountPopup
        showing={!!discountingItem}
        discountingItem={discountingItem}
        onEsc={() => setDiscountingItem(undefined)}
        onDone={(discountData) => {
          handleProductDiscount(discountingItem.get('variantUUID'), discountData)
        }}
        testID={makeTestID(testID, baseTestID)}
      />
    </div>
  )
}

LineItems.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  trackingEvents: PropTypes.object,
  testID: PropTypes.string,
}

export default LineItems
