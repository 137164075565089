import React from 'react'
import { Icon } from '@yoco/design-system-icons/dist/react'
import classnames from 'classnames'
import FlatButton from 'components/buttons/FlatButton'
import LoadingView from 'components/loaders/LoadingView'
import RadioButtonForm from 'components/radioButton'
import popupClasses from 'components/capital/capital.module.scss'
import DECLINE_REASONS from 'components/capital/data/DECLINE_REASONS'
import * as forms from 'libs/forms'
import PopupComponent from 'libs/modals/popups'
import styles from 'libs/modals/popups.module.scss'
import Spacer from 'ui/layout/Spacer'
import { DeclineOfferData, DeclineReasons, LendingPartners } from 'redux/modules/capital/types'

const formName = 'CapitalDecline'

interface Props {
  showing: boolean
  offerUUID: string
  providerName: LendingPartners
  onOfferDeclined: (uuid: string, data: DeclineOfferData) => void
  onConfirm: () => void
  onCancel: () => void
}

export default class CapitalDeclinePopup extends PopupComponent {
  constructor(props: Props) {
    super(props)

    this.state = {
      showLoader: false,
      declineReason: DECLINE_REASONS[0].value,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props): void {
    super.UNSAFE_componentWillReceiveProps(nextProps)

    if (nextProps.showing !== this.props.showing) {
      this.setState({
        showLoader: false,
      })
    }
  }

  onClose(): void {
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  popoverStyles(): any {
    if (window && (window.innerWidth <= 500 || window.innerHeight <= 500)) {
      return {
        overflowY: 'auto',
        padding: '0%',
        width: '100%',
        height: 'auto',
        maxWidth: '800px',
      }
    }
    return {
      overflowY: 'auto',
      padding: '0%',
      width: '60%',
      height: 'auto',
      maxWidth: '800px',
    }
  }

  popoverBackgroundStyles(): { overflowY: string } {
    return {
      overflowY: 'auto',
    }
  }

  confirm(): void {
    this.setState({
      showLoader: true,
    })

    if (this.props.onConfirm) {
      this.props.onConfirm()
    }
  }

  onDeclineOffer = (): void => {
    const { declineReason } = this.state
    this.confirm()
    this.props.onOfferDeclined(this.props.offerUUID, {
      merchantBankConfirmation: false,
      merchantOfferConfirmation: false,
      declineReason,
    })
  }

  onRadioButtonPress = (value: DeclineReasons): void => {
    this.setState({
      declineReason: value,
    })
  }

  getContent(): React.ReactNode {
    return (
      <section>
        <LoadingView test={this.state.showLoader} />
        <button
          type='button'
          className={classnames([styles.capitalEscButton, styles.escButton, styles.modifiedButton])}
          onClick={this.onClose.bind(this)}
        >
          <Icon name='cross' size={24} />
        </button>
        <div className={popupClasses.capitalDeclinePopupTitleBox}>
          <div>
            <h1>Looking for something else?</h1>
          </div>
          <div>
            <p>Select an option below and we will make sure to give you a call back!</p>
          </div>
        </div>
        <div className={popupClasses.capitalDeclinePopupQuestionBox}>
          <div className={popupClasses.capitalDeclinePopupRadioButtonsWrapper}>
            <forms.Form name={formName} showLoader={false}>
              <RadioButtonForm options={DECLINE_REASONS} onPress={this.onRadioButtonPress} />
              <div className={popupClasses.capitalDeclinePopupButtons}>
                <Spacer isHorizontal size='xs' />
                <FlatButton
                  label='Decline offer'
                  onClick={this.onDeclineOffer}
                  className='destructive'
                />
              </div>
            </forms.Form>
          </div>
        </div>
      </section>
    )
  }
}
