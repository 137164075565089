import { useEffect } from 'react'

import { callInvoicesJSONAPI } from 'libs/api'
import store from 'redux/store'
import { dataLoading, initializeForm } from 'redux/modules/forms'
import { dueInSelectOptions } from 'containers/invoices/components/constants'

import { FormName } from './constants'

export const useInitialiseFormValues = () => {
  useEffect(() => {
    store.dispatch(dataLoading(FormName))

    const defaultDueInValue = dueInSelectOptions[0].value

    callInvoicesJSONAPI(`business`, 'GET')
      .then((response) => {
        store.dispatch(
          initializeForm(FormName, {
            dueIn: response.data?.dueIn || defaultDueInValue,
          })
        )
      })
      .catch(() => {
        store.dispatch(
          initializeForm(FormName, {
            dueIn: defaultDueInValue,
          })
        )
      })
  }, [])
}
