/* eslint-disable react/sort-comp */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react'
import { Map, List } from 'immutable'
import moment from 'moment'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import PropTypes from 'prop-types'

import ConnectedFilteredComponent from 'libs/filters/FilteredComponent'
import InsightCalculationPanel from 'components/insights/InsightCalculationPanel'
import { Tabs, TabPanel } from 'libs/tabs'
import { makeTestID, trackFeature } from 'libs/utils'
import SplitBlock from 'components/blocks/SplitBlock'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import DateHeader from 'components/headers/DateHeader'
import ExportButton from 'components/buttons/ExportButton'
import { track } from 'libs/analytics'
import { Table, EmptyTableView, SortableTableHeader } from 'libs/tables'
import { Amount, FormattedNumber } from 'libs/formats'

import SalesInsightsHeader from './SalesInsightsHeader'
import FeeDescription from './FeeDescription'
import VoucherMessage from './VoucherMessage'
import classes from './sales.module.scss'

const baseTestID = makeTestID('reports')

function ReportDateDisplay(props) {
  let start = moment().format('Do MMM YYYY')
  let end = moment().add(1, 'days').format('Do MMM YYYY')
  if (props.batch && props.batch.get('filters')) {
    start = moment(props.batch.getIn(['filters', 'created', 'values', '0'])).format('Do MMM YYYY')
    end = moment(props.batch.getIn(['filters', 'created', 'values', '1'])).format('Do MMM YYYY')
  }
  const startOfDayFormat = props.startOfBusinessDay === 0 ? 12 : props.startOfBusinessDay

  return (
    <div className='report-date-display'>
      {startOfDayFormat && `${startOfDayFormat}am `}
      <span className='date'>{start} </span>
      to {startOfDayFormat && `${startOfDayFormat}am `}
      <span className='date'>{end}</span>
    </div>
  )
}

ReportDateDisplay.propTypes = {
  batch: PropTypes.object,
  startOfBusinessDay: PropTypes.number,
}

class UnconnectedSalesReportPage extends Component {
  constructor(props) {
    super(props)

    this.oldIndex = -1
    this.state = {
      tabIndex: this.props.queryParams.report
        ? this.props.queryParams.report === 'products'
          ? 1
          : this.props.queryParams.report === 'staff'
          ? 2
          : this.props.queryParams.report === 'payments'
          ? 0
          : 0
        : 0,
      staffRowExpanded: [],
      paymentRowExpanded: [],
      productRowExpanded: [],
      categoryRowExpanded: List(),
      categoryTreeData: Map(),
    }
  }

  trackExport = (type) => track('SalesReport> Export Button Clicked', { type })

  trackProductExport = () => this.trackExport('products')

  trackTableViewed(index) {
    if (index !== this.oldIndex) {
      this.oldIndex = index

      switch (index) {
        case 0: {
          trackFeature('sales_payments_report', 'reports')
          break
        }
        case 1: {
          trackFeature('sales_items_report', 'reports')
          break
        }
        case 2: {
          trackFeature('sales_staff_report', 'reports')
          break
        }
        default:
      }
    }
  }

  closeSplitView() {
    this.setState({ showingSplit: false })
  }

  showCalculation(insightResult) {
    this.setState((prevState) => ({
      showingSplit: !prevState.showingSplit,
      insightResult,
    }))
  }

  tabChanged(index) {
    this.setState({
      tabIndex: index,
    })
  }

  onCategoryClick(rowData) {
    if (rowData.has('subItems')) {
      if (!this.state.categoryRowExpanded.includes(rowData.get('categoryUUID'))) {
        this.setState((prevState) => ({
          categoryRowExpanded: prevState.categoryRowExpanded.push(rowData.get('categoryUUID')),
        }))
      } else {
        this.setState((prevState) => ({
          categoryRowExpanded: prevState.categoryRowExpanded.filterNot((item) => {
            return item === rowData.get('categoryUUID')
          }),
        }))
      }

      if (rowData.get('subItems')) {
        let categoryTreeData = Map()
        rowData.get('subItems').forEach((subItem) => {
          let subItems = List()
          subItems = subItems.unshift(rowData.get('categoryUUID')).push(subItem.get('categoryUUID'))
          if (rowData.get('parentListUUID').size) {
            subItems = rowData.get('parentListUUID').concat(subItems)
          }
          let stateCategoryMap = Map().setIn(subItems.toSet().toList().toJS(), true)
          if (this.state.categoryTreeData.getIn(subItems.toSet().toList().toJS())) {
            stateCategoryMap = Map().setIn(subItems.toSet().toList().pop().toJS(), true)
          }
          categoryTreeData = categoryTreeData.mergeDeep(stateCategoryMap)
        })
        this.setState((prevState) => ({
          categoryTreeData: prevState.categoryTreeData.mergeDeep(categoryTreeData),
        }))
      }
    }
  }

  getProductsTable() {
    return (
      <Table
        name='salesProducts'
        filterName='salesReport'
        api='/sales/item-report'
        defaultSort='amount'
        hideNoResults
        hasResponsive
        waitForFilterLoad
        useSubItems
        useOffset
        isVisible={this.state.tabIndex === 1}
        emptyTable={
          <EmptyTableView
            title='No products sold during period!'
            testID={makeTestID(baseTestID, 'products', 'emptyState')}
          >
            <span>
              When you sell <Link to='/items/'>items</Link> you will get insights about them here.
            </span>
          </EmptyTableView>
        }
        getHeader={() => {
          return (
            <tr className='visible-header'>
              <th className='ranking' />
              <SortableTableHeader column='name' tableName='salesProducts' className='product-name'>
                Name
              </SortableTableHeader>
              <SortableTableHeader column='sku' tableName='salesProducts' className='sku'>
                SKU
              </SortableTableHeader>
              <SortableTableHeader
                column='quantity'
                tableName='salesProducts'
                className='product-quantity'
              >
                Quantity
              </SortableTableHeader>
              <SortableTableHeader
                column='grossProfit'
                tableName='salesProducts'
                className='product-gross amount'
              >
                Gross Profit
              </SortableTableHeader>
              <SortableTableHeader
                column='amount'
                tableName='salesProducts'
                className='product-total amount'
              >
                Total
              </SortableTableHeader>
            </tr>
          )
        }}
        getGroupHeader={(rowData, index) => {
          const className = rowData.get('subItems') ? 'group-header-row' : 'underlined-row'

          return (
            <tr className={className} key={`product-row-${index}`}>
              <td className='ranking'>{index + 1}</td>
              <td className='product-name'>{rowData.get('name')}</td>
              <td className='sku'>{rowData.get('sku') ? rowData.get('sku') : ''}</td>
              <td className='product-quantity'>{rowData.get('quantity')}</td>
              <td className='product-gross'>
                {rowData.get('noCostPrice') ? (
                  <div className='amount text-right'>-</div>
                ) : (
                  <Amount amount={rowData.get('grossProfit')} />
                )}
              </td>
              <td className='product-total'>
                <Amount amount={rowData.get('amount')} />
              </td>
            </tr>
          )
        }}
        getRow={(rowData, groupIndex, index, lastSubrow) => {
          const className = lastSubrow ? 'underlined-row child-row' : 'child-row'

          return (
            !!rowData && (
              <tr className={className} key={`product-row-${groupIndex}-${index}`}>
                <td className='ranking' />
                <td className='product-name subtext'>{rowData.get('name')}</td>
                <td className='sku' />
                <td className='product-quantity subtext'>{rowData.get('quantity')}</td>
                <td className='product-gross subtext'>
                  {rowData.get('noCostPrice') ? (
                    <div className='amount text-right'>-</div>
                  ) : (
                    <Amount amount={rowData.get('grossProfit')} />
                  )}
                </td>
                <td className='product-total subtext'>
                  <Amount amount={rowData.get('amount')} />
                </td>
              </tr>
            )
          )
        }}
        getResponsiveRow={(rowData, index) => {
          let rowClass = 'product-row'
          if (rowData.get('subItems')) {
            rowClass += ' expandable'
          }

          if (this.state.productRowExpanded[index]) {
            rowClass += ' expanded'
          }

          return (
            <div
              className={rowClass}
              onClick={this.expandProductResponsiveRow.bind(this, index)}
              key={`product-responsive-row-${index}`}
            >
              <span className='title'>{rowData.get('name')}</span>
              <span className='quantity'>{rowData.get('quantity')}</span>
              {this.getProductResponsiveRowChildren(rowData, index)}
            </div>
          )
        }}
        footerAPI='/sales/bill-discounts'
        getFooter={(billDiscountData) => {
          return (
            <tr className='underlined-row footerDiscountRow' key='product-bill-row'>
              <td />
              <td className='product-name'>Bill discounts</td>
              <td />
              <td />
              <td />
              <td className='product-total'>
                <Amount amount={-billDiscountData.billDiscountsTotal} />
              </td>
            </tr>
          )
        }}
      />
    )
  }

  getBrandsTable() {
    return (
      <Table
        name='salesBrands'
        filterName='salesReport'
        api='/sales/brand-report'
        defaultSort='amount'
        hideNoResults
        hasResponsive
        waitForFilterLoad
        useSubItems
        useOffset
        isVisible={this.state.tabIndex === 1}
        emptyTable={
          <EmptyTableView
            title='No products sold during period!'
            testID={makeTestID(baseTestID, 'categories', 'emptyState')}
          >
            <span>
              When you sell <Link to='/items/'>items</Link> you will get insights about them here.
            </span>
          </EmptyTableView>
        }
        getHeader={() => {
          return (
            <tr className='visible-header'>
              <th className='ranking' />
              <SortableTableHeader column='name' tableName='salesBrands' className='product-name'>
                Brands
              </SortableTableHeader>
              <SortableTableHeader
                column='quantity'
                tableName='salesBrands'
                className='product-quantity'
              >
                Quantity
              </SortableTableHeader>
              <SortableTableHeader
                column='grossProfit'
                tableName='salesBrands'
                className='product-gross amount'
              >
                Gross Profit
              </SortableTableHeader>
              <SortableTableHeader
                column='amount'
                tableName='salesBrands'
                className='product-total amount'
              >
                Total
              </SortableTableHeader>
            </tr>
          )
        }}
        getGroupHeader={(rowData, index) => {
          const className = rowData.get('subItems') ? 'group-header-row' : 'underlined-row'
          return (
            !!rowData && (
              <tr className={className} key={`product-row-${index}`}>
                <td className='ranking'>{index + 1}</td>
                <td className='product-name'>{rowData.get('name')}</td>
                <td className='product-quantity'>{rowData.get('quantity')}</td>
                <td className='product-gross'>
                  <Amount amount={rowData.get('grossProfit')} />
                </td>
                <td className='product-total'>
                  <Amount amount={rowData.get('amount')} />
                </td>
              </tr>
            )
          )
        }}
        getRow={(rowData, groupIndex, index, lastSubrow) => {
          const className = lastSubrow ? 'underlined-row child-row' : 'child-row'
          return (
            !!rowData && (
              <tr className={className} key={`product-row-${groupIndex}-${index}`}>
                <td className='ranking' />
                <td className='product-name subtext'>{rowData.get('name')}</td>
                <td className='product-quantity subtext'>{rowData.get('quantity')}</td>
                <td className='product-gross subtext'>
                  <Amount amount={rowData.get('grossProfit')} />
                </td>
                <td className='product-total subtext'>
                  <Amount amount={rowData.get('amount')} />
                </td>
              </tr>
            )
          )
        }}
        getResponsiveRow={(rowData, index) => {
          let rowClass = 'product-row'
          if (!!rowData && rowData.get('subItems')) {
            rowClass += ' expandable'
          }

          if (this.state.productRowExpanded[index]) {
            rowClass += ' expanded'
          }

          return (
            !!rowData && (
              <div
                className={rowClass}
                onClick={this.expandProductResponsiveRow.bind(this, index)}
                key={`product-responsive-row-${index}`}
              >
                <span className='title'>{rowData.get('name')}</span>
                <span className='quantity'>{rowData.get('quantity')}</span>
                {this.getProductResponsiveRowChildren(rowData, index)}
              </div>
            )
          )
        }}
        footerAPI='/sales/bill-discounts'
        getFooter={(billDiscountData) => {
          return (
            <tr className='underlined-row footerDiscountRow' key='brands-bill-row'>
              <td />
              <td className='product-name'>Bill discounts</td>
              <td />
              <td />
              <td className='product-total'>
                <Amount amount={-billDiscountData.billDiscountsTotal} />
              </td>
            </tr>
          )
        }}
      />
    )
  }

  getSplitContent() {
    if (this.state.insightResult) {
      return <InsightCalculationPanel insightResult={this.state.insightResult} />
    }

    return undefined
  }

  getCategoriesTable() {
    return (
      <Table
        name='salesCategories'
        filterName='salesReport'
        api='/sales/category-report'
        defaultSort='amount'
        hideNoResults
        parentKey='categoryUUID'
        hasResponsive
        waitForFilterLoad
        useSubItems
        useOffset
        isVisible={this.state.tabIndex === 1}
        emptyTable={
          <EmptyTableView
            title='No products sold during period!'
            testID={makeTestID(baseTestID, 'brands', 'emptyState')}
          >
            <span>
              When you sell <Link to='/items/'>items</Link> you will get insights about them here.
            </span>
          </EmptyTableView>
        }
        getHeader={() => {
          return (
            <tr className='visible-header'>
              <th className='ranking' />
              <SortableTableHeader
                column='name'
                tableName='salesCategories'
                className='product-name'
              >
                Category
              </SortableTableHeader>
              <SortableTableHeader
                column='quantity'
                tableName='salesCategories'
                className='product-quantity'
              >
                Quantity
              </SortableTableHeader>
              <SortableTableHeader
                column='grossProfit'
                tableName='salesCategories'
                className='product-gross amount'
              >
                Gross Profit
              </SortableTableHeader>
              <SortableTableHeader
                column='amount'
                tableName='salesCategories'
                className='product-total amount'
              >
                Total
              </SortableTableHeader>
            </tr>
          )
        }}
        getGroupHeader={(rowData, index, indentation) => {
          const className = rowData.get('subItems')
            ? 'group-header-row hoverable'
            : 'underlined-row'
          const iconType = this.state.categoryRowExpanded.includes(rowData.get('categoryUUID'))
            ? 'minus'
            : 'add'
          return (
            <tr className={className} key={`product-row-${index}`}>
              <td className='ranking' />
              <td
                className='product-name'
                style={{ paddingLeft: `${indentation * 20}px` }}
                onClick={() => this.onCategoryClick(rowData)}
              >
                {rowData.get('subItems') && <span className={`icon2-${iconType}-4`} />}{' '}
                {rowData.get('name')}
              </td>
              <td className='product-quantity'>{rowData.get('quantity')}</td>
              <td className='product-gross'>
                <Amount amount={rowData.get('grossProfit')} />
              </td>
              <td className='product-total'>
                <Amount amount={rowData.get('amount')} />
              </td>
            </tr>
          )
        }}
        getRow={(rowData, groupIndex, index, lastSubrow, indentation) => {
          const parentListUUID = rowData
            .get('parentListUUID', List())
            .push(rowData.get('categoryUUID'))
          if (
            rowData &&
            (rowData.get('subItems') || (!rowData.get('subItems') && rowData.get('parentUUID')))
          ) {
            if (!this.state.categoryTreeData.getIn(parentListUUID.toJS())) return null
          }
          const iconType =
            this.state.categoryTreeData.getIn(parentListUUID.toJS()) !== true ? 'minus' : 'add'
          const className = lastSubrow ? 'underlined-row child-row' : 'hoverable child-row'
          const padding = rowData.has('subItems') ? 20 : 30
          return (
            <tr className={className} key={`product-row-${groupIndex}-${index}`}>
              <td className='ranking' />
              <td
                className='product-name subtext'
                style={{ paddingLeft: `${indentation * 20 + padding}px` }}
                onClick={() => this.onCategoryClick(rowData)}
              >
                {rowData.get('subItems') ? <span className={`icon2-${iconType}-3`} /> : null}{' '}
                {rowData.get('name')}
              </td>
              <td className='product-quantity subtext'>{rowData.get('quantity')}</td>
              <td className='product-gross subtext'>
                <Amount amount={rowData.get('grossProfit')} />
              </td>
              <td className='product-total subtext'>
                <Amount amount={rowData.get('amount')} />
              </td>
            </tr>
          )
        }}
        getResponsiveRow={(row, index) => {
          const parentListUUID = List()
          const indentation = 0
          if (!row.get('subItems')) {
            return this.getProductResponsiveRowChildrenHeader(row, index)
          }
          if (row && row.get('subItems')) {
            // eslint-disable-next-line no-param-reassign
            row = row.set('parentListUUID', parentListUUID)
            return this.getNestedProductResponsiveRowChildren(
              row,
              index,
              'categoryUUID',
              indentation
            )
          }
          return null
        }}
        footerAPI='/sales/bill-discounts'
        getFooter={(billDiscountData) => {
          return (
            <tr className='underlined-row child-row footerDiscountRow' key='categories-bill-row'>
              <td />
              <td className='product-name'>Bill discounts</td>
              <td />
              <td />
              <td className='product-total'>
                <Amount amount={-billDiscountData.billDiscountsTotal} />
              </td>
            </tr>
          )
        }}
      />
    )
  }

  expandProductResponsiveRow(rowIndex) {
    this.setState((currentState) => {
      const newState = { ...currentState }
      newState.productRowExpanded[rowIndex] = !newState.productRowExpanded[rowIndex]
      return newState
    })
  }

  getProductResponsiveRowChildren(row) {
    if (row.get('subItems')) {
      let loopIndex = 0
      return (
        <div className='list'>
          {row.get('subItems').map((subItem) => {
            loopIndex += 1
            if (subItem) {
              return (
                <div className='subItem' key={`product-responsive-child-${loopIndex}`}>
                  <div className='name'>{subItem.get('title')}</div>
                  {this.getResponsiveLineItem(
                    `${subItem.get('quantity')} x ${subItem.get('name')}`,
                    subItem.get('amount'),
                    false
                  )}
                </div>
              )
            }
            return null
          })}
        </div>
      )
    }

    return undefined
  }

  getProductResponsiveRowChildrenHeader(rowData, index) {
    let rowClass = 'product-row'
    if (rowData.get('subItems')) {
      rowClass += ' expandable'
    }

    if (this.state.productRowExpanded[index]) {
      rowClass += ' expanded'
    }
    return (
      <div className={rowClass} key={`product-responsive-row-${index}`}>
        <div onClick={() => this.onCategoryClick(rowData)}>
          <span className='title'>{`${rowData.get('name')}`}</span>
          <span className='quantity'>{rowData.get('quantity')}</span>
        </div>
      </div>
    )
  }

  getProductResponsiveRowChildrenRow(subItem, index, groupIndex, lastSubrow, indentation) {
    return (
      <div className='list' onClick={() => this.onCategoryClick(subItem)}>
        <div className='subItem' key={`product-responsive-child-${index}`}>
          <div className='name'>{subItem.get('title')}</div>
          <div style={{ paddingLeft: `${indentation * 8}px` }}>
            {this.getResponsiveLineItem(
              `${subItem.get('quantity')} x ${subItem.get('name')}`,
              subItem.get('amount'),
              false
            )}
          </div>
        </div>
      </div>
    )
  }

  getNestedProductResponsiveRowChildren(row, index = 1, parentKey = null, indentation) {
    const group = []
    let groupIndex = -1
    let parentListUUID = row.get('parentListUUID').push(row.get(parentKey))
    if (!this.state.categoryTreeData.getIn(parentListUUID.toJS()) && indentation > 0) return null
    if (row.get('subItems')) {
      const lastIndex = row.get('subItems').size - 1

      if (indentation < 1) {
        group.push(this.getProductResponsiveRowChildrenHeader(row, index))
      } else {
        group.push(
          this.getProductResponsiveRowChildrenRow(
            row,
            groupIndex,
            index,
            groupIndex === lastIndex,
            indentation
          )
        )
      }

      row.get('subItems').forEach((subItem) => {
        if (!subItem.get('subItems')) {
          parentListUUID = parentListUUID.push(subItem.get(parentKey))
        }
        subItem = subItem.set('parentUUID', row.get(parentKey))
        subItem = subItem.set('parentListUUID', parentListUUID.toSet().toList())

        groupIndex += 1

        if (subItem.get('subItems')) {
          group.push(
            this.getNestedProductResponsiveRowChildren(
              subItem,
              index + 1,
              parentKey,
              indentation + 1
            )
          )
        } else if (this.state.categoryTreeData.getIn(parentListUUID.toJS())) {
          group.push(
            this.getProductResponsiveRowChildrenRow(
              subItem,
              groupIndex,
              index,
              groupIndex === lastIndex,
              indentation + 1
            )
          )
        }
      })
    }
    return group
  }

  getResponsiveLineItem(label, value, isNumber, className) {
    let lineClass = 'line-item clearfix'
    if (className) {
      lineClass += ` ${className}`
    }

    if (value) {
      if (isNumber) {
        return (
          <div className={lineClass}>
            {label}
            <div className='pull-right'>
              <FormattedNumber number={value} />
            </div>
          </div>
        )
      }

      return (
        <div className={lineClass}>
          {label}
          <div className='pull-right'>
            <Amount amount={value} />
          </div>
        </div>
      )
    }

    return undefined
  }

  getStaffResponsiveRowChildren(row) {
    if (row.get('subItems')) {
      let loopIndex = 0
      return (
        <div>
          {row.get('subItems').map((subItem) => {
            loopIndex += 1
            return (
              <div className='subItem' key={`staff-responsive-child-${loopIndex}`}>
                <div className='title'>{subItem.get('description')}</div>
                {this.getResponsiveLineItem('Card amount', subItem.get('cardAmount'), false)}
                {this.getResponsiveLineItem('Cash amount', subItem.get('cashAmount'), false)}
                {this.getResponsiveLineItem('Other amount', subItem.get('customAmount', 0), false)}
                {this.getResponsiveLineItem(
                  'Tip amount',
                  subItem.get('tipAmount', 0) * -1,
                  false,
                  'no-red'
                )}
                {this.getResponsiveLineItem('Total', subItem.get('total'), false)}
              </div>
            )
          })}
        </div>
      )
    }

    return undefined
  }

  expandStaffResponsiveRow(rowIndex) {
    this.setState((currentState) => {
      const newState = { ...currentState }
      newState.staffRowExpanded[rowIndex] = !newState.staffRowExpanded[rowIndex]

      return newState
    })
  }

  getStaffTable() {
    return (
      <Table
        name='salesStaff'
        filterName='salesReport'
        api='/sales/staff-report'
        defaultSort='name'
        useSubItems
        hideNoResults
        hasResponsive
        waitForFilterLoad
        isVisible={this.state.tabIndex === 2}
        dataLoaded={(data) => {
          this.dataLoaded(data)
        }}
        emptyTable={
          <EmptyTableView
            title='No staff transacted during period!'
            testID={makeTestID(baseTestID, 'staff', 'emptyState')}
          >
            <span>
              When your <Link to='/business/staff/'>staff</Link> transact you will get insights
              about them here.
            </span>
          </EmptyTableView>
        }
        getHeader={() => {
          let tipsHeader = null
          if (this.state.showTips) {
            tipsHeader = (
              <SortableTableHeader column='tips' tableName='salesStaff' className='amount'>
                Tips
              </SortableTableHeader>
            )
          }

          let otherHeader = null
          if (this.state.showOther) {
            otherHeader = <th className='amount'>Other</th>
          }

          return (
            <tr className='visible-header'>
              <th className='ranking' />
              <SortableTableHeader column='name' tableName='salesStaff' className='staff-member'>
                Staff member
              </SortableTableHeader>
              <th className='amount'>Card</th>
              <th className='amount'>Cash</th>
              {otherHeader}
              {tipsHeader}
              <SortableTableHeader column='total' tableName='salesStaff' className='amount'>
                Total
              </SortableTableHeader>
            </tr>
          )
        }}
        getRow={(rowData, index) => {
          const className = index === 1 ? 'underlined-row' : ''

          let tipsCell = null
          if (this.state.showTips) {
            tipsCell = (
              <td className='amount subtext no-red'>
                <Amount showZeroAsDash amount={rowData.get('tipAmount', 0) * -1} />
              </td>
            )
          }
          let otherCell = null
          if (this.state.showOther) {
            otherCell = (
              <td className='amount subtext'>
                <Amount showZeroAsDash amount={rowData.get('customAmount', 0)} />
              </td>
            )
          }

          return (
            <tr className={className} key={`staff-row-${index}`}>
              <td className='ranking' />
              <td className='staff-member subtext'>{rowData.get('description')}</td>
              <td className='amount subtext'>
                <Amount showZeroAsDash amount={rowData.get('cardAmount')} />
              </td>
              <td className='amount subtext'>
                <Amount showZeroAsDash amount={rowData.get('cashAmount')} />
              </td>
              {otherCell}
              {tipsCell}
              <td className='amount subtext'>
                <Amount showZeroAsDash amount={rowData.get('total')} />
              </td>
            </tr>
          )
        }}
        getGroupHeader={(group, index) => {
          const className = group.get('subItems') ? '' : 'underlined-row'

          let tipsCell = null
          if (this.state.showTips) {
            tipsCell = (
              <td className='amount no-red'>
                <Amount showZeroAsDash amount={group.get('tipAmount', 0) * -1} />
              </td>
            )
          }

          let otherCell = null
          if (this.state.showOther) {
            otherCell = (
              <td className='amount'>
                <Amount showZeroAsDash amount={group.get('customAmount', 0)} />
              </td>
            )
          }

          return (
            <tr className={className} key={`staff-groupheader-${index}`}>
              <td className='ranking'>{index + 1}</td>
              <td className='staff-member'>{group.get('name')}</td>
              <td className='amount'>
                <Amount showZeroAsDash amount={group.get('cardAmount')} />
              </td>
              <td className='amount'>
                <Amount showZeroAsDash amount={group.get('cashAmount')} />
              </td>
              {otherCell}
              {tipsCell}
              <td className='amount'>
                <Amount showZeroAsDash amount={group.get('total')} />
              </td>
            </tr>
          )
        }}
        getResponsiveRow={(row, rowIndex) => {
          let rowClass = 'payment-row expandable'

          if (this.state.staffRowExpanded[rowIndex]) {
            rowClass += ' expanded'
          }

          return (
            <div
              className={rowClass}
              onClick={this.expandStaffResponsiveRow.bind(this, rowIndex)}
              key={`staff-responsive-${rowIndex}`}
            >
              <span className='title'>{row.get('name')}</span>
              <Amount amount={row.get('total')} />
              <div className='list'>
                {this.getResponsiveLineItem('Card amount', row.get('cardAmount'), false)}
                {this.getResponsiveLineItem('Cash amount', row.get('cashAmount'), false)}
                {this.getResponsiveLineItem('Other amount', row.get('customAmount', 0), false)}
                {this.getResponsiveLineItem(
                  'Tip amount',
                  row.get('tipAmount', 0) * -1,
                  false,
                  'no-red'
                )}
                {this.getStaffResponsiveRowChildren(row, rowIndex)}
              </div>
            </div>
          )
        }}
      />
    )
  }

  dataLoaded(data) {
    let showOther = false
    data.get('staff', List()).forEach((staff) => {
      if (staff.get('customAmount') > 0) {
        showOther = true
      }
    })

    this.setState({
      showTips: data.get('hasTips'),
      showOther,
    })
  }

  getPaymentTable() {
    return (
      <Table
        name='salesPayments'
        filterName='salesReport'
        api='/sales/payment-report'
        isVisible={this.state.tabIndex === 0}
        useSubItems
        hideNoResults
        hasResponsive
        waitForFilterLoad
        dataLoaded={(data) => {
          this.dataLoaded(data)
        }}
        emptyTable={
          <EmptyTableView
            title='No payments during period!'
            testID={makeTestID(baseTestID, 'payments', 'emptyState')}
          >
            <span>When you make payments you will see their breakdown and fees here.</span>
          </EmptyTableView>
        }
        getHeader={() => {
          return (
            <tr className='visible-header'>
              <th className='ranking' />
              <th className='payment-type'>Payment type</th>
              <th className='number'>Count</th>
              <th className='amount'>Total</th>
              <th className='amount no-red'>Fees</th>
              <th className='amount'>Net</th>
            </tr>
          )
        }}
        getRow={(rowData, groupIndex, index, lastSubrow) => {
          const className = lastSubrow ? 'underlined-row child-row' : 'child-row'

          return (
            <tr className={className} key={`payment-row-${groupIndex}-${index}`}>
              <td className='ranking' />
              <td className='payment-type subtext'>{rowData.get('title')}</td>
              <td className='number subtext'>
                <FormattedNumber number={rowData.get('count')} alignLeft format='0,0' />
              </td>
              <td className='amount subtext'>
                <Amount amount={rowData.get('total')} />
              </td>
              <td className='amount subtext no-red' style={{ overflow: 'visible' }}>
                <Amount showZeroAsDash amount={rowData.get('fees', 0) * -1} />
                <FeeDescription payment={rowData} />
              </td>
              <td className='amount subtext'>
                <Amount amount={rowData.get('net')} />
              </td>
            </tr>
          )
        }}
        getGroupHeader={(group, index) => {
          const className = group.get('subItems') ? 'group-header-row' : 'underlined-row'

          return (
            <tr className={className} key={`payment-groupheader-${index}`}>
              <td className='ranking'>{index + 1}</td>
              <td className='payment-type'>{group.get('title')}</td>
              <td className='number'>
                <FormattedNumber number={group.get('count')} alignLeft format='0,0' />
              </td>
              <td className='amount'>
                <Amount amount={group.get('total')} />
              </td>
              <td className='amount no-red'>
                <Amount showZeroAsDash amount={group.get('fees', 0) * -1} />
              </td>
              <td className='amount'>
                <Amount amount={group.get('net')} />
              </td>
            </tr>
          )
        }}
        getResponsiveRow={(row, rowIndex) => {
          let rowClass = 'payment-row'
          if (row.get('subItems')) {
            rowClass += ' expandable'
          }

          if (this.state.paymentRowExpanded[rowIndex]) {
            rowClass += ' expanded'
          }

          return (
            <div
              className={rowClass}
              onClick={this.expandPaymentResponsiveRow.bind(this, rowIndex)}
              key={`payment-responsive-${rowIndex}`}
            >
              <span className='title'>{row.get('title')}</span>
              <Amount amount={row.get('total')} />
              {this.getPaymentResponsiveRowChildren(row, rowIndex)}
            </div>
          )
        }}
      />
    )
  }

  expandPaymentResponsiveRow(rowIndex) {
    this.setState((currentState) => {
      const newState = { ...currentState }
      newState.paymentRowExpanded[rowIndex] = !newState.paymentRowExpanded[rowIndex]

      return newState
    })
  }

  getPaymentResponsiveRowChildren(row) {
    if (row.get('subItems')) {
      let loopIndex = 0
      return (
        <div className='list'>
          {row.get('subItems').map((subItem) => {
            loopIndex += 1
            return (
              <div className='subItem' key={loopIndex}>
                <div className='title'>{subItem.get('title')}</div>
                {this.getResponsiveLineItem('Count', subItem.get('count'), true)}
                {this.getResponsiveLineItem('Total', subItem.get('total'), false)}
                {this.getResponsiveLineItem('Fees', subItem.get('fees', 0) * -1, false, 'no-red')}
                {this.getResponsiveLineItem('Net', subItem.get('net'), false)}
              </div>
            )
          })}
        </div>
      )
    }

    return undefined
  }

  getExportSalesButton() {
    return this.state.tabIndex === 1 ? (
      <ExportButton
        name='salesReport'
        className={classes.exportReport}
        label='Export sales by product'
        onClick={this.trackProductExport}
        exportApi='/sales/export/sales.csv'
      />
    ) : null
  }
  render() {
    this.trackTableViewed(this.state.tabIndex)
    return (
      <SplitBlock
        hideActionButtonBar
        showingSplit={this.state.showingSplit}
        onDismiss={() => this.closeSplitView()}
        header='Insight calculated from'
        renderSplitContent={() => this.getSplitContent()}
      >
        <MaxWidthBlock className={classes.salesReport}>
          <DateHeader title='Sales report for' name='salesReport'>
            {this.getExportSalesButton()}
          </DateHeader>
          <ConnectedFilteredComponent
            name='salesReport'
            renderContent={(props) => <ReportDateDisplay {...props} />}
          />
          <SalesInsightsHeader
            businessUUID={this.props.businessUUID}
            showCalculation={(insight) => this.showCalculation(insight)}
            name='salesReport'
          />
          <Tabs tabChanged={(index) => this.tabChanged(index)} tabIndex={this.state.tabIndex}>
            <TabPanel title='Payments'>
              {this.getPaymentTable()}
              <VoucherMessage renderContent={this.props.hasVoucherTransactions} />
            </TabPanel>
            <TabPanel title='Products'>{this.getProductsTable()}</TabPanel>
            <TabPanel title='Staff'>{this.getStaffTable()}</TabPanel>
            <TabPanel title='Categories'>{this.getCategoriesTable()}</TabPanel>
            <TabPanel title='Brands'>{this.getBrandsTable()}</TabPanel>
          </Tabs>
        </MaxWidthBlock>
      </SplitBlock>
    )
  }
}

UnconnectedSalesReportPage.propTypes = {
  queryParams: PropTypes.object,
  hasVoucherTransactions: PropTypes.bool,
  businessUUID: PropTypes.string,
}

export default connect((state) => ({
  queryParams: state.routing.locationBeforeTransitions.query,
  hasVoucherTransactions: state.tables
    .getIn(['salesPayments', 'data'], List())
    .some((e) => e.get('title') === 'Vouchers Bought'),
  businessUUID: state.session.getIn(['business', 'uuid']),
}))(UnconnectedSalesReportPage)
