import React, { Component } from 'react'
import PropTypes from 'prop-types'

import classes from 'components/cards/cards.module.scss'

export default class TitleCard extends Component {
  renderChildren() {
    return React.Children.map(this.props.children, (child) => {
      return <div className={classes.content}>{child}</div>
    })
  }

  render() {
    return (
      <div>
        <div className={classes.titleCard}>
          <div className={classes.banner}>
            <h1 className={classes.title}>{this.props.title}</h1>
          </div>
          {this.renderChildren()}
        </div>
      </div>
    )
  }
}

TitleCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
}
