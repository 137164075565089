/* eslint-disable no-param-reassign */
export const calculateInvoiceEntryTotals = (entry) => {
  if (typeof entry.toJS === 'function') {
    entry = entry.toJS()
  }

  const subTotal = entry.price * entry.quantity

  const discountTotal = entry.discountPercentage
    ? subTotal * (entry.discountPercentage / 100)
    : entry.discount

  const total = subTotal - discountTotal

  const vatTotal = entry.salesTaxes
    ? entry.salesTaxes.reduce((r, tax) => r + total * tax.taxPercentageOfTotal, 0)
    : 0

  return {
    subTotal,
    discountTotal,
    vatTotal,
    total,
  }
}
