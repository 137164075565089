import React from 'react'
import PropTypes from 'prop-types'

import { Amount, FormattedNumber } from 'libs/formats'

function InsightAmountText({ insightResult, style }) {
  if (insightResult) {
    if (insightResult.insight.isPercentage) {
      const percentage = ' %'
      return (
        <span style={style}>
          <FormattedNumber number={insightResult.insight.value} alignLeft format='0,0.0' />
          {percentage}
        </span>
      )
    }
    if (insightResult.insight.value && insightResult.insight.value !== 0) {
      return <Amount style={style} amount={insightResult.insight.value} alignLeft />
    }
    return <span>-</span>
  }
  return <span>?</span>
}

InsightAmountText.propTypes = {
  insightResult: PropTypes.object,
  style: PropTypes.object,
}

export default InsightAmountText
