import { statusBackgroundMap, statusColorMap } from './constants'

export const getStatusStyles = (
  status,
  colorMap = statusColorMap,
  backgroundMap = statusBackgroundMap
) => ({
  color: colorMap[status] || colorMap.default,
  backgroundColor: backgroundMap[status] || backgroundMap.default,
})
