/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'

import store from 'redux/store'
import { initializeForm, submitResult, showSuccess, showError } from 'redux/modules/forms'
import { track } from 'libs/analytics'
import * as forms from 'libs/forms'
import { callInvoicesJSONAPI } from 'libs/api'

import { selectBankAccount } from 'redux/modules/onboarding/bankDetails/selectors'
import { FormFieldNames, FormName, FormSubmissionFlags } from './constants'
import renderFormFields from './renderFormFields'

function UpdateInvoice({ invoice, bankingDetails, onUpdated }) {
  const invoiceId = invoice.get('id')

  useEffect(() => {
    store.dispatch(initializeForm(FormName, invoice))
  }, [invoice])

  return (
    <forms.Form
      name={FormName}
      noWrap
      onSubmit={(data) => {
        const { dispatch } = store

        // Store and reset the flag
        const isSavedAsDraft = FormSubmissionFlags.saveAsDraft
        FormSubmissionFlags.saveAsDraft = false
        if (!isSavedAsDraft) {
          track('invoices_draft_invoice_next_button_clicked', {
            name: 'invoices_draft_invoice_next_button_clicked',
          })
        }

        data = data
          .set('customerId', data.getIn([FormFieldNames.Customer, 'id']))
          // eslint-disable-next-line radix
          .set(FormFieldNames.DueIn, parseInt(data.get(FormFieldNames.DueIn) || '0'))

        if (Object.keys(bankingDetails).length > 0) {
          data = data.set('bankingDetails', bankingDetails.delete('accountHolder'))
        }

        callInvoicesJSONAPI(`invoices/${invoiceId}`, 'PATCH', data)
          .then((response) => {
            dispatch(submitResult(FormName, response.data))
            dispatch(showSuccess(FormName, { message: 'Invoice successfully saved!' }))
            onUpdated?.(fromJS(response.data), isSavedAsDraft)
          })
          .catch(() => {
            dispatch(submitResult(FormName, {}))
            dispatch(showError(FormName, 'Failed to save invoice!'))
          })
      }}
      scrollToTop={false}
      showLoader
      loaderClass='spinner-container'
      showSuccess
      showError
    >
      {renderFormFields()}
    </forms.Form>
  )
}

UpdateInvoice.propTypes = {
  invoice: PropTypes.object.isRequired,
  bankingDetails: PropTypes.object,
  onUpdated: PropTypes.func,
}

export default connect((state) => ({
  bankingDetails: selectBankAccount(state),
}))(UpdateInvoice)
