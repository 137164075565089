import { Component } from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'

/*
  This class effectively does 2 things.
  1. It listens for the esc key pressed, and fires escPressed (or onEsc prop).
  2. It removes the -webkit-overflow-scrolling: touch; property from all '.touch' classes,
  this is useful on iOS devices as otherwise the modal is confined to the previous scrolling element
*/
export default class Modal extends Component {
  UNSAFE_componentWillMount() {
    window.addEventListener('keydown', this.handleKeyDown.bind(this))
  }

  componentDidMount() {
    if (this.props.showing) {
      this.removeTouchClasses()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.showing) {
      this.removeTouchClasses()
    } else {
      this.addTouchClasses()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown.bind(this))

    this.addTouchClasses()
  }

  handleKeyDown(event) {
    if (event.keyCode === 27) {
      this.escPressed()
    }
  }

  removeTouchClasses() {
    let removedTouchClasses = false
    $('.touch').each(function () {
      if (!$(this).hasClass('no-touch')) {
        $(this).addClass('no-touch')
        removedTouchClasses = true
      }
    })

    if (removedTouchClasses) {
      this.removedTouchClasses = removedTouchClasses
    }
  }

  addTouchClasses() {
    if (this.removedTouchClasses) {
      this.removedTouchClasses = false
      $('.no-touch').each(function () {
        $(this).removeClass('no-touch')
      })
    }
  }

  escPressed() {
    if (this.props.onEsc && this.props.showing) {
      this.props.onEsc()
    }
  }
}

Modal.propTypes = {
  showing: PropTypes.bool,
  onEsc: PropTypes.func,
}
