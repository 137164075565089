import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { callInvoicesJSONAPI } from 'libs/api'
import { Table, TableRow, EmptyTableView } from 'libs/tables'
import { formatCurrency } from 'libs/utils'
import * as TableActions from 'redux/modules/tables'
import store from 'redux/store'
import { InvoiceStatus } from 'containers/invoices/components/Invoices'

import { TableName, TablePageSize } from './constants'
import { buildInvoiceUrlParams } from './Table.utils'

const baseTestID = 'invoicesTable'

const renderDateCell = (dateStr) => {
  if (!dateStr) {
    return '-'
  }

  return moment(dateStr).format('DD/MM/YYYY')
}

const getTableHeader = () => (
  <tr className='visible-header'>
    <th>No.</th>
    <th>Customer</th>
    <th>Date</th>
    <th>Due date</th>
    <th>Amount</th>
    <th>Status</th>
  </tr>
)

function InvoicesTable({ onRowClick }) {
  const handleCustomApiCall = useCallback((url, tableProps) => {
    const { name } = tableProps

    callInvoicesJSONAPI(`invoices${buildInvoiceUrlParams(url, tableProps)}`, 'GET')
      .then(({ data = [] }) => {
        // Table reducer check for duplicates using "uuid" (we don't have it)
        const entries = data.map((e) => ({
          ...e,
          uuid: e.id,
        }))

        store.dispatch(TableActions.dataFetched(name, { data: { entries } }, 'entries', false))
      })
      .catch(() => {
        store.dispatch(
          TableActions.dataFetched(
            name,
            {
              status: 400,
              error: 'Failed to fetch invoices',
            },
            'error',
            false
          )
        )
      })
  }, [])

  const getTableRow = useCallback(
    (row, index) => (
      <TableRow index={index} key={index} onClick={() => onRowClick(row)} testID={baseTestID}>
        <td>{row.get('invoiceNumber') || '-'}</td>
        <td>{row.getIn(['customerData', 'fullName'])}</td>
        <td>{renderDateCell(row.get('createdDate'))}</td>
        <td>{renderDateCell(row.get('dueDate'))}</td>
        <td>{formatCurrency(row.get('invoiceTotal') || 0, 'ZAR')}</td>
        <td>
          <InvoiceStatus status={row.get('overdue') ? 'overdue' : row.get('status')} />
        </td>
      </TableRow>
    ),
    [onRowClick]
  )

  return (
    <Table
      api='/'
      name={TableName}
      waitForFilterLoad
      rowsClickable
      pageSize={TablePageSize}
      useOffset
      customApiCall={handleCustomApiCall}
      emptyTable={
        <EmptyTableView title='No invoices found'>
          <span>There are no invoices for the filters you selected, try changing them.</span>
        </EmptyTableView>
      }
      getHeader={getTableHeader}
      getRow={getTableRow}
      testID={baseTestID}
    />
  )
}

InvoicesTable.propTypes = {
  onRowClick: PropTypes.func.isRequired,
}

export default InvoicesTable
