import React, { Component } from 'react'
import PropTypes from 'prop-types'

import TileIcon from 'components/tiles/TileIcon'
import CONSTANTS from 'libs/constants'

import classes from './tile.module.scss'

export default class Tile extends Component {
  getClassName() {
    let className = classes.tile
    if (this.props.className) {
      className += ` ${this.props.className}`
    }

    return className
  }

  getTileStyle(backgroundColor) {
    if (this.props.tile) {
      return {
        backgroundColor:
          backgroundColor.indexOf('#') === 0 ? backgroundColor : `#${backgroundColor}`,
        borderRadius: '8px',
      }
    }
    return {}
  }

  getIconStyle() {
    if (this.props.tile) {
      return {
        width: '100%',
        margin: 'auto',
        borderRadius: 0,
        fontSize: '24px',
      }
    }
    return {}
  }

  getBackgroundColor(tile) {
    if (this.props.color) {
      return this.props.color
    }
    if (tile.get('backgroundColor', tile.get('backgroundColour'))) {
      return tile.get('backgroundColor', tile.get('backgroundColour'))
    }

    return CONSTANTS.YOCO_DEFAULT_COLOR
  }

  render() {
    if (this.props.tile) {
      const className = this.getClassName()
      const backgroundColor = this.getBackgroundColor(this.props.tile)
      const style = this.getTileStyle(backgroundColor)

      let collectionStyle = {}
      let halfCircleClass = classes.halfCircle
      if (this.props.tile.get('tileType') !== 'product') {
        collectionStyle = { backgroundColor: 'white' }
        halfCircleClass = ''
      }

      return (
        <div className={className} style={style}>
          <div className={classes.tileImage} style={collectionStyle}>
            <TileIcon tile={this.props.tile.flatten(true)} style={this.getIconStyle()} />
          </div>
          <div className={classes.tileText}>{this.props.tile.get('name')}</div>
          <div className={classes.tileFooter}>
            <div className={halfCircleClass} style={style} />
          </div>
        </div>
      )
    }

    return undefined
  }
}

Tile.propTypes = {
  tile: PropTypes.object,
  color: PropTypes.string,
  className: PropTypes.object,
}
