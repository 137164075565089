import React from 'react'
import PropTypes from 'prop-types'

import {
  TransactionItemsSummary,
  TransactionAmount,
  TransactionIndicator,
} from 'components/transactions'
import TableRow from 'libs/tables/TableRow'
import * as misc from 'libs/formats'
import { prettyPaymentResult, prettyPaymentMethod } from 'libs/payments'

import classes from './sales.module.scss'

export default class TransactionRowItem extends TableRow {
  getSavedBillsColumn() {
    if (this.props.showSavedBillColumn) {
      return (
        <td key={`${this.props.rowIndex}billname`} className={`${classes.billName} hide-on-md`}>
          {this.props.transaction.getIn(['bill', 'name'], '-')}
        </td>
      )
    }
    return undefined
  }

  getContent() {
    return [
      <td key={`${this.props.rowIndex}time`} className={classes.time}>
        <misc.Time time={this.props.transaction.get('created')} />
      </td>,
      <td key={`${this.props.rowIndex}status`} className={classes.transactionStatus}>
        <TransactionIndicator transaction={this.props.transaction} />
        {prettyPaymentResult(this.props.transaction)}
      </td>,
      <td key={`${this.props.rowIndex}type`} className={classes.transactionType}>
        {prettyPaymentMethod(this.props.transaction)}
      </td>,
      <td key={`${this.props.rowIndex}username`} className={classes.transactionName}>
        {this.props.transaction.get('userDisplayName')}
      </td>,
      this.getSavedBillsColumn(),
      <td key={`${this.props.rowIndex}items`} className={`${classes.itemSummary} hide-on-md`}>
        <TransactionItemsSummary transaction={this.props.transaction} />
      </td>,
      <td key={`${this.props.rowIndex}amount`} className={classes.transactionAmount}>
        <TransactionAmount transaction={this.props.transaction} />
      </td>,
    ]
  }
}

TransactionRowItem.propTypes = {
  className: PropTypes.string,
  shownUUID: PropTypes.string,
  transaction: PropTypes.object,
  bill: PropTypes.object,
  rowClicked: PropTypes.func,
  showSavedBillColumn: PropTypes.bool,
}
