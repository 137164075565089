import React, { useCallback } from 'react'
import { Icon } from '@yoco/design-system-icons/dist/react'

import { makeTestID } from 'libs/utils'
import PopupComponent from 'libs/modals/popups'
import VStack from 'ui/layout/VStack'
import typography from 'ui/typography/typography.module.scss'
import FlatButton from 'components/buttons/FlatButton'
import Spinner from 'components/loaders/Spinner'

import classes from './ConfirmPublishModal.module.scss'
import { Props } from './types'

const ConfirmPublishModal: React.FunctionComponent<Props> = ({
  showPopup,
  setShowPopup,
  isPublished,
  togglePublished,
  isSaving,
}) => {
  const setPublishedState = () => {
    togglePublished()
    setShowPopup(false)
  }

  const TextContent = useCallback(
    () => (
      <>
        <h2
          data-testid={makeTestID('onlineStore', 'ConfirmPublishModal', 'title')}
          className={typography.h2}
        >
          {isPublished ? 'Deactivate Your Store' : 'Activate Your Store'}
        </h2>
        <p
          data-testid={makeTestID('onlineStore', 'ConfirmPublishModal', 'message')}
          className={typography.textSmall}
        >
          {isPublished
            ? // eslint-disable-next-line max-len
              "Take your store offline. Deactivating your store won't delete its content. You can always reactivate it at a later stage."
            : // eslint-disable-next-line max-len
              'Click activate to make your store visible to customers. Once activated, share a link to your store on your favourite social media channels.'}
        </p>
      </>
    ),
    [isPublished]
  )

  const getPopupContent = () => (
    <div
      style={{ display: 'flex', flexDirection: 'column' }}
      data-testid={makeTestID('onlineStore', 'ConfirmPublishModal')}
    >
      <Icon
        data-testid={makeTestID('onlineStore', 'ConfirmPublishModal', 'icon')}
        name='online-store'
        size={56}
      />
      {isSaving ? (
        <Spinner blue />
      ) : (
        <VStack spacing='small'>
          <TextContent />
          <div
            data-testid={makeTestID('onlineStore', 'ConfirmPublishModal', 'buttonContainer')}
            className={classes.buttonContainer}
          >
            <FlatButton
              onClick={() => setShowPopup(false)}
              type='tertiary'
              disabled={isSaving}
              className={`${classes.button}`}
              label='Cancel'
            />
            <FlatButton
              onClick={setPublishedState}
              type='tertiary'
              disabled={isSaving}
              className={`blueBackground ${classes.button} ${
                isPublished ? classes.deleteButton : 'primary'
              }`}
              label={`${isPublished ? 'Deactivate' : 'Activate'}`}
            />
          </div>
        </VStack>
      )}
    </div>
  )

  return (
    <PopupComponent
      onEsc={() => setShowPopup(false)}
      showing={showPopup}
      getContent={getPopupContent}
    />
  )
}
export default ConfirmPublishModal
