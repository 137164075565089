import keyMirror from 'libs/key-mirror'

export default keyMirror(
  {
    FETCH_SALES_PERFORMANCE_REQUEST: null,
    FETCH_SALES_PERFORMANCE_SUCCESS: null,
    FETCH_SALES_PERFORMANCE_FAILURE: null,
    FETCH_SALES_PERFORMANCE_FULFILLED: null,
  },
  '@sales/performance/'
)
