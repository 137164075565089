import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import PopupContentLayout from 'components/popups/OnboardingPopups/PopupContentLayout'
import { isFlagship } from 'libs/utils'
import { selectBankAccountUpdateError } from 'redux/modules/onboarding/bankDetails/selectors'
import { closeBankDetailsPopup } from 'redux/modules/onboarding/bankDetails/actions'
import { selectBusinessName } from 'redux/modules/session/selectors'
import EditBankDetailsContent from 'containers/business/EditBankDetailsPage/components/EditBankDetailsContent'
import { wasRoutedFromApp } from 'redux/modules/routes'
import { fetchBankUpdateRequest } from 'redux/modules/onboarding/bankDetails'
import LoadingView from '../../loaders/LoadingView'

function BankDetailsPopup({
  businessName,
  show,
  bankAccountUpdateError,
  dismissPopup,
  refreshBankUpdateRequest,
  isInApp,
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [isReady, setIsReady] = useState(false)

  const dismissPopupAndWebView = useCallback(() => {
    if (isInApp && !isFlagship && window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('success')
    }
    dismissPopup()
  }, [dismissPopup, isInApp])

  useEffect(() => {
    setIsLoading(true)

    refreshBankUpdateRequest(
      () => {
        // onSuccess
        setIsLoading(false)
        setIsReady(true)
      },
      () => {
        // onError
        setIsLoading(false)
        dismissPopupAndWebView()
      }
    )
  }, [refreshBankUpdateRequest, setIsLoading, setIsReady, dismissPopupAndWebView])

  const onSuccess = () => {
    dismissPopupAndWebView()
  }

  if (isLoading) {
    return <LoadingView />
  }

  if (!isReady) {
    return null
  }

  return (
    <PopupContentLayout
      headerTitle={`Add ${businessName}'s bank account`}
      onClosePopup={!isInApp ? dismissPopupAndWebView : undefined}
      showing={show}
      popupErrorMessage={bankAccountUpdateError}
      popupBodyContent={<EditBankDetailsContent onSuccess={onSuccess} />}
      hideFooterContent
    />
  )
}

BankDetailsPopup.propTypes = {
  show: PropTypes.bool,
  businessName: PropTypes.string.isRequired,
  bankAccountUpdateError: PropTypes.string,
  dismissPopup: PropTypes.func.isRequired,
  refreshBankUpdateRequest: PropTypes.func.isRequired,
  isInApp: PropTypes.bool.isRequired,
}

BankDetailsPopup.defaultProps = {
  show: true,
  bankAccountUpdateError: undefined,
}

const mapStateToProps = (state) => ({
  businessName: selectBusinessName(state),
  bankAccountUpdateError: selectBankAccountUpdateError(state),
  isInApp: wasRoutedFromApp(state),
})

const mapDispatchToProps = (dispatch) => ({
  dismissPopup: () => dispatch(closeBankDetailsPopup()),
  refreshBankUpdateRequest: (onSuccess, onError) =>
    dispatch(fetchBankUpdateRequest(onSuccess, onError)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BankDetailsPopup)
