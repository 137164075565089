import { getLendingPartnerDisplayName } from 'components/capital/utils'
import React from 'react'
import { LendingPartners } from 'redux/modules/capital/types'

import CapitalBankingDetailsRow from '../CapitalBankingDetailsRow'
import styles from './CapitalBankingDetailsBlock.module.scss'

interface Props {
  bank: string
  accountHolder: string
  accountNumber: string
  branchCode: string
  email: string
  providerName: LendingPartners
  approvedAmount: string
}

const CapitalBankingDetailsBlock: React.FunctionComponent<Props> = ({
  bank,
  accountHolder,
  accountNumber,
  branchCode,
  email,
  approvedAmount,
  providerName,
}) => {
  return (
    <div className={styles.titleCardChildColumn} aria-label='CapitalBankingDetailsBlock'>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 className={styles.title}>Your banking details</h1>
          <h1 className={styles.subTitle}>
            {getLendingPartnerDisplayName(providerName)} will pay{' '}
            <span className={styles.subTitleInline}>{approvedAmount}</span> into the bank account
            specified below. You&apos;ll receive proof of payment once the funds have been
            transferred.
          </h1>
        </div>
        <div className={styles.section}>
          <CapitalBankingDetailsRow title='Bank' titleDisplay={bank} />
          <CapitalBankingDetailsRow title='Account holder' titleDisplay={accountHolder} />
          <CapitalBankingDetailsRow title='Account number' titleDisplay={accountNumber} />
          <CapitalBankingDetailsRow title='Branch code' titleDisplay={branchCode} />
          <CapitalBankingDetailsRow title='Proof of payment email' titleDisplay={email} />
          <CapitalBankingDetailsRow title='Amount' titleDisplay={approvedAmount} />
        </div>
      </div>
    </div>
  )
}

export default CapitalBankingDetailsBlock
