/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react'

import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import BlockHeader from 'components/headers/BlockHeader'
import { EmptyTableView, Table } from 'libs/tables'
import OnlineProductRow from 'containers/online/components/OnlineProductRow'
import SplitBlock from 'components/blocks/SplitBlock'
import OnlineProductRowHeader from 'containers/online/components/OnlineProductRowHeader'

import OnlineProductForm from 'containers/online/OnlineProductForm'
import FlatButton from 'components/buttons/FlatButton'

import OnlineStoreBackButton from './components/OnlineStoreBackButton'

const onlineProductsTable = 'onlineProducts'

interface Product {
  productName: string
}

const OnlineProductsPage: React.FunctionComponent = () => {
  const [showingSplit, setShowingSplit] = useState(false)
  const [onlineProduct, setOnlineProduct] = useState<Product | null>(null)

  const editProduct = (product) => {
    setOnlineProduct(product)
    setShowingSplit(true)
  }

  const splitViewClosed = () => {
    setShowingSplit(false)
    setOnlineProduct(null)
  }

  return (
    <SplitBlock
      formName={onlineProductsTable}
      showingSplit={showingSplit}
      onDismiss={() => splitViewClosed()}
      header={onlineProduct ? onlineProduct.productName : null}
      renderSplitContent={() =>
        onlineProduct && <OnlineProductForm onlineProduct={onlineProduct} />
      }
    >
      <MaxWidthBlock>
        <OnlineStoreBackButton />
        <BlockHeader title='Online Products' />
        <Table
          api='/online/products/'
          // tableName={onlineProductsTable}
          name={onlineProductsTable}
          waitForFilterLoad={false}
          showPaging
          getHeader={() => <OnlineProductRowHeader />}
          getRow={(product, index) => (
            <OnlineProductRow
              onlineProduct={product}
              index={index}
              onClick={() => {
                editProduct(product)
              }}
            />
          )}
          emptyTable={
            <EmptyTableView title='No products for sale online' testID='OnlineProductsPage'>
              <p>
                Sell your products online or get new sign ups for your online classes by sharing the
                link on your social media channels.
              </p>
              <p>
                Collect payments from your customers by sharing the link in WhatsApp, email or SMS
              </p>
              <p>Add products and enable them for online sales</p>
              <p>
                <FlatButton
                  className='blueBackground'
                  href='/store/products'
                  type='button'
                  label='Add a new product'
                />
              </p>
            </EmptyTableView>
          }
        />
      </MaxWidthBlock>
    </SplitBlock>
  )
}

export default OnlineProductsPage
