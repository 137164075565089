/* eslint-disable @typescript-eslint/no-empty-function */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import PropTypes from 'prop-types'

import { FirebaseHandler } from 'libs/firebase'

export function serializeFilters(filters, search) {
  let encodedFilters = ''
  let encodedSearch = ''

  if (filters && filters.size > 0) {
    encodedFilters = encodeURIComponent(
      JSON.stringify(
        filters.map((filter) => {
          if (filter) {
            return filter.get('values')
          }
          return ''
        })
      )
    )
  }

  if (search && search.get('value')) {
    encodedSearch = encodeURIComponent(search.get('value'))
  }

  return {
    filters: encodedFilters,
    search: encodedSearch,
  }
}

export class FilteredComponent extends Component {
  UNSAFE_componentWillMount() {
    if (this.filtersFinishedInitializing()) {
      this.filtersInitialized(this.props)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // If we had finished initializing already, and filters changed, inform listeners
    if (
      this.filtersFinishedInitializing() &&
      this.props.batch &&
      nextProps.batch &&
      (this.props.batch.getIn(['search', 'value'], '') !==
        nextProps.batch.getIn(['search', 'value'], '') ||
        this.props.batch.get('filters') !== nextProps.batch.get('filters'))
    ) {
      if (
        this.serializeFilters() !==
        this.serializeFilters(nextProps.batch.get('filters'), nextProps.batch.get('search'))
      ) {
        this.filtersChanged(nextProps)
      }
    } else if (!this.filtersFinishedInitializing() && this.filtersFinishedInitializing(nextProps)) {
      // If we weren't initialized, and now are, tell everyone
      this.filtersInitialized(nextProps)
    }
  }

  filtersFinishedInitializing(nextProps) {
    const props = nextProps || this.props
    return (props.batch || Map()).get('initialized')
  }

  filtersInitialized() {}

  filtersChanged() {}

  serializeFilters(nextProps) {
    const props = nextProps || this.props

    const filters = props.batch ? props.batch.get('filters', Map()) : Map()
    const search = props.batch ? props.batch.get('search', Map()) : Map()

    return serializeFilters(filters, search)
  }

  render() {
    if (this.props.renderContent) {
      return this.props.renderContent(this.props)
    }
    return <span />
  }
}

FilteredComponent.propTypes = {
  renderContent: PropTypes.func,
  batch: PropTypes.objectOf(Map),
}

const ReduxConnectedFilteredComponent = connect((state, props) => ({
  batch: state.filters.get(props.name),
}))(FilteredComponent)

export default FirebaseHandler.connect(
  ReduxConnectedFilteredComponent,
  Map({
    startOfBusinessDay: {
      path: 'readOnly/store/business/businessConfig/config/startOfBusinessDay',
    },
  })
)
