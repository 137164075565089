import React from 'react'
import { IndexRedirect } from 'react-router'

import ROLES from 'libs/roles'
import FEATURES from 'libs/features'

import BusinessDetailsPage from './BusinessDetailsPage'
import CardReadersPage from './CardReadersPage'
import SettingsPage from './SettingsPage'
import EditBankDetailsPage from './EditBankDetailsPage'
import EmailNotifications from './EmailNotifications'
import StoreLocations from './StoreLocations'
import DesktopSettingsPage from './DesktopSettingsPage'
import WifiReaderSettingsPage from './WifiReaderSettingsPage'
import ReceiptConfigPage from './ReceiptConfigPage'
import WebhooksPaymentsPage from './WebhooksPaymentsPage'
import ConfirmBankDetailsPage from './ConfirmBankDetailsPage'
import CancelBankDetailsUpdatePage from './CancelBankDetailsUpdatePage'

export default function getRoutes() {
  return {
    path: 'business',
    text: 'Business Settings',
    icon: 'shop-settings',
    indexRedirect: <IndexRedirect to='/business/details' />,
    requiresOneRole: [
      ROLES.MANAGE_BUSINESS,
      ROLES.MANAGE_STAFF,
      ROLES.VIEW_FINANCIAL,
      ROLES.MANAGE_REPORTS,
    ],
    routes: [
      {
        path: 'details',
        text: 'Details',
        component: BusinessDetailsPage,
        requiresOneRole: [ROLES.MANAGE_BUSINESS],
      },
      {
        path: 'card-readers',
        text: 'Card Machines',
        component: CardReadersPage,
        isForCardAcceptingLocalisation: true,
      },
      {
        path: 'email-notifications',
        text: 'Email Notifications',
        component: EmailNotifications,
        requiresOneRole: [ROLES.VIEW_FINANCIAL, ROLES.MANAGE_REPORTS],
      },
      {
        path: 'bank-account',
        text: 'Bank Details',
        component: EditBankDetailsPage,
        requiresOneRole: [ROLES.MANAGE_BUSINESS],
        isForCardAcceptingLocalisation: true,
      },
      {
        path: 'webhooks',
        text: 'Webhooks',
        component: WebhooksPaymentsPage,
        requiresOneRole: [ROLES.MANAGE_BUSINESS],
        requiresOneOfFeatures: [FEATURES.WEBHOOKS],
      },
      {
        path: 'store-locations',
        text: 'Store Locations',
        component: StoreLocations,
        requiresOneRole: [ROLES.MANAGE_BUSINESS],
        requiresOneOfFeatures: ['storeLocations'],
      },
      {
        path: 'bill-receipt',
        text: 'Receipt Configuration',
        component: ReceiptConfigPage,
      },
      {
        path: 'point-of-sale',
        text: 'Point of Sale',
        component: SettingsPage,
      },
      {
        path: 'desktop-settings',
        text: 'Desktop settings',
        component: DesktopSettingsPage,
        hideInMenu: true,
      },
      {
        path: 'wifi-reader-settings',
        text: 'WIFI reader settings',
        component: WifiReaderSettingsPage,
        hideInMenu: true,
      },
      {
        path: 'bank-account/confirm/:hashid',
        component: ConfirmBankDetailsPage,
        hideInMenu: true,
      },
      {
        path: 'bank-account/:uuid/cancel',
        component: CancelBankDetailsUpdatePage,
        hideInMenu: true,
      },
    ],
    redirects: [
      {
        from: 'emailNotifications',
        to: 'email-notifications',
      },
    ],
  }
}
