import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { transactionStateClass } from './utils'
import classes from './transactions.module.scss'

function TransactionIndicator({ transaction }) {
  const transactionState = transaction.get('transactionState')
  const paymentType = transaction.get('paymentType')

  const baseClasses = [classes.transactionState, classes.smallIndicator]

  if (paymentType === 'refund') {
    return <div className={classnames([baseClasses, classes.refunded])} />
  }

  return <div className={classnames([baseClasses, transactionStateClass(transactionState)])} />
}

TransactionIndicator.propTypes = {
  transaction: PropTypes.object.isRequired,
}

export default TransactionIndicator
