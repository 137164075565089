import React from 'react'

import * as forms from 'libs/forms'
import { PopupComponent } from 'libs/modals'
import popupClasses from 'libs/modals/popups.module.scss'
import FlatButton from 'components/buttons/FlatButton'
import { track } from 'libs/analytics'
import { determineEmailOrPhone } from 'libs/utils'

export default class SendReceiptPopup extends PopupComponent {
  constructor(props) {
    super(props)
    this.onSendReceiptPress = this.onSendReceiptPress.bind(this)
  }

  onSendReceiptPress(response) {
    const contactType = determineEmailOrPhone(response.message.split(' ').pop())
    track('history_send_receipt_send_clicked', {
      contact: contactType,
    })
  }
  getContent() {
    return (
      <div>
        <div className={popupClasses.popupTitle}>Send receipt</div>
        <div className={popupClasses.popupSubtitle}>
          Send this receipt to your customer by email or a link to the receipt by SMS.
        </div>
        <forms.Form
          action={`/transactions/${this.props.itemUUID}/sendReceipt`}
          name='sendReceipt'
          onSuccess={(response) => {
            this.onSendReceiptPress(response)
          }}
          showSuccess
        >
          <forms.TextField
            name='destination'
            label='Email or Phone number'
            placeholder='customer@gmail.com or 080 000 000'
            isFullWidth
            validators={[
              new forms.EmailOrMobileValidator(),
              new forms.RequiredValidator('You must provide a destination'),
            ]}
          />
          <div className={popupClasses.actions}>
            <FlatButton label='Send receipt' className='primary' />
          </div>
        </forms.Form>
      </div>
    )
  }
}
