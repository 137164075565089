import React from 'react'

import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import BlockHeader from 'components/headers/BlockHeader'

import Referrals from './components/Referrals'

function ReferralPage() {
  return (
    <MaxWidthBlock>
      <BlockHeader title='Referrals' />
      <Referrals />
    </MaxWidthBlock>
  )
}

export default ReferralPage
