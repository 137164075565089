import React from 'react'
import PropTypes from 'prop-types'

import FlatButton from 'components/buttons/FlatButton'
import { isFlagship } from 'libs/utils'

import classes from './styles.module.scss'

function PopupFooter({
  buttonLabel,
  submitButtonIsDisabled,
  onFooterButtonClick,
  footerContent,
  hideFooterContent,
}) {
  if (hideFooterContent) {
    return null
  }
  if (footerContent) {
    return <div className={classes.customFooterContainer}>{footerContent}</div>
  }
  return (
    <div
      className={
        isFlagship ? classes.flagshipDefaultFooterContainer : classes.defaultFooterContainer
      }
    >
      <FlatButton
        disabled={submitButtonIsDisabled}
        type='submit'
        style={{ width: 250 }}
        label={buttonLabel || 'Submit'}
        className='blueBackground'
        onClick={onFooterButtonClick}
      />
    </div>
  )
}

PopupFooter.propTypes = {
  buttonLabel: PropTypes.string,
  submitButtonIsDisabled: PropTypes.bool,
  onFooterButtonClick: PropTypes.func,
  footerContent: PropTypes.node,
  hideFooterContent: PropTypes.bool,
}

export default PopupFooter
