import React from 'react'
import PropTypes from 'prop-types'

function InsightSymbol({ insightResult, style }) {
  if (insightResult) {
    if (insightResult.insight.isPositive) {
      return <i className='icon-arrow-up' style={style} />
    }
    if (insightResult.insight.value > 0) {
      return <i className='icon-arrow-down' style={style} />
    }
  }

  return <span style={style} />
}

InsightSymbol.propTypes = {
  insightResult: PropTypes.object,
  style: PropTypes.object,
}

export default InsightSymbol
