import FlatButton from 'components/buttons/FlatButton'
import { getLendingPartnerDisplayName } from 'components/capital/utils'
import NotificationBar from 'components/notificationBar'
import React from 'react'
import { LendingPartners } from 'redux/modules/capital/types'

import styles from './CapitalConfirmOfferButtons.module.scss'

interface Props {
  providerName: LendingPartners
  onDeclinePressed: () => void
  onAcceptPressed: () => void
}

const CapitalConfirmOfferButtons: React.FunctionComponent<Props> = ({
  providerName,
  onDeclinePressed,
  onAcceptPressed,
}) => {
  return (
    <section>
      <NotificationBar
        content={`NB: Once you accept this Cash Advance Agreement and this agreement is approved by ${getLendingPartnerDisplayName(
          providerName
        )}, funds
    will be disbursed to this account within one business day.`}
      />
      <div className={styles.buttons} aria-label='decline and accept buttons'>
        <FlatButton label='Decline offer' onClick={onDeclinePressed} className='destructive' />
        <FlatButton label='Accept offer' onClick={onAcceptPressed} className='blueBackground' />
      </div>
    </section>
  )
}

export default CapitalConfirmOfferButtons
