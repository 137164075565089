import React from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'

import * as forms from 'libs/forms'
import FormCard from 'components/cards/FormCard'
import { maskEmailAddress } from 'libs/utils'

const api = '/staffShifts/clearStaffPIN'
export const CLEAR_STAFF_PIN_FORM_NAME = 'clearStaffPIN'

export function ClearStaffPin({ shownRow, maskedEmail, onSuccess }) {
  return (
    <forms.Form
      action={api}
      name={CLEAR_STAFF_PIN_FORM_NAME}
      initialData={shownRow}
      initialDataProcessor={(data) =>
        Immutable.fromJS({
          userUUID: data.size > 0 ? data.get('info').get('userUUID') : '',
        })
      }
      onSuccess={onSuccess}
    >
      <FormCard header='Password required to authorise'>
        <forms.PasswordField
          name='password'
          label={`Please enter the password for ${maskEmailAddress(maskedEmail)}`}
          validators={[new forms.RequiredValidator('You must enter your password')]}
        />
      </FormCard>
    </forms.Form>
  )
}

ClearStaffPin.propTypes = {
  shownRow: PropTypes.object,
  maskedEmail: PropTypes.string,
  onSuccess: PropTypes.func,
}
