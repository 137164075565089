import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import * as forms from 'libs/forms'
import FormCard from 'components/cards/FormCard'
import { callJSONApi } from 'libs/api'
import { makeTestID, generateUUID } from 'libs/utils'
import { showError } from 'redux/modules/forms'

class UnconnectedCreateLinkForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      customerName: null,
    }
  }

  onCreateBill(uuid, ref) {
    callJSONApi(
      `/paymentLinks/`,
      'POST',
      {
        billUUID: uuid,
        ref,
      },
      (response) => {
        if (response && response.status === 200) {
          this.props.onLinkCreated(response.data.paymentLink)
        }
      },
      (prettyError) => {
        if (prettyError) {
          this.props.showError(this.props.formName, prettyError)
        }
      }
    )
  }

  dataProcessor(data) {
    this.setState({
      customerName: data.get('customerName'),
    })

    return data
      .set('billEntries', [
        {
          price: data.get('totalAmount'),
          quantity: 1,
          tile: {
            name: 'Custom amount',
          },
        },
      ])
      .set('clientBillIdentifier', generateUUID())
  }

  render() {
    return (
      <div>
        <forms.Form
          action='/bills/'
          name={this.props.formName}
          dataProcessor={(data) => this.dataProcessor(data)}
          onSuccess={(response) =>
            this.onCreateBill(response.data.bill.uuid, this.state.customerName)
          }
        >
          <FormCard header='Add the basic payment details'>
            <div className='clearfix'>
              <forms.TextField
                name='customerName'
                label={`Customer's name`}
                placeholder={`Customer's name`}
                required
                isFullWidth
                validators={[
                  new forms.RequiredValidator('You must provide a name'),
                  new forms.RequiredLengthValidator(1, 'You must provide a name'),
                ]}
                testID={makeTestID('sellOnline', 'paymentLink', 'reference')}
              />
              <forms.CurrencyTextField
                localisation={this.props.localisation}
                name='totalAmount'
                label='Amount due'
                placeholder='0.00'
                required
                isFullWidth
                validators={[
                  new forms.RequiredValidator('This field is required'),
                  new forms.NumberValidator(true, 2, 2000000, 'This value must be a number'),
                ]}
                testID={makeTestID('sellOnline', 'paymentLink', 'amount')}
              />
              <p>The minimum amount is R2.00</p>
            </div>
          </FormCard>
        </forms.Form>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  showError: (form, message) => dispatch(showError(form, message)),
})

const CreateLinkForm = connect(null, mapDispatchToProps)(UnconnectedCreateLinkForm)
export default CreateLinkForm

UnconnectedCreateLinkForm.propTypes = {
  localisation: PropTypes.object,
  formName: PropTypes.string,
  onLinkCreated: PropTypes.func,
  showError: PropTypes.func,
}
