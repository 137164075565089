/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import S from 'string'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import FlatButton from 'components/buttons/FlatButton'
import { FirebaseHandler, features, roleDescriptions } from 'libs/firebase'
import { Table, EmptyTableView, TableRow } from 'libs/tables'
import CONSTANTS from 'libs/constants'
import { makeTestID } from 'libs/utils'
import classes from './StaffManagement.module.scss'
import { RouteContext, RouteDispatchContext, routerReducer } from './StaffRoute/RouteContext'
import StaffRoutes from './StaffRoute'

export const baseTestID = makeTestID('staff')

const StaffManagement = (props) => {
  const { roleDescriptions: data } = props
  const [currentClass, setCurrentClass] = React.useState(classes.splitViewStart)
  const wrapperRef = React.useRef(null)
  const init = React.useContext(RouteContext)
  const [state, dispatch] = React.useReducer(routerReducer, init)

  const toggleSidebar = React.useCallback(() => {
    if (currentClass === classes.splitViewOpen) {
      setCurrentClass(classes.splitViewClosed)
      dispatch({ type: 'clear' })
    } else {
      setCurrentClass(classes.splitViewOpen)
    }
  }, [currentClass])

  React.useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        currentClass === classes.splitViewOpen
      ) {
        toggleSidebar()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef, currentClass, toggleSidebar])

  const getHeader = React.useCallback(() => {
    return (
      <tr className='visible-header'>
        <th>Name</th>
        <th className='hide-on-mobile'>Email</th>
        <th className='hide-on-mobile'>Mobile Number</th>
        <th>Permissions</th>
      </tr>
    )
  }, [])

  const getRow = React.useCallback((immutableRow, rowIndex) => {
    return (
      <TableRow
        onClick={() => console.log('Open Sidebar for the editing of staff')}
        selected={false}
        rowIndex={rowIndex}
        key={immutableRow.get('uuid')}
      >
        <td>{immutableRow.get('fullName')}</td>
        <td className='hide-on-mobile'>{immutableRow.get('email')}</td>
        <td className='hide-on-mobile'>{immutableRow.get('mobileNumber')}</td>
        <td>{S(immutableRow.get('userType')).humanize().s}</td>
      </TableRow>
    )
  }, [])

  return (
    <RouteContext.Provider value={state}>
      <RouteDispatchContext.Provider value={{ dispatch }}>
        <div className={currentClass}>
          <div className={classes.mainContainer}>
            <div className={classes.header}>
              <h1>Staff</h1>
              <FlatButton type='submit' className='blueBackground' onClick={toggleSidebar}>
                Add staff member
              </FlatButton>
            </div>
            <Table
              api='/staff/'
              name='staffMembers'
              useOffset
              waitForFilterLoad={false}
              hideNoResults
              rowsClickable
              getHeader={getHeader}
              getRow={getRow}
              emptyTable={
                <EmptyTableView title='Your business currently has no staff members'>
                  <span>
                    Click
                    <button
                      style={{ color: CONSTANTS.YOCO_BLUE }}
                      className={classes.clickableText}
                      onClick={toggleSidebar}
                      type='button'
                    >
                      Invite Staff Member
                    </button>
                    to add staff to your business
                  </span>
                </EmptyTableView>
              }
              dataLoaded={(response) => console.log(response)}
            />
          </div>
          <div className={classes.sideContainer} ref={wrapperRef}>
            {/* Where we are going to put our sidebar content */}
            <StaffRoutes roleDescriptions={data} toggleSidebar={toggleSidebar} />
          </div>
        </div>
      </RouteDispatchContext.Provider>
    </RouteContext.Provider>
  )
}

StaffManagement.propTypes = {
  roleDescriptions: PropTypes.object,
}

export const ReduxConnectedPage = connect((state) => ({
  productData: state.tables,
  userInfo: state.session,
}))(StaffManagement)

export default FirebaseHandler.connect(
  ReduxConnectedPage,
  Map({
    features,
    roleDescriptions,
  })
)
