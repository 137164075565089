import { createSelector } from 'reselect'
import { Map } from 'immutable'

const onboardingNode = (state) => state.onboarding

export const selectTradingAddress = createSelector(
  onboardingNode,
  (onboardingData) => onboardingData.getIn(['businessAddress', 'address']) || Map()
)

export const selectShouldShowBusinessInfoPopup = createSelector(onboardingNode, (onboardingData) =>
  onboardingData.getIn(['businessAddress', 'showBusinessInfoPopup'])
)
