/* eslint-disable react/no-unused-class-component-methods */
import React, { Component } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import { Amount } from 'libs/formats'
import CONSTANTS from 'libs/constants'

import FormCard from '../cards/FormCard'

import classes from './insights.module.scss'
import InsightAmountText from './InsightAmountText'
import InsightSymbol from './InsightSymbol'

export default class InsightCalculationPanel extends Component {
  getComparisonDatesDiv(startDate, endDate, period) {
    let formatString = 'Do MMM YYYY'

    // eslint-disable-next-line default-case
    switch (period) {
      case 'day': {
        return (
          <div className='dates'>
            <b>{moment(startDate).format('ddd Do MMM')}</b>
          </div>
        )
      }
      case 'week': {
        formatString = 'ddd Do MMM'
        break
      }
      case 'month': {
        formatString = 'Do MMM YYYY'
        break
      }
    }

    return (
      <div className={classes.dates}>
        <span style={{ color: 'rgba(33, 33, 33, 0.6)' }}>From 5am </span>
        <b>{moment(startDate).format(formatString)}</b>
        <span style={{ color: 'rgba(33, 33, 33, 0.6)' }}> until 5am </span>
        <b>{moment(endDate).format(formatString)}</b>
      </div>
    )
  }

  getSymbol() {
    if (this.props.insightResult) {
      if (this.props.insightResult.insight.isPositive) {
        return <i className='icon-arrow-up' style={this.getStyle()} />
      }
      if (this.props.insightResult.insight.value > 0) {
        return <i className='icon-arrow-down' style={this.getStyle()} />
      }
    }

    return undefined
  }

  getStyle() {
    if (this.props.insightResult) {
      if (this.props.insightResult.insight.isPositive) {
        return { color: CONSTANTS.GREEN }
      }
      if (this.props.insightResult.insight.value > 0) {
        return { color: CONSTANTS.RED }
      }
    }

    return {}
  }

  getResult() {
    if (
      this.props.insightResult.currentValue === 0 &&
      this.props.insightResult.previousValue === 0
    ) {
      return <div className={classes.result}>no data for selected period</div>
    }
    return (
      <div className={classes.result}>
        ={' '}
        <span className={classes.values}>
          <InsightAmountText insightResult={this.props.insightResult} style={this.getStyle()} />
        </span>
        <InsightSymbol style={this.getStyle()} insightResult={this.props.insightResult} />
      </div>
    )
  }

  render() {
    return (
      <FormCard hideHeader style={{ marginBottom: '0' }}>
        <div className={classes.section}>
          <div className={classes.title}>PREVIOUS PERIOD</div>
          <div className={`${classes.numbersContainer} ${classes.smallPadding}`}>
            {this.getComparisonDatesDiv(
              this.props.insightResult.dates.previousStartDate,
              this.props.insightResult.dates.previousEndDate,
              this.props.insightResult.dates.period
            )}
            &nbsp;
            <b>
              <Amount amount={this.props.insightResult.previousValue} alignLeft />
            </b>
          </div>
          <div className={classes.title}>CURRENT PERIOD</div>
          <div className={`${classes.numbersContainer} ${classes.smallPadding}`}>
            {this.getComparisonDatesDiv(
              this.props.insightResult.dates.startDate,
              this.props.insightResult.dates.endDate,
              this.props.insightResult.dates.period
            )}
            &nbsp;
            <b>
              <Amount amount={this.props.insightResult.currentValue} alignLeft />
            </b>
          </div>
        </div>
        <div className={`${classes.section} ${classes.footer}`}>
          <div className={classes.resultBlock}>{this.getResult()}</div>
        </div>
      </FormCard>
    )
  }
}

InsightCalculationPanel.propTypes = {
  insightResult: PropTypes.object,
}
