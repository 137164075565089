/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react'

import FlatButton from 'components/buttons/FlatButton'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import { callJSONApi } from 'libs/api'
import Alert from 'components/notifications/Alert'
import LoadingView from 'components/loaders/LoadingView'
import VStack from 'ui/layout/VStack'

import promoImage from './promo.png'

function YoyoPage() {
  const [errorMessage, setErrorMessage] = useState(undefined)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isEnrolled, setIsEnrolled] = useState(false)

  const enroll = useCallback(() => {
    setIsSubmitting(true)
    callJSONApi(
      '/yoyo/enroll',
      'POST',
      {},
      () => {
        setIsSubmitting(false)
        setIsEnrolled(true)
      },
      (prettyError) => {
        setErrorMessage(prettyError)
        setIsSubmitting(false)
      }
    )
  }, [])

  return (
    <div>
      <MaxWidthBlock>
        <div className='inner' style={{ textAlign: 'center' }}>
          <VStack>
            <img src={promoImage} style={{ width: '100%' }} alt='promo' />

            {errorMessage && <Alert message={errorMessage} messageType='danger' />}

            {isSubmitting ? (
              <LoadingView />
            ) : isEnrolled ? (
              <Alert
                message={`
                      You have been successfully signed up to the Yoyo loyalty service. 
                      An email should arrive shortly from Yoyo.
                    `}
                messageType='success'
              />
            ) : (
              <VStack>
                <FlatButton className='blueBackground large' onClick={enroll}>
                  Sign Up
                </FlatButton>
                <div>
                  Please note that by signing up you consent with Yoco sharing your business
                  information with Yoyo for the purposes of creating your profile and tracking
                  loyalty points.
                </div>
              </VStack>
            )}
          </VStack>
        </div>
      </MaxWidthBlock>
    </div>
  )
}

export default YoyoPage
