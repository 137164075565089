import React from 'react'

import { If } from 'libs/formats'
import emptyBasket from 'assets/images/icon-empty-state.png'

import classes from './tables.module.scss'

interface Props {
  title: string
  testID?: string
  showImage?: boolean
  image?: string
  children?: React.ReactNode
}

const EmptyTableView: React.FunctionComponent<Props> = ({
  testID,
  title,
  showImage = true,
  image = emptyBasket,
  children,
}) => {
  return (
    <div className={classes.emptyTable}>
      <div className={classes.emptyTableTitle} data-testid={testID}>
        {title}
      </div>
      <If condition={showImage}>
        <img src={image} alt='Empty table' />
      </If>
      <div className={classes.emptyTableContent}>{children}</div>
    </div>
  )
}

export default EmptyTableView
