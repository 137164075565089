import React from 'react'

import classes from 'containers/online/online.module.scss'

export default function OnlineProductRowHeader() {
  return (
    <tr className='visible-header'>
      <th className={classes.tileColumn} aria-label='Title' />
      <th>Name</th>
      <th className={classes.priceColumn}>Price</th>
    </tr>
  )
}
