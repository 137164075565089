export const calculateImageSize = (image, maxHeight, shouldSquareAndCentre) => {
  const smallestSide = Math.min(image.width, image.height)
  const canvasSize = Math.min(smallestSide, maxHeight)
  const aspectRatio = image.width / image.height
  const defaultSize = {
    sourceX: 0,
    sourceY: 0,
    sourceWidth: image.width,
    sourceHeight: image.height,
    destinationX: 0,
    destinationY: 0,
    destinationHeight: Math.min(image.height, maxHeight),
    destinationWidth: Math.floor(Math.min(image.height, maxHeight) * aspectRatio),
  }
  const squareAndCentre = {
    sourceX: (image.width - smallestSide) / 2,
    sourceY: (image.height - smallestSide) / 2,
    sourceWidth: smallestSide,
    sourceHeight: smallestSide,
    destinationHeight: canvasSize,
    destinationWidth: canvasSize,
    destinationX: 0,
    destinationY: 0,
  }
  return shouldSquareAndCentre ? squareAndCentre : defaultSize
}
