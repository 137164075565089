/* eslint-disable
  @typescript-eslint/no-explicit-any,
  @typescript-eslint/no-unused-vars,
  no-unused-vars
*/

import { callJSONApi } from 'libs/api'

import ActionTypes from './actionTypes'

export function getKeysAPI() {
  return (dispatch) => {
    dispatch({ type: ActionTypes.GET_API_KEYS_REQUEST })

    callJSONApi(
      `/thrive/update`,
      'GET',
      {},
      (response) => {
        if (Number(response.status) === 200) {
          dispatch({
            type: ActionTypes.GET_API_KEYS_SUCCESS,
          })
          dispatch({
            type: ActionTypes.RECEIVED_KEYS,
            payload: response.data,
          })
        } else {
          dispatch({
            type: ActionTypes.GET_API_KEYS_FAILURE,
            error: response.message,
          })
        }
      },
      (prettyError) => {
        dispatch({
          type: ActionTypes.GET_API_KEYS_FAILURE,
          error: prettyError,
        })
      }
    )
  }
}

export function createKeysAPI() {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CREATE_API_KEYS_REQUEST })

    callJSONApi(
      `/thrive/createKeys`,
      'GET',
      {},
      (response) => {
        if (Number(response.status) === 200) {
          dispatch({
            type: ActionTypes.CREATE_API_KEYS_SUCCESS,
          })
          dispatch({
            type: ActionTypes.RECEIVED_KEYS,
            payload: response.data,
          })
        } else {
          dispatch({
            type: ActionTypes.CREATE_API_KEYS_FAILURE,
            error: response.message,
            payload: false,
          })
        }
      },
      (prettyError) => {
        dispatch({
          type: ActionTypes.CREATE_API_KEYS_FAILURE,
          error: prettyError,
          payload: false,
        })
      }
    )
  }
}

export const createKeys = () => (dispatch) => {
  dispatch(createKeysAPI())
}

export const getKeys = () => (dispatch) => {
  dispatch(getKeysAPI())
}
