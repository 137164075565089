/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'

import classes from './referrals.module.scss'

function ShareButton({ text, icon, colour, url, onClick }) {
  const handleWindowOpen = () => {
    window.open(`${url}`, '_blank')
  }

  const handleClick = onClick || handleWindowOpen

  return (
    <div
      onClick={handleClick}
      className={classes.shareCardSocialButton}
      style={{ backgroundColor: `${colour}` }}
    >
      <img src={icon} alt='icon' className={classes.shareCardSocialButtonIcon} />
      <span>{text}</span>
    </div>
  )
}

ShareButton.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  colour: PropTypes.string,
  url: PropTypes.string,
  onClick: PropTypes.func,
}

export default ShareButton
