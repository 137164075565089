/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import FlatButton from 'components/buttons/FlatButton'
import * as forms from 'libs/forms'
import { clearForm } from 'redux/modules/forms'
import PopupComponent from 'libs/modals/popups'
import classes from 'libs/modals/popups.module.scss'

class UnconnectedUpdateStockPopup extends PopupComponent {
  onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel()
    }
    this.props.dispatch(clearForm(this.props.formName))
  }

  stockTotal() {
    const { reduxItem } = this.props
    let editValue = (reduxItem || Map()).get('stockCount')

    if (editValue) {
      if (reduxItem.get('adjust')) {
        const intValue = parseInt(editValue, 10)
        const totalStock = parseInt(this.props.currentStock, 10) + parseInt(editValue, 10)
        const symbol = intValue >= 0 ? '+' : '-'

        if (intValue < 0) {
          editValue = editValue.substr(1)
        }

        return (
          <div className={classes.stockTotal}>
            {`The new stock total will be ${this.props.currentStock} ${symbol} ${editValue} = ${totalStock}`}
          </div>
        )
      }

      return (
        <div className={classes.stockTotal}>
          {`Changing your stock from ${this.props.currentStock} to ${editValue}`}
        </div>
      )
    }

    return undefined
  }

  mouseDown() {
    if (this.props.onMouseDown) {
      this.props.onMouseDown()
    }
  }

  mouseUp() {
    if (this.props.onMouseUp) {
      this.props.onMouseUp()
    }
  }

  onSuccess() {
    this.escPressed()
  }

  getContent() {
    const { reduxItem } = this.props
    let label = 'Set Stock Level'
    let placeholder = 'Set a new stock level'
    if (reduxItem.get('adjust')) {
      label = 'Add/Remove Stock'
      placeholder = `To remove stock prefix with a '-'. e.g. -12 or -7`
    }

    let buttonLabel = 'Set stock'
    if (reduxItem && reduxItem.get('stockCount')) {
      if (reduxItem.get('set')) {
        buttonLabel += ` to ${reduxItem.get('stockCount')}`
      } else if (reduxItem.get('adjust')) {
        buttonLabel =
          reduxItem.get('stockCount').indexOf('-') < 0
            ? `Add ${reduxItem.get('stockCount')} units to stock`
            : `Remove ${reduxItem.get('stockCount').replace('-', '')} units from stock`
      }
    }

    return (
      <div onMouseDown={this.mouseDown.bind(this)} onMouseUp={this.mouseUp.bind(this)}>
        <forms.Form
          action={this.props.api}
          name={this.props.formName}
          dataProcessor={(data) => {
            const newData = data
            this.newValue = parseInt(data.get('stockCount'), 10)
            if (!data.get('set')) {
              this.newValue += this.props.currentStock
            }

            return newData.merge(
              Map({
                updateStockMethod: data.get('set') ? 'set' : 'adjust',
                skuUUID: data.get('skuUUID') || this.props.skuUUID,
              })
            )
          }}
          onSuccess={() => this.onSuccess()}
        >
          <div className={classes.popupTitle}>
            <i className='icon-shopping-1' />
            Update Stock
          </div>
          <div className={classes.popupSubtitle}>{this.props.sku}</div>
          <div className={`${classes.updateMethod} clearfix`}>
            <forms.RadioButtonField
              name='set'
              label='Set a new stock level'
              linkedFields={['adjust']}
              defaultValue
              isFullWidth
              validators={[
                new forms.EitherOrValidator('adjust', 'You must choose to set or adjust stock'),
              ]}
            />
            <forms.RadioButtonField
              name='adjust'
              isFullWidth
              label='Add/Remove stock from my existing stock level'
              linkedFields={['set']}
              validators={[
                new forms.EitherOrValidator('set', 'You must choose to set or adjust stock'),
              ]}
            />
          </div>
          <div className='clearfix'>
            <forms.TextField
              name='stockCount'
              label={label}
              placeholder={placeholder}
              isFullWidth
              validators={[
                new forms.RequiredValidator('You must provide a value'),
                new forms.NumberValidator('Value must be a number'),
              ]}
            />
          </div>

          <div className='clearfix'>
            <div className={classes.actions}>
              <FlatButton
                label={buttonLabel}
                className='blueBackground'
                style={{ minWidth: '150px' }}
              />
            </div>
          </div>
          {this.stockTotal()}
        </forms.Form>
      </div>
    )
  }
}

const UpdateStockPopup = connect((state, props) => ({
  reduxItem: state.forms.getIn([props.formName, 'data'], Map({})),
}))(UnconnectedUpdateStockPopup)

UpdateStockPopup.propTypes = {
  sku: PropTypes.string,
  api: PropTypes.string,
  stockInfo: PropTypes.object,
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
  actionType: PropTypes.string,
  formName: PropTypes.string,
}

export default UpdateStockPopup
