/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@yoco/design-system-icons/dist/react'

import Tooltip from 'components/Tooltip/Tooltip'

import classes from './referrals.module.scss'

function StatusFilter({ column: { setFilter } }) {
  const STATUS_VALUES = useMemo(
    () => [
      {
        label: 'All',
        value: '',
      },
      {
        label: 'Signed up',
        value: 'Pending',
      },
      {
        label: 'Complete',
        value: 'Earned',
      },
      {
        label: 'Paid',
        value: 'Awarded',
      },
      {
        label: 'Declined',
        value: 'Declined',
      },
    ],
    []
  )

  const getTooltipContent = () => {
    return (
      <div>
        {STATUS_VALUES.map((status) => (
          <p key={status.value} onClick={() => setFilter(status.value || undefined)}>
            {status.label}
          </p>
        ))}
      </div>
    )
  }

  return (
    <Tooltip
      showOnHover={false}
      hoverClassName={classes.statusFilterOptions}
      hoverContent={getTooltipContent()}
      below
    >
      <div className={classes.statusFilterButton}>
        <div className={classes.statusFilterIconWrapper}>
          <Icon name='chevron-down' size={24} />
        </div>
      </div>
    </Tooltip>
  )
}

export default StatusFilter

StatusFilter.propTypes = {
  column: PropTypes.shape({
    setFilter: PropTypes.func.isRequired,
  }),
}
