import React from 'react'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { makeTestID } from 'libs/utils'

import FlatButton from './FlatButton'

class UnconnectedReferralLink extends FlatButton {
  gotoLetsGrow() {
    this.props.dispatch(push('/lets-grow'))
  }

  render() {
    const className = this.props.className ? this.props.className : 'blueBackground'

    return (
      <FlatButton
        id='referBtn'
        className={className}
        style={this.props.style}
        onClick={() => this.gotoLetsGrow()}
        label='Earn R500'
        testID={makeTestID('mainNavBar', 'referralLink')}
      />
    )
  }
}

const ReferralLink = connect(() => ({}))(UnconnectedReferralLink)

export default ReferralLink

UnconnectedReferralLink.propTypes = {
  dispatch: PropTypes.func,
}
