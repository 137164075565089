/* eslint-disable no-promise-executor-return */
import { useEffect } from 'react'

import { callJSONApi } from 'libs/api'
import store from 'redux/store'
import { dataLoading, initializeForm } from 'redux/modules/forms'

import { FormNames } from './constants'

export const useInitialiseFormValues = (initialValues) => {
  useEffect(() => {
    store.dispatch(dataLoading(FormNames.Create))

    Promise.all([
      new Promise((resolve, reject) =>
        callJSONApi(
          '/business/?display=tradingAddress',
          'GET',
          undefined,
          (json) => resolve(json.data?.business?.tradingAddress),
          reject
        )
      ),
      new Promise((resolve, reject) =>
        callJSONApi(
          '/business/receiptConfig',
          'GET',
          undefined,
          (json) => resolve(json.data?.receiptConfig),
          reject
        )
      ),
    ])
      .then(([tradingAddress, receiptConfig]) => {
        const physicalAddress = [
          tradingAddress?.firstLine,
          tradingAddress?.secondLine,
          tradingAddress?.city,
          tradingAddress?.province,
          tradingAddress?.postalCode,
        ]
          .filter((v) => !!v)
          .join(', ')
        const logoUri = receiptConfig?.receiptLogoURL

        store.dispatch(
          initializeForm(FormNames.Create, {
            ...initialValues,
            physicalAddress,
            logoUri,
          })
        )
      })
      .catch(() => {
        store.dispatch(initializeForm(FormNames.Create, initialValues))
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
