/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@yoco/design-system-icons/dist/react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'

import Spacer from 'ui/layout/Spacer'
import containers from 'ui/containers/containers.module.scss'
import { callJSONApi } from 'libs/api'
import * as session from 'redux/modules/session'

import classes from './account.module.scss'
import AvailableAccount from './AvailableAccount'

function AccountSwitcher({ name, switchAccount }) {
  const [isExpanded, setIsExpanded] = useState(false)
  const [availableAccounts, setAvailableAccounts] = useState([])

  useEffect(() => {
    callJSONApi(
      '/user/linkedAccounts',
      'GET',
      null,
      (result) => {
        setAvailableAccounts(result.data.linkedAccounts)
      },
      (error) => {
        console.error('Unable to load sub accounts', error)
      }
    )
  }, [])

  return (
    <div onClick={() => setIsExpanded(!isExpanded)} className={classes.accountSwitcher}>
      {name}
      {availableAccounts.length > 0 ? (
        <>
          <Spacer isHorizontal size='small' />

          {isExpanded ? (
            <>
              <Icon name='chevron-up' size={24} />
              <div
                className={classnames(classes.accountList, containers.shadow, containers.rounded)}
              >
                {availableAccounts.map((availableAccount) => {
                  return (
                    <AvailableAccount
                      key={availableAccount.userUUID}
                      {...availableAccount}
                      onClick={() => {
                        switchAccount(availableAccount.userUUID)
                      }}
                    />
                  )
                })}
              </div>
            </>
          ) : (
            <Icon name='chevron-down' size={24} />
          )}
        </>
      ) : null}
    </div>
  )
}

AccountSwitcher.propTypes = {
  name: PropTypes.string,
  switchAccount: PropTypes.func,
}

const mapStateToProps = null
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      switchAccount: (userUUID) => {
        return session.switchAccount(userUUID)
      },
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(AccountSwitcher)
