import Immutable, { Map, List } from 'immutable'

import { NAVIGATED_ACTION } from 'redux/modules/navigatedAction'

export const ACTIONS = {
  DATA_FETCHED: 'TABLE_DATA_FETCHED',
  FETCH_DATA: 'TABLE_FETCH_DATA',
  SORT_BY_COLUMN: 'TABLE_SORT_BY_COLUMN',
  REFRESH_TABLE: 'TABLE_REFRESH_TABLE',
  ITEM_UPDATED: 'TABLE_ITEM_UPDATED',
  SET_FIELD_ON_ITEM: 'TABLE_SET_FIELD_ON_ITEM',
  ADD_ITEM: 'TABLE_ADD_ITEM',
  SELECT_ALL: 'TABLE_SELECT_ALL_ITEMS',
  REMOVE_ITEM: 'TABLE_REMOVE_ITEM',
  HIDE_ERROR: 'TABLE_ERROR_REMOVED',
  CLEAR_TABLE: 'TABLE_DATA_CLEARED',
}

export function dataFetched(tableName, response, dataPath, wasCustomData = false) {
  return {
    type: ACTIONS.DATA_FETCHED,
    tableName,
    response,
    dataPath,
    wasCustomData,
  }
}

export function fetchData(tableName, useSubItems, refresh, pageSize, hasSelectors) {
  return {
    type: ACTIONS.FETCH_DATA,
    tableName,
    refresh,
    useSubItems,
    pageSize,
    hasSelectors,
  }
}

export function refreshTable(tableName) {
  return {
    type: ACTIONS.REFRESH_TABLE,
    tableName,
  }
}

export function sortByColumn(tableName, column) {
  return {
    type: ACTIONS.SORT_BY_COLUMN,
    tableName,
    column,
  }
}

export function clearTable(tableName) {
  return {
    type: ACTIONS.CLEAR_TABLE,
    tableName,
  }
}

export function itemUpdated(tableName, item) {
  return {
    type: ACTIONS.ITEM_UPDATED,
    tableName,
    item,
  }
}

export function setFieldOnItem(tableName, itemUUID, fieldName, data) {
  return {
    type: ACTIONS.SET_FIELD_ON_ITEM,
    tableName,
    itemUUID,
    fieldName,
    data,
  }
}

export function addItem(tableName, item) {
  return {
    type: ACTIONS.ADD_ITEM,
    tableName,
    item,
  }
}

export function selectAllItems(tableName, value) {
  return {
    type: ACTIONS.SELECT_ALL,
    tableName,
    value,
  }
}

export function removeItem(tableName, uuid) {
  return {
    type: ACTIONS.REMOVE_ITEM,
    tableName,
    uuid,
  }
}

export function hideError(tableName) {
  return {
    type: ACTIONS.HIDE_ERROR,
    tableName,
  }
}

// Method that lets us search through items and sub items for a uuid, useful when updating items
export function getIndexesOfItem(array, uuid) {
  let secondIndex = -1
  const firstKeyValue = (array || List()).findEntry((entry) => {
    if (entry.get('subItems')) {
      const keyValue = entry.get('subItems').findEntry((subEntry) => {
        return subEntry.get('uuid') === uuid
      }, -1)
      if (keyValue) {
        // eslint-disable-next-line prefer-destructuring
        secondIndex = keyValue[0]
        return true
      }
    } else if (entry.get('uuid') === uuid) {
      return true
    }

    return false
  })

  const firstIndex = firstKeyValue ? firstKeyValue[0] : -1
  return [firstIndex, secondIndex]
}

export function getItemFromArray(array, uuid) {
  const indexes = getIndexesOfItem(array, uuid)
  // This would mean we found a sub-item
  if (indexes[1] > -1) {
    return array.getIn([indexes[0], 'subItems', indexes[1]])
  }

  // This index could still be -1, this would then return undefined as expected.
  return array.getIn(indexes[0])
}

export const initialState = Map({
  sortedColumns: Map(),
})

function updateTable(state, tableName, merge) {
  const tables = {}
  tables[tableName] = merge
  return state.mergeDeep(tables)
}

export default function Tables(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.DATA_FETCHED: {
      if (action.response.status > 299) {
        return updateTable(
          state,
          action.tableName,
          Map({
            busyLoading: false,
            busyRefreshing: false,
            error: action.response.message,
          })
        )
      }

      let loadedData = []
      if (action.dataPath) {
        loadedData = action.response.data[action.dataPath]
      } else if (action.wasCustomData && action.response) {
        loadedData = action.response
      } else if (!action.wasCustomData && action.response.data instanceof Array) {
        loadedData = action.response.data
      } else {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in action.response.data) {
          if ({}.hasOwnProperty.call(action.response.data, key)) {
            const object = action.response.data[key]
            if (object instanceof Array) {
              loadedData = object
              break
            }
          }
        }
      }

      // Get the size of the loaded data
      // and if the size is greater than the pageSize canLoadMore is true
      loadedData = Immutable.fromJS(loadedData)
      let count = 0
      if (loadedData && loadedData.size > 0) {
        if (!state.getIn([action.tableName, 'useSubItems'])) {
          count = loadedData.size
        } else {
          loadedData.forEach((data) => {
            count += Math.max(data.get('subItems', List()).size, 1)
          })
        }
      }
      const canLoadMore = count >= state.getIn([action.tableName, 'pageSize'])
      let hasNoData = count === 0

      const wasFullReload = action.wasCustomData
        ? true
        : state.getIn([action.tableName, 'busyRefreshing'])
      let addedItems = state.getIn([action.tableName, 'addedItems'], List())

      if (!wasFullReload) {
        let currentData = state.getIn([action.tableName, 'data'])
        if (loadedData && loadedData.size > 0) {
          // Check if we are using grouped with subItems
          if (currentData && currentData.last() && currentData.last().get('subItems')) {
            let lastObject = currentData.last()
            // Check if the new items belong to the last sublist we previously loaded
            if (lastObject.get('title') === loadedData.first().get('title')) {
              lastObject = lastObject.set(
                'subItems',
                lastObject.get('subItems').concat(loadedData.first().get('subItems'))
              )
              loadedData = loadedData.shift()
            }
            currentData = currentData.butLast().toList().push(lastObject)
          }

          const loadedDataUUIDSet = loadedData.map((loadedItem) => loadedItem.get('uuid')).toSet()

          // If we have addedItems, remove any of them that we have now loaded
          if (addedItems.size > 0) {
            addedItems = addedItems.filter((addedItem) => {
              return !loadedDataUUIDSet.includes(addedItem.get('uuid')) || !addedItem.get('uuid')
            })
          }

          // If we already have data, make sure we remove any duplicates.
          // This can happen if we added an item.
          if (currentData && currentData.size > 0) {
            if (!state.getIn([action.tableName, 'useSubItems'])) {
              currentData = currentData.filter((currentItem) => {
                return (
                  !loadedDataUUIDSet.includes(currentItem.get('uuid')) || !currentItem.get('uuid')
                )
              })
            }
          }

          loadedData = (currentData || List()).concat(loadedData)
        } else {
          loadedData = currentData
        }
        hasNoData = false
      } else {
        addedItems = List()
      }

      if (
        state.getIn([action.tableName, 'hasSelectors']) &&
        !state.getIn([action.tableName, 'hasNoData'])
      ) {
        loadedData = loadedData.filter((row) => row).map((row) => row.set('itemIsSelected', false))
        addedItems = addedItems.filter((row) => row).map((row) => row.set('itemIsSelected', false))
      }

      // We clear the removed count here
      // because we selected the correct page count and offset to make up for it
      return updateTable(
        state,
        action.tableName,
        Map({
          busyLoading: false,
          busyRefreshing: false,
          shouldRefresh: false,
          removedCount: 0,
          canLoadMore,
          hasNoData,
          error: undefined,
        })
      )
        .setIn([action.tableName, 'addedItems'], addedItems)
        .setIn([action.tableName, 'data'], loadedData)
    }
    case ACTIONS.FETCH_DATA: {
      return updateTable(
        state,
        action.tableName,
        Map({
          busyLoading: true,
          busyRefreshing: action.refresh,
          useSubItems: action.useSubItems,
          shouldRefresh: false,
          hasSelectors: action.hasSelectors,
          pageSize: Number(action.pageSize),
        })
      )
    }
    case ACTIONS.SORT_BY_COLUMN: {
      const currentDirection = state.getIn(['sortedColumns', action.tableName, action.column])
      const sortDirection = currentDirection === undefined ? false : !currentDirection

      let newState = state.setIn(['sortedColumns', action.tableName, action.column], sortDirection)
      newState = newState.setIn(['sortedColumns', action.tableName, 'sortedColumn'], action.column)
      return newState
    }
    case ACTIONS.REFRESH_TABLE: {
      return updateTable(
        state,
        action.tableName,
        Map({
          shouldRefresh: true,
        })
      )
    }
    case ACTIONS.CLEAR_TABLE: {
      return updateTable(
        state,
        action.tableName,
        Map({
          busyLoading: false,
          busyRefreshing: false,
          shouldRefresh: false,
          removedCount: 0,
        })
      )
        .setIn([action.tableName, 'addedItems'], List())
        .setIn([action.tableName, 'data'], List())
    }
    case ACTIONS.ITEM_UPDATED: {
      if (!state.get(action.tableName)) {
        console.error('Trying to update an item in a table that does not exist')
        return state
      }

      const indexes = getIndexesOfItem(
        state.getIn([action.tableName, 'data']),
        action.item.get('uuid')
      )
      let newState

      if (indexes[1] > -1) {
        newState = state.setIn(
          [action.tableName, 'data', indexes[0], 'subItems', indexes[1]],
          action.item
        )
      } else if (indexes[0] > -1) {
        newState = state.setIn([action.tableName, 'data', indexes[0]], action.item)
      }

      // Check if it was added later
      if (newState === undefined) {
        const addedItemIndexs = getIndexesOfItem(
          state.getIn([action.tableName, 'addedItems']),
          action.item.get('uuid')
        )
        if (addedItemIndexs[1] > -1) {
          newState = state.setIn(
            [action.tableName, 'addedItems', addedItemIndexs[0], 'subItems', addedItemIndexs[1]],
            action.item
          )
        } else if (addedItemIndexs[0] > -1) {
          newState = state.setIn([action.tableName, 'addedItems', addedItemIndexs[0]], action.item)
        }
      }

      if (newState) {
        if (state.getIn([action.tableName, 'hasSelectors'])) {
          const tableData = newState
            .getIn([action.tableName, 'data'], List())
            .concat(newState.getIn([action.tableName, 'addedItems'], List()))
          const tableWithSelectedItems = tableData.filter(
            (row) => row.get('itemIsSelected') === true
          )
          newState = newState.setIn(
            [action.tableName, 'allSelected'],
            tableWithSelectedItems.size === tableData.size
          )
        }

        return newState
      }

      console.log(`Couldn't find item to update ${action.item.get('uuid')}`)
      return state
    }
    /**
     * Set additional data on an item within our table.
     */
    case ACTIONS.SET_FIELD_ON_ITEM: {
      if (!state.get(action.tableName)) {
        console.error('Trying to set a field on an item in a table that does not exist')
        return state
      }

      const indexes = getIndexesOfItem(state.getIn([action.tableName, 'data']), action.itemUUID)
      let newState

      // This is updating in the original set of data we fetched for the table
      if (indexes[1] > -1) {
        newState = state.setIn(
          [action.tableName, 'data', indexes[0], 'subItems', indexes[1], action.fieldName],
          action.data
        )
      } else if (indexes[0] > -1) {
        newState = state.setIn(
          [action.tableName, 'data', indexes[0], action.fieldName],
          action.data
        )
      }

      // This is updating in any items that were added by subsequent loads
      if (newState === undefined) {
        const addedItemIndexs = getIndexesOfItem(
          state.getIn([action.tableName, 'addedItems']),
          action.item.get('uuid')
        )
        if (addedItemIndexs[1] > -1) {
          newState = state.setIn(
            [
              action.tableName,
              'addedItems',
              addedItemIndexs[0],
              'subItems',
              addedItemIndexs[1],
              action.fieldName,
            ],
            action.data
          )
        } else if (addedItemIndexs[0] > -1) {
          newState = state.setIn(
            [action.tableName, 'addedItems', addedItemIndexs[0], action.fieldName],
            action.data
          )
        }
      }

      if (newState) {
        if (state.getIn([action.tableName, 'hasSelectors'])) {
          const tableData = newState
            .getIn([action.tableName, 'data'], List())
            .concat(newState.getIn([action.tableName, 'addedItems'], List()))
          const tableWithSelectedItems = tableData.filter(
            (row) => row.get('itemIsSelected') === true
          )
          newState = newState.setIn(
            [action.tableName, 'allSelected'],
            tableWithSelectedItems.size === tableData.size
          )
        }

        return newState
      }

      console.log(`Couldn't find item to update ${action.item.get('uuid')}`)
      return state
    }
    case ACTIONS.SELECT_ALL: {
      if (!state.get(action.tableName)) {
        return state
      }
      const { value } = action

      let data = state.getIn([action.tableName, 'data'])
      let addedItems = state.getIn([action.tableName, 'addedItems'])

      if (state.getIn([action.tableName, 'hasSelectors']) && action.value !== undefined) {
        data = data.map((row) => row.set('itemIsSelected', value))
        addedItems = addedItems.map((row) => row.set('itemIsSelected', value))
      }

      return updateTable(
        state,
        action.tableName,
        Map({
          allSelected: action.value,
        })
      )
        .setIn([action.tableName, 'data'], data)
        .setIn([action.tableName, 'addedItems'], addedItems)
    }
    case ACTIONS.ADD_ITEM: {
      if (!state.get(action.tableName) || state.getIn([action.tableName, 'useSubItems'])) {
        console.error('Trying to add an item to a table that does not exist or uses sub items')
        return state
      }
      const newItem = state.getIn([action.tableName, 'hasSelectors'])
        ? action.item.set('itemIsSelected', false)
        : action.item
      const addedItems = state.getIn([action.tableName, 'addedItems'], List()).push(newItem)

      return updateTable(
        state,
        action.tableName,
        Map({
          allSelected: false,
          hasNoData: false,
        })
      ).setIn([action.tableName, 'addedItems'], addedItems)
    }
    case ACTIONS.REMOVE_ITEM: {
      if (!state.get(action.tableName) || state.getIn([action.tableName, 'useSubItems'])) {
        console.error('Trying to remove an item from a table that does not exist or uses sub items')
        return state
      }
      const removedCount = state.getIn([action.tableName, 'removedCount'], 0) + 1
      const newItems = state
        .getIn([action.tableName, 'data'], List())
        .filter((item) => item.get('uuid') !== action.uuid)
      const newAddedItems = state
        .getIn([action.tableName, 'addedItems'], List())
        .filter((item) => item.get('uuid') !== action.uuid)
      const displayingNoItems = newItems.size === 0 && newAddedItems.size === 0
      const canLoadMore = state.getIn([action.tableName, 'canLoadMore'])
      return state
        .setIn([action.tableName, 'data'], newItems)
        .setIn([action.tableName, 'hasNoData'], displayingNoItems && !canLoadMore)
        .setIn([action.tableName, 'shouldRefresh'], displayingNoItems && canLoadMore)
        .setIn([action.tableName, 'addedItems'], newAddedItems)
        .setIn([action.tableName, 'removedCount'], removedCount)
    }
    case ACTIONS.HIDE_ERROR: {
      return updateTable(
        state,
        action.tableName,
        Map({
          error: undefined,
        })
      )
    }
    case NAVIGATED_ACTION: {
      return initialState
    }
    default: {
      return state
    }
  }
}
