/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { Icon } from '@yoco/design-system-icons/dist/react'

import FlatButton from 'components/buttons/FlatButton'
import CONSTANTS from 'libs/constants'

import classes from './wrappers.module.scss'
import { FormField } from './base'

export default class TaggedTextField extends FormField {
  constructor(props) {
    super(props)

    this.state = {
      showError: false,
      textValue: '',
    }
  }

  deleteTag(index, list) {
    if (index > -1) {
      this.updateTags(list.splice(index, 1))
    }
    if (this.props.tagsAvailable && this.props.tagsAvailable() === 0) {
      this.setState({
        showError: false,
      })
    }
  }

  updateTags(tags) {
    let tagList = tags

    if (!List.isList(tags)) {
      tagList = List(tags)
    }

    // super.onChange expects an event, so we need to place it in the correct spot.
    super.onChange({ target: { value: tagList } })
    if (this.props.tagsUpdated) {
      setTimeout(() => {
        this.props.tagsUpdated()
      })
    }
  }

  processTextInput(text) {
    if (text && text.indexOf(',') > -1) {
      // Add a tag
      this.setState({
        textValue: '',
      })

      let currentTags = this.props.value || List()

      if (!List.isList(currentTags)) {
        currentTags = List(currentTags)
      }

      text.split(',').forEach((tag) => {
        const trimmedTag = tag.trim()
        if (trimmedTag === '' || currentTags.indexOf(trimmedTag) > -1) {
          return
        }
        currentTags = currentTags.push(trimmedTag)
      })

      this.updateTags(currentTags)
    }
  }

  onChange(event) {
    if (this.props.tagsAvailable && this.props.tagsAvailable() === 0) {
      this.setState({
        showError: true,
      })
    }

    this.setState({
      textValue: event.target.value,
    })

    this.processTextInput(event.target.value)
  }

  renderInput() {
    return <div className={classes.row}>{this.renderMessageOrInput()}</div>
  }

  renderMessageOrInput() {
    if (this.state.showError) {
      return (
        <span>
          <div className={classes.errorMessage}>{this.props.errorMessage || 'Limit reached'}</div>
          <div ref={(ref) => (this.tagContainer = ref)} className={classes.tagContainer}>
            {this.renderTags()}
          </div>
        </span>
      )
    }
    let addButton = null
    const placeholder =
      this.props.value && this.props.value.size > 0
        ? ''
        : this.props.placeholder
        ? this.props.placeholder
        : 'Separate options with a comma'

    if (this.state.textValue && this.state.textValue.length > 0) {
      addButton = (
        <FlatButton
          className='secondary'
          label='+'
          style={{
            minWidth: '40px',
            margin: 0,
          }}
          onClick={() => {
            this.processTextInput(`${this.state.textValue},`)
          }}
        />
      )
    }

    return (
      <div>
        <div className={classes.tagsWrapper}>
          <input
            style={{ border: 'none', flex: 1, minWidth: 0 }}
            onChange={this.onChange.bind(this)}
            type={this.props.type}
            value={this.state.textValue}
            placeholder={placeholder}
            onFocus={this.onFocus.bind(this)}
            onBlur={this.onBlur.bind(this)}
          />
          {addButton}
        </div>
        <div style={{ display: 'block' }}>
          <div ref={(ref) => (this.tagContainer = ref)} className={classes.tagContainer}>
            {this.renderTags()}
          </div>
        </div>
      </div>
    )
  }

  renderTags() {
    return (this.props.value || List()).map((option, index) => {
      return (
        <div
          key={option}
          className={classes.tag}
          style={{ backgroundColor: CONSTANTS.VARIANT_COLORS[this.props.variantIndex] }}
          onClick={this.deleteTag.bind(this, index, this.props.value)}
        >
          <div className={classes.tagText}> {option} </div>
          <Icon name='cross' size={24} color='white' />
        </div>
      )
    })
  }
}

TaggedTextField.propTypes = {
  variantIndex: PropTypes.number.isRequired,
}
