/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import PropTypes from 'prop-types'

import store from 'redux/store'
import { submitForm } from 'redux/modules/forms'
import SplitBlock from 'components/blocks/SplitBlock'
import SplitHeader from 'components/headers/SplitHeader'
import FlatButton from 'components/buttons/FlatButton'

import { FormName, FormSubmissionFlags, InvoiceUpdateForm } from './Form'

function InvoiceUpdate({ invoice, onUpdated, onDeletePressed, onClose, children }) {
  return (
    <SplitBlock
      showingSplit
      formName={FormName}
      saveLabel='Next'
      headerRenderer={() => (
        <SplitHeader
          title='Draft Invoice'
          actions={[
            <FlatButton
              key='save-as-draft'
              label='Save as Draft'
              className='secondary'
              onClick={() => {
                // That's how form onSubmit handler will know
                // whether user click-ed "Save as Draft" or "Next" button,
                // @see ./Form/Update.js Line: 64
                FormSubmissionFlags.saveAsDraft = true
                store.dispatch(submitForm(FormName))
              }}
            />,
          ]}
          closeSplitView={onClose}
        />
      )}
      renderSplitContent={() => <InvoiceUpdateForm invoice={invoice} onUpdated={onUpdated} />}
      showDelete={!!onDeletePressed}
      deletePressed={onDeletePressed}
      onDismiss={onClose}
    >
      {children}
    </SplitBlock>
  )
}

InvoiceUpdate.propTypes = {
  invoice: PropTypes.func.isRequired,
  onUpdated: PropTypes.func,
  onDeletePressed: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.node,
}

export default InvoiceUpdate
