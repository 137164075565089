/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { callJSONApi } from 'libs/api'
import Alert from 'components/notifications/Alert'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import LoadingView from 'components/loaders/LoadingView'

function CancelBankDetailsUpdatePage({ routeParams, onCancelComplete }) {
  const uuid = routeParams?.uuid

  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState(
    uuid ? null : 'Invalid bank account update identifier - please try again'
  )

  useEffect(() => {
    if (uuid) {
      callJSONApi(
        `/business/bank-account/updates/${uuid}/cancel`,
        'POST',
        {},
        (response) => {
          if (response.status === 200) {
            onCancelComplete()
          } else {
            setErrorMessage(
              response.message
                ? `Unable to cancel this bank account change - please update your bank details again`
                : undefined
            )
          }
          setIsLoading(false)
        },
        (errorMessage) => {
          setErrorMessage(errorMessage)
          setIsLoading(false)
        }
      )
    }
  }, [onCancelComplete, setIsLoading, uuid])

  return (
    <MaxWidthBlock>
      {errorMessage ? <Alert messageType='danger'>{errorMessage}</Alert> : null}

      {isLoading ? <LoadingView /> : null}
    </MaxWidthBlock>
  )
}

CancelBankDetailsUpdatePage.propTypes = {
  routeParams: PropTypes.object,
  onCancelComplete: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => ({
  onCancelComplete: () => dispatch(push('/business/bank-account')),
})

export default connect(undefined, mapDispatchToProps)(CancelBankDetailsUpdatePage)
