import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import colors from 'ui/colors/colors.module.scss'
import layout from 'ui/layout/layout.module.scss'

function Well({ children, isInline, isCentered, textCentered }) {
  return (
    <div
      className={classnames(
        isInline ? layout.inline : null,
        isCentered ? layout.marginCentered : null,
        isCentered ? layout.inlineBlock : null,
        textCentered ? layout.textCentered : null,
        colors.backgroundLightGray,
        layout.p2
      )}
    >
      {children}
    </div>
  )
}

Well.propTypes = {
  isInline: PropTypes.bool,
  isCentered: PropTypes.bool,
  textCentered: PropTypes.bool,
  children: PropTypes.element,
}

export default Well
