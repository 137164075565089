/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Spinner from 'components/loaders/Spinner'
import { callCommonJSONAPI } from 'libs/api'

import classes from './account.module.scss'

export default class UnsubscribePage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tried: false,
    }
  }

  componentDidMount() {
    this.doUnsubscribe()
  }

  doUnsubscribe() {
    callCommonJSONAPI(
      `/emailPreferences/unsubscribe/${this.props.params.uuid}`,
      'POST',
      {},
      (data) => {
        this.setState({
          unsubscribeResult: data.data,
          tried: true,
        })
      },
      (prettyError, jqXHR) => {
        console.log(prettyError, jqXHR)
        if (jqXHR && (jqXHR.status === 404 || jqXHR.status === 401)) {
          this.setState({
            tried: true,
            unsubscribeResult: jqXHR.responseJSON.data,
          })
        }
        this.setState({
          tried: true,
        })
      }
    )
  }

  retry() {
    this.setState({
      tried: false,
    })
  }

  render() {
    if (this.state.tried) {
      if (this.state.unsubscribeResult) {
        if (this.state.unsubscribeResult.email) {
          return (
            <div className={classes.unsubscribeBlock}>
              Successfully unsubscribed {this.state.unsubscribeResult.email} from{' '}
              {this.state.unsubscribeResult.period} {this.state.unsubscribeResult.type.humanize()}
            </div>
          )
        }
        return (
          <div className={classes.unsubscribeBlock}>{this.state.unsubscribeResult.message}</div>
        )
      }

      return (
        <div className={classes.unsubscribeBlock}>
          There was a problem unsubscribing you, please{' '}
          <a onClick={this.retry.bind(this)}>try again</a> and if the problem persists contact{' '}
          <a href='mailto:support@yoco.co.za'>support@yoco.co.za</a>.
        </div>
      )
    }

    return (
      <div className={classes.unsubscribeBlock}>
        <Spinner blue />
        Unsubscribing...
      </div>
    )
  }
}

UnsubscribePage.propTypes = {
  params: PropTypes.object,
}
