import React from 'react'

import * as forms from 'libs/forms'
import { makeTestID } from 'libs/utils'

import { FormFieldNames } from './constants'

const baseTestID = 'customerForm'

function FormFields() {
  return (
    <>
      <forms.TextField
        key={FormFieldNames.CustomerName}
        name={FormFieldNames.CustomerName}
        label='Customer name'
        validators={[
          new forms.RequiredValidator('You must provide your name'),
          new forms.MaxLengthValidator(100, 'The name you have entered is too long'),
        ]}
        isFullWidth
        testID={makeTestID(baseTestID, FormFieldNames.CustomerName)}
        ariaLabel={makeTestID(baseTestID, FormFieldNames.CustomerName)}
      />
      <forms.TextField
        key={FormFieldNames.Company}
        name={FormFieldNames.Company}
        label='Company (optional)'
        isFullWidth
        testID={makeTestID(baseTestID, FormFieldNames.Company)}
        ariaLabel={makeTestID(baseTestID, FormFieldNames.Company)}
      />
      <forms.EmailField
        key={FormFieldNames.Email}
        name={FormFieldNames.Email}
        label='Email address'
        validators={[
          new forms.EitherOrValidator(FormFieldNames.Phone, 'You must provide a phone number'),
          new forms.EmailValidator('You must provide a valid email address'),
        ]}
        isFullWidth
        testID={makeTestID(baseTestID, FormFieldNames.Email)}
        ariaLabel={makeTestID(baseTestID, FormFieldNames.Email)}
      />
      <forms.TextField
        key={FormFieldNames.Phone}
        name={FormFieldNames.Phone}
        label='Phone number'
        validators={[
          new forms.EitherOrValidator(FormFieldNames.Email, 'You must provide an email address'),
          new forms.MobileValidator('You must provide a valid phone number'),
        ]}
        isFullWidth
        testID={makeTestID(baseTestID, FormFieldNames.Phone)}
        ariaLabel={makeTestID(baseTestID, FormFieldNames.Phone)}
      />
      <forms.TextField
        key={FormFieldNames.PhysicalAddress}
        name={FormFieldNames.PhysicalAddress}
        label='Physical address (optional)'
        isFullWidth
        testID={makeTestID(baseTestID, FormFieldNames.PhysicalAddress)}
        ariaLabel={makeTestID(baseTestID, FormFieldNames.PhysicalAddress)}
      />
      <forms.TextField
        key={FormFieldNames.VAT}
        name={FormFieldNames.VAT}
        label='VAT Number (optional)'
        validators={[new forms.VATNumberValidator('Please enter a valid VAT Number')]}
        isFullWidth
        testID={makeTestID(baseTestID, FormFieldNames.VAT)}
        ariaLabel={makeTestID(baseTestID, FormFieldNames.VAT)}
      />
    </>
  )
}

export default FormFields
