import React from 'react'
import { IndexRedirect } from 'react-router'

import BuyCardMachinePage from './BuyCardMachinePage'

export default function getRoutes() {
  return {
    path: 'card-machines',
    text: `Buy Card Machines`,
    icon: 'cart',
    indexRedirect: <IndexRedirect to='/card-machines/buy' />,
    hideInMenu: false,
    routes: [
      {
        path: 'buy',
        component: BuyCardMachinePage,
        hideInMenu: true,
      },
      {
        path: ':uuid',
        component: BuyCardMachinePage,
        hideInMenu: true,
      },
    ],
  }
}
