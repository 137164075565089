import React from 'react'
import PropTypes from 'prop-types'

import yocoLogo from 'assets/images/yoco.png'

import classes from './account.module.scss'

function AccountPage({ children }) {
  return (
    <div className={classes.accountWrapper}>
      <div className={classes.accountTable}>
        <div className={classes.accountInner}>
          <img src={yocoLogo} className={classes.logo} alt='Yoco logo' />
          {children}
        </div>
      </div>
    </div>
  )
}

AccountPage.propTypes = {
  children: PropTypes.element,
}

export default AccountPage
