import React, { useCallback } from 'react'
import propTypes from 'prop-types'
import { Map } from 'immutable'

import FlatButton from 'components/buttons/FlatButton'
import BlockHeader from 'components/headers/BlockHeader'
import { makeTestID } from 'libs/utils'

import { TableName } from './constants'

function CustomerListHeader({ onNewCustomer }) {
  const handleNewCustomerClick = useCallback(() => {
    onNewCustomer(new Map())
  }, [onNewCustomer])

  return (
    <BlockHeader
      title='Customers'
      actions={
        <FlatButton
          className='blueBackground'
          label='Add a Customer'
          onClick={handleNewCustomerClick}
          testID={makeTestID(TableName, 'newCustomer')}
        />
      }
    />
  )
}

CustomerListHeader.propTypes = {
  onNewCustomer: propTypes.func,
}

export default CustomerListHeader
