/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Immutable from 'immutable'
import PropTypes from 'prop-types'

import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import BlockHeader from 'components/headers/BlockHeader'
import ReferralLink from 'components/buttons/ReferralLink'
import * as forms from 'libs/forms'
import FlatButton from 'components/buttons/FlatButton'
import Alert from 'components/notifications/Alert'
import { getToken } from 'libs/api'

class DesktopSettingsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  getMessage() {
    if (
      this.props.queryParams.message &&
      this.props.queryParams.message.length > 0 &&
      !this.state.hideMessage
    ) {
      return (
        <Alert
          message={this.props.queryParams.message}
          messageType={this.props.queryParams.success ? 'success' : 'danger'}
          onClose={() => {
            this.setState({ hideMessage: true })
          }}
        />
      )
    }

    return undefined
  }

  authenticateDesktop() {
    window.location = `http://localhost:9626/configure?email=${this.props.session.getIn([
      'user',
      'email',
    ])}&userSessionToken=${getToken()}`
  }

  render() {
    return (
      <MaxWidthBlock>
        <BlockHeader title='Desktop Settings' actions={<ReferralLink />} />
        {this.getMessage()}
        <forms.FormBlock
          heading='Authentication'
          infoText='This will authenticate the desktop app with the current user.'
        >
          <FlatButton
            label='Authenticate'
            type='submit'
            className='blueBackground'
            onClick={this.authenticateDesktop.bind(this)}
          />
        </forms.FormBlock>
      </MaxWidthBlock>
    )
  }
}

DesktopSettingsPage.propTypes = {
  session: PropTypes.instanceOf(Immutable.Map),
  queryParams: PropTypes.object,
}

export default connect((state) => ({
  session: state.session,
  queryParams: state.routing.locationBeforeTransitions.query,
}))(DesktopSettingsPage)
