/* eslint-disable max-classes-per-file */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import numeral from 'numeral'

export class FormattedNumber extends Component {
  getClassName() {
    if (this.props.alignLeft) {
      return 'amount'
    }
    return 'amount text-right'
  }

  render() {
    let formattedNumber = '0'
    if (this.props.number) {
      formattedNumber = numeral(this.props.number).format(this.props.format)
    }

    return <div className={this.getClassName()}>{formattedNumber}</div>
  }
}

FormattedNumber.propTypes = {
  alignLeft: PropTypes.any,
  number: PropTypes.number,
  format: PropTypes.string,
}

FormattedNumber.defaultProps = {
  format: '0.0',
}

export class Number extends Component {
  getClassName() {
    if (this.props.alignLeft) {
      return 'amount'
    }
    return 'amount text-right'
  }

  render() {
    let formattedNumber = '0'
    if (this.props.number) {
      formattedNumber = numeral(this.props.number).format(this.props.format).replace(',', ' ')
    }

    return <span className={this.getClassName()}>{formattedNumber}</span>
  }
}

Number.propTypes = {
  alignLeft: PropTypes.any,
  number: PropTypes.number,
  format: PropTypes.string,
}

Number.defaultProps = {
  format: '0,0',
}
