import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { TabDashboard, TabPanel } from 'libs/tabs'

import PeriodBlock from './PeriodBlock'

export default class TabBlock extends Component {
  constructor(props) {
    super(props)

    this.oldIndex = -1
    this.state = {
      tabIndex: 1,
    }
  }

  tabChanged(index) {
    this.setState({
      tabIndex: index,
    })
  }

  trackTableViewed(index) {
    if (index !== this.oldIndex) {
      this.oldIndex = index
    }
  }

  renderTabPanel(title, insight, index, period) {
    if (this.state.tabIndex === index) {
      return (
        <TabPanel period={title} insight={insight} isActive={this.state.tabIndex === index}>
          <PeriodBlock
            isVisible={this.state.tabIndex === index}
            period={period}
            iKentoo={this.props.iKentoo}
          />
        </TabPanel>
      )
    }
    return <TabPanel period={title} insight={insight} isActive={this.state.tabIndex === index} />
  }

  render() {
    this.trackTableViewed(this.state.tabIndex)

    let periodYesterday
    let periodToday
    let periodLast7Days
    let periodLast30Days

    if (this.props.insight) {
      periodYesterday = this.props.insight.yesterday
      periodToday = this.props.insight.today
      periodLast7Days = this.props.insight.last7Days
      periodLast30Days = this.props.insight.last30Days
    }

    return (
      <TabDashboard tabChanged={(index) => this.tabChanged(index)} tabIndex={this.state.tabIndex}>
        {this.renderTabPanel('Yesterday', periodYesterday, 0, 'yesterday')}
        {this.renderTabPanel('Today', periodToday, 1, 'today')}
        {this.renderTabPanel('Last 7 Days', periodLast7Days, 2, 'last7days')}
        {this.renderTabPanel('Last 30 Days', periodLast30Days, 3, 'last30days')}
      </TabDashboard>
    )
  }
}

TabBlock.propTypes = {
  insight: PropTypes.object,
  iKentoo: PropTypes.bool,
}
