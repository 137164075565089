/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState, useEffect } from 'react'
import { Page, Text, Font, Image, Document, PDFDownloadLink } from '@react-pdf/renderer'
import QRCode from 'qrcode'
import font from 'assets/styles/fonts/sharpGrotesk/SharpGroteskSmBold20-subset.ttf'
import logo from 'assets/images/yoco.png'
import FlatButton from 'components/buttons/FlatButton'

import styles from './QRCodePDFGenerator.styles'

interface Props {
  url: string
}

const QRCodePDFGenerator: React.FunctionComponent<Props> = ({ url }) => {
  const [qrCode, setQrCode] = useState(undefined)
  useEffect(() => {
    QRCode.toDataURL(url, (err, url) => setQrCode(url))
  }, [url])

  Font.register({
    family: 'SharpGroteskSmBold20',
    fontWeight: 600,
    src: font,
  })

  function PDF() {
    return (
      <Document>
        <Page>
          <Image src={logo} style={styles.logo} />
          <Image src={qrCode} style={styles.qr} />
          <Text style={styles.supportingText}>Scan the QR code</Text>
          <Text style={styles.supportingText2}>to visit our online store</Text>
        </Page>
      </Document>
    )
  }

  return (
    <div>
      <PDFDownloadLink document={<PDF />} fileName='yoco-storefront-qr.pdf'>
        {({ loading }) => (
          <FlatButton
            label={loading ? 'Loading QR Code...' : 'Download QR Code'}
            type='button'
            className='blueBackground'
            style={{ minWidth: '175px' }}
          />
        )}
      </PDFDownloadLink>
    </div>
  )
}

export default QRCodePDFGenerator
