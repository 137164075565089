import React from 'react'
import { Link } from 'react-router'

import * as forms from 'libs/forms'
import { If } from 'libs/formats'
import { track } from 'libs/analytics'
import { makeTestID } from 'libs/utils'

import { FormFieldNames, dueInSelectOptions } from './constants'
import classes from './Settings.module.scss'

const baseTestID = 'invoiceSettingsForm'

const renderFormFields = (shouldShowInvoiceStartingNumber) => [
  <forms.FormBlock key='business_details' heading='Business details'>
    <forms.ImageUploadField
      name={FormFieldNames.LogoUri}
      label='Business logo'
      grouping='invoices-logo'
      uploadDescription={
        <>
          This will appear on your invoices and emails.
          <br />
          Maximum size: 5 MB
        </>
      }
      testID={makeTestID(baseTestID, FormFieldNames.LogoUri)}
      isFullWidth
    />
    <p>
      To add or change the business info or contact details shown on your invoices, go to{' '}
      <Link to='/business/details' className={classes.businessSettingsLink}>
        Business Settings &#8594;
      </Link>
    </p>
  </forms.FormBlock>,

  <forms.FormBlock key='invoice_template' heading='Invoice template'>
    <If condition={shouldShowInvoiceStartingNumber}>
      <forms.TextField
        name={FormFieldNames.InitialInvoiceNumber}
        label='Invoice starting number'
        validators={[
          new forms.NumberValidator(
            false,
            undefined,
            undefined,
            {
              notNumeric: 'Only numbers can be entered',
            },
            true
          ),
        ]}
        testID={makeTestID(baseTestID, FormFieldNames.InitialInvoiceNumber)}
        arialLabel={makeTestID('invoices', 'settings', 'invoice-starting-number')}
        isFullWidth
      />
    </If>
    <forms.SwitchField
      name={FormFieldNames.IncludeEFTDetails}
      label='Include EFT details on the invoice PDF'
      onClick={() =>
        track('invoices_settings_manual_eft_toggle_clicked', {
          name: 'invoices_settings_manual_eft_toggle_clicked',
        })
      }
      arialLabel={makeTestID('invoices', 'settings', 'include-eft-details')}
      testID={makeTestID(baseTestID, FormFieldNames.IncludeEFTDetails)}
      isFullWidth
    />
    <forms.SelectField
      name={FormFieldNames.DueIn}
      label='Default due date'
      options={dueInSelectOptions}
      testID={makeTestID(baseTestID, FormFieldNames.DueIn)}
      isFullWidth
    />
    <forms.TextareaField
      name={FormFieldNames.Terms}
      label='Default terms'
      placeholder='e.g. Return policies, warranties and cancellations'
      rows={3}
      testID={makeTestID(baseTestID, FormFieldNames.Terms)}
      ariaLabel={makeTestID('invoices', 'settings', 'default-terms')}
      isFullWidth
    />
  </forms.FormBlock>,
]

export default renderFormFields
