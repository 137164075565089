/* eslint-disable max-classes-per-file */
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Map } from 'immutable'
import S from 'string'
import classnames from 'classnames'

import { FormField } from './base'
import classes from './forms.module.scss'

export class ReadOnlyField extends FormField {
  getValue() {
    const value = this.props.value || this.props.defaultValue
    if (this.props.dateFormat && value) {
      return moment(this.props.value).format(this.props.dateFormat)
    }
    return value
  }

  renderLabelAndToolTip() {
    return (
      <div className={`${classes.fieldLabel} ${classes.readOnly}`}>
        {this.props.label || S(this.props.name).humanize().s}
      </div>
    )
  }

  renderInput() {
    return (
      <div>
        <div className={classes.inputWrapper}>
          <div
            className={classnames([
              classes.readOnlyField,
              this.props.disabled ? classes.readOnlyDisabled : null,
            ])}
          >
            {this.getValue()}
          </div>
        </div>
      </div>
    )
  }
}

export class CurrencyReadField extends FormField {
  renderInput() {
    let { value } = this.props
    if (typeof value !== 'string' && value !== undefined && value !== null) {
      value = value.toFixed(2)
    }

    if (typeof value !== 'string' && value !== undefined && value !== null) {
      if (this.props.localisation.get('currencyAllowsDecimals')) {
        value = value.toFixed(2)
      } else {
        value = value.toString()
      }
    }

    let prefix = null
    let suffix = null
    let positionalClass = 'left'
    switch (this.props.localisation.get('currency')) {
      case 'RWF':
        suffix = this.props.localisation.get('currencySymbol')
        positionalClass = 'right'
        break
      case 'ZAR':
      default:
        prefix = this.props.localisation.get('currencySymbol', 'R')
    }

    return (
      <div className={`${classes.currencyInputWrapper} input-group`} style={this.props.style}>
        {prefix && <span className={`${positionalClass} input-group-addon`}>{prefix}</span>}
        <div className={classes.readOnlyField} style={{ paddingRight: '16px' }}>
          {value}
        </div>
        {suffix && <span className={`${positionalClass} input-group-addon`}>{suffix}</span>}
      </div>
    )
  }
}

CurrencyReadField.propTypes = {
  localisation: PropTypes.objectOf(Map).isRequired,
}
