/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable max-classes-per-file */
import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Icon } from '@yoco/design-system-icons/dist/react'

import FlatButton from 'components/buttons/FlatButton'
import { Filter } from 'libs/filters'
import classes from 'libs/filters/filters.module.scss'
import CalendarButton from 'components/buttons/CalendarButton'
import DismissComponent from 'libs/DismissComponent'
import { makeTestID, mouseDownHandler } from 'libs/utils'
import PaddedContent from 'ui/layout/PaddedContent'

const baseTestID = makeTestID('reports', 'calendar')

export default class DateFilter extends Filter {
  dropdownRefs = {}

  constructor(props) {
    super(props)

    const availableMonths = []
    let loopMonth = moment().startOf('month')
    const createdDate = moment('2014-01-01').startOf('month')
    while (loopMonth >= createdDate) {
      availableMonths.push({
        value: loopMonth.format(),
        label: loopMonth.format('MMMM YYYY'),
      })

      loopMonth = loopMonth.subtract(1, 'month')
    }

    this.state = {
      selectedDay: moment(),
      day: false,
      month: false,
      custom: true,
      availableMonths,
      selectedMonth: availableMonths[0],
    }
  }

  initializeFilter(defaults = this.props.defaults) {
    this.wasInitialized = true

    if (defaults) {
      const calculatedDefaults = [moment(defaults[0]).format(), moment(defaults[1]).format()]
      this.props.batch.filterInitialized(this.props.filterKey, calculatedDefaults)
    }
  }

  mouseDownHandler() {
    mouseDownHandler()

    this.closeInternalDropdowns()
  }

  closeInternalDropdowns(dropdownsToOmit) {
    if (!Array.isArray(dropdownsToOmit)) {
      dropdownsToOmit = []
    }

    Object.values(this.dropdownRefs)
      .filter((ref) => ref && !dropdownsToOmit.includes(ref) && ref.state?.toggled)
      .map((ref) => ref.setState({ toggled: false }))
  }

  getStartDate() {
    if (this.props.values && this.props.values.size > 0) {
      return moment(this.props.values.get(0))
    }

    return moment()
  }

  getEndDate() {
    if (this.props.values && this.props.values.size > 1) {
      return moment(this.props.values.get(1))
    }

    return moment().add(1, 'days')
  }

  getFilterClass() {
    return `${super.getFilterClass()} ${classes.dateFilter}`
  }

  getFilterIcon() {}

  getFilterBoxIcon() {
    return 'calendar'
  }

  startDatePicked(incomingMoment) {
    const startDate = incomingMoment
    let endDate = this.getEndDate()
    if (endDate <= startDate) {
      endDate = moment(startDate).add(1, 'day')
    }

    this.setState({
      month: false,
      day: false,
      custom: false,
    })
    this.props.batch.filterInitialized(
      this.props.filterKey,
      [startDate.format(), endDate.format()],
      true
    )
  }

  endDatePicked(incomingMoment) {
    const endDate = incomingMoment
    let startDate = this.getStartDate()
    if (endDate <= startDate) {
      startDate = moment(endDate).subtract(1, 'day')
    }

    this.setState({
      month: false,
      day: false,
      custom: false,
    })
    this.props.batch.filterInitialized(
      this.props.filterKey,
      [startDate.format(), endDate.format()],
      true
    )
  }

  setDaySelected() {
    this.dayPicked(this.state.selectedDay)
  }

  dayPicked(incomingMoment) {
    const startDate = moment(incomingMoment)
    const endDate = moment(startDate).add(1, 'day')

    this.setSelection('day', { selectedDay: startDate })
    this.props.batch.filterInitialized(
      this.props.filterKey,
      [startDate.format(), endDate.format()],
      true
    )
  }

  setMonthSelected() {
    this.monthPicked(this.state.selectedMonth)
  }

  monthPicked(option) {
    const startDate = moment(option.value)
    const endDate = moment(startDate).add(1, 'month')

    this.setSelection('month', {
      selectedMonth: option,
    })
    this.props.batch.filterInitialized(
      this.props.filterKey,
      [startDate.format(), endDate.format()],
      true
    )
  }

  getSelectedString() {
    return `${this.getStartDate().format('DD/MM/YYYY')} to ${this.getEndDate().format(
      'DD/MM/YYYY'
    )}`
  }

  getOptionButtonClass(key) {
    let className = classes.optionButton
    if (this.state[key]) {
      className = `${className} ${classes.selectedOptionButton}`
    }

    return className
  }

  rangeChanged(range) {
    const startDate = range[0].format()
    const endDate = range[1].add(1, 'day').format()
    this.props.batch.filterInitialized(this.props.filterKey, [startDate, endDate], true)

    this.setSelection('custom')
  }

  setSelection(selected, state = {}) {
    const stateObject = {
      month: false,
      day: false,
      custom: false,
    }
    stateObject[selected] = true
    this.setState({ ...stateObject, ...state })
    if (this.props.onFilterChange) {
      this.props.onFilterChange(stateObject)
    }
  }

  getDefaultRange() {
    return [this.getStartDate(), this.getEndDate().subtract(1, 'days')]
  }

  getDropdown() {
    const shiftStart = this.props.startOfBusinessDay === 0 ? 12 : this.props.startOfBusinessDay

    return (
      <div className={`${this.getDropdownClassName()} ${classes.dateFilter}`}>
        <div className={classes.responsiveTitle}>Select a date range</div>
        <div className={classes.lineItem}>
          <div
            onClick={this.setDaySelected.bind(this)}
            className={classes.option}
            data-testid={makeTestID(baseTestID, 'pickADay')}
          >
            {this.getOptionButton('day')} Pick a <strong>day</strong>
          </div>
          <span
            onMouseDown={(e) => {
              e.stopPropagation()
              this.closeInternalDropdowns([this.dropdownRefs.dayDropdown])
            }}
          >
            <CalendarButton
              date={this.state.selectedDay}
              dateChanged={this.dayPicked.bind(this)}
              testID={makeTestID(baseTestID, 'dayDropdown')}
              ref={(el) => {
                this.dropdownRefs.dayDropdown = el
              }}
            />
          </span>
        </div>
        <div className={classes.lineItem}>
          <div
            onClick={this.setMonthSelected.bind(this)}
            className={classes.option}
            data-testid={makeTestID(baseTestID, 'pickAMonth')}
          >
            {this.getOptionButton('month')} Pick a <strong>month</strong>
          </div>
          <span
            onMouseDown={(e) => {
              e.stopPropagation()
              this.closeInternalDropdowns([this.dropdownRefs.monthDropdown])
            }}
          >
            <MonthDropdown
              selectedOption={this.state.selectedMonth}
              options={this.state.availableMonths}
              onChange={this.monthPicked.bind(this)}
              ref={(el) => {
                this.dropdownRefs.monthDropdown = el
              }}
            />
          </span>
        </div>
        <div className={classes.lineItem}>
          <div
            onClick={() => this.setSelection('custom')}
            className={classes.option}
            data-testid={makeTestID(baseTestID, 'pickACustomRange')}
          >
            {this.getOptionButton('custom')} Pick a <strong>custom</strong> range
          </div>
          <span
            onMouseDown={(e) => {
              e.stopPropagation()
              this.closeInternalDropdowns([this.dropdownRefs.customRangeDropdown])
            }}
          >
            <CalendarButton
              defaultRange={this.getDefaultRange()}
              rangeChanged={this.rangeChanged.bind(this)}
              isRangePicker
              testID={makeTestID(baseTestID, 'customRangeDropdown')}
              ref={(el) => {
                this.dropdownRefs.customRangeDropdown = el
              }}
            />
          </span>
        </div>
        <div className={classes.customDivider}>
          <div className={classes.line} />
          <div className={classes.text}>Selected date range</div>
        </div>
        <div className={classes.dateDisplay}>
          <div className={classes.dates}>
            <div className={classes.descriptor}>
              {shiftStart}am&nbsp;
              {this.getStartDate().format('MMMM Do YYYY')}&nbsp;
              <span className={classes.faded}>until</span>&nbsp;
              {shiftStart}am&nbsp;
              {this.getEndDate().format('MMMM Do YYYY')}
            </div>
          </div>
        </div>
        <PaddedContent className={classes.doneAndResetButtonsContainer}>
          <FlatButton
            label='Reset'
            testID={makeTestID(baseTestID, 'reset')}
            onClick={() => {
              const defaults = this.props.defaults
                ? [moment(this.props.defaults[0]), moment(this.props.defaults[1])]
                : [moment().subtract(1, 'day'), moment()]

              this.rangeChanged(defaults)
              this.toggle()
            }}
          />
          <FlatButton
            className='blueBackground'
            label='Done'
            testID={makeTestID(baseTestID, 'done')}
            onClick={() => {
              this.toggle()
            }}
          />
        </PaddedContent>
      </div>
    )
  }
}

DateFilter.defaultProps = {
  filterKey: 'created',
}

DateFilter.propTypes = {
  startOfBusinessDay: PropTypes.number,
}

class MonthDropdown extends DismissComponent {
  toggle() {
    this.setState({
      toggled: !this.state.toggled,
    })
  }

  optionSelected(option) {
    this.setState({
      toggled: false,
    })

    this.props.onChange(option)
  }

  getDropdown() {
    if (this.state.toggled) {
      return (
        <div className={classes.monthDropdown}>
          {this.props.options.map((option) => {
            let className = classes.dropdownOption
            if (this.props.selectedOption.value === option.value) {
              className = `${className} ${classes.pickedOption}`
            }
            return (
              <div
                className={className}
                key={option.value}
                onClick={this.optionSelected.bind(this, option)}
              >
                <span className='option-label'>{option.label}</span>
              </div>
            )
          })}
        </div>
      )
    }
  }

  getClassName() {
    let className = `${classes.numberSelect} ${classes.dateSelect} ${classes.monthSelect}`
    if (this.state.toggled) {
      className = `${className} ${classes.toggled}`
    }

    return className
  }

  render() {
    return (
      <div
        className={this.getClassName()}
        onMouseDown={this.mouseDownHandler.bind(this)}
        onMouseUp={this.mouseUpHandler.bind(this)}
      >
        <div
          className={`${classes.valueDisplay} ${classes.dateValueDisplay}`}
          onClick={this.toggle.bind(this)}
        >
          {this.props.selectedOption.label}
        </div>
        <span
          onClick={this.toggle.bind(this)}
          data-testid={makeTestID(baseTestID, 'monthDropdown')}
        >
          <Icon name='chevron-down' size={24} />
        </span>
        {this.getDropdown()}
      </div>
    )
  }
}
