/* eslint-disable react/no-unstable-nested-components */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import S from 'string'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'

import BlockHeader from 'components/headers/BlockHeader'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import FlatButton from 'components/buttons/FlatButton'
import SplitBlock from 'components/blocks/SplitBlock'
import { showMessage } from 'redux/modules/notifications'
import { callJSONApi } from 'libs/api'
import { FirebaseHandler, features, roleDescriptions } from 'libs/firebase'
import { Table, EmptyTableView, TableRow } from 'libs/tables'
import ConfirmPopup from 'components/popups/ConfirmPopup'
import { removeItem, refreshTable } from 'redux/modules/tables'
import CONSTANTS from 'libs/constants'
import { makeTestID } from 'libs/utils'
import { submitForm, clearForm } from 'redux/modules/forms'

import { ClearStaffPin, CLEAR_STAFF_PIN_FORM_NAME } from './ClearStaffPin'
import { EditStaffPin, SET_STAFF_PIN_FORM_NAME } from './EditStaffPin'
import StaffForm from './StaffForm'

export const baseTestID = makeTestID('staff')

const TABLE_NAME = 'staffMembers'

const StaffSplitStates = {
  EDIT: {
    content: function EditState(props) {
      return <StaffForm {...props} />
    },
    showDelete: (component) => !!component.state.shownRow,
    saveLabel: (component) => (component.state.shownRow ? 'Save' : 'Invite'),
    onCancel: (component) => () => component.splitViewClosed(),
    formName: TABLE_NAME,
    deletePressed: (component) => () =>
      component.setState({
        showDeleteModal: true,
        deleteUUID: component.state.shownRow.get('uuid'),
      }),
    showSave: true,
    onSuccess: (component) => () => {
      component.splitViewClosed()
      component.props.dispatch(refreshTable(TABLE_NAME))
    },
  },
  PIN: {
    content: function PinState(props) {
      return <EditStaffPin {...props} />
    },
    showDelete: () => false,
    saveLabel: () => 'Save',
    onCancel: (component) => () => component.setState({ splitContent: StaffSplitStates.EDIT }),
    formName: SET_STAFF_PIN_FORM_NAME,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    deletePressed: () => () => {},
    showSave: true,
    onSuccess: (component) => (staffPin) => {
      if (component.state.shownRow) {
        component.splitViewClosed()
        component.props.dispatch(refreshTable(TABLE_NAME))
      } else {
        component.setState({ staffPin, splitContent: StaffSplitStates.EDIT })
        component.props.dispatch(clearForm(SET_STAFF_PIN_FORM_NAME))
      }
    },
  },
  CLEAR: {
    content: function ClearState(props) {
      return <ClearStaffPin {...props} />
    },
    showSave: false,
    saveLabel: () => '',
    showDelete: () => true,
    onCancel: (component) => () => component.setState({ splitContent: StaffSplitStates.EDIT }),
    deletePressed: (component) => () =>
      component.props.dispatch(submitForm(CLEAR_STAFF_PIN_FORM_NAME)),
    deleteLabel: 'Clear PIN',
    onSuccess: (component) => () => {
      component.splitViewClosed()
      component.props.dispatch(refreshTable(TABLE_NAME))
    },
  },
}
class StaffPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      shownRow: null,
      splitContent: StaffSplitStates.EDIT,
      staffPin: '',
    }
  }

  onRemoveStaff(uuid) {
    callJSONApi(
      `/staff/${uuid}`,
      'DELETE',
      {},
      (response) => {
        if (response.status === 200) {
          this.props.dispatch(removeItem(TABLE_NAME, this.state.deleteUUID))
          this.hideDeletePopup()
          this.setState({
            shownRow: null,
            showingSplit: false,
          })
        } else {
          this.props.dispatch(showMessage(`Error removing staff member: ${response.message}`))
          this.hideDeletePopup()
        }
      },
      (prettyError) => {
        this.props.dispatch(showMessage(`Error removing staff member: ${prettyError}`, 'danger'))
        this.hideDeletePopup()
      }
    )
  }

  showRow(immutableRow) {
    this.setState((currentState) => {
      const newState = { ...currentState }

      if (newState.shownRow && newState.shownRow.get('uuid') === immutableRow.get('uuid')) {
        newState.shownRow = null
      } else {
        newState.shownRow = immutableRow
        newState.email = immutableRow.get('email')
        newState.phone = immutableRow.get('mobileNumber')
      }

      if (newState.shownRow) {
        newState.showingSplit = true
      } else {
        newState.showingSplit = false
      }

      newState.splitContent = StaffSplitStates.EDIT

      return newState
    })
  }

  hideDeletePopup() {
    this.setState({
      showDeleteModal: false,
      deleteUUID: null,
    })
  }

  deletePopUp() {
    return (
      <ConfirmPopup
        showing={this.state.showDeleteModal}
        icon='icon-delete-garbage-streamline'
        onCancel={() => this.hideDeletePopup()}
        onEsc={() => this.hideConfirmation()}
        title='Remove'
        subtitle='Are you sure you want to remove this staff member?'
        actionType='destructive'
        iconType='red-text'
        onConfirm={() => {
          this.onRemoveStaff(this.state.deleteUUID)
        }}
      />
    )
  }

  inviteStaff() {
    this.setState({
      showingSplit: true,
    })
  }

  splitViewClosed() {
    this.setState({
      shownRow: null,
      showingSplit: false,
      splitContent: StaffSplitStates.EDIT,
      staffPin: '',
    })
  }

  renderTable() {
    return (
      <Table
        api='/staff/'
        name='staffMembers'
        useOffset
        waitForFilterLoad={false}
        hideNoResults
        rowsClickable
        getHeader={() => {
          return (
            <tr className='visible-header'>
              <th>Name</th>
              <th className='hide-on-mobile'>Email</th>
              <th className='hide-on-mobile'>Mobile Number</th>
              <th>Permissions</th>
            </tr>
          )
        }}
        getRow={(immutableRow, rowIndex) => {
          return (
            <TableRow
              onClick={() => this.showRow(immutableRow)}
              selected={
                this.state.shownRow && this.state.shownRow.get('uuid') === immutableRow.get('uuid')
              }
              rowIndex={rowIndex}
              key={immutableRow.get('uuid')}
            >
              <td>{immutableRow.get('fullName')}</td>
              <td className='hide-on-mobile'>{immutableRow.get('email')}</td>
              <td className='hide-on-mobile'>{immutableRow.get('mobileNumber')}</td>
              <td>{S(immutableRow.get('userType')).humanize().s}</td>
            </TableRow>
          )
        }}
        emptyTable={
          <EmptyTableView title='Your business currently has no staff members'>
            <span>
              Click
              {/* eslint-disable-next-line */}
              <span
                style={{ color: CONSTANTS.YOCO_BLUE, cursor: 'pointer' }}
                onClick={() => this.inviteStaff()}
              >
                Invite Staff Member
              </span>{' '}
              to add staff to your business
            </span>
          </EmptyTableView>
        }
        dataLoaded={(response) => {
          if (response && response.get('userTypeRoles')) {
            this.setState({
              userRoles: response.get('userTypeRoles').toJS(),
            })
          }
        }}
      />
    )
  }

  renderSplitContent() {
    return this.state.splitContent.content({
      formName: TABLE_NAME,
      shownRow: this.state.shownRow,
      closeSplitView: () => this.splitViewClosed(),
      onSuccess: (data) => {
        this.state.splitContent.onSuccess(this)(data)
      },
      additionalData: this.state.userRoles,
      showActions: this.state.showingSplit,
      hasStaffSwitching: (this.props.features || Map()).getIn(['staffSwitching', 'enabled']),
      onSetPIN: () => this.setState({ splitContent: StaffSplitStates.PIN }),
      onClearPIN: () => {
        const newState = this.state.shownRow
          ? { splitContent: StaffSplitStates.CLEAR }
          : { staffPin: '' }
        return this.setState(newState)
      },
      maskedEmail: this.props.userInfo.get('user').get('email'),
      roleDescriptions: this.props.roleDescriptions ?? List(),
      staffPIN: this.state.staffPin,
    })
  }

  render() {
    return (
      <SplitBlock
        formName={this.state.splitContent.formName}
        showingSplit={this.state.showingSplit}
        header={this.state.shownRow ? 'Edit Staff Member' : 'Invite a Staff Member'}
        saveLabel={this.state.splitContent.saveLabel(this)}
        showDelete={this.state.splitContent.showDelete(this)}
        cancelPressed={this.state.splitContent.onCancel(this)}
        renderSplitContent={() => this.renderSplitContent()}
        deletePressed={this.state.splitContent.deletePressed(this)}
        onDismiss={() => this.splitViewClosed()}
        deleteLabel={this.state.splitContent.deleteLabel}
        showSave={this.state.splitContent.showSave}
      >
        <MaxWidthBlock>
          <BlockHeader
            title='Manage Staff Members'
            actions={
              <FlatButton
                className='blueBackground'
                label='Invite Staff Member'
                onClick={() => this.inviteStaff()}
                testID={makeTestID(baseTestID, 'inviteStaffMember')}
              />
            }
          />
          {this.renderTable()}
        </MaxWidthBlock>
        {this.deletePopUp()}
      </SplitBlock>
    )
  }
}

StaffPage.propTypes = {
  features: PropTypes.object,
  userInfo: PropTypes.object,
  dispatch: PropTypes.func,
  roleDescriptions: PropTypes.object,
}

export const ReduxConnectedPage = connect((state) => ({
  productData: state.tables,
  userInfo: state.session,
}))(StaffPage)

export default FirebaseHandler.connect(
  ReduxConnectedPage,
  Map({
    features,
    roleDescriptions,
  })
)
