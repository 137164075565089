import React from 'react'
import styles from './CapitalQuoteBlock.module.scss'

interface Props {
  repaymentPercentage: string
  dailyAmount: string
}

const CapitalQuoteBlock: React.FunctionComponent<Props> = ({
  repaymentPercentage,
  dailyAmount,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.textWrapper}>
        <div>
          <span className={styles.text}>{repaymentPercentage}</span> Daily holdback of your card
          payments
        </div>
        <div>
          <span className={styles.text}>{dailyAmount}</span> Estimated daily amount of your card
          payments
        </div>
      </div>
    </div>
  )
}

export default CapitalQuoteBlock
