import ROLES from 'libs/roles'

import CollectionsPage from './CollectionsPage'
import CustomersPage from './CustomersPage'
import ProductsPage from './ProductsPage'
import StockPage from './StockPage'
import StaffPage from './StaffPage'
import StaffManagement from './components/StaffManagement'

export default function getRoutes(hasNewStaffManagementEnabled = false) {
  return {
    path: 'store',
    text: 'Manage My Store',
    icon: 'shop',
    routes: [
      {
        path: 'products',
        text: 'Products',
        component: ProductsPage,
        requiresOneRole: [ROLES.MANAGE_INVENTORY],
      },
      {
        path: 'brands-and-categories',
        text: 'Brands and Categories',
        component: CollectionsPage,
        requiresOneRole: [ROLES.MANAGE_INVENTORY],
      },
      {
        path: 'stock',
        text: 'Stock',
        component: StockPage,
        requiresOneRole: [ROLES.MANAGE_INVENTORY],
        requiresOneOfFeatures: ['manageStockPortal'],
      },
      {
        path: 'staff',
        text: 'Staff',
        component: hasNewStaffManagementEnabled ? StaffManagement : StaffPage,
        requiresOneRole: [ROLES.MANAGE_STAFF],
      },
      {
        path: 'customers',
        text: 'Customers',
        component: CustomersPage,
      },
    ],
    redirects: [
      {
        from: 'items',
        to: 'products',
      },
    ],
  }
}
