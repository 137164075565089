/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'

import { FormField } from 'libs/forms'
import classes from 'components/icons/icons.module.scss'

export default class IconSelectorField extends FormField {
  iconSelected(icon) {
    this.onChange({ target: { value: icon } })
  }

  getSelected(icon) {
    if (this.props.value === icon) {
      return classes.selected
    }
    return ''
  }

  renderInput() {
    return (
      <div className={`${classes.iconSelectorField}`}>
        {this.props.icons.map((icon) => {
          return (
            <div
              className={`${this.getSelected(icon)}`}
              key={icon}
              onClick={() => {
                this.iconSelected(icon)
              }}
            >
              <i className={`icon2-${icon}`} />
            </div>
          )
        })}
      </div>
    )
  }
}

IconSelectorField.propTypes = {
  icons: PropTypes.array,
  value: PropTypes.string,
}
