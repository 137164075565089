export const getFilterValues = (batch, filter) => batch.getIn(['filters', filter, 'values'])

export const getFilter = (batch, filterName, callback = null) => {
  const filter = getFilterValues(batch, filterName)
  if (!filter.size) {
    return {}
  }

  if (callback) {
    return callback(filter)
  }

  return { [filterName]: filter.get(0) }
}

export const getSearchParam = (batch) => {
  const search = batch.getIn(['search', 'value'])

  if (!search) {
    return {}
  }

  return { search }
}

export const getParamAs = (URLSearchParam, originalParam, targetParam) => {
  if (!URLSearchParam.has(originalParam)) {
    return {}
  }

  return { [targetParam]: URLSearchParam.get(originalParam) }
}

export const formatParamsString = (params) => {
  const urlParams = new URLSearchParams(params)
  return urlParams.toString().length > 0 ? `?${urlParams}` : ''
}

export const getUrlParams = (url) => new URLSearchParams(url.replace(/^\//, ''))
