import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import * as forms from 'libs/forms'
import { fieldUpdated, initializeForm } from 'redux/modules/forms'
import { FORM_NAMES } from 'redux/modules/onboarding'
import { shouldRemoveLoader } from 'libs/utils'

import { connect } from 'react-redux'
import { selectTradingAddress } from 'redux/modules/onboarding/businessDetails/selectors'
import classes from './styles.module.scss'
import TradingAddressForm from './components/TradingAddressForm'

const sanitizeCompanyRegistrationNumber = (value) => {
  let registrationNumber = value.replace(/[^0-9]+/g, '')
  if (registrationNumber.length > 10) {
    registrationNumber = `${registrationNumber.substring(0, 4)}/${registrationNumber.substring(
      4,
      registrationNumber.length
    )}`
    registrationNumber = `${registrationNumber.substring(0, 11)}/${registrationNumber.substring(
      11,
      registrationNumber.length + 1
    )}`
  } else if (registrationNumber.length > 4) {
    registrationNumber = `${registrationNumber.substring(0, 4)}/${registrationNumber.substring(
      4,
      registrationNumber.length
    )}`
  }

  return registrationNumber
}

const BusinessInfoForm = ({
  showTradingAddressForm,
  showCompanyRegistrationForm,
  initializeForms,
  updateCompanyRegistrationNumberField,
  onSuccess: onSuccessCallback,
  businessAddress,
}) => {
  const [isTradingAddressFormSubmitted, setTradingAddressFormSubmitted] = useState(false)
  const [
    isCompanyRegistrationNumberFormSubmitted,
    setCompanyRegistrationNumberFormSubmitted,
  ] = useState(false)

  useEffect(() => {
    if (window.ReactNativeWebView && shouldRemoveLoader) {
      window.ReactNativeWebView.postMessage('removeLoader')
    }
  }, [])

  useEffect(() => {
    if (businessAddress.get('firstLine')) {
      initializeForms(FORM_NAMES.BUSINESS_ADDRESS, businessAddress)
    }
  }, [initializeForms, businessAddress])

  const onTradingAddressFormSuccess = () => {
    setTradingAddressFormSubmitted(true)
  }

  const onCompanyRegistrationNumberFormSuccess = () => {
    setCompanyRegistrationNumberFormSubmitted(true)
  }

  useEffect(() => {
    // This is a little bit hacky because we're actually submitting multiple forms within the same popup. We only
    // trigger the `onSuccessCallback` when both forms are submitted successfully
    // In the event that we aren't showing any form, we avoid triggering an immediate `onSuccessCallback`.
    if (showTradingAddressForm || showCompanyRegistrationForm) {
      const isTradingAddressFormSuccessful =
        !showTradingAddressForm || (showTradingAddressForm && isTradingAddressFormSubmitted)

      const isCompanyRegistrationNumberFormSuccessful =
        !showCompanyRegistrationForm ||
        (showCompanyRegistrationForm && isCompanyRegistrationNumberFormSubmitted)

      if (isTradingAddressFormSuccessful && isCompanyRegistrationNumberFormSuccessful) {
        if (onSuccessCallback) {
          onSuccessCallback()
        }

        setTradingAddressFormSubmitted(false)
        setCompanyRegistrationNumberFormSubmitted(false)
      }
    }
  }, [
    showTradingAddressForm,
    isTradingAddressFormSubmitted,
    showCompanyRegistrationForm,
    isCompanyRegistrationNumberFormSubmitted,
    onSuccessCallback,
  ])

  return (
    <div>
      {showTradingAddressForm && (
        <>
          <h4 className={classes.subheading}>Business address</h4>
          <TradingAddressForm onSuccess={onTradingAddressFormSuccess} />
        </>
      )}

      {showCompanyRegistrationForm && (
        <forms.Form
          action='/business/companyRegistrationNumber'
          name={FORM_NAMES.COMPANY_REGISTRATION_NUMBER}
          onSuccess={onCompanyRegistrationNumberFormSuccess}
        >
          <forms.FormBlock>
            <h4 className={classes.subheading}>Company Registration Number</h4>
            <forms.TextField
              name='companyRegistrationNumber'
              label='Company Registration Number'
              required
              isFullWidth
              onChange={(event) => {
                const companyRegistrationNumber = sanitizeCompanyRegistrationNumber(
                  event.target.value
                )

                updateCompanyRegistrationNumberField(companyRegistrationNumber)
              }}
              maxLength={14}
              showRemaining={false}
              subTitle='Please use the format XXXX/XXXXXX/XX'
              validators={[new forms.RegisteredBusinessNumberValidator()]}
            />
          </forms.FormBlock>
        </forms.Form>
      )}
    </div>
  )
}

BusinessInfoForm.propTypes = {
  showTradingAddressForm: PropTypes.bool,
  showCompanyRegistrationForm: PropTypes.bool,
  initializeForms: PropTypes.func.isRequired,
  updateCompanyRegistrationNumberField: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  businessAddress: PropTypes.object.isRequired,
}

BusinessInfoForm.defaultProps = {
  showTradingAddressForm: true,
  showCompanyRegistrationForm: true,
  onSuccess: undefined,
}

const mapStateToProps = (state) => ({
  businessAddress: selectTradingAddress(state),
})

const mapDispatchToProps = (dispatch) => ({
  initializeForms: (formName, data) => dispatch(initializeForm(formName, data)),
  updateCompanyRegistrationNumberField: (companyRegistrationNumber) => {
    dispatch(
      fieldUpdated(
        FORM_NAMES.COMPANY_REGISTRATION_NUMBER,
        'companyRegistrationNumber',
        companyRegistrationNumber
      )
    )
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(BusinessInfoForm)
