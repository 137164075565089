import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { RequestStatus } from 'redux/modules/helpers/requestHelper'
import LoadingView from 'components/loaders/LoadingView'
import FlatButton from 'components/buttons/FlatButton'
import { If } from 'libs/formats'
import failureImage from 'assets/images/Sadface.png'

import classes from './request.module.scss'

export default class Request extends Component {
  render() {
    if (this.props.request.status === RequestStatus.SUCCESS) {
      return this.props.children
    }
    return (
      <div className={classes.container}>
        <If condition={this.props.request.status === RequestStatus.STARTED}>
          <LoadingView message={this.props.loadingMessage} />
        </If>

        <If condition={this.props.request.status === RequestStatus.FAILURE}>
          <div style={{ flex: 1, padding: '30px' }}>
            <h1>Whoops! Something has gone wrong</h1>
            <img className={classes.image} src={failureImage} alt='sadface' />
            <h4>{this.props.errorTitle}</h4>
            <p className={classes.errorDescription}>{this.props.request.error}</p>
            {this.props.onRetryPress && (
              <FlatButton
                label='Retry'
                onClick={this.props.onRetryPress}
                className='blueBackground'
              />
            )}
          </div>
        </If>
      </div>
    )
  }
}

Request.propTypes = {
  request: PropTypes.object,
  loadingMessage: PropTypes.string.isRequired,
  errorTitle: PropTypes.string,
  onRetryPress: PropTypes.func,
  children: PropTypes.node,
}
