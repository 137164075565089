import React from 'react'

import TableRow from 'libs/tables/TableRow'
import styles from './DeductionPlanRowItem.module.scss'

interface Props {
  onClick: () => void
  selected: boolean
  rowIndex: number
  uuid: string
  planCategory: string
  planProviderDisplayName: string
  payoutDeductionState: string
  totalAmountPaid: string
  totalAmountPayable: string
  created: string
}

const DeductionPlanRowItem: React.FunctionComponent<Props> = ({
  onClick,
  selected,
  rowIndex,
  uuid,
  planCategory,
  planProviderDisplayName,
  payoutDeductionState,
  totalAmountPaid,
  totalAmountPayable,
  created,
}) => {
  return (
    <TableRow onClick={onClick} selected={selected} rowIndex={rowIndex} key={uuid}>
      <td key={`${rowIndex}type`} className={styles.rowItem}>
        {`${planCategory} by ${planProviderDisplayName}`}
      </td>
      <td key={`${rowIndex}state`} className={styles.rowItem}>
        {payoutDeductionState}
      </td>
      <td key={`${rowIndex}totalAmountPaid`} className={styles.rowItem}>
        {totalAmountPaid}
      </td>
      <td key={`${rowIndex}totalAmountPayable`} className={styles.rowItem}>
        {totalAmountPayable}
      </td>
      <td key={`${rowIndex}created`} className={styles.rowItem}>
        {created}
      </td>
    </TableRow>
  )
}

export default DeductionPlanRowItem
