import React from 'react'
import PropTypes from 'prop-types'

import classes from './tooltip.module.scss'

export default function Tooltip({ children, content }) {
  return (
    <div className={classes.tooltip}>
      {children}

      <div className={classes.content}>{content}</div>
    </div>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node,
  content: PropTypes.node,
}
