import React from 'react'
import PropTypes from 'prop-types'

import FlatButton from 'components/buttons/FlatButton'
import LoadingView from 'components/loaders/LoadingView'
import PopupComponent from 'libs/modals/popups'
import popupClasses from 'libs/modals/popups.module.scss'
import { track } from 'libs/analytics'

import classes from './sales.module.scss'

/**
 * @deprecated This needs to be updated to account for the new Instant Payout
 * feature released in the app November 2021
 */
export default class InstantPayoutPopup extends PopupComponent {
  escPressed() {
    if (this.props.showing) {
      track('InstantPayoutCancel', { balance: this.props.balance, fees: this.props.fee })
      super.escPressed()
    }
  }

  onCancel() {
    track('InstantPayoutCancel', { balance: this.props.balance, fees: this.props.fee })
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  onConfirm = () => {
    track('InstantPayoutConfirmed', { balance: this.props.balance, fees: this.props.fee })
    this.setState({ isLoading: true })
    this.props.onConfirm()
  }

  getContent() {
    return (
      <div className={classes.instantPayout}>
        {this.props.loading ? <LoadingView /> : null}
        <div className={popupClasses.popupTitle}>Get an instant payout</div>
        <div style={{ paddingBottom: '16px' }}>
          <LineItem
            description='Balance'
            amount={this.props.balance}
            localisation={this.props.localisation}
          />
          <LineItem
            description='Instant payout fee'
            amount={-1 * this.props.fee}
            localisation={this.props.localisation}
          />
          <hr style={{ marginTop: '7.5px', marginBottom: '7.5px' }} />
          <LineItem
            description='Total payout'
            amount={this.props.balance - this.props.fee}
            localisation={this.props.localisation}
          />
        </div>
        <FlatButton
          label='Confirm'
          type='button'
          onClick={this.onConfirm}
          className='blueBackground'
          style={{ minWidth: '250px' }}
        />
      </div>
    )
  }
}

InstantPayoutPopup.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  balance: PropTypes.number.isRequired,
  fee: PropTypes.number.isRequired,
  localisation: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
}

function LineItem({ description, amount, localisation }) {
  return (
    <div style={{ display: 'flex', paddingTop: '8px', paddingBottom: '8px' }}>
      <div>{description}</div>
      <div style={{ flex: '1', textAlign: 'right' }}>{localisation.formatCurrency(amount)}</div>
    </div>
  )
}

LineItem.propTypes = {
  description: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  localisation: PropTypes.object.isRequired,
}
