import React from 'react'
import PropTypes from 'prop-types'

import DeleteConfirmation from 'components/popups/DeleteConfirmation'
import { getENV } from 'libs/utils'

function InvoiceDelete({ invoice, onDeleted, onClose }) {
  return (
    <DeleteConfirmation
      title='Delete'
      subtitle='Are you sure you want to delete this draft?'
      failureMessageSubtitle='Oops. We were unable to delete this draft invoice. Please try again'
      loadingMessage='Deleting invoice ...'
      successMessageTitle='Invoice Deleted'
      successMessageSubtitle='The invoice has been successfully deleted'
      showing
      url={`${getENV('INVOICES_BASE_URL')}/api/invoices/${invoice.get('id')}/delete`}
      onSuccess={() => onDeleted(invoice)}
      onFailure={onClose}
      onCancel={onClose}
    />
  )
}

InvoiceDelete.propTypes = {
  invoice: PropTypes.object.isRequired,
  onDeleted: PropTypes.func,
  onClose: PropTypes.func,
}

export default InvoiceDelete
