import { push } from 'react-router-redux'
import { callJSONApi } from 'libs/api'
import { firebaseAuthFailed } from 'redux/modules/session'
import * as Sentry from '@sentry/react'
import firebaseHandler from './firebase'

export const authHandler = {}
let failedCount = 0

export function initializeFirebaseAuthHandler(store) {
  authHandler.isLoggedIn = () => {
    return store.getState().session.get('loggedIn')
  }

  authHandler.resetFailedCount = () => {
    Sentry.addBreadcrumb({
      category: 'firebase',
      message: 'AuthHandler: Reset failed count',
      level: 'info',
    })
    failedCount = 0
  }

  authHandler.authenticateFirebase = (reset = false) => {
    Sentry.addBreadcrumb({
      category: 'firebase',
      message: 'AuthHandler: Attempt authenticate firebase',
      level: 'info',
      data: {
        failedCount,
        reset,
        isLoggedIn: authHandler.isLoggedIn(),
      },
    })

    if (reset) {
      authHandler.resetFailedCount()
    }

    const { session } = store.getState()

    if (failedCount < 5) {
      failedCount += 1

      // The API gateway will route this to our auth service
      callJSONApi(
        '/firebase/auth',
        'GET',
        {},
        (response) => {
          Sentry.addBreadcrumb({
            category: 'firebase',
            message: 'AuthHandler: Fetched token with result',
            level: 'info',
            data: {
              status: response.status,
            },
          })
          if (response && response.status === 200) {
            firebaseHandler.token(response.data.token, response.data.authParams)
          } else {
            authHandler.authenticateFirebase()
          }
        },
        (prettyError) => {
          Sentry.addBreadcrumb({
            category: 'firebase',
            message: 'AuthHandler: Error fetching token',
            level: 'warning',
            data: {
              prettyError,
            },
          })
          console.log('Error fetching firebase token: ', prettyError)
          authHandler.authenticateFirebase()
        }
      )
    } else if (session.get('loggedIn')) {
      store.dispatch(firebaseAuthFailed())
    }
  }

  authHandler.noFirebaseUserFound = () => {
    store.dispatch(
      push(
        `/account/logout?message=${'There was an error retrieving your permissions, please contact support@yoco.co.za'}`
      )
    )
  }
}
