import React from 'react'
import PropTypes from 'prop-types'

import classes from './styles.module.scss'

function PopupErrorAlert({ errorMessage }) {
  return (
    <div className={classes.errorMessageContainer}>
      <p className={classes.errorMessage}>{errorMessage}</p>
    </div>
  )
}

PopupErrorAlert.propTypes = {
  errorMessage: PropTypes.string,
}

export default PopupErrorAlert
