/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from '@reduxjs/toolkit'

import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import Spacer from 'ui/layout/Spacer'
import LoadingView from 'components/loaders/LoadingView'
import BlockHeader from 'components/headers/BlockHeader'
import KeyBlock from 'components/keyBlock'
import { track } from 'libs/analytics'
import * as PluginActions from 'redux/modules/plugin/actions'
import FlatButton from 'components/buttons/FlatButton'
import classes from 'containers/online/online.module.scss'
import InfoCardHolder from 'components/infoCards/InfoCard'
import testCard from 'assets/images/online/test_card.png'
import SubHeading from 'ui/components/SubHeading'
import PreventBeforeFullyOnboarded from 'containers/onboarding/PreventBeforeFullyOnboarded'

class PluginPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      copyClick: false,
      hasKeys: props.hasAccount,
    }
  }

  componentDidMount() {
    if (this.state.hasKeys) {
      this.props.actions.getKeys()
    } else {
      this.props.actions.createKeys()
    }
  }

  getKeys(keyArray, isLive) {
    const keys = keyArray.filter((key) => {
      return key.isLive === isLive
    })
    return keys[0]
  }

  copyKey(key, type) {
    const copyText = document.getElementById(`copy-${key}`)
    const textArea = document.createElement('textarea')
    textArea.value = copyText.textContent
    document.body.appendChild(textArea)
    textArea.select()

    try {
      const successful = document.execCommand('Copy')
      if (successful) {
        this.setState({
          copyClick: true,
        })
        this.copied()
        textArea.remove()
        track('keys_copied', { type })
      } else {
        window.prompt(
          `To copy to the clipboard right click and select "copy" or press: Ctrl+C (Cmd + C on a mac)`,
          textArea.value
        )
        textArea.remove()
      }
    } catch (err) {
      window.prompt(
        `To copy to the clipboard right click and select "copy" or press: Ctrl+C (Cmd + C on a mac)`,
        textArea.value
      )
      textArea.remove()
    }
  }

  copied() {
    setTimeout(() => {
      this.setState({
        copyClick: false,
      })
    }, 2000)
  }

  copyText() {
    return this.state.copyClick ? (
      <span className={classes.confirmCopyText}>Copied to clipboard</span>
    ) : (
      <span className={classes.confirmCopyText}>&nbsp;</span>
    )
  }

  copyButton(key, type) {
    return (
      <span onClick={() => this.copyKey(key, type)} className={classes.copy}>
        <i className={`icon2-copy ${classes.icon}`} />
      </span>
    )
  }

  testCardBlock() {
    return (
      <div className={classes.testCardHolder}>
        <div className={classes.testCard}>
          <img alt='test card' width='250' src={testCard} />
        </div>
        <div className={classes.testCardContent}>
          <p>1. Add the test keys to your payment plugin.</p>
          <p>2. Use the test card details to make a test payment.</p>
          <p>
            3. Switch to your
            <strong> Live keys </strong>
            if your test payment was successful.
          </p>
          <p className={classes.supportLinks}>
            Support documents:
            <a
              href='https://developer.yoco.com/online/plugins/wix'
              rel='noopener noreferrer'
              target='_blank'
            >
              &nbsp;Wix&nbsp;
            </a>
            <a
              href='https://developer.yoco.com/online/plugins/woo-commerce'
              rel='noopener noreferrer'
              target='_blank'
            >
              &nbsp;WooCommerce&nbsp;{' '}
            </a>
            <a href='https://developer.yoco.com/' rel='noopener noreferrer' target='_blank'>
              &nbsp;Yoco developer SDK&nbsp;
            </a>
          </p>
        </div>
      </div>
    )
  }

  keyBlock(key, type) {
    if (key) {
      return (
        <div>
          <p>
            <strong>{`${type} PUBLIC KEY:`}</strong>
            <br />
            <KeyBlock id={key.publicKey}>
              {key.publicKey}
              {this.copyButton(key.publicKey, type)}
            </KeyBlock>
          </p>
          <p>
            <strong>{`${type} SECRET KEY:`}</strong>
            <br />
            <KeyBlock id={key.secretKey}>
              {key.secretKey}
              {this.copyButton(key.secretKey, type)}
            </KeyBlock>
          </p>
          <Spacer size='medium' />
        </div>
      )
    }
    return null
  }

  successKeyRequest() {
    const keys = this.props.pluginDetails.data
    const liveKey = this.getKeys(keys, true)
    const testKey = this.getKeys(keys, false)
    return (
      <div>
        <div className={classes.keyHolder}>
          {this.keyBlock(liveKey, 'LIVE')}
          {this.keyBlock(testKey, 'TEST')}
        </div>
        {this.copyText()}
        <Spacer size='large' />
        {this.testCardBlock()}
      </div>
    )
  }

  unsuccessfulKeyRequest() {
    return (
      <p>
        There was an error requesting your keys, please contact{' '}
        <a href='mailto:online@yoco.com'>online@yoco.com</a>
      </p>
    )
  }

  hasNotActivated() {
    this.props.actions.createKeys()
    return (
      <div>
        <p>
          To start processing your online payments, click the button below to receive your access
          keys.
        </p>
        <Spacer size='large' />
        <FlatButton
          label='Activate my gateway'
          className='blueBackground'
          onClick={() => this.props.actions.createKeys()}
        />
      </div>
    )
  }

  render() {
    if (this.props.loaderDisplay) {
      return <LoadingView />
    }

    const { hasAccount } = this.props
    const hasPluginKeys = 'data' in this.props.pluginDetails
    return (
      <MaxWidthBlock className={classes.pageBG}>
        <PreventBeforeFullyOnboarded />
        <BlockHeader title='Payment Gateway' />
        <InfoCardHolder featuredSolutions />
        <SubHeading>Your Keys</SubHeading>
        {this.props.thriveError
          ? this.unsuccessfulKeyRequest()
          : !hasAccount && !hasPluginKeys
          ? this.hasNotActivated()
          : ''}
        {hasAccount && hasPluginKeys ? this.successKeyRequest() : ''}
        <SubHeading>More Solutions</SubHeading>
        <InfoCardHolder moreSolutions />
      </MaxWidthBlock>
    )
  }
}
PluginPage.propTypes = {
  actions: PropTypes.shape({
    createKeys: PropTypes.func,
    getKeys: PropTypes.func,
  }),
  loaderDisplay: PropTypes.bool,
  pluginDetails: PropTypes.object,
  hasAccount: PropTypes.bool,
  thriveError: PropTypes.bool,
}

export default connect(
  (state) => ({
    pluginDetails: state.plugin.pluginDetails,
    loaderDisplay: state.plugin.loaderDisplay,
    hasAccount: state.plugin.hasAccount,
    thriveError: state.plugin.thriveError,
  }),
  (dispatch) => ({
    actions: bindActionCreators({ ...PluginActions }, dispatch),
  })
)(PluginPage)
