import React from 'react'
import PropTypes from 'prop-types'

import classes from './Status.module.scss'
import { getStatusStyles } from './utils'

function InvoiceStatus({ status }) {
  return (
    <div data-testid='invoice-status' className={classes.root} style={getStatusStyles(status)}>
      {status}
    </div>
  )
}

InvoiceStatus.propTypes = {
  status: PropTypes.string.isRequired,
}

export default InvoiceStatus
