/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import moment from 'moment'

import { refreshTable, removeItem } from 'redux/modules/tables'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import BlockHeader from 'components/headers/BlockHeader'
import FlatButton from 'components/buttons/FlatButton'
import ConfirmPopup from 'components/popups/ConfirmPopup'
import { showMessage } from 'redux/modules/notifications'
import { Table, TableRow, EmptyTableView } from 'libs/tables'
import { callJSONApi } from 'libs/api'
import * as forms from 'libs/forms'
import classes from 'containers/store/store.module.scss'
import SplitBlock from 'components/blocks/SplitBlock'
import { mouseDownHandler, mouseUpHandler } from 'libs/utils'
import localisation from 'libs/localisation/localisation'
import Spacer from 'ui/layout/Spacer'

import AddCustomPaymentMethodForm from './AddCustomPaymentMethodForm'

const TABLE_NAME = 'customPaymentMethods'

class SettingsPage extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  onSuccess() {
    this.props.dispatch(refreshTable(TABLE_NAME))
  }

  getTableHeader() {
    return (
      <tr className='visible-header'>
        <th>Name</th>
        <th>Icon</th>
        <th>Created</th>
      </tr>
    )
  }

  showConfirmation() {
    this.setState((prevState) => ({
      showDeleteModal: true,
      deleteRow: prevState.shownRow,
    }))
  }

  hideConfirmation() {
    this.setState({
      showDeleteModal: false,
    })
  }

  deleteMethod(rowData) {
    callJSONApi(
      `/customPaymentMethods/${rowData.get('uuid')}/`,
      'DELETE',
      {},
      (result) => {
        if (result.status === 200) {
          this.props.dispatch(removeItem(TABLE_NAME, rowData.get('uuid')))
          this.splitViewClosed()
        }
      },
      (prettyError) => {
        this.props.dispatch(showMessage(`Error removing method: ${prettyError}`, 'danger'))
      }
    )
    this.hideConfirmation()
  }

  showRow(rowData) {
    this.setState((currentState) => {
      const newState = { ...currentState }

      if (newState.shownRow && newState.shownRow.get('uuid') === rowData.get('uuid')) {
        newState.shownRow = null
      } else {
        newState.shownRow = rowData
      }

      if (newState.shownRow) {
        newState.showingSplit = true
      } else {
        newState.showingSplit = false
      }

      return newState
    })
  }

  addCustomPaymentItem() {
    this.setState({
      showingSplit: true,
    })
  }

  mouseDownHandler() {
    mouseDownHandler()
  }

  mouseUpHandler() {
    mouseUpHandler()
  }

  splitViewClosed() {
    this.setState({
      shownRow: null,
      showingSplit: false,
    })
  }

  rowClicked(rowData) {
    this.showRow(rowData)
  }

  renderAddCustomPaymentMethodForm() {
    return (
      <AddCustomPaymentMethodForm
        formName={TABLE_NAME}
        shownRow={this.state.shownRow}
        closeSplitView={() => this.splitViewClosed()}
      />
    )
  }

  renderConfirmDeletePopup() {
    return (
      <ConfirmPopup
        showing={this.state.showDeleteModal}
        // eslint-disable-next-line react/jsx-no-bind
        onCancel={this.hideConfirmation.bind(this)}
        icon='icon-delete-garbage-streamline'
        title='Remove'
        iconType={classes.deleteIconType}
        actionType='destructive'
        subtitle='Are you sure you want to remove this payment method?'
        onConfirm={() => this.deleteMethod(this.state.deleteRow)}
      />
    )
  }

  renderTableRow(rowData, rowIndex) {
    return (
      <TableRow
        key={`row-${rowIndex}`}
        rowIndex={rowIndex}
        onClick={() => this.rowClicked(rowData)}
        selected={this.state.shownRow && this.state.shownRow.get('uuid') === rowData.get('uuid')}
      >
        <td>{rowData.get('name')}</td>
        <td>
          <i
            className={`icon2-${rowData.getIn(['config', 'icon'], 'credit-card')} ${
              classes.paymentIcons
            }`}
          />
        </td>
        <td>{moment(rowData.get('created')).format('D MMMM YYYY')}</td>
      </TableRow>
    )
  }

  renderCustomPaymentsTable() {
    // NOTE: Only 4 Custom Payment Methods allowed.
    // Will likely persist this limit elsewhere in the fufure.
    const disableAddButton = this.props.customPaymentMethods.size > 3
    const addPaymentMethodLabel = disableAddButton ? 'Only 4 methods allowed' : 'Add payment method'

    return (
      <forms.FormBlock
        heading='Custom Payment Methods'
        actions={
          <FlatButton
            label={addPaymentMethodLabel}
            className='secondary'
            disabled={disableAddButton}
            onClick={() => !disableAddButton && this.addCustomPaymentItem()}
          />
        }
      >
        <Table
          api='/customPaymentMethods/'
          waitForFilterLoad={false}
          showPaging={false}
          name={TABLE_NAME}
          sortPath='name'
          getHeader={() => this.getTableHeader()}
          getRow={(rowData, index) => this.renderTableRow(rowData, index)}
          emptyTable={
            <EmptyTableView title='Your business has no custom payment methods'>
              <p>
                Track all the different ways you allow your customers to pay. Cash and Card are
                always available
              </p>
              <p>
                <FlatButton
                  label={addPaymentMethodLabel}
                  className='blueBackground'
                  disabled={disableAddButton}
                  onClick={() => !disableAddButton && this.addCustomPaymentItem()}
                />
              </p>
            </EmptyTableView>
          }
        />
      </forms.FormBlock>
    )
  }

  renderTipsForm() {
    const maybeAcceptsCardPayments = this.props.localisation.get('acceptsCardPayments', false)
    return (
      <forms.Form
        className='form half-width'
        action='/business/businessConfig'
        fetchAPI='/business/businessConfig'
        name='settings'
        persistData
        onSuccess={() => this.props.dispatch(showMessage('Tip configuration updated.'))}
      >
        <forms.FormBlock
          heading='Tips'
          infoText={`
            When using the Yoco POS application, this will control how you are able to accept tips from customers
          `}
        >
          <forms.DescriptionCheckboxField
            isFullWidth
            isVisible={maybeAcceptsCardPayments}
            name='inAppTipEntry'
            label='Ask for amount tendered (tips) on card payments'
            description={`
              When you process a card payment, we will ask how much you want to charge to that card. This is the easiest way to accept tips on card transactions.'
            `}
          />
          <Spacer size='small' />
          <forms.DescriptionCheckboxField
            isFullWidth
            name='showAddTipButton'
            label='Show an add tip button above the bill'
            description={`
              This allows you to manually add a percent or amount tip to a bill before starting payments.'
            `}
          />
          <Spacer size='small' />
          <forms.DescriptionCheckboxField
            isFullWidth
            name='onReaderTipEntry'
            label='Ask for tip on card machine'
            description={`
              This will ask your customers if they would like to add a tip 
              to their purchase before inserting their card.
            `}
            isVisible={maybeAcceptsCardPayments}
          />
        </forms.FormBlock>
        <FlatButton label='Save' type='submit' className='secondary' />
      </forms.Form>
    )
  }

  renderStartOfDayForm() {
    const options = [
      { label: '12am to 12am', value: 0 },
      { label: '5am to 5am', value: 5 },
    ]

    const example =
      this.props.startOfBusinessDay === 5
        ? `If you transact after midnight, this will allow you to see the transactions you made after midnight as part of the previous business day. ' +
          'e.g. Transaction at 1:04am tonight will show on reports for today.`
        : `Default setting for reporting on calendar day. e.g. Transaction at 1:04am tonight will show on reports for tomorrow.`
    return (
      <forms.Form
        className='form half-width'
        action='/business/businessConfig'
        fetchAPI='/business/businessConfig'
        name='businessStartOfDay'
        persistData
        onSuccess={() =>
          this.props.dispatch(showMessage('Business start of day configuration updated.'))
        }
      >
        <forms.FormBlock
          heading='Trading Hours for Reporting'
          infoText={`
            Customising your trading hours will allow for clearer reporting. 
            You can track what sales were made in one continuous shift.
          `}
        >
          <forms.SelectField
            isFullWidth
            name='startOfBusinessDay'
            label='Select reporting trading hours'
            options={options}
            defaultValue={this.props.startOfBusinessDay}
          />
          <div className='clearfix'>{example}</div>
        </forms.FormBlock>
        <FlatButton label='Save' type='submit' className='secondary' />
      </forms.Form>
    )
  }

  render() {
    return (
      <SplitBlock
        formName={TABLE_NAME}
        showDelete={!!this.state.shownRow}
        showingSplit={this.state.showingSplit}
        header={
          this.state.shownRow ? `${this.state.shownRow.get('name')}` : 'Add Custom Payment Method'
        }
        saveLabel={this.state.shownRow ? 'Save' : 'Add'}
        renderSplitContent={() => this.renderAddCustomPaymentMethodForm()}
        deletePressed={() => this.showConfirmation()}
        onDismiss={() => this.splitViewClosed()}
      >
        <MaxWidthBlock>
          <BlockHeader title='Settings' />
          {this.renderConfirmDeletePopup()}
          {this.renderTipsForm()}
          {this.renderStartOfDayForm()}
          {this.renderCustomPaymentsTable()}
        </MaxWidthBlock>
      </SplitBlock>
    )
  }
}

SettingsPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  customPaymentMethods: PropTypes.object,
  startOfBusinessDay: PropTypes.number,
  localisation: PropTypes.objectOf(Map),
}

export default connect((state) => ({
  customPaymentMethods: state.tables.getIn([TABLE_NAME, 'data'], Map()),
  localisation: localisation(state),
  startOfBusinessDay: state.forms.getIn(['businessStartOfDay', 'data', 'startOfBusinessDay'], 0),
}))(SettingsPage)
