import React from 'react'
import propTypes from 'prop-types'

import { makeTestID } from 'libs/utils'
import { TableRow } from 'libs/tables'

export function CustomersTableHeader() {
  return (
    <tr className='visible-header'>
      <th>Name</th>
      <th>Company</th>
      <th>Email</th>
      <th>Phone</th>
    </tr>
  )
}

export function CustomersTableRow({ index, onClick, row }) {
  const rowAriaLabel = makeTestID('customer', 'table', 'row', `${index}`)
  return (
    <TableRow index={index} onClick={() => onClick(row)} ariaLabel={rowAriaLabel}>
      <td aria-label={`${rowAriaLabel}-fullName`}>{row.get('fullName') || '-'}</td>
      <td aria-label={`${rowAriaLabel}-company`}>{row.get('company') || '-'}</td>
      <td aria-label={`${rowAriaLabel}-email`}>{row.get('email') || '-'}</td>
      <td aria-label={`${rowAriaLabel}-phone`}>{row.get('phone') || '-'}</td>
    </TableRow>
  )
}

CustomersTableRow.propTypes = {
  row: propTypes.any,
  index: propTypes.number,
  onClick: propTypes.func,
}
