/* eslint-disable max-classes-per-file */
import { Filter } from 'libs/filters'
import { makeTestID } from 'libs/utils'

export class TransactionTypeFilter extends Filter {}

TransactionTypeFilter.defaultProps = {
  emptySelectedString: 'All Transactions',
  filterKey: 'transactionType',
  defaults: [],
  filterIcon: 'credit-card',
  filterColumns: 2,
  loadAvailableOptions: () => {
    return {
      credit_card: {
        icon: 'credit-card',
        title: 'Card',
        subtext: 'Card transactions including refunds',
        selected: false,
        isForCardAcceptingLocalisation: true,
        testID: makeTestID('filters', 'saleType', 'card'),
      },
      cash: {
        icon: 'cashnotes',
        title: 'Cash',
        subtext: 'Cash transactions',
        selected: false,
        testID: makeTestID('filters', 'saleType', 'cash'),
      },
      refund: {
        icon: 'refund',
        title: 'Refund',
        subtext: 'Refund transactions',
        selected: false,
        testID: makeTestID('filters', 'saleType', 'refund'),
      },
      free: {
        icon: 'gift',
        title: 'Free',
        subtext: 'Free transactions',
        selected: false,
        testID: makeTestID('filters', 'saleType', 'freeTransaction'),
      },
    }
  },
}

export class TransactionStateFilter extends Filter {}

TransactionStateFilter.defaultProps = {
  emptySelectedString: 'All States',
  filterKey: 'transactionState',
  defaults: [],
  filterIcon: 'credit-card-lock',
  loadAvailableOptions: () => {
    return {
      approved: {
        icon: 'payment-approved',
        title: 'Approved',
        subtext: 'Approved transactions',
        selected: false,
        testID: makeTestID('filters', 'saleState', 'approved'),
      },
      declined: {
        icon: 'payment-declined',
        title: 'Declined',
        subtext: 'Declined transactions',
        selected: false,
        testID: makeTestID('filters', 'saleState', 'declined'),
      },
      aborted: {
        icon: 'payment-aborted',
        title: 'Aborted',
        subtext: 'Aborted transactions',
        selected: false,
        testID: makeTestID('filters', 'saleState', 'aborted'),
      },
      error: {
        icon: 'payment-error',
        title: 'Error',
        subtext: 'Error transactions',
        selected: false,
        testID: makeTestID('filters', 'saleState', 'error'),
      },
    }
  },
}
