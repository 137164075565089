import React from 'react'
import PropTypes from 'prop-types'

import SubHeading from 'ui/components/SubHeading'
import { If } from 'libs/formats'

import classes from './forms.module.scss'

export function Heading({ heading }) {
  return <SubHeading>{heading}</SubHeading>
}

Heading.propTypes = {
  heading: PropTypes.string,
}

export function InfoText({ text }) {
  return <p className={classes.infoText}>{text}</p>
}

InfoText.propTypes = {
  text: PropTypes.string,
}

export function FormBlock({ heading, actions, infoText, children }) {
  return (
    <div className={classes.formBlock}>
      <div className={classes.formBlockHeading}>
        <div className={classes.formBlockHeadingTitles}>
          {heading ? <SubHeading>{heading}</SubHeading> : null}
          {infoText ? <InfoText text={infoText} /> : null}
        </div>
        <If condition={!!actions}>
          <div className={classes.formBlockActions}>{actions}</div>
        </If>
      </div>
      <div className='clearfix'>{children}</div>
    </div>
  )
}

FormBlock.propTypes = {
  heading: PropTypes.string,
  infoText: PropTypes.string,
  actions: PropTypes.node,
  children: PropTypes.node,
}
