/* eslint-disable jsx-a11y/no-static-element-interactions */
/**
 * Created by mandisa on 2017/05/25.
 */
import React from 'react'
import PropTypes from 'prop-types'

import DismissComponent from 'libs/DismissComponent'
import VStack from 'ui/layout/VStack'

import FlatButton from './FlatButton'
import classes from './buttons.module.scss'

export default class DropDownButton extends DismissComponent {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
    }
  }

  toggleModalShown() {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
    }))
  }

  isToggled() {
    return this.state.showModal
  }

  onDismiss() {
    this.setState({
      showModal: false,
    })
  }

  renderListModal() {
    if (this.state.showModal && this.props.actions) {
      const { ButtonComponent } = this.props

      return (
        <div
          className={classes.dropdownModal}
          onMouseDown={() => this.mouseDownHandler()}
          onMouseUp={() => this.mouseUpHandler()}
        >
          <VStack spacing='xs'>
            {this.props.actions.map((action) => {
              return (
                <ButtonComponent
                  key={action.label}
                  onClick={() => {
                    this.toggleModalShown()
                    action.onClick()
                  }}
                  {...action.buttonProps}
                  data-testid={this.props.testID}
                >
                  {action.label}
                </ButtonComponent>
              )
            })}
          </VStack>
        </div>
      )
    }
    return undefined
  }

  render() {
    return (
      <div style={{ position: 'relative' }}>
        <button
          type='button'
          className={`${classes.flatButton} ${this.props.buttonClass}`}
          onClick={() => {
            this.toggleModalShown()
            this.props.onClick?.()
          }}
          style={this.props.style}
          data-testid={this.props.testID}
        >
          {this.props.label}
        </button>
        {this.renderListModal()}
      </div>
    )
  }
}

DropDownButton.defaultProps = {
  ButtonComponent: FlatButton,
}

DropDownButton.propTypes = {
  icon: PropTypes.string,
  style: PropTypes.object,
  label: PropTypes.node,
  actions: PropTypes.array,
  ButtonComponent: PropTypes.elementType,
  buttonClass: PropTypes.string,
}
