/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { Icon } from '@yoco/design-system-icons/dist/react'

import { CheckBoxField } from './switch'
import classes from './forms.module.scss'

export default class DescriptionCheckboxField extends CheckBoxField {
  renderDescription() {
    if (this.state.opened) {
      // now return the extended
      return <div className={`${classes.checkBoxDescriptionText}`}>{this.props.description}</div>
    }
    return undefined
  }

  onExpandClick() {
    this.setState((prevState) => ({
      opened: !prevState.opened,
    }))
  }

  render() {
    if (!this.props.isVisible) {
      return null
    }
    return (
      <div className={classes.expandableCheckbox} onClick={() => this.onExpandClick()}>
        <div className={classes.expandableCheckboxRow}>
          {this.state.opened ? (
            <Icon name='chevron-up' size={24} />
          ) : (
            <Icon name='chevron-down' size={24} />
          )}
          <div className={`${classes.expandableCheckboxDescription}`}>{this.props.label}</div>
          {this.renderInput()}
        </div>

        {this.renderDescription()}
        <div className='help'>{this.renderHelpText()}</div>
      </div>
    )
  }
}

DescriptionCheckboxField.defaultProps = {
  isVisible: true,
}
