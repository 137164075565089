import React from 'react'
import PropTypes from 'prop-types'

import classes from './forms.module.scss'

export default function Label({ children }) {
  return <div className={classes.fieldLabel}>{children}</div>
}

Label.propTypes = {
  children: PropTypes.element,
}
