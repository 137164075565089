/**
 * Created by mandisa on 2016/10/20.
 */
import Immutable, { Map, List } from 'immutable'

/*
 Take a matrix of variant choices like
 [["S", "M", "L"], ["White", "Black", "Red"]]

 and convert into an array like
 ["S-White", "S-Black", "S-Red", "M-White", "M-Black", "M-Red", "L-White", "L-Black", "L-Red"]
 */
export function cartesianProduct(arr) {
  if (!arr || arr.length === 0) {
    return []
  }

  return arr.reduce(
    function (a, b) {
      return a
        .map(function (x) {
          return b.map(function (y) {
            return x.concat(y)
          })
        })
        .reduce(function (x, y) {
          return x.concat(y)
        }, [])
    },
    [[]]
  )
}

/*
 Return a list of variant identifiers from our set of variant group choices.
 This should be used instead of iterating over the variants option so that we
 preserve the correct ordering.
 */
export function variantsFromVariantGroups(variantGroups) {
  return Immutable.fromJS(
    cartesianProduct(
      (variantGroups || List())
        .filter((variantGroup) => (variantGroup || Map()).get('choiceOptions', List()).count() > 0)
        .map((variantGroup) => variantGroup.get('choiceOptions', List()))
        .toJS()
    )
  )
}
