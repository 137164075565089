import React from 'react'
import classnames from 'classnames'

import typography from 'ui/typography/typography.module.scss'
import layout from 'ui/layout/layout.module.scss'

interface Props {
  children: string
  className?: string
}
const CardTitle: React.FunctionComponent<Props> = ({ children, className }) => {
  return (
    <div className={classnames(layout.block, layout.pb1, typography.h3, className)}>{children}</div>
  )
}

export default CardTitle
