import React from 'react'

import FlatButton from 'components/buttons/FlatButton'
import blueTick from 'assets/images/blue-success@2x.png'
import styles from './CapitalLetMeKnowBlock.module.scss'
import * as utils from './utils'

interface Props {
  optedIn: boolean
  onOptInPress: () => void
  fetchingData: boolean
}

const CapitalLetMeKnowBlock: React.FunctionComponent<Props> = ({
  optedIn,
  onOptInPress,
  fetchingData,
}) => {
  if (optedIn) {
    return (
      <div className={styles.container} data-testid='CapitalLetMeKnowBlock'>
        <p className={styles.title}>
          We will notify you as soon as an offer becomes available to you.
        </p>
        <img src={blueTick} alt='blueTick' />
      </div>
    )
  }
  return (
    <div className={styles.container} data-testid='CapitalLetMeKnowBlock'>
      <p className={styles.title}>
        <b>Unfortunately you do not qualify for a Yoco Capital cash advance at the moment.</b>
        <br />
        Please click below to be notified when an offer is available.
      </p>
      <FlatButton
        label={utils.determineButtonText(fetchingData)}
        onClick={onOptInPress}
        type='submit'
        className='primary'
      />
    </div>
  )
}

export default CapitalLetMeKnowBlock
