import React from 'react'
import Helmet from 'react-helmet'

import { getENV, isFlagship } from 'libs/utils'

const head = () => {
  return (
    <Helmet>
      <script key='segment' type='text/javascript'>{`
        !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${getENV(
          'SEGMENT_KEY'
        )}";;
        analytics.SNIPPET_VERSION="4.15.3";
        analytics.load("${getENV('SEGMENT_KEY')}");
        analytics.page();
        }}();
      `}</script>
      <link
        key='nvd3-style'
        rel='stylesheet'
        type='text/css'
        href='https://cdnjs.cloudflare.com/ajax/libs/nvd3/1.8.4/nv.d3.css'
      />

      {/* do not load zendeskChat when flavour === 'flagship'
       (used for the Khumo); otherwise, show it */}
      {!isFlagship ? (
        <script type='text/javascript'>
          {`
              window.zESettings = {
                webWidget: {
                  color: {
                    theme: '#222222'
                  }
                }
              }
            `}
        </script>
      ) : null}
      {!isFlagship ? (
        <script
          key='zopimFilter'
          type='text/javascript'
          src='/dependencies/zopimDepartmentsFilter.js'
          async
        />
      ) : null}

      <meta key='noreferrer' name='referrer' content='no-referrer' />
    </Helmet>
  )
}
export default head
