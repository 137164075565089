/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import PropTypes from 'prop-types'

import { track } from 'libs/analytics'
import store from 'redux/store'
import { submitForm } from 'redux/modules/forms'
import SplitBlock from 'components/blocks/SplitBlock'
import SplitHeader from 'components/headers/SplitHeader'
import FlatButton from 'components/buttons/FlatButton'
import { makeTestID } from 'libs/utils'

import { FormName, FormSubmissionFlags, InvoiceCreateForm } from './Form'

function InvoiceCreate({ onCreated, onClose, children }) {
  return (
    <SplitBlock
      showingSplit
      formName={FormName}
      saveLabel='Next'
      headerRenderer={() => (
        <SplitHeader
          title='New Invoice'
          actions={[
            <FlatButton
              key='save-as-draft'
              label='Save as Draft'
              className='secondary'
              onClick={() => {
                track('invoices_new_invoice_save_as_draft_clicked', {
                  name: 'invoices_new_invoice_save_as_draft_clicked',
                })

                // That's how form onSubmit handler will know
                // whether user click-ed "Save as Draft" or "Next" button,
                // @see ./Form/Create.js Line: 34
                FormSubmissionFlags.saveAsDraft = true
                store.dispatch(submitForm(FormName))
              }}
              testID={makeTestID('invoices', 'newInvoice', 'save-as-draft')}
            />,
          ]}
          closeSplitView={onClose}
        />
      )}
      renderSplitContent={() => <InvoiceCreateForm onCreated={onCreated} />}
      onDismiss={onClose}
    >
      {children}
    </SplitBlock>
  )
}

InvoiceCreate.propTypes = {
  onCreated: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.node,
}

export default InvoiceCreate
