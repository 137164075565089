import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import { sortByColumn } from 'redux/modules/tables'

import classes from './tables.module.scss'

class SortableTableHeader extends Component {
  getRowClass() {
    let className = `${classes.sortable} unselectable`
    if (this.props.className) {
      className = `${className} ${this.props.className}`
    }

    return className
  }

  getArrowClass() {
    let arrowClass = classes.inactive
    if (
      this.props.sortedColumns.getIn([this.props.tableName, 'sortedColumn']) === this.props.column
    ) {
      arrowClass = classes.active
    }
    if (this.props.sortedColumns.getIn([this.props.tableName, this.props.column])) {
      arrowClass += ' icon-arrow-57'
    } else {
      arrowClass += ' icon-arrow-58'
    }
    return arrowClass
  }

  render() {
    return (
      <th
        className={this.getRowClass()}
        onClick={() => {
          this.props.dispatch(sortByColumn(this.props.tableName, this.props.column))
        }}
      >
        {this.props.children}
        <i className={this.getArrowClass()} />
      </th>
    )
  }
}

SortableTableHeader.propTypes = {
  dispatch: PropTypes.func.isRequired,
  sortedColumns: PropTypes.object.isRequired,
  className: PropTypes.string,
  tableName: PropTypes.string,
  column: PropTypes.string,
  children: PropTypes.element,
}

export default connect((state) => ({
  sortedColumns: state.tables.get('sortedColumns', Map()),
}))(SortableTableHeader)
