/* eslint-disable radix */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import SplitHeader from 'components/headers/SplitHeader'
import SplitBlock from 'components/blocks/SplitBlock'
import { List, ListItem } from 'libs/list'
import { formatCurrency } from 'libs/utils'
import { If } from 'libs/formats'
import ItemIcon from 'components/items/ItemIcon'
import FormCard from 'components/cards/FormCard'
import { InvoiceStatus, calculateInvoiceEntryTotals } from 'containers/invoices/components/Invoices'

import InvoiceMarkAsPaid from './MarkAsPaid'
import InvoiceCopyLink from './CopyLink'
import classes from './Details.module.scss'

function InvoiceDetails({ invoice, onViewPdfPressed, onMarkedAsPaid, children, onClose }) {
  return (
    <SplitBlock
      showingSplit
      headerRenderer={() => (
        <SplitHeader
          title='Invoice Details'
          actions={
            invoice.get('status') === 'unpaid' && (
              <InvoiceMarkAsPaid invoice={invoice} onMarkedAsPaid={onMarkedAsPaid} />
            )
          }
          closeSplitView={onClose}
        />
      )}
      saveLabel='View PDF'
      savePressed={onViewPdfPressed}
      cancelLabel='Back'
      renderSplitContent={() => (
        <>
          <FormCard style={{ padding: 0 }}>
            <List>
              <ListItem
                style={{ 'text-transform': 'capitalize', color: 'green' }}
                title={invoice.getIn(['customerData', 'fullName'])}
                subTitle={`Invoice #${invoice.get('invoiceNumber')}`}
                description={
                  <InvoiceStatus
                    status={invoice.get('overdue') ? 'overdue' : invoice.get('status')}
                  />
                }
              />
              {(invoice.get('billEntries') || []).map((billEntry, key) => {
                const { subTotal } = calculateInvoiceEntryTotals(billEntry)

                return (
                  <ListItem
                    key={key}
                    accessoryLeft={<ItemIcon tile={billEntry.get('tile')} />}
                    title={billEntry.getIn(['tile', 'name'])}
                    subTitle={`${billEntry.get('quantity')} x ${formatCurrency(
                      billEntry.get('price'),
                      'ZAR'
                    )}`}
                    description={formatCurrency(subTotal, 'ZAR')}
                  />
                )
              })}
              <ListItem
                className={classes.oneLineItem}
                title='Subtotal'
                description={formatCurrency(invoice.get('invoiceSubtotal'), 'ZAR')}
              />
              <If condition={parseInt(invoice.get('invoiceDiscount')) > 0}>
                <ListItem
                  className={classes.oneLineItem}
                  title='Discount'
                  description={`- ${formatCurrency(invoice.get('invoiceDiscount'), 'ZAR')}`}
                />
              </If>
              <ListItem
                className={classes.oneLineItem}
                title='VAT 15%'
                description={formatCurrency(invoice.get('invoiceTax'), 'ZAR')}
              />
              <ListItem
                className={classes.oneLineItem}
                title='Total (Incl. Tax)'
                description={<strong>{formatCurrency(invoice.get('invoiceTotal'), 'ZAR')}</strong>}
              />
              <ListItem
                className={classes.oneLineItem}
                title='Due date'
                description={moment(invoice.get('dueDate')).format('DD MMMM YYYY')}
              />
              <If condition={invoice.get('status') === 'paid'}>
                <ListItem
                  className={classes.oneLineItem}
                  style={{ 'text-transform': 'capitalize' }}
                  title='Payment Method'
                  description={invoice.get('paymentMethod')}
                />
              </If>
            </List>
          </FormCard>
          <If condition={invoice.get('status') === 'unpaid'}>
            <InvoiceCopyLink url={invoice.get('invoicePayUrl')} />
          </If>
        </>
      )}
      onDismiss={onClose}
    >
      {children}
    </SplitBlock>
  )
}

InvoiceDetails.propTypes = {
  invoice: PropTypes.object.isRequired,
  onViewPdfPressed: PropTypes.func,
  onMarkedAsPaid: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.node,
}

export default InvoiceDetails
