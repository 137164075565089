import React from 'react'

import { List, ListItem } from 'libs/list'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import BlockHeader from 'components/headers/BlockHeader'
import FormCard from 'components/cards/FormCard'

function ListPage() {
  return (
    <MaxWidthBlock>
      <BlockHeader title='List' />

      <div style={{ maxWidth: 480 }}>
        <div>
          <pre>hasOutlineBorders=false</pre>
        </div>
        <FormCard style={{ padding: 0 }}>
          <List>
            <ListItem title='Title' />
            <ListItem title='Title' subTitle='Sub title' />
            <ListItem title='Title' subTitle='Sub title' description='Description' />
            <ListItem
              title='Title'
              subTitle='Sub title'
              description='Description'
              subDescription='Sub description'
            />
            <ListItem title='Title' description='Description' subDescription='Sub description' />
            <ListItem
              title='Title'
              subTitle='Sub title'
              description='Description'
              subDescription='Sub description'
              accessoryLeft='qr-code'
              accessoryRight='close'
            />
            <ListItem
              title='Title'
              subTitle='Sub title'
              description='Description'
              subDescription='Sub description'
              accessoryLeft='user'
              accessoryRight='elipses'
            />
          </List>
        </FormCard>

        <div style={{ marginTop: 32 }}>
          <pre>hasOutlineBorders=true</pre>
        </div>
        <FormCard style={{ padding: 32 }}>
          <List hasOutlineBorders>
            <ListItem title='Title' />
            <ListItem title='Title' subTitle='Sub title' />
            <ListItem title='Title' subTitle='Sub title' description='Description' />
            <ListItem
              title='Title'
              subTitle='Sub title'
              description='Description'
              subDescription='Sub description'
            />
            <ListItem title='Title' description='Description' subDescription='Sub description' />
            <ListItem
              title='Title'
              subTitle='Sub title'
              description='Description'
              subDescription='Sub description'
              accessoryLeft='qr-code'
              accessoryRight='close'
            />
            <ListItem
              title='Title'
              subTitle='Sub title'
              description='Description'
              subDescription='Sub description'
              accessoryLeft='user'
              accessoryRight='elipses'
            />
          </List>
        </FormCard>
      </div>
    </MaxWidthBlock>
  )
}

export default ListPage
