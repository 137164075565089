import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@yoco/design-system-icons/dist/react'

import FlatButton from 'components/buttons/FlatButton'

import { FormField } from './base'
import classes from './forms.module.scss'

export default class SelectButtonField extends FormField {
  onClick() {
    if (this.props.onDelete && this.props.valueLookup && this.props.valueLookup()) {
      this.props.onDelete()
    } else if (this.props.onClick) {
      this.props.onClick()
    }
  }

  renderButtonLabel() {
    const { value } = this.props
    if (value !== undefined && value !== null) {
      if (this.props.valueLookup && this.props.valueLookup()) {
        return <span>{this.props.valueLookup()}</span>
      }
    }
    return <span className={classes.placeholder}>{this.props.placeholder}</span>
  }

  renderInput() {
    const icon =
      this.props.valueLookup && this.props.valueLookup() ? (
        <div className={classes.clearButton}>Clear</div>
      ) : (
        <Icon name='chevron-right' size={24} />
      )

    return (
      <div className={classes.inputWrapper}>
        <div className={`${classes.input} ${classes.selectButtonField}`}>
          <FlatButton
            className={`${classes.buttonField} selectButton`}
            onClick={() => this.onClick()}
            type='button'
          >
            {this.renderButtonLabel()}
            <span className={classes.buttonIcon}>{icon}</span>
          </FlatButton>
        </div>
      </div>
    )
  }
}

SelectButtonField.defaultProps = {
  placeholder: 'Select',
}

SelectButtonField.propTypes = {
  placeholder: PropTypes.string,
  // If our value is a UUID, accept a function to look up a pretty name for it
  valueLookup: PropTypes.func,
  onClick: PropTypes.func,
}
