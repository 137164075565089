/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { Icon } from '@yoco/design-system-icons/dist/react'

import { PopupComponent } from 'libs/modals'
import classes from 'components/popups/popups.module.scss'

export default class ViewVideoPopup extends PopupComponent {
  onClose() {
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  popoverStyles() {
    if (window && window.innerWidth <= 1019) {
      return {
        height: '330px',
        color: 'white',
        'margin-top': '40%',
        padding: 0,
      }
    }
    return {
      color: 'white',
      width: '40%',
      height: '330px',
      padding: 0,
    }
  }

  getContent() {
    const escButtonClass = `${classes.escButton} ${classes.modifiedButton}`

    return (
      <div data-testid='ViewVideoPopup'>
        <div className={escButtonClass} onClick={this.onClose.bind(this)}>
          <Icon name='cross' size={24} />
        </div>
        <iframe
          width='100%'
          height='330'
          src='https://www.youtube.com/embed/KCHquVue2wI?rel=0&amp;showinfo=0'
          frameBorder='0'
          allowFullScreen
          title='Find out more'
        />
      </div>
    )
  }
}
