import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import './index.css'
import ldRedux from '@svey/ld-redux'
import store from 'redux/store'
import Head from 'containers/Head'
import GenericErrorPage from 'containers/errors/GenericErrorPage'
import { getENV } from 'libs/utils'
import Routes from './routes'
import reportWebVitals from './reportWebVitals'

Sentry.init({
  dsn: getENV('SENTRY_DSN'),
  integrations: [new BrowserTracing()],
  environment: getENV('SENTRY_ENVIRONMENT'),
  tracesSampleRate: 0.2,
  normalizeDepth: 10,
  release: getENV('SENTRY_ENVIRONMENT') === 'production' ? getENV('CI_COMMIT_SHA') : undefined,
})

ldRedux.init({
  clientSideId: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  dispatch: store.dispatch,
  context: {
    kind: 'user',
    key: 'Unauthenticated-User',
  },
})

const App = () => {
  // To add clickjacking protection on the client side
  // we check if the current window object is the top one
  if (window.self !== window.top) {
    return <GenericErrorPage hideDetails />
  }

  return (
    <Sentry.ErrorBoundary fallback={<GenericErrorPage />}>
      <Head />
      <Provider store={store}>
        <Routes store={store} />
      </Provider>
    </Sentry.ErrorBoundary>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))

console.log(`Starting app ${process.env.REACT_APP_APP_VERSION}`)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
