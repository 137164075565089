/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@yoco/design-system-icons/dist/react'

import FlatButton from 'components/buttons/FlatButton'
import * as forms from 'libs/forms'
import PopupComponent from 'libs/modals/popups'
import classes from 'libs/modals/popups.module.scss'

export default class AdvancedOptionsPopup extends PopupComponent {
  onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  getContent() {
    return (
      <forms.Form name={this.props.formName}>
        <div className={classes.popupTitle}>
          Advanced Options
          <div
            className={`${classes.escButton} ${classes.modifiedButton}`}
            onClick={() => this.onCancel()}
          >
            <Icon name='close' size={24} />
          </div>
        </div>
        <div className={`${classes.updateMethod} clearfix`}>
          <forms.SwitchField
            name='advancedOptions.askForPrice'
            label='Ask for price when selling product'
            isFullWidth
          />
          <forms.SwitchField
            name='advancedOptions.askForQuantity'
            label='Ask for quantity when selling product'
            isFullWidth
          />
        </div>
        <div className='clearfix'>
          <forms.TextField
            name='advancedOptions.defaultQuantity'
            label='Default quantity'
            placeholder='0'
            isFullWidth
          />
          <forms.TextField
            name='advancedOptions.quantityUnits'
            label='Quantity Units'
            placeholder='e.g. kgs, l, rooms'
            isFullWidth
          />
        </div>
        <div className='clearfix'>
          <div className={classes.actions}>
            <FlatButton
              label='Done'
              type='button'
              onClick={() => this.onCancel()}
              className='blueBackground'
              style={{ minWidth: '250px' }}
            />
          </div>
        </div>
      </forms.Form>
    )
  }
}

AdvancedOptionsPopup.propTypes = {
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
  formName: PropTypes.string,
}
