import { prettyPrintNumber } from 'libs/utils'
import React from 'react'
import CapitalInformationDetailsRow from '../CapitalInformationDetailsRow'
import styles from './CapitalDetailsBlock.module.scss'

interface Props {
  estimatedTerm: number
  repaymentPercentage: number
  processingFee: number
  totalPayable: number
  outstandingBalance?: number
}

const CapitalDetailsBlock: React.FunctionComponent<Props> = ({
  estimatedTerm,
  repaymentPercentage,
  outstandingBalance,
  processingFee,
  totalPayable,
}) => {
  return (
    <div className={styles.container}>
      {outstandingBalance ? (
        <CapitalInformationDetailsRow
          title='Discounted balance outstanding:'
          titleDisplay={`R ${prettyPrintNumber(outstandingBalance)}`}
        />
      ) : null}
      <CapitalInformationDetailsRow
        title='Estimated repayment period:'
        titleDisplay={`${estimatedTerm} months`}
      />
      <CapitalInformationDetailsRow
        title='Repayment percentage (Holdback):'
        titleDisplay={`${repaymentPercentage}% of daily payout`}
      />
      {processingFee ? (
        <CapitalInformationDetailsRow
          title='Processing fee:'
          titleDisplay={`R ${prettyPrintNumber(processingFee)}`}
        />
      ) : null}
      <CapitalInformationDetailsRow
        title='Total Payable:'
        titleDisplay={`R ${prettyPrintNumber(totalPayable)}`}
      />
    </div>
  )
}

export default CapitalDetailsBlock
