/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Table, EmptyTableView, TableRow } from 'libs/tables'
import { callJSONApi } from 'libs/api'
import BlockHeader from 'components/headers/BlockHeader'
import SplitBlock from 'components/blocks/SplitBlock'
import ConfirmPopup from 'components/popups/ConfirmPopup'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import FlatButton from 'components/buttons/FlatButton'
import { showMessage } from 'redux/modules/notifications'
import StoreLocationForm from 'containers/business/StoreLocationForm'
import { removeItem } from 'redux/modules/tables'
import { mouseDownHandler, mouseUpHandler } from 'libs/utils'
import CONSTANTS from 'libs/constants'
import * as misc from 'libs/formats'

import classes from './locations.module.scss'

const TABLE_NAME = 'storeLocations'

class UnconnectedStoreLocationsPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showingSplit: false,
      shownRow: null,
    }
  }

  onDeleteLocation(uuid) {
    callJSONApi(
      `/storeLocations/${uuid}/`,
      'DELETE',
      {},
      (response) => {
        if (response.status === 200) {
          this.props.dispatch(removeItem(TABLE_NAME, uuid))
          this.hideDeletePopup()
          this.setState({
            shownRow: null,
            showingSplit: false,
          })
        } else {
          this.props.dispatch(showMessage(`Error removing location: ${response.message}`))
          this.hideDeletePopup()
        }
      },
      (prettyError) => {
        this.props.dispatch(showMessage(`Error removing location: ${prettyError}`, 'danger'))
        this.hideDeletePopup()
      }
    )
  }

  getDefaultBadge(rowData) {
    return rowData.get('isDefault') && <span className={classes.defaultNoteText}>Default</span>
  }

  addLocation() {
    this.setState({
      showingSplit: true,
    })
  }

  rowClicked(rowData) {
    this.showRow(rowData)
  }

  showRow(rowData) {
    this.setState((currentState) => {
      const newState = { ...currentState }

      if (newState.shownRow && newState.shownRow.get('uuid') === rowData.get('uuid')) {
        newState.shownRow = null
      } else {
        newState.shownRow = rowData
      }

      if (newState.shownRow) {
        newState.showingSplit = true
      } else {
        newState.showingSplit = false
      }

      return newState
    })
  }

  mouseDownHandler() {
    mouseDownHandler()
  }

  mouseUpHandler() {
    mouseUpHandler()
  }

  splitViewClosed() {
    this.setState({
      shownRow: null,
      showingSplit: false,
    })
  }

  hideDeletePopup() {
    this.setState({
      showDeleteModal: false,
      deleteUUID: null,
    })
  }

  deletePressed() {
    this.setState((prevState) => ({
      showDeleteModal: true,
      deleteUUID: prevState.shownRow.get('uuid'),
    }))
  }

  deletePopUp() {
    return (
      <ConfirmPopup
        showing={this.state.showDeleteModal}
        onCancel={() => this.hideDeletePopup()}
        icon='icon-delete-garbage-streamline'
        actionType='destructive'
        iconType={classes.deleteIconType}
        title='Remove'
        subtitle='Are you sure you want to remove this location?'
        onConfirm={() => {
          this.onDeleteLocation(this.state.deleteUUID)
        }}
      />
    )
  }

  addLocationButton() {
    return (
      <FlatButton
        className='blueBackground'
        label='Add Location'
        onClick={() => this.addLocation()}
        onMouseDown={() => this.mouseDownHandler()}
        onMouseUp={() => this.mouseUpHandler()}
      />
    )
  }

  renderTableRow(rowData, rowIndex) {
    return (
      <TableRow
        onClick={() => this.rowClicked(rowData)}
        selected={this.state.shownRow && this.state.shownRow.get('uuid') === rowData.get('uuid')}
        rowIndex={rowIndex}
        key={rowData.get('uuid')}
      >
        <td className={classes.defaultNote}>{this.getDefaultBadge(rowData)}</td>
        <td>{rowData.get('name')}</td>
        <td>
          <misc.LongDate date={rowData.get('lastUpdated')} />
        </td>
      </TableRow>
    )
  }

  renderSplitContent() {
    return (
      <StoreLocationForm
        formName={TABLE_NAME}
        closeSplitView={() => this.splitViewClosed()}
        showButtons={this.state.showingSplit}
        shownRow={this.state.shownRow}
        mouseDownHandler={() => this.mouseDownHandler()}
        mouseUpHandler={() => this.mouseUpHandler()}
      />
    )
  }

  renderLocationsTable() {
    return (
      <Table
        useOffset
        waitForFilterLoad={false}
        hideNoResults
        rowsClickable
        name={TABLE_NAME}
        api='/storeLocations/'
        sortPath='name'
        emptyTable={
          <EmptyTableView title='Your business currently has no locations.'>
            <span>
              {'Click '}
              <b
                style={{ color: CONSTANTS.YOCO_BLUE, cursor: 'pointer' }}
                onClick={this.addLocation.bind(this)}
              >
                Add Location
              </b>
              {' to add Locations to your store'}
            </span>
          </EmptyTableView>
        }
        getHeader={() => {
          return (
            <tr className='visible-header'>
              <th className={classes.defaultNoteHeader} />
              <th>Name</th>
              <th>Last Updated</th>
            </tr>
          )
        }}
        getRow={(immutableRow, rowIndex) => this.renderTableRow(immutableRow, rowIndex)}
      />
    )
  }

  render() {
    return (
      <SplitBlock
        formName={TABLE_NAME}
        showDelete={!!this.state.shownRow && !this.state.shownRow.get('isDefault')}
        showingSplit={this.state.showingSplit}
        saveLabel={this.state.shownRow ? 'Save' : 'Add'}
        header={this.state.shownRow ? 'Edit Location' : 'Add a New Location'}
        onDismiss={() => this.splitViewClosed()}
        renderSplitContent={() => this.renderSplitContent()}
        deletePressed={() => this.deletePressed()}
      >
        <MaxWidthBlock>
          <BlockHeader
            title='Store Locations'
            style={{ marginBottom: '6px' }}
            actions={this.addLocationButton()}
          />
          {this.renderLocationsTable()}
        </MaxWidthBlock>
        {this.deletePopUp()}
      </SplitBlock>
    )
  }
}

export default connect(() => ({}))(UnconnectedStoreLocationsPage)

UnconnectedStoreLocationsPage.propTypes = {
  dispatch: PropTypes.func,
}
