import { StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  logo: {
    position: 'absolute',
    width: '146px',
    height: '64px',
    left: '225px',
    top: '120px',
  },
  qr: {
    position: 'absolute',
    width: '440px',
    height: '440px',
    left: '78px',
    top: '204px',
  },
  supportingText: {
    lineHeight: '114%',
    position: 'absolute',
    width: '432',
    height: '64',
    left: '82',
    top: '660',
    fontFamily: 'SharpGroteskSmBold20',
    fontWeight: '600',
    fontSize: '28',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  supportingText2: {
    lineHeight: '114%',
    position: 'absolute',
    width: '432',
    height: '64',
    left: '82',
    top: '700',
    fontFamily: 'SharpGroteskSmBold20',
    fontWeight: '600',
    fontSize: '28',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
})

export default styles
