import React, { useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import S from 'string'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import localisationSelector from 'libs/localisation/localisation'
import { formatCurrency, friendlyDate } from 'libs/utils'
import { selectors } from 'redux/modules/capital'
import styles from 'components/capital/capital.module.scss'
import SplitBlock from 'components/blocks/SplitBlock'
import SplitHeader from 'components/headers/SplitHeader'
import { FilterBatch } from 'libs/filters'
import DateFilter from 'components/filters/DateFilter'
import {
  CapitalOfferRecordFactory,
  PayoutDeductionPlanRecordFactory,
} from 'redux/modules/capital/records'
import TitleCard from 'components/cards/TitleCard'
import CapitalRepaymentsTable from 'components/capital/partials/CapitalRepaymentsTable'
import { If } from 'libs/formats'
import * as actions from 'redux/modules/capital/actions'
import { PayoutDeductionPlanFromTableData } from 'redux/modules/capital/types'
import { getTotalAmountRepaid } from '../utils'
import CapitalDeductionsTable from '../partials/CapitalDeductionsTable'

interface Props {
  localisation: any
  capitalDeductions: PayoutDeductionPlanFromTableData[]
  onDeductionPlanViewed: (deductionPlan: PayoutDeductionPlanFromTableData) => void
}

export const CapitalDeductionsPage: React.FunctionComponent<Props> = ({
  localisation,
  capitalDeductions,
  onDeductionPlanViewed,
}) => {
  const [showSplitScreen, setShowSplitScreen] = useState(false)
  const [selectedDeductionPlan, setSelectedDeductionPlan] = useState<any>(null)

  const closeSplitView = () => {
    setShowSplitScreen(false)
    setSelectedDeductionPlan(null)
  }

  const selectDeductionPlan = (deductionPlan) => {
    if (selectedDeductionPlan === deductionPlan) {
      closeSplitView()
      return
    }
    setShowSplitScreen(true)
    setSelectedDeductionPlan(deductionPlan)
    onDeductionPlanViewed(deductionPlan.toJS())
  }

  const renderDeductionPlan = () => {
    if (selectedDeductionPlan) {
      // capitalOffer withMutations is required for the <CapitalRepaymentsTable/> component
      const capitalOfferWithMutations = CapitalOfferRecordFactory(
        selectedDeductionPlan.get('capitalOffer')
      ).withMutations((map) => {
        map.setIn(['payoutDeductionPlan'], PayoutDeductionPlanRecordFactory(selectedDeductionPlan))
      })
      const offerContract = capitalOfferWithMutations.getIn(['offerResponseData', 'offerContract'])

      return (
        <div>
          <TitleCard title='Deduction Plan'>
            <div style={{ padding: '20px' }}>
              <p>
                Provider:
                <b>
                  {` ${selectedDeductionPlan.getIn(['providerBusiness', 'displayName'], 'Yoco')}`}
                </b>
              </p>
              <p>
                State:
                <b>{` ${S(selectedDeductionPlan.get('payoutDeductionState')).humanize().s}`}</b>
              </p>
              <If condition={offerContract && offerContract.get('outstanding_balance')}>
                <p>
                  Discounted balance outstanding:
                  <b>{` ${localisation.formatCurrency(
                    offerContract.get('outstanding_balance')
                  )}`}</b>
                </p>
              </If>
              <p>
                Total amount paid:
                <b>{` ${localisation.formatCurrency(
                  selectedDeductionPlan.get('totalAmountPaid', 0)
                )}`}</b>
              </p>
              <p>
                Total amount payable:
                <b>{` ${localisation.formatCurrency(
                  selectedDeductionPlan.get('totalAmountPayable', 0)
                )}`}</b>
              </p>
              <p>
                Created:
                <b>{` ${friendlyDate(selectedDeductionPlan.get('created'))}`}</b>
              </p>
            </div>
          </TitleCard>
          <div style={{ height: '21px' }} />
          <TitleCard title='Cash advance repayment history'>
            <FilterBatch containerClass={styles.filterCapitalContainer} name='capitalDeductions'>
              <DateFilter
                noPosition
                defaults={[moment().startOf('day'), moment().startOf('day').add(1, 'day')]}
              />
            </FilterBatch>
            <CapitalRepaymentsTable
              deductionPlanUUID={capitalOfferWithMutations.getIn(['payoutDeductionPlan', 'uuid'])}
              totalAmountPayable={capitalOfferWithMutations.getIn([
                'payoutDeductionPlan',
                'totalAmountPayable',
              ])}
              formatCurrency={localisation.formatCurrency}
              getTotalAmountRepaid={getTotalAmountRepaid(capitalDeductions)}
            />
          </TitleCard>
        </div>
      )
    }
    return undefined
  }

  const renderSplitBlock = () => {
    const renderSplitBlockHeader = () => {
      return <SplitHeader title='Deduction Plan' closeSplitView={closeSplitView} />
    }

    return (
      <SplitBlock
        showSave={false}
        hideActionButtonBar={false}
        showingSplit={showSplitScreen}
        onDismiss={closeSplitView}
        renderSplitContent={renderDeductionPlan}
        headerRenderer={renderSplitBlockHeader}
      >
        <CapitalDeductionsTable
          selectedDeductionPlan={selectedDeductionPlan}
          selectDeductionPlan={selectDeductionPlan}
          formatCurrency={formatCurrency}
        />
      </SplitBlock>
    )
  }

  return <MaxWidthBlock>{renderSplitBlock()}</MaxWidthBlock>
}

const mapStateToProps = (state) => ({
  localisation: localisationSelector(state),
  capitalDeductions: selectors.selectCapitalDeductionsTableData(state),
})

const mapDispatchToProps = (dispatch) => ({
  onDeductionPlanViewed: (deductionPlan: PayoutDeductionPlanFromTableData) =>
    dispatch(actions.deductionPlanViewed(deductionPlan)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CapitalDeductionsPage)
