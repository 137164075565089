import OnlineSettingsPage from 'containers/online/OnlineSettingsPage'
import ROLES from 'libs/roles'
import VouchersPage from 'containers/vouchers/VouchersPage'
import VouchersPageActivatedPage from 'containers/vouchers/VouchersPageActivatedPage'
import OnlineActivatePage from 'containers/online/OnlineActivatePage'
import PluginPage from 'containers/online/PluginPage'
import PaymentLinksPage from 'containers/online/PaymentLinksPage'
import { labelType } from 'components/navbars/RouteLabel.constants'

import ShopifyInstallationPage from './checkout/ShopifyInstallationPage'
import OnlineStorePage from './store/OnlineStorePage'
import OnlineProductsPage from './store/OnlineProductsPage'
import DeliveryMethodsPage from './store/DeliveryMethodsPage'
import OnlineStoreDesignPage from './store/OnlineStoreDesignPage'

export default function onlineRoutes() {
  return {
    path: 'online',
    text: `Sell Online`,
    icon: 'online-store',
    hideInMenu: false,
    requiresOneRole: [ROLES.MANAGE_BUSINESS],
    routes: [
      {
        path: 'payment-links',
        text: 'Payment Links',
        component: PaymentLinksPage,
      },
      {
        path: 'plugin-keys',
        text: 'Payment Gateway',
        component: PluginPage,
        hideInMenu: false,
        requiresOneRole: [ROLES.MANAGE_BUSINESS],
      },
      {
        path: 'settings',
        text: 'Payment Page',
        component: OnlineSettingsPage,
      },
      {
        path: 'activate',
        text: 'Settings',
        hideInMenu: true,
        component: OnlineActivatePage,
      },
      {
        path: 'store',
        text: 'Online Store',
        label: labelType.NOTICE,
        requiresOneOfFeatures: ['onlineProducts'],
        component: OnlineStorePage,
      },
      {
        path: 'store/products',
        text: 'Online Products',
        hideInMenu: true,
        requiresOneOfFeatures: ['onlineProducts'],
        component: OnlineProductsPage,
      },
      {
        path: 'store/deliveryMethods',
        text: 'Delivery Methods',
        hideInMenu: true,
        requiresOneOfFeatures: ['onlineProducts'],
        component: DeliveryMethodsPage,
      },
      {
        path: 'store/design',
        text: 'Online Store Design',
        hideInMenu: true,
        requiresOneOfFeatures: ['onlineProducts'],
        component: OnlineStoreDesignPage,
      },
      {
        path: 'vouchers',
        text: 'Gift vouchers',
        component: VouchersPage,
      },
      {
        path: 'vouchers/activated',
        text: 'Gift vouchers',
        hideInMenu: true,
        component: VouchersPageActivatedPage,
      },
      {
        path: 'shopify/install',
        text: 'Complete shopify installation',
        hideInMenu: true,
        component: ShopifyInstallationPage,
      },
    ],
  }
}
