import { createSelector } from 'reselect'
import { CapitalOfferStates } from './types'

export const selectTableState = (state) => state.tables

export const selectCapitalDeductionsTable = createSelector(selectTableState, (tables) =>
  tables.get('capitalDeductions')
)

export const selectCapitalDeductionsTableData = createSelector(
  selectCapitalDeductionsTable,
  (deductions) => deductions && deductions.get('data')
)

export const selectCapitalState = (state) => state.capital

export const selectQualificationRequest = createSelector(
  selectCapitalState,
  (capitalState) => capitalState.qualificationRequest
)

export const selectQualification = createSelector(
  selectCapitalState,
  (capitalState) => capitalState.qualification
)

export const selectCapitalQualifyingBusiness = createSelector(
  selectCapitalState,
  (capitalState) => capitalState.capitalQualifyingBusiness
)

export const selectCapitalQualifyingBusinessNotifyMe = createSelector(
  selectCapitalQualifyingBusiness,
  (capitalQualifyingBusiness) => capitalQualifyingBusiness.notifyMe
)

export const selectCapitalQualifyingBusinessIsFetchingData = createSelector(
  selectCapitalQualifyingBusiness,
  (capitalQualifyingBusiness) => capitalQualifyingBusiness.fetchingData
)

export const selectPrelimOffersRequest = createSelector(
  selectCapitalState,
  (capitalState) => capitalState.prelimOffersRequest
)

export const selectPrelimOffer = createSelector(
  selectCapitalState,
  (capitalState) => capitalState.prelimOffer
)

export const selectPrelimOfferProviderName = createSelector(
  selectPrelimOffer,
  (prelimOffer) => prelimOffer.providerName
)

export const selectHasPrelimOffer = createSelector(
  selectPrelimOffer,
  (prelimOffer) => prelimOffer && prelimOffer.uuid !== null
)

export const selectCapitalOfferRequest = createSelector(
  selectCapitalState,
  (capitalState) => capitalState.capitalOfferRequest
)

export const selectCapitalOffer = createSelector(selectCapitalState, (capitalState) =>
  capitalState.capitalOffer.toJS()
)

export const selectCapitalOfferCollectionsActivated = createSelector(
  selectCapitalOffer,
  (capitalOffer) => capitalOffer.state === CapitalOfferStates.CapitalOfferCollectionsActivated
)

export const selectCapitalOfferProviderName = createSelector(
  selectCapitalOffer,
  (capitalOffer) => capitalOffer.providerName
)

export const selectPrelimProviderMatchesOfferProvider = createSelector(
  selectCapitalOfferProviderName,
  selectPrelimOfferProviderName,
  (offerProviderName, prelimOfferProviderName) =>
    offerProviderName && offerProviderName === prelimOfferProviderName
)

export const selectCapitalOfferPayoutDeductionPlan = createSelector(
  selectCapitalOffer,
  (capitalOffer) => capitalOffer.payoutDeductionPlan
)

export const selectHasCapitalOfferPayoutDeductionPlan = createSelector(
  selectCapitalOfferPayoutDeductionPlan,
  (payoutDeductionPlan) => payoutDeductionPlan && payoutDeductionPlan.uuid !== null
)

export const selectCapitalOfferPayoutDeductionPlanTotalAmountPayable = createSelector(
  selectCapitalOfferPayoutDeductionPlan,
  (payoutDeductionPlan) => (payoutDeductionPlan ? payoutDeductionPlan.totalAmountPayable : 0)
)

export const selectCapitalOfferPayoutDeductionPlanTotalAmountPaid = createSelector(
  selectCapitalOfferPayoutDeductionPlan,
  (payoutDeductionPlan) => (payoutDeductionPlan ? payoutDeductionPlan.totalAmountPaid : 0)
)

export const selectHasRevolvingOffer = createSelector(
  selectPrelimProviderMatchesOfferProvider,
  selectHasPrelimOffer,
  selectHasCapitalOfferPayoutDeductionPlan,
  selectCapitalOfferCollectionsActivated,
  (
    prelimProviderMatchesOfferProvider,
    hasPrelimOffer,
    hasPayoutDeductionPlan,
    collectionsActivatedCapitalOffer
  ) => {
    return (
      prelimProviderMatchesOfferProvider &&
      hasPrelimOffer &&
      hasPayoutDeductionPlan &&
      collectionsActivatedCapitalOffer
    )
  }
)

export const selectCanReApplyForAdvance = createSelector(
  selectCapitalOfferPayoutDeductionPlanTotalAmountPayable,
  selectCapitalOfferPayoutDeductionPlanTotalAmountPaid,
  selectHasPrelimOffer,
  selectHasCapitalOfferPayoutDeductionPlan,
  selectHasRevolvingOffer,
  (
    totalAmountPayable,
    totalAmountPaid,
    hasPrelimOffer,
    hasPayoutDeductionPlan,
    hasRevolvingOffer
  ) => {
    return (
      (totalAmountPayable - totalAmountPaid <= 0 && hasPrelimOffer && hasPayoutDeductionPlan) ||
      hasRevolvingOffer
    )
  }
)
