import { IconName } from '@yoco/design-system-icons'
import React from 'react'
import { track } from 'libs/analytics'
import QRCodePDFGenerator from './components/QRCodePDFGenerator/QRCodePDFGenerator'

interface StoreCreationStepsProps {
  hasProducts: boolean
  qrUrl: string
}

interface StoreCreationSteps {
  heading: string
  blurb: string
  btnLabel: string
  iconName: IconName
  btnLink?: string
  todoTag?: boolean
  btnDisabled?: boolean
  btnOnPress?: JSX.Element
}

interface Product {
  onlineProduct?: OnlineProduct
}

interface OnlineProduct {
  isEnabled: boolean
}

export const storeCreationSteps = ({
  hasProducts,
  qrUrl,
}: StoreCreationStepsProps): StoreCreationSteps[] => [
  {
    heading: 'ONLINE PRODUCTS',
    blurb: 'Manage and set stock availability for products you want to sell online',
    btnLabel: 'Manage Products',
    iconName: 'sell',
    btnLink: '/store/products',
    btnDisabled: false,
  },
  {
    heading: 'CUSTOMISE YOUR STORE',
    blurb: 'Make your store your own and share it on social media',
    btnLabel: 'Customise',
    btnDisabled: !hasProducts,
    iconName: 'payment-page',
    todoTag: !hasProducts,
    btnLink: '/online/store/design',
  },
  {
    heading: 'DELIVERY METHODS',
    blurb: 'Add delivery methods your store supports',
    btnLabel: 'Delivery Method',
    btnDisabled: !hasProducts,
    iconName: 'delivery-method',
    todoTag: !hasProducts,
    btnLink: '/online/store/deliveryMethods',
  },
  {
    heading: 'QR CODE',
    blurb: 'Print and display this QR code in your shop so customers can find your online store',
    btnLabel: 'Download QR code',
    btnDisabled: !hasProducts,
    iconName: 'qr-code',
    todoTag: !hasProducts,
    btnOnPress: <QRCodePDFGenerator url={qrUrl} />,
  },
]

export const trackStorefront = (businessUUID: string, userUUID: string, identifier: string): void =>
  track(identifier, {
    name: identifier,
    businessUUID,
    userUUID,
  })

export const hasOnlineProducts = (products: Product[]): boolean => {
  const productsWithOnlineParam = products.filter(
    (product) => product.onlineProduct && product.onlineProduct.isEnabled
  )
  return productsWithOnlineParam.length > 0
}
