import React from 'react'

interface Props {
  payoutType: 'instant' | 'standard'
}

const PayoutType: React.FunctionComponent<Props> = ({ payoutType }) => {
  if (payoutType === 'instant') {
    return <div>Instant Payout</div>
  }
  return null
}

export default PayoutType
