import { Map, List } from 'immutable'

const ACTIONS = {
  SHOW_MESSAGE: 'SHOW_MESSAGE',
  REMOVE_MESSAGE: 'REMOVE_MESSAGE',
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
  CLEAR_NOTIFICATIONS: 'CLEAR_NOTIFICATIONS',
}

export function showMessage(message, messageType = 'success', key = undefined) {
  return {
    type: ACTIONS.SHOW_MESSAGE,
    message,
    messageType,
    key,
  }
}

export function removeMessage(key) {
  return {
    type: ACTIONS.REMOVE_MESSAGE,
    key,
  }
}

export function showLoader() {
  return {
    type: ACTIONS.SHOW_LOADER,
  }
}

export function hideLoader() {
  return {
    type: ACTIONS.HIDE_LOADER,
  }
}

export function clearNotifications() {
  return {
    type: ACTIONS.CLEAR_NOTIFICATIONS,
  }
}

const initialState = Map({
  messages: List([]),
  loadingCount: 0,
})

export default function Notifications(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.SHOW_MESSAGE:
      // eslint-disable-next-line no-case-declarations
      let messages = state.get('messages')
      if (action.key) {
        messages = messages.filter((message) => {
          return message.get('key') !== action.key
        })
      }
      messages = messages.push(
        Map({
          message: action.message,
          messageType: action.messageType,
          key: action.key || messages.size,
        })
      )

      return state.set('messages', messages)
    case ACTIONS.REMOVE_MESSAGE:
      return state.set(
        'messages',
        state.get('messages').filter((message) => message.get('key') !== action.key)
      )
    case ACTIONS.SHOW_LOADER:
      return state.set('loadingCount', state.get('loadingCount') + 1)
    case ACTIONS.HIDE_LOADER:
      return state.set('loadingCount', Math.max(state.get('loadingCount') - 1, 0))
    case ACTIONS.CLEAR_NOTIFICATIONS:
      return initialState
    default:
      return state
  }
}
