/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@yoco/design-system-icons/dist/react'

import { isFlagship } from 'libs/utils'
import typography from 'ui/typography/typography.module.scss'
import { If } from 'libs/formats'

import classes from './styles.module.scss'

function PopupHeader({ headerTitle, onBackArrow, onCancel }) {
  return (
    <div
      className={isFlagship ? classes.flagshipPopupHeaderContainer : classes.popupHeaderContainer}
    >
      <If condition={onBackArrow}>
        <div onClick={onBackArrow} className={classes.backArrowContainer}>
          <Icon name='chevron-left' size={24} />
        </div>
      </If>
      <div className={classes.headerTitleContainer}>
        <div className={typography.h2}>{headerTitle}</div>
      </div>
      <If condition={onCancel}>
        <div onClick={onCancel} className={classes.closeButtonContainer}>
          <Icon name='close' size={24} />
        </div>
      </If>
    </div>
  )
}

PopupHeader.propTypes = {
  headerTitle: PropTypes.node,
  onBackArrow: PropTypes.func,
  onCancel: PropTypes.func,
}

export default PopupHeader
