import { RootState } from 'redux/store'

export const getTransformedState = (state: RootState): Record<string, any> => {
  const transformedState = {
    ...state,
    session: {
      ...state.session,
      posFirebaseToken: null,
      token: null,
    },
    plugin: null,
    launchDarkly: null,
    imports: null,
    notifications: null,
  }

  return transformedState
}
