import React from 'react'
import PropTypes from 'prop-types'

import { PopupComponent } from 'libs/modals'

import PopupSuccessView from './Views/Success'

class SuccessPopup extends PopupComponent {
  getContent() {
    return (
      <PopupSuccessView
        title={this.props.title}
        subtitle={this.props.subtitle}
        buttonLabel='Done'
        onDismiss={this.props.onDismiss}
      />
    )
  }
}

SuccessPopup.defaultProps = {
  showing: true,
  title: 'Success',
}

SuccessPopup.propTypes = {
  showing: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onDismiss: PropTypes.func,
}

export default SuccessPopup
