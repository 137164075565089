import { callJSONApi } from './api'

export const helpCenterURL = 'https://support.yoco.help/s/'

export function visitHelpCenter(e) {
  if (e) {
    console.log('preventing default')
    e.preventDefault()
  }

  callJSONApi(
    '/user/help/sso',
    'GET',
    undefined,
    (response) => {
      console.log('response', response)
      if (response.status === 200 && response.data) {
        window.open(response.data.url || helpCenterURL)
      } else {
        window.open(helpCenterURL)
      }
    },
    (error) => {
      console.error('Unable to retrieve help center SSO', error)
      window.open(helpCenterURL)
    }
  )
}
