/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-bind */
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router'
import Immutable, { Map, List } from 'immutable'

import { FirebaseTable, EmptyTableView, TableRow } from 'libs/tables'
import ItemIcon from 'components/items/ItemIcon'
import BlockHeader from 'components/headers/BlockHeader'
import SplitBlock from 'components/blocks/SplitBlock'
import SplitHeader from 'components/headers/SplitHeader'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import { clearForm } from 'redux/modules/forms'
import UpdateStockPopup from 'components/popups/UpdateStockPopup'
import { FilterBatch } from 'libs/filters'
import SearchBar from 'components/filters/SearchBar'
import * as formats from 'libs/formats'
import VariantName from 'components/pos/VariantName'
import CONSTANTS from 'libs/constants'
import { getStockCount } from 'libs/pos/utils'
import { mouseDownHandler, mouseUpHandler } from 'libs/utils'

import { useStateCallback } from 'libs/hooks'
import EditStockForm from './EditStockForm'
import classes from './store.module.scss'

const TABLE_NAME = 'stockItems'
const FIREBASE_JOIN = Immutable.fromJS({
  skuUUID: 'stockInfo',
})

const StockPage = () => {
  const dispatch = useDispatch()
  const [showingSplit, setShowingSplit] = useStateCallback(false)
  const [shownRow, setShownRow] = useStateCallback(null)
  const [showStockModal, setShowStockModal] = useStateCallback(false)
  const [editStockInfo, setEditStockInfo] = useStateCallback(null)

  const onUpdateStock = (rowData) => {
    setShowStockModal(true, setEditStockInfo(rowData))
  }

  const getAlertLevel = (rowData) => {
    return rowData.get('alertLevel', 'Not set')
  }

  const splitViewClosed = () => {
    dispatch(clearForm(TABLE_NAME))
    setShownRow(null, setShowingSplit(false))
  }

  const showRow = (rowData) => {
    const shownRowNew =
      shownRow && shownRow.get('skuUUID') === rowData.get('skuUUID') ? null : rowData
    setShownRow(shownRowNew, setShowingSplit(!!shownRowNew))
  }

  const rowClicked = (rowData) => {
    showRow(rowData)
  }

  const updateStockPopup = () => {
    const formName = 'updateStockFormFromTable'

    return (
      <UpdateStockPopup
        showing={showStockModal}
        api='/stock/setStockAtLocation'
        sku={(editStockInfo || Map()).getIn(['sku', 'sku'])}
        skuUUID={(editStockInfo || Map()).get('skuUUID')}
        stockInfo={editStockInfo}
        formName={formName}
        currentStock={getStockCount(editStockInfo || Map())}
        onMouseDown={() => {
          mouseDownHandler()
        }}
        onMouseUp={() => {
          mouseUpHandler()
        }}
        onEsc={() => {
          setShowStockModal(false)
        }}
      />
    )
  }

  const renderTableRow = (rowData, rowIndex) => {
    const stockCount = getStockCount(rowData)
    const stockInfo = rowData
    const sku = rowData.get('sku', Map())
    // If we have not yet finished fetching the tile data from Firebase
    // then we display the data that was returned from core
    const tile = rowData.getIn(['product', 'tile'], rowData.get('tile')) || Map()

    let alertNote = ''
    let noteClass = 'stockAlert'

    if (stockInfo.get('alertLevel') && stockCount < stockInfo.get('alertLevel') && stockCount > 0) {
      alertNote = 'Low'
      noteClass += ` lowStock`
    } else if (stockCount < 1) {
      alertNote = 'Out'
      noteClass += ` zeroStock`
    }

    return (
      <TableRow
        rowIndex={rowIndex}
        onClick={() => rowClicked(rowData)}
        selected={shownRow && shownRow.get('skuUUID') === rowData.get('skuUUID')}
      >
        <td className='image'>
          <ItemIcon tile={tile} />
        </td>
        <td className='showOnPhoneOnly'>
          <tr className='stockPhoneRows'>
            <td className='variant' colSpan={3}>
              {tile.get('name')}{' '}
              <VariantName choiceValues={rowData.get('variantChoiceValues', List())} />
            </td>
          </tr>
          <tr className='stockPhoneRows'>
            <td className='sku'>{sku.get('sku')}</td>
            <td className='stockNote'>
              <span className={noteClass}>{alertNote}</span>
            </td>
            <td className='stockLevel'>{stockCount}</td>
          </tr>
        </td>
        <td className='sku hide-on-xs-mobile'>{sku.get('sku')}</td>
        <td className='variant hide-on-xs-mobile'>
          {tile.get('name')}{' '}
          <VariantName choiceValues={rowData.get('variantChoiceValues', List())} />
        </td>
        <td className='stockLevel hide-on-xs-mobile'>
          <div
            className='stockUpdate hide-on-xs-mobile'
            onClick={(event) => {
              event.stopPropagation()
              onUpdateStock(rowData)
            }}
          >
            <formats.Number number={stockCount} />
            <i className='iconcollection-pencil' style={{ marginLeft: '12px' }} />
          </div>
        </td>
        <td className='stockNote hide-on-xs-mobile'>
          <span className={noteClass}>{alertNote}</span>
        </td>
        <td className='stockAlertCell hide-on-md'>{getAlertLevel(rowData)}</td>
      </TableRow>
    )
  }

  const renderHeader = () => {
    const formTitle = shownRow && (
      <span>
        {shownRow.getIn(['product', 'tile', 'name'])}

        <VariantName choiceValues={shownRow.get('variantChoiceValues', List())} />
      </span>
    )

    return (
      <SplitHeader
        title={formTitle}
        closeSplitView={() => splitViewClosed()}
        style={{ background: CONSTANTS.FADED_BACKGROUND }}
      />
    )
  }

  const renderSplitContent = () => {
    if (shownRow) {
      return (
        <EditStockForm
          closeSplitView={() => splitViewClosed()}
          localObject={shownRow}
          firebaseJoin={FIREBASE_JOIN}
          formName={TABLE_NAME}
        />
      )
    }
    return undefined
  }

  return (
    <SplitBlock
      formName={TABLE_NAME}
      renderSplitContent={() => renderSplitContent()}
      headerRenderer={() => renderHeader()}
      showingSplit={showingSplit}
      onDismiss={() => splitViewClosed()}
    >
      <MaxWidthBlock className={classes.itemsPage}>
        <BlockHeader title='Manage Stock' style={{ marginBottom: '6px' }} />
        <FilterBatch name={TABLE_NAME} showMobileInline>
          <SearchBar placeholder='Search by product name or sku' />
        </FilterBatch>
        <FirebaseTable
          api='/stock/'
          name={TABLE_NAME}
          useOffset
          waitForFilterLoad={false}
          hideNoResults
          rowsClickable
          firebaseJoin={FIREBASE_JOIN}
          emptyTable={
            <EmptyTableView title='Your business currently has no stock'>
              <span>
                Visit
                <Link
                  style={{ color: CONSTANTS.YOCO_BLUE, cursor: 'pointer' }}
                  to='/store/products'
                >
                  {' '}
                  Products{' '}
                </Link>{' '}
                to enable stock on your products
              </span>
            </EmptyTableView>
          }
          getHeader={() => {
            return (
              <tr className='visible-header'>
                <th className={classes.image} />
                <th className={classes.showOnPhoneOnly}>Product</th>
                <th className={`${classes.sku} hide-on-xs-mobile`}>SKU</th>
                <th className={`${classes.variant} hide-on-xs-mobile`}>Product</th>
                <th className={`${classes.stock} hide-on-xs-mobile`}>Stock Level</th>
                <th className={`${classes.stockNote} hide-on-xs-mobile`} />
                <th className={`${classes.alert} hide-on-md`}>Alert Level</th>
              </tr>
            )
          }}
          getRow={(immutableRow, rowIndex) => renderTableRow(immutableRow, rowIndex)}
        />
        {updateStockPopup()}
      </MaxWidthBlock>
    </SplitBlock>
  )
}

export default StockPage
