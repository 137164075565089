import { AnyAction, Store } from '@reduxjs/toolkit'
import { CapitalAction } from './Capital.analytics.utils'
import { CapitalActions, CapitalAnalyticsEvent, CapitalAnalyticsEvents } from './types'

export interface CapitalAnalyticsDependencies {
  track: (eventName: CapitalAnalyticsEvents, eventPayload?: any) => void
  getAnalyticsEventFromAction: (
    state
  ) => (action: CapitalAction) => CapitalAnalyticsEvent | undefined
}

export const createCapitalAnalyticsMiddleware = ({
  track,
  getAnalyticsEventFromAction,
}: CapitalAnalyticsDependencies): any => (store: Store) => (next) => (action: AnyAction) => {
  if (Object.values(CapitalActions).includes(action.type)) {
    const state = store.getState()
    const event = getAnalyticsEventFromAction(state)(action as CapitalAction)
    if (event) {
      track(event.event, event?.payload)
    }
  }

  return next(action)
}
