/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unused-class-component-methods */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import PropTypes from 'prop-types'

import SplitBlock from 'components/blocks/SplitBlock'
import BlockHeader from 'components/headers/BlockHeader'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import { Tabs, TabPanel } from 'libs/tabs'
import { FirebaseHandler, features } from 'libs/firebase'

import ExampleReport from './EmailExampleReport'
import PreferenceForm from './EmailPreferenceForm'

class UnconnectedEmailNotifications extends Component {
  constructor(props) {
    super(props)

    this.oldIndex = -1
    this.state = {
      tabIndex: this.getTabIndex(),
    }
  }

  tabChanged(index) {
    this.setState({
      tabIndex: index,
    })
  }

  getTabIndex() {
    if (this.props.queryParams.notification) {
      if (this.props.queryParams.notification === 'sales') {
        return 0
      }
      if (this.props.queryParams.notification === 'tax') {
        return 1
      }
      if (
        this.props.queryParams.notification === 'stock' &&
        this.props.features &&
        this.props.features.getIn(['stock', 'enabled'])
      ) {
        return 2
      }
    }
    return 0
  }

  trackTableViewed(index) {
    if (index !== this.oldIndex) {
      this.oldIndex = index
    }
  }

  splitViewClosed() {
    this.setState({
      exampleReport: null,
      showingSplit: false,
    })
  }

  mouseDownHandler() {
    this.mouseIsDown = true
  }

  mouseUpHandler() {
    this.mouseIsDown = false
  }

  showExampleReport(exampleReport) {
    this.setState({
      exampleReport,
      showingSplit: true,
    })
  }

  renderSalesTab() {
    return (
      <TabPanel title='Sales Report'>
        <PreferenceForm
          searchable={!this.isMobileDevice}
          preferenceType='sales_report'
          description={`
            A report showing the total sales, best selling items and highest earning staff members for the period.
          `}
          exampleFetched={this.showExampleReport.bind(this)}
          exampleReport={this.state.exampleReport}
          closeExample={this.splitViewClosed.bind(this)}
          mouseDownEvent={this.mouseDownHandler.bind(this)}
          mouseUpEvent={this.mouseUpHandler.bind(this)}
        />
      </TabPanel>
    )
  }

  renderTaxTab() {
    return (
      <TabPanel title='Tax Invoice'>
        <PreferenceForm
          searchable={!this.isMobileDevice}
          preferenceType='tax_invoice'
          periods={['monthly']}
          description='A tax invoice showing all the payouts you received this month.'
          hasShowExample={false}
          mouseDownEvent={this.mouseDownHandler.bind(this)}
          mouseUpEvent={this.mouseUpHandler.bind(this)}
        />
      </TabPanel>
    )
  }

  renderStockTab() {
    if (this.props.features && this.props.features.getIn(['stock', 'enabled'])) {
      return (
        <TabPanel title='Stock Alerts'>
          <PreferenceForm
            searchable={!this.isMobileDevice}
            preferenceType='stock_alerts'
            periods={['enabled']}
            description={`
              An email notification when a product stock level falls below the alert level you have configured
            `}
            hasShowExample={false}
            mouseDownEvent={this.mouseDownHandler.bind(this)}
            mouseUpEvent={this.mouseUpHandler.bind(this)}
          />
        </TabPanel>
      )
    }

    return undefined
  }

  renderTabs() {
    return (
      <Tabs tabChanged={this.tabChanged.bind(this)} tabIndex={this.state.tabIndex}>
        {this.renderSalesTab()}
        {this.renderTaxTab()}
        {this.renderStockTab()}
      </Tabs>
    )
  }

  renderSplitContent() {
    return <ExampleReport exampleReport={this.state.exampleReport} />
  }

  render() {
    this.trackTableViewed(this.state.tabIndex)

    return (
      <SplitBlock
        hideHeader
        hideActionButtonBar
        showingSplit={this.state.showingSplit}
        onDismiss={() => this.splitViewClosed()}
        renderSplitContent={() => this.renderSplitContent()}
        contentStyle={{ overflow: 'hidden', position: 'relative' }}
      >
        <MaxWidthBlock>
          <BlockHeader title='Email Notifications' />
          {this.renderTabs()}
        </MaxWidthBlock>
      </SplitBlock>
    )
  }
}

UnconnectedEmailNotifications.propTypes = {
  features: PropTypes.object,
  queryParams: PropTypes.object,
}

const ReduxConnectedEmailNotifications = connect((state) => ({
  queryParams: state.routing.locationBeforeTransitions.query,
}))(UnconnectedEmailNotifications)

const EmailNotifications = FirebaseHandler.connect(
  ReduxConnectedEmailNotifications,
  Map({
    features,
  })
)

export default EmailNotifications
