import * as Castle from '@castleio/castle-js'

const initCastle = () => {
  if (process.env.REACT_APP_CASTLE_PUBLISHABLE_KEY) {
    return Castle.configure({
      pk: process.env.REACT_APP_CASTLE_PUBLISHABLE_KEY,
    })
  }

  return undefined
}

export const castle = initCastle()
