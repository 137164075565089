import React from 'react'
import PropTypes from 'prop-types'

import BillPayment from './BillPayment'
import classes from './transactions.module.scss'

function TransactionLifespan({ transaction }) {
  if (transaction) {
    return (
      <div className={classes.billLifeSpan}>
        <BillPayment payment={transaction} isActive />
      </div>
    )
  }
  return <div />
}

TransactionLifespan.propTypes = {
  transaction: PropTypes.object,
}

export default TransactionLifespan
