/* eslint-disable react/no-unused-state */
import React, { Component } from 'react'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import Immutable from 'immutable'
import PropTypes from 'prop-types'

import * as forms from 'libs/forms'
import FlatButton from 'components/buttons/FlatButton'

import classes from './account.module.scss'

class UnconnectedResetPasswordPage extends Component {
  constructor(props) {
    super(props)
    // Token comes from our route params that are passed in by `react-router-redux`
    this.initialData = Immutable.Map({ token: props.params.token })
  }

  onSuccess(data) {
    this.props.dispatch(push(`/account/login?message=${data.message}&messageType=success`))
  }

  render() {
    return (
      <div className={`${classes.formBlock} clearfix`}>
        <p>Please set a new password for your Yoco account.</p>
        <forms.Form
          name='resetPassword'
          action='/user/reset/set-password'
          noWrap
          showSuccess
          addUTM
          initialData={this.initialData}
          loaderClass={classes.formLoader}
          onValidated={() => {
            this.setState({ hideMessage: true })
          }}
          onSuccess={(data) => this.onSuccess(data)}
        >
          <forms.HiddenField name='token' />
          <forms.FormBlock>
            <forms.PasswordField
              name='password'
              placeholder='New Password'
              validators={[new forms.RequiredValidator('You must provide a new password')]}
            />
            <forms.PasswordField
              name='confirmPassword'
              placeholder='Confirm Password'
              validators={[
                new forms.RequiredValidator('You must confirm the new password'),
                new forms.ConfirmPasswordValidator(this.props.password),
              ]}
            />
          </forms.FormBlock>
          <span style={{ marginTop: '12px', display: 'inline-block' }}>
            <Link to='/account/login'>Back to login</Link>
          </span>
          <FlatButton
            label='Set Password'
            className={`${classes.loginButton} blueBackground`}
            type='submit'
          />
        </forms.Form>
      </div>
    )
  }
}

UnconnectedResetPasswordPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  params: PropTypes.object,
  password: PropTypes.string,
}

const ResetPasswordPage = connect((state) => ({
  password: state.forms.getIn(['resetPassword', 'data', 'password']),
}))(UnconnectedResetPasswordPage)

export default ResetPasswordPage
