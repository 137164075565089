import { fromJS } from 'immutable'

import { callJSONApi } from 'libs/api'

import { updateTradingAddress } from './actions'

export const fetchTradingAddress = (onSuccess, onError) => (dispatch) => {
  callJSONApi(
    '/business/',
    'GET',
    {},
    (response) => {
      const { tradingAddress } = response.data.business

      const immutableTradingAddress = fromJS(tradingAddress) || new Map()

      dispatch(updateTradingAddress(immutableTradingAddress))

      if (onSuccess) {
        onSuccess(immutableTradingAddress)
      }
    },
    (error) => {
      if (onError) {
        onError(error)
      }
    }
  )
}
