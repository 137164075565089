import Immutable, { Map } from 'immutable'

import { NAVIGATED_ACTION } from 'redux/modules/navigatedAction'

export const ACTIONS = {
  INITIALIZE_FORM: 'INITIALIZE_FORM',
  SUBMIT: 'FORM_SUBMIT',
  VALIDATE: 'FORM_VALIDATE',
  FIELD_UPDATED: 'FORM_FIELD_UPDATED',
  FIELDS_UPDATED: 'FORM_FIELDS_UPDATED',
  SHOW_VALIDATION_ERRORS: 'FORM_SHOW_VALIDATION_ERRORS',
  STARTED_SUBMIT: 'FORM_STARTED_SUBMIT',
  SUBMIT_RESULT: 'FORM_SUBMIT_RESULT',
  DATA_FETCHED: 'FORM_DATA_FETCHED',
  CLEAR_FORM: 'FORM_CLEAR_FORM',
  DATA_LOADING: 'DATA_LOADING',
  CLEAR_FIELDS: 'FORM_CLEAR_FIELDS',
  STOP_VALIDATING: 'FORM_STOP_VALIDATING',
  SHOW_SUCCESS: 'FORM_SHOW_SUCCESS',
  SHOW_ERROR: 'FORM_SHOW_ERROR',
  CLEAR_ERROR_MESSAGE: 'FORM_CLEAR_ERROR_MESSAGE',
  CLEAR_SUCCESS_MESSAGE: 'FORM_CLEAR_SUCCESS_MESSAGE',
}

export function clearFormFields(formName, fields) {
  return {
    type: ACTIONS.CLEAR_FIELDS,
    formName,
    fields,
  }
}

export function clearForm(formName) {
  return {
    type: ACTIONS.CLEAR_FORM,
    formName,
  }
}

export function submitForm(formName) {
  return {
    type: ACTIONS.SUBMIT,
    formName,
  }
}

export function validateForm(formName) {
  return {
    type: ACTIONS.VALIDATE,
    formName,
  }
}

export function stopValidating(formName) {
  return {
    type: ACTIONS.STOP_VALIDATING,
    formName,
  }
}

export function showValidationErrors(
  formName,
  validationErrors,
  errorMessage = null,
  shouldStartValidating = true
) {
  return {
    type: ACTIONS.SHOW_VALIDATION_ERRORS,
    formName,
    validationErrors: Immutable.fromJS(validationErrors),
    errorMessage,
    shouldStartValidating,
  }
}

export function multipleFieldsUpdated(formName, data, replace = false) {
  return {
    type: ACTIONS.FIELDS_UPDATED,
    formName,
    data,
    replace,
  }
}

export function fieldUpdated(formName, fieldName, value) {
  return {
    type: ACTIONS.FIELD_UPDATED,
    formName,
    fieldName,
    value,
  }
}

export function startedSubmit(formName) {
  return {
    type: ACTIONS.STARTED_SUBMIT,
    formName,
  }
}

export function submitResult(formName, result) {
  return {
    type: ACTIONS.SUBMIT_RESULT,
    formName,
    result,
  }
}

export function dataLoading(formName) {
  return {
    type: ACTIONS.DATA_LOADING,
    formName,
  }
}

export function dataFetched(formName, result, dataPath) {
  return {
    type: ACTIONS.DATA_FETCHED,
    formName,
    result,
    dataPath,
  }
}

export function initializeForm(formName, data) {
  return {
    type: ACTIONS.INITIALIZE_FORM,
    formName,
    data,
  }
}

export function showSuccess(formName, data) {
  return {
    type: ACTIONS.SHOW_SUCCESS,
    formName,
    data,
  }
}

export function showError(formName, message) {
  return {
    type: ACTIONS.SHOW_ERROR,
    formName,
    message,
  }
}

export function clearSuccessMessage(formName) {
  return {
    type: ACTIONS.CLEAR_SUCCESS_MESSAGE,
    formName,
  }
}

export function clearErrorMessage(formName) {
  return {
    type: ACTIONS.CLEAR_ERROR_MESSAGE,
    formName,
  }
}

const initialState = Map()

function updateForm(state, formName, merge) {
  const forms = {}
  forms[formName] = merge
  return state.mergeDeep(forms)
}

export default function Forms(state = Map(), action = {}) {
  switch (action.type) {
    case ACTIONS.SUBMIT:
      return updateForm(
        state,
        action.formName,
        Map({
          transientShouldSubmit: true,
        })
      )
    case ACTIONS.STARTED_SUBMIT:
      return updateForm(
        state,
        action.formName,
        Map({
          transientShouldSubmit: false,
          transientIsSubmitting: true,
          transientResult: null,
          errorMessage: null,
          successMessage: null,
        })
      )
    case ACTIONS.SUBMIT_RESULT: {
      let validationErrors = {}
      let errorMessage = null

      const { result } = action

      if (result.status !== 200) {
        errorMessage = result.message

        if (result.status === 400 && result.data && result.data.errors) {
          validationErrors = result.data.errors
        }
      }

      const updatedState = updateForm(
        state,
        action.formName,
        Map({
          transientIsSubmitting: false,
          transientSubmittedResult: action.result,
          transientResult: action.result,
          validationErrors: Immutable.fromJS(validationErrors),
          errorMessage,
        })
      )

      return updatedState
    }
    case ACTIONS.INITIALIZE_FORM:
      return state.set(
        action.formName,
        Map({
          data: Immutable.fromJS(action.data),
          isLoading: false,
        })
      )
    case ACTIONS.DATA_FETCHED: {
      if (action.result.status === 200) {
        let data
        if (action.dataPath) {
          data = action.result.data[action.dataPath]
        } else {
          data = action.result.data[Object.keys(action.result.data)[0]]
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const key in data) {
          if (data[key] && typeof data[key] === 'string') {
            data[key] = data[key].trim()
          }
        }

        return state
          .setIn([action.formName, 'data'], Immutable.fromJS(data || {}))
          .setIn([action.formName, 'isLoading'], false)
          .setIn([action.formName, 'formJustCleared'], false)
      }

      let errorMessage = action.result.message
      if (!errorMessage) {
        errorMessage = 'There was a problem fetching the data'
      }
      return updateForm(state, action.formName, {
        isLoading: false,
        formJustCleared: false,
        errorMessage,
      })
    }
    case ACTIONS.DATA_LOADING: {
      return updateForm(
        state,
        action.formName,
        Map({
          isLoading: true,
        })
      )
    }
    case ACTIONS.VALIDATE:
      return updateForm(
        state,
        action.formName,
        Map({
          transientShouldValidate: true,
        })
      )
    case ACTIONS.STOP_VALIDATING:
      return updateForm(
        state,
        action.formName,
        Map({
          shouldValidateOnFieldChange: false,
        })
      )
    case ACTIONS.SHOW_VALIDATION_ERRORS: {
      const forms = {}
      forms[action.formName] = Map({
        validationErrors: Immutable.fromJS(action.validationErrors),
        shouldValidateOnFieldChange: action.shouldStartValidating,
        transientShouldSubmit: false,
        transientShouldValidate: false,
        errorMessage: action.errorMessage,
      })
      return state.deleteIn([action.formName, 'validationErrors']).mergeDeep(forms)
    }
    case ACTIONS.FIELDS_UPDATED: {
      const currentState = state.get(action.formName)
      if (currentState && (currentState.get('shouldSubmit') || currentState.get('isSubmitting'))) {
        // We shouldn't let fields update while we are submitting
        console.log('WARN: Tried to update field while form was submitting')
        return state
      }

      const forms = {}
      forms[action.formName] = {
        data: action.data,
      }

      let newState = state

      if (action.replace) {
        newState = newState.deleteIn([action.formName, 'data', Object.keys(action.data)[0]])
      }

      return newState.mergeDeep(forms)
    }
    case ACTIONS.FIELD_UPDATED: {
      const currentState = state.get(action.formName)
      if (currentState && (currentState.get('shouldSubmit') || currentState.get('isSubmitting'))) {
        // We shouldn't let fields update while we are submitting
        console.log('WARN: Tried to update field while form was submitting')
        return state
      }

      const dataPath = action.fieldName.split('.')
      dataPath.unshift('data')
      dataPath.unshift(action.formName)

      return state.setIn(dataPath, action.value).setIn([action.formName, 'successMessage'], null)
    }
    case ACTIONS.CLEAR_FORM: {
      let newState = updateForm(
        state,
        action.formName,
        Map({
          transientIsSubmitting: false,
          transientSubmittedResult: null,
          validationErrors: Map(),
          errorMessage: '',
          transientResult: '',
          successMessage: '',
          shouldValidateOnFieldChange: false,
          formJustCleared: true,
        })
      )

      newState = newState
        .deleteIn([action.formName, 'data'])
        .deleteIn([action.formName, 'validationErrors'])

      return newState
    }
    case ACTIONS.CLEAR_FIELDS: {
      let newState = updateForm(
        state,
        action.formName,
        Map({
          transientIsSubmitting: false,
          transientSubmittedResult: null,
          validationErrors: Map(),
          errorMessage: '',
          transientResult: '',
          successMessage: '',
          shouldValidateOnFieldChange: false,
        })
      )

      action.fields.forEach((field) => {
        newState = newState.deleteIn([action.formName, 'data', field])
      })

      return newState
    }
    case ACTIONS.SHOW_SUCCESS: {
      return updateForm(
        state,
        action.formName,
        Map({
          successMessage: action.data.message,
        })
      )
    }
    case ACTIONS.SHOW_ERROR: {
      return updateForm(
        state,
        action.formName,
        Map({
          errorMessage: action.message,
        })
      )
    }
    case ACTIONS.CLEAR_ERROR_MESSAGE: {
      return updateForm(
        state,
        action.formName,
        Map({
          errorMessage: undefined,
        })
      )
    }
    case ACTIONS.CLEAR_SUCCESS_MESSAGE: {
      return updateForm(
        state,
        action.formName,
        Map({
          successMessage: undefined,
        })
      )
    }
    case NAVIGATED_ACTION: {
      if (state && state.routing && action) {
        if (!(state.routing.location.pathname === action.payload.pathname)) {
          return initialState
        }
      }
      return state
    }
    default: {
      return state
    }
  }
}
