/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react' //eslint-disable-line
import PropTypes from 'prop-types'
import Immutable, { List, Set, Map } from 'immutable'

import { FirebaseHandler } from 'libs/firebase'

/**
 * This shows us how to construct objects from firebase,
 * Each toplevel is the name of an object. It can have 3 properties:
 * 1. firebasePath: This is either a function like:
 *    (uuid, localPath) => returns the path on firebase for that object,
 *    or simply a plain string, which will have the UUID joined to it.
 * 2. joins: This is a map of the uuid to what type of item it is.
 *    For instance in the product example below, once the product
 *    has been fetched from firebase, the skuUUID will be used to fetch stockInfo, and sku.
 * 3. subLists: This means the item will have a subList of this type of item,
 *    and will then preform the joins needed for those items.
 * 4. externalJoins: These are if the API returned some additional data that cannot be fetched
 *    in one try from Firebase, an example is the product on a stockInfo,
 *    nothing in stockInfo points to the product so we need to fetch it as an externalJoin.
 *    NOTE: these will only get used in the initial fetch, if it is used as a subsequent join,
 *    the external join will not get called.
 */
const COLLECTION_JOIN = {
  firebasePath: 'readWrite/store/collections',
  joins: {
    tileUUID: ['tile'],
  },
}

export const FIREBASE_OBJECTS = Immutable.fromJS({
  brand: COLLECTION_JOIN,
  productCategory: COLLECTION_JOIN,
  collection: COLLECTION_JOIN,
  product: {
    firebasePath: 'readOnly/store/products',
    preserves: ['variantsHaveDifferentStockAlerts'],
    joins: {
      skuUUID: ['stockInfo', 'sku'],
      tileUUID: ['tile'],
      brandUUID: ['brand'],
      productCategoryUUID: ['productCategory'],
    },
    subLists: {
      variants: 'variant',
    },
  },
  tile: {
    firebasePath: 'readOnly/store/tiles',
  },
  sku: {
    firebasePath: 'readOnly/store/skus',
  },
  stockInfo: {
    firebasePath: 'readOnly/store/stockInfos',
    preserves: ['variantChoiceValues'],
    joins: {
      skuUUID: ['sku'],
    },
    externalJoins: {
      'product.uuid': ['product'],
    },
  },
  variant: {
    joins: {
      skuUUID: ['stockInfo', 'sku'],
    },
  },
})

export default class ConnectedObjectComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      firebaseData: Map(),
      firebaseMetadata: Map(),
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.localObject !== this.props.localObject) {
      this.registerUUID(nextProps)
    }
  }

  componentDidMount() {
    // This needs to happen on a timeout otherwise set state won't work properly
    setTimeout(() => this.registerUUID(), 0)
  }

  componentWillUnmount() {
    // We need to unregister to everything
    this.state.firebaseMetadata.forEach((metaData) => {
      metaData.get('requestedItems', List()).forEach((requestedItem) => {
        const remotePath = requestedItem.split('|')[0]
        FirebaseHandler.disconnectRef(remotePath, 'value', this)
      })
    })
  }

  /**
   * This checks if we have already registered for an object at that location,
   * and if not registers for it and saves it.
   */
  registerForObject(localPath, remoteUUID, firebaseObjectKey) {
    const firebaseObject = FIREBASE_OBJECTS.get(firebaseObjectKey)
    let remotePath
    if (typeof firebaseObject.get('firebasePath') === 'function') {
      remotePath = firebaseObject.get('firebasePath')(remoteUUID, localPath)
    } else {
      remotePath = `${firebaseObject.get('firebasePath')}/${remoteUUID}`
    }

    const requestedItems = this.state.firebaseMetadata.getIn(
      [localPath.split('.')[0], 'requestedItems'],
      Set()
    )
    const requestItem = `${remotePath}|${localPath}`
    if (!requestedItems.contains(requestItem)) {
      this.setState((currentState) => {
        const newState = { ...currentState }
        const currentRequestedItems = newState.firebaseMetadata.getIn(
          [localPath.split('.')[0], 'requestedItems'],
          Set()
        )
        newState.firebaseMetadata = newState.firebaseMetadata.setIn(
          [localPath.split('.')[0], 'requestedItems'],
          currentRequestedItems.add(requestItem)
        )

        return newState
      })

      const prop = `${localPath}|${firebaseObjectKey}`

      const result = FirebaseHandler.registerForRef(prop, remotePath, undefined, 'value', this)
      if (result.connected) {
        this.handleFirebaseUpdate(prop, Immutable.fromJS(result.initialData || {}), undefined)
      }
    }
  }

  /**
   *  This gets called every-time the table data gets updated.
   *  We look for any new uuid's and look them up in firebase
   *  We don't actually care about un-registering old uuid's at this point.
   *  We might as well stay up to date with their.
   *  data as we are likely to see them again, think about searching and then clearing the search
   */
  registerUUID(nextProps) {
    const props = nextProps || this.props

    if (props.localObject) {
      props.firebaseJoin.entrySeq().forEach(([key, firebaseConfigKey]) => {
        const uuid = props.localObject.getIn(key.split('.'))
        const firebaseConfig = FIREBASE_OBJECTS.get(firebaseConfigKey)
        // Register for the default object
        this.registerForObject(uuid, uuid, firebaseConfigKey)
        // Sometimes we have some external joins returned by the API that we must also register on
        // These will be in the format {'product.uuid': 'product'}
        firebaseConfig
          .get('externalJoins', Map())
          .entrySeq()
          .forEach(([externalKey, externalFirebaseConfigKeyList]) => {
            // The local path is the UUID of this item + the first part of the external join key,
            // ie product
            const localPath = `${uuid}.${externalKey.split('.')[0]}`
            // Our join points to where the remoteUUID is stored.
            // We have to register these joins now, as we will loose
            // this uuid as soon as our initial join finishes
            const remoteUUID = props.localObject.getIn(externalKey.split('.'))
            externalFirebaseConfigKeyList.forEach((externalFBConfigKey) => {
              this.registerForObject(localPath, remoteUUID, externalFBConfigKey)
            })
          })
      })
    }
  }

  /**
   * This recursive method checks that we have fetched all the pieces of data we care for
   */
  testSatisfied(firebaseObject, item, testExternal = false) {
    if (!firebaseObject) {
      return true
    }
    if (!item) {
      return false
    }

    // testExternal means this is a top level test,
    // which means if our firebase identifier is not set, we received a blank
    // update from FB, and we should wait for the top level item
    // to be written to FB before continuing.
    if (testExternal && !item.get(this.getFirebaseIdentifierKey())) {
      return false
    }

    const testSubLists = firebaseObject
      .get('subLists', Map())
      .entrySeq()
      .map(([listKey, configKey]) => {
        const subList = item.getIn(listKey.split('.'), List())
        const testSubList = subList.map((subListItem) => {
          return this.testSatisfied(FIREBASE_OBJECTS.get(configKey), subListItem)
        })
        return testSubList.filter((entry) => entry === false).first() === undefined
      })

    const subListsSatisfied = testSubLists.filter((entry) => entry === false).first() === undefined

    const joinsSatisfied =
      firebaseObject
        .get('joins', List())
        .entrySeq()
        .map(([joinKey, joinList]) => {
          if (item.get(joinKey)) {
            return (
              joinList
                .map((join) => {
                  return this.testSatisfied(FIREBASE_OBJECTS.get(join), item.get(join))
                })
                .filter((entry) => entry === false)
                .first() === undefined
            )
          }

          // If we don't have the join key we don't join anything,
          // ie we can't join an sku if we have no skuUUID
          return true
        })
        .filter((entry) => entry === false)
        .first() === undefined

    let externalJoinsSatisfied = true
    if (testExternal) {
      // We only test external joins on the base object
      externalJoinsSatisfied =
        firebaseObject
          .get('externalJoins', List())
          .entrySeq()
          .map(([joinKey, joinList]) => {
            if (item.get(joinKey.split('.')[0])) {
              return (
                joinList
                  .map((join) => {
                    return this.testSatisfied(FIREBASE_OBJECTS.get(join), item.get(join))
                  })
                  .filter((entry) => entry === false)
                  .first() === undefined
              )
            }

            // If we don't have the join key we don't join anything,
            // ie we can't join an sku if we have no skuUUID
            return false
          })
          .filter((entry) => entry === false)
          .first() === undefined
    }

    return subListsSatisfied && joinsSatisfied && externalJoinsSatisfied
  }

  handleFirebaseUpdate(prop, data, error) {
    if (error) {
      console.log('Error loading firebase:', prop, error)
    }

    // Split prop into localPath and firebaseObjectKey
    const [localPath, firebaseObjectKey] = prop.split('|')
    const localPathArray = localPath.split('.')
    const firebaseObject = FIREBASE_OBJECTS.get(firebaseObjectKey)

    this.setState((currentState) => {
      const newState = { ...currentState }
      const oldData = newState.firebaseData.getIn(localPathArray, Map())
      newState.firebaseData = newState.firebaseData.setIn(localPathArray, data)
      // We need to ensure if this is a new update,
      // and we had already updated the insides, we retain the insides
      firebaseObject
        .get('joins', Map())
        .entrySeq()
        .forEach(([joinKey, joinList]) => {
          // For each join replace what we had already loaded if UUID is unchanged
          if (oldData.get(joinKey) === data.get(joinKey)) {
            joinList.forEach((join) => {
              const newArray = Immutable.fromJS(localPathArray).push(join)
              newState.firebaseData = newState.firebaseData.setIn(
                newArray.toJS(),
                oldData.get(join)
              )
            })
          }
        })
      // We need to restore external joins on base object
      if (localPathArray.length === 1) {
        firebaseObject
          .get('externalJoins', Map())
          .entrySeq()
          .forEach(([, joinList]) => {
            // Here we don't care about UUID's changing,
            // because we don't have access to the UUID anymore
            joinList.forEach((join) => {
              const newArray = Immutable.fromJS(localPathArray).push(join)
              // Only preserve it if we actually have data
              if (oldData.get(join)) {
                newState.firebaseData = newState.firebaseData.setIn(
                  newArray.toJS(),
                  oldData.get(join)
                )
              }
            })
          })
      }

      // If we have any preserves, lets do them
      if (firebaseObject.get('preserves')) {
        // We might have to preserve it from our local object
        firebaseObject.get('preserves', List()).forEach((preserveKey) => {
          const newArray = Immutable.fromJS(localPathArray).push(preserveKey)
          // Either get the value from what we've already received from FB,
          // or set it from what core gave us
          const preserveValue =
            oldData.get(preserveKey) || (this.props.localObject || Map()).get(preserveKey)
          if (preserveValue) {
            newState.firebaseData = newState.firebaseData.setIn(newArray.toJS(), preserveValue)
          }
        })
      }

      firebaseObject
        .get('subLists', Map())
        .entrySeq()
        .forEach(([listKey, configKey]) => {
          const oldSubList = oldData.getIn(listKey.split('.'), List())
          const firebaseObjectConfig = FIREBASE_OBJECTS.get(configKey)
          oldSubList.forEach((oldSubListItem, index) => {
            // If our item hasn't changed
            if (oldSubListItem.get('uuid') === data.getIn([listKey, index], Map()).get('uuid')) {
              if (firebaseObjectConfig.get('firebasePath')) {
                const newArray = Immutable.fromJS(localPathArray).push(listKey).push(index)
                newState.firebaseData = newState.firebaseData.setIn(newArray.toJS(), oldSubListItem)
              }
              firebaseObjectConfig
                .get('joins', Map())
                .entrySeq()
                .forEach(([joinKey, joinList]) => {
                  // Check uuid hasn't changed
                  if (oldSubListItem.get(joinKey) === data.getIn([listKey, index, joinKey])) {
                    joinList.forEach((join) => {
                      const newArray = Immutable.fromJS(localPathArray)
                        .push(listKey)
                        .push(index)
                        .push(join)
                      newState.firebaseData = newState.firebaseData.setIn(
                        newArray.toJS(),
                        oldSubListItem
                      )
                    })
                  }
                })
            }
          })
        })

      return newState
    })

    firebaseObject
      .get('joins', Map())
      .entrySeq()
      .forEach(([key, joinList]) => {
        joinList.forEach((join) => {
          const newArray = Immutable.fromJS(localPathArray).push(join)
          // No need to do this join if there is no data.get(key)
          if (data.getIn(key.split('.'))) {
            this.registerForObject(
              newArray.reduce((list, current) => `${list}.${current}`),
              data.getIn(key.split('.')),
              join
            )
          }
        })
      })

    firebaseObject
      .get('subLists', Map())
      .entrySeq()
      .forEach(([key, configKey]) => {
        const subList = data.getIn(key.split('.'), List())
        const firebaseObjectConfig = FIREBASE_OBJECTS.get(configKey)
        subList.forEach((subListItem, index) => {
          firebaseObjectConfig
            .get('joins', Map())
            .entrySeq()
            .forEach(([joinKey, joinList]) => {
              joinList.forEach((join) => {
                const remoteUUID = subListItem.get(joinKey)
                if (remoteUUID) {
                  const newArray = Immutable.fromJS(localPathArray)
                    .concat(key.split('.'))
                    .push(`${index}`)
                    .push(join)
                  this.registerForObject(
                    newArray.reduce((list, current) => `${list}.${current}`),
                    remoteUUID,
                    join
                  )
                }
              })
            })
        })
      })

    // Check if we have finished fetching this item
    if (!this.state.firebaseMetadata.getIn([localPathArray[0], 'completed'])) {
      const stateItem = this.state.firebaseData.get(localPathArray[0], Map())
      const finished =
        this.props.firebaseJoin
          .map((firebaseKey) => {
            return this.testSatisfied(FIREBASE_OBJECTS.get(firebaseKey), stateItem, true)
          })
          .filter((entry) => entry === false)
          .first() === undefined
      if (finished) {
        this.setState((previousState) => {
          return {
            firebaseMetadata: previousState.firebaseMetadata.setIn(
              [localPathArray[0], 'completed'],
              true
            ),
          }
        })
      }
    }
  }

  receivedFirebaseUpdateForProp(wasLocal, wasGlobal, prop, snapshot, mergedData, error) {
    this.handleFirebaseUpdate(prop, Immutable.fromJS(mergedData || {}), error)
  }

  getFirebaseIdentifierKey() {
    return this.props.firebaseJoin.entrySeq().first()[0] || 'uuid'
  }

  getConnectionStatus() {
    const itemIdentifier = this.props.localObject.get(this.getFirebaseIdentifierKey())
    return !!this.state.firebaseMetadata.getIn([itemIdentifier, 'completed'])
  }

  getConnectedObject() {
    if (!this.props.localObject) {
      return undefined
    }

    const itemIdentifier = this.props.localObject.get(this.getFirebaseIdentifierKey())
    if (this.getConnectionStatus()) {
      return this.state.firebaseData.get(itemIdentifier)
    }
    return this.props.localObject
  }

  render() {
    return this.props.render(this.getConnectedObject())
  }
}

/*
 * An example of what a join would look like
 * Immutable.fromJS({uuid: 'product'});
 * This means that we will use the uuid of each list item,
 * to go fetch a product from firebase as per FIREBASE_OBJECTS
 */

ConnectedObjectComponent.propTypes = {
  firebaseJoin: PropTypes.object.isRequired,
  localObject: PropTypes.object,
  render: PropTypes.func.isRequired,
}
