const getAddressComponent = (place, name) => {
  if (place.address_components) {
    const match = place.address_components.filter((component) => component.types.includes(name))[0]
    if (match) {
      if (name === 'country') {
        return {
          country: match.long_name,
          countryCode: match.short_name,
        }
      }
      return match.long_name
    }

    if (!match && name) {
      return undefined
    }
  }
  return {
    latitude: place.geometry.location.lat(),
    longitude: place.geometry.location.lng(),
  }
}

const handleSelectedAddress = (
  address,
  placesService,
  updateAddressFields,
  clearAutocompleteOptions
) => {
  placesService.getDetails(
    {
      placeId: address.place_id,
      fields: ['address_components', 'geometry'],
    },
    (place) => {
      const addressDetails = {
        firstLine: address.structured_formatting.main_text,
        secondLine: getAddressComponent(place, 'sublocality'),
        postalCode: getAddressComponent(place, 'postal_code'),
        city: getAddressComponent(place, 'locality'),
        cityGroup: getAddressComponent(place, 'administrative_area_level_2'),
        province: getAddressComponent(place, 'administrative_area_level_1'),
        country: getAddressComponent(place, 'country').country,
        latitude: getAddressComponent(place).latitude,
        longitude: getAddressComponent(place).longitude,
        countryCode: getAddressComponent(place, 'country').countryCode,
      }
      if (addressDetails) {
        updateAddressFields(addressDetails)
      }
      clearAutocompleteOptions()
    }
  )
}

const dismissPlacesSuggestionsOnOutsideClick = (
  isFocused,
  placesSuggestions,
  clearAutoCompleteOptions
) => {
  if (!isFocused && placesSuggestions.length > 0) {
    setTimeout(() => clearAutoCompleteOptions([]), 1000)
  }
}

const getPlacesField = () => {
  let placesField = null
  placesField = document.getElementById('places')
  return placesField
}

const updatePlacesPredictions = (autocompleteService, setAutoCompleteOptions) => {
  const placesInputField = getPlacesField()
  if (placesInputField) {
    const input = placesInputField.value
    if (autocompleteService && input && input.length > 3) {
      autocompleteService.getPlacePredictions(
        {
          input,
          componentRestrictions: { country: 'za' },
        },
        (autoCompleteOptions) => setAutoCompleteOptions(autoCompleteOptions || [])
      )
    } else {
      setAutoCompleteOptions([])
    }
  }
}

const onPlacesFieldFocus = (isFocused, onFocus) => {
  onFocus(isFocused)
}

const onPlacesFieldBlur = (isNotFocused, onBlur) => {
  onBlur(isNotFocused)
}

export {
  getAddressComponent,
  handleSelectedAddress,
  dismissPlacesSuggestionsOnOutsideClick,
  updatePlacesPredictions,
  onPlacesFieldFocus,
  onPlacesFieldBlur,
}
