import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { get } from 'lodash'

import { track } from 'libs/analytics'
import { classes as formClasses, EmailValidator, MobileValidator } from 'libs/forms'
import { PopupComponent } from 'libs/modals'
import popupClasses from 'libs/modals/popups.module.scss'
import CheckboxButton from 'components/buttons/CheckboxButton'
import FlatButton from 'components/buttons/FlatButton'
import { List, ListItem } from 'libs/list'
import { callInvoicesJSONAPI } from 'libs/api'
import LoadingView from 'components/loaders/LoadingView'
import successImage from 'assets/images/success-green-icon@2x.png'
import { If } from 'libs/formats'
import Alert from 'components/notifications/Alert'

import classes from './Popup.module.scss'

const emailValidator = new EmailValidator()
const mobileValidator = new MobileValidator()

class InvoiceSendPopup extends PopupComponent {
  state = {
    channel: 'email',
  }

  componentDidMount() {
    this.setState({
      email: this.props.invoice.getIn(['customerData', 'email']) || '',
      phone: this.props.invoice.getIn(['customerData', 'phone']) || '',
    })
  }

  handleEmailChange(nextValue) {
    this.setState({
      email: nextValue,
      emailError: emailValidator.validate(nextValue),
    })
  }

  handlePhoneChange(nextValue) {
    this.setState({
      phone: nextValue,
      phoneError: mobileValidator.validate(nextValue),
    })
  }

  handleSendInvoice() {
    const { invoice } = this.props
    const { channel, email, phone } = this.state

    const emailError = emailValidator.validate(email)
    const phoneError = mobileValidator.validate(phone)

    if (emailError || phoneError) {
      this.setState({
        emailError,
        phoneError,
      })
      return
    }

    this.setState({
      sent: undefined,
      error: undefined,
      sending: true,
    })

    callInvoicesJSONAPI(`invoices/${invoice.get('id')}/send`, 'POST', {
      channel,
      recipient: channel === 'email' ? email : phone,
      platform: 'portal',
    })
      .then(() => {
        this.setState({
          sending: false,
          sent: true,
        })
      })
      .catch(({ jqXHR }) => {
        this.setState({
          sending: false,
          error: get(jqXHR, ['responseJSON', 'errors', '0'], 'Failed to send invoice'),
        })
      })
  }

  getContent() {
    const { sending, sent } = this.state

    if (sent) {
      return this.renderDone()
    }

    if (sending) {
      return <LoadingView message='Sending invoice ...' />
    }

    return this.renderForm()
  }

  renderForm() {
    const { channel, email, emailError, phone, phoneError, error } = this.state
    const canSend = (channel === 'email' && email) || (channel === 'sms' && phone)

    return (
      <>
        <div className={classNames(classes.title, popupClasses.popupTitle)}>Send</div>
        <If condition={!!error}>
          <Alert
            className={classes.alert}
            message={error}
            messageType='danger'
            onClose={() => this.setState({ error: undefined })}
          />
        </If>
        <List hasOutlineBorders className={classNames(classes.list, formClasses.form)}>
          <ListItem
            className={formClasses.radioButtonField}
            accessoryLeft={
              <CheckboxButton
                selected={channel === 'email'}
                onClick={() => {
                  track('invoices_send_email_radio_button_clicked', {
                    name: 'invoices_send_email_radio_button_clicked',
                  })
                  this.setState({ channel: 'email' })
                }}
              />
            }
            title='Email'
            subTitle={
              channel === 'email' ? (
                <input
                  className={classNames(classes.field, formClasses.field)}
                  value={email}
                  onChange={(e) => this.handleEmailChange(e.target.value)}
                  placeholder='Enter email ...'
                />
              ) : (
                email || 'Enter email ...'
              )
            }
          >
            {channel === 'email' && emailError && (
              <div className={classes.fieldError}>{emailError}</div>
            )}
          </ListItem>
          <ListItem
            className={formClasses.radioButtonField}
            accessoryLeft={
              <CheckboxButton
                selected={channel === 'sms'}
                onClick={() => {
                  track('invoices_send_sms_radio_button_clicked', {
                    name: 'invoices_send_sms_radio_button_clicked',
                  })
                  this.setState({ channel: 'sms' })
                }}
              />
            }
            title='SMS'
            subTitle={
              channel === 'sms' ? (
                <input
                  className={classNames(classes.field, formClasses.field)}
                  value={phone}
                  onChange={(e) => this.handlePhoneChange(e.target.value)}
                  placeholder='Enter phone number ...'
                />
              ) : (
                phone || 'Enter phone number ...'
              )
            }
          >
            {channel === 'sms' && phoneError && (
              <div className={classes.fieldError}>{phoneError}</div>
            )}
          </ListItem>
        </List>
        <div className={popupClasses.actions}>
          <FlatButton label='Cancel' onClick={this.props.onEsc} />
          <FlatButton
            label='Send'
            className='blueBackground'
            disabled={!canSend}
            onClick={() => {
              track('invoices_send_send_button_clicked', {
                name: 'invoices_send_send_button_clicked',
              })

              if (canSend) {
                this.handleSendInvoice()
              }
            }}
          />
        </div>
      </>
    )
  }

  renderDone() {
    const customerName = this.props.invoice.getIn(['customerData', 'fullName'])

    return (
      <>
        <div className={popupClasses.popupImageContainer}>
          <img src={successImage} alt='Invoice Sent!' style={{ maxWidth: 64 }} />
        </div>
        <div className={popupClasses.popupTitle}>Invoice Sent!</div>
        <div className={popupClasses.popupSubtitle}>
          You’ve successfully sent an invoice <br />
          {customerName ? ` to ${customerName}` : ''}
        </div>
        <div className={popupClasses.actions}>
          <FlatButton
            label='Done'
            className='blueBackground'
            onClick={() => {
              track('invoices_send_invoice_sent_done_button_clicked', {
                name: 'invoices_send_invoice_sent_done_button_clicked',
              })
              this.props.onSent()
            }}
          />
        </div>
      </>
    )
  }
}

InvoiceSendPopup.propTypes = {
  invoice: PropTypes.object.isRequired,
  onSent: PropTypes.func,
}

export default InvoiceSendPopup
