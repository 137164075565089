/* eslint-disable no-unused-expressions */
import create from 'zustand'

import { callJSONApi } from 'libs/api'

const useReferralStore = create((set) => ({
  referralCode: '',
  referralLink: '',
  rewardAmount: undefined,
  rewardCurrency: undefined,
  tpvThreshold: undefined,
  fetchReferralCode: () => {
    callJSONApi(
      `/incentives/referral/referralCode`,
      'GET',
      {},
      (response) => {
        response?.data?.referralCode ? set({ referralCode: response.data.referralCode }) : null
        response?.data?.referralLink ? set({ referralLink: response.data.referralLink }) : null
        response?.data?.referralProgram?.rewardAmount
          ? set({ rewardAmount: response.data.referralProgram.rewardAmount })
          : null
        response?.data?.referralProgram?.rewardCurrency
          ? set({ rewardCurrency: response.data.referralProgram.rewardCurrency })
          : null
        response?.data?.referralProgram?.tpvThreshold
          ? set({ tpvThreshold: response.data.referralProgram.tpvThreshold })
          : null
      },
      (error) => {
        console.log('Error fetching referral code. ', error)
      },
      true,
      false
    )
  },
  referrals: undefined,
  fetchReferrals: () => {
    callJSONApi(
      `/incentives/referral/referrals`,
      'GET',
      {},
      (response) => {
        response?.data?.referrals ? set({ referrals: response?.data?.referrals }) : null
      },
      (error) => {
        console.log('Error fetching referrals. ', error)
      },
      true,
      false
    )
  },
  amountEarned: undefined,
  amountAwarded: undefined,
  fetchSummary: () => {
    callJSONApi(
      `/incentives/referral/summary`,
      'GET',
      {},
      (response) => {
        response?.data?.amountEarned
          ? set({ amountEarned: response?.data?.amountEarned })
          : set({ amountEarned: 0 })
        response?.data?.amountAwarded
          ? set({ amountAwarded: response?.data?.amountAwarded })
          : set({ amountAwarded: 0 })
      },
      (error) => {
        console.log('Error fetching referral summary. ', error)
      },
      true,
      false
    )
  },
  clearReferralStore: () => {
    set({
      referralCode: '',
      referralLink: '',
      rewardAmount: undefined,
      rewardCurrency: undefined,
      tpvThreshold: undefined,
      referrals: undefined,
      amountEarned: undefined,
      amountAwarded: undefined,
    })
  },
}))

export default useReferralStore
