/* eslint-disable @typescript-eslint/no-shadow */
import React, { useCallback, useEffect, useMemo } from 'react'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import PropTypes from 'prop-types'

import FlatButton from 'components/buttons/FlatButton'
import Alert from 'components/notifications/Alert'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import BlockHeader from 'components/headers/BlockHeader'
import Spacer from 'ui/layout/Spacer'
import { formatCamelCaseToSentenceCase } from 'libs/utils'
import {
  fetchUserUpdateRequestByToken,
  confirmUserUpdateRequest,
  selectErrorMessage,
  selectUpdatedFields,
} from 'redux/modules/profile'
import elevatePermissions from 'components/elevatePermissions'

function ConfirmUserDetailsPage({
  routeParams,
  updatedFields,
  errorMessage,
  onConfirmComplete,
  onConfirmUserUpdateRequest,
  onFetchUserUpdateRequestByToken,
}) {
  const token = useMemo(() => routeParams?.hashid, [routeParams?.hashid])

  const confirmDetails = useCallback(() => {
    onConfirmUserUpdateRequest(token, onConfirmComplete)
  }, [onConfirmComplete, onConfirmUserUpdateRequest, token])

  useEffect(() => {
    if (token) {
      onFetchUserUpdateRequestByToken(token)
    }
  }, [token, onFetchUserUpdateRequestByToken, onConfirmComplete])

  return (
    <MaxWidthBlock>
      <BlockHeader title='Confirm your new user details' />

      {errorMessage ? <Alert messageType='danger'>{errorMessage}</Alert> : null}

      {updatedFields ? (
        <div>
          <p>
            We received a request to update your user details. If you approve this change, your new
            user details will be
          </p>
          <p>
            {updatedFields.map((field) => (
              <>
                <b>{formatCamelCaseToSentenceCase(field[0])}: </b> {field[1]} <br />
              </>
            ))}
          </p>
          <p>
            For your security, you are required to approve this change. If you have made a mistake,
            please&nbsp;
            <Link to='/profile'>edit your user details</Link> again.
          </p>

          <FlatButton className='blueBackground' onClick={confirmDetails}>
            Confirm user details
          </FlatButton>

          <Spacer size='medium' />

          <p>
            If you did not request changes to your user details and believe someone may have
            accessed your account, we recommend you&nbsp;
            <Link to='/profile'>change your password</Link>
            &nbsp;immediately and review your security.
            <br />
            <br />
            <a href='https://support.yoco.help/s/article/What-to-do-if-you-think-your-account-has-been-compromised'>
              What to do if you think your account has been compromised
            </a>
          </p>
        </div>
      ) : null}
    </MaxWidthBlock>
  )
}

ConfirmUserDetailsPage.propTypes = {
  routeParams: PropTypes.object,
  onConfirmComplete: PropTypes.func,
  errorMessage: PropTypes.string,
  updatedFields: PropTypes.array,
  onConfirmUserUpdateRequest: PropTypes.func,
  onFetchUserUpdateRequestByToken: PropTypes.func,
}

const mapStateToProps = (state) => ({
  errorMessage: selectErrorMessage(state),
  updatedFields: selectUpdatedFields(state),
})

const mapDispatchToProps = (dispatch) => ({
  onConfirmComplete: () => dispatch(push('/profile')),
  onFetchUserUpdateRequestByToken: (token) => dispatch(fetchUserUpdateRequestByToken(token)),
  onConfirmUserUpdateRequest: (token, onConfirmComplete) =>
    dispatch(confirmUserUpdateRequest(token, onConfirmComplete)),
})

export default elevatePermissions(
  connect(mapStateToProps, mapDispatchToProps)(ConfirmUserDetailsPage)
)
