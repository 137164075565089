/* eslint-disable no-param-reassign */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fromJS } from 'immutable'

import * as forms from 'libs/forms'
import { track } from 'libs/analytics'
import store from 'redux/store'
import { callInvoicesJSONAPI } from 'libs/api'
import { submitResult, showSuccess, showError } from 'redux/modules/forms'

import { selectBankAccount } from 'redux/modules/onboarding/bankDetails/selectors'
import { FormName, FormFieldNames, FormSubmissionFlags } from './constants'
import renderFormFields from './renderFormFields'
import { useInitialiseFormValues } from './Create.hooks'

function CreateInvoice({ bankingDetails, onCreated }) {
  useInitialiseFormValues()

  return (
    <forms.Form
      name={FormName}
      noWrap
      onSubmit={(data) => {
        const { dispatch } = store

        // Store and reset the flag
        const isSavedAsDraft = FormSubmissionFlags.saveAsDraft
        FormSubmissionFlags.saveAsDraft = false
        if (!isSavedAsDraft) {
          track('invoices_new_invoice_next_button_clicked', {
            name: 'invoices_new_invoice_next_button_clicked',
          })
        }

        data = data
          .set('customerId', data.getIn([FormFieldNames.Customer, 'id']))
          // eslint-disable-next-line radix
          .set(FormFieldNames.DueIn, parseInt(data.get(FormFieldNames.DueIn) || '0'))

        if (Object.keys(bankingDetails).length > 0) {
          data = data.set('bankingDetails', bankingDetails.delete('accountHolder'))
        }

        callInvoicesJSONAPI('invoices', 'POST', data)
          .then((response) => {
            dispatch(submitResult(FormName, response.data))
            dispatch(showSuccess(FormName, { message: 'Invoice successfully saved!' }))
            onCreated?.(fromJS(response.data), isSavedAsDraft)
          })
          .catch(() => {
            dispatch(submitResult(FormName, {}))
            dispatch(showError(FormName, 'Failed to save invoice!'))
          })
      }}
      scrollToTop={false}
      showLoader
      loaderClass='spinner-container'
      showSuccess
      showError
    >
      {renderFormFields(true)}
    </forms.Form>
  )
}

CreateInvoice.propTypes = {
  bankingDetails: PropTypes.object,
  onCreated: PropTypes.func,
}

export default connect((state) => ({
  bankingDetails: selectBankAccount(state),
}))(CreateInvoice)
