import React from 'react'

import generalStyles from 'components/capital/capital.module.scss'
import styles from './CapitalHelpMessage.module.scss'

const CapitalHelpMessage: React.FunctionComponent = () => {
  return (
    <p className={generalStyles.supportCopy} data-testid='CapitalHelpMessage'>
      Not sure what you&apos;re looking for or have a question?&nbsp;
      <a
        className={styles.link}
        href='https://support.yoco.help/s/article/What-is-Yoco-Capital'
        rel='noopener noreferrer'
        target='_blank'
      >
        Click for more info
      </a>
    </p>
  )
}

export default CapitalHelpMessage
