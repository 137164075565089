import React from 'react'
import PropTypes from 'prop-types'
import { FORM_NAMES } from 'redux/modules/onboarding'
import { clearForm, initializeForm } from 'redux/modules/forms'
import { connect } from 'react-redux'
import { selectBankAccount } from 'redux/modules/onboarding/bankDetails/selectors'
import EditBankDetailsForm from './EditBankDetailsForm'
import UpdateStateMessageAlert from './UpdateStateMessageAlert'

function EditBankDetailsContent({ onSuccess, clearForms, initializeForms, bankAccount }) {
  const [isFormEnabled, setIsFormEnabled] = React.useState(false)

  const onFormLoaded = (response) => {
    if (!response.data.bankAccount) {
      setIsFormEnabled(true)
    }
  }

  const onCancelButtonClick = () => {
    initializeForms(FORM_NAMES.BANK_DETAILS, bankAccount)
    setIsFormEnabled(false)
  }

  const onEditButtonClick = () => {
    clearForms(FORM_NAMES.BANK_DETAILS)
    setIsFormEnabled(true)
  }

  return (
    <>
      <UpdateStateMessageAlert
        onEditButtonClick={onEditButtonClick}
        isFormEnabled={isFormEnabled}
      />

      <EditBankDetailsForm
        onSuccess={onSuccess}
        onLoad={onFormLoaded}
        onCancelButtonClick={onCancelButtonClick}
        isEnabled={isFormEnabled}
      />
    </>
  )
}

EditBankDetailsContent.propTypes = {
  onSuccess: PropTypes.func,
  clearForms: PropTypes.func.isRequired,
  initializeForms: PropTypes.func.isRequired,
  bankAccount: PropTypes.object.isRequired,
}

EditBankDetailsContent.defaultProps = {
  onSuccess: undefined,
}

const mapStateToProps = (state) => ({
  bankAccount: selectBankAccount(state),
})

const mapDispatchToProps = (dispatch) => ({
  clearForms: (formName) => dispatch(clearForm(formName)),
  initializeForms: (formName, data) => dispatch(initializeForm(formName, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditBankDetailsContent)
