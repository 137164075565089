export const FormName = 'invoiceSingle'

export const FormSubmissionFlags = {
  saveAsDraft: false,
}

export const FormFieldNames = {
  Customer: 'customerData',
  BillEntries: 'billEntries',
  DueIn: 'dueIn',
  Note: 'note',
}
