import React from 'react'
import classNames from 'classnames/bind'
import Progress from 'components/progress'
import FlatButton from 'components/buttons/FlatButton'
import { prettyPrintNumber, getPercentage, formatCurrencyNoDecimals } from 'libs/utils'
import styles from './CapitalCashAdvanceOverviewBlock.module.scss'
import CapitalDetailsBlock from '../CapitalDetailsBlock'

const cx = classNames.bind(styles)

interface Props {
  totalAmountPaid: number
  totalAmountPayable: number
  estimatedTerm: number
  processingFee: number
  totalPayable: number
  principalAmount: number
  repaymentPercentage: number
  contractURL: string
}

const CapitalCashAdvanceOverviewBlock: React.FunctionComponent<Props> = ({
  totalAmountPaid,
  principalAmount,
  totalAmountPayable,
  estimatedTerm,
  processingFee,
  totalPayable,
  repaymentPercentage,
  contractURL,
}) => {
  const totalAmountPaidPercentage = getPercentage(totalAmountPaid, totalAmountPayable)

  const renderAmountInfo = (amountType: string, greyIndicator = false) => {
    const amount = amountType === 'paid' ? totalAmountPaid : totalAmountPayable - totalAmountPaid
    return (
      <div className={styles.amountBlock}>
        <div className={styles.indicatorBlock}>
          <div className={cx('indicator', { grey: greyIndicator })} />
        </div>
        <div className={styles.amountInfoBlock}>
          <p>{`R ${prettyPrintNumber(amount)}`}</p>
          <p className={styles.amountType}>{amountType}</p>
        </div>
      </div>
    )
  }

  const renderButtons = () => {
    return (
      <div className={styles.downloadButtonCover}>
        <div className={styles.downloadButtonContainer}>
          <FlatButton
            className={styles.downloadButton}
            label='Download Contract'
            href={contractURL}
            target='_blank'
          />
        </div>
        <div className={styles.viewContractLink}>
          <a href={contractURL} className={styles.link} target='_blank' rel='noopener noreferrer'>
            View Contract
          </a>
        </div>
      </div>
    )
  }

  const renderAcceptedOfferSummary = () => {
    if (estimatedTerm) {
      return (
        <div className={styles.acceptOfferPageSummary}>
          <div className={styles.blocksContainer}>
            <div className={styles.amountBlock}>
              <p>
                You are
                <span className={styles.percentage}> {totalAmountPaidPercentage}%</span> through.
              </p>
              <p>
                {`You have paid ${formatCurrencyNoDecimals(totalAmountPaid)} of `}
                <span className={styles.amount}>
                  {formatCurrencyNoDecimals(totalAmountPayable)}
                </span>
              </p>
            </div>
            <CapitalDetailsBlock
              repaymentPercentage={repaymentPercentage}
              estimatedTerm={estimatedTerm}
              processingFee={processingFee}
              totalPayable={totalPayable}
            />
            {renderButtons()}
          </div>
        </div>
      )
    }
    return <span />
  }

  const progressColor = '#00AEE4'
  const progressCircleInnerLabel = (
    <div className={styles.indicatorTextContainer}>
      <h1>{formatCurrencyNoDecimals(principalAmount)}</h1>
      <p>Your total capital</p>
    </div>
  )
  return (
    <div>
      <div className={styles.container} aria-label='CapitalRepaymentOverview'>
        <div className={styles.progress}>
          <Progress
            theme={{
              default: {
                symbol: progressCircleInnerLabel,
                trailColor: '#CFD8DC',
                color: progressColor,
              },
              active: {
                symbol: progressCircleInnerLabel,
                trailColor: '#CFD8DC',
                color: progressColor,
              },
              success: {
                symbol: progressCircleInnerLabel,
                color: progressColor,
              },
            }}
            style={{ width: '100%', height: 187.5 }}
            symbolClassName='capital'
            type='circle'
            percent={totalAmountPaidPercentage}
          />
        </div>
        <div className={styles.overview}>
          <div className={styles.overviewDetailsBlock}>
            {renderAmountInfo('paid')}
            {renderAmountInfo('outstanding', true)}
            {principalAmount ? renderButtons() : null}
          </div>
        </div>
      </div>
      {renderAcceptedOfferSummary()}
    </div>
  )
}

export default CapitalCashAdvanceOverviewBlock
