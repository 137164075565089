import $ from 'jquery'
import React from 'react'
import PropTypes from 'prop-types'

import { getToken } from 'libs/api'
import { PopupComponent } from 'libs/modals'
import { makeTestID } from 'libs/utils'

import popupClasses from 'libs/modals/popups.module.scss'
import FlatButton from 'components/buttons/FlatButton'
import LoadingView from 'components/loaders/LoadingView'

import PopupSuccessView from './Views/Success'
import PopupFailureView from './Views/Failure'

const Status = {
  Confirm: 'confirm',
  Deleting: 'deleting',
  Deleted: 'deleted',
  Failure: 'failure',
}

class DeleteConfirmation extends PopupComponent {
  state = {
    status: Status.Confirm,
  }

  handleDelete = () => {
    this.setState({ status: Status.Deleting })
    $.ajax({
      url: this.props.url,
      method: 'DELETE',
      headers: {
        'X-Auth-Token': getToken(),
      },
    })
      .done(() => {
        this.setState({ status: Status.Deleted })
      })
      .fail(() => {
        this.setState({ status: Status.Failure })
      })
  }

  renderConfimationPrompt() {
    return (
      <div>
        <div className={popupClasses.popupTitle}>{this.props.title}</div>
        <div className={popupClasses.popupSubtitle}>{this.props.subtitle}</div>
        <div>
          <FlatButton
            label='Cancel'
            type='reset'
            onClick={() => this.props.onCancel()}
            ariaLabel={makeTestID('delete-confirmation', 'cancel')}
          />
          <span />
          <FlatButton
            label='Delete'
            title='Delete'
            className='destructive'
            ariaLabel={makeTestID('delete-confirmation', 'delete')}
            onClick={() => this.handleDelete()}
          />
        </div>
      </div>
    )
  }

  getContent() {
    const { status } = this.state

    switch (status) {
      case Status.Deleting:
        return <LoadingView message={this.props.loadingMessage} />

      case Status.Deleted:
        return (
          <PopupSuccessView
            title={this.props.successMessageTitle}
            subtitle={this.props.successMessageSubtitle}
            buttonLabel='Done'
            onDismiss={this.props.onSuccess}
          />
        )

      case Status.Failure:
        return (
          <PopupFailureView
            title={this.props.failureMessageTitle}
            subtitle={this.props.failureMessageSubtitle}
            buttonLabel='OK'
            onDismiss={this.props.onFailure}
          />
        )

      default:
        return this.renderConfimationPrompt()
    }
  }
}

DeleteConfirmation.defaultProps = {
  showing: true,
  title: 'Delete',
  subtitle: 'Are you sure you want to delete this?',
  successMessageTitle: 'Deleted',
  successMessageSubtitle: 'Successfully deleted!',
  failureMessageTitle: 'Failed',
  failureMessageSubtitle: 'Oops, we were unable to delete this item. Please try again',
}

DeleteConfirmation.propTypes = {
  url: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  loadingMessage: PropTypes.string,
  failureMessageTitle: PropTypes.string,
  failureMessageSubtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  successMessageTitle: PropTypes.string,
  successMessageSubtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  showing: PropTypes.bool,
}

export default DeleteConfirmation
