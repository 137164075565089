/* eslint-disable react/jsx-no-constructed-context-values */
import { Icon } from '@yoco/design-system-icons/dist/react'
import FlatButton from 'components/buttons/FlatButton'
import { List } from 'immutable'
import React from 'react'
import { RouteContext, RouteDispatchContext } from './RouteContext'
import { UserInfoScreen, PermissionScreen } from '../StaffForm'
import classes from '../StaffManagement.module.scss'

type StaffRoutesType = {
  roleDescriptions: List<[]>
  toggleSidebar: () => void
}

const WithSubmitButton = (WrappedComponent, backText?: string, onBack?: () => void) => {
  const { dispatch } = React.useContext(RouteDispatchContext)

  return (
    <WrappedComponent>
      <div className={classes.actionButtons}>
        <FlatButton onClick={onBack ?? dispatch('back')} type='button'>
          {backText ?? 'Next'}
        </FlatButton>
        <FlatButton className='blueBackground' type='submit'>
          Next
        </FlatButton>
      </div>
    </WrappedComponent>
  )
}

const StaffRoutes = ({ roleDescriptions, toggleSidebar }: StaffRoutesType): JSX.Element => {
  const { dispatch } = React.useContext(RouteDispatchContext)
  const state = React.useContext(RouteContext)

  const renderRoute = React.useCallback(() => {
    switch (state.currentRoute) {
      case 'userInfo':
        return WithSubmitButton(UserInfoScreen, 'Cancel', toggleSidebar)

      case 'userPermissions':
        return <PermissionScreen roleDescriptions={roleDescriptions} />

      default:
        return ''
    }
  }, [roleDescriptions, state.currentRoute, toggleSidebar])

  return (
    <>
      <div className={classes.sideContainerHeader}>
        <div className={classes.header}>
          <button type='button' onClick={() => dispatch({ type: 'back' })}>
            {state.currentRoute === 'userInfo' ? (
              <Icon name='close' size={24} color='black' />
            ) : (
              <Icon name='arrow-left' size={24} color='black' />
            )}
          </button>
          <h4 className={classes.title}>Add Staff</h4>
        </div>
        <h5>{state.routeIndex}/4</h5>
      </div>
      {renderRoute()}
    </>
  )
}

export default StaffRoutes
