import ROLES from 'libs/roles'
import YoyoPage from 'containers/marketplace/yoyo/YoyoPage'

export default function getRoutes() {
  return {
    path: 'mk/',
    text: 'Yoyo',
    requiresOneRole: [ROLES.MANAGE_BUSINESS],
    hideInMenu: true,
    routes: [
      {
        path: 'yoyo',
        text: 'Yoyo',
        component: YoyoPage,
        hideInMenu: true,
      },
    ],
  }
}
