import PropTypes from 'prop-types'

import { CheckBoxField } from './switch'
import classes from './forms.module.scss'

export default class RadioButtonField extends CheckBoxField {
  getDivClassName() {
    const className = super.getDivClassname()
    return `${className} ${classes.checkboxField} ${classes.radioButtonField}`
  }

  onClick(value) {
    super.onClick(value)
    if (this.props.value) return
    // if we have unchecked the value of a radio button,
    // there is no need to check the value of the linkedFields
    if (this.props.linkedFields) {
      this.props.linkedFields.forEach((linkedField) => {
        this.props.form.onFieldChange(linkedField, this.props.value)
      })
    }
  }
}

RadioButtonField.propTypes = {
  linkedFields: PropTypes.array,
}
