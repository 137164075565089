export const dueInSelectOptions = [
  {
    label: 'Upon Receipt',
    value: 0,
  },
  {
    label: 'Due in 7 days',
    value: 7,
  },
  {
    label: 'Due in 14 days',
    value: 14,
  },
  {
    label: 'Due in 21 days',
    value: 21,
  },
]
