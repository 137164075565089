/* eslint-disable no-nested-ternary */
import React from 'react'
import classNames from 'classnames'
import { Icon } from '@yoco/design-system-icons/dist/react'

import colors from 'ui/colors'
import ContentCard from 'components/cards/ContentCard'

import referralsCountIcon from '../images/referrals-count.svg'
import useReferralStore from '../store'

import classes from './referrals.module.scss'

const useStore = () => ({
  amountEarned: useReferralStore((state) => state?.amountEarned),
  amountAwarded: useReferralStore((state) => state?.amountAwarded),
  referrals: useReferralStore((state) => state?.referrals),
})

function ReferralSummary() {
  const { amountAwarded, referrals } = useStore()

  const referredCount = referrals?.filter(
    (referral) => referral.status !== 'declined' && referral.status !== 'Declined'
  ).length

  return (
    <div className={classNames(classes.summaryContainer)}>
      <ContentCard>
        <div className={classNames(classes.summaryContentWrapper)}>
          <div className={classNames(classes.amountAwardedSummary)}>
            {amountAwarded !== 0 ? (
              <Icon name='check-circle' size={22} color={colors['Brand-Blue']} />
            ) : (
              <span style={{ height: '32px' }} />
            )}
            <h1 className={classNames(classes.amountAwardedValue)}>{`R${amountAwarded || 0}`}</h1>
            <p
              className={
                amountAwarded !== 0
                  ? classes.amountAwardedLabel
                  : classNames(classes.amountAwardedLabel, classes.noReferralsText)
              }
            >
              Paid out to you
            </p>
          </div>
          <div className={classNames(classes.secondarySummariesWrapper)}>
            {/* <div className={classNames(classes.secondarySummaryDetails)}>
              <img
                className={classes.referralIcon}
                src={referralsPendingIcon}
                alt='Referrals pending icon'
              />
              <h2 className={classNames(classes.secondarySummaryValue)}>
                {`R${amountEarned ? amountEarned : 0}`}
              </h2>
              <p className={classNames(classes.secondarySummaryLabel)}>Pending</p>
            </div> */}
            <div className={classNames(classes.secondarySummaryDetails)}>
              <img
                className={classes.referralIcon}
                src={referralsCountIcon}
                alt='People referred icon'
              />
              <h2 className={classNames(classes.secondarySummaryValue)}>
                {typeof referredCount === 'number'
                  ? referredCount === 0
                    ? '0'
                    : String(referredCount).padStart(2, '0')
                  : '0'}
              </h2>
              <p className={classNames(classes.secondarySummaryLabel)}>Referred</p>
            </div>
          </div>
        </div>
      </ContentCard>
    </div>
  )
}

export default ReferralSummary
