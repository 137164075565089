/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unused-class-component-methods */
import React from 'react'
import $ from 'jquery'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { If } from 'libs/formats'
import ActionButtonBar from 'components/buttons/ActionButtonBar'
import SplitHeader from 'components/headers/SplitHeader'
import { submitForm, clearForm } from 'redux/modules/forms'

import classes from './blocks.module.scss'

class SplitBlock extends React.Component {
  constructor(props) {
    super(props)
    this.splitBlockContentRef = React.createRef()
  }
  onDismiss() {
    if (this.props.onDismiss) {
      this.props.onDismiss()
    }

    if (this.props.formName && !this.props.persistData) {
      this.props.clearForm(this.props.formName)
    }
  }

  getHeader() {
    if (!this.props.hideHeader) {
      if (this.props.headerRenderer) {
        return this.props.headerRenderer()
      }

      return <SplitHeader title={this.props.header} closeSplitView={() => this.onDismiss()} />
    }

    return undefined
  }

  isToggled() {
    return this.props.showingSplit
  }

  scrollSplitToTop() {
    if (this.splitBlockContentRef && this.splitBlockContentRef.current) {
      $(this.splitBlockContentRef.current).animate({ scrollTop: '0' }, 'fast')
    }
  }

  renderActionButtonBar() {
    if (!this.props.hideActionButtonBar) {
      return (
        <ActionButtonBar
          saveLabel={this.props.saveLabel}
          cancelPressed={() => {
            if (this.props.cancelPressed) {
              this.props.cancelPressed()
            } else {
              this.onDismiss()
            }
          }}
          cancelLabel={this.props.cancelLabel}
          showDelete={this.props.showDelete}
          deleteLabel={this.props.deleteLabel}
          showSave={this.props.showSave}
          showCancel={this.props.showCancel}
          savePressed={() => {
            this.scrollSplitToTop()
            if (this.props.savePressed) {
              this.props.savePressed()
            } else if (this.props.formName) {
              this.props.submitForm(this.props.formName)
            } else {
              console.error('You have not specified savePressed or a form name, action ignored')
            }
          }}
          deletePressed={() => {
            if (this.props.deletePressed) {
              this.props.deletePressed()
            }
          }}
        />
      )
    }

    return undefined
  }

  renderSplitContent() {
    let className = classes.splitView
    if (!this.props.showingSplit) {
      className = `${className} ${classes.gone}`
    }

    if (this.props.className) {
      className += ` ${this.props.className}`
    }

    let splitContent
    if (this.props.renderSplitContent) {
      splitContent = this.props.renderSplitContent()
    }

    if (this.oldSplit !== splitContent) {
      this.scrollSplitToTop()
    }

    this.oldSplit = splitContent

    return (
      <div
        className={className}
        style={this.props.style}
        /* eslint-disable-next-line react/no-string-refs */
      >
        <div
          ref={this.splitBlockContentRef}
          className={classes.content}
          style={this.props.contentStyle}
        >
          {this.getHeader()}
          <div>{splitContent}</div>
        </div>
        {this.renderActionButtonBar()}
      </div>
    )
  }

  render() {
    return (
      <div className={`${classes.outerBlock}`} style={{ overflowX: 'hidden' }}>
        {this.props.children}
        {this.renderSplitContent()}
        <If condition={this.props.showingSplit}>
          <div className={classes.backdrop} onClick={() => this.onDismiss()} />
        </If>
      </div>
    )
  }
}

export default connect(
  undefined,
  (dispatch) => ({
    submitForm: (formName) => dispatch(submitForm(formName)),
    clearForm: (formName) => dispatch(clearForm(formName)),
  }),
  undefined
)(SplitBlock)

SplitBlock.defaultProps = {
  persistData: false,
}

SplitBlock.propTypes = {
  formName: PropTypes.string,
  header: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  onDismiss: PropTypes.func,
  showingSplit: PropTypes.bool,
  renderSplitContent: PropTypes.func,
  style: PropTypes.object,
  showDelete: PropTypes.bool,
  showSave: PropTypes.bool,
  hideHeader: PropTypes.bool,
  headerRenderer: PropTypes.func,
  hideActionButtonBar: PropTypes.bool,
  contentStyle: PropTypes.object,
  showCancel: PropTypes.bool,
  cancelLabel: PropTypes.string,
  cancelPressed: PropTypes.func,
  deleteLabel: PropTypes.string,
  deletePressed: PropTypes.func,
  saveLabel: PropTypes.string,
  savePressed: PropTypes.func,
  persistData: PropTypes.bool,
  submitForm: PropTypes.func,
  clearForm: PropTypes.func,
}
