import React from 'react'

import classes from './account.module.scss'

function TermsAcceptedPage() {
  return (
    <div className={classes.unsubscribeBlock}>
      <br />
      <p style={{ fontSize: '22px' }}>Thank you for signing up with Yoco.</p>
      <br />
      <p>Your order is being processed we will send you updates through email.</p>
      <br />
    </div>
  )
}

export default TermsAcceptedPage
