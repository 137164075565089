import React from 'react'
import PropTypes from 'prop-types'

import FlatButton from 'components/buttons/FlatButton'
import popupClasses from 'libs/modals/popups.module.scss'

function PopupResult({
  title,
  subtitle,
  image,
  buttonLabel,
  buttonClassname,
  buttonType,
  onDismiss,
}) {
  return (
    <>
      <div className={popupClasses.popupImageContainer}>
        <img src={image} alt={title} style={{ maxWidth: 64 }} />
      </div>
      <div className={popupClasses.popupTitle}>{title}</div>
      <div className={popupClasses.popupSubtitle}>{subtitle}</div>
      <div className={popupClasses.actions}>
        <FlatButton
          label={buttonLabel}
          type={buttonType}
          className={buttonClassname}
          onClick={() => onDismiss?.()}
        />
      </div>
    </>
  )
}

PopupResult.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  image: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonType: PropTypes.string,
  buttonClassname: PropTypes.string,
  onDismiss: PropTypes.func,
}

export default PopupResult
