import { formatParamsString, getParamAs, getSearchParam, getUrlParams } from 'libs/invoices/utils'

import { getCustomerFilter, getDateFilter, getPaymentMethodFilter, getStatusFilter } from './utils'

export const buildInvoiceUrlParams = (url, tableProps) => {
  const { batch } = tableProps

  const tableUrlParams = getUrlParams(url)

  const params = {
    ...getParamAs(tableUrlParams, 'pageSize', 'limit'),
    ...getParamAs(tableUrlParams, 'offset', 'offset'),
    ...getPaymentMethodFilter(batch),
    ...getStatusFilter(batch),
    ...getCustomerFilter(batch),
    ...getDateFilter(batch),
    ...getSearchParam(batch),
  }

  return formatParamsString(params)
}
