/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import lodash from 'lodash'
import FileUploadProgress from 'react-fileupload-progress'
import { connect } from 'react-redux'
import { Progress } from 'react-sweet-progress'
import 'react-sweet-progress/lib/style.css'
import Spinner from 'react-activity/lib/Spinner'
import 'react-activity/lib/Spinner/Spinner.css'
import * as Sentry from '@sentry/react/'
import { Map, List } from 'immutable'
import { refreshTable } from 'redux/modules/tables'
import { callJSONApi } from 'libs/api'
import Alert from 'components/notifications/Alert'
import ErrorAccordion from 'components/accordions/ErrorAccordion'
import { checkIfMobileDevice, getENV, objectKeysToLowerCase } from 'libs/utils'
import { PopupComponent } from 'libs/modals'
import CONSTANTS from 'libs/constants'
import uploadIcon from 'assets/images/uploadIcon.png'
import tick from 'assets/images/tick.png'
import {
  changeScreen,
  selectFile,
  fileUploaded,
  uploadResponse,
  resetState,
} from 'redux/modules/imports'

import FlatButton from '../buttons/FlatButton'

import classes from './popups.module.scss'

const TABLE_NAME = 'storeProducts'

const defaultState = {
  busyUploading: false,
  counter: 0,
  currentCount: 0,
  productCount: 0,
  rowCount: 0,
  errorCount: 0,
  completedProgress: 0,
  openRow: 0,
  errors: false,
  fileUUID: null,
  importUUID: null,
  errorMsg: null,
  progressChecked: 0,
  progressTimer: undefined,
}

const DEVELOPMENT = getENV('NODE_ENV') === 'development'

class UploadProductsPopup extends PopupComponent {
  constructor(props) {
    super(props)
    this.state = defaultState
  }

  refreshTable() {
    this.props.dispatch(refreshTable(TABLE_NAME))
  }

  escPressed() {
    if (this.props.onEsc && this.props.showing) {
      this.props.onEsc()
      this.cancelUpload()
    }
    this.refreshTable()
  }

  onProgress(value) {
    const busyUploading = value > 0
    if (this.state.busyUploading !== busyUploading) {
      this.setState({
        busyUploading,
      })
    }
  }

  screenChange(screen) {
    this.props.dispatch(changeScreen(screen))
  }

  setFileUploadUUID(uuid) {
    this.setState({
      fileUUID: uuid,
    })
  }

  cancelUpload() {
    this.setState(defaultState)
    this.props.dispatch(resetState())
  }

  updateFileUploaded(status, uuid) {
    this.props.dispatch(fileUploaded(status, uuid))
  }

  popoverStyles() {
    if (this.props.screen === 'preview-screen') {
      return { width: '80%' }
    }
    return null
  }

  setErrorMsg(msg) {
    this.setState({
      errorMsg: msg,
    })
  }

  displayError() {
    if (this.state.errorMsg) {
      return (
        <Alert
          message={this.state.errorMsg}
          messageType='danger'
          onClose={() => {
            this.setState({ errorMsg: false })
          }}
        />
      )
    }
    return null
  }

  customFormRenderer(onSubmit) {
    if (this.state.busyUploading && !this.props.fileUploaded && !this.state.errorMsg) {
      return <div />
    }

    return (
      <div>
        <div className='modalBody'>
          <h1>Upload and save your products</h1>
          <FlatButton
            className='blueBackground'
            style={{ width: '100%' }}
            href={`/files/productsTemplate.csv?${new Date().getTime()}`}
            rel='noopener noreferrer'
            target='_blank'
            label='Download CSV template (optional)'
          />
          <hr className={classes.hrText} data-content='OR' />
          {this.displayError()}
          <div className={classes.dragDropHolder}>
            <img
              src={uploadIcon}
              alt='upload icon'
              style={{ height: '160px', position: 'relative', marginTop: '40px' }}
            />
            <p
              style={{
                position: 'relative',
                marginTop: '10px',
                marginBottom: '80px',
                display: 'block',
              }}
            >
              Drag and drop your file here,
              <br /> or <span className={classes.upload}>upload from your computer</span>
            </p>
            <form id='uploadFile'>
              <input
                type='file'
                name='file'
                accept='.xls, .xlsx, .csv'
                className={classes.inputStyling}
                onChange={(event) => {
                  this.handleUpload(event, onSubmit)
                }}
              />
            </form>
          </div>
        </div>
      </div>
    )
  }

  heading() {
    return (
      <div className='modalHeader' style={{ alignSelf: 'center' }}>
        <h5>IMPORT PRODUCTS</h5>
      </div>
    )
  }

  formGetter() {
    const data = new FormData(document.getElementById('uploadFile'))
    data.append('file', this.props.file)
    return data
  }

  dataURLToBlob(dataURL) {
    const BASE64_MARKER = ';base64,'
    if (dataURL.indexOf(BASE64_MARKER) === -1) {
      const parts = dataURL.split(',')
      const contentType = parts[0].split(':')[1]
      const raw = parts[1]

      return new Blob([raw], { type: contentType })
    }

    const parts = dataURL.split(BASE64_MARKER)
    const contentType = parts[0].split(':')[1]
    const raw = window.atob(parts[1])
    const rawLength = raw.length

    const uInt8Array = new Uint8Array(rawLength)

    for (let i = 0; i < rawLength; i += 1) {
      uInt8Array[i] = raw.charCodeAt(i)
    }

    return new Blob([uInt8Array], { type: contentType })
  }

  handleUpload(event, onSubmit) {
    const file = event.target.files[0]
    const reader = new FileReader()

    reader.onload = (readerEvent) => {
      const data = readerEvent.target.result
      const blob = this.dataURLToBlob(data)

      this.props.dispatch(selectFile(blob))
      onSubmit(event)
    }

    reader.onerror = function (err) {
      console.log('Something went wrong, err:', err)
    }

    reader.readAsDataURL(file)
  }

  renderProgressCircle(progress) {
    return (
      <div className={classes.progressBG}>
        <Progress
          theme={{
            active: {
              color: CONSTANTS.YOCO_BLUE,
            },
            success: {
              symbol: `${progress}%`,
              color: CONSTANTS.GREEN,
            },
          }}
          type='circle'
          percent={progress}
          width={200}
          strokeWidth={7}
        />
      </div>
    )
  }

  renderInfiniteProgressCircle() {
    if (this.state.completedProgress) {
      return this.renderProgressCircle(this.state.completedProgress.toFixed(0))
    }

    return (
      <div className={classes.infiniteProgressBG}>
        <Spinner size={90} color={CONSTANTS.GREEN} />
      </div>
    )
  }

  renderCompleteScreen() {
    return (
      <div>
        <h1>Upload and save your products</h1>
        <div className={classes.progressHolder}>
          {this.renderProgressCircle(100)}
          <p className={classes.completeText}>
            UPLOAD COMPLETE
            <img
              src={tick}
              alt='tick'
              style={{
                height: '10px',
                position: 'relative',
                marginLeft: '4px',
                marginTop: '-3px',
              }}
            />
          </p>
        </div>
        <FlatButton
          label='View products'
          className='blueBackground'
          style={{ width: '100%', marginTop: '10px' }}
          onClick={() => {
            this.escPressed()
          }}
        />
      </div>
    )
  }

  renderInfiniteProgress() {
    return (
      <div>
        <h1>Upload and save your products</h1>
        <div className={classes.progressHolder}>
          {this.renderInfiniteProgressCircle()}
          <p className={classes.loadingText}>This may take a while, hang tight...</p>
        </div>
      </div>
    )
  }

  renderValidationProgress() {
    let subText
    switch (this.state.validationStepName) {
      case 'notStarted':
        subText = 'Inspecting file...'
        break
      case 'checkProductVariants':
        subText = 'Checking variants...'
        break
      case 'checkSKUs':
        subText = 'Checking SKUs...'
        break
      case 'checkNames':
        subText = 'Checking names...'
        break
      case 'saveResults':
        subText = 'Finalizing...'
        break
      default:
        subText = 'Calculating...'
        break
    }
    return (
      <div>
        <h1>Upload and save your products</h1>
        <div className={classes.progressHolder}>
          {this.renderInfiniteProgressCircle()}
          <p className={classes.loadingText}>Validating your file, hang tight...</p>
          <p className={classes.subText}>{subText}</p>
        </div>
      </div>
    )
  }

  renderProgressScreen(progress, hasError, cancelHandler) {
    if ((!this.props.fileUploaded && progress > 0) || progress > 0) {
      return (
        <div>
          <h1>Upload and save your products</h1>
          <div className={classes.progressHolder}>
            {this.renderProgressCircle(progress)}
            <p className={classes.loadingText}>This may take a while, hang tight...</p>
          </div>
          <FlatButton
            label='Cancel Upload'
            style={{ width: '100%', marginTop: '10px' }}
            onClick={() => {
              this.cancelUpload()
              cancelHandler()
            }}
          />
        </div>
      )
    }

    return <div />
  }

  returnHeaderName(headerName) {
    if (this.props && this.props.uploadResponse) {
      const fileData = (this.props.uploadResponse || Map()).toJS()
      const index = fileData.data.camelCaseHeaders.indexOf(headerName)
      return fileData.data.headers[index]
    }
    return null
  }

  displayHeader() {
    if (this.props && this.props.uploadResponse) {
      const fileData = (this.props.uploadResponse || Map()).toJS()
      return (
        <tr>
          <th>Row Number</th>
          {fileData.data.headers.map((name, index) => {
            return <th key={index}>{name}</th>
          })}
        </tr>
      )
    }
    return null
  }

  checkValueForError(errors, column, index, row) {
    if (!lodash.isEmpty(errors) && errors[column]) {
      return (
        <td key={index} className={classes.fieldError}>
          <p>
            {row[column]} &nbsp;
            <i
              style={{ float: 'right', marginTop: '7px', marginRight: '2px', color: CONSTANTS.RED }}
              className='icon2-alert-2'
            />
          </p>
          <div className={classes.fieldErrorMessage}>{errors[column][0].errorMessage}</div>
        </td>
      )
    }
    return (
      <td key={index}>
        <p>{row[column]} &nbsp;</p>
      </td>
    )
  }

  displayColumns(rowObject, rowNumber) {
    const row = objectKeysToLowerCase(rowObject.row)
    const fileData = (this.props.uploadResponse || Map()).toJS()
    return (
      <tr key={rowNumber + 2}>
        <td>{row.rownumber + 2}</td>
        {fileData.data.headers
          .map((x) => x.replace(/\s/g, '').toLowerCase().replace('productid', 'slug'))
          .map((name, index) => {
            let { errors } = rowObject
            if (!lodash.isEmpty(errors)) {
              errors = errors[0]
            }
            return this.checkValueForError(objectKeysToLowerCase(errors), name, index, row)
          })}
      </tr>
    )
  }

  displayOneError(productRow, rowIndex) {
    return Object.entries(productRow.errors).map(([columnError, error]) => {
      return (
        <ErrorAccordion
          key={rowIndex + 1}
          title={`Row ${rowIndex + 1}: ${this.returnHeaderName(columnError)} - ${
            error[0].errorMessage
          }`}
        />
      )
    })
  }

  displayMultipleErrors(productRow, rowIndex) {
    const lastIndex = lodash.size(productRow.errors) - 1
    const errorWord = lodash.size(productRow.errors) > 2 ? 'errors' : 'error'
    let content = List()
    let title = ''

    Object.entries(productRow.errors).forEach(([columnError, error], index) => {
      if (index === 0) {
        title = `Row ${rowIndex + 1}: ${this.returnHeaderName(columnError)} - ${
          error[0].errorMessage
        } and ${lastIndex} more ${errorWord}`
      } else {
        content = content.push(
          <li>
            {this.returnHeaderName(columnError)} - {error[0].errorMessage}
          </li>
        )
      }
    })

    return <ErrorAccordion title={title} content={content} />
  }

  checkTotalErrors(productRow, index) {
    if (typeof productRow.errors !== 'undefined') {
      if (lodash.size(productRow.errors) > 1) {
        return this.displayMultipleErrors(productRow, index)
      }
      return this.displayOneError(productRow, index)
    }
    return null
  }

  displayErrors() {
    if (this.props && this.props.uploadResponse) {
      const fileData = (this.props.uploadResponse || Map()).toJS()
      return fileData.data.products.map((i, index) => {
        return this.checkTotalErrors(i, index)
      })
    }
    return null
  }

  countErrorsProducts() {
    let errorTotal = 0
    let productTotal = 0
    if (this.props && this.props.uploadResponse) {
      const fileData = (this.props.uploadResponse || Map()).toJS()
      productTotal = fileData.data.totalRows
      Object.keys(fileData.data.products).forEach((row) => {
        if (typeof fileData.data.products[row].errors !== 'undefined') {
          errorTotal += lodash.size(fileData.data.products[row].errors)
        }
      })
    }
    this.setState({
      errorCount: errorTotal,
      productCount: productTotal,
    })
  }

  displayRows() {
    if (this.props && this.props && this.props.uploadResponse) {
      const fileData = (this.props.uploadResponse || Map()).toJS()

      return fileData.data.products.map((row, index) => {
        return this.displayColumns(row, index)
      })
    }
    return null
  }

  showErrorBlock() {
    if (this.state && this.state.errorCount > 0 && checkIfMobileDevice()) {
      return (
        <div className={classes.errorBlock}>
          <h4>Errors</h4>
          <div className={classes.errorBlockInner}>{this.displayErrors()}</div>
        </div>
      )
    }
    return null
  }

  getErrorIcon() {
    const icon = this.state.errorCount > 0 ? 'icon2-alert-2' : 'icon2-check-circle-2'
    const iconColor = this.state.errorCount > 0 ? '#FE6162' : '#42af5f'
    return (
      <i
        style={{ position: 'absolute', marginTop: '2px', marginLeft: '5px', color: iconColor }}
        className={icon}
      />
    )
  }

  renderPreviewScreen() {
    const data = this.props.uploadResponse.get('data')
    let screen = <div />
    if (data) {
      screen = (
        <div style={{ marginBottom: '10px' }}>
          <h1>CSV preview</h1>
          <div className={classes.csvPreviewHolder}>
            <div style={{ margin: '8px 8px 16px 0' }}>
              Total products: {this.state.productCount} | Errors found: {this.state.errorCount}{' '}
              {this.getErrorIcon()}{' '}
            </div>
            {this.showErrorBlock()}
            <div className={classes.tablePreviewHolder}>
              <table className={classes.productsTable}>
                <tbody>
                  {this.displayHeader()}
                  {this.displayRows()}
                </tbody>
              </table>
            </div>

            <div style={{ textAlign: 'right', marginTop: '20px' }}>
              <FlatButton label='Back' onClick={() => this.cancelUpload()} />
              <FlatButton
                label='Proceed'
                disabled={this.state.errorCount !== 0}
                className='blueBackground'
                onClick={() => this.uploadProducts()}
              />
            </div>
          </div>
        </div>
      )
    }

    return <div>{screen}</div>
  }

  getJsonResponse(uuid) {
    const uuidToValidate = uuid
    const url = `/product/import/${uuidToValidate}/validate`
    callJSONApi(
      url,
      'POST',
      {},
      (response) => {
        if (uuidToValidate === this.props.fileUploadedUUID) {
          // We don't care about this anymore, shouldn't retry
          return
        }
        this.setState({
          validationStepName: undefined,
        })
        if (response.status === 202) {
          if (DEVELOPMENT) {
            console.log('response', response)
          }
          this.screenChange('validating-screen')
          this.checkValidationStatus(uuid)
        }
      },
      (response) => {
        this.setErrorMsg(response)
        this.screenChange('upload-screen')
      }
    )
  }

  checkValidationStatus(uuid) {
    const uuidToValidate = uuid
    const url = `/product/import/${uuidToValidate}/validate/status`
    const interval = setInterval(() => {
      callJSONApi(
        url,
        'GET',
        {},
        (response) => {
          if (uuidToValidate === this.props.fileUploadedUUID) {
            // We don't care about this anymore, shouldn't retry
            return
          }
          if (response.status === 200) {
            this.setState({
              validationStepName: undefined,
            })
            if (DEVELOPMENT) {
              console.log('response', response)
            }
            if (response.data.failureReason) {
              this.screenChange('upload-screen')
              this.setErrorMsg(response.data.failureReason)
            } else {
              this.screenChange('loading-screen')
              if (lodash.size(response.data.products) === 0) {
                Sentry.captureEvent(`Non-empty import with no items ${response.data}`)
                this.screenChange('upload-screen')
                this.setErrorMsg(
                  `Failed to load any items from non-empty file. 
                  Check your file for issues, contact support for help.`
                )
              } else {
                this.props.dispatch(uploadResponse(response))
                this.countErrorsProducts()
                this.screenChange('preview-screen')
                this.setFileUploadUUID(uuid)
                if (!lodash.isEmpty(response.data.uuid)) {
                  this.setState({ importUUID: response.data.uuid })
                }
              }
            }
            clearInterval(interval)
          } else if (response.status === 202) {
            this.setState({
              validationStepName: response.data.validationStepName,
            })
          }
        },
        (response) => {
          this.setState({
            validationStepName: undefined,
          })
          this.setErrorMsg(response)
          this.screenChange('upload-screen')
          clearInterval(interval)
        }
      )
    }, 3000)
  }

  checkUploadProgress(url) {
    callJSONApi(
      url,
      'GET',
      {},
      (response) => {
        const productImport = response.data && response.data.productImport
        if (productImport && productImport.status === 'complete') {
          clearInterval(this.state.progressTimer)
          this.screenChange('completed-screen')
        } else if (productImport && productImport.status === 'running') {
          this.setState((prevState) => ({
            progressChecked: prevState.progressChecked + 1,
            completedProgress: (productImport.completedCount / productImport.numberOfRows) * 100.0,
          }))
        } else {
          const errorMessage = `Warning import was partially completed but failed with error: ${
            productImport.failureReason || response.message
          }`
          clearInterval(this.state.progressTimer)
          this.setErrorMsg(errorMessage)
          this.screenChange('upload-screen')
        }
      },
      (response) => {
        clearInterval(this.state.progressTimer)
        this.setErrorMsg(response)
        this.screenChange('upload-screen')
      }
    )
  }

  uploadProducts() {
    const uuid = this.state.importUUID
    const url = `/product/import/${uuid}`

    this.screenChange('loading-screen')

    callJSONApi(
      url,
      'POST',
      {},
      (response) => {
        if (response.status === 202) {
          this.setState({ progressChecked: 0, completedProgress: 0 })
          const timer = setInterval(() => {
            this.checkUploadProgress(response.data.url)
          }, 3000)
          this.setState({ progressTimer: timer })
        }
      },
      (response) => {
        this.setErrorMsg(response)
        this.screenChange('upload-screen')
      }
    )
  }

  renderUploadScreen() {
    return (
      <FileUploadProgress
        url={`${getENV('CORE_URL')}api/common/v1/files/?grouping=productCSV-upload`}
        method='POST'
        onLoad={(event, request) => {
          const response = JSON.parse(request.responseText)
          this.updateFileUploaded(true)
          this.screenChange('loading-screen')
          this.getJsonResponse(response.data.uuid)
        }}
        onProgress={(e, request, progress) => {
          this.onProgress(progress)
        }}
        progressRenderer={(progress, hasError, cancelHandler) => {
          // this.onProgress(progress);
          return this.renderProgressScreen(progress, hasError, cancelHandler)
        }}
        onError={(event, request) => {
          this.updateFileUploaded(false)
          this.setErrorMsg('Error uploading file')
          this.screenChange('upload-screen')
          if (DEVELOPMENT) {
            console.log('onError: ', { event }, { request })
          }
        }}
        onAbort={(event, request) => {
          this.updateFileUploaded(false)
          if (DEVELOPMENT) {
            console.log('onAbort: ', event, request)
          }
        }}
        beforeSend={(request) => {
          request.setRequestHeader('X-Auth-Token', window?.yocoStorage?.getItem('token'))
          return request
        }}
        formGetter={() => this.formGetter()}
        formRenderer={(onSubmit) => this.customFormRenderer(onSubmit)}
      />
    )
  }

  getContent() {
    let screen
    switch (this.props.screen) {
      case 'upload-screen':
        screen = this.renderUploadScreen()
        break
      case 'preview-screen':
        screen = this.renderPreviewScreen()
        break
      case 'completed-screen':
        screen = this.renderCompleteScreen()
        break
      case 'loading-screen':
        screen = this.renderInfiniteProgress()
        break
      case 'validating-screen':
        screen = this.renderValidationProgress()
        break
      default:
        screen = this.renderUploadScreen()
    }

    // TODO: Documentation: https://github.com/georgeosddev/react-fileupload-progress
    return (
      <div id='heading' style={{ justifyContent: 'center', alignItems: 'center', padding: '5px' }}>
        {this.heading()}
        <div id='content'>{screen}</div>
      </div>
    )
  }
}

export default connect((state) => ({
  file: state.imports.get('file'),
  screen: state.imports.get('screen'),
  uploadResponse: state.imports.get('uploadResponse'),
  fileUploaded: state.imports.get('fileUploaded', false),
  fileUploadedUUID: state.imports.get('uuid'),
}))(UploadProductsPopup)
