import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import * as forms from 'libs/forms'
import Alert from 'components/notifications/Alert'
import BlockHeader from 'components/headers/BlockHeader'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import FlatButton from 'components/buttons/FlatButton'

import {
  fetchLatestUserUpdateRequest,
  selectErrorMessage,
  selectUpdateState,
} from 'redux/modules/profile'
import { selectIsBusinessOwner } from 'redux/modules/session/selectors'
import ConfirmationRequiredAlert from './ConfirmationRequiredAlert'

function UnconnectedUserProfilePage({
  password,
  onFetchLatestUserUpdateRequest,
  updateState,
  errorMessage,
  isCurrentUserOwner,
}) {
  useEffect(() => onFetchLatestUserUpdateRequest(), [onFetchLatestUserUpdateRequest])

  const isConfirmationRequired = useMemo(
    () => updateState === 'confirmation_required' && isCurrentUserOwner,
    [updateState, isCurrentUserOwner]
  )

  return (
    <MaxWidthBlock>
      <BlockHeader title='My Account' />
      {errorMessage && <Alert messageType='danger'>{errorMessage}</Alert>}
      {isConfirmationRequired && <ConfirmationRequiredAlert />}
      <forms.Form
        fetchAPI='/user/account'
        action='/user/account'
        name='user'
        persistData
        showSuccess={!isCurrentUserOwner}
        onSuccess={onFetchLatestUserUpdateRequest}
      >
        <forms.Heading heading='Basic Information' />
        <forms.InfoText text='You can sign in with either your email address or your mobile number.' />
        <div className='clearfix'>
          <forms.ReadOnlyField
            name='firstName'
            label='First Name'
            placeholder='First Name'
            disabled
          />
          <forms.ReadOnlyField name='lastName' label='Last Name' placeholder='Last Name' disabled />
          <forms.EmailField
            name='email'
            label='Email'
            placeholder='Email'
            validators={[
              new forms.EitherOrValidator(
                'mobileNumber',
                'You must provide an email address or contact number'
              ),
              new forms.EmailValidator('You must provide a valid email address'),
            ]}
          />
          <forms.TextField
            name='mobileNumber'
            label='Mobile Number'
            placeholder='Mobile Number'
            validators={[
              new forms.EitherOrValidator(
                'email',
                'You must provide a contact number or email address'
              ),
              new forms.MobileValidator('You must provide a valid phone number'),
            ]}
          />
          <forms.TextField
            name='info.staffNumber'
            label='Staff Number'
            placeholder='Staff Number'
          />
          <forms.ReadOnlyField
            name='info.idNumber'
            label='ID Number'
            placeholder='ID Number'
            disabled
          />
        </div>
        <FlatButton
          label='Save'
          type='submit'
          className='blueBackground'
          disabled={isConfirmationRequired}
        />
      </forms.Form>
      <forms.Form action='/user/set-password' name='password' scrollToTop={false} showSuccess>
        <forms.Heading heading='Change Password' />
        <forms.InfoText
          text='To change your password, please fill in your old password
            as well as the new password you want to use.'
        />
        <div className='clearfix'>
          <forms.PasswordField
            name='oldPassword'
            placeholder='Old Password'
            validators={[new forms.RequiredValidator('You must provide your old password')]}
          />
          <forms.PasswordField
            name='password'
            placeholder='New Password'
            validators={[new forms.RequiredValidator('You must provide a new password')]}
          />
          <forms.PasswordField
            name='confimPassword'
            placeholder='Confirm Password'
            validators={[
              new forms.RequiredValidator('You must confirm the new password'),
              new forms.ConfirmPasswordValidator(password),
            ]}
          />
        </div>
        <FlatButton label='Change Password' type='submit' className='secondary' />
      </forms.Form>
    </MaxWidthBlock>
  )
}

UnconnectedUserProfilePage.propTypes = {
  onFetchLatestUserUpdateRequest: PropTypes.func,
  password: PropTypes.string,
  updateState: PropTypes.string,
  errorMessage: PropTypes.string,
  isCurrentUserOwner: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isOwner: selectIsBusinessOwner(state),
  password: state.forms.getIn(['password', 'data', 'password']),
  updateState: selectUpdateState(state),
  errorMessage: selectErrorMessage(state),
  isCurrentUserOwner: selectIsBusinessOwner(state),
})

const mapDispatchToProps = (dispatch) => ({
  onFetchLatestUserUpdateRequest: () => dispatch(fetchLatestUserUpdateRequest()),
})

const UserProfilePage = connect(mapStateToProps, mapDispatchToProps)(UnconnectedUserProfilePage)

export default UserProfilePage
