import React from 'react'

import styles from './CapitalMerchantUseBlock.module.scss'

interface Props {
  image: string
  description: string
}

const CapitalMerchantUseBlock: React.FunctionComponent<Props> = ({ image, description }) => {
  return (
    <div className={styles.container} data-testid='CapitalMerchantUseBlock'>
      <img src={image} alt='Merchant uses' className={styles.image} />
      <p className={styles.description}>{description}</p>
    </div>
  )
}

export default CapitalMerchantUseBlock
