import React from 'react'
import PropTypes from 'prop-types'

import constants from 'libs/constants'

import classes from './pos.module.scss'

function VariantName({ choiceValues }) {
  return (
    <div className={classes.variantName}>
      {choiceValues.map((choiceValue, index) => {
        const key = `${choiceValue}-${index}`
        return (
          <span key={key}>
            {index > 0 ? <span style={{ padding: '0 3px' }}>/</span> : undefined}
            <span style={{ color: constants.VARIANT_COLORS[index] }}>{choiceValue}</span>
          </span>
        )
      })}
    </div>
  )
}

VariantName.propTypes = {
  choiceValues: PropTypes.object,
}

export default VariantName
