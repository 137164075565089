import { useCallback, useEffect, useRef, useState } from 'react'

/**
 * UseState wrapper that takes a callback , wraps useEffect for state update + side effect
 * */
export function useStateCallback<T>(initialState: T): [T, (state: T, cb?: any) => any] {
  const [state, setState] = useState<T>(initialState)
  const cbRef = useRef(null) // init mutable ref container for callbacks

  const setStateCallback = useCallback((newState, cb) => {
    cbRef.current = cb
    setState(newState)
  }, [])

  useEffect(() => {
    // cb.current is `null` on initial render,
    // so we only invoke callback on state *updates*
    if (cbRef && cbRef.current && typeof cbRef.current === 'function') {
      const callback = cbRef.current as (state: any) => void
      callback(state)
      cbRef.current = null // reset callback after execution
    }
  }, [state])

  return [state, setStateCallback]
}
