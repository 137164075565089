import { LendingPartners, OfferQuote, Quote } from 'redux/modules/capital/types'

/**
 * This is used to transform OfferQuotes array into an array of offer advances.
 */
export const offerQuotesToAdvances = (offerQuotes: OfferQuote[]): number[] => {
  return offerQuotes.map((offerQuote) => offerQuote.advance)
}

/**
 * This is used to transform OfferQuotes array into an array of Quote arrays,
 * where each sub-array is sorted by repaymentPeriod.
 */
export const offerQuotesToQuotes = (offerQuotes: OfferQuote[]): Quote[][] => {
  return offerQuotes.map((offerQuote) =>
    offerQuote.quotes.sort((q1, q2) => q1.repaymentPeriod - q2.repaymentPeriod)
  )
}

/**
 * This is used to transform an array of Quotes into an array of repaymentPeriods.
 */
export const quotesToMonths = (quotes: Quote[]): number[] => {
  return quotes.map((quote) => quote.repaymentPeriod)
}

export const formatMonths = (months: number): string => {
  return `${months} ${months > 1 ? 'months' : 'month'}`
}

export const getTotalAmountRepaid = (capitalDeductions?: any[]) => (rowIndex: number): number => {
  return (
    capitalDeductions &&
    capitalDeductions.reduce(
      (accumulativeAmount, currentValue, index) =>
        index >= rowIndex ? accumulativeAmount + currentValue.get('amount') : accumulativeAmount,
      0
    )
  )
}

export const lendingPartnersInformation = {
  [LendingPartners.RetailCapital]: {
    displayName: 'Retail Capital',
    extendedDisplayName: 'Retail Capital, a division of Tyme Bank Limited,',
  },
  [LendingPartners.Lulalend]: {
    displayName: 'Lulalend',
    extendedDisplayName: 'Lulalend',
  },
}

interface GetLendingPartnerDisplayNameSettings {
  extendedDisplayName: boolean
}

const defaultSettings: GetLendingPartnerDisplayNameSettings = {
  extendedDisplayName: false,
}

export const getLendingPartnerDisplayName = (
  lendingPartner: LendingPartners,
  settings: GetLendingPartnerDisplayNameSettings = defaultSettings
): string => {
  const { extendedDisplayName = false } = settings
  return extendedDisplayName
    ? lendingPartnersInformation[lendingPartner].extendedDisplayName
    : lendingPartnersInformation[lendingPartner].displayName ?? ''
}
