/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { formatCurrency } from 'libs/utils'

import { calculateInvoiceEntryTotals } from '../Invoices'

export const buildLineItemDiscountLine = (item) => {
  if (!item) {
    return
  }

  return item.get('discountPercentage')
    ? `- ${item.get('discountPercentage')}%`
    : item.get('discount')
    ? `- ${formatCurrency(item.get('discount'), 'ZAR')}`
    : undefined
}

export const applyItemDiscount = (item, { discount = 0, discountPercentage } = {}) => {
  const { subTotal } = calculateInvoiceEntryTotals(item)

  if (discountPercentage) {
    // Make sure discountPercentage is not bigger than 100%
    discountPercentage = Math.min(discountPercentage, 100)
    discount = parseFloat((subTotal * (discountPercentage / 100)).toFixed(2))
  } else {
    discountPercentage = undefined
  }

  return item.merge({
    // Make sure final price (subTotal - discount) can't be less than 0
    discount: Math.min(discount, subTotal),
    discountPercentage,
  })
}
