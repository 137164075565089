/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { If } from 'libs/formats'
import layout from 'ui/layout/layout.module.scss'

export default function DisplayTrigger({
  children,
  contentFactory,
  shouldDisplayOnHover,
  onClick,
}) {
  const [isShowing, setIsShowing] = useState(false)
  const [isHovering, setIsHovering] = useState(false)

  return (
    <div className={layout.relative}>
      <div
        onClick={(e) => {
          setIsShowing(!isShowing)
          if (onClick) {
            onClick(e)
          }
        }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {children}
      </div>
      <If condition={isShowing || (shouldDisplayOnHover && isHovering)}>
        {contentFactory(setIsShowing)}
      </If>
    </div>
  )
}

DisplayTrigger.propTypes = {
  children: PropTypes.node,
  contentFactory: PropTypes.func,
  shouldDisplayOnHover: PropTypes.bool,
  onClick: PropTypes.func,
}

DisplayTrigger.defaultProps = {
  shouldDisplayOnHover: false,
}
