/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'

export default function AvailableAccount({ businessName, onClick }) {
  return <div onClick={onClick}>{businessName}</div>
}

AvailableAccount.propTypes = {
  businessName: PropTypes.string,
  onClick: PropTypes.func,
}
