/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import { Icon } from '@yoco/design-system-icons/dist/react'
import classNames from 'classnames'
import * as capitalActions from 'redux/modules/capital/actions'
import { userHasRequiredRoles } from 'libs/roles'
import { hasRequiredFeatures, hasFeature } from 'libs/features'
import { fetchRouteBadges, asBadgeDisplayString } from 'libs/routeBadges'
import { checkIfWindowIsResponsive, orEmptyMap, makeTestID } from 'libs/utils'
import DismissComponent from 'libs/DismissComponent'
import { clearNotifications } from 'redux/modules/notifications'
import localisation, { doesAcceptCardPayments } from 'libs/localisation/localisation'
import yocoLogo from 'assets/images/yoco.png'
import yoco from 'assets/images/yoco-logo.png'
import { visitHelpCenter, helpCenterURL } from 'libs/help'
import { putRoutesBadges, clearRouteBadge } from 'redux/modules/routeBadges'
import classes from './navbars.module.scss'
import { RouteLabel } from './RouteLabel'

const ICON_SIZE = 24

class UnconnectedMainNavBar extends DismissComponent {
  constructor(props) {
    super(props)

    this.state = {
      activeRoute: this.props.route ? this.props.route : [],
      windowIsResponsive: checkIfWindowIsResponsive(),
      showIntercom: false,
    }

    fetchRouteBadges(props)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.route !== this.props.route) {
      this.setState({
        route: nextProps.route,
      })
    }

    if (
      nextProps.rootPath !== this.props.route ||
      nextProps.secondaryPath !== this.props.secondaryPath
    ) {
      this.setState({
        activeRoute: [],
      })
    }
  }

  componentDidMount() {
    window.onresize = () => {
      const isResponsive = checkIfWindowIsResponsive()
      if (!this.state.windowIsResponsive === isResponsive) {
        this.setState({
          windowIsResponsive: isResponsive,
        })
      }
    }
  }

  userHasRequiredRolesFeaturesAndDoesAcceptCard({ user, features, localisation, route }) {
    return (
      userHasRequiredRoles(user, route) &&
      hasRequiredFeatures(features, route) &&
      doesAcceptCardPayments(localisation, route)
    )
  }

  getRoutesToRender() {
    const { user, features, localisation, routes } = this.props
    return routes.filter((route) =>
      this.userHasRequiredRolesFeaturesAndDoesAcceptCard({
        user,
        features,
        localisation,
        route,
      })
    )
  }

  getInnerRoutesToRender(route) {
    const { user, features, localisation } = this.props
    if (route && route.routes) {
      return route.routes.filter((route) =>
        this.userHasRequiredRolesFeaturesAndDoesAcceptCard({
          user,
          features,
          localisation,
          route,
        })
      )
    }

    return []
  }

  onMenuItemClick(route, routePath, forcePush = false) {
    if (
      (route.routes || []).filter((innerRoute) => !innerRoute.hideInMenu).length === 0 ||
      forcePush
    ) {
      this.onDismiss(route)
      this.props.pushRoutePath(routePath)
    }

    this.setState({
      activeRoute: route,
    })
  }

  onDismiss(route) {
    this.props.clearNotifications()
    if (route && route.onClick) {
      route.onClick(this.props)
    }
    this.setState({
      mainNavShown: false,
    })
  }

  navTag(route) {
    if (route.tag) {
      return <span className={classes.tagText}>{route.tag}</span>
    }

    // nav tag from the server
    let routeBadgeData = orEmptyMap(this.props.routeBadges).get(route.text.toLowerCase())
    if (routeBadgeData) {
      if (route.badgeTransformer) {
        routeBadgeData = route.badgeTransformer(routeBadgeData)
      } else if (route.badgeElement) {
        return route.badgeElement(routeBadgeData)
      }

      return (
        <span className={classes.tagText} style={route.badgeStyle}>
          {' '}
          {asBadgeDisplayString(routeBadgeData)}
        </span>
      )
    }
    return null
  }

  getMenuItem(route, routePath, iconClass, forcePush) {
    return (
      <div
        className={classes.menuItem}
        onClick={() => this.onMenuItemClick(route, routePath, forcePush)}
      >
        <Icon name={iconClass} size={ICON_SIZE} />
        <a
          href={routePath}
          onClick={(event) => {
            this.onMenuItemClick(route, routePath, forcePush)
            event.preventDefault()
            return false
          }}
          className={classes.menuText}
        >
          {route.text}
        </a>
        {this.navTag(route)}
        {route.label && <RouteLabel route={route.label} />}
      </div>
    )
  }

  onInnerRouteClicked(routePath) {
    switch (routePath) {
      case '/capital/offers': {
        this.props.handleCapitalOffersClicked()
        break
      }
      case '/capital/payments': {
        this.props.handleCapitalPaymentsClicked()
        break
      }
      default:
    }
  }

  renderRoutes() {
    const isResponsive = this.state.windowIsResponsive
    const routes = this.getRoutesToRender()
    const profileActiveClass = this.props.rootPath === 'profile' ? classes.active : ''

    const currentPath =
      this.state.activeRoute.length !== 0 ? this.state.activeRoute.path : this.props.rootPath

    return (
      <ul>
        {routes
          .filter((route) => !route.hideInMenu)
          .map((route) => {
            let activeRoute = []
            let className
            let innerListClass = classes.innerNavHidden
            let iconClass = route.icon
            let routePath = '/'
            if (route.path) {
              // Append the routePath with the primary route path e.g. /sales
              routePath += route.path
            }
            if (route.routes && route.routes[0].path) {
              // Append the routePath with the first secondary route path e.g. /sales/report
              routePath += `/${route.routes[0].path}`
            }

            if (
              (isResponsive && currentPath === route.path) ||
              (!isResponsive &&
                (route.path === this.props.rootPath || (!route.path && !this.props.rootPath)))
            ) {
              /*
              If window is responsive check that the state route path equals this route path
              or if window is not responsive check that the location route path equals this
              route path
             */
              className = classes.active
              activeRoute = this.state.activeRoute.length === 0 ? route : this.state.activeRoute
              innerListClass = classes.innerNavShown
              iconClass = route.icon
            }

            const innerRoutes = this.getInnerRoutesToRender(isResponsive ? route : activeRoute)
            if (route.path) {
              return (
                <li key={route.path || 'index'} className={className}>
                  {this.getMenuItem(route, routePath, iconClass, !isResponsive)}
                  <ul className={innerListClass}>
                    {(innerRoutes || [])
                      .filter((innerRoute) => !innerRoute.hideInMenu)
                      .map((innerRoute) => {
                        let innerClassName
                        let bulletClassName = classes.bullet

                        if (innerRoute.path === this.props.secondaryPath) {
                          innerClassName = classes.innerActive
                          bulletClassName += ` ${classes.active}`
                        }

                        let innerRoutePath = `/${route.path}/`
                        if (innerRoute.path) {
                          innerRoutePath += innerRoute.path
                        }
                        return (
                          <li
                            key={innerRoute.path}
                            className={classNames([innerClassName, classes.submenuItem])}
                            onClick={() => {
                              this.onDismiss()
                              this.onInnerRouteClicked(innerRoutePath)
                            }}
                          >
                            <div className={bulletClassName} />
                            <Link to={innerRoutePath}>
                              <span>{innerRoute.text}</span>
                            </Link>
                            {innerRoute.label && <RouteLabel route={innerRoute.label} />}
                          </li>
                        )
                      })}
                  </ul>
                </li>
              )
            }

            return (
              <li
                key={route.path || 'index'}
                className={className}
                onClick={() => {
                  this.onDismiss()
                }}
              >
                {this.getMenuItem(route, routePath, iconClass, !isResponsive)}
              </li>
            )
          })}
        <li>
          <div
            className={classes.menuItem}
            onClick={() => {
              this.onDismiss()
            }}
          >
            <a
              onClick={visitHelpCenter}
              href={helpCenterURL}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Icon name='support' size={ICON_SIZE} />
              <span>Get Help</span>
            </a>
          </div>
        </li>
        <li className={profileActiveClass}>
          <div
            className={classes.menuItem}
            onClick={() => {
              this.onDismiss()
            }}
          >
            <Link to='/account/'>
              <Icon name='profile' size={ICON_SIZE} />
              <span>Profile</span>
            </Link>
          </div>
        </li>
        <li>
          <div
            className={classes.menuItem}
            onClick={() => {
              this.onDismiss()
            }}
          >
            <a target='_blank' rel='noopener noreferrer' href='https://yoco.co.za/za/terms'>
              <Icon name='cheque' size={ICON_SIZE} />
              <span>Legal</span>
            </a>
          </div>
        </li>
        <li>
          <div className={classes.menuItem}>
            <Link to='/account/logout'>
              <Icon name='logout' size={ICON_SIZE} />
              <span>Logout</span>
            </Link>
          </div>
        </li>

        {this.props.showMigrate && (
          <li>
            <div
              className={`${classes.hubMenuItem} ${
                currentPath === 'newportal' ? `${classes.active}` : ''
              }`}
            >
              <Link to='/newportal'>
                <span>Switch to the new Yoco Web App</span>
                <Icon name='star' size={ICON_SIZE} />
              </Link>
            </div>
          </li>
        )}
      </ul>
    )
  }

  render() {
    let menuOverlay
    let mainHeaderNavStyle = {}
    let mainNavContainerClass = classes.mainNavContainer

    if (this.state.mainNavShown) {
      menuOverlay = classes.navbarOverlay
      mainNavContainerClass += ` ${classes.shown}`
      mainHeaderNavStyle = { position: 'fixed' }
    }

    if (this.state.windowIsResponsive) {
      return (
        <div className={mainNavContainerClass} style={mainHeaderNavStyle}>
          <div className={classes.navbarHeader}>
            <div
              onMouseDown={this.mouseDownHandler.bind(this)}
              onMouseUp={this.mouseUpHandler.bind(this)}
              className={classes.mobileNavToggle}
              onClick={() => {
                this.props.clearNotifications()
                this.setState(({ mainNavShown }) => ({
                  mainNavShown: !mainNavShown,
                }))
              }}
            >
              <Icon name='menu' size={36} />
            </div>
            <div className={classes.navbarBrand} data-testid={makeTestID('mobileBusinessName')}>
              {this.props.businessName}
            </div>
          </div>
          <div className={menuOverlay}>
            <span
              className={`nav ${classes.responsiveNavbar} navbar-right`}
              onMouseDown={this.mouseDownHandler.bind(this)}
              onMouseUp={this.mouseUpHandler.bind(this)}
            >
              {this.renderRoutes()}
            </span>
          </div>
        </div>
      )
    }

    const logo = this.props.showBrandRefresh ? yoco : yocoLogo

    return (
      <div className={classes.mainNavContainer}>
        <div className={classes.fixedContainer}>
          <img src={logo} height='71' width='140' alt='' style={{ display: 'inline' }} />
        </div>
        {this.renderRoutes()}
      </div>
    )
  }
}

UnconnectedMainNavBar.propTypes = {
  route: PropTypes.object,
  routes: PropTypes.array,
  rootPath: PropTypes.string,
  user: PropTypes.object,
  features: PropTypes.object,
  routeBadges: PropTypes.object,
  handleCapitalOffersClicked: PropTypes.func,
  handleCapitalPaymentsClicked: PropTypes.func,
}

const mapStateToProps = (state) => ({
  localisation: localisation(state),
  routeBadges: state.routeBadges,
  showAccountSwitcher: hasFeature(state, 'linkedAccounts'),
  showBrandRefresh: hasFeature(state, 'brandRefresh2024', true),
  showMigrate: hasFeature(state, 'hubMigrate2024', false),
})

const mapDispatchToProps = (dispatch) => ({
  handleCapitalOffersClicked: () => dispatch(capitalActions.offersViewedFromMainNavBar()),
  handleCapitalPaymentsClicked: () => dispatch(capitalActions.deductionPlansViewedFromMainNavBar()),
  pushRoutePath: (routePath) => dispatch(push(routePath)),
  clearNotifications: () => dispatch(clearNotifications()),
  clearCapitalRouteBadge: () => dispatch(clearRouteBadge('capital')),
  putRoutesBadges: (data) => dispatch(putRoutesBadges(data)),
})

const MainNavBar = connect(mapStateToProps, mapDispatchToProps)(UnconnectedMainNavBar)

export default MainNavBar
