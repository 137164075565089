import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'

import { RequestStatus } from 'redux/modules/helpers/requestHelper'
import LoadingView from 'components/loaders/LoadingView'
import Alert from 'components/notifications/Alert'
import PaddedContent from 'ui/layout/PaddedContent'
import { fetchBankUpdateRequest } from 'redux/modules/onboarding/bankDetails'

import Tasks from './Tasks'
import { fetchInvoiceOnboardingData } from './utils'

/**
 * HOC that renders provided component only in case when Invoices onboarding is finished,
 * othervice either redirects to onboarding route or show the tasks
 *
 * @param {*} Component
 * @returns
 */
function withOnboarding(Component) {
  function WithOnboardingWrapper({
    routeProps: { location },
    dispatch,
    onboarding,
    ...passThroughProps
  }) {
    const { loading, error, data } = onboarding

    useEffect(() => {
      dispatch(fetchBankUpdateRequest())
      fetchInvoiceOnboardingData()
    }, [dispatch])

    const onboardingTasksStatus = useMemo(() => {
      if (!data) {
        return undefined
      }

      return {
        isComplete: data.get('isOnboardingComplete'),
      }
    }, [data])

    useEffect(() => {
      if (!onboardingTasksStatus) {
        return
      }
      if (!onboardingTasksStatus.isComplete && location.pathname !== '/invoice/invoices') {
        dispatch(push('/invoice/invoices'))
      }
    }, [onboardingTasksStatus, location, dispatch])

    if (loading) {
      return <LoadingView />
    }

    if (error) {
      return (
        <PaddedContent>
          <Alert closable={false} messageType='danger' message={error} />
        </PaddedContent>
      )
    }

    if (onboardingTasksStatus) {
      if (onboardingTasksStatus.isComplete) {
        return <Component {...passThroughProps} />
      }
      if (location.pathname === '/invoice/invoices') {
        return <Tasks {...onboardingTasksStatus} />
      }
    }

    return null
  }

  WithOnboardingWrapper.propTypes = {
    routeProps: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    onboarding: PropTypes.object.isRequired,
  }

  return connect((state, routeProps) => ({
    routeProps,
    onboarding: {
      loading: state.invoices.onboarding.request.get('status') === RequestStatus.STARTED,
      error:
        state.invoices.onboarding.request.get('status') === RequestStatus.FAILURE
          ? state.invoices.onboarding.request.get('error')
          : undefined,
      data:
        state.invoices.onboarding.request.get('status') === RequestStatus.SUCCESS
          ? state.invoices.onboarding.data
          : undefined,
    },
  }))(WithOnboardingWrapper)
}

export default withOnboarding
