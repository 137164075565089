/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react'
import { connect } from 'react-redux'

import * as forms from 'libs/forms'
import FormCard from 'components/cards/FormCard'
import localisation from 'libs/localisation/localisation'
import { If } from 'libs/formats'
import { Props } from './types'

const formName = 'deliveryMethod'

const DeliveryMethodForm: React.FunctionComponent<Props> = ({
  localisation,
  deliveryMethod,
  initialData,
  onSuccess,
}) => {
  const action = initialData
    ? `/online/deliveryMethods/${initialData.uuid}`
    : '/online/deliveryMethods/'

  return (
    <forms.Form
      action={action}
      initialData={initialData}
      name={formName}
      persistData
      onSuccess={onSuccess}
    >
      <FormCard>
        <forms.SelectField
          name='deliveryMethod'
          label='Delivery Method'
          isFullWidth
          placeholder='Please select one'
          options={[
            { label: 'Collection', value: 'collection' },
            { label: 'Delivery', value: 'delivery' },
            { label: 'Courier', value: 'courier' },
            { label: 'No delivery', value: 'no_delivery' },
            { label: 'Post Office', value: 'post_office' },
            { label: 'PostNet', value: 'post_net' },
            { label: 'Digital', value: 'digital' },
            { label: 'Custom (enter your own)', value: 'custom' },
          ]}
          validators={[new forms.RequiredValidator('Please select a delivery method')]}
        />

        <If condition={deliveryMethod === 'custom'}>
          <forms.TextField
            name='name'
            label='Name'
            placeholder=''
            isFullWidth
            validators={[new forms.RequiredValidator('Please enter a name')]}
          />
        </If>

        <div>
          <forms.CurrencyTextField
            name='price'
            label='Price'
            isFullWidth
            localisation={localisation}
            validators={[
              new forms.RequiredValidator('Please enter a price - enter 0 for no delivery charge'),
            ]}
          />
        </div>

        <forms.TextField
          name='description'
          label='Description'
          description={`
            This will be shown to the user when they select this delivery method. You may want to specify delivery timelines, restrictions or any general information
          `}
          placeholder=''
          isFullWidth
        />
      </FormCard>
    </forms.Form>
  )
}

export default connect((state) => {
  return {
    localisation: localisation(state),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    deliveryMethod: state.forms.deliveryMethod,
  }
})(DeliveryMethodForm)
