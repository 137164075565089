import React from 'react'
import classnames from 'classnames'

import classes from './cards.module.scss'

interface Props {
  children: React.ReactNode
  type?: string
}

const ContentCard: React.FunctionComponent<Props> = ({ children, type = 'default' }) => {
  return <div className={classnames([classes.contentCard, classes[type]])}>{children}</div>
}

export default ContentCard
