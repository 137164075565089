import React from 'react'

import Spinner from 'components/loaders/Spinner'

import useReferralStore from '../store'

import classes from './referrals.module.scss'
import CommissionsTable from './CommissionsTable'

const useStore = () => ({
  referrals: useReferralStore((state) => state?.referrals),
})

function Commissions() {
  const { referrals } = useStore()

  const isReferralsLoaded = typeof referrals === 'object'

  return isReferralsLoaded ? (
    <CommissionsTable />
  ) : (
    <Spinner blue className={classes.commissionsSpinner} />
  )
}

export default Commissions
