import React, { useState } from 'react'

import FlatButton from 'components/buttons/FlatButton'
import { formatCurrency } from 'libs/utils'
import LoadingView from 'components/loaders/LoadingView'
import CapitalHelpVideo from 'components/capital/partials/CapitalHelpVideo'
import qualifiedImage from 'assets/images/success-green-icon@2x.png'
import SubHeading from 'ui/components/SubHeading'
import Spacer from 'ui/layout/Spacer'
import styles from './CapitalQualifiedBlock.module.scss'

interface Props {
  uptoAmount: number
  uuid: string
  onCreateApplication: (
    prelimOfferUUID: string,
    { capitalAmount }: { capitalAmount: number }
  ) => void
  onFindOutMoreClicked: () => void
}

const CapitalQualifiedBlock: React.FunctionComponent<Props> = ({
  uptoAmount,
  uuid,
  onCreateApplication,
  onFindOutMoreClicked,
}) => {
  const [showLoading, setShowLoading] = useState(false)

  const toggleLoading = () => {
    setShowLoading((prevLoadingState) => !prevLoadingState)
  }

  const handleCreateApplication = () => {
    toggleLoading()
    onCreateApplication(uuid, { capitalAmount: uptoAmount })
  }

  return (
    <div className={styles.container}>
      <img src={qualifiedImage} alt='Partner' />
      <div className={styles.text}>
        <SubHeading>Congratulations</SubHeading>
        <p className={styles.description}>
          You have been pre-approved for a cash amount of up to{' '}
          <span className={styles.amount}>{formatCurrency(uptoAmount, 'R', false, 0)}</span>
        </p>
      </div>
      <div className={styles.buttons}>
        <FlatButton
          label='View your offer'
          onClick={handleCreateApplication}
          type='submit'
          className={styles.viewOfferButton}
        />
        <LoadingView message='Fetching your capital application' test={showLoading} />
        <Spacer size='medium' />
        <CapitalHelpVideo onFindOutMoreClicked={onFindOutMoreClicked} />
      </div>
    </div>
  )
}

export default CapitalQualifiedBlock
