import React, { Component } from 'react'
import PropTypes from 'prop-types'

import classes from 'libs/filters/filters.module.scss'
import { PERFORMANCE_PERIODS } from 'redux/modules/sales/performance/index'

export default class DatePeriodFilter extends Component {
  fetchResultsForSelectedPeriod = (event) => {
    this.props.fetchResultsForPeriod(event.target.value)
  }

  render() {
    return (
      <div>
        <div className={classes.responsiveTitle}>Sales Performance report for</div>
        <div>
          <select
            onChange={this.fetchResultsForSelectedPeriod}
            defaultValue={this.props.currentPeriod}
          >
            <option value={PERFORMANCE_PERIODS.YEAR}>This year</option>
          </select>
        </div>
      </div>
    )
  }
}

DatePeriodFilter.defaultProps = {
  currentPeriod: PERFORMANCE_PERIODS.YEAR,
}

DatePeriodFilter.propTypes = {
  currentPeriod: PropTypes.string,
  fetchResultsForPeriod: PropTypes.func,
}
