import React from 'react'
import PropTypes from 'prop-types'

import classes from './transactions.module.scss'

function TransactionTypeIcon(props) {
  let iconClass = props.defaultIcon || ''

  if (props.transaction && props.transaction.get('transactionSimpleState') && !props.defaultIcon) {
    const transactionSimpleState = props.transaction.get('transactionSimpleState')
    iconClass = transactionSimpleState.get('typeIcon', transactionSimpleState.get('getIcon'))
  }

  return (
    <div className={classes.transactionTypeIcon}>
      <i className={iconClass} />
    </div>
  )
}

TransactionTypeIcon.propTypes = {
  transaction: PropTypes.object,
  defaultIcon: PropTypes.string,
}

export default TransactionTypeIcon
