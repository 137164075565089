import { Map } from 'immutable'
import { createSelector } from 'reselect'

const profileNode = (state) => state.profile

export const selectUserUpdateRequest = createSelector(
  profileNode,
  (profile) => profile.get('userUpdateRequest') || Map()
)

export const selectUUID = createSelector(selectUserUpdateRequest, (userUpdateRequest) =>
  userUpdateRequest.get('uuid')
)
export const selectUpdateState = createSelector(selectUserUpdateRequest, (userUpdateRequest) =>
  userUpdateRequest.get('updateState')
)

export const selectErrorMessage = createSelector(selectUserUpdateRequest, (userUpdateRequest) =>
  userUpdateRequest.get('errorMessage')
)

export const selectUpdatedFields = createSelector(selectUserUpdateRequest, (userUpdateRequest) => {
  const updatedFields = userUpdateRequest.get('updateUserFields') || {}
  return Object.entries(updatedFields)
})

export const selectConfirmationSentTo = createSelector(
  selectUserUpdateRequest,
  (userUpdateRequest) => {
    const confirmationDetails = userUpdateRequest.get('confirmationDetails')
    return confirmationDetails.confirmationSentTo
  }
)

export const selectReferenceNumber = createSelector(selectUserUpdateRequest, (userUpdateRequest) =>
  userUpdateRequest.get('referenceNumber')
)
