import React from 'react'

import styles from './BlockHeader.module.scss'

interface Props {
  title
  actions?: React.ReactNode
  style?: React.CSSProperties
  children?: React.ReactNode
}

const BlockHeader: React.FunctionComponent<Props> = ({ title, actions, style, children }) => {
  const renderActions = () => actions

  return (
    <div className={styles.container} style={style} data-testid='BlockHeader'>
      <h1>{title}</h1>
      {children}
      <div className={styles.actionContainer}>{renderActions()}</div>
    </div>
  )
}

export default BlockHeader
