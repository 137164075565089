import { callJSONApi } from 'libs/api'

export function fetchRouteBadges(props) {
  callJSONApi(
    '/business/routeBadges',
    'GET',
    {},
    (response) => {
      props.putRoutesBadges(response.data)
    },
    (prettyError) => {
      console.log('Unable to fetch routes badges', prettyError)
    }
  )
}

export function asBadgeDisplayString(input) {
  let result = input

  if (`${input}`.length > 3) {
    if (typeof input === 'number' && input > 0) {
      if (parseInt(input, 10) >= 100) {
        result = '99+'
      } else {
        result = `${`${parseInt(input, 10)}   `.substr(0, 2)} +`
      }
    } else {
      result = `${input}`.substr(0, 3)
    }
  }
  return result
}
