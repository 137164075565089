import React from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'

import classes from './settlements.module.scss'

function SettlementOnboardingWarning({ style, isInApp }) {
  return (
    <div className={classes.settlementOnboardingWarningWrapper} style={style}>
      <div className={classes.settlementOnboardingWarning}>
        <span>
          <strong>Please Note:</strong>
          We can only settle you for card payments once your bank account and ID has been verified.
          <br />
          <br />
          {isInApp ? (
            // temporary fix while we cant update yocopos to handle the link in app
            'To complete your setup, visit your business portal.'
          ) : (
            <span>
              To complete your setup&nbsp;
              <Link to='/onboarding'>
                <span>click here.</span>
              </Link>
            </span>
          )}
        </span>
      </div>
    </div>
  )
}

SettlementOnboardingWarning.defaultProps = {
  style: {},
}

SettlementOnboardingWarning.propTypes = {
  isInApp: PropTypes.bool,
  style: PropTypes.object,
}

export default SettlementOnboardingWarning
