/* eslint-disable @typescript-eslint/no-explicit-any */

import Immutable from 'immutable'

import keyMirror from 'libs/key-mirror'

export const RequestStatus = keyMirror({
  NOT_STARTED: null,
  STARTED: null,
  SUCCESS: null,
  FAILURE: null,
})

export function initialRequestState() {
  return new Immutable.Record({
    status: RequestStatus.NOT_STARTED,
    error: null,
  })()
}

/**
 * Automatically updates the state for requests, i.e async api calls
 *
 * @param {string} REQUEST - the request action type, i.e FETCH_PRODUCTS_REQUEST
 * @param {string} SUCCESS - the success action type, i.e FETCH_PRODUCTS_SUCCESS
 * @param {string} FAILURE - the failure action type, i.e FETCH_PRODUCTS_FAILURE
 * @param {Immutable.Map} state - your reducers initial state
 * @param {Object} action - the redux action
 * @returns {*}
 */
export function handleRequest(REQUEST, SUCCESS, FAILURE, state, action) {
  switch (action.type) {
    case REQUEST:
      return state.withMutations((map) => {
        map.setIn(['status'], RequestStatus.STARTED)
      })
    case SUCCESS:
      return state.withMutations((map) => {
        map.setIn(['status'], RequestStatus.SUCCESS)
      })
    case FAILURE:
      return state.withMutations((map) => {
        let { error } = action
        if (error instanceof Error) {
          error = JSON.parse(JSON.stringify(error))
        }
        map.setIn(['status'], RequestStatus.FAILURE).setIn(['error'], error)
      })
    default:
      return state
  }
}
