import PropTypes from 'prop-types'

export function If({ condition, fallback, children }) {
  if (condition) {
    return children
  }

  if (fallback) {
    return fallback
  }

  return null
}

If.propTypes = {
  children: PropTypes.node.isRequired,
  condition: PropTypes.any,
  fallback: PropTypes.node,
}
