/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@yoco/design-system-icons/dist/react'

import collectionIcons from 'components/icons/collectionIcons'
import PopupComponent from 'libs/modals/popups'
import popupClasses from 'libs/modals/popups.module.scss'

import classes from './popups.module.scss'

export default class SelectIconPopup extends PopupComponent {
  onClose() {
    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  onIconClicked(icon) {
    if (this.props.onIconClicked) {
      this.props.onIconClicked(icon)
    }
  }

  getIcons() {
    return collectionIcons.map((icon) => {
      return (
        <div key={icon} className={classes.iconItem} onClick={this.onIconClicked.bind(this, icon)}>
          <i className={`iconcollection-${icon}`} />
        </div>
      )
    })
  }

  getContent() {
    return (
      <div className={classes.iconPopup}>
        <div className={popupClasses.popupTitle}>
          Select an Icon
          <div
            className={`${classes.escButton} ${classes.modifiedButton}`}
            onClick={this.onClose.bind(this)}
          >
            <Icon name='close' size={24} />
          </div>
        </div>
        <div className={classes.popupEditSection}>{this.getIcons()}</div>
      </div>
    )
  }
}

SelectIconPopup.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  actionType: PropTypes.string,
  iconType: PropTypes.string,
}
