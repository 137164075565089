import { combineReducers } from '@reduxjs/toolkit'
import { Record } from 'immutable'

import { handleRequest, initialRequestState } from 'redux/modules/helpers/requestHelper'

import ActionTypes from './actionTypes'

const makeState = Record({
  totalSales: 0,
  averageSalesAmount: 0,
  averageItemsPerSale: 0,
})

const initialState = makeState()

export function insightsRequest(state = initialRequestState(), action) {
  return handleRequest(
    ActionTypes.FETCH_FACTS_REQUEST,
    ActionTypes.FETCH_FACTS_SUCCESS,
    ActionTypes.FETCH_FACTS_FAILURE,
    state,
    action
  )
}

export function insights(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.FETCH_FACTS_FULFILLED:
      return state.mergeDeep(action.payload)
    default:
      return state
  }
}

export default combineReducers({
  insights,
  insightsRequest,
})
