import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'

import * as forms from 'libs/forms'

import renderFormFields from './FormFields'
import { FormName } from './constants'
import { useSaveCustomer } from './hooks'

function CustomerForm({ customer = new Map(), onSaved, onFailure }) {
  const { onSubmit } = useSaveCustomer(customer, onSaved, onFailure)
  return (
    <forms.Form
      name={FormName}
      noWrap
      onSubmit={onSubmit}
      scrollToTop={false}
      showLoader
      loaderClass='spinner-container'
      showSuccess
      showError
    >
      {renderFormFields()}
    </forms.Form>
  )
}

CustomerForm.propTypes = {
  customer: PropTypes.object,
  onSaved: PropTypes.func,
  onFailure: PropTypes.func,
}

export default CustomerForm
