import React from 'react'

import styles from './CapitalHeaderBlock.module.scss'

interface Props {
  title: string
  description: string
}

const CapitalHeaderBlock: React.FunctionComponent<Props> = ({ title, description }) => {
  return (
    <div className={styles.container} data-testid='CapitalHeaderBlock'>
      <h1 className={styles.title}>{title}</h1>
      <h3 className={styles.description}>{description}</h3>
    </div>
  )
}

export default CapitalHeaderBlock
