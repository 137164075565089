import { Map } from 'immutable'

import { BANK_ACTIONS } from './bankDetails/actions'
import { BUSINESS_INFO_ACTIONS } from './businessDetails/actions'

export const FORM_NAMES = {
  BANK_DETAILS: 'bankDetails',
  BUSINESS_ADDRESS: 'businessAddress',
  COMPANY_REGISTRATION_NUMBER: 'companyRegistrationNumber',
}

const initialState = Map({
  bank: Map({
    bankAccount: Map(),
    bankAccountUpdateRequest: Map(),
    bankAccountUpdateError: undefined,
    showBankDetailsPopup: false,
  }),
  businessAddress: Map({
    address: undefined,
    showBusinessInfoPopup: false,
  }),
})

export const onboardingReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case BANK_ACTIONS.UPDATE_UPLOADED_BANK_UPDATE_REQUEST_DOCUMENTS:
      return state.setIn(['bank', 'uploadedDocuments'], action.payload)
    case BANK_ACTIONS.DISPLAY_BANK_DETAILS_POPUP:
      return state.setIn(['bank', 'showBankDetailsPopup'], action.payload)
    case BANK_ACTIONS.UPDATE_BANK_ACCOUNT:
      return state.setIn(['bank', 'bankAccount'], Map(action.payload))
    case BANK_ACTIONS.UPDATE_BANK_ACCOUNT_UPDATE_REQUEST:
      return state.setIn(['bank', 'bankAccountUpdateRequest'], Map(action.payload))
    case BANK_ACTIONS.UPDATE_BANK_ACCOUNT_UPDATE_ERROR:
      return state.setIn(['bank', 'bankAccountUpdateError'], action.payload)
    case BUSINESS_INFO_ACTIONS.UPDATE_TRADING_ADDRESS:
      return state.setIn(['businessAddress', 'address'], action.payload)
    case BUSINESS_INFO_ACTIONS.DISPLAY_BUSINESS_INFO_POPUP:
      return state.setIn(['businessAddress', 'showBusinessInfoPopup'], action.payload)
    default:
      return state
  }
}
