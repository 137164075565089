import { combineReducers } from '@reduxjs/toolkit'
import { Map } from 'immutable'

import ActionTypes from './actionTypes'

const initialState = Map({
  fetchError: undefined,
})

export function summary(state = initialState, action = {}) {
  switch (action.type) {
    case ActionTypes.FETCH_SETTLEMENT_SUMMARY_SUCCESS:
      return state.mergeDeep(action.payload)
    case ActionTypes.FETCH_SETTLEMENT_SUMMARY_FAILURE:
      return state.mergeDeep(action.payload)
    default:
      return state
  }
}

export default combineReducers({
  summary,
})
