/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import copy from 'copy-to-clipboard'
import classNames from 'classnames'
import { Icon } from '@yoco/design-system-icons/dist/react'

import { track } from 'libs/analytics'
import * as forms from 'libs/forms'
import FormCard from 'components/cards/FormCard'
import FlatButton from 'components/buttons/FlatButton'

import classes from './CopyLink.module.scss'

function InvoiceCopyLink({ url }) {
  const [isCopied, setIsCopied] = useState(false)
  const copiedTimeoutRef = useRef()
  const toastVisibleMs = 2000

  useEffect(() => {
    if (copiedTimeoutRef.current) {
      clearTimeout(copiedTimeoutRef.current)
    }

    if (isCopied) {
      copiedTimeoutRef.current = setTimeout(() => setIsCopied(false), toastVisibleMs)
    }
  }, [isCopied, copiedTimeoutRef])

  const handleCopyClick = useCallback(() => {
    track('invoices_details_copy_button_clicked', { name: 'invoices_details_copy_button_clicked' })

    copy(url)
    setIsCopied(true)
  }, [url])

  return (
    <FormCard header='Invoice url' headerText='Copy the link and share it with your customer'>
      <div className={classes.root}>
        <div onClick={handleCopyClick} className={classes.inputWrapper}>
          <input className={forms.classes.field} readOnly value={url} />
          <div className={classes.inputIcon}>
            <Icon name='copy' size={24} />
          </div>
        </div>
        <div className={classes.action}>
          <div className={classNames(classes.toast, { [classes.showToast]: isCopied })}>
            Copied!
          </div>
          <FlatButton
            label='Copy'
            className={classNames(classes.button, 'secondary')}
            onClick={handleCopyClick}
          />
        </div>
      </div>
    </FormCard>
  )
}

InvoiceCopyLink.propTypes = {
  url: PropTypes.string.isRequired,
}

export default InvoiceCopyLink
