import React from 'react'
import FormCard from 'components/cards/FormCard'
import { Icon } from '@yoco/design-system-icons/dist/react'
import { remove } from 'lodash'
import CheckboxButton from 'components/buttons/CheckboxButton'
import { useFormik } from 'formik'
import FlatButton from 'components/buttons/FlatButton'
import * as Yup from 'yup'
import FormField from './FormField'
import classes from './StaffForm.module.scss'
import { permissionsMapping, permissionType } from './utils'
import { RouteContext, RouteDispatchContext } from '../StaffRoute/RouteContext'

type UserInfoScreenType = {
  children: any
}

export const UserInfoScreen = ({ children }: UserInfoScreenType): JSX.Element => {
  const { dispatch } = React.useContext(RouteDispatchContext)
  const state = React.useContext(RouteContext)

  const { handleSubmit, handleChange, values, errors, touched, setValues } = useFormik({
    initialValues: { firstName: state.userInfo.firstName, lastName: state.userInfo.lastName },
    validationSchema: Yup.object({
      firstName: Yup.string().required('This field is required'),
      lastName: Yup.string().required('This field is required'),
    }),
    validateOnMount: false,
    onSubmit: (formValues) => {
      dispatch({ type: 'userInfo', values: formValues })
    },
  })

  React.useEffect(() => {
    setValues({ firstName: state.userInfo.firstName, lastName: state.userInfo.lastName })
  }, [setValues, state.userInfo])

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.userInfo}>
        <FormCard header='User name' style={{ fontSize: '1.5em' }}>
          <div className={classes.flexContainer}>
            <FormField
              name='firstName'
              label='First Name'
              value={values.firstName}
              onChange={handleChange}
              placeholder='John'
              errors={errors.firstName}
              touched={touched.firstName}
            />
            <FormField
              name='lastName'
              label='Surname'
              value={values.lastName}
              onChange={handleChange}
              placeholder='Sebenza'
              errors={errors.lastName}
              touched={touched.lastName}
            />
          </div>
        </FormCard>
        {children}
      </div>
    </form>
  )
}

type CreateStaffFormType = {
  roleDescriptions: any
}

const roleDescriptionsText = {
  admin: 'Admins have the same permissions as the business owner',
  staff:
    'People in the business only responsible for making sales and keeping track of their own transactions',
  manager:
    'People responsible for managing and overseeing all parts of the business, excl the Yoco balance or payout information',
  supervisor:
    'People who oversee operations to ensure business runs smoothly. They supervise staff and can authorise refunds.',
  custom:
    'People who only need to do very specific things in the business. You can add or remove permissions from these users at any point.',
}

export const PermissionScreen = ({ roleDescriptions }: CreateStaffFormType): JSX.Element => {
  const [selected, setSelected] = React.useState('admin')
  const [showPermission, setShowPermission] = React.useState(false)
  const { dispatch } = React.useContext(RouteDispatchContext)
  const [selectedRoles, setSelectedRoles] = React.useState<permissionType>({
    'Managing your Business': [],
    'Sales and Refunds': [],
  })

  const remoteRoleDescriptions = roleDescriptions?.map((roleGroup) =>
    roleGroup.update('roles', (roles) => roles.filter((role) => role.get('role')))
  )

  Object.assign(permissionsMapping, {
    custom: permissionsMapping.admin,
  })

  const toggleDisplay = () => {
    setShowPermission(!showPermission)
  }

  const renderPermissions = React.useCallback(() => {
    return remoteRoleDescriptions?.toJS().map((value) => {
      const { groupName, roles: rolesArray } = value
      const validRoles = rolesArray.filter((element) => {
        const { role } = element
        return permissionsMapping[selected][groupName]?.includes(role)
      })

      const customPermissionRoleRender = (role: string, title: string, description: string) => {
        const onClickItem = () => {
          if (selectedRoles[groupName].includes(title)) {
            setSelectedRoles({
              ...selectedRoles,
              [groupName]: remove(selectedRoles[groupName], (element) => element !== title),
            })
            return
          }
          selectedRoles[groupName].push(title)
          setSelectedRoles({ ...selectedRoles })
        }

        return (
          <div key={role} className={classes.itemContainerCustom}>
            <CheckboxButton
              className={classes.checkboxButton}
              onClick={onClickItem}
              selected={selectedRoles[groupName].includes(title)}
            />
            <div>
              <h4>{title}</h4>
              <p>{description}</p>
            </div>
          </div>
        )
      }

      return validRoles.length > 1 ? (
        <div className={classes.roles} key={groupName}>
          <h3 className={classes.roleHeader}>{groupName}</h3>

          <div className={classes.itemContainer}>
            {validRoles.map((element) => {
              if (selected === 'custom') {
                return customPermissionRoleRender(element.role, element.title, element.description)
              }
              return (
                <div key={element.role}>
                  <h4>{element.title}</h4>
                  <p>{element.description}</p>
                </div>
              )
            })}
          </div>
          <hr />
        </div>
      ) : null
    })
  }, [remoteRoleDescriptions, selected, selectedRoles])

  const onRadioChange = (permission: string) => {
    setSelected(permission)
  }

  const onSubmit = () => {
    if (selected !== 'custom') {
      const values = remoteRoleDescriptions?.toJS().map((value) => {
        const { groupName, roles: rolesArray } = value
        return rolesArray.filter((element) => {
          const { role } = element
          return permissionsMapping[selected][groupName]?.includes(role)
        })
      })

      dispatch({ type: 'userPermissions', values })
    } else {
      dispatch({ type: 'userPermissions', selectedRoles })
    }
  }

  return (
    <div className={classes.permissionsContainer}>
      <FormCard
        header='User role'
        style={{
          fontSize: '1.5em',
          overflowY: 'auto',
          position: 'absolute',
          width: '95%',
          maxHeight: '89%',
          fontWeight: 700,
        }}
      >
        <div className={classes.card}>
          <div className={classes.carousel}>
            {Object.keys(permissionsMapping).map((element) => {
              return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <div
                  key={element}
                  onClick={() => onRadioChange(element)}
                  className={
                    selected === element ? classes.carouselItemSelected : classes.carouselItem
                  }
                >
                  <h6>{element.charAt(0).toLocaleUpperCase() + element.slice(1)}</h6>
                </div>
              )
            })}
          </div>

          <p className={classes.description}>{roleDescriptionsText[selected]}</p>
          <button className={classes.dropdownButton} type='button' onClick={toggleDisplay}>
            Show permissions
            <Icon name={showPermission ? 'chevron-down' : 'chevron-up'} size={24} color='black' />
          </button>
          {showPermission ? (
            <div className={classes.roleContainer}>{renderPermissions()}</div>
          ) : null}
        </div>
      </FormCard>
      <div className={classes.actionButtons}>
        <FlatButton onClick={() => dispatch({ type: 'back' })}>Back</FlatButton>
        <FlatButton className='blueBackground' onClick={onSubmit}>
          Next
        </FlatButton>
      </div>
    </div>
  )
}
