import { FrequentlyAskedQuestions } from './types'

export const FAQS: FrequentlyAskedQuestions = [
  {
    title: 'What is Yoco Capital?',
    description: `Yoco Capital is an upfront sum of cash that is provided to a Yoco merchant in exchange for a fixed percent of future card sales.`,
  },
  {
    title: 'How does Capital work?',
    description: `We tailor an offer to you based on your transaction history with your Yoco device.
    Your offer will be made available here: portal.yoco.co.za/capital.
    You will be made aware of all costs and the final amount payable prior to accepting or declining your capital offer.
    You pay back your capital amount automatically with a percentage of your Yoco sales as per the agreed holdback percentage. The higher your sales, the faster you repay. On days without sales, you won’t pay anything, and there is no fixed term within which you need to pay.`,
  },
  {
    title: 'How is this different from a loan?',
    description: `Yoco Capital is not a loan or debt instrument.  It is, however, a type of financing that is linked to a merchant’s previous trading patterns and future turnover.
    It differs from a loan in that you do not have to disclose the reason for your capital requirements.
    It differs from a loan as there is no accruing interest but rather a once-off flat rate - the final amount you see when applying is the total amount you will pay back over time, even if you go over your ideal repayment period.
    There is almost no admin and zero paperwork involved - your contract with the respective offers are available in your Business Portal and is accessible within 3 simple steps.`,
  },
]
