import React from 'react'
import { IndexRedirect } from 'react-router'

import UserProfilePage from './UserProfilePage'
import ConfirmUserDetailsPage from './ConfirmUserDetailsPage'
import CancelUserDetailsUpdatePage from './CancelUserDetailsUpdatePage'

export default function getRoutes() {
  return {
    path: 'profile',
    text: 'Profile',
    icon: 'profile',
    indexRedirect: <IndexRedirect to='/profile/edit' />,
    hideInMenu: true,
    routes: [
      {
        path: 'edit',
        text: 'Profile',
        icon: 'profile',
        component: UserProfilePage,
      },
      {
        path: 'confirm/:hashid',
        component: ConfirmUserDetailsPage,
        hideInMenu: true,
      },
      {
        path: ':uuid/cancel',
        component: CancelUserDetailsUpdatePage,
        hideInMenu: true,
      },
    ],
  }
}
