import { DeclineReasons } from 'redux/modules/capital/types'

const DECLINE_REASONS: {
  label: string
  value: DeclineReasons
}[] = [
  {
    label: `I'd rather speak to someone, please call me back`,
    value: DeclineReasons.CallBack,
  },
  { label: `I am just browsing`, value: DeclineReasons.Browsing },
  { label: `I don't need Capital at this time`, value: DeclineReasons.DontNeed },
  { label: `I need a higher amount`, value: DeclineReasons.HigherAmount },
  { label: `I'm unhappy with the pricing`, value: DeclineReasons.Pricing },
  { label: 'Other', value: DeclineReasons.Other },
]

export default DECLINE_REASONS
