/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@yoco/design-system-icons/dist/react'

import FlatButton from 'components/buttons/FlatButton'
import { makeTestID } from 'libs/utils'
import { If } from 'libs/formats'

import classes from './online.module.scss'

export default function CopyableLink({ link, actions, testID }) {
  const id = `link-${Math.floor(Math.random() * 100000)}`

  const [message, setMessage] = useState(undefined)
  const copyLink = () => {
    const copyTextarea = document.querySelector(`#${id}`)
    copyTextarea.select()

    try {
      const successful = document.execCommand('copy')
      if (successful) {
        setMessage('Link copied to clipboard')
      } else {
        console.error('document.execCommand did not execute successful to copy link')
        window.prompt(
          `To copy to the clipboard right click and select "copy" or press: Ctrl+C (Cmd + C on a mac)`,
          link
        )
      }
    } catch (err) {
      console.error('Exception thrown while trying to copy', err)
      window.prompt(
        `To copy to the clipboard right click and select "copy" or press: Ctrl+C (Cmd + C on a mac)`,
        link
      )
    }
  }

  return (
    <div data-testid={testID}>
      <div className={classes.copyableLink}>
        <div onClick={copyLink} className={classes.copyableLinkInputContainer}>
          <input
            className={classes.urlText}
            value={link}
            id={id}
            data-testid={makeTestID(testID, 'link')}
            readOnly
          />
          <div className={classes.copyIcon}>
            <Icon name='copy' size={24} />
          </div>
        </div>
        <div className={classes.copyableLinkActions}>
          <FlatButton
            label='Copy'
            className='secondary'
            onClick={copyLink}
            testID={makeTestID(testID, 'copy')}
          />
          {actions}
        </div>
      </div>
      <If condition={message}>
        <div className={classes.copyableLinkMessage}>{message}</div>
      </If>
    </div>
  )
}

CopyableLink.propTypes = {
  link: PropTypes.string.isRequired,
  actions: PropTypes.node,
  testID: PropTypes.string,
}
