import React from 'react'
import classNames from 'classnames'

import Card from 'ui/containers/Card'
import layout from 'ui/layout/layout.module.scss'
import ui from 'ui/ui.module.scss'
import typography from 'ui/typography/typography.module.scss'
import CheckboxButton from 'components/buttons/CheckboxButton'

import { makeTestID } from 'libs/utils'
import styles from './DesignCard.module.scss'
import { DesignCardProps } from './types'

const DesignCard: React.FunctionComponent<DesignCardProps> = ({
  title,
  isExpanded = true,
  showCheckbox = false,
  isEnabled,
  toggleIsEnabled,
  children,
  testID,
}) => {
  return (
    <Card>
      <div
        data-testid={testID || makeTestID('onlineStore', 'designPage', 'designCard')}
        className={classNames([layout.flex, layout.itemsCenter, layout.pb2])}
      >
        {showCheckbox ? (
          <div className={layout.pr2}>
            <CheckboxButton selected={isEnabled} onClick={toggleIsEnabled} />
          </div>
        ) : null}
        <div className={layout.flexGrow}>
          <div className={typography.h3}>{title}</div>
        </div>
        <div className={classNames([ui.cursorPointer, styles.checkBox])} />
      </div>

      {isExpanded ? children : null}
    </Card>
  )
}

export default DesignCard
