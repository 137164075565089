import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import typograhy from 'ui/typography/typography.module.scss'

export default function SubHeading({ children, htmlFor }) {
  return (
    <h2 className={classnames(typograhy.h2)} htmlFor={htmlFor}>
      {children}
    </h2>
  )
}

SubHeading.propTypes = {
  htmlFor: PropTypes.string,
  children: PropTypes.node,
}
