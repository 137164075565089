/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react'
import classnames from 'classnames'

import * as forms from 'libs/forms'
import VStack from 'ui/layout/VStack'
import Label from 'ui/components/Label'
import layout from 'ui/layout/layout.module.scss'
import buttonClasses from 'components/buttons/buttons.module.scss'
import ItemImageUpload from 'components/items/ItemImageUpload'

import { makeTestID } from 'libs/utils'
import DesignCard from './DesignCard'
import { HeroDesignProps } from './types'

const HeroDesignCard: React.FunctionComponent<HeroDesignProps> = ({
  sectionConfig,
  setSectionConfig,
}) => {
  const { imageURL } = sectionConfig

  const setImageURL = useCallback(
    (imageURL) => {
      setSectionConfig({ ...sectionConfig, imageURL })
    },
    [sectionConfig, setSectionConfig]
  )

  const setTitle = useCallback(
    (event) => {
      setSectionConfig({ ...sectionConfig, title: event.target.value })
    },
    [sectionConfig, setSectionConfig]
  )

  const setSubtitle = useCallback(
    (event) => {
      setSectionConfig({ ...sectionConfig, subtitle: event.target.value })
    },
    [sectionConfig, setSectionConfig]
  )

  const toggleIsEnabled = useCallback(() => {
    setSectionConfig({ ...sectionConfig, isEnabled: !sectionConfig.isEnabled })
  }, [sectionConfig, setSectionConfig])

  return (
    <>
      <Label> Hero</Label>
      <DesignCard
        testID={makeTestID('onlineStore', 'designPage', 'heroDesignCard')}
        title='Hero section'
        showCheckbox
        isEnabled={sectionConfig.isEnabled}
        toggleIsEnabled={toggleIsEnabled}
      >
        <div className='clearfix'>
          <forms.TextField
            name='title'
            label='Title'
            isFullWidth
            value={sectionConfig.title}
            onChange={setTitle}
          />
        </div>
        <div className='clearfix'>
          <forms.TextField
            name='subtitle'
            label='Subtitle'
            isFullWidth
            value={sectionConfig.subtitle}
            onChange={setSubtitle}
          />
        </div>

        <VStack spacing='xs' className={layout.pb2}>
          <Label>Hero Image</Label>

          {imageURL ? (
            <a href='#' color='red' onClick={() => setImageURL(null)}>
              Remove Image
            </a>
          ) : (
            <div>No image uploaded</div>
          )}

          <div className={layout.relative}>
            <button
              type='button'
              className={classnames(buttonClasses.flatButton, 'secondary')}
              style={{
                position: 'relative',
              }}
            >
              Upload
            </button>
            <ItemImageUpload
              grouping='online-store-images'
              fullCover
              shouldSquareAndCenter={false}
              maxHeight={800}
              format='image/jpeg'
              completeCallback={(data) => {
                setImageURL(data.data.url)
              }}
            />
          </div>
        </VStack>
      </DesignCard>
    </>
  )
}

export default HeroDesignCard
