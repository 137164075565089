/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { Map } from 'immutable'
import { Icon } from '@yoco/design-system-icons/dist/react'
import { glyphMap } from '@yoco/design-system-icons'

import { Table, TableRow } from 'libs/tables'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import BlockHeader from 'components/headers/BlockHeader'

function IconsPage() {
  return (
    <MaxWidthBlock>
      <BlockHeader title='Icons' />
      <Table
        name='icons'
        defaultSort='id'
        pageSize={100}
        hasCustomData
        waitForFilterLoad={false}
        showPaging={false}
        data={Object.keys(glyphMap).map((name) => new Map({ name }))}
        getHeader={() => {
          return (
            <tr className='visible-header'>
              <th width={90}>Icon</th>
              <th>Name</th>
            </tr>
          )
        }}
        getRow={(row, index) => (
          <TableRow index={index} key={index}>
            <td style={{ paddingTop: 20 }}>
              <Icon name={row.get('name')} size={32} />
            </td>
            <td>{row.get('name')}</td>
          </TableRow>
        )}
      />
    </MaxWidthBlock>
  )
}

export default IconsPage
