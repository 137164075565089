/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { track } from 'libs/analytics'

import { openBankDetailsPopup } from 'redux/modules/onboarding/bankDetails/actions'
import { selectShouldShowBankDetailsPopup } from 'redux/modules/onboarding/bankDetails/selectors'
import { BankDetailsPopup, BusinessInfoPopup } from 'components/onboarding/TaskCardPopups'
import { openBusinessInfoPopup } from 'redux/modules/onboarding/businessDetails/actions'

import { selectShouldShowBusinessInfoPopup } from 'redux/modules/onboarding/businessDetails/selectors'
import OnboardingV3Page from './OnboardingV3Page'

const OnboardingPage = ({
  location,
  shouldShowBankDetailsPopup,
  shouldShowBusinessInfoPopup,
  showBankDetailsPopup,
  showBusinessInfoPopup,
}) => {
  useEffect(() => {
    track('Complete setup viewed')
  }, [])

  // The app's onboarding experience uses a native component to render a list of tasks aka things for the user
  // to do to complete onboarding. The actual tasks themselves are webviewed here and launched as modals in the app.
  // The `task` query string parameter is used to automatically render a modal.
  const { task } = location.query

  useEffect(() => {
    switch (task) {
      case 'addBankAccount':
        track('portal_complete_your_setup_bank_details')
        track('add_bank_account_button_clicked')

        showBankDetailsPopup()
        break
      case 'addBusinessInformation':
        track('portal_complete_your_setup_business_address ')

        showBusinessInfoPopup()
        break
      default:
    }
  }, [showBankDetailsPopup, showBusinessInfoPopup, task])

  if (!task) {
    return <OnboardingV3Page />
  }

  if (shouldShowBankDetailsPopup) {
    return <BankDetailsPopup />
  }

  if (shouldShowBusinessInfoPopup) {
    return <BusinessInfoPopup />
  }

  return null
}

OnboardingPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
  shouldShowBusinessInfoPopup: PropTypes.bool.isRequired,
  shouldShowBankDetailsPopup: PropTypes.bool.isRequired,
  showBankDetailsPopup: PropTypes.func.isRequired,
  showBusinessInfoPopup: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  shouldShowBusinessInfoPopup: selectShouldShowBusinessInfoPopup(state),
  shouldShowBankDetailsPopup: selectShouldShowBankDetailsPopup(state),
})

const mapDispatchToProps = (dispatch) => ({
  showBankDetailsPopup: () => dispatch(openBankDetailsPopup()),
  showBusinessInfoPopup: () => dispatch(openBusinessInfoPopup()),
})

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingPage)
