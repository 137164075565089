import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as forms from 'libs/forms'
import BlockHeader from 'components/headers/BlockHeader'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import FlatButton from 'components/buttons/FlatButton'

class UnconnectedPartnerPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userExists: false,
      searched: false,
    }
  }

  checkResponse(data) {
    if (data.data) {
      this.setState({
        userExists: true,
        searched: true,
      })
    } else {
      this.setState({
        userExists: false,
        searched: true,
      })
    }
  }

  renderResponse() {
    if (!this.state.userExists && this.state.searched) {
      return <div className='alert alert-danger'>Cannot find user in system</div>
    }
    if (this.state.userExists && this.state.searched) {
      return <div className='alert alert-success'>User does exist</div>
    }
    return null
  }

  render() {
    return (
      <MaxWidthBlock>
        <BlockHeader title='Check Yoco Partner' />
        {this.renderResponse()}
        <forms.Form
          action='/partners/checkId'
          name='partnerID'
          persistData={false}
          onSuccess={(response) => this.checkResponse(response)}
        >
          <forms.Heading heading='Information' />
          <forms.InfoText text='Please enter the ID/Passport number of the merchant.' />
          <div className='clearfix'>
            <forms.TextField
              name='idNumber'
              label='ID/Passport Number'
              placeholder='ID/Passport Number'
              validators={[
                new forms.IDNumberPassportValidator('You must provide a valid ID/Passport number'),
              ]}
            />
          </div>
          <FlatButton label='Search' type='submit' className='blueBackground' />
        </forms.Form>
      </MaxWidthBlock>
    )
  }
}

const PartnerPage = connect()(UnconnectedPartnerPage)

export default PartnerPage
