import { formatCurrency, formatCurrencyNoDecimals } from 'libs/utils'
import React from 'react'
import styles from './LulalendReAdvanceSliderContainer.module.scss'

interface Props {
  amountSelected: number
  earlySettlementAmount: number
  discountOnCurrentAdvanceOutstandingBalance: number
  children: React.ReactNode
}

const LulalendReAdvanceSliderContainer: React.FunctionComponent<Props> = ({
  amountSelected,
  earlySettlementAmount,
  discountOnCurrentAdvanceOutstandingBalance,
  children,
}) => {
  const calculateAmountReceived = () => {
    const amountReceived = amountSelected - earlySettlementAmount

    return amountReceived > 0 ? amountReceived : 0
  }

  return (
    <div className={styles.lulalendReAdvanceSliderContainer}>
      {children}
      <div className={styles.reAdvanceDetailsContainer}>
        <div className={styles.reAdvanceDetailRowWithBottomRule}>
          <div className={styles.reAdvanceDetailLabelContainer}>
            <div className={styles.reAdvanceDetailLabel}>Amount selected</div>
          </div>
          <div className={styles.reAdvanceDetailValue}>{formatCurrency(amountSelected)}</div>
        </div>
        <div className={styles.reAdvanceDetailRowWithBottomRule}>
          <div className={styles.reAdvanceDetailLabelContainer}>
            <div className={styles.reAdvanceDetailLabel}>
              Remaining balance on your current advance
            </div>
            <div className={styles.reAdvanceDetailLabelExplanation}>
              Including {formatCurrencyNoDecimals(discountOnCurrentAdvanceOutstandingBalance)}{' '}
              discount
            </div>
          </div>
          <div className={styles.reAdvanceDetailSmallValue}>
            - {formatCurrency(earlySettlementAmount)}
          </div>
        </div>
        <div className={styles.reAdvanceDetailRow}>
          <div className={styles.reAdvanceDetailLabelContainer}>
            <div className={styles.reAdvanceDetailLabel}>Amount you will receive</div>
            <div className={styles.reAdvanceDetailLabelExplanation}>
              What you will receive after the remaining balance is deducted
            </div>
          </div>
          <div className={styles.reAdvanceDetailEmphasizedValue}>
            {formatCurrency(calculateAmountReceived())}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LulalendReAdvanceSliderContainer
