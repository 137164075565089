import $ from 'jquery'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { EmptyTableView } from 'libs/tables'
import { getENV } from 'libs/utils'
import LoadingView from 'components/loaders/LoadingView'
import FlatButton from 'components/buttons/FlatButton'
import FormCard from 'components/cards/FormCard'
import warningImage from 'assets/images/icon-empty-state.png'

import InvoicePreviewIframe from './PreviewIframe'

function InvoicePreviewCard({ invoice }) {
  const invoicePublicId = invoice.get('publicId')
  const [version, setVersion] = useState(1)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState()
  const [content, setContent] = useState('')

  useEffect(() => {
    if (!invoicePublicId) {
      return
    }

    setIsLoading(true)
    $.ajax({
      type: 'GET',
      url: `${getENV('INVOICES_BASE_URL')}/invoices-view/${invoicePublicId}`,
      dataType: 'html',
      success: (response) => setContent(response),
      error: (_, __, errorThrown) => setError(errorThrown),
      complete: () => setIsLoading(false),
    })
  }, [invoicePublicId, version])

  if (isLoading) {
    return <LoadingView message='Loading invoice preview' />
  }

  if (error) {
    return (
      <FormCard>
        <EmptyTableView title='Unable to load invoice preview' image={warningImage}>
          <FlatButton
            label='Try again'
            icon='icon-refresh'
            className='blueBackground'
            onClick={() => {
              setError(undefined)
              setVersion(version + 1)
            }}
          />
        </EmptyTableView>
      </FormCard>
    )
  }

  if (content) {
    return <InvoicePreviewIframe srcDoc={content} />
  }

  return null
}

InvoicePreviewCard.propTypes = {
  invoice: PropTypes.object,
}
export default InvoicePreviewCard
