import React from 'react'
import PropTypes from 'prop-types'

import classes from './styles.module.scss'

function FiltersContainer({ actions, children }) {
  return (
    <div className={classes.filtersContainer}>
      {actions ? <div className={classes.actions}>{actions}</div> : null}
      {children}
    </div>
  )
}

FiltersContainer.propTypes = {
  actions: PropTypes.node,
  children: PropTypes.node,
}

export default FiltersContainer
