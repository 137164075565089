import React from 'react'

import styles from './BaseChip.module.scss'

interface Props {
  label: string
  color: string
  hasNotifyIndicator: boolean
}

const BaseChip: React.FunctionComponent<Props> = ({ label, color, hasNotifyIndicator }) => {
  const colorToUse = `${color}4D`

  return (
    <div
      className={styles.baseChip}
      style={{
        borderColor: colorToUse,
        color,
      }}
      data-testid='BaseChip'
    >
      <div className={styles.chipContentWrapper}>
        <p className={styles.labelText}>{label}</p>
        {hasNotifyIndicator ? (
          <span
            className={styles.notifyIndicator}
            style={{
              outlineColor: colorToUse,
              backgroundColor: color,
            }}
          />
        ) : undefined}
      </div>
    </div>
  )
}

export default BaseChip
