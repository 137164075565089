import React from 'react'

import CapitalHeaderBlock from 'components/capital/partials/CapitalHeaderBlock/CapitalHeaderBlock'
import CapitalQualificationBlock from 'components/capital/partials/CapitalQualificationBlock'
import CapitalMerchantUsesBlock from 'components/capital/partials/CapitalMerchantUsesBlock'
import CapitalFrequentlyAskedQuestionsBlock from 'components/capital/partials/CapitalFrequentlyAskedQuestionsBlock'
import CapitalHelpVideo from 'components/capital/partials/CapitalHelpVideo'
import CapitalHelpMessage from 'components/capital/partials/CapitalHelpMessage'
import { Metrics } from 'redux/modules/capital/types'

import classes from '../capital.module.scss'

interface Props {
  metrics: Metrics
  optedInForNotification: boolean
  fetchingData: boolean
  onOptInPress: () => void
  onFindOutMoreClicked: () => void
}

const CapitalQualificationPage: React.FunctionComponent<Props> = ({
  metrics,
  optedInForNotification,
  onOptInPress,
  fetchingData,
  onFindOutMoreClicked,
}) => {
  return (
    <div className={classes.page} data-testid='CapitalQualificationPage'>
      <CapitalHeaderBlock
        title='Get Yoco Capital to grow your business.'
        description={`
          Any business can benefit from extra Capital for growth - whether it's to help buy more equipment or open a new store.
          Now Yoco merchants can get a cash advance, see if you meet the criteria below.
        `}
      />
      <CapitalQualificationBlock
        volume={metrics.volume}
        transactions={metrics.transactions}
        months={metrics.months}
      />
      <CapitalHelpVideo onFindOutMoreClicked={onFindOutMoreClicked} />
      <CapitalMerchantUsesBlock
        optedIn={optedInForNotification}
        onOptInPress={onOptInPress}
        fetchingData={fetchingData}
      />
      <CapitalFrequentlyAskedQuestionsBlock />
      <CapitalHelpMessage />
    </div>
  )
}

export default CapitalQualificationPage
