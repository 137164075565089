/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import classes from './QuantityInput.module.scss'

function QuantityInput({ value, onChange, className, ...props }) {
  const editingFlags = useRef({
    isEmpty: !value,
  })

  return (
    <input
      type='number'
      className={classNames(classes.root, className)}
      value={editingFlags.current.isEmpty ? '' : value}
      onChange={(e) => {
        editingFlags.current.isEmpty = !e.target.value
        // eslint-disable-next-line radix
        const nextValue = parseInt(e.target.value)
        onChange?.(Number.isInteger(nextValue) ? nextValue : 1)
      }}
      {...props}
    />
  )
}

QuantityInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  className: PropTypes.string,
}

export default QuantityInput
