/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import Immutable, { Map } from 'immutable'
import classnames from 'classnames'
import SplitBlock from 'components/blocks/SplitBlock'
import { FilterBatch, ApiFilter } from 'libs/filters'
import {
  TransactionStateFilter,
  TransactionTypeFilter,
} from 'components/filters/TransactionFilters'
import DateFilter from 'components/filters/DateFilter'
import DropDownButton from 'components/buttons/DropDownButton'
import ExportButton from 'components/buttons/ExportButton'
import { Table, EmptyTableView } from 'libs/tables'
import { FirebaseHandler } from 'libs/firebase'
import { track } from 'libs/analytics'
import * as misc from 'libs/formats'
import { TransactionReceipt, TransactionLifespan, BillLifespan } from 'components/transactions'
import { callJSONApi } from 'libs/api'
import FEATURES, { hasFeature } from 'libs/features'
import { setFieldOnItem, itemUpdated, getItemFromArray } from 'redux/modules/tables'
import localisation from 'libs/localisation/localisation'
import { formatCurrency, makeTestID } from 'libs/utils'
import blockClasses from 'components/blocks/blocks.module.scss'
import SplitHeader from 'components/headers/SplitHeader'
import SearchBar from 'components/filters/SearchBar'
import FiltersContainer from 'components/filters/FiltersContainer'
import layout from 'ui/layout/layout.module.scss'
import BlockHeader from 'components/headers/BlockHeader'
import DeviceFilters from 'libs/filters/DeviceFilter'

import classes from './sales.module.scss'
import TransactionRowItem from './TransactionRowItem'
import TransactionHeaderRow from './TransactionHeaderRow'

const TABLE_NAME = 'salesHistory'

class UnconnectedReduxSalesHistoryPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showingSplit: false,
      shownUUID: null,
    }
    this.onSearchPress = this.onSearchPress.bind(this)
  }

  onSearchPress(searchText) {
    track('history_search_tapped', { search: searchText })
  }

  exportButtonPress() {
    track('history_export_clicked')
  }

  getShownRow() {
    if (this.state.shownUUID) {
      return getItemFromArray(this.props.transactions, this.state.shownUUID)
    }

    return undefined
  }

  showRow(rowData, isSplitContentClick) {
    // Clicks from the split content should not dismiss the split content panel.
    if (!isSplitContentClick && this.state.shownUUID === rowData.get('uuid')) {
      this.setState({
        showingSplit: false,
        shownUUID: null,
      })
    } else {
      this.setState({
        showingSplit: true,
        shownUUID: rowData.get('uuid'),
      })
    }
  }

  fetchBillDetails(transactionUUID, billUUID) {
    const url = `/bills/${billUUID}/`
    callJSONApi(url, 'GET', {}, (response) => {
      if (response.status === 200) {
        const data = response.data.bill
        this.props.dispatch(
          setFieldOnItem(TABLE_NAME, transactionUUID, 'billDetails', Immutable.fromJS(data))
        )
      }
    })
  }

  rowClicked(rowData, isSplitContentClick = false) {
    track('history_txn_line_item_clicked')
    // If we are showing a new row start fetching refund details
    if (!this.state.shownUUID || rowData.get('uuid') !== this.state.shownUUID) {
      if (!rowData.get('billDetails')) {
        this.fetchBillDetails(rowData.get('uuid'), rowData.get('billUUID'))
      }
    }

    this.showRow(rowData, isSplitContentClick)
  }

  getGroupHeaderDetail(group) {
    const cellStyle = `text-right ${classes.bigTotal}`

    const tips = group.get('totalTipAmount')

    return (
      <tr className={classes.newGroupHeader}>
        <td colSpan='4'>
          <misc.Day date={group.get('title')} />
        </td>
        <td className={cellStyle} colSpan={this.props.savedBillsEnabled ? '3' : '2'}>
          <misc.Amount amount={group.get('total') - tips} />
          <div className={classes.tipAmount}>
            <span> + Tip:&nbsp;</span>
            <misc.Amount amount={tips} />
          </div>
        </td>
      </tr>
    )
  }

  renderSplitBlockHeader() {
    const payment = this.getShownRow()
    let title = 'Bill'
    if (payment) {
      const billAmount = payment.getIn(
        ['billDetails', 'amounts', 'totalAmount'],
        payment.get('amount')
      )
      const paidAmount = payment.getIn(
        ['billDetails', 'amounts', 'paidAmount'],
        payment.get('amount')
      )
      const currency = payment.get('currency', 'ZAR')

      const displayAmount = formatCurrency(billAmount, currency)
      const displayPaidAmount = formatCurrency(paidAmount, currency)

      if (billAmount === paidAmount) {
        title = `${displayAmount} bill`
      } else {
        title = `${displayAmount} bill (${displayPaidAmount} paid)`
      }
    }

    return <SplitHeader title={title} closeSplitView={() => this.splitViewClosed()} />
  }

  paymentRefunded(paymentUUID, billUUID, refundResult) {
    this.fetchBillDetails(paymentUUID, billUUID)

    const originalTransaction = getItemFromArray(this.props.transactions, paymentUUID)
    const transaction = Immutable.fromJS(refundResult.data.originalTransaction).set(
      'items',
      originalTransaction.get('items')
    )

    this.props.dispatch(itemUpdated(TABLE_NAME, transaction))
  }

  renderSplitContent() {
    const payment = this.getShownRow()

    let lifespan = payment && <TransactionLifespan transaction={payment} />

    if (payment && payment.get('billDetails')) {
      lifespan = (
        <BillLifespan
          bill={payment.get('billDetails')}
          activePayment={payment}
          paymentRefunded={(paymentUUID, billUUID, refundResult) => {
            this.paymentRefunded(paymentUUID, billUUID, refundResult)
          }}
          onSetShownPayment={(rowData) => {
            this.rowClicked(rowData, true)
          }}
        />
      )
    }

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {lifespan}
        <TransactionReceipt transaction={payment} salesTaxes={this.props.salesTaxes} />
      </div>
    )
  }

  splitViewClosed() {
    this.setState({
      shownUUID: null,
      showingSplit: false,
    })
  }

  buttonActions() {
    const containerStyle = {
      width: '200px',
    }

    return [
      {
        label: 'Export Excel',
        onClick: this.trackExcelExport,
        buttonProps: {
          containerStyle,
          buttonClass: classnames(['tertiary', layout.widthFull]),
          label: 'Export Excel',
          name: TABLE_NAME,
          exportApi: '/transactions/transactions.xlsx',
          tooltip: 'Export your transactions to Excel using these filters',
        },
      },
      {
        label: 'Export CSV',
        onClick: this.trackCSVExport,
        buttonProps: {
          containerStyle,
          buttonClass: classnames(['tertiary', layout.widthFull]),
          label: 'Export CSV',
          name: TABLE_NAME,
          exportApi: '/transactions/transactions.csv',
          tooltip: 'Export your transactions to CSV using these filters',
        },
      },
    ]
  }

  renderFilters() {
    return (
      <FiltersContainer>
        <FilterBatch name={TABLE_NAME} containerClass={classes.filterContainer}>
          <div className={classes.filterBatchInner}>
            <TransactionTypeFilter
              key='type'
              localisation={this.props.localisation}
              testID={makeTestID('sales', 'history', 'saleType')}
            />

            <TransactionStateFilter
              key='state'
              localisation={this.props.localisation}
              testID={makeTestID('sales', 'history', 'saleState')}
            />

            <ApiFilter
              api='/staff/'
              defaultPath='users'
              placeholder='Filter staff members...'
              additionalParameters='excludeIntegrationStaff=false'
              filterKey='userUUID'
              filterIcon='staff'
              emptySelectedString='All Staff'
              testID={makeTestID('sales', 'history', 'staff')}
            />

            <DeviceFilters
              api='/readers/allocated'
              defaultPath='readers'
              offsetProp={5}
              placeholder='Filter devices...'
              filterKey='serialNumber'
              subtextKey='serialNumber'
              filterIcon='vcc'
              emptySelectedString='All Devices'
              titleKey='model'
              multipleSelectAsDefault
            />

            {this.props.customPaymentFilterEnabled && (
              <ApiFilter
                api='/customPaymentMethods/'
                defaultPath='customPaymentMethods'
                titleKey='name'
                iconKey='config'
                placeholder='Filter custom payment method...'
                filterKey='customPaymentMethodUUID'
                filterIcon='qr-code'
                emptySelectedString='All Custom Payment Methods'
                testID={makeTestID('sales', 'history', 'customPaymentMethod')}
              />
            )}
            <div className='filler' />
            <DateFilter defaults={[moment('2014-01-01'), moment().endOf('month').add(1, 'day')]} />
            <SearchBar
              placeholder={
                this.props.savedBillsEnabled
                  ? 'Search by amount, receipt #, bill or note'
                  : 'Search by amount, receipt # or note'
              }
              onSearchSubmit={this.onSearchPress}
            />
          </div>
        </FilterBatch>
      </FiltersContainer>
    )
  }

  trackExport = (type) => {
    track('SalesHistory> Export Button Clicked', { type })
  }

  trackExcelExport = () => {
    track('history_export_excel_clicked')
    this.trackExport('Excel')
  }

  trackCSVExport = () => {
    track('history_export_csv_clicked')
    this.trackExport('CSV')
  }

  render() {
    return (
      <SplitBlock
        showSave={false}
        hideActionButtonBar
        showingSplit={this.state.showingSplit}
        onDismiss={() => this.splitViewClosed()}
        renderSplitContent={() => this.renderSplitContent()}
        headerRenderer={() => this.renderSplitBlockHeader()}
      >
        <div className={`${classes.salesHistoryPage} ${blockClasses.outerBlock} touch`}>
          <BlockHeader title='Sales History' />

          {this.renderFilters()}
          <Table
            api='/transactions/'
            name={TABLE_NAME}
            rowsClickable
            pageSize={50}
            useSubItems
            blockClass={classes.transactionList}
            emptyTable={
              <EmptyTableView title='No transactions found'>
                <span>
                  There are no transactions for the filters you selected, try changing them.
                </span>
              </EmptyTableView>
            }
            getHeader={() => {
              return <TransactionHeaderRow showSavedBillsColumn={this.props.savedBillsEnabled} />
            }}
            getGroupHeader={(group) => {
              return this.getGroupHeaderDetail(group)
            }}
            getRow={(immutableRow, rowIndex) => {
              const transaction = immutableRow

              return (
                <TransactionRowItem
                  transaction={transaction}
                  key={immutableRow.get('uuid')}
                  selected={this.state.shownUUID === immutableRow.get('uuid')}
                  onClick={() => this.rowClicked(immutableRow)}
                  rowIndex={rowIndex}
                  showSavedBillColumn={this.props.savedBillsEnabled}
                />
              )
            }}
          />
          <div className={classes.export}>
            <DropDownButton
              label='Export'
              buttonClass='secondary'
              actions={this.buttonActions()}
              ButtonComponent={ExportButton}
              testID={makeTestID('sales', 'history', 'export')}
              onClick={this.exportButtonPress}
            />
          </div>
        </div>
      </SplitBlock>
    )
  }
}

const ReduxConnectedSalesHistoryPage = connect((state) => ({
  transactions: state.tables.getIn([TABLE_NAME, 'data']),
  localisation: localisation(state),
  savedBillsEnabled: hasFeature(state, FEATURES.SAVED_BILLS),
  customPaymentFilterEnabled: hasFeature(state, FEATURES.CUSTOM_PAYMENT_FILTER),
}))(UnconnectedReduxSalesHistoryPage)

export default FirebaseHandler.connect(
  ReduxConnectedSalesHistoryPage,
  Map({
    salesTaxes: {
      path: 'readOnly/store/salesTaxes',
    },
  })
)

UnconnectedReduxSalesHistoryPage.defaultProps = {
  salesTaxes: Map(),
}

UnconnectedReduxSalesHistoryPage.propTypes = {
  transactions: PropTypes.object,
  salesTaxes: PropTypes.objectOf(Map),
  localisation: PropTypes.objectOf(Map),
  savedBillsEnabled: PropTypes.bool,
  customPaymentFilterEnabled: PropTypes.bool,
  dispatch: PropTypes.func,
}
