import React, { useEffect } from 'react'
import { bindActionCreators } from '@reduxjs/toolkit'
import { connect } from 'react-redux'
import * as capitalActions from 'redux/modules/capital/actions'
import CapitalQualificationPage from 'components/capital/CapitalQualificationPage'
import CapitalPostQualificationPage from 'components/capital/CapitalPostQualificationPage'
import CapitalApplicationPage from 'containers/capital/CapitalApplicationPage'
import Request from 'components/request'
import CenteredContainerPage from 'components/pages/CenteredContainerPage'
import selectLocalisation from 'libs/localisation/localisation'
import { selectors } from 'redux/modules/capital'
import * as sessionSelectors from 'redux/modules/session/selectors'
import { RequestStatus } from 'redux/modules/helpers/requestHelper'
import CapitalErrorBlock from 'components/capital/partials/CapitalErrorBlock'
import { debounce } from 'lodash'
import { getTotalAmountRepaid } from 'components/capital/utils'
import {
  CapitalOffer,
  CapitalOfferStates,
  PrelimOffer,
  Qualification,
  Request as RequestType,
} from 'redux/modules/capital/types'
import { getENV } from 'libs/utils'

interface Props {
  qualification: Qualification
  qualificationRequest: RequestType
  prelimOffer: PrelimOffer
  prelimOffersRequest: RequestType
  capitalOffer: CapitalOffer
  capitalOfferRequest: RequestType
  userEmail: string
  fetchingData: boolean
  notifyMe: boolean
  canReApplyForAdvance: boolean
  capitalDeductions: any[]
  localisation: any
  actions: {
    applyForReAdvance: () => void
    createApplication: () => void
    sendApplication: () => void
    declineOffer: () => void
    acceptOffer: () => void
  }
  fetchOffers: () => void
  handleSliderAmountChanged: (amount: number) => void
  handleSliderMonthChanged: (term: number) => void
  handleDeductionsLinkClicked: () => void
  handleFindOutMoreClicked: () => void
  handleViewContractClicked: () => void
  handlePrelimOfferPresented: () => void
  capitalOptIn: () => void
}

export const CapitalPage: React.FunctionComponent<Props> = ({
  qualification,
  qualificationRequest,
  prelimOffer,
  prelimOffersRequest,
  capitalOffer,
  capitalOfferRequest,
  userEmail,
  fetchingData,
  notifyMe,
  canReApplyForAdvance,
  capitalDeductions,
  localisation,
  actions,
  fetchOffers,
  handleSliderAmountChanged,
  handleSliderMonthChanged,
  handleDeductionsLinkClicked,
  handleFindOutMoreClicked,
  handleViewContractClicked,
  handlePrelimOfferPresented,
  capitalOptIn,
}) => {
  useEffect(() => {
    fetchOffers()
  }, [fetchOffers])

  const hasCapitalOfferError =
    capitalOfferRequest && capitalOfferRequest.status === RequestStatus.FAILURE
  const hasAPrelimOffer = prelimOffer && prelimOffer.uuid
  const hasOfferApplicationInProgress = Object.values(CapitalOfferStates).includes(
    capitalOffer.state
  )

  if (hasCapitalOfferError) {
    return (
      <CenteredContainerPage>
        <CapitalErrorBlock
          title="We've hit a snag..."
          error={capitalOfferRequest.error!}
          buttonInfo={{
            text: 'Contact Support',
            contactSupportURL: getENV('CONTACT_SUPPORT_URL')!,
            onClick: fetchOffers,
          }}
        />
      </CenteredContainerPage>
    )
  }

  if (hasOfferApplicationInProgress) {
    return (
      <CenteredContainerPage>
        <Request
          loadingMessage='Fetching your capital application'
          errorTitle='We were unable to fetch your capital offer, please try again.'
          request={capitalOfferRequest}
          onRetryPress={fetchOffers}
        >
          <CapitalApplicationPage
            canReApplyForAdvance={canReApplyForAdvance}
            capitalAmount={prelimOffer.uptoAmount}
            capitalOffer={capitalOffer}
            currency={localisation.get('currency')}
            prelimOfferUUID={prelimOffer.uuid}
            userEmail={userEmail}
            fetchOffers={fetchOffers}
            formatCurrency={localisation.formatCurrency}
            getTotalAmountRepaid={getTotalAmountRepaid(capitalDeductions)}
            onApplyForReAdvance={actions.applyForReAdvance}
            onDeductionsLinkClicked={handleDeductionsLinkClicked}
            onFindOutMoreClicked={handleFindOutMoreClicked}
            onOfferAccepted={actions.acceptOffer}
            onOfferDeclined={actions.declineOffer}
            onSliderAmountChanged={(amount) => handleSliderAmountChanged(amount)}
            onSliderMonthChanged={(term) => handleSliderMonthChanged(term)}
            onViewContractClicked={handleViewContractClicked}
            sendApplication={actions.sendApplication}
          />
        </Request>
      </CenteredContainerPage>
    )
  }

  if (hasAPrelimOffer) {
    return (
      <CenteredContainerPage>
        <Request
          loadingMessage='Fetching your capital offers'
          errorTitle='We were unable to fetch capital offers, please try again.'
          request={prelimOffersRequest}
          onRetryPress={fetchOffers}
        >
          <CapitalPostQualificationPage
            providerName={prelimOffer.providerName}
            uptoAmount={prelimOffer.uptoAmount}
            uuid={prelimOffer.uuid}
            onCreateApplication={actions.createApplication}
            onFindOutMoreClicked={handleFindOutMoreClicked}
            onPrelimOfferPresented={handlePrelimOfferPresented}
          />
        </Request>
      </CenteredContainerPage>
    )
  }

  // merchant is not ready for capital yet (not qualified, no prelim)
  return (
    <CenteredContainerPage>
      <Request
        loadingMessage='Fetching your capital qualifications'
        errorTitle='We were unable to fetch capital offers, please try again.'
        request={qualificationRequest}
        onRetryPress={fetchOffers}
      >
        <CapitalQualificationPage
          metrics={qualification.metrics}
          optedInForNotification={notifyMe}
          onOptInPress={capitalOptIn}
          fetchingData={fetchingData}
          onFindOutMoreClicked={handleFindOutMoreClicked}
        />
      </Request>
    </CenteredContainerPage>
  )
}

const mapStateToProps = (state) => ({
  qualification: selectors.selectQualification(state),
  prelimOffer: selectors.selectPrelimOffer(state),
  capitalOffer: selectors.selectCapitalOffer(state),
  qualificationRequest: selectors.selectQualificationRequest(state),
  prelimOffersRequest: selectors.selectPrelimOffersRequest(state),
  localisation: selectLocalisation(state),
  capitalOfferRequest: selectors.selectCapitalOfferRequest(state),
  userEmail: sessionSelectors.selectUserEmail(state),
  capitalDeductions: selectors.selectCapitalDeductionsTableData(state),
  canReApplyForAdvance: selectors.selectCanReApplyForAdvance(state),
  notifyMe: selectors.selectCapitalQualifyingBusinessNotifyMe(state),
  fetchingData: selectors.selectCapitalQualifyingBusinessIsFetchingData(state),
})

const mapDispatchToProps = (dispatch) => ({
  handleDeductionsLinkClicked: () => dispatch(capitalActions.viewDeductions()),
  handleViewContractClicked: () => dispatch(capitalActions.viewContract()),
  handleFindOutMoreClicked: () => dispatch(capitalActions.findOutMoreClicked()),
  handlePrelimOfferPresented: () => dispatch(capitalActions.prelimOfferPresented()),
  handleSliderAmountChanged: debounce(
    (amount: number) => dispatch(capitalActions.sliderAmountChanged(amount)),
    500
  ),
  handleSliderMonthChanged: debounce(
    (term: number) => dispatch(capitalActions.sliderMonthChanged(term)),
    500
  ),
  fetchOffers: () => dispatch(capitalActions.fetchOffers()),
  capitalOptIn: () => dispatch(capitalActions.capitalOptIn()),
  actions: bindActionCreators({ ...capitalActions }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CapitalPage)
