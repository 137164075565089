import $ from 'jquery'

import { getENV } from 'libs/utils'
import { downloadFile, getToken } from 'libs/api'
import { formatParamsString } from 'libs/invoices/utils'

import { getCustomerFilter, getDateFilter, getPaymentMethodFilter, getStatusFilter } from './utils'

export const createExportFilename = (exportApi) =>
  `invoices_export.${exportApi.includes('invoices-excel') ? 'xlsx' : 'csv'}`

export const buildUrl = (uri, api, params) => `${uri}/api/${api}${params}`

export const exportFile = (exportApi, batch) => {
  // Old portal seems to have potentially added a bad cookie that breaks export - unset it.
  // Our real auth cookie will use .yoco.co.za
  document.cookie =
    'yoco-auth-cookie=; path=/; domain=portal.yoco.co.za; expires = Thu, 01 Jan 1970 00:00:00 GMT'

  const params = {
    ...getPaymentMethodFilter(batch),
    ...getStatusFilter(batch),
    ...getDateFilter(batch),
    ...getCustomerFilter(batch),
  }

  const url = buildUrl(getENV('INVOICES_BASE_URL'), exportApi, formatParamsString(params))

  $.ajax({
    type: 'GET',
    url,
    headers: { 'X-Auth-Token': getToken() },
    xhrFields: { responseType: 'blob' },
    success: (blob) => {
      const filename = createExportFilename(exportApi)

      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE workaround for "HTML7007
        window.navigator.msSaveBlob(blob, filename)
      } else {
        const URL = window.URL || window.webkitURL
        const downloadUrl = URL.createObjectURL(blob)

        downloadFile(downloadUrl, filename)
      }
    },
  })
}
