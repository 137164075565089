import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import * as forms from 'libs/forms'
import { getProvinces } from 'libs/utils'

import { connect } from 'react-redux'
import { Map } from 'immutable'
import { FORM_NAMES } from 'redux/modules/onboarding'
import { fieldUpdated, multipleFieldsUpdated } from 'redux/modules/forms'
import selectLocalisation from 'libs/localisation/localisation'
import classes from './styles.module.scss'
import PlacesField from './components/PlacesField'
import UseMyLocation from './components/UseMyLocation'

function TradingAddressForm({
  localisation,
  firstLine,
  updateFirstLineField,
  updateAddressFields,
  onSuccess,
}) {
  const [provinces, setProvinces] = useState([])

  useEffect(() => {
    getProvinces(localisation.get('countryCode'), (data) =>
      setProvinces(data.data.map((p) => ({ label: p.name, value: p.name })))
    )
  }, [localisation, setProvinces])

  const dataProcessor = (data) => {
    const address = {
      firstLine: data.get('firstLine'),
      secondLine: data.get('secondLine'),
      thirdLine: data.get('thirdLine'),
      city: data.get('city'),
      province: data.get('province'),
      postalCode: data.get('postalCode'),
      countryCode: 'ZA',
      latitude: data.get('latitude'),
      longitude: data.get('longitude'),
    }
    return data.setIn(['address'], address)
  }

  return (
    <forms.Form
      action='/onboarding/business/details'
      name='businessAddress'
      dataProcessor={dataProcessor}
      onSuccess={onSuccess}
    >
      <div>
        <forms.FormBlock>
          <div>
            <div className={classes.firstLineFieldContainer}>
              <PlacesField
                label='Street address'
                placeholder='56 Shortmarket Street'
                firstLine={firstLine}
                updateFirstLine={updateFirstLineField}
                updateAddressFields={updateAddressFields}
                required
              />
              <div className={classes.linkContainer}>
                <UseMyLocation onLocateAddress={updateAddressFields} />
              </div>
            </div>

            <div className={classes.firstLineFieldContainer}>
              <div>
                <forms.TextField name='thirdLine' label='Building/complex' isFullWidth />
              </div>
            </div>

            <forms.TextField name='secondLine' label='Suburb' required />

            <forms.TextField name='city' label='City' required />

            <forms.SelectField
              name='province'
              label='Province'
              placeholder='Province'
              validators={[new forms.RequiredValidator('This field is required')]}
              options={provinces}
            />

            <forms.TextField name='postalCode' label='Postal code' required />
          </div>
        </forms.FormBlock>
      </div>
    </forms.Form>
  )
}

TradingAddressForm.propTypes = {
  localisation: PropTypes.objectOf(Map).isRequired,
  firstLine: PropTypes.string,
  updateFirstLineField: PropTypes.func.isRequired,
  updateAddressFields: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
}

TradingAddressForm.defaultProps = {
  firstLine: undefined,
  onSuccess: undefined,
}

const mapStateToProps = (state) => ({
  localisation: selectLocalisation(state),
  firstLine: state.forms.getIn([FORM_NAMES.BUSINESS_ADDRESS, 'data', 'firstLine']),
})

const mapDispatchToProps = (dispatch) => ({
  updateFirstLineField: (firstLine) =>
    dispatch(fieldUpdated(FORM_NAMES.BUSINESS_ADDRESS, 'firstLine', firstLine)),
  updateAddressFields: (data) => dispatch(multipleFieldsUpdated(FORM_NAMES.BUSINESS_ADDRESS, data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TradingAddressForm)
