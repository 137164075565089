/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import PropTypes from 'prop-types'

import { track } from 'libs/analytics'
import SplitBlock from 'components/blocks/SplitBlock'
import SplitHeader from 'components/headers/SplitHeader'
import FlatButton from 'components/buttons/FlatButton'

import InvoicePreviewCard from './PreviewCard'

function InvoicePreview({ invoice, onSendPressed, onCancelPressed, onClose, children }) {
  return (
    <SplitBlock
      showingSplit
      headerRenderer={() => (
        <SplitHeader
          title={invoice.get('status') === 'draft' ? 'Preview and send' : 'View PDF'}
          actions={
            <FlatButton
              label='Print'
              className='secondary'
              onClick={() => {
                track('invoices_print_button_clicked', { name: 'invoices_print_button_clicked' })
                try {
                  window.frames.invoicePreviewIframe.focus()
                  window.frames.invoicePreviewIframe.print()
                } catch (e) {
                  console.error('Failed to trigger Print', e)
                }
              }}
            />
          }
          closeSplitView={onClose}
        />
      )}
      saveLabel={invoice.get('status') === 'draft' ? 'Send' : 'Resend'}
      showSave={invoice.get('status') !== 'paid'}
      savePressed={() => {
        if (invoice.get('status') === 'draft') {
          track('invoices_send_button_clicked', { name: 'invoices_send_button_clicked' })
        } else {
          track('invoices_details_view_pdf_resend_button_clicked', {
            name: 'invoices_details_view_pdf_resend_button_clicked',
          })
        }

        onSendPressed()
      }}
      cancelLabel='Back'
      cancelPressed={onCancelPressed}
      renderSplitContent={() => <InvoicePreviewCard invoice={invoice} />}
      onDismiss={onClose}
    >
      {children}
    </SplitBlock>
  )
}

InvoicePreview.propTypes = {
  invoice: PropTypes.func.isRequired,
  onSendPressed: PropTypes.func,
  onCancelPressed: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.node,
}

export default InvoicePreview
