import { combineReducers } from '@reduxjs/toolkit'
import { Record } from 'immutable'

import keyMirror from 'libs/key-mirror'
import { handleRequest, RequestStatus } from 'redux/modules/helpers/requestHelper'

export const ActionTypes = keyMirror(
  {
    FETCH_REQUEST: null,
    FETCH_SUCCESS: null,
    FETCH_FAILURE: null,
  },
  '@invoices/onboarding/'
)

const initialRequestState = Record({
  status: RequestStatus.STARTED,
  error: null,
})

function requestReducer(state = initialRequestState(), action) {
  return handleRequest(
    ActionTypes.FETCH_REQUEST,
    ActionTypes.FETCH_SUCCESS,
    ActionTypes.FETCH_FAILURE,
    state,
    action
  )
}

const initialState = Record({})

function reducer(state = initialState(), action) {
  switch (action.type) {
    case ActionTypes.FETCH_SUCCESS:
      return Record(action.payload)()
    default:
      return state
  }
}

export default combineReducers({
  request: requestReducer,
  data: reducer,
})
