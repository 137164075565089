import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@yoco/design-system-icons/dist/react'

import classes from './buttons.module.scss'

export default class CheckboxButton extends Component {
  getClassName() {
    let className = classes.checkboxButton

    if (!this.props.disabled) {
      className = `${className}`
    } else if (this.props.disabled) {
      className = `${className} ${classes.greyCheckBox}`
    }

    if (this.props.className) {
      className = `${className} ${this.props.className}`
    }

    if (this.props.selected && !this.props.disabled) {
      className = `${className} ${classes.selected}`
    } else if (this.props.selected && this.props.disabled) {
      className = `${className} ${classes.disabled}`
    }

    return className
  }

  clicked(event) {
    if (this.props.onClick) {
      this.props.onClick(event)
    }
  }

  render() {
    return (
      <button
        className={this.getClassName()}
        type='button'
        onClick={(event) => this.clicked(event)}
      >
        <Icon name='tick' size={24} color='white' />
      </button>
    )
  }
}

CheckboxButton.propTypes = {
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}
