/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react'
import FileUploadProgress from 'react-fileupload-progress'
import PropTypes from 'prop-types'

import { getENV } from 'libs/utils'

export default class FileUpload extends Component {
  constructor(props) {
    super(props)

    this.state = {
      fileUploaded: false,
      fileUploading: false,
    }
  }

  beforeSend = (request) => {
    if (this.props.startUploadCallback) {
      this.props.startUploadCallback(request)
    }
    this.setState({
      showLoader: true,
      fileUploaded: false,
      fileUploading: true,
    })
    request.setRequestHeader('X-Auth-Token', yocoStorage.getItem('token'))
    return request
  }

  onAbort = () => {
    this.setState({
      showLoader: false,
      fileUploaded: false,
      fileUploading: false,
    })
  }

  onError = (e, request) => {
    if (this.props.onFileUploadError) {
      this.props.onFileUploadError(request)
    }
    this.setState({
      showLoader: false,
      fileUploaded: false,
      fileUploading: false,
    })
  }

  onProgress = (e, request, progress) => {
    this.setState({
      progress,
    })
  }

  onLoad = (e, request) => {
    const response = JSON.parse(request.responseText)
    if (this.props.completeCallback) {
      this.props.completeCallback(response)
    }
    this.setState({
      showLoader: false,
      fileUploaded: true,
      fileUploading: false,
    })
  }

  customFormRenderer() {
    return null
  }

  customProgressRenderer() {
    return null
  }

  render() {
    const coreURL = getENV('CORE_URL')
    let url = `${coreURL}api/common/v1/files/`
    if (this.props.grouping) {
      url = `${coreURL}api/common/v1/files/?grouping=${this.props.grouping}`
    }
    if (this.props.targetUUID) {
      url = `${url}&targetUUID=${this.props.targetUUID}`
    }
    if (this.props.documentType) {
      url = `${url}&documentType=${this.props.documentType}`
    }
    // Documentation: https://github.com/georgeosddev/react-fileupload-progress
    return (
      <FileUploadProgress
        url={url}
        onLoad={this.onLoad.bind(this)}
        onError={this.onError.bind(this)}
        onAbort={this.onAbort.bind(this)}
        onProgress={this.onProgress.bind(this)}
        beforeSend={this.beforeSend.bind(this)}
        formGetter={this.formGetter.bind(this)}
        formRenderer={this.customFormRenderer.bind(this)}
        progressRenderer={this.customProgressRenderer.bind(this)}
        method='POST'
      />
    )
  }
}

FileUpload.propTypes = {
  startUploadCallback: PropTypes.func,
  completeCallback: PropTypes.func,
  targetUUID: PropTypes.string,
  grouping: PropTypes.string,
  documentType: PropTypes.string,
}
