import { Map } from 'immutable'
import { createSelector } from 'reselect'

import * as Utils from '../utils'

// Default localisation of this business
const countryLocalisationSelector = (state) =>
  state.session.getIn(['business', 'countryLocalisation'], Map())
// Business specific overrides
const businessLocalisationSelector = (state) =>
  state.session.getIn(['business', 'businessLocalisation'], Map())

const defaults = Map({
  currency: 'ZAR',
  currencySymbol: 'R',
  language: 'en',
  timezoneId: 'Africa/Johannesburg',
  countryCode: 'ZA',
  taxNumberName: 'VAT Number',
  currencyAllowsDecimals: true,
  acceptsCardPayments: true,
  maximumAmount: 100000,
})

export function doesAcceptCardPayments(localisation, route) {
  if (!route.isForCardAcceptingLocalisation || !localisation) {
    return true
  }

  if (
    !localisation.get('acceptsCardPayments', defaults.get('acceptsCardPayments')) &&
    route.isForCardAcceptingLocalisation === true
  ) {
    return false
  }

  return true
}

function addLocalisationHelpers(localisation) {
  const currencyAllowsDecimals = localisation.get(
    'currencyAllowsDecimals',
    defaults.get('currencyAllowsDecimals', true)
  )

  const businessLocalisation = localisation.merge({
    currencyPlaceholder: currencyAllowsDecimals ? '0.00' : '0',
  })

  const currency = businessLocalisation.get('currency', defaults.get('currency'))

  businessLocalisation.keypadDigitsToAmount = (amount, isPercentage = false) => {
    // For our keypads where we optionally enforce two decimal places
    if (!isPercentage && currencyAllowsDecimals) {
      return parseFloat(amount) / 100
    }

    return parseFloat(amount)
  }

  businessLocalisation.amountToKeypadDigits = (amount, isPercentage = false) => {
    // For our keypads where we optionally enforce two decimal places
    if (!isPercentage && currencyAllowsDecimals) {
      return amount * 100
    }

    return amount
  }

  businessLocalisation.formatCurrency = (amount) => {
    return Utils.formatCurrency(amount, currency)
  }

  businessLocalisation.formatDecimalPlaces = (maybeAmount) => {
    const amount = maybeAmount || 0

    if (typeof amount !== 'string') {
      if (!currencyAllowsDecimals) {
        return Utils.round(amount).toString()
      }

      return amount.toFixed(2)
    }

    return amount
  }

  businessLocalisation.amountToKeypadDigits = (amount, isPercentage = false) => {
    // For our keypads where we optionally enforce two decimal places
    if (!isPercentage && currencyAllowsDecimals) {
      return amount * 100
    }

    return amount
  }

  return businessLocalisation
}

function currencySymbol(currency) {
  switch (currency) {
    case 'ZAR':
      return 'R'
    case 'USD':
      return '$'
    case 'GBP':
      return '£'
    case 'EUR':
      return '€'
    default:
      return currency
  }
}

const localisation = createSelector(
  countryLocalisationSelector,
  businessLocalisationSelector,
  (countryLocalisation, businessLocalisation) => {
    const currency = businessLocalisation.get(
      'currency',
      countryLocalisation.get('defaultCurrency')
    )
    return addLocalisationHelpers(
      defaults.merge(countryLocalisation).merge(
        Map({
          currency,
          currencySymbol: currencySymbol(currency),
          language: businessLocalisation.get(
            'language',
            countryLocalisation.get('defaultLanguage')
          ),
          timezoneId: businessLocalisation.get(
            'timezoneId',
            countryLocalisation.get('defaultTimezoneId')
          ),
        }).filter((value) => value !== undefined)
      )
    )
  }
)

export default localisation
