/* eslint-disable consistent-return */
import colors from 'ui/colors'

import classes from './transactions.module.scss'

/**
 * Util functions for transactions
 */

/**
 * Calculates the per item discount
 * by dividing totalDiscount by quantity.
 *
 * This gives the absolute value, in case of
 * negative quantities for refunds.
 * @param {Number} totalDiscount
 * @param {Number} quantity
 */
export const calculatePerItemDiscountAmount = (totalDiscount, quantity) =>
  Math.abs(totalDiscount / quantity)

/**
 * Calculates the total per item
 * for the given quantity, amount and totalDiscount
 *
 * In the case of negative quantity for refunds,
 * add the totalDiscount instead of subtracting
 * @param {Number} quantity
 * @param {Number} amount
 * @param {Number} totalDiscount
 */
export const calculatePerItemTotal = (quantity, amount, totalDiscount) =>
  quantity > 0 ? amount * quantity - totalDiscount : amount * quantity + totalDiscount

export function transactionStateClass(transactionState) {
  if (transactionState === 'aborted') {
    return classes.aborted
  }
  if (transactionState === 'error') {
    return classes.error
  }
  if (transactionState === 'declined') {
    return classes.declined
  }
  if (transactionState === 'approved' || transactionState === 'refunded') {
    return classes.approved
  }
  if (transactionState === 'pending' || transactionState === 'created') {
    return classes.pending
  }

  return null
}

export function transactionStateIcon(transactionState) {
  if (transactionState === 'aborted') {
    // Circle with a horizontal line
    return 'payment-aborted'
  }
  if (transactionState === 'error') {
    return 'payment-error'
  }
  if (transactionState === 'declined') {
    return 'payment-declined'
  }
  if (transactionState === 'approved' || transactionState === 'refunded') {
    return 'payment-approved'
  }
  if (transactionState === 'pending' || transactionState === 'created') {
    return 'clock'
  }
}

export function transactionStateColor(transactionState) {
  if (transactionState === 'aborted') {
    return colors['Gray-500']
  }
  if (transactionState === 'error') {
    return colors['Red-500']
  }
  if (transactionState === 'declined') {
    return colors['Yellow-500']
  }
  if (transactionState === 'approved' || transactionState === 'refunded') {
    return colors['Green-400']
  }
  if (transactionState === 'pending' || transactionState === 'created') {
    return colors['Gray-200']
  }
}

export function paymentMethodIcon(paymentMethod, isSplitBill = false) {
  if (isSplitBill) {
    return 'split-bill'
  }
  if (paymentMethod === 'credit_card') {
    return 'credit-card'
  }
  if (paymentMethod === 'cash') {
    return 'cashnotes'
  }
  if (paymentMethod === 'system') {
    return 'adjust'
  }
  if (paymentMethod === 'voucher') {
    return 'voucher'
  }
  return 'question'
}
