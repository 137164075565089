import React, { useState } from 'react'

import ViewVideoPopup from 'components/popups/ViewVideoPopup'
import Spacer from 'ui/layout/Spacer'
import styles from './CapitalHelpVideo.module.scss'

interface Props {
  onFindOutMoreClicked: () => void
}

const CapitalHelpVideo: React.FunctionComponent<Props> = ({ onFindOutMoreClicked }) => {
  const [showVideo, setShowVideo] = useState(false)
  const togglePopup = () => setShowVideo(!showVideo)
  const handleFindOutMoreClicked = () => {
    togglePopup()
    onFindOutMoreClicked()
  }

  return (
    <div data-testid='CapitalHelpVideo'>
      <Spacer size='medium' />
      <button type='button' onClick={handleFindOutMoreClicked} className={styles.button}>
        Find out more
      </button>
      <ViewVideoPopup showing={showVideo} onCancel={togglePopup} />
    </div>
  )
}

export default CapitalHelpVideo
