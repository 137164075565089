import img0 from 'assets/images/online/payment-page-image-1.png'
import img1 from 'assets/images/online/payment-page-image-2.png'
import img2 from 'assets/images/online/payment-page-image-3.png'

const infoCards = [
  {
    img: img0,
    title: 'What is Payment Page?',
    blurb: `Get paid online by your customers through one link. Copy the link below and share it anywhere to accept online card payments from anyone.`,
    linkAText: '',
    linkAHref: '',
    popUp: false,
    tracking: '',
  },
  {
    img: img1,
    title: 'Add card payments to your invoices.',
    blurb: `Add your Payment Page link to your invoice template on Word, Excel or any custom template to accept online card payments from your customers.`,
    linkAText: '',
    linkAHref: '',
    popUp: true,
    tracking: '',
  },
  {
    img: img2,
    title: 'Replace slow EFTs with card payments.',
    blurb: `Use Payment Page to receive fast deposits for services you'll fulfil later. Or use it to accept international card payments from anywhere in the world.`,
    linkAText: '',
    linkAHref: '',
    popUp: false,
    tracking: '',
  },
]

export default infoCards
