import styles from 'components/capital/capital.module.scss'
import CapitalFrequentlyAskedQuestionsBlock from 'components/capital/partials/CapitalFrequentlyAskedQuestionsBlock'
import CapitalHeaderBlock from 'components/capital/partials/CapitalHeaderBlock'
import CapitalHelpMessage from 'components/capital/partials/CapitalHelpMessage'
import CapitalQualifiedBlock from 'components/capital/partials/CapitalQualifiedBlock'
import { getLendingPartnerDisplayName } from 'components/capital/utils'
import React, { useEffect, useRef } from 'react'
import { LendingPartners } from 'redux/modules/capital/types'

interface Props {
  providerName: LendingPartners
  uptoAmount: number
  uuid: string
  onCreateApplication: (
    prelimOfferUUID: string,
    { capitalAmount }: { capitalAmount: number }
  ) => void
  onFindOutMoreClicked: () => void
  onPrelimOfferPresented: () => void
}

const CapitalPostQualificationPage: React.FunctionComponent<Props> = ({
  providerName,
  uptoAmount,
  uuid,
  onCreateApplication,
  onFindOutMoreClicked,
  onPrelimOfferPresented,
}) => {
  const hasMounted = useRef(false)

  useEffect(() => {
    if (hasMounted.current === false) {
      hasMounted.current = true
      onPrelimOfferPresented()
    }
  }, [onPrelimOfferPresented])

  return (
    <div className={styles.page}>
      <CapitalHeaderBlock
        title='Get Yoco Capital to grow your business.'
        description={`
        Yoco has partnered with ${getLendingPartnerDisplayName(
          providerName
        )}, the specialists in SME funding. 
        ${getLendingPartnerDisplayName(
          providerName
        )}'s aim is provide small to medium-sized businesses with financial solutions that work with your business cash flow.
      `}
      />
      <CapitalQualifiedBlock
        uptoAmount={uptoAmount}
        uuid={uuid}
        onCreateApplication={onCreateApplication}
        onFindOutMoreClicked={onFindOutMoreClicked}
      />
      <CapitalFrequentlyAskedQuestionsBlock />
      <CapitalHelpMessage />
    </div>
  )
}

export default CapitalPostQualificationPage
