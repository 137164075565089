import React, { Component } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

import classes from './settlements.module.scss'

class SettlementCard extends Component {
  getFormattedDate = () => {
    return (
      <span className={classes.settlementDate}>
        {` -  ${moment(this.props.date).format('D MMM YYYY')}`}
      </span>
    )
  }

  render() {
    const { header, amount, date } = this.props
    return (
      <div className={classes.settlementCardWrapper}>
        <div className={classes.settlementHeader}>
          <span>{header}</span>
          {date ? this.getFormattedDate() : null}
        </div>
        <div className={classes.settlementBlockAmount}>
          <span>{amount}</span>
        </div>
      </div>
    )
  }
}

SettlementCard.propTypes = {
  header: PropTypes.string,
  amount: PropTypes.string,
  date: PropTypes.string,
}

export default SettlementCard
