import React, { useCallback, useMemo } from 'react'

import CustomerView from '../View'

import { getIsNewCustomer } from './utils'
import { getSplitViewProps } from './ListViewSplit.utils'

export const useCustomerSplitBlockProps = (customer, setCustomer) => {
  const onDismiss = useCallback(() => {
    setCustomer(undefined)
  }, [setCustomer])

  const onDeletePressed = useCallback(() => {
    setCustomer(!getIsNewCustomer(customer) ? customer.set('confirmingDelete', true) : undefined)
  }, [customer, setCustomer])

  const splitContent = useCallback(
    () => <CustomerView customer={customer} setCustomer={setCustomer} />,
    [customer, setCustomer]
  )

  const splitBlockProps = useMemo(
    () =>
      getSplitViewProps({
        customer,
        onDeletePressed,
        splitContent,
      }),
    [customer, onDeletePressed, splitContent]
  )

  return { onDismiss, ...splitBlockProps }
}
