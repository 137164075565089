import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import classes from './popover.module.scss'

function Popover({ children, className }) {
  return <div className={classnames(classes.popover, className)}>{children}</div>
}

Popover.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default Popover
