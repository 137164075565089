import React from 'react'
import Alert from 'components/notifications/Alert'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { selectIsRegisteredBusiness } from 'redux/modules/session/selectors'
import FlatButton from 'components/buttons/FlatButton'
import { push } from 'react-router-redux'

function StuckInRequestedAlert({ isRegisteredBusiness, navigateToOnboarding }) {
  return (
    <Alert messageType='warning' closable={false}>
      <p>We have received your request to update your bank account information.</p>
      <br />
      {isRegisteredBusiness ? (
        <p>
          Please note that we will only be able to process your bank account information once you
          have confirmed your identity and completed setup of your business.
        </p>
      ) : (
        <p>
          Please note that we will only be able to process your bank account information once you
          have confirmed your identity.
        </p>
      )}
      <br />
      <FlatButton className='secondary' onClick={navigateToOnboarding}>
        Complete setup
      </FlatButton>
    </Alert>
  )
}

StuckInRequestedAlert.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  isRegisteredBusiness: PropTypes.bool.isRequired,
  navigateToOnboarding: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  isRegisteredBusiness: selectIsRegisteredBusiness(state),
})

const mapDispatchToProps = (dispatch) => ({
  navigateToOnboarding: () => dispatch(push('/onboarding')),
})

export default connect(mapStateToProps, mapDispatchToProps)(StuckInRequestedAlert)
