import React, { Component } from 'react'
import PropTypes from 'prop-types'
import numeral from 'numeral'

import classes from './transactions.module.scss'

export default class TransactionItem extends Component {
  getNote() {
    if (this.props.item.get('isTip')) {
      return (
        <span className={classes.note}>
          R&nbsp;
          {numeral(this.props.item.get('amount')).format('0,0.00')}
        </span>
      )
    }

    if (this.props.item.get('note')) {
      return <span className={classes.note}>{this.props.item.get('note')}</span>
    }

    return null
  }

  getQuantity() {
    if (this.props.item.get('quantity') > 1) {
      return (
        <span className={classes.quantity}>
          {this.props.item.get('quantity')}
          &nbsp;x&nbsp;
        </span>
      )
    }

    return null
  }

  getClassName() {
    let divClass = classes.transactionItemDiv
    if (this.props.item.get('note') || this.props.item.get('isTip')) {
      divClass += ` ${classes.hasNote}`
    }

    return divClass
  }

  getName() {
    if (this.props.item.get('name')) {
      return this.props.item.get('name')
    }

    return 'Custom Amount'
  }

  render() {
    return (
      <div className={this.getClassName()}>
        {this.getQuantity()}
        <span className={classes.name}>{this.getName()}</span>
        {this.getNote()}
      </div>
    )
  }
}

TransactionItem.propTypes = {
  item: PropTypes.object,
}
