/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import * as forms from 'libs/forms'
import FormCard from 'components/cards/FormCard'
import { refreshTable } from 'redux/modules/tables'

import classes from './locations.module.scss'

class UnconnectedStoreLocationForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showDeleteModal: false,
    }
  }

  updateTable() {
    this.props.dispatch(refreshTable(this.props.formName))
    this.props.closeSplitView && this.props.closeSplitView()
  }

  renderDefaultLocationField() {
    if (this.props.shownRow && this.props.shownRow.get('isDefault')) {
      return (
        <div>
          <div className={classes.defaultNote}>
            <span className={classes.defaultNoteText}>Default</span>
          </div>
          <div className={classes.description}>You cannot delete your default store.</div>
        </div>
      )
    }

    return <forms.CheckBoxField name='isDefault' label='Set this as your default store?' />
  }

  render() {
    const { shownRow } = this.props
    const api = shownRow ? `/storeLocations/${shownRow.get('uuid')}/` : '/storeLocations/'

    return (
      <forms.Form
        action={api}
        name={this.props.formName}
        initialData={shownRow}
        initialDataProcessor={(data) => {
          if (data.get('isDefault') === undefined) {
            return data.set('isDefault', false)
          }

          return data
        }}
        onSuccess={() => this.updateTable()}
      >
        <FormCard header='Location'>
          <forms.TextField
            isFullWidth
            required
            name='name'
            label='Name'
            placeholder='Name'
            validators={[new forms.RequiredValidator('You must provide a name')]}
          />
          {this.renderDefaultLocationField()}
        </FormCard>
      </forms.Form>
    )
  }
}

const EditStoreLocationForm = connect(() => ({}))(UnconnectedStoreLocationForm)

export default EditStoreLocationForm

UnconnectedStoreLocationForm.propTypes = {
  shownRow: PropTypes.object,
  dispatch: PropTypes.func,
  closeSplitView: PropTypes.func,
  formName: PropTypes.string,
}
