/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState, useEffect } from 'react'
import { Map, List } from 'immutable'
import { connect } from 'react-redux'

import { FirebaseHandler } from 'libs/firebase'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import Spacer from 'ui/layout/Spacer'
import FlatButton from 'components/buttons/FlatButton'
import Alert from 'components/notifications/Alert'
import BlockHeader from 'components/headers/BlockHeader'
import SubHeading from 'ui/components/SubHeading'
import CopyableLink from 'components/online/CopyableLink'
import ContentCard from 'components/cards/ContentCard'
import { callJSONApi } from 'libs/api'
import HStack from 'ui/layout/HStack'
import VStack from 'ui/layout/VStack'

import { selectBusinessUUID, selectUserUUID } from 'redux/modules/session/selectors'
import closedSign from 'assets/images/closed-sign.svg'
import { hasOnlineProducts, storeCreationSteps, trackStorefront } from './utils'
import { usePublishStore } from './hooks'
import ConfirmPublishModal from './components/ConfirmPublishModal/ConfirmPublishModal'
import Classes from './OnlineStorePage.module.scss'
import OnlineStoreStepsCard from './components/ OnlineStoreStepsCard/OnlineStoreStepsCard'

interface Props {
  products: Product
  userUUID: string
  businessUUID: string
}

interface Product {
  title: string
  toList: () => List<[]>
}

const OnlineStorePage: React.FunctionComponent<Props> = ({ products, businessUUID, userUUID }) => {
  const { dataLoaded, isPublished, togglePublished, isSaving, publishError } = usePublishStore()
  const [showPublishPopup, setShowPublishPopup] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [storeLink, setStoreLink] = useState('')
  const [qrUrl, setQrUrl] = useState('')
  const [hasProducts, setHasProducts] = useState(false)
  const [trackingIdentifier, setTrackingIdentifier] = useState('')

  const storeSteps = storeCreationSteps({ hasProducts, qrUrl })
  useEffect(() => {
    callJSONApi(
      '/paymentPage/',
      'GET',
      null,
      (data) => {
        const { paymentPages } = data.data
        const paymentPage = paymentPages.length > 0 ? paymentPages[0] : null
        if (paymentPage) {
          setStoreLink(`https://store.yoco.com/s/${paymentPage.path}`)
        }
      },
      (prettyError) => {
        setErrorMessage(prettyError)
        console.error('Unable to load store link', prettyError)
      }
    )
    setTrackingIdentifier(
      isPublished ? 'storefront_deactivate_clicked' : 'storefront_activate_clicked'
    )
    setQrUrl(storeLink)
    if (products) {
      const productList = products.toList().toJS()
      setHasProducts(hasOnlineProducts(productList))
    }
  }, [storeLink, products, isPublished, businessUUID, userUUID])

  useEffect(() => {
    setErrorMessage(publishError)
  }, [publishError])

  const togglePublishedPopup = () => {
    trackStorefront(businessUUID, userUUID, trackingIdentifier)
    setShowPublishPopup((showPublishPopup) => !showPublishPopup)
  }

  return (
    <MaxWidthBlock>
      <ContentCard type='warning'>
        <HStack>
          <img src={closedSign} alt='Closed' />
          <VStack spacing='none'>
            <div className={Classes.cardHeading}>
              Important Notice: Storefront is being sunsetted
            </div>
            <div className={Classes.cardBody}>
              Unfortunately we are discontinuing Storefront at the end of February 2023. This means
              your online store will be deactivated indefinitely. We have sent you an email with
              more information.
            </div>
          </VStack>
        </HStack>
      </ContentCard>
      <BlockHeader
        title='Online Store'
        actions={
          <HStack spacing='xs'>
            {dataLoaded && (
              <FlatButton
                onClick={togglePublishedPopup}
                type='tertiary'
                disabled={isSaving}
                className={`blueBackground ${isPublished ? Classes.deleteButton : 'primary'}`}
                label={`${isPublished ? 'Deactivate Store' : 'Activate Store'}`}
              />
            )}
            <FlatButton
              onClick={() => {
                window.open(storeLink)
              }}
              type='tertiary'
              className='secondary'
              label='Preview'
            />
          </HStack>
        }
      />

      {errorMessage && (
        <Alert
          messageType='danger'
          message={errorMessage}
          closable
          onClose={() => setErrorMessage(undefined)}
        />
      )}

      {storeLink && (
        <div>
          <CopyableLink link={storeLink} />
        </div>
      )}

      <Spacer size='large' />

      <SubHeading>Set up your store</SubHeading>
      <div className={Classes.storeStepContainer}>
        {storeSteps.map((step) => (
          <OnlineStoreStepsCard
            key={step.heading}
            heading={step.heading}
            blurb={step.blurb}
            btnLabel={step.btnLabel}
            iconName={step.iconName}
            btnLink={step.btnLink}
            btnDisabled={step.btnDisabled}
            todoTag={step.todoTag}
            btnOnPress={step.btnOnPress}
          />
        ))}
      </div>
      <ConfirmPublishModal
        isPublished={isPublished}
        togglePublished={togglePublished}
        showPopup={showPublishPopup}
        setShowPopup={setShowPublishPopup}
        isSaving={isSaving}
      />
    </MaxWidthBlock>
  )
}

const ReduxConnectedOnlineStorePage = connect((state) => ({
  businessUUID: selectBusinessUUID(state),
  userUUID: selectUserUUID(state),
}))(OnlineStorePage)

export default FirebaseHandler.connect(
  ReduxConnectedOnlineStorePage,
  Map({
    products: {
      path: 'readOnly/store/products',
    },
  })
)
