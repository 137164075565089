import React, { Component } from 'react'
import { Map, List } from 'immutable'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { clearForm } from 'redux/modules/forms'
import { refreshTable } from 'redux/modules/tables'
import RefundPopup from 'components/popups/RefundPopup'
import SendReceiptPopup from 'components/popups/SendReceiptPopup'

import classes from './transactions.module.scss'
import BillPayment from './BillPayment'

class BillLifespan extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showRefundModal: false,
      showReceiptModal: false,
    }
  }

  refundedPayment = (result) => {
    if (result.status === 200) {
      this.props.dispatch(refreshTable('billsHistory'))

      this.setState({
        showRefundModal: false,
      })

      this.close()

      const payment = this.props.activePayment
      this.props.paymentRefunded(payment.get('uuid'), payment.get('billUUID'), result)
    }
  }

  onRefundCancel = () => {
    this.setState({
      showRefundModal: false,
    })
    this.props.dispatch(clearForm('refundTransaction'))
  }

  onReceiptCancel = () => {
    this.setState({
      showReceiptModal: false,
    })
    this.props.dispatch(clearForm('sendReceipt'))
    this.close()
  }

  close() {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  renderPopovers() {
    const { activePayment } = this.props
    if (activePayment && this.state.showReceiptModal) {
      return (
        <SendReceiptPopup
          showing
          onEsc={this.onReceiptCancel}
          itemUUID={activePayment.get('uuid')}
        />
      )
    }

    if (activePayment && this.state.showRefundModal) {
      return (
        <RefundPopup
          showing={this.state.showRefundModal}
          onEsc={this.onRefundCancel}
          title='Refund this transaction'
          transaction={activePayment}
          onSuccess={this.refundedPayment}
        />
      )
    }

    return null
  }

  render() {
    if (this.props.bill) {
      const payments = this.props.bill.get('payments', List())
      const { activePayment } = this.props

      return (
        <div className={classes.billLifeSpan}>
          {payments
            .filter((payment) => payment.get('paymentType') !== 'refund')
            .sortBy((payment) => payment.get('created'))
            .reverse()
            .map((payment) => {
              const isActive = payment.get('uuid') === activePayment.get('uuid')
              const associatedPayment = payments.find(
                (p) => p.get('associatedTransactionUUID') === payment.get('uuid')
              )
              const isAssociatedActive =
                associatedPayment && associatedPayment.get('uuid') === activePayment.get('uuid')

              return (
                <BillPayment
                  key={payment.get('uuid')}
                  payment={payment}
                  associatedPayment={associatedPayment}
                  bill={this.props.bill}
                  isActive={isActive || isAssociatedActive}
                  setActive={() => {
                    this.props.onSetShownPayment(payment)
                  }}
                  sendReceipt={() => {
                    this.setState({ showReceiptModal: true })
                  }}
                  refund={() => {
                    this.setState({ showRefundModal: true })
                  }}
                />
              )
            })}
          {this.renderPopovers()}
        </div>
      )
    }

    return <div />
  }
}

BillLifespan.propTypes = {
  dispatch: PropTypes.func.isRequired,
  bill: PropTypes.object,
  onClose: PropTypes.func,
  activePayment: PropTypes.objectOf(Map),
  paymentRefunded: PropTypes.func,
  onSetShownPayment: PropTypes.func,
}

export default connect(() => ({}))(BillLifespan)
