/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PropTypes from 'prop-types'

function OnOffSwitch({ checked, onChange, ariaLabel }) {
  return (
    <div className='onoffswitch'>
      <input
        type='checkbox'
        name='onoffswitch'
        className='onoffswitch-checkbox'
        value={checked}
        checked={checked}
        aria-label={ariaLabel}
      />
      <a className='onoffswitch-label' onClick={onChange}>
        <span className='onoffswitch-inner' />
        <span className='onoffswitch-switch' />
      </a>
    </div>
  )
}

OnOffSwitch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  ariaLabel: PropTypes.string,
}

OnOffSwitch.defaultProps = {
  checked: false,
  onChange: undefined,
  ariaLabel: undefined,
}

export default OnOffSwitch
