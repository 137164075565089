import React from 'react'
import propTypes from 'prop-types'

import DeleteConfirmation from 'components/popups/DeleteConfirmation'
import SuccessPopup from 'components/popups/SuccessPopup'
import FailurePopup from 'components/popups/FailurePopup'
import { If } from 'libs/formats'
import { getENV } from 'libs/utils'

import CustomerForm from '../Form/Form'

import { useCustomerViewActions } from './hooks'

function CustomerView({ customer, setCustomer }) {
  const {
    onCloseConfirmation,
    onCloseSuccess,
    onCloseFailure,
    onDeleted,
    onSaved,
    onFailure,
  } = useCustomerViewActions(customer, setCustomer)

  return (
    <>
      <CustomerForm customer={customer} onSaved={onSaved} onFailure={onFailure} />
      <If condition={!!customer.get('confirmingDelete')}>
        <DeleteConfirmation
          title='Delete'
          subtitle='Are you sure you want to delete this customer?'
          loadingMessage='Deleting customer ...'
          failureMessageSubtitle={`Oops. We were unable to delete ${
            customer.get('fullName') || 'the customer'
          }'s details. Please try again.`}
          successMessageTitle='Customer Deleted'
          successMessageSubtitle={
            <>
              You’ve successfully deleted {customer.get('fullName') || 'the customer'}
              <br />
              from your customer directory
            </>
          }
          showing
          url={`${getENV('INVOICES_BASE_URL')}/api/customers/${customer.get('id')}`}
          onSuccess={onDeleted}
          onFailure={onCloseConfirmation}
          onCancel={onCloseConfirmation}
        />
      </If>
      <If condition={customer.get('isSaved')}>
        <SuccessPopup
          title='Customer saved'
          subtitle={`You have successfully saved ${
            customer.get('fullName') || 'the customer'
          }'s details`}
          buttonLabel='Done'
          onDismiss={onCloseSuccess}
        />
      </If>
      <If condition={customer.get('error')}>
        <FailurePopup
          title='Failed'
          subtitle={`Oops. ${customer.get('error')}`}
          buttonLabel='OK'
          onDismiss={onCloseFailure}
        />
      </If>
    </>
  )
}

CustomerView.propTypes = {
  customer: propTypes.any,
  setCustomer: propTypes.func,
}

export default CustomerView
