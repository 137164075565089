/* eslint-disable import/namespace, @typescript-eslint/no-unused-vars, no-unused-vars */
import { List, Record } from 'immutable'

export const OfferContractRecordFactory = Record({
  contract_id: null,
  total_payable: null,
  processing_fee: null,
  agreement_text: null,
  approved_advance: null,
  estimated_term: null,
  user_id: null,
  tos_url: null,
  company_name: null,
  user_name: null,
  merchant_id: null,
  repayment_percentage: null,
  outstanding_balance: null,
})

export const InitialApplicationDataRecordFactory = Record({
  capitalAmount: 0,
})

export const ApplicationDataRecordFactory = Record({
  initialApplicationData: InitialApplicationDataRecordFactory(),
})

export const OfferResponseDataRecordFactory = Record({
  offerQuotes: List(),
  offerContract: OfferContractRecordFactory(),
  earlySettlementAmount: null,
  discountOnCurrentAdvanceOutstandingBalance: null,
})

export const BankAccountRecordFactory = Record({
  accountHolder: null,
  accountNumber: null,
  accountType: null,
  bank: null,
  branchCode: null,
  businessUUID: null,
  institution: null,
  uuid: null,
})

export const PayoutDeductionPlanRecordFactory = Record({
  clientBusinessUUID: null,
  providerBusinessUUID: null,
  payoutDeductionPlanType: null,
  payoutDeductionCategory: null,
  chargeRate: null,
  principalAmount: 0,
  totalAmountPayable: 0,
  totalAmountPaid: 0,
  payoutDeductionState: null,
  description: null,
  capitalOfferUUID: null,
  uuid: null,
})

export const PrelimOfferRecordFactory = Record({
  minimumAmount: 0,
  uptoAmount: 0,
  uuid: null,
  providerName: null,
})

const MetricRecordFactory = Record({
  target: 0,
  actual: 0,
  achieved: false,
})

const MetricsRecordFactory = Record({
  volume: MetricRecordFactory(),
  transactions: MetricRecordFactory(),
  months: MetricRecordFactory(),
})

export const QualificationRecordFactory = Record({
  businessUUID: null,
  qualified: false,
  metrics: MetricsRecordFactory(),
})

export const OfferQuoteRecordFactory = Record({
  advance: null,
  quotes: List(),
})

export const QuoteRecordFactory = Record({
  repaymentPeriod: null,
  repaymentPercentage: null,
  dailyAmount: null,
})

export const CapitalQualifyingBusinessFactory = Record({
  businessUUID: null,
  notifyMe: false,
  fetchingData: false,
})

export const CapitalOfferRecordFactory = Record({
  uuid: null,
  state: null,
  providerName: null,
  offerResponseData: OfferResponseDataRecordFactory(),
  applicationData: ApplicationDataRecordFactory(),
  bankAccount: BankAccountRecordFactory(),
  payoutDeductionPlan: PayoutDeductionPlanRecordFactory(),
})

export const UserRecordFactory = Record({
  name: null,
  email: null,
})
