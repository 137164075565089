import React from 'react'
import PropTypes from 'prop-types'

import Alert from 'components/notifications/Alert'
import FlatButton from 'components/buttons/FlatButton'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'

// eslint-disable-next-line react/prop-types
const BusinessSettingsAlert = ({ navigateToBusinessSettings, onClose }) => {
  return (
    <Alert messageType='warning' onClose={onClose}>
      Your Invoices setup has changed: The details shown on your Invoices will now match what
      you&apos;ve entered on the Business Details page. Edit or update your Invoices details in your
      Business Settings.
      <br />
      <br />
      <FlatButton
        label='Update business settings'
        type='button'
        className='secondary'
        onClick={navigateToBusinessSettings}
      />
    </Alert>
  )
}

BusinessSettingsAlert.propTypes = {
  navigateToBusinessSettings: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  navigateToBusinessSettings: () => dispatch(push('/business/details')),
})

export default connect(undefined, mapDispatchToProps)(BusinessSettingsAlert)
