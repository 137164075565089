/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import ReactDOM from 'react-dom'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import PropTypes from 'prop-types'
import { Icon } from '@yoco/design-system-icons/dist/react'

import { mouseDownHandler, mouseUpHandler, isFlagship } from 'libs/utils'

import classes from './popups.module.scss'
import Modal from './base'

export default class PopupComponent extends Modal {
  UNSAFE_componentWillMount() {
    super.UNSAFE_componentWillMount()

    this.portalDOMNode = document.createElement('div')
    document.body.appendChild(this.portalDOMNode)
  }

  componentWillUnmount() {
    super.componentWillUnmount()

    if (this.portalDOMNode) {
      document.body.removeChild(this.portalDOMNode)
    }
  }

  getContent() {
    if (this.props.getContent) {
      return this.props.getContent()
    }

    return undefined
  }

  stopProp(event) {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
  }

  escPressed() {
    if (this.props.onEsc) {
      this.props.onEsc()
    }
  }

  onCancel(event) {
    this.stopProp(event)

    if (this.props.onCancel) {
      this.props.onCancel()
    }
  }

  exitButton() {
    if (this.props.onEsc) {
      return (
        <div className={classes.escButton} onClick={this.escPressed.bind(this)}>
          {isFlagship ? '' : <Icon name='close' size={24} />}
        </div>
      )
    }

    return undefined
  }

  popoverStyles() {
    return null
  }

  popoverBackgroundStyles() {
    return { overflowY: 'scroll' }
  }

  getPopupInsideClass() {
    if (isFlagship) {
      return classes.flagshipPopupInside
    }
    if (this.props.rounded) {
      return classes.roundedPopupInside
    }
    if (this.props.customStyle) {
      return this.props.customStyle
    }
    return classes.popupInside
  }

  render() {
    let popup
    let { popupBackground } = classes
    if (this.props.background) {
      popupBackground += ` ${this.props.background}`
    }

    if (this.props.showing) {
      popup = (
        <div
          style={this.popoverBackgroundStyles()}
          className={popupBackground}
          onClick={this.onCancel.bind(this)}
          onMouseDown={(e) => e.stopPropagation()}
          onMouseUp={(e) => e.stopPropagation()}
        >
          <div
            style={this.popoverStyles()}
            className={this.getPopupInsideClass()}
            onClick={this.stopProp.bind(this)}
          >
            {this.exitButton()}
            <span onMouseDown={() => mouseDownHandler()} onMouseUp={() => mouseUpHandler()}>
              {this.getContent()}
            </span>
          </div>
        </div>
      )
    }

    return ReactDOM.createPortal(
      <ReactCSSTransitionGroup
        transitionName='fade'
        transitionEnterTimeout={200}
        transitionLeaveTimeout={200}
      >
        {popup}
      </ReactCSSTransitionGroup>,
      this.portalDOMNode
    )
  }
}

PopupComponent.propTypes = {
  showing: PropTypes.bool,
  onEsc: PropTypes.func,
  getContent: PropTypes.func,
  absolute: PropTypes.bool,
  containerStyle: PropTypes.object,
  rounded: PropTypes.bool,
  customStyle: PropTypes.string,
}
