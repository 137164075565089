import S from 'string'

export function prettyPaymentMethod(payment) {
  const method = payment.get('paymentMethod', 'Unknown')
  const product = payment.getIn(['transactionInfo', 'paymentProduct'])
  const paymentMethod = product && product !== 'unknown' ? product : method

  if (paymentMethod === 'credit_card') {
    return 'Credit card'
  }
  if (paymentMethod === 'cash') {
    return 'Cash'
  }
  if (paymentMethod === 'custom') {
    return payment.get('customPaymentMethodName', 'Custom')
  }
  if (paymentMethod === 'payment_link') {
    return 'Payment Link'
  }
  if (paymentMethod === 'wc_plugin') {
    return 'Plugin'
  }

  return S(paymentMethod).humanize().titleCase().s
}

export function prettyPaymentType(payment) {
  const paymentType = payment.get('paymentType', 'charge')
  if (paymentType === 'charge') {
    return 'payment'
  }

  return S(paymentType).humanize().s.toLowerCase()
}

/**
 * A combination of payment method and type
 * @param payment
 */
export function prettyPaymentDescription(payment) {
  return `${prettyPaymentMethod(payment)} ${prettyPaymentType(payment)}`
}

export function prettyPaymentResult(payment) {
  const paymentState = payment.get('transactionState', 'created')

  if (['created', 'pending'].indexOf(paymentState) !== -1) {
    return 'Pending'
  }

  if (payment.get('paymentType') === 'refund') {
    if (paymentState === 'approved') {
      return 'Refund approved'
    }
  }

  if (paymentState === 'refunded') {
    return 'Approved'
  }

  return S(paymentState).humanize().s
}
