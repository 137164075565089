import { callJSONApi } from 'libs/api'
import { multipleFieldsUpdated } from 'redux/modules/forms'

import { FORM_NAMES } from '../index'
import {
  updateBankAccountUpdateError,
  updateUploadedBankUpdateRequestDocuments,
  updateBankAccount,
  updateBankAccountUpdateRequest,
} from './actions'

export const BANK_REQUEST_STATUSES = {
  REQUESTED: 'requested',
  CONFIRMATION_REQUIRED: 'confirmation_required',
  CHANGE_REQUIRED: 'change_required',
  APPROVED_WITHOUT_ID_MATCH: 'approved_without_id_match',
  EXECUTED: 'executed',
  MANUALLY_EXECUTED: 'manually_executed',
  INFORMATION_REQUIRED: 'information_required',
  DECLINED: 'declined',
  APPROVED: 'approved',
}

export const BANK_ACCOUNT_FAILURE_REASONS = {
  ACCOUNT_INVALID: 'account_invalid',
  ACCOUNT_NOT_OPEN: 'account_not_open',
  NON_TRANSACTIONAL_ACCOUNT: 'non_transactional_account',
  ANOTHER_INDIVIDUAL_OWNS: 'another_individual_owns',
  ANOTHER_BUSINESS_OWNS: 'another_business_owns',
  CHURCH: 'church',
  CLUB: 'clubs',
  NPO: 'npo',
  TRUST: 'trust',
  SCHOOL: 'school',
  DOCUMENT_DOES_NOT_MATCH_BANK_ACCOUNT: 'document_does_not_match_bank_account',
  MISSING_REQUIRED_DOCUMENTS: 'missing_required_documents',
  NOT_A_BANK_ACCOUNT_DOCUMENT: 'not_a_bank_account_document',
}

export const onFetchBankAccountUpdateRequest = (dispatch, response, onSuccess) => {
  const { updateRequest, bankAccount } = response.data
  const { storedFiles = [], updateInfo = {} } = updateRequest || {}

  dispatch(updateBankAccount(bankAccount))
  dispatch(updateBankAccountUpdateRequest(updateRequest))
  dispatch(updateBankAccountUpdateError())

  // TODO: We can avoid dispatching updateUploadedBankUpdateRequestDocuments() and just have the storedFiles
  // TODO: set in state with the fetchedBankAccountAndUpdateRequest() action
  dispatch(updateUploadedBankUpdateRequestDocuments(storedFiles))

  dispatch(
    multipleFieldsUpdated(FORM_NAMES.BANK_DETAILS, {
      accountNumber: bankAccount?.accountNumber || updateInfo?.accountNumber,
      accountType: bankAccount?.accountType || updateInfo?.accountType,
      bank: bankAccount?.bank || updateInfo?.bank,
    })
  )

  if (onSuccess) {
    onSuccess(updateRequest, bankAccount)
  }
}

export const fetchBankUpdateRequest = (onSuccess, onError) => (dispatch) => {
  callJSONApi(
    '/business/bank-account',
    'GET',
    {},
    (response) => {
      onFetchBankAccountUpdateRequest(dispatch, response, onSuccess)
    },
    (error) => {
      dispatch(
        updateBankAccountUpdateError(
          'An error occurred - please refresh the page or try again later.'
        )
      )

      if (onError) {
        onError(error)
      }
    }
  )
}
