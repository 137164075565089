import React from 'react'
import { Map } from 'immutable'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import ConnectedRow from 'libs/firebase/ConnectedRow'

import { Table } from './Table'

class FirebaseTable extends Table {
  getRow(rowData, index, groupIndex, lastSubrow) {
    const identifier = this.props.firebaseJoin.entrySeq().first()[0] || 'uuid'

    return (
      <ConnectedRow
        key={rowData.get(identifier)}
        localObject={rowData}
        index={index}
        groupIndex={groupIndex}
        lastSubrow={lastSubrow}
        firebaseJoin={this.props.firebaseJoin}
        renderRow={this.props.getRow}
      />
    )
  }
}

/*
 * An example of what a join would look like
 * Immutable.fromJS({uuid: 'product'});
 * This means that we will use the uuid of each list item,
 * to go fetch a product from firebase as per FIREBASE_OBJECTS
 */

FirebaseTable.propTypes = {
  firebaseJoin: PropTypes.object.isRequired,
}

export default connect((state, props) => ({
  table: state.tables.get(props.name, Map()),
  batch: state.filters.get(props.filterName || props.name, Map()),
  sortedColumns: state.tables.getIn(['sortedColumns', props.name], Map()),
}))(FirebaseTable)
