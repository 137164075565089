import { getFilter, getFilterValues } from 'libs/invoices/utils'

import { InvoiceFilterNames } from './constants'

export const getPaymentMethodFilter = (batch) => getFilter(batch, InvoiceFilterNames.PaymentMethod)

export const getCustomerFilter = (batch) => getFilter(batch, InvoiceFilterNames.Customer)

export const getStatusFilter = (batch) =>
  getFilter(batch, InvoiceFilterNames.Status, (filter) => {
    const status = filter.get(0)
    return status === 'overdue' ? { overdue: true } : { status }
  })

export const getDateFilter = (batch) => {
  const dateFilter = getFilterValues(batch, 'created')

  if (dateFilter.size <= 1) {
    return {}
  }

  return {
    createdDateFrom: `${dateFilter.get(0, '').split('T')[0]}T00:00:00+00:00`,
    createdDateTo: `${dateFilter.get(1, '').split('T')[0]}T23:59:59+00:00`,
  }
}
