import React from 'react'
import Immutable from 'immutable'
import PropTypes from 'prop-types'

import ItemIcon from 'components/items/ItemIcon'
import * as misc from 'libs/formats'
import CONSTANTS from 'libs/constants'

import classes from './transactions.module.scss'
import { calculatePerItemDiscountAmount, calculatePerItemTotal } from './utils'

function BasketItemIcon({ item }) {
  const quantity = item.get('quantity')
  const itemData = item.get('inventoryItemData', item.get('tile'))
  if (itemData) {
    const backgroundColor = item.get('tile') ? 'backgroundColor' : 'backgroundColour'
    const tile = Immutable.Map({
      backgroundColor: itemData.get(backgroundColor, CONSTANTS.YOCO_DEFAULT_COLOR),
      imageText: itemData.get('imageText'),
      imageURL: itemData.get('imageURL'),
      name: itemData.get('name'),
    })
    return <ItemIcon tile={tile} quantity={quantity} />
  }
  const tile = Immutable.Map({
    backgroundColor: CONSTANTS.YOCO_DEFAULT_COLOR,
  })
  return <ItemIcon tile={tile} quantity={quantity} />
}

BasketItemIcon.propTypes = {
  item: PropTypes.instanceOf(Immutable.Map).isRequired,
}

function ItemDiscount({ quantity, amount, totalDiscount }) {
  const perItemDiscountAmount = calculatePerItemDiscountAmount(totalDiscount, quantity)
  return (
    <div className={classes.discountContainer}>
      {quantity > 1 && (
        <span>
          {quantity}
          {' @ ('}
        </span>
      )}
      <misc.Amount className={classes.discountText} amount={amount} />
      <span> - </span>
      <span className={classes.discountAmount}>
        <misc.Amount className={classes.discountText} amount={perItemDiscountAmount} />
        {` discount`}
      </span>
      {quantity > 1 && <span>)</span>}
    </div>
  )
}

ItemDiscount.propTypes = {
  quantity: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  totalDiscount: PropTypes.number.isRequired,
}

function BasketItem({ item }) {
  const name = item.getIn(['tile', 'name'], item.get('name')) || 'Custom amount'
  const variantChoices = item.getIn(['meta', 'variantChoices'])
  const quantity = item.get('quantity') || 1
  const amount = item.get('amount', item.get('price'))
  const totalDiscount = item.get('discount') || 0
  const total = calculatePerItemTotal(quantity, amount, totalDiscount)
  return (
    <tr>
      <td className={classes.itemIconCol}>
        <BasketItemIcon item={item} />
      </td>
      <td className={classes.itemNameCol}>
        <div className={classes.variants}>
          {variantChoices &&
            variantChoices.map((choice) => (
              <div key={choice} className={classes.variant}>
                {choice}
              </div>
            ))}
        </div>
        {name}
        <div className={classes.note}>{item.get('note')}</div>
        {totalDiscount > 0 && (
          <ItemDiscount quantity={quantity} amount={amount} totalDiscount={totalDiscount} />
        )}
      </td>
      <td className={classes.itemAmountCol}>
        <misc.Amount amount={total} />
      </td>
    </tr>
  )
}

BasketItem.propTypes = {
  item: PropTypes.instanceOf(Immutable.Map).isRequired,
}

export default BasketItem
