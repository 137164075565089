/* eslint-disable import/namespace, @typescript-eslint/no-unused-vars, no-unused-vars */
import Immutable from 'immutable'
import { push } from 'react-router-redux'
import { callJSONApi } from 'libs/api'
import { ACTIONS as formActions } from 'redux/modules/forms'

import { CapitalActions, CapitalOfferStates } from './types'

export function requestQualifications() {
  return (dispatch) => {
    dispatch({ type: CapitalActions.FETCH_QUALIFICATIONS_REQUEST })
    callJSONApi(
      '/lending/capitalOffer/qualified',
      'GET',
      {},
      (response) => {
        if (response.status === 200 && response.data) {
          dispatch({
            type: CapitalActions.FETCH_QUALIFICATIONS_SUCCESS,
          })
          dispatch({
            type: CapitalActions.RECEIVED_QUALIFICATIONS,
            payload: Immutable.fromJS(response.data),
          })
        } else {
          dispatch({
            type: CapitalActions.FETCH_QUALIFICATIONS_FAILURE,
            error: response.message,
          })
        }
      },
      (prettyError) => {
        dispatch({
          type: CapitalActions.FETCH_QUALIFICATIONS_FAILURE,
          error: prettyError,
        })
      }
    )
  }
}

export function fetchPrelimOffers() {
  return (dispatch) => {
    dispatch({ type: CapitalActions.FETCH_PRELIM_OFFERS_REQUEST })
    callJSONApi(
      '/lending/capitalOffer/prelims',
      'GET',
      {},
      (response) => {
        if (response.status === 200 && response.data) {
          dispatch({
            type: CapitalActions.FETCH_PRELIM_OFFERS_SUCCESS,
          })
          dispatch({
            type: CapitalActions.RECEIVED_PRELIM_OFFERS,
            payload: Immutable.fromJS(response.data),
          })
        } else {
          dispatch({
            type: CapitalActions.FETCH_PRELIM_OFFERS_FAILURE,
            error: response.message,
          })
        }
      },
      (error) => {
        console.log('capitalPrelimOffers', 'Error fetching capital prelim offers', error)
        dispatch({
          type: CapitalActions.FETCH_PRELIM_OFFERS_FAILURE,
          error,
        })
      },
      true,
      false
    )
  }
}

export function capitalOptIn() {
  return (dispatch) => {
    dispatch({ type: CapitalActions.CAPITAL_OPT_IN_REQUEST })
    callJSONApi(
      '/lending/capitalOffer/notify',
      'POST',
      {},
      () => {
        dispatch({ type: CapitalActions.CAPITAL_OPT_IN_SUCCESS })
      },
      (error) => {
        dispatch({ type: CapitalActions.CAPITAL_OPT_IN_FAILURE, error })
      }
    )
  }
}

export const activeCapitalOffer = () => (dispatch) => {
  return progressOffer('/lending/capitalOffer/activeCapitalOffer', {}, 'GET')(dispatch)
}

export const fetchOffers = () => (dispatch) => {
  dispatch(requestQualifications())
  dispatch(fetchPrelimOffers())
  dispatch(activeCapitalOffer())
}

function progressOffer(url, data, action = 'POST') {
  return (dispatch) => {
    dispatch({ type: CapitalActions.PROGRESS_OFFER_REQUEST })
    callJSONApi(
      url,
      action,
      data,
      (response) => {
        const offerState =
          response.data && response.data.capitalOffer && response.data.capitalOffer.state
        const validState =
          Object.values(CapitalOfferStates).includes(offerState) || action === 'GET'

        if (response.status === 200 && response.data && validState) {
          dispatch({ type: CapitalActions.PROGRESS_OFFER_SUCCESS })
          if (
            offerState === CapitalOfferStates.CapitalOfferDeclinedConfirmationApprovedByProvider
          ) {
            dispatch(fetchOffers())
            return dispatch({
              type: CapitalActions.DECLINED_CAPITAL_OFFER,
            })
          }
          dispatch({
            type: CapitalActions.RECEIVED_CAPITAL_QUALIFYING_BUSINESS,
            data: response.data.capitalQualifyingBusiness,
          })
          dispatch({
            type: formActions.CLEAR_FORM,
            formName: 'prelimApplication',
          })
          return dispatch({
            type: CapitalActions.RECEIVED_CAPITAL_OFFER,
            data: response.data.capitalOffer,
          })
        }
        return dispatch({
          type: CapitalActions.PROGRESS_OFFER_FAILURE,
          error: response.message,
        })
      },
      (error) => {
        dispatch({ type: CapitalActions.PROGRESS_OFFER_FAILURE, error })
      }
    )
  }
}

export const createApplication = (prelimOfferUUID, data) => (dispatch) => {
  dispatch({
    type: CapitalActions.CREATE_APPLICATION,
  })
  return progressOffer(`/lending/capitalOffer/createApplication/${prelimOfferUUID}`, data)(dispatch)
}

export const sendApplication = (applicationUUID, data) => (dispatch) => {
  dispatch({
    type: CapitalActions.SEND_APPLICATION,
    meta: {
      repaymentPercentage: data.repaymentPercentage,
      dailyAmount: data.dailyAmount,
      repaymentPeriod: data.repaymentPeriod,
      selectedAmount: data.capitalAmount,
      consented: data.consented,
    },
  })
  return progressOffer(`/lending/capitalOffer/sendApplication/${applicationUUID}`, data)(dispatch)
}

export const acceptOffer = (applicationUUID, data) => (dispatch) => {
  dispatch({
    type: CapitalActions.ACCEPTED_CAPITAL_OFFER,
  })
  return progressOffer(`/lending/capitalOffer/acceptOffer/${applicationUUID}`, data)(dispatch)
}

export const declineOffer = (applicationUUID, data) => (dispatch) => {
  dispatch({
    type: CapitalActions.DECLINED_CAPITAL_OFFER,
    meta: {
      declineReason: data.declineReason,
    },
  })
  return progressOffer(`/lending/capitalOffer/declineOffer/${applicationUUID}`, data)(dispatch)
}

export const applyForReAdvance = (prelimOfferUUID, data) => (dispatch) => {
  dispatch({
    type: CapitalActions.APPLY_FOR_RE_ADVANCE,
  })
  dispatch(createApplication(prelimOfferUUID, data))
  dispatch(push('/capital/offers'))
}

export const sliderAmountChanged = (amount) => ({
  type: CapitalActions.SLIDER_AMOUNT_CHANGED,
  meta: {
    amount,
  },
})

export const sliderMonthChanged = (term) => ({
  type: CapitalActions.SLIDER_MONTH_CHANGED,
  meta: {
    term,
  },
})

export const prelimOfferPresented = () => ({
  type: CapitalActions.PRELIM_OFFER_PRESENTED,
})

export const viewDeductions = () => ({
  type: CapitalActions.DEDUCTIONS_VIEWED,
})

export const viewContract = () => ({
  type: CapitalActions.CONTRACT_VIEWED,
})

export const findOutMoreClicked = () => ({
  type: CapitalActions.FIND_OUT_MORE_CLICKED,
})

export const offersViewedFromMainNavBar = () => ({
  type: CapitalActions.OFFERS_VIEWED_FROM_MAIN_NAV_BAR,
})

export const deductionPlansViewedFromMainNavBar = () => ({
  type: CapitalActions.DEDUCTION_PLANS_VIEWED_FROM_MAIN_NAV_BAR,
})

export const deductionPlanViewed = (deductionPlan) => ({
  type: CapitalActions.DEDUCTION_PLAN_VIEWED,
  meta: deductionPlan,
})
