import capitalStyles from 'components/capital/capital.module.scss'
import CapitalApplicationProgress from 'components/capital/partials/CapitalApplicationProgress'
import { ApplicationProgressSteps } from 'components/capital/partials/CapitalApplicationProgress/types'
import CapitalCashAdvanceAgreement from 'components/capital/partials/CapitalCashAdvanceAgreement'
import CapitalConfirmOfferButtons from 'components/capital/partials/CapitalConfirmOfferButtons'
import CapitalHeaderBlock from 'components/capital/partials/CapitalHeaderBlock'
import CapitalHelpMessage from 'components/capital/partials/CapitalHelpMessage'
import CapitalHelpVideo from 'components/capital/partials/CapitalHelpVideo'
import CapitalOfferSummary from 'components/capital/partials/CapitalOfferSummary'
import { getLendingPartnerDisplayName } from 'components/capital/utils'
import TitleCard from 'components/cards/TitleCard'
import CenteredContainerPage from 'components/pages/CenteredContainerPage'
import CapitalDeclinePopup from 'components/popups/CapitalDeclinePopup'
import ConfirmPopup from 'components/popups/ConfirmPopup'
import React, { useState } from 'react'
import { AcceptOfferData, DeclineOfferData, LendingPartners } from 'redux/modules/capital/types'

interface Props {
  offerUUID: string
  outstandingBalance: number
  approvedAdvance: string
  repaymentPercentage: number
  estimatedTerm: number
  processingFee: number
  totalPayable: number
  providerName: LendingPartners
  contractURL: string
  onFindOutMoreClicked: () => void
  onOfferAccepted: (uuid: string, data: AcceptOfferData) => void
  onOfferDeclined: (uuid: string, data: DeclineOfferData) => void
}

const CapitalConfirmOfferPage: React.FunctionComponent<Props> = ({
  offerUUID,
  outstandingBalance,
  approvedAdvance,
  repaymentPercentage,
  estimatedTerm,
  processingFee,
  totalPayable,
  providerName,
  contractURL,
  onFindOutMoreClicked,
  onOfferAccepted,
  onOfferDeclined,
}) => {
  const [agreedToTerms, setAgreedToTerms] = useState(false)
  const [validationMessage, setValidationMessage] = useState('')
  const [showPopup, setShowPopup] = useState(false)

  const handleAcceptPressed = () => {
    if (!agreedToTerms) {
      const error = 'Please agree to the agreement'
      setValidationMessage(error)
      return
    }
    onOfferAccepted(offerUUID, {
      merchantBankConfirmation: true,
      merchantOfferConfirmation: true,
    })
  }

  const handleDeclinePressed = () => setShowPopup(true)
  const hideDeclinePopup = () => setShowPopup(false)
  const resetValidationError = () => setValidationMessage('')

  return (
    <CenteredContainerPage>
      <section className={capitalStyles.page}>
        <CapitalHeaderBlock
          title='Get a cash advance to grow your business.'
          description={`Your funds are on the way! Your cash advance has been approved by
            ${getLendingPartnerDisplayName(
              providerName
            )}. Please review and accept our agreement terms to get your cash.`}
        />
        <CapitalApplicationProgress activeStep={ApplicationProgressSteps.reviewAndAcceptTerms} />
        <TitleCard title='Please review and accept the agreement terms to get your advance.'>
          <CapitalOfferSummary
            outstandingBalance={outstandingBalance}
            approvedAdvance={approvedAdvance}
            repaymentPercentage={repaymentPercentage}
            estimatedTerm={estimatedTerm}
            processingFee={processingFee}
            totalPayable={totalPayable}
            providerName={providerName}
          />
          <CapitalCashAdvanceAgreement
            providerName={providerName}
            agreedToTerms={agreedToTerms}
            setAgreedToTerms={setAgreedToTerms}
            contractURL={contractURL}
          />
          <CapitalConfirmOfferButtons
            providerName={providerName}
            onDeclinePressed={handleDeclinePressed}
            onAcceptPressed={handleAcceptPressed}
          />
        </TitleCard>
        <CapitalHelpVideo onFindOutMoreClicked={onFindOutMoreClicked} />
        <CapitalHelpMessage />
      </section>
      <ConfirmPopup
        showing={validationMessage}
        title='Oops!'
        label='OK'
        subtitle={validationMessage}
        onConfirm={resetValidationError}
        onCancel={resetValidationError}
        hideCancel
      />
      <CapitalDeclinePopup
        offerUUID={offerUUID}
        showing={showPopup}
        onConfirm={hideDeclinePopup}
        onCancel={hideDeclinePopup}
        providerName={providerName}
        onOfferDeclined={onOfferDeclined}
      />
    </CenteredContainerPage>
  )
}

export default CapitalConfirmOfferPage
