import keyMirror from 'libs/key-mirror'

export default keyMirror(
  {
    GET_API_KEYS_REQUEST: null,
    GET_API_KEYS_FAILURE: null,
    GET_API_KEYS_SUCCESS: null,
    RECEIVED_KEYS: null,

    CREATE_API_KEYS_REQUEST: null,
    CREATE_API_KEYS_FAILURE: null,
    CREATE_API_KEYS_SUCCESS: null,
  },
  '@plugin/'
)
