import React from 'react'

import MainVavBar from 'components/navbars/MainNavbar'
import { getRoutesArray } from 'containers/portal/PortalPage'
import pageNotFound from 'assets/images/page-not-found.png'

import classes from './errors.module.scss'

function PageNotFoundPage() {
  return (
    <div>
      <MainVavBar routes={getRoutesArray()} rootPath='' />
      <div className={`${classes.notFound}`}>
        <div className='inner'>
          <div className='title'>PAGE NOT FOUND</div>
          <img className='img' src={pageNotFound} height='70px' alt='Page not found' />
          <div className='title2'>We couldn’t find that page.</div>
          <p>Unfortunately the page could not be found. What would you like to do?</p>
          <p>
            <a href='/' className='btn btn-primary'>
              Home
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default PageNotFoundPage
