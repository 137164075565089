/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { List, Map } from 'immutable'
import { connect } from 'react-redux'

import { itemUpdated } from 'redux/modules/tables'
import { ItemIcon } from 'components/items'
import CheckboxButton from 'components/buttons/CheckboxButton'

import classes from './rows.module.scss'

class UnconnectedTileCheckboxRow extends Component {
  onClick(rowData) {
    if (this.props.onClick) {
      this.props.onClick(rowData)
    }
    this.onCheckboxChange()
  }

  onCheckboxChange() {
    if (this.props.reduxItem && this.props.showCheckbox) {
      const updateItem = this.props.reduxItem.set(
        'itemIsSelected',
        !this.props.reduxItem.get('itemIsSelected')
      )
      this.props.dispatch(itemUpdated(this.props.tableName, updateItem))
    }
  }

  renderCheckbox() {
    if (this.props.reduxItem && this.props.showCheckbox) {
      return (
        <td className={classes.checkbox}>
          <CheckboxButton
            onClick={this.onCheckboxChange.bind(this)}
            selected={this.props.reduxItem.get('itemIsSelected')}
          />
        </td>
      )
    }
    return undefined
  }

  render() {
    const tile = this.props.tile || Map()

    return (
      <tr
        className={this.props.className}
        onClick={this.onClick.bind(this, this.props.tile)}
        onMouseDown={this.props.onMouseDown}
        onMouseUp={this.props.onMouseUp}
      >
        <td className={classes.flushImage}>
          <ItemIcon tile={tile} />
        </td>
        <td className={classes.name}>{tile.get('name')}</td>
        {this.renderCheckbox()}
      </tr>
    )
  }
}

const TileCheckboxRow = connect((state, props) => ({
  reduxItem: state.tables
    .getIn([props.tableName, 'data'], List())
    .concat(state.tables.getIn([props.tableName, 'addedItems'], List()))
    .find((item) => item && item.get('uuid') === props.tile.get('uuid')),
}))(UnconnectedTileCheckboxRow)

UnconnectedTileCheckboxRow.defaultProps = {
  showCheckbox: true,
}

UnconnectedTileCheckboxRow.propTypes = {
  dispatch: PropTypes.func.isRequired,
  tableName: PropTypes.string.isRequired,
  tile: PropTypes.object.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  reduxItem: PropTypes.object,
  showCheckbox: PropTypes.bool,
}

export default TileCheckboxRow
