import React from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import { Icon } from '@yoco/design-system-icons/dist/react'

import { makeTestID } from 'libs/utils'
import Spacer from 'ui/layout/Spacer'

import classes from './navbars.module.scss'
import AccountSwitcher from './account/AccountSwitcher'

function SecondaryNavbar({ businessName, showAccountSwitcher, fullName }) {
  return (
    <div key='second-nav' className={classes.secondNavContainer}>
      <div className={classes.currentPathContainer}>
        <div className={classes.currentPathName} data-testid={makeTestID('businessName')}>
          {businessName}
        </div>
      </div>
      <Spacer size='medium' isHorizontal />
      <Spacer size='medium' isHorizontal />
      {showAccountSwitcher ? (
        <AccountSwitcher name={fullName} />
      ) : (
        <Link to='/profile' className={classes.userName}>
          {fullName}
          <Spacer size='medium' isHorizontal />
          <Icon name='profile' size={24} />
        </Link>
      )}
    </div>
  )
}

export default SecondaryNavbar

SecondaryNavbar.propTypes = {
  businessName: PropTypes.string,
  fullName: PropTypes.string,
  showAccountSwitcher: PropTypes.bool,
}
