import portalReferral from 'assets/images/online/portal_referral_home.jpg'

const infoCards = [
  {
    img: portalReferral,
    title: 'Earn an easy R500',
    blurb: `Every time you refer a friend to Yoco you will get rewarded and help their business grow with a discount off their card machine purchase`,
    linkAText: 'Find out more',
    linkAHref: '/lets-grow',
    popUp: false,
    tracking: 'referral-page',
  },
]

export default infoCards
