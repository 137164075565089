/* eslint-disable no-restricted-syntax */
import { gte, coerce } from 'semver'
import { Map } from 'immutable'

import { getENV } from 'libs/utils'

const FEATURES = {
  CAPITAL: 'capital',
  WEBHOOKS: 'webhooks',
  SALES_PERFORMANCE: 'salesPerformance',
  CUSTOM_PAYMENT_FILTER: 'customPaymentFilter',
  SAVED_BILLS: 'savedBills',
  NEW_EDIT_BANK_ACCOUNT_SCREEN: 'newEditBankAccountScreen',
  INSTANT_PAYOUTS: 'instantPayouts',
}

export default FEATURES

export const selectFeatures = (state) => {
  return state.session.get('features', Map()).merge(state.launchDarkly)
}

/**
 * Determines if a feature is enabled from the store
 * @param {Map} state
 * @param {String} featureName
 * @param {Boolean} defaultValue will default to false
 * @returns {Boolean} whether the feature is enabled or not
 */
export const hasFeature = (state, featureName, defaultValue = false) => {
  const features = selectFeatures(state)
  return isFeatureEnabled(features, featureName, defaultValue)
}

export const isLaunchDarklyFeatureEnabled = (features, featureName, defaultValue) => {
  const feature = features.get(featureName, defaultValue)
  return typeof feature === 'boolean' ? feature : false
}

export function isLegacyFeatureEnabled(features, featureName) {
  /**
   * We have a problem where any undefined call on completesetup.enabled
   * is redirecting the dashboard.
   * It would be better practice to assume redirect only when the feature
   * is defined, as the redirect is permanent.
   */

  const appVersion = coerce(getENV('APP_VERSION')) // eslint-disable-line
  const requiredPortalVersion = coerce(
    features.getIn([featureName, 'enableOnVersions', 'minPortalVersion'])
  )

  if (requiredPortalVersion && gte(appVersion, requiredPortalVersion)) {
    return true
  }

  return features.getIn([featureName, 'enabled'], false)
}

export function isFeatureEnabled(features, featureName, defaultValue) {
  return (
    isLegacyFeatureEnabled(features, featureName) ||
    isLaunchDarklyFeatureEnabled(features, featureName, defaultValue)
  )
}

export function hasOneOfFeatures(features, oneOfFeatures) {
  if (!oneOfFeatures) {
    return true
  }

  for (const requiredFeature of oneOfFeatures) {
    if (isFeatureEnabled(features, requiredFeature)) {
      return true
    }
  }
  return false
}

export function notHasOneOfFeatures(features, oneOfFeatures) {
  if (!oneOfFeatures || oneOfFeatures.length === 0) {
    return true
  }

  return !hasOneOfFeatures(features, oneOfFeatures)
}

export function hasAllOfFeatures(features, allOfFeatures) {
  if (!allOfFeatures) {
    return true
  }

  for (const requiredFeature of allOfFeatures) {
    // eslint-disable-line
    if (!isFeatureEnabled(features, requiredFeature)) {
      return false
    }
  }

  return true
}

export function notHasAllOfFeatures(features, allOfFeatures) {
  if (!allOfFeatures || allOfFeatures.length === 0) {
    return true
  }

  return !hasAllOfFeatures(features, allOfFeatures)
}

export function hasRequiredFeatures(features, route) {
  const oneOfFeatures =
    route.requiresOneOfFeatures && route.requiresOneOfFeatures.length > 0
      ? route.requiresOneOfFeatures
      : undefined
  const allOfFeatures =
    route.requiresAllOfFeatures && route.requiresAllOfFeatures.length > 0
      ? route.requiresAllOfFeatures
      : undefined
  const notOneOfFeatures =
    route.doesNotHaveOneOfFeatures && route.doesNotHaveOneOfFeatures.length > 0
      ? route.doesNotHaveOneOfFeatures
      : undefined
  const notAllOfFeatures =
    route.doesNotHaveAllOfFeatures && route.doesNotHaveAllOfFeatures.length > 0
      ? route.doesNotHaveAllOfFeatures
      : undefined

  // If we haven't synced user yet, return true if we don't require any roles.
  if (!features) {
    return !oneOfFeatures && !allOfFeatures
  }

  return (
    hasOneOfFeatures(features, oneOfFeatures) &&
    hasAllOfFeatures(features, allOfFeatures) &&
    notHasOneOfFeatures(features, notOneOfFeatures) &&
    notHasAllOfFeatures(features, notAllOfFeatures)
  )
}
