import React from 'react'
import { Icon } from '@yoco/design-system-icons/dist/react'
import CardMachineImage from '../../../assets/images/64px-icon-card-machine-payments.svg'

export const contentMap = {
  unknown: {
    header: 'We couldn’t verify your profile',
    body: 'Please check your internet connection or refresh to retry',
    primary: 'Refresh',
    secondary: 'Go back',
    image: <Icon name='alert-warning' color='#F70C13' size={64} />,
  },
  failed: {
    header: 'We couldn’t verify your profile',
    body: 'Please check your profile for incorrect details or outstanding documents.',
    primary: "Check what's missing",
    secondary: 'Get help',
    image: <Icon name='alert-warning' color='#F70C13' size={64} />,
  },
  todo: {
    header: 'Complete your profile to accept payments',
    body: 'All you need to do is verify your identity and provide your bank details.',
    primary: 'Complete your profile',
    secondary: 'Not now',
    image: <img src={CardMachineImage} alt='cardMachineImage' />,
  },
  in_progress: {
    header: 'You can accept payments once your profile is verified',
    body: 'If you haven’t heard back from us within 24 hours, get help from Yoco Support.',
    primary: 'Okay',
    secondary: 'Get help',
    image: <Icon name='connecting' color='#878B90' size={64} />,
  },
}

export const isRelevantState = (state: string): boolean => {
  const relevantStates = ['unknown', 'failed', 'todo', 'in_progress']

  if (relevantStates.includes(state)) {
    return true
  }

  return false
}

export const isNotRelevantState = (state: string): boolean => !isRelevantState(state)
