import React from 'react'

import { track } from 'libs/analytics'
import classes from 'components/onboarding/onboarding.module.scss'
import AppDownloadButtons from 'components/onboarding/AppDownloadButtons'
import SubHeading from 'ui/components/SubHeading'

export default function AppDownloadContent() {
  const trackIOS = () => {
    track('portal_onboarding_download_app_ios_button_clicked')
  }

  const trackAndroid = () => {
    track('portal_onboarding_download_app_android_button_clicked')
  }

  const trackHuawei = () => {
    track('portal_onboarding_download_app_huawei_button_clicked')
  }

  return (
    <div className={`${classes.taskCardColumn} ${classes.appDownloadContent}`}>
      <SubHeading>Download the Yoco app</SubHeading>
      <AppDownloadButtons
        buttonAlignment={classes.appDownloadButtons}
        trackAndroid={trackIOS}
        trackIOS={trackAndroid}
        trackHuawei={trackHuawei}
      />
    </div>
  )
}
