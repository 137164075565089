import React from 'react'
import Loading from 'components/loaders/LoadingView'
import ReactDOM from 'react-dom'
import Modal from './ui/Modal'
import { useShouldPreventTransactions } from './hooks/useShouldPreventTransactions'
import useOnboardingStatus from './hooks/useOnboardingStatus'
import usePreventTransactionsData from './hooks/usePreventTransactionsData'
import { isNotRelevantState } from './utils'

const PreventBeforeFullyOnboarded: React.FC = () => {
  const shouldPrevent = useShouldPreventTransactions()
  const allowTransactingRegardlessOfOnboardingState = !shouldPrevent
  const onboardingStatus = useOnboardingStatus(shouldPrevent)
  const getData = usePreventTransactionsData()
  if (allowTransactingRegardlessOfOnboardingState) {
    return null
  }

  if (onboardingStatus === undefined) {
    return <Loading />
  }

  if (isNotRelevantState(onboardingStatus)) {
    return null
  }

  const data = getData(onboardingStatus)

  if (!data) {
    return null
  }

  return ReactDOM.createPortal(<Modal {...data} />, document.body)
}

export default PreventBeforeFullyOnboarded
