import React from 'react'
import PropTypes from 'prop-types'

import classes from './sales.module.scss'

function VoucherMessage(props) {
  if (props.renderContent) {
    return (
      <p className={classes.voucherMessage}>
        *The above report distinguishes between &quot;vouchers bought&quot; and &quot;vouchers
        redeemed&quot;. Vouchers bought are included as part of your money earned for the day and
        will be included in your daily payouts. Since you have not yet delivered the goods or
        services from selling the voucher, we have not included this cash as part of your sales
        report. At a future point in time when your customer redeems the voucher and you have
        provided the goods or services, this event is considered to be a sale and will reflect in
        your gross sales amount.
      </p>
    )
  }
  return null
}

VoucherMessage.propTypes = {
  renderContent: PropTypes.bool,
}

export default VoucherMessage
