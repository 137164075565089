import React from 'react'

import styles from './CapitalBankingDetailsRow.module.scss'

interface Props {
  title: string
  titleDisplay: string
}

const CapitalBankingDetailsRow: React.FunctionComponent<Props> = ({ title, titleDisplay }) => {
  return (
    <div className={styles.row} data-testid='CapitalBankingDetailsRow'>
      <p className={styles.title}>{title}</p>
      <p className={styles.titleDisplay}>{titleDisplay}</p>
    </div>
  )
}

export default CapitalBankingDetailsRow
