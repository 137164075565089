/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import classes from './Tooltip.module.scss'

export default class TooltipButton extends Component {
  constructor(props) {
    super(props)

    this.pageClickListener = this.pageClick.bind(this)
    this.componentIsMounted = false
    this.mouseIsDown = false

    this.state = {
      shown: false,
    }
  }

  componentDidMount() {
    this.componentIsMounted = true
    window.addEventListener('mousedown', this.pageClickListener, false)
  }

  componentWillUnmount() {
    this.componentIsMounted = false
    window.removeEventListener('mousedown', this.pageClickListener, false)
  }

  onClick() {
    this.setState((state) => ({
      shown: !state.shown,
    }))
  }

  getHover() {
    if (this.state.shown) {
      let className = classes.hoverContainer
      if (this.props.hoverClassName) {
        className = `${className} ${this.props.hoverClassName}`
      }
      if (this.props.below) {
        className = `${className} ${classes.below}`
      }
      return <div className={className}>{this.props.hoverContent}</div>
    }
  }

  mouseEntered() {
    if (this.props.showOnHover) {
      this.setState({
        shown: true,
      })
    }
  }

  mouseLeft() {
    if (this.props.showOnHover) {
      this.setState({
        shown: false,
      })
    }
  }

  pageClick() {
    if (this.componentIsMounted && !this.mouseIsDown) {
      this.setState({
        shown: false,
      })
    }
  }

  mouseDownHandler() {
    this.mouseIsDown = true
  }

  mouseUpHandler() {
    this.mouseIsDown = false
  }

  render() {
    let className = classes.tooltipContainer
    if (this.props.className) {
      className = `${className} ${this.props.className}`
    }
    return (
      <div
        className={className}
        onMouseEnter={this.mouseEntered.bind(this)}
        onMouseLeave={this.mouseLeft.bind(this)}
        onMouseDown={this.mouseDownHandler.bind(this)}
        onMouseUp={this.mouseUpHandler.bind(this)}
        onClick={this.onClick.bind(this)}
      >
        {this.props.children}
        {this.getHover()}
      </div>
    )
  }
}

TooltipButton.propTypes = {
  showOnHover: PropTypes.bool,
  hoverClassName: PropTypes.string,
  hoverContent: PropTypes.element,
  below: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.element,
}
