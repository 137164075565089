import React from 'react'
import { List } from 'immutable'
import classnames from 'classnames'

import FormCard from 'components/cards/FormCard'
import classes from 'containers/store/store.module.scss'
import * as forms from 'libs/forms'
import HStack from 'ui/layout/HStack'
import RemovableImage from 'ui/components/RemovableImage'
import Spacer from 'ui/layout/Spacer'
import buttonClasses from 'components/buttons/buttons.module.scss'
import ItemImageUpload from 'components/items/ItemImageUpload'
import { If } from 'libs/formats'

/**
 * A card within a form that allows the editing of a products
 * online configuration
 *
 * Because of how the old forms library works, we can't actually structure this
 * as a React component. I'm exposing this as a function so that it is re-usable,
 * even if it's not an actual component
 *
 * @param onlineProduct
 * @param updateImages A function that allows us to update the images for the product
 * @returns {*}
 */
export default function onlineProductCard(
  onlineProduct,
  updateImages,
  fieldNameFactory = (fieldName) => `onlineProduct.${fieldName}`
) {
  return (
    <FormCard header='Online'>
      <div className={classes.onlineProduct}>
        <forms.SwitchField
          name={fieldNameFactory('isEnabled')}
          label='Sell this product online'
          isFullWidth
        />

        {onlineProduct && onlineProduct.get('isEnabled')
          ? onlineProductConfiguration(onlineProduct, updateImages, fieldNameFactory)
          : null}
      </div>
    </FormCard>
  )
}

function onlineProductConfiguration(onlineProduct, updateImages, fieldNameFactory) {
  const images = onlineProduct.get('images', List())

  return (
    <div>
      <forms.TextareaField
        name={fieldNameFactory('description')}
        label='Online Description'
        isFullWidth
        disableResize
        rows={5}
      />

      <div className={forms.classes.field}>
        <div className={forms.classes.fieldLabel}>Additional images</div>

        <If condition={images}>
          <HStack spacing='xs'>
            {images.map((image) => (
              <RemovableImage
                key={image}
                alt=''
                className={classes.productImage}
                src={image}
                onClick={() => {
                  updateImages(images.filter((i) => i !== image))
                }}
              />
            ))}
          </HStack>
          <Spacer size='small' />
        </If>

        <div style={{ position: 'relative' }}>
          <button
            type='button'
            className={classnames(buttonClasses.flatButton, 'blueBackground')}
            style={{
              position: 'relative',
              cursor: 'pointer',
            }}
          >
            Add image
          </button>
          <ItemImageUpload
            grouping='online-product'
            fullCover
            maxHeight={1200}
            format='image/jpeg'
            encoderOptions={0.6}
            completeCallback={(data) => {
              updateImages(images.push(data.data.url))
            }}
          />
        </div>
      </div>
    </div>
  )
}
