import React from 'react'
import classnames from 'classnames'

import ItemImageUpload from 'components/items/ItemImageUpload'
import buttonClasses from 'components/buttons/buttons.module.scss'
import VStack from 'ui/layout/VStack'
import layout from 'ui/layout/layout.module.scss'
import Label from 'ui/components/Label'
import HStack from 'ui/layout/HStack'
import CheckboxButton from 'components/buttons/CheckboxButton'
import typography from 'ui/typography/typography.module.scss'
import { If } from 'libs/formats'

import { makeTestID } from 'libs/utils'
import DesignCard from './DesignCard'
import ColorPicker from '../ColorPicker/ColorPicker'
import { HeaderDesignCardProps } from './types'

const HeaderDesignCard: React.FunctionComponent<HeaderDesignCardProps> = ({
  logoURL,
  setLogoURL,
  showBusinessName,
  setShowBusinessName,
  primaryColor,
  setPrimaryColor,
}) => {
  return (
    <DesignCard
      testID={makeTestID('onlineStore', 'designPage', 'headerDesignCard')}
      title='Header and Theme'
    >
      <VStack>
        <VStack spacing='xs'>
          <Label>Store Logo</Label>
          <If condition={logoURL} fallback={<div> No logo uploaded </div>}>
            <img style={{ maxHeight: 128, maxWidth: '100%' }} src={logoURL} alt='logo' />
          </If>
          <div className={layout.relative}>
            <button
              type='button'
              className={classnames(buttonClasses.flatButton, 'secondary')}
              style={{
                position: 'relative',
              }}
            >
              Upload
            </button>
            <ItemImageUpload
              // eslint-disable-next-line
              grouping='online-store-logo'
              fullCover
              shouldSquareAndCenter={false}
              maxHeight={128}
              format='image/png'
              completeCallback={(data) => {
                setLogoURL(data.data.url)
              }}
            />
          </div>
        </VStack>

        {logoURL ? (
          <HStack>
            <CheckboxButton
              selected={showBusinessName}
              onClick={() => setShowBusinessName(!showBusinessName)}
            />
            <span>Show business name after logo</span>
          </HStack>
        ) : null}

        <div>
          <Label>Theme Colour</Label>

          <p className={typography.textSmall}>Buttons and links will appear in the chosen colour</p>
          <ColorPicker selectedColor={primaryColor} setColor={setPrimaryColor} />
        </div>
      </VStack>
    </DesignCard>
  )
}

export default HeaderDesignCard
