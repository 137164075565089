/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import PropTypes from 'prop-types'
import Immutable from 'immutable'

import BlockHeader from 'components/headers/BlockHeader'
import SplitBlock from 'components/blocks/SplitBlock'
import ConfirmPopup from 'components/popups/ConfirmPopup'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import { TabPanel, Tabs } from 'libs/tabs'
import { refreshTable } from 'redux/modules/tables'
import { formatCurrency, makeTestID } from 'libs/utils'
import CreateLinkForm from 'containers/online/CreateLinkForm'
import ShareLink from 'containers/online/ShareLink'
import PreventBeforeFullyOnboarded from 'containers/onboarding/PreventBeforeFullyOnboarded'
import { FilterBatch } from 'libs/filters'
import SearchBar from 'components/filters/SearchBar'
import { callJSONApi } from 'libs/api'
import FlatButton from 'components/buttons/FlatButton'
import { EmptyTableView, Table, TableRow } from 'libs/tables'
import Spacer from 'ui/layout/Spacer'
import { track } from 'libs/analytics'
import localisation from 'libs/localisation/localisation'
import { showMessage } from 'redux/modules/notifications'

import classes from './paymentLinks.module.scss'

const baseTestID = makeTestID('sellOnline', 'paymentLink')

class UnconnectedPaymentLinksPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showingSplit: false,
      showDeleteModal: false,
      shownRow: null,
      tableName: 'paymentLinksUnpaid',
      tabIndex: 0,
      linkCreated: false,
    }
  }

  onLinkCreated(data) {
    this.props.refreshTable()
    this.showRow(Immutable.Map(data))
    this.setState({
      showDelete: false,
      linkCreated: true,
    })
    track('payment_link_created', { name: 'create payment link ' })
  }

  onDeleteLink() {
    this.props.refreshTable()
    this.splitViewClosed()
    this.setState({
      showDeleteModal: false,
    })
    track('payment_link_deleted', { name: 'delete payment link' })
  }

  deletePaymentLink(uuid) {
    callJSONApi(
      `/paymentLinks/${uuid}`,
      'DELETE',
      {},
      (response) => {
        if (response && response.status === 200) {
          this.onDeleteLink()
        }
      },
      (prettyError) => {
        if (prettyError) {
          this.props.showMessage(prettyError)
          this.setState({
            showDeleteModal: false,
          })
        }
      }
    )
  }

  createLink() {
    this.setState({
      showingSplit: true,
      showDelete: false,
      linkCreated: false,
    })
    track('create_payment_link_clicked', { name: 'create payment link' })
  }

  showRow(rowData) {
    track('view_payment_link', { name: 'view payment link ' })

    this.setState((currentState) => {
      const newState = { ...currentState }

      if (newState.shownRow && newState.shownRow.get('uuid') === rowData.get('uuid')) {
        newState.shownRow = null
        newState.showDelete = false
      } else {
        newState.shownRow = rowData
        newState.showDelete = true
        newState.linkCreated = false
      }

      newState.showingSplit = !!newState.shownRow

      return newState
    })
  }

  deletePopUp() {
    return (
      <ConfirmPopup
        showing={this.state.showDeleteModal}
        icon='icon-delete-garbage-streamline'
        onCancel={() => this.hideDeletePopup()}
        onEsc={() => this.hideConfirmation()}
        title='Delete'
        subtitle='Are you sure you want to delete this Payment link'
        actionType='destructive'
        iconType='red-text'
        onConfirm={() => {
          this.deletePaymentLink(this.state.shownRow.get('uuid'))
        }}
      />
    )
  }

  splitViewClosed() {
    this.setState({
      shownRow: null,
      showingSplit: false,
    })
  }

  hideDeletePopup() {
    this.props.refreshTable()
    this.setState({
      showDeleteModal: false,
    })
  }

  hideConfirmation() {
    this.setState({
      showDeleteModal: false,
    })
  }

  tabChanged(tabIndex) {
    let selectedTab
    switch (tabIndex) {
      case 0:
        selectedTab = 'Unpaid'
        break
      case 1:
        selectedTab = 'Paid'
        break
      default:
        selectedTab = 'UnPaid'
    }

    this.setState({
      tabIndex,
      tableName: `paymentLinks${selectedTab}`,
    })
  }

  renderTab(tabIndex, tabName) {
    if (tabIndex === this.state.tabIndex) {
      return this.renderTable(tabName)
    }
    return null
  }

  renderSplitContent() {
    if (this.state && this.state.shownRow) {
      return <ShareLink shownRow={this.state.shownRow} />
    }

    return (
      <CreateLinkForm
        onLinkCreated={(id) => this.onLinkCreated(id)}
        formName={this.state.tableName}
        localisation={this.props.localisation}
      />
    )
  }

  renderTable(filter) {
    const rowClickable = filter === 'pending'
    return (
      <Table
        api={`/paymentLinks/?linkState=${filter}`}
        name={this.state.tableName}
        useOffset
        waitForFilterLoad={false}
        rowsClickable={rowClickable}
        canLoadMore
        getHeader={() => {
          return (
            <tr className='visible-header'>
              <th>Customer Name</th>
              <th className='hide-on-mobile'>Date created</th>

              <th>Url</th>
              <th className={classes.currencyHeader}>Amount</th>
            </tr>
          )
        }}
        getRow={(immutableRow, rowIndex) => {
          if (immutableRow.get('linkState') === filter) {
            return (
              <TableRow
                onClick={rowClickable ? () => this.showRow(immutableRow) : null}
                selected={
                  this.state.shownRow &&
                  this.state.shownRow.get('uuid') === immutableRow.get('uuid')
                }
                rowIndex={rowIndex}
                key={immutableRow.get('uuid')}
              >
                <td>{immutableRow.get('ref')}</td>
                <td className='hide-on-mobile'>
                  {moment(immutableRow.get('created')).format('L LT')}
                </td>
                <td>{immutableRow.get('url')}</td>
                <td className={classes.currency}>
                  {formatCurrency(immutableRow.get('amount'), this.props.localisation)}
                </td>
              </TableRow>
            )
          }
          return null
        }}
        emptyTable={
          <EmptyTableView showImage={false}>
            <Spacer size='large' />
            <h2>Get paid online</h2>
            <p>Send a payment link to your customer by email, Whatsapp or SMS.</p>
            <Spacer size='small' />
            <div>
              <FlatButton
                className='blueBackground'
                label='Send a payment Link'
                onClick={() => this.createLink()}
              />
            </div>
            <Spacer size='medium' />
            <a
              href='https://get.yoco.help/hc/en-za/articles/360007134238-Send-a-Payment-Link'
              target='_blank'
              rel='noopener noreferrer'
              onClick={() => track('payment_link_help', { name: 'What is a payment link?' })}
            >
              What is a payment link?
            </a>
          </EmptyTableView>
        }
      />
    )
  }

  render() {
    return (
      <SplitBlock
        formName={this.state.linkCreated ? null : this.state.tableName}
        showDelete={this.state.showDelete}
        header='Send a payment link'
        showingSplit={this.state.showingSplit}
        saveLabel={this.state.linkCreated ? 'Done' : this.state.shownRow ? 'Save' : 'Next'}
        showSave={this.state.linkCreated || !this.state.shownRow}
        showCancel={!this.state.linkCreated}
        savePressed={this.state.linkCreated ? () => this.splitViewClosed() : null}
        deletePressed={() => {
          this.setState({ showDeleteModal: true })
        }}
        onDismiss={() => this.splitViewClosed()}
        renderSplitContent={() => this.renderSplitContent()}
      >
        <PreventBeforeFullyOnboarded />
        <MaxWidthBlock>
          <BlockHeader
            title='Payment Links'
            style={{ marginBottom: '12px' }}
            actions={
              <FlatButton
                className='blueBackground'
                label='Send a payment link'
                onClick={() => this.createLink()}
                testID={makeTestID(baseTestID, 'createLink')}
              />
            }
          />
          <FilterBatch name={this.state.tableName} showMobileInline>
            <SearchBar placeholder='Search by name' testID={makeTestID(baseTestID, 'searchBar')} />
          </FilterBatch>
          <Tabs tabIndex={this.state.tabIndex} tabChanged={(index) => this.tabChanged(index)}>
            <TabPanel title='Unpaid'>{this.renderTab(0, 'pending')}</TabPanel>
            <TabPanel title='Paid'>{this.renderTab(1, 'paid')}</TabPanel>
          </Tabs>
        </MaxWidthBlock>
        {this.deletePopUp()}
      </SplitBlock>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  refreshTable: () => dispatch(refreshTable('paymentLinksUnpaid')),
  showMessage: (errorMsg) =>
    dispatch(showMessage(`Error deleting Payment link: ${errorMsg}`, 'danger')),
})

const mapStateToProps = (state) => {
  return {
    localisation: localisation(state),
  }
}
const PaymentLinksPage = connect(mapStateToProps, mapDispatchToProps)(UnconnectedPaymentLinksPage)

export default PaymentLinksPage

UnconnectedPaymentLinksPage.propTypes = {
  refreshTable: PropTypes.func,
  localisation: PropTypes.object,
  showMessage: PropTypes.func,
}
