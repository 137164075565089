/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'

import { fetchSalesPerformance } from 'redux/modules/sales/performance/actions'
import { PERFORMANCE_PERIODS } from 'redux/modules/sales/performance/index'
import classes from 'containers/sales/sales.module.scss'
import localisation from 'libs/localisation/localisation'
import { formatCurrency } from 'libs/utils'
import DatePeriodFilter from 'components/filters/DatePeriodFilter'

class SalesPerformancePage extends Component {
  UNSAFE_componentWillMount() {
    this.props.actions.fetchSalesPerformance(PERFORMANCE_PERIODS.YEAR)
  }

  formatCurrency(amount) {
    return this.props.localisation
      ? this.props.localisation.formatCurrency(amount)
      : formatCurrency(amount, 'ZAR')
  }

  renderHeaders() {
    return (
      <tr className={classes.newGroupHeader}>
        {this.props.performance.rows.get(0).map((_, key) => {
          return (
            <th key={key} className={classes.headStyle}>
              {key[0].toUpperCase() + key.slice(1)}
            </th>
          )
        })}
      </tr>
    )
  }

  renderRows() {
    return this.props.performance.rows.map((row, i) => {
      return <tr key={i}>{this.renderRow(row)}</tr>
    })
  }

  renderRow(row) {
    return row.map((value, i) => {
      return (
        <td key={i} className={`${classes.number} ${classes.amount}`}>
          {Number.isNaN(Number(value)) ? value : this.formatCurrency(value)}
        </td>
      )
    })
  }

  render() {
    if (this.props.performance.rows.length === 0) {
      return <div>No Results</div>
    }
    return (
      <div>
        <DatePeriodFilter
          currentPeriod={this.props.performance.period}
          fetchResultsForPeriod={this.props.actions.fetchSalesPerformance}
        />
        <div
          className={`${classes.salesHistoryPage} ${classes.transactionList} touch`}
          style={{ width: '20%' }}
        >
          {/* Temporary width above for limited data: https://app.clubhouse.io/yocotech/story/5474/first-slice-of-the-sales-performance-report */}
          <div className={classes.salesReport}>
            <table className={classes.table}>
              <thead>{this.renderHeaders()}</thead>
              <tbody>{this.renderRows()}</tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    performance: state.performance.performance,
    localisation: localisation(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ fetchSalesPerformance }, dispatch),
  }
}

SalesPerformancePage.propTypes = {
  performance: PropTypes.object,
  actions: PropTypes.object,
  localisation: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesPerformancePage)
