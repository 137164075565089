import * as libUtils from 'libs/utils'
import styles from './CapitalQualificationCriteriaBlock.module.scss'

export const determineHeadingStyle = (progress: number): string =>
  progress === 99.9 ? styles.achieved : styles.actual

export const determineProgressBarStyle = (progress: number): string =>
  progress === 99.9 ? styles.finishedBar : styles.barWrapper

export const determineBorderStyle = (shouldApplyStyle?: boolean): string | undefined =>
  shouldApplyStyle ? styles.middleBlock : undefined

export const typeMap = {
  months: (value): string => `${value} months`,
  volume: (value): string => `${libUtils.formatCurrency(value, 'ZAR')}`,
  transactions: (value): string => `${libUtils.round(value)}`,
}
