import React from 'react'
import propTypes from 'prop-types'

import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import SplitBlock from 'components/blocks/SplitBlock'

import { useCustomerSplitBlockProps } from './ListViewSplit.hooks'

function CustomerListViewSplit({ children, customer, setCustomer }) {
  const splitBlockProps = useCustomerSplitBlockProps(customer, setCustomer)

  return (
    <SplitBlock {...splitBlockProps}>
      <MaxWidthBlock>{children}</MaxWidthBlock>
    </SplitBlock>
  )
}

CustomerListViewSplit.propTypes = {
  children: propTypes.node,
  customer: propTypes.any,
  setCustomer: propTypes.func,
}

export default CustomerListViewSplit
