import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@yoco/design-system-icons/dist/react'
import classnames from 'classnames'

import layout from 'ui/layout/layout.module.scss'

import classes from './transactions.module.scss'
import { transactionStateColor, transactionStateIcon } from './utils'

export default function TransactionStateIcon({ transactionState, iconName }) {
  return (
    <div
      className={classnames([
        classes.transactionState,
        classes.transactionStateIcon,
        layout.inlineBlock,
      ])}
    >
      <Icon
        name={iconName || transactionStateIcon(transactionState)}
        color={transactionStateColor(transactionState)}
        size={24}
      />
    </div>
  )
}

TransactionStateIcon.propTypes = {
  transactionState: PropTypes.string,
  // Forcefully display a specific icon
  iconName: PropTypes.string,
}
