/* eslint-disable consistent-return */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Amount, FormattedNumber } from 'libs/formats'
import Spinner from 'components/loaders/Spinner'

import classes from './insights.module.scss'

export default class InsightDataBlock extends Component {
  getHeader() {
    if (this.props.header) {
      return <div className={classes.insightHeader}>{this.props.header}</div>
    }
  }

  getPrimaryValue() {
    if (this.props.primaryIsCurrency) {
      return (
        <div className={classes.primary}>
          <Amount amount={this.props.primaryValue} alignLeft={!this.props.alignRight} />
          {this.getValuesList()}
        </div>
      )
    }
    return (
      <div className={classes.primary}>
        <FormattedNumber
          number={this.props.primaryValue}
          alignLeft={!this.props.alignRight}
          format='0,0'
        />
        {this.getValuesList()}
      </div>
    )
  }

  getValuesList() {
    if (this.props.values) {
      return (
        <div className={classes.values}>
          {this.props.values.map((item) => {
            let { value } = item
            if (item.isCurrency) {
              value = <Amount amount={item.value} />
            }

            if (!item.showIfZero && (!item.value || item.value === 0)) {
              return
            }

            const spacerClassName = this.props.alignRight ? classes.spacer : ''

            return (
              <div className={classes.valueBlock} key={item.label}>
                <div className={spacerClassName} />
                <div className={classes.itemLabel}>{item.label}</div>
                <div className={classes.itemValue}>{value}</div>
              </div>
            )
          })}
        </div>
      )
    }
  }

  getContent() {
    if (this.props.loading) {
      return (
        <div className={classes.loadingView}>
          <div className={classes.spacer} />
          <Spinner blue />
          <div className={classes.spacer} />
        </div>
      )
    }
    let flexBoxClass = classes.flexBox
    if (this.props.leftAlign) {
      flexBoxClass += ` ${classes.leftAlign}`
    }

    return <div className={flexBoxClass}>{this.getPrimaryValue()}</div>
  }

  render() {
    let className = classes.insightDataBlock
    if (this.props.className) {
      className = `${className} ${this.props.className}`
    }
    return (
      <div className={className}>
        {this.getHeader()}
        {this.getContent()}
      </div>
    )
  }
}

InsightDataBlock.propTypes = {
  header: PropTypes.any,
  primaryIsCurrency: PropTypes.bool,
  primaryValue: PropTypes.number,
  alignRight: PropTypes.string,
  values: PropTypes.array,
  loading: PropTypes.bool,
  leftAlign: PropTypes.string,
  className: PropTypes.string,
}
