import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

function RadioButton({ index, option, checked, onPress }) {
  const id = `option_${option.value}`

  return (
    <label
      className={`${option.subtitle ? styles.radioWithSubtitle : styles.radioWithoutSubtitle}
        ${option.subtitle && checked ? styles.radioWithSubtitleSelected : ''}
        ${option.subtitle && !checked ? styles.radioWithSubtitleUnselected : ''}
        ${styles.radio}`}
      htmlFor={id}
    >
      <input
        className={styles.hidden}
        type='radio'
        name='rdo'
        id={id}
        defaultChecked={checked}
        onClick={() => onPress(option, index)}
      />
      <span className={styles.label} />
      {option.label}
      {option.subtitle ? <div className={styles.radioSubtitle}>{option.subtitle}</div> : ''}
    </label>
  )
}

RadioButton.propTypes = {
  index: PropTypes.number.isRequired,
  option: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    subtitle: PropTypes.string,
  }).isRequired,
  checked: PropTypes.bool.isRequired,
  subtitle: PropTypes.string,
  onPress: PropTypes.func.isRequired,
}

export default RadioButton
