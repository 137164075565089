/* eslint-disable react/no-unused-class-component-methods */
import React from 'react'

import { mouseIsDown, mouseDownHandler, mouseUpHandler } from 'libs/utils'

export default class DismissComponent extends React.Component {
  /*
   Ensure you add this to div's you don't want to dismiss from
   onMouseDown={this.mouseDownHandler.bind(this)}
   onMouseUp={this.mouseUpHandler.bind(this)}
   */

  constructor(props) {
    super(props)

    this.state = {}
  }

  UNSAFE_componentWillMount() {
    if (!this.pageClickHandler) {
      this.pageClickHandler = () => {
        if (mouseIsDown) {
          return
        }

        if (this.isToggled()) {
          this.onDismiss()
        }
      }

      window.addEventListener('mousedown', this.pageClickHandler, false)
    }
  }

  componentWillUnmount() {
    if (this.pageClickHandler) {
      window.removeEventListener('mousedown', this.pageClickHandler, false)
      this.pageClickHandler = null
    }
  }

  onDismiss() {
    this.setState({
      toggled: false,
    })
  }

  isToggled() {
    return this.state.toggled
  }

  mouseDownHandler() {
    mouseDownHandler()
  }

  mouseUpHandler() {
    mouseUpHandler()
  }
}
