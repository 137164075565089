/* eslint-disable no-promise-executor-return */
import { useEffect, useState } from 'react'

import { callInvoicesJSONAPI } from 'libs/api'
import store from 'redux/store'
import { initializeForm } from 'redux/modules/forms'

import { FormNames } from './constants'
import { normalizeInitialFormData } from './utils'

export const useInitialiseFormValues = (initialValues) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState()

  useEffect(() => {
    callInvoicesJSONAPI('business', 'GET')
      .then((invoice) => {
        store.dispatch(
          initializeForm(
            FormNames.Update,
            normalizeInitialFormData({
              ...initialValues,
              ...invoice.data,
            })
          )
        )
      })
      .catch(() => setError('Failed to fetch invoice settings'))
      .finally(() => setIsLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return [isLoading, error]
}
