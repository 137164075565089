import React, { useState } from 'react'

import PageHeader from 'ui/components/PageHeader'
import invoicesIcon from 'assets/images/Invoices-64px.svg'
import CenteredContainerPage from 'components/pages/CenteredContainerPage'
import FlatButton from 'components/buttons/FlatButton'
import { track } from 'libs/analytics'
import { makeTestID } from 'libs/utils'
import SplitBlock from 'components/blocks/SplitBlock'
import { CreateInvoicesSettings, FormNames } from 'containers/invoices/components/Settings'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import PaddedContent from 'ui/layout/PaddedContent'
import Spacer from 'ui/layout/Spacer'
import { fetchInvoiceOnboardingData } from '../utils'

function Setup() {
  const [isFormVisible, setIsFormVisible] = useState(false)

  return (
    <MaxWidthBlock>
      <PaddedContent isTextCentered>
        <SplitBlock
          showingSplit={isFormVisible}
          header='Invoices setup'
          formName={FormNames.Create}
          renderSplitContent={() => (
            <CreateInvoicesSettings
              onCreated={() => {
                fetchInvoiceOnboardingData({ showLoader: true })
              }}
            />
          )}
          onDismiss={() => setIsFormVisible(false)}
        >
          <CenteredContainerPage>
            <PageHeader>Yoco Invoices</PageHeader>
            <img src={invoicesIcon} alt='invoices' />
            <Spacer size='xl' />
            <p>
              Send simple, professional invoices to your customers
              <br />
              and get paid fast.
            </p>
            <Spacer size='xl' />
            <FlatButton
              label='Set up invoices'
              className='blueBackground'
              onClick={() => {
                setIsFormVisible(true)
                track('invoices_set_up_button_clicked', {
                  name: 'invoices_set_up_button_clicked',
                })
              }}
              testID={makeTestID('invoices', 'gettingStarted', 'taskSetupBusinessTrigger')}
            />
          </CenteredContainerPage>
        </SplitBlock>
      </PaddedContent>
    </MaxWidthBlock>
  )
}

export default Setup
