/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState, useEffect } from 'react'
import { Page, Image, Document, PDFDownloadLink } from '@react-pdf/renderer'
import QRCode from 'qrcode'
import linkIcon from 'assets/images/icon-link.svg'
import logo from 'assets/images/yoco.png'
import classes from './ReferralQRCodePopup.module.scss'
import styles from './ReferralQRCodeGenerator.styles'

interface PDFProps {
  qrCode: string
}

const PDF = ({ qrCode }: PDFProps): JSX.Element => {
  return (
    <Document>
      <Page style={styles.page}>
        <Image src={logo} style={styles.logo} />
        <Image src={qrCode} style={styles.qr} />
      </Page>
    </Document>
  )
}

interface Props {
  url: string
}

const ReferralQrCodeGenerator: React.FunctionComponent<Props> = ({ url }) => {
  const [qrCode, setQrCode] = useState<string>('')
  useEffect(() => {
    QRCode.toDataURL(url, (err, url) => setQrCode(url))
  }, [url])

  return (
    <>
      <div>
        <img className={classes.qrImage} src={qrCode} alt='qr-code' />
      </div>
      <div className={classes.linkContainer}>
        <img src={linkIcon} alt='link-icon' className={classes.linkIcon} />
        <p className={classes.url}>{url}</p>
      </div>
      <div className={classes.buttonContainer}>
        <PDFDownloadLink document={<PDF qrCode={qrCode} />} fileName='referral-qr-code'>
          {({ loading }) => (
            <button
              type='button'
              style={{
                position: 'relative',
                cursor: 'pointer',
              }}
              className={classes.downloadAsPDFButton}
            >
              {loading ? 'Loading...' : 'Download as PDF'}
            </button>
          )}
        </PDFDownloadLink>
      </div>
    </>
  )
}

export default ReferralQrCodeGenerator
