/* eslint-disable max-classes-per-file */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import * as forms from 'libs/forms'
import { refreshTable } from 'redux/modules/tables'
import CheckboxButton from 'components/buttons/CheckboxButton'
import { initializeForm } from 'redux/modules/forms'
import FormCard from 'components/cards/FormCard'
import classes from 'containers/business/business.module.scss'
import { makeTestID } from 'libs/utils'
import FlatButton from 'components/buttons/FlatButton'
import { hasFeature } from 'libs/features'
import { defaultRoleDescriptions } from './roleDescriptions'

export const baseTestID = makeTestID('staff', 'staffForm')

class UnconnectedStaffForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      permissionsVisible: false,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.shownRow !== this.props.shownRow) {
      this.loadStaffMember(nextProps.shownRow)
    }
  }

  onPermissionsClicked() {
    this.setState((state) => {
      return { permissionsVisible: !state.permissionsVisible }
    })
  }

  onUpdateStaff() {
    this.props.dispatch(refreshTable(this.props.formName))
    this.props.closeSplitView()
  }

  setPINField() {
    if (!this.props.hasStaffSwitching) {
      return null
    }
    const hasPin = this.props.shownRow
      ? this.props.shownRow.getIn(['info', 'hasStaffPIN'])
      : this.props.staffPIN
    return (
      <>
        {hasPin ? (
          <FlatButton
            label='Clear PIN'
            type='button'
            className='destructive'
            onClick={this.props.onClearPIN}
          />
        ) : undefined}
        <FlatButton
          label={hasPin ? 'Change PIN' : 'Set PIN'}
          type='button'
          className='secondary'
          onClick={this.props.onSetPIN}
        />
      </>
    )
  }

  loadStaffMember(shownRow) {
    if (!shownRow) {
      this.props.dispatch(initializeForm(this.props.formName, {}))
    } else {
      this.props.dispatch(initializeForm(this.props.formName, shownRow))
    }
  }

  disallowedRoles() {
    return {
      userCanAssignSales: !this.props.canAssignSales,
      userCanAssignSalesHistorically: !this.props.canAssignSales,
    }
  }

  render() {
    const { shownRow } = this.props
    const api = shownRow ? `/staff/${shownRow.get('uuid')}` : '/staff/portal-invite'
    const disallowedRolesMap = this.disallowedRoles()
    const remoteRoleDescriptions = this.props?.roleDescriptions?.map((roleGroup) =>
      roleGroup.update('roles', (roles) =>
        roles.filter((role) => !disallowedRolesMap[role.get('role')])
      )
    )

    // Fallback to local role descriptions in remote firebase ones fail
    let roleDescriptions = remoteRoleDescriptions?.toJS()
    if (!roleDescriptions?.length) {
      roleDescriptions = defaultRoleDescriptions
    }

    const permissionStyle = { display: this.state.permissionsVisible ? 'block' : 'none' }
    const permissionsTitle = this.state.permissionsVisible
      ? 'Hide advanced permissions'
      : 'Show advanced permissions'

    return (
      <div>
        <forms.Form
          action={api}
          name={this.props.formName}
          onSuccess={() => {
            this.onUpdateStaff()
          }}
          testID={makeTestID(baseTestID, 'staffForm')}
        >
          <FormCard header='General Information'>
            <forms.TextField
              name='firstName'
              label='First Name'
              placeholder='First Name'
              validators={[new forms.RequiredValidator('You must provide a First Name')]}
              testID={makeTestID(baseTestID, 'firstName')}
            />
            <forms.TextField
              name='lastName'
              label='Last Name'
              placeholder='Last Name'
              validators={[new forms.RequiredValidator('You must provide a Last Name')]}
              testID={makeTestID(baseTestID, 'lastName')}
            />
            <forms.EmailField
              name='email'
              label='Staff Email'
              placeholder='staff@email.com'
              validators={[
                new forms.EitherOrValidator(
                  'mobileNumber',
                  'You must provide an email address or phone number'
                ),
                new forms.EmailValidator(),
              ]}
              testID={makeTestID(baseTestID, 'staffEmail')}
            />
            <forms.TextField
              name='mobileNumber'
              label='Staff mobile number'
              placeholder='080 000 000'
              validators={[
                new forms.EitherOrValidator(
                  'email',
                  'You must provide an email address or phone number'
                ),
                new forms.MobileValidator(),
              ]}
              testID={makeTestID(baseTestID, 'staffMobileNumber')}
            />
          </FormCard>
          <FormCard header='Permissions & Security'>
            <UserTypeSelectField
              isFullWidth
              placeholder='Select a role for this staff member'
              name='userType'
              options={[
                { value: 'staff_member', label: 'Staff Member' },
                { value: 'supervisor', label: 'Supervisor' },
                { value: 'manager', label: 'Manager' },
                { value: 'administrator', label: 'Administrator' },
                { value: 'custom', label: 'Custom Permissions' },
              ]}
              additionalData={this.props.additionalData}
              validators={[new forms.RequiredValidator('You must provide a role')]}
            />
            <div className={classes.roles}>
              <div className={classes.showHidePermissions}>
                {/* eslint-disable-next-line */}
                <a onClick={() => this.onPermissionsClicked()}>{permissionsTitle}</a>
              </div>
              <div className={classes.permissionsList} style={permissionStyle}>
                {roleDescriptions?.map((permissionSection) => (
                  <section key={permissionSection.groupName}>
                    <h5 className={classes.permissionSectionTitle}>
                      {permissionSection.groupName}
                    </h5>
                    {permissionSection.roles.map((permission) => (
                      <PermissionCheckBox
                        key={permission?.title}
                        name={`roles.${permission?.role}`}
                        label={permission?.title}
                        description={permission?.description}
                        note={permission?.note}
                      />
                    ))}
                  </section>
                ))}
              </div>
            </div>
            {this.setPINField()}
            {this.props.staffPIN ? (
              <forms.HiddenField name='staffPIN' defaultValue={this.props.staffPIN} />
            ) : undefined}
          </FormCard>
        </forms.Form>
      </div>
    )
  }
}

const StaffForm = connect((state) => {
  return {
    canAssignSales: hasFeature(state, 'assignSales'),
  }
})(UnconnectedStaffForm)

UnconnectedStaffForm.propTypes = {
  staffPIN: PropTypes.string,
  formName: PropTypes.string,
  hasStaffSwitching: PropTypes.bool,
  closeSplitView: PropTypes.func,
  shownRow: PropTypes.object,
  additionalData: PropTypes.object,
  roleDescriptions: PropTypes.object,
  canAssignSales: PropTypes.bool,
  onSetPIN: PropTypes.func,
  onClearPIN: PropTypes.func,
  dispatch: PropTypes.func,
}

export default StaffForm

class UserTypeSelectField extends forms.SelectField {
  onChange(value) {
    super.onChange(value)

    const roles = this.props.additionalData[value.value].reduce(
      (accumulator, role) => accumulator.set(role, true),
      Map()
    )
    this.props.form.onFieldChange('roles', roles)
  }
}

class PermissionCheckBox extends forms.SwitchField {
  onClick(value) {
    super.onClick(value)
    this.props.form.onFieldChange('userType', 'custom')
  }

  render() {
    return (
      <div className={classes.permission}>
        <div className={classes.permissionCheckbox}>
          <CheckboxButton
            name={this.props.name}
            onClick={() => this.onClick()}
            selected={this.props.value}
          />
        </div>
        <div className={classes.permissionText}>
          <span>{this.props.label}</span>
          <div className={classes.help}>{this.props.description}</div>
          {this.props.note ? <div className={classes.subHelp}>{this.props.note}</div> : null}
        </div>
      </div>
    )
  }
}
