import React from 'react'

import * as forms from 'libs/forms'

import LineItems from './LineItems'

class LineItemsField extends forms.FormField {
  renderInput() {
    return (
      <div
        className={forms.classes.inputWrapper}
        style={{ height: 'auto' }}
        data-testid={this.props.testID}
      >
        <LineItems
          value={this.props.value}
          onChange={(value) => {
            this.props.form.onFieldChange(this.props.name, value)
          }}
          trackingEvents={this.props.trackingEvents}
          testID={this.props.testID}
        />
      </div>
    )
  }
}

export default LineItemsField
