import React from 'react'
import { Link } from 'react-router'
import { Icon } from '@yoco/design-system-icons/dist/react'

import classes from './OnlineStoreBackButton.module.scss'

const OnlineStoreBackButton = (): JSX.Element => (
  <div>
    <Link to='/online/store' className={classes.buttonLink} aria-label='go back'>
      <Icon name='arrow-left' size={24} />
    </Link>
  </div>
)

export default OnlineStoreBackButton
