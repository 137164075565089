import { useDispatch } from 'react-redux'
import { push } from 'react-router-redux'
import { track } from 'libs/analytics'

import { contentMap, isNotRelevantState } from '../utils'
import { ModalProps } from '../types'

const trackingAction = (cb: () => void) => (event?: string) => (): void => {
  if (event) {
    track(event)
  }
  cb()
}

const usePreventTransactionsData = (): ((state: string) => ModalProps) => {
  const dispatch = useDispatch()

  const getData = (state: string) => {
    if (!state || isNotRelevantState(state)) {
      return null
    }

    const onboard = trackingAction(() => dispatch(push('/onboarding')))
    const help = trackingAction(() => window.open('https://support.yoco.help/s/'))
    const home = trackingAction(() => dispatch(push('/dashboard')))

    const actionMap = {
      unknown: {
        primaryAction: () => window.location.reload(),
        secondaryAction: home(),
      },
      failed: {
        primaryAction: onboard('not_onboarded_cannot_verify_whats_missing_clicked'),
        secondaryAction: help('not_onboarded_cannot_verify_close_clicked'),
      },
      todo: {
        primaryAction: onboard('not_onboarded_complete_account_button_clicked'),
        secondaryAction: home('not_onboarded_complete_not_now_button_clicked'),
      },
      in_progress: {
        primaryAction: home('not_onboarded_verify_close_clicked'),
        secondaryAction: help('not_onboarded_verify_check_status_clicked'),
      },
    }

    return {
      ...contentMap[state],
      ...actionMap[state],
    }
  }

  return getData
}

export default usePreventTransactionsData
