import DateFilter from 'components/filters/DateFilter'
import BlockHeader from 'components/headers/BlockHeader'
import { FilterBatch } from 'libs/filters'
import { EmptyTableView, Table } from 'libs/tables'
import moment from 'moment'
import S from 'string'
import React from 'react'
import blockStyles from 'components/blocks/blocks.module.scss'
import { friendlyDate } from 'libs/utils'
import styles from './CapitalDeductionsTable.module.scss'
import DeductionPlanRowItem from '../DeductionPlanRowItem'

interface Props {
  selectedDeductionPlan: any
  formatCurrency: (amount: number) => string
  selectDeductionPlan: (deductionPlan: any) => void
}

const CapitalDeductionsTable: React.FunctionComponent<Props> = ({
  selectedDeductionPlan,
  formatCurrency,
  selectDeductionPlan,
}) => {
  const yearAgo = moment().startOf('day').subtract(1, 'year')
  const monthFromNow = moment().endOf('month').add(1, 'day')
  const tableHeader = () => {
    return (
      <tr className='visible-header'>
        <th className='hide-on-mobile'>Provider</th>
        <th>State</th>
        <th>Paid amount</th>
        <th>Owed amount</th>
        <th className='hide-on-mobile'>Created</th>
      </tr>
    )
  }

  const tableRow = (deductionPlan, rowIndex: number) => {
    const uuid = deductionPlan.get('uuid')
    const payoutDeductionCategory = S(deductionPlan.get('payoutDeductionCategory')).humanize().s
    const planProviderDisplayName = S(
      deductionPlan.getIn(['providerBusiness', 'displayName'], 'Yoco')
    ).humanize().s
    const payoutDeductionState = S(deductionPlan.get('payoutDeductionState')).humanize().s
    const totalAmountPaid = formatCurrency(deductionPlan.get('totalAmountPaid', 0))
    const totalAmountPayable = formatCurrency(deductionPlan.get('totalAmountPayable', 0))
    const created = friendlyDate(deductionPlan.get('created'))
    const handleRowClicked = () => selectDeductionPlan(deductionPlan)
    const isSelectedRow = selectedDeductionPlan === deductionPlan

    return (
      <DeductionPlanRowItem
        key={uuid}
        onClick={handleRowClicked}
        selected={isSelectedRow}
        rowIndex={rowIndex}
        uuid={uuid}
        planCategory={payoutDeductionCategory}
        planProviderDisplayName={planProviderDisplayName}
        payoutDeductionState={payoutDeductionState}
        totalAmountPaid={totalAmountPaid}
        totalAmountPayable={totalAmountPayable}
        created={created}
      />
    )
  }

  return (
    <section className={`${blockStyles.outerBlock} ${blockStyles.paddedBlock} touch`}>
      <BlockHeader title='Cash advances' />
      <FilterBatch name='allCapitalDeductions' showMobileInline>
        <div className={styles.filterPlaceholder} />
        <DateFilter defaults={[yearAgo, monthFromNow]} />
      </FilterBatch>
      <Table
        api='/lending/capitalDeductions/'
        name='allCapitalDeductions'
        rowsClickable
        useOffset
        pageSize={10}
        hideNoResults
        emptyTable={
          <EmptyTableView title='There are no deduction plans for the date range selected, try changing it' />
        }
        getHeader={tableHeader}
        getRow={tableRow}
      />
    </section>
  )
}

export default CapitalDeductionsTable
