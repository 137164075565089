import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import layout from './layout.module.scss'

export default function DesktopOnly({ children, className }) {
  return <div className={classnames([layout.hideOnMobile, className])}>{children}</div>
}

DesktopOnly.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}
