/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react'
import PropTypes from 'prop-types'

import { getENV } from 'libs/utils'

import PlacesFieldContainer from './components/PlacesFieldContainer'
import PlacesInputField from './components/PlacesInputField'
import PlacesFieldResults from './components/PlacesFieldResults'
import {
  dismissPlacesSuggestionsOnOutsideClick,
  handleSelectedAddress,
  onPlacesFieldBlur,
  onPlacesFieldFocus,
  updatePlacesPredictions,
} from './utils'

const $script = require('scriptjs')

class PlacesField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      autocompleteService: undefined,
      placesService: undefined,
      autoCompleteOptions: [],
      inputPlacesIsConnected: false,
      isFocused: false,
    }
  }

  componentDidUpdate() {
    if (!this.state.inputPlacesIsConnected) {
      $script(
        [
          `https://maps.googleapis.com/maps/api/js?key=${getENV(
            'GOOGLE_MAPS_API_KEY'
          )}&libraries=places`,
        ],
        'google-places-api'
      )

      $script.ready('google-places-api', () => {
        this.setState({
          autocompleteService: new window.google.maps.places.AutocompleteService(),
          placesService: new window.google.maps.places.PlacesService(document.createElement('div')),
          inputPlacesIsConnected: true,
        })
      })
    }
    dismissPlacesSuggestionsOnOutsideClick(
      this.state.isFocused,
      this.state.autoCompleteOptions,
      (emptyOptions) =>
        this.setState({
          autoCompleteOptions: emptyOptions,
        })
    )
  }

  render() {
    return (
      <PlacesFieldContainer>
        <PlacesInputField
          label={this.props.label}
          placeholder={this.props.placeholder}
          firstLine={this.props.firstLine}
          updateFirstLine={this.props.updateFirstLine}
          updatePlacePredictions={() =>
            updatePlacesPredictions(this.state.autocompleteService, (autoCompleteOptions) =>
              this.setState({
                autoCompleteOptions,
              })
            )
          }
          isRequired={this.props.required}
          isFocused={this.state.isFocused}
          onFocus={() => onPlacesFieldFocus(true, (isFocused) => this.setState({ isFocused }))}
          onBlur={() =>
            onPlacesFieldBlur(false, (isNotFocused) => this.setState({ isFocused: isNotFocused }))
          }
        />

        <PlacesFieldResults
          autoCompleteOptions={this.state.autoCompleteOptions}
          setSelectedAddress={handleSelectedAddress}
          placesService={this.state.placesService}
          updateAddressFields={this.props.updateAddressFields}
          clearAutoCompleteResults={() =>
            this.setState({
              autoCompleteOptions: [],
            })
          }
        />
      </PlacesFieldContainer>
    )
  }
}

PlacesField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  firstLine: PropTypes.string,
  updateFirstLine: PropTypes.any,
  required: PropTypes.bool,
  updateAddressFields: PropTypes.any,
}

export default PlacesField
