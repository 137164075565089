import greenTick from 'assets/images/success-green-icon@2x.png'
import FlatButton from 'components/buttons/FlatButton'
import capitalStyles from 'components/capital/capital.module.scss'
import CapitalBankingDetailsBlock from 'components/capital/partials/CapitalBankingDetailsBlock'
import CapitalDetailsBlock from 'components/capital/partials/CapitalDetailsBlock'
import CapitalHeaderBlock from 'components/capital/partials/CapitalHeaderBlock'
import CapitalHelpMessage from 'components/capital/partials/CapitalHelpMessage'
import TitleCard from 'components/cards/TitleCard'
import CenteredContainerPage from 'components/pages/CenteredContainerPage'
import { prettyPrintNumber } from 'libs/utils'
import React from 'react'
import { Link } from 'react-router'
import { LendingPartners } from 'redux/modules/capital/types'

import { getLendingPartnerDisplayName } from 'components/capital/utils'
import styles from './CapitalApplicationApprovedPage.module.scss'

interface Props {
  bankName: string
  bankAccountHolder: string
  bankAccountNumber: string
  bankBranchCode: string
  userEmail: string
  providerName: LendingPartners
  approvedAdvance: number
  outstandingBalance?: number
  repaymentPercentage: number
  estimatedTerm: number
  processingFee: number
  totalPayable: number
  contractURL: string
  formatCurrency: (amount: number) => string
  onDeductionsLinkClicked: () => void
  onViewContractClicked: () => void
}

const CapitalApplicationApprovedPage: React.FunctionComponent<Props> = ({
  bankName,
  bankAccountHolder,
  bankAccountNumber,
  bankBranchCode,
  userEmail,
  providerName,
  approvedAdvance,
  outstandingBalance,
  repaymentPercentage,
  estimatedTerm,
  processingFee,
  totalPayable,
  contractURL,
  formatCurrency,
  onDeductionsLinkClicked,
  onViewContractClicked,
}) => {
  return (
    <CenteredContainerPage className={capitalStyles.capitalContainer}>
      <div className={capitalStyles.page}>
        <CapitalHeaderBlock
          title='Growing your business with Yoco Capital.'
          description={`Your funds are on the way! Your cash advance has been approved by ${getLendingPartnerDisplayName(
            providerName
          )}.`}
        />
        <img src={greenTick} className={styles.greenTick} alt='happyFace' />
        <h3 className={styles.approvedHeader}>Approved!</h3>
        <p className={styles.reflectMessage}>
          Your cash advance will reflect in your account within 48 hours.
        </p>
        <br />
        <TitleCard title='Summary'>
          <div>
            <h4>A breakdown of your offer from {getLendingPartnerDisplayName(providerName)}.</h4>
            <div className={styles.offerSummary}>
              <div className={styles.amountBlock}>
                <h4>Amount you&apos;ll receive</h4>
                <h1>{`R ${prettyPrintNumber(approvedAdvance)}`}</h1>
              </div>
              <CapitalDetailsBlock
                outstandingBalance={outstandingBalance}
                repaymentPercentage={repaymentPercentage}
                estimatedTerm={estimatedTerm}
                processingFee={processingFee}
                totalPayable={totalPayable}
              />
            </div>
          </div>
          <CapitalBankingDetailsBlock
            bank={bankName}
            accountHolder={bankAccountHolder}
            accountNumber={bankAccountNumber}
            branchCode={bankBranchCode}
            email={userEmail}
            providerName={providerName}
            approvedAmount={formatCurrency(approvedAdvance)}
          />
          <div className={styles.buttonsContainer}>
            <div>
              <Link to='/capital/payments' className='flatButton'>
                <FlatButton
                  className='secondary'
                  style={{ textDecoration: 'none' }}
                  label='View deductions'
                  onClick={onDeductionsLinkClicked}
                />
              </Link>
              <FlatButton
                name='capitalContract'
                label='View contract'
                href={contractURL}
                className='blueBackground'
                target='_blank'
                onClick={onViewContractClicked}
              />
            </div>
          </div>
        </TitleCard>
        <CapitalHelpMessage />
      </div>
    </CenteredContainerPage>
  )
}

export default CapitalApplicationApprovedPage
