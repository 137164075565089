import React from 'react'
import PropTypes from 'prop-types'

import store from 'redux/store'
import { submitForm } from 'redux/modules/forms'
import { PopupComponent } from 'libs/modals'
import { Tabs, TabPanel } from 'libs/tabs'
import { CustomerForm, FormName as CustomerFormName } from 'containers/store/components/Customers'
import FlatButton from 'components/buttons/FlatButton'

import SelectCustomerList from './List'
import classes from './Popup.module.scss'

class SelectCustomerPopup extends PopupComponent {
  state = {
    tabIndex: 0,
  }

  getContent() {
    return (
      <div className={classes.root}>
        <Tabs tabIndex={this.state.tabIndex} tabChanged={(tabIndex) => this.setState({ tabIndex })}>
          <TabPanel title='Select a customer'>
            <SelectCustomerList
              onSelected={this.props.onDone}
              onCreateCustomerClick={() => this.setState({ tabIndex: 1 })}
            />
          </TabPanel>
          <TabPanel title='New customer'>
            {this.state.tabIndex === 1 ? (
              <>
                <CustomerForm
                  onSaved={(data) => {
                    this.setState({ tabIndex: 0 })
                    this.props.onDone(data)
                  }}
                />
                <div className={classes.footer}>
                  <FlatButton
                    type='button'
                    label='Add a Customer'
                    className='blueBackground'
                    onClick={() => store.dispatch(submitForm(CustomerFormName))}
                  />
                </div>
              </>
            ) : null}
          </TabPanel>
        </Tabs>
      </div>
    )
  }
}

SelectCustomerPopup.propTypes = {
  onDone: PropTypes.func,
}

export default SelectCustomerPopup
