/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'

import { submitForm } from 'redux/modules/forms'
import { FirebaseHandler } from 'libs/firebase'
import * as forms from 'libs/forms'
import localisation from 'libs/localisation/localisation'
import FlatButton from 'components/buttons/FlatButton'
import { makeTestID } from 'libs/utils'

import classes from './Form.module.scss'

const FormName = 'add_product_form'

const FormFieldNames = {
  Name: 'name',
  Description: 'description',
  Price: 'defaultPrice',
  SalesTax: 'salesTaxUUIDs',
}

const processFormData = (data) => {
  const price = parseFloat(data.get(FormFieldNames.Price)).toFixed(2)

  // salesTaxUUIDs must be a list
  if (data.get(FormFieldNames.SalesTax)) {
    data = data.set(FormFieldNames.SalesTax, List([data.get(FormFieldNames.SalesTax)]))
  }

  // default variants structure required on the backend side
  data = data.set('variants', [{ choiceValues: [], enabled: true }])

  return data.set(FormFieldNames.Price, Number.isNaN(price) ? 0 : price)
}

const AddProductForm = ({
  onSuccess,
  dispatch,
  localisation,
  salesTaxes,
  trackingEvents,
  testID,
}) => {
  const [salesTaxOptions, salesTaxPlaceholder] = useMemo(() => {
    let placeholder
    const options = (salesTaxes || Map())
      .toArray()
      .reverse()
      .map((salesTax) => {
        const label = `${salesTax.get('taxName')} (${(
          salesTax.get('taxPercentage') * 100
        ).toFixed()}%)`

        if (salesTax.get('isEnabledByDefault')) {
          placeholder = `Default: ${label}`
        }

        return {
          label,
          value: salesTax.get('uuid'),
        }
      })

    return [options, placeholder]
  }, [salesTaxes])

  const baseTestID = 'addProductForm'

  return (
    <forms.Form
      action='/product/'
      name={FormName}
      dataProcessor={processFormData}
      onSuccess={onSuccess}
      scrollToTop={false}
      showLoader
      loaderClass='spinner-container'
      showSuccess
      showError
    >
      <forms.TextField
        name={FormFieldNames.Name}
        label='Name'
        validators={[new forms.RequiredValidator('You must provide a name')]}
        isFullWidth
        testID={makeTestID(testID, baseTestID, FormFieldNames.Name)}
      />
      <forms.TextField
        name={FormFieldNames.Description}
        label='Description'
        placeholder='Description'
        isFullWidth
        testID={makeTestID(testID, baseTestID, FormFieldNames.Description)}
      />
      <div className='clearfix'>
        <forms.CurrencyTextField
          name={FormFieldNames.Price}
          label='Price'
          localisation={localisation}
          validators={[
            new forms.RequiredValidator('You must provide a price'),
            new forms.NumberValidator(true),
          ]}
          isHalfWidth
          testID={makeTestID(testID, baseTestID, FormFieldNames.Price)}
        />
        <forms.SelectField
          name={FormFieldNames.SalesTax}
          label='Tax'
          options={salesTaxOptions}
          placeholder={salesTaxPlaceholder}
          isHalfWidth
          testID={makeTestID(testID, baseTestID, FormFieldNames.SalesTax)}
        />
      </div>
      <div className={classes.submitWrapper}>
        <FlatButton
          label='Add a Product'
          type='button'
          className='blueBackground'
          onClick={() => {
            trackingEvents?.trackAddProductSaveClick?.()
            dispatch(submitForm(FormName))
          }}
          testID={makeTestID(testID, baseTestID, 'addProductButton')}
        />
      </div>
    </forms.Form>
  )
}

AddProductForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  localisation: PropTypes.object.isRequired,
  salesTaxes: PropTypes.object,
  trackingEvents: PropTypes.object,
  testID: PropTypes.string,
}

const ReduxConnectedAddProductForm = connect((state) => ({
  localisation: localisation(state),
}))(AddProductForm)

export default FirebaseHandler.connect(
  ReduxConnectedAddProductForm,
  Map({
    salesTaxes: {
      path: 'readOnly/store/salesTaxes',
    },
    products: {
      path: 'readOnly/store/products',
    },
  })
)
