import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Immutable from 'immutable'

import { maskEmailAddress, makeTestID } from 'libs/utils'
import * as forms from 'libs/forms'
import FormCard from 'components/cards/FormCard'

export const SET_STAFF_PIN_FORM_NAME = 'setStaffPin'
const api = '/staffShifts/setStaffPIN'

export const baseTestID = makeTestID('staff', 'editStaffPin')
export function UnconnectedEditStaffPin({ shownRow, maskedEmail, newStaffPIN, onSuccess }) {
  const onSubmit = React.useCallback(
    (value) => {
      onSuccess(value.get('staffPIN'))
    },
    [onSuccess]
  )

  return (
    <forms.Form
      action={shownRow ? api : undefined}
      name={SET_STAFF_PIN_FORM_NAME}
      initialData={shownRow}
      initialDataProcessor={(data) =>
        Immutable.fromJS({
          userUUID: data.size > 0 ? data.get('info').get('userUUID') : '',
        })
      }
      onSuccess={onSuccess}
      onSubmit={!shownRow ? onSubmit : undefined}
    >
      <FormCard header='Enter new PIN'>
        <forms.PasswordField
          name='staffPIN'
          label='Enter PIN'
          placeholder='****'
          keyboardType='numeric'
          validators={[new forms.LengthValidator(4), new forms.NumberValidator()]}
          testID={makeTestID(baseTestID, 'staffPINfield')}
        />
        <forms.PasswordField
          name='staffPINConfirm'
          label='Confirm PIN'
          placeholder='****'
          keyboardType='numeric'
          validators={[
            new forms.LengthValidator(4),
            new forms.NumberValidator(),
            new forms.ConfirmPasswordValidator(newStaffPIN, 'PINs do not match'),
          ]}
          testID={makeTestID(baseTestID, 'confirmPINfield')}
        />
      </FormCard>
      {shownRow ? (
        <FormCard header='Password required to authorise'>
          <forms.PasswordField
            name='password'
            label={`Please enter the password for ${maskEmailAddress(maskedEmail)}`}
            validators={[new forms.RequiredValidator('You must enter your password')]}
          />
        </FormCard>
      ) : undefined}
    </forms.Form>
  )
}

UnconnectedEditStaffPin.propTypes = {
  newStaffPIN: PropTypes.string,
  shownRow: PropTypes.object,
  onSuccess: PropTypes.func,
  maskedEmail: PropTypes.string,
}

export const EditStaffPin = connect((state) => ({
  newStaffPIN: state.forms.getIn([SET_STAFF_PIN_FORM_NAME, 'data', 'staffPIN']),
}))(UnconnectedEditStaffPin)
