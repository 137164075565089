/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useMemo } from 'react'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Alert from 'components/notifications/Alert'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import { cancelUserUpdateRequest } from 'redux/modules/profile'

function CancelUserDetailsUpdatePage({ routeParams, onCancelComplete, onCancelUserUpdateRequest }) {
  const uuid = useMemo(() => routeParams?.uuid, [routeParams?.uuid])

  const errorMessage = useMemo(
    () => (uuid ? null : 'Invalid user update identifier - please try again'),
    [uuid]
  )

  useEffect(() => {
    if (uuid) {
      onCancelUserUpdateRequest(uuid, onCancelComplete)
    }
  }, [onCancelComplete, onCancelUserUpdateRequest, uuid])

  return (
    <MaxWidthBlock>
      {errorMessage && <Alert messageType='danger'>{errorMessage}</Alert>}
    </MaxWidthBlock>
  )
}

CancelUserDetailsUpdatePage.propTypes = {
  routeParams: PropTypes.object,
  onCancelComplete: PropTypes.func,
  onCancelUserUpdateRequest: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => ({
  onCancelComplete: () => dispatch(push('/profile')),
  onCancelUserUpdateRequest: (uuid, onSuccess) =>
    dispatch(cancelUserUpdateRequest(uuid, onSuccess)),
})

export default connect(undefined, mapDispatchToProps)(CancelUserDetailsUpdatePage)
