import React from 'react'
import PropTypes from 'prop-types'

import successImage from 'assets/images/success-green-icon@2x.png'

import PopupResult from './PopupResult'

function PopupSuccessView({ title, subtitle, buttonLabel, onDismiss }) {
  return (
    <PopupResult
      image={successImage}
      buttonClassname='blueBackground'
      title={title}
      subtitle={subtitle}
      buttonLabel={buttonLabel}
      onDismiss={onDismiss}
    />
  )
}

PopupSuccessView.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  buttonLabel: PropTypes.string,
  onDismiss: PropTypes.func,
}

export default PopupSuccessView
