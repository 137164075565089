import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import { callJSONApi } from 'libs/api'
import LoadingView from 'components/loaders/LoadingView'
import FlatButton from 'components/buttons/FlatButton'
import Alert from 'components/notifications/Alert'
import { selectBankAccountUpdateRequest } from 'redux/modules/onboarding/bankDetails/selectors'
import { connect } from 'react-redux'
import { Icon } from '@yoco/design-system-icons/dist/react'
import './styles.scss'

function ConfirmationRequiredAlert({ bankAccountUpdateRequest, onCancelComplete }) {
  const [isLoading, setIsLoading] = useState(false)

  const [messageType, setMessageType] = useState('info')
  const [setMessage] = useState(undefined)
  const [hasResentConfirmation, setHasResentConfirmation] = useState(false)

  const onResendClick = useCallback(() => {
    setIsLoading(true)

    callJSONApi(
      `/business/bank-account/updates/${bankAccountUpdateRequest.uuid}/resend`,
      'POST',
      {},
      (response) => {
        setIsLoading(false)
        setMessage(response.message)
        if (response.status === 200) {
          setMessageType('info')
        } else {
          setMessageType('danger')
        }
        setHasResentConfirmation(true)
      },
      (errorMessage) => {
        setMessageType('danger')
        setMessage(errorMessage)
        setIsLoading(false)
      }
    )
  }, [bankAccountUpdateRequest, setIsLoading, setMessage, setMessageType])

  const onCancelClick = useCallback(() => {
    setIsLoading(true)

    callJSONApi(
      `/business/bank-account/updates/${bankAccountUpdateRequest.uuid}/cancel`,
      'POST',
      {},
      (response) => {
        setIsLoading(false)

        if (response.status === 200) {
          onCancelComplete()
        } else {
          setMessageType('danger')
          setMessage(response.message)
        }
      },
      (errorMessage) => {
        setIsLoading(false)
        setMessage(errorMessage)
        setMessageType('danger')
      }
    )
  }, [onCancelComplete, bankAccountUpdateRequest, setIsLoading, setMessage, setMessageType])

  const confirmationSentTo = bankAccountUpdateRequest?.confirmationDetails?.confirmationSentTo
  const confirmationType =
    confirmationSentTo && confirmationSentTo.indexOf('@') !== -1 ? 'email' : 'sms'

  return (
    <Alert messageType={messageType} closable={false}>
      {confirmationSentTo ? (
        <>
          <b>Please check your {confirmationType}</b>
          <br />
          <br />
          <>
            A confirmation has been sent to {confirmationSentTo}. Please click the link in the{' '}
            {confirmationType} to approve the following change:
          </>
        </>
      ) : (
        <b>Please check your email address to confirm your new bank details.</b>
      )}
      <br />
      <br />
      <b>Bank:</b> {bankAccountUpdateRequest?.updateInfo?.bank}
      <br />
      <b>Account Number:</b> {bankAccountUpdateRequest?.updateInfo?.accountNumber}
      <br />
      <br />
      <span>Until then you’ll still receive funds in the account below.</span>
      <br />
      <br />
      {isLoading ? (
        <LoadingView />
      ) : (
        <>
          <div className='link'>
            {hasResentConfirmation ? (
              <>
                <Icon name='tick' size={24} />
                <span>Sent!</span>
              </>
            ) : (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <a onClick={onResendClick}>
                <span>Resend confirmation</span>
                <Icon name='chevron-right' size={24} />
              </a>
            )}
          </div>
          <br />
          <FlatButton className='secondary' onClick={onCancelClick}>
            Cancel change
          </FlatButton>
        </>
      )}
    </Alert>
  )
}

ConfirmationRequiredAlert.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  bankAccountUpdateRequest: PropTypes.object.isRequired,
  onCancelComplete: PropTypes.func,
}

ConfirmationRequiredAlert.defaultProps = {
  onCancelComplete: () => window.location.reload(),
}

const mapStateToProps = (state) => ({
  bankAccountUpdateRequest: selectBankAccountUpdateRequest(state).toJS(),
})

export default connect(mapStateToProps)(ConfirmationRequiredAlert)
