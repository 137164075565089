import ROLES from 'libs/roles'

import PartnerPage from './partnerPage'

export default function getRoutes() {
  return {
    path: 'partners',
    text: 'Partners',
    icon: 'connection',
    component: PartnerPage,
    requiresOneRole: [ROLES.VIEW_PARTNERS],
    hideInMenu: false,
  }
}
