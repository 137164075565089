import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { makeTestID } from 'libs/utils'

import FlatButton from './FlatButton'
import classes from './buttons.module.scss'

export default class ActionButtonBar extends Component {
  getDeleteButton() {
    if (this.props.showDelete) {
      return (
        <div className={classes.deleteButtonContainer}>
          <FlatButton
            label={this.props.deleteLabel}
            type='button'
            testID={makeTestID('actionButtonBar', 'delete')}
            className={classes.deleteButton}
            onClick={this.props.deletePressed}
          />
        </div>
      )
    }

    return undefined
  }

  render() {
    if (!this.props.showing) {
      return <span />
    }

    return (
      <div className={classes.actionBar}>
        {this.getDeleteButton()}
        <div className={classes.rightButtons}>
          {this.props.showCancel && (
            <FlatButton
              label={this.props.cancelLabel}
              type='reset'
              testID={makeTestID('actionButtonBar', 'cancel')}
              className={classes.cancelButton}
              onClick={this.props.cancelPressed}
            />
          )}
          {this.props.showSave && (
            <FlatButton
              label={this.props.saveLabel}
              type='submit'
              testID={makeTestID('actionButtonBar', 'save')}
              className='blueBackground'
              onClick={this.props.savePressed}
            />
          )}
        </div>
      </div>
    )
  }
}

ActionButtonBar.propTypes = {
  showDelete: PropTypes.bool,
  deletePressed: PropTypes.func,
  deleteLabel: PropTypes.string,
  showCancel: PropTypes.bool,
  cancelPressed: PropTypes.func,
  cancelLabel: PropTypes.string,
  showSave: PropTypes.bool,
  savePressed: PropTypes.func,
  saveLabel: PropTypes.string,
  showing: PropTypes.bool,
}

ActionButtonBar.defaultProps = {
  showDelete: false,
  deleteLabel: 'Delete',
  showCancel: true,
  cancelLabel: 'Cancel',
  showSave: true,
  saveLabel: 'Save',
  showing: true,
}
