import React from 'react'
import PropTypes from 'prop-types'

import FlatButton from 'components/buttons/FlatButton'
import PopupComponent from 'libs/modals/popups'
import classes from 'libs/modals/popups.module.scss'
import LoadingView from 'components/loaders/LoadingView'
import { makeTestID } from 'libs/utils'
import { If } from 'libs/formats'

const baseTestID = makeTestID('productsPage', 'delete')

export default class ConfirmPopup extends PopupComponent {
  constructor(props) {
    super(props)

    this.state = {
      showLoader: false,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    super.UNSAFE_componentWillReceiveProps(nextProps)

    if (nextProps.showing !== this.props.showing) {
      this.setState({
        showLoader: false,
      })
    }
  }

  confirm() {
    this.setState({
      showLoader: true,
    })

    if (this.props.onConfirm) {
      this.props.onConfirm()
    }
  }

  getContent() {
    const label = this.props.label ? this.props.label : this.props.title

    return (
      <div>
        <LoadingView test={this.state.showLoader} />
        <div className={classes.popupTitle}>
          <If condition={this.props.popupImage}>
            <div className={classes.popupImageContainer}>
              <img src={this.props.popupImage} alt='popup' />
            </div>
          </If>
          {this.props.title}
        </div>
        <div className={classes.popupSubtitle}>{this.props.subtitle}</div>
        <div className={classes.actions}>
          {this.props.hideCancel ? null : (
            <FlatButton
              label='Cancel'
              type='reset'
              onClick={this.props.onCancel}
              testID={makeTestID('productsPage', 'delete', 'cancel')}
            />
          )}
          <span />
          <FlatButton
            label={label}
            className={`${this.props.actionType || 'blueBackground'}`}
            onClick={() => this.confirm()}
            testID={makeTestID(baseTestID, 'remove')}
          />
        </div>
      </div>
    )
  }
}

ConfirmPopup.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  actionType: PropTypes.string,
  iconType: PropTypes.string,
  hideCancel: PropTypes.bool,
}
