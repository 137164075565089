import { createSelector } from 'reselect'

const BUSINESS_TYPES = {
  SOLE_PROPRIETOR: 'sole_proprietor',
  REGISTERED_BUSINESS: 'company',
  CLOSE_CORPORATION: 'cc',
  TRUST: 'trust',
  NOT_REGISTERED: 'not_registered',
  NPO: 'npo',
  NPC: 'npc',
}

const sessionNode = (state) => state.session

export const selectBusiness = createSelector(sessionNode, (session) => session.get('business'))

export const selectIsDemoAccount = createSelector(selectBusiness, (business) =>
  business.get('isDemoAccount')
)

export const selectBusinessName = createSelector(selectBusiness, (business) => {
  return business.get('displayName')
})

export const selectBusinessUUID = createSelector(selectBusiness, (business) => business.get('uuid'))

export const selectIsSettlementActivated = createSelector(selectBusiness, (business) => {
  return business.get('settlementActivated')
})

export const selectHasCompletedOnboarding = createSelector(selectBusiness, (business) => {
  return business.get('settlementActivated')
})

export const selectBusinessType = createSelector(selectBusiness, (business) => {
  return business.getIn(['info', 'businessType'])
})

export const selectIsRegisteredBusiness = createSelector(selectBusinessType, (businessType) => {
  const registeredBusinessTypes = [
    BUSINESS_TYPES.REGISTERED_BUSINESS,
    BUSINESS_TYPES.CLOSE_CORPORATION,
    BUSINESS_TYPES.NPC,
  ]

  return registeredBusinessTypes.includes(businessType)
})

export const selectCompanyRegistrationNumber = createSelector(selectBusiness, (business) => {
  return business.getIn(['info', 'registrationNumber'])
})

export const selectUser = createSelector(sessionNode, (sessionData) => {
  return sessionData.get('user')
})

export const selectUserUUID = createSelector(selectUser, (user) => user.get('uuid'))

export const selectUserEmail = createSelector(selectUser, (user) => user.get('email'))

export const selectIsBusinessOwner = createSelector(sessionNode, (session) => {
  const sessionUserUUID = session.getIn(['user', 'uuid'])
  const ownerUUID = session.getIn(['business', 'ownerUUID'])

  return sessionUserUUID && sessionUserUUID === ownerUUID
})
