import paymentPage from 'assets/images/online/paymentPage.png'

const infoCards = [
  {
    img: paymentPage,
    title: 'A simple Payment Page',
    blurb: `Get paid online by your customers through one link. Share it anywhere to accept online card payments from anyone.`,
    linkAText: 'Learn more',
    linkAHref: '/online/settings',
    popUp: false,
    tracking: 'payment-page',
  },
]

export default infoCards
