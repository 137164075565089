/* global zE */

import { isFlagship } from './utils'

const isAnalyticsAvailable = () => window.analytics

export function pageViewed(path) {
  if (isAnalyticsAvailable()) {
    let search = 'portal'
    if (path !== '/') {
      search = path
    }
    const timer = setInterval(() => {
      if (typeof zE !== 'undefined' && typeof (zE.setHelpCenterSuggestions === 'function')) {
        zE.setHelpCenterSuggestions({ search })
        clearTimeout(timer)
      }
    }, 2000)
    window.analytics.ready(() => {
      window.analytics.page(path)
    })
  }
}

export function userLoggedIn(session) {
  const { user, business } = session
  if (isAnalyticsAvailable()) {
    window.analytics.ready(() => {
      // eslint-disable-next-line no-undef
      analytics.identify(user.uuid, { businessUUID: user.businessUUID })
    })
    window.analytics.track('[PORTAL] User Logged In', {
      email: user.email,
      fullName: user.fullName,
      userUUID: user.uuid,
      businessUUID: user.businessUUID,
      mobileNumber: user.mobileNumber,
      userType: user.userType,
      businessName: business.name,
    })
  }
}

export function userLoggedOut() {
  if (isAnalyticsAvailable()) {
    window.analytics.ready(() => {
      window.analytics.reset()
    })
  }
}

export function track(event, data) {
  if (isAnalyticsAvailable()) {
    window.analytics.ready(() => {
      window.analytics.track(`[PORTAL] ${event}`, {
        ...data,
        ...(isFlagship ? { channel: 'flagship' } : null),
      })
    })
  }
}
