import React from 'react'
import PropTypes from 'prop-types'

import { Amount, FormattedNumber } from 'libs/formats'

function FeeDescription({ payment }) {
  if (payment.get('feeDescriptionAmount')) {
    if (payment.get('feeIsPercent')) {
      return (
        <span className='feeDescription faded'>
          @ <FormattedNumber format='0.00' number={payment.get('feeDescriptionAmount')} />%
        </span>
      )
    }

    return (
      <span className='feeDescription faded'>
        @ <Amount amount={payment.get('feeDescriptionAmount')} />
      </span>
    )
  }

  return <span />
}

FeeDescription.propTypes = {
  payment: PropTypes.object,
}

export default FeeDescription
