/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-classes-per-file */
import React, { Component } from 'react'
import firebase from 'firebase'
import Immutable, { Map, List } from 'immutable'
import { getENV } from 'libs/utils'
import * as Sentry from '@sentry/react'

import { authHandler } from './authHandler'

export const features = {
  path: 'readOnly/store/features',
  globalPath: 'features',
}

export const roleDescriptions = {
  path: 'readOnly/store/roleDescriptions',
  globalPath: 'roleDescriptions',
}

const makeFirebaseConfig = () => ({
  apiKey: getENV('FIREBASE_API_KEY'),
  authDomain: getENV('FIREBASE_AUTH_DOMAIN'),
  databaseURL: getENV('FIREBASE_DATABASE_URL'),
  // storageBucket: getENV('FIREBASE_STORAGE_BUCKET'),
  // messagingSenderId: getENV('FIREBASE_MESSAGING_SENDER_ID'),
})

const FIREBASE_ROOT_NODE = getENV('FIREBASE_ROOT_NODE')

class FirebaseHandler {
  constructor() {
    const firebaseConfig = makeFirebaseConfig()
    if (process.env.FIREBASE_API_KEY) {
      firebaseConfig.apiKey = process.env.FIREBASE_API_KEY
    }
    firebase.initializeApp(firebaseConfig)
    this.storedRefs = Map()
    this.connectedComponents = []
  }

  init() {
    authHandler.authenticateFirebase(true)
    Sentry.addBreadcrumb({
      category: 'firebase',
      message: 'init',
      level: 'info',
    })
  }

  token(firebaseToken, authParams) {
    if (!this.isConnected()) {
      Sentry.addBreadcrumb({
        category: 'firebase',
        message: 'Connecting with token',
        level: 'info',
        data: {
          businessUUID: authParams.businessUUID,
          userUUID: authParams.userUUID,
          version: authParams.version,
          created: authParams.created,
        },
      })
      const rootPath = FIREBASE_ROOT_NODE ? `${FIREBASE_ROOT_NODE}/` : ''

      if (FIREBASE_ROOT_NODE) {
        console.log('Using Firebase root node:', FIREBASE_ROOT_NODE)
      }

      this.basePath = `${rootPath}businessData/${authParams.businessUUID}/`
      this.globalPath = `${rootPath}/global`
      this.authParams = Immutable.fromJS(authParams)

      firebase
        .auth()
        .signInWithCustomToken(firebaseToken)
        .then(() => {
          this.onAuthenticationSuccess()
        })
        .catch((error) => {
          console.error('Error authenticating with firebase', error)
          Sentry.captureException(error, error.code)
          // Try again but fetch a new token
          authHandler.authenticateFirebase()
        })
    }
  }

  isConnected() {
    return this.firebaseRef !== undefined
  }

  getPropPathForNode(node) {
    let propPath = node[1].path

    if (propPath) {
      propPath = firebaseInstance.setAuthParamsInPropPath(propPath)
    }

    return propPath
  }

  writeToPath(path, data) {
    if (!firebaseInstance.firebaseRef) {
      console.error('WE DO NOT HAVE FIREBASE YET')
      Sentry.addBreadcrumb({
        category: 'firebase',
        message: 'We do not have a Firebase instance yet',
        level: 'info',
      })

      // Try again after 1 second
      setTimeout(() => {
        this.writeToPath(path, data)
      }, 1000)
    } else {
      firebaseInstance.firebaseRef
        .child(path)
        .update(data)
        .then((result) => {
          console.log('Succeeded Update', result)
        })
        .catch((error) => {
          Sentry.captureException(error)
          console.log('Failed Update', error)
        })
    }
  }

  // You can use this to temporarily tell all connected components
  // of an update that is about to be transmitted,
  // Note: this is not stored in cache
  // and should only be used when you expect the update almost immediately
  setLocalFirebaseValue(propPath, updatedValue) {
    const snapshot = {
      val: () => updatedValue,
      key: () => undefined,
    }
    this.storedRefs.getIn([propPath, 'value'], List()).forEach((componentMap) => {
      componentMap
        .get('component')
        .receivedFirebaseUpdateForProp(true, true, componentMap.get('prop'), snapshot)
    })
  }

  getGlobalCached(globalPath) {
    const globalStoredValue = globalPath
      ? window?.yocoStorage?.getItem(`firebase-global-${globalPath}`)
      : undefined
    return globalStoredValue ? JSON.parse(globalStoredValue) : {}
  }

  getCached(path) {
    const storedValue = window?.yocoStorage?.getItem(`firebase-${path}`)
    return storedValue ? JSON.parse(storedValue) : {}
  }

  getNodeMergedData(storedRefNode) {
    if (!storedRefNode.get('globalLoadedData')) {
      return storedRefNode.get('loadedData')
    }

    return Object.assign(
      storedRefNode.get('globalLoadedData', {}),
      storedRefNode.get('loadedData', {})
    )
  }

  getIsNodeConnected(storedRefNode) {
    return (
      !!storedRefNode.get('connected') &&
      (storedRefNode.get('globalRef') === undefined || !!storedRefNode.get('globalConnectedData'))
    )
  }

  registerForRef(prop, propPath, globalPath, event, connectingComponent) {
    if (!this.isConnected()) {
      return { connected: false }
    }
    let existingInstance = this.storedRefs.get(
      propPath,
      Map({
        count: 0,
        firebaseRef: firebaseInstance.firebaseRef.child(propPath),
        globalRef: globalPath ? firebaseInstance.globalRef.child(globalPath) : undefined,
      })
    )
    existingInstance = existingInstance.set('count', existingInstance.get('count') + 1)
    const existingConnectedComponents = existingInstance.get(event)
    // Save a list of components that care about this ref for this event, with the prop name
    existingInstance = existingInstance.set(
      event,
      (existingConnectedComponents || List()).push(
        Map({
          component: connectingComponent,
          prop,
        })
      )
    )
    this.storedRefs = this.storedRefs.set(propPath, existingInstance)

    if (!existingConnectedComponents) {
      // There are no existing connections for this type of event, we must initialize one
      if (event !== 'child_changed') {
        const storedValue = this.getCached(propPath)
        const jsonGlobalStoredValue = this.getGlobalCached(globalPath)
        if (
          (!!storedValue && Object.keys(storedValue).length > 0) ||
          (!!jsonGlobalStoredValue && Object.keys(jsonGlobalStoredValue).length > 0)
        ) {
          this.storedRefs = this.storedRefs.setIn([propPath, 'loadedData'], storedValue)
          this.storedRefs = this.storedRefs.setIn(
            [propPath, 'globalLoadedData'],
            jsonGlobalStoredValue
          )
        }
      }

      // We have not registered this type of event yet
      existingInstance.get('firebaseRef').on(
        event,
        (snapshot) => {
          if (event !== 'child_changed') {
            firebaseInstance.storedRefs = firebaseInstance.storedRefs.setIn(
              [propPath, 'connected'],
              true
            )
            // This will be used to initialize new components that register (after the first),
            // while they wait new changes
            firebaseInstance.storedRefs = firebaseInstance.storedRefs.setIn(
              [propPath, 'loadedData'],
              snapshot.val()
            )
            // Save the data for page refresh
            window?.yocoStorage?.setItem(`firebase-${propPath}`, JSON.stringify(snapshot.val()))
          }

          const mergedData = this.getNodeMergedData(firebaseInstance.storedRefs.get(propPath))
          firebaseInstance.storedRefs.getIn([propPath, event], List()).forEach((componentMap) => {
            componentMap
              .get('component')
              .receivedFirebaseUpdateForProp(
                true,
                false,
                componentMap.get('prop'),
                snapshot,
                mergedData
              )
          })
        },
        (error) => {
          if (event !== 'child_changed') {
            firebaseInstance.storedRefs.setIn([propPath, 'connected'], true)
          }

          firebaseInstance.storedRefs.getIn([propPath, event], List()).forEach((componentMap) => {
            componentMap
              .get('component')
              .receivedFirebaseUpdateForProp(
                true,
                false,
                componentMap.get('prop'),
                undefined,
                undefined,
                error
              )
          })
        }
      )
      // Register global snapshot if needed
      if (existingInstance.get('globalRef')) {
        existingInstance.get('globalRef').on(
          event,
          (snapshot) => {
            if (event !== 'child_changed') {
              firebaseInstance.storedRefs = firebaseInstance.storedRefs.setIn(
                [propPath, 'globalConnected'],
                true
              )
              // This will be used to initialize new components that register (after the first)
              // while they wait new changes
              firebaseInstance.storedRefs = firebaseInstance.storedRefs.setIn(
                [propPath, 'globalLoadedData'],
                snapshot.val()
              )
              // Save the data for page refresh
              window?.yocoStorage?.setItem(
                `firebase-global-${globalPath}`,
                JSON.stringify(snapshot.val())
              )
            }

            const mergedData = this.getNodeMergedData(firebaseInstance.storedRefs.get(propPath))
            firebaseInstance.storedRefs.getIn([propPath, event], List()).forEach((componentMap) => {
              componentMap
                .get('component')
                .receivedFirebaseUpdateForProp(
                  false,
                  true,
                  componentMap.get('prop'),
                  snapshot,
                  mergedData
                )
            })
          },
          (error) => {
            if (event !== 'child_changed') {
              firebaseInstance.storedRefs.setIn([propPath, 'globalConnected'], true)
            }

            firebaseInstance.storedRefs.getIn([propPath, event], List()).forEach((componentMap) => {
              componentMap
                .get('component')
                .receivedFirebaseUpdateForProp(
                  false,
                  true,
                  componentMap.get('prop'),
                  undefined,
                  undefined,
                  error
                )
            })
          }
        )
      }
    }

    if (event !== 'child_changed') {
      const node = firebaseInstance.storedRefs.get(propPath)

      return {
        connected: this.getIsNodeConnected(node),
        initialData: this.getNodeMergedData(node),
      }
    }
    return {
      connected: false,
    }
  }

  disconnectRef(propPath, event, connectedComponent) {
    let existingInstance = this.storedRefs.get(propPath)
    if (!existingInstance) {
      console.error(
        'We are trying to disconnect a ref that is not connected, how is this possible?'
      )
      return
    }
    const connectedComponents = existingInstance
      .get(event, List())
      .filter((componentMap) => componentMap.get('component') !== connectedComponent)
    existingInstance = existingInstance.set(event, connectedComponents)
    const count = existingInstance.get('count') - 1
    existingInstance = existingInstance.set('count', count)
    if (count === 0) {
      existingInstance.get('firebaseRef').off()
      this.storedRefs = this.storedRefs.remove(propPath)
    } else {
      this.storedRefs = this.storedRefs.set(propPath, existingInstance)
    }
  }

  disconnectFirebase() {
    try {
      firebase
        .auth()
        .signOut()
        .then(
          () => {
            this.firebaseRef = undefined
            this.globalRef = undefined
            this.storedRefs.forEach((storedRef) => {
              storedRef.get('firebaseRef').off()
            })
            this.firebaseConnectionStateUpdated()
            this.storedRefs = Map()
            this.globalRef = Map()
          },
          (error) => {
            console.error('Error disconnecting firebase', error)
            this.firebaseRef = undefined
            this.globalRef = undefined
            this.firebaseConnectionStateUpdated()
          }
        )
    } catch (error) {
      console.error('Firebase > disconnectFirebase > Error: ', error)
    }
  }

  firebaseConnectionStateUpdated() {
    this.connectedComponents.forEach((component) => {
      component.firebaseConnectionStateUpdated()
    })
  }

  componentConnected(component) {
    this.connectedComponents.push(component)
  }

  componentDisconnected(component) {
    this.connectedComponents.splice(this.connectedComponents.indexOf(component))
  }

  onAuthenticationSuccess() {
    Sentry.addBreadcrumb({
      category: 'firebase',
      message: 'Successfully authenticated',
      level: 'info',
    })
    if (authHandler.isLoggedIn()) {
      authHandler.resetFailedCount()
      this.firebaseRef = firebase.database().ref(this.basePath)
      this.globalRef = firebase.database().ref(this.globalPath)
      this.firebaseConnectionStateUpdated()
    } else {
      this.disconnectFirebase()
      this.firebaseConnectionStateUpdated()
    }
  }

  setAuthParamsInPropPath(path) {
    let replacedPath = path
    this.authParams.entrySeq().forEach(([key, value]) => {
      replacedPath = replacedPath.replace(new RegExp(`{${key}}`, 'g'), value)
    })

    return replacedPath
  }

  /**
   * Use the connect function to connect your components to firebase.
   * See example below. (Think redux connect)
   * const FirebaseTest = FirebaseHandler.connect(UnconnectedFirebaseTest, Map({
   *   // This is what the data will be returned as in your components props
   *   users: {
   *     // Path in firebase from the business
   *     // You can also use authParams like readOnly/store/users/{userUUID}
   *     path: 'readOnly/store/users',
   *     changesOnly: false, // Defaults to false
   *     onNoValueOrError: func // What to do if we return no value or get an error (optional)
   *   }
   * }));
   */
  connect(FirebaseConnectedComponent, listenNodes) {
    return class FirebaseComponent extends Component {
      constructor(props) {
        super(props)
        // The props we pass to the component
        this.connectedProps = {}
        // A list of firebase paths we have connected too, and their events
        this.connectedRefs = List()
        // A list of firebase paths we are interested in the whole value for (ie not changesOnly).
        this.waitForResponses = List()
        // A list of firebase paths we are interested in the whole value we received a response for
        this.receivedResponses = List()
        this.componentIsMounted = false

        firebaseInstance.componentConnected(this)

        const firebaseConnected = firebaseInstance.isConnected()
        this.state = {
          firebaseConnected,
          receivedAllEvents: false,
        }
        if (firebaseConnected) {
          this.state = this.listenOnNodes()
        }
      }

      componentDidMount() {
        this.componentIsMounted = true
      }

      componentWillUnmount() {
        this.componentIsMounted = false
        if (this.onFirebaseStateUpdatedListener) {
          this.onFirebaseStateUpdatedListener = undefined
        }
        this.disconnectComponent()
        firebaseInstance.componentDisconnected(this)
      }

      disconnectComponent() {
        this.connectedRefs.forEach((connected) => {
          firebaseInstance.disconnectRef(connected.get('propPath'), connected.get('event'), this)
        })

        this.connectedProps = {}
        this.connectedRefs = List()
      }

      receivedFirebaseUpdateForProp(wasLocal, wasGlobal, propName, snapshot, mergedData, error) {
        const node = listenNodes.get(propName)
        const { onNoValueOrError } = node
        const { changesOnly } = node

        // We got a response for an event, should add it and calculate if we reveived all events.
        // We do this regardless of error (because we have waited for a response).
        // We handle global and local independantly, and send both when inflating from cache
        const globalReceived = `${propName}-global`
        const localReceived = propName
        if (!changesOnly) {
          if (wasLocal && !this.receivedResponses.contains(localReceived)) {
            this.receivedResponses = this.receivedResponses.push(localReceived)
          }
          if (wasGlobal && !this.receivedResponses.contains(globalReceived)) {
            this.receivedResponses = this.receivedResponses.push(globalReceived)
          }
        }

        if (error) {
          Sentry.captureException(error)

          if (onNoValueOrError) {
            onNoValueOrError()
          }
          this.setState({
            receivedAllEvents: this.waitForResponses.size === this.receivedResponses.size,
          })
        } else if (this.componentIsMounted) {
          if (mergedData === null && onNoValueOrError) {
            Sentry.captureEvent(`FirebaseFoundNoValue no value found for prop: ${propName}`)
            onNoValueOrError()
          }
          if (changesOnly) {
            // We only want the changes
            // so we should amend what we have in our connected props, not overwrite it
            const oldValue = this.connectedProps[propName] || Map()
            const newMap = {}
            newMap[snapshot.key] = mergedData
            this.connectedProps[propName] = oldValue.merge(Immutable.fromJS(newMap))
          } else {
            this.connectedProps[propName] = Immutable.fromJS(mergedData)
          }

          this.setState({
            connectedProps: this.connectedProps,
            receivedAllEvents: this.waitForResponses.size === this.receivedResponses.size,
          })
        } else {
          // This seem to happen if a component receiving this update
          // causes it to unmount the next registered component that will receive this update.
          // However this is then a good catch for that.
          console.warn(
            'Component was no longer mounted but wanted to update',
            FirebaseConnectedComponent
          )
        }
      }

      listenOnNodes() {
        const newState = this.state

        listenNodes.entrySeq().forEach((node) => {
          const prop = node[0]
          const propPath = firebaseInstance.getPropPathForNode(node)
          const { changesOnly } = node[1]
          const event = changesOnly ? 'child_changed' : 'value'

          // Save the path we are connecting too,
          // so we can disconnect regardless of auth params changing
          this.connectedRefs = this.connectedRefs.push(Map({ propPath, event }))
          if (!changesOnly) {
            this.waitForResponses = this.waitForResponses.push(propPath)
            if (node[1].globalPath) {
              this.waitForResponses = this.waitForResponses.push(`${propPath}-global`)
            }
          }
          // Add our component as a listener,
          // and get any data that has already been fetched for this list
          const registerResponse = firebaseInstance.registerForRef(
            prop,
            propPath,
            node[1].globalPath,
            event,
            this
          )
          if (registerResponse.initialData && !changesOnly) {
            this.connectedProps[prop] = Immutable.fromJS(registerResponse.initialData)
            if (!this.receivedResponses.contains(prop)) {
              this.receivedResponses = this.receivedResponses.push(prop)
            }
            if (node[1].globalPath) {
              if (!this.receivedResponses.contains(`${prop}-global`)) {
                this.receivedResponses = this.receivedResponses.push(`${prop}-global`)
              }
            }
            newState.connectedProps = this.connectedProps
          }
        })

        if (this.waitForResponses.size === this.receivedResponses.size) {
          newState.receivedAllEvents = true
        }

        // Instead of setting state in this method we return it,
        // because this can either get called from the constructor,
        // or at some other time after we finish connecting.
        // During the constructor we can't call this.setState();
        return newState
      }

      firebaseConnectionStateUpdated() {
        const firebaseConnected = firebaseInstance.isConnected()
        const componentConnected = this.state.firebaseConnected || false
        if (firebaseConnected !== componentConnected) {
          this.setState({ firebaseConnected })
          if (firebaseConnected) {
            // We should bind all the listenNodes
            const newState = this.listenOnNodes()
            this.setState(newState)
          } else if (componentConnected) {
            // We were connected, but firebase is no longer connected, remove all refs
            this.disconnectComponent()
          }
        }
      }

      render() {
        return (
          <FirebaseConnectedComponent
            {...this.props}
            {...this.connectedProps}
            firebaseConnected={this.state.firebaseConnected}
            receivedAllEvents={this.state.receivedAllEvents}
            firebaseRef={firebaseInstance.firebaseRef}
          />
        )
      }
    }
  }
}

const firebaseInstance = new FirebaseHandler()
export default firebaseInstance
