/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { EmptyTableView, Table } from 'libs/tables'
import * as utils from 'libs/utils'
import { checkIfMobileDevice } from 'libs/utils'
import styles from 'components/capital/capital.module.scss'

interface Props {
  totalAmountPayable: number
  deductionPlanUUID: string
  formatCurrency: (amount: number) => string
  getTotalAmountRepaid: (amount: number) => number
}

const CapitalRepaymentsTable: React.FunctionComponent<Props> = ({
  totalAmountPayable,
  deductionPlanUUID,
  formatCurrency,
  getTotalAmountRepaid,
}) => {
  const renderHeader = () => {
    if (!checkIfMobileDevice()) {
      return (
        <tr className='visible-header'>
          <th>
            <div className={styles.tableRowHeader}>DATE</div>
          </th>
          <th>
            <div className={`${styles.tableRowHeader} ${styles.tableRowPadded}`}>
              TOTAL CARD TRANSACTIONS
            </div>
          </th>
          <th>
            <div className={`${styles.tableRowHeader} ${styles.tableRowPadded}`}>
              REPAYMENT AMOUNT
            </div>
          </th>
          <th>
            <div className={`${styles.tableRowHeader} ${styles.tableRowPadded}`}>
              OUTSTANDING BALANCE
            </div>
          </th>
        </tr>
      )
    }
    return (
      <tr className='visible-header'>
        <th>
          <div className={styles.tableRowHeader}>DATE</div>
        </th>
        <th>
          <div className={`${styles.tableRowHeader} ${styles.tableRowPadded}`}>REPAYMENT</div>
        </th>
      </tr>
    )
  }

  const renderDeductionEntriesTable = () => {
    return (
      <Table
        api={`/lending/capitalDeductions/${deductionPlanUUID}/`}
        name='capitalDeductions'
        useOffset
        waitForFilterLoad={false}
        emptyTable={
          <EmptyTableView
            title='Your business currently has no repayments for this capital deduction plan'
            testID='EmptyTableView'
          />
        }
        pageSize={1000}
        getHeader={renderHeader}
        getRow={(immutableRow, rowIndex) => {
          let className = ''
          if (!(rowIndex % 2)) {
            className = ` row-background`
          }

          const paidOff = immutableRow.get('amount')
          const fee = immutableRow.getIn(['payout', 'feeAmount'])
          const payout = immutableRow.getIn(['payout', 'amount'])
          const totalAmountRepaid = getTotalAmountRepaid(rowIndex)

          if (!checkIfMobileDevice()) {
            return (
              <tr className={className} key={`row-${rowIndex}`}>
                <td>{utils.friendlyDate(immutableRow.get('created'))}</td>
                <td>
                  <div className={styles.tableRow}>
                    {paidOff + fee + payout ? formatCurrency(paidOff + fee + payout) : '-'}
                  </div>
                </td>
                <td>
                  <div className={styles.tableRow}>
                    {formatCurrency(immutableRow.get('amount'))}
                  </div>
                </td>
                <td>
                  <div className={styles.tableRow}>
                    {formatCurrency(totalAmountPayable - totalAmountRepaid)}
                  </div>
                </td>
              </tr>
            )
          }
          return (
            <tr className={className} key={`row-${rowIndex}`}>
              <td>{utils.friendlyDate(immutableRow.get('created'))}</td>
              <td>
                <div className={styles.tableRow}>{formatCurrency(immutableRow.get('amount'))}</div>
              </td>
            </tr>
          )
        }}
      />
    )
  }

  return (
    <div className={styles.table} aria-label='DeductionTable'>
      {renderDeductionEntriesTable()}
    </div>
  )
}

export default CapitalRepaymentsTable
