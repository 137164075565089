/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@yoco/design-system-icons/dist/react'
import { Map } from 'immutable'

import { List, ListItem } from 'libs/list'

import { SelectCustomerPopup } from './SelectCustomerPopup'
import classes from './CustomerSelect.module.scss'

function CustomerSelect({ value, onChange, placeholder, trackingEvents }) {
  const [isPopupVisible, setIsPopupVisible] = useState(false)

  return (
    <div className={classes.root}>
      <List style={{ cursor: 'pointer' }}>
        {value ? (
          <ListItem
            accessoryLeft='user'
            title={value.get('fullName')}
            subTitle={value.get('phone') || value.get('email')}
            accessoryRight={
              <div
                onClick={(e) => {
                  e.stopPropagation()
                  onChange(undefined)
                }}
              >
                <Icon name='close' size={24} />
              </div>
            }
            onClick={() => setIsPopupVisible(true)}
          />
        ) : (
          <ListItem
            accessoryLeft='add'
            title={placeholder}
            onClick={() => {
              trackingEvents?.trackAddCustomerClick?.()
              setIsPopupVisible(true)
            }}
          />
        )}
      </List>
      <SelectCustomerPopup
        showing={isPopupVisible}
        onEsc={() => setIsPopupVisible(false)}
        onDone={(customerData) => {
          setIsPopupVisible(false)
          onChange(Map(customerData))
        }}
      />
    </div>
  )
}

CustomerSelect.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  trackingEvents: PropTypes.object,
}

export default CustomerSelect
