/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@yoco/design-system-icons/dist/react'

import { Filter } from 'libs/filters'
import { If } from 'libs/formats'
import { makeTestID } from 'libs/utils'
import FlatButton from 'components/buttons/FlatButton'

import classes from './SearchBar.module.scss'

export default class SearchBar extends Filter {
  constructor(props) {
    super(props)

    this.state = {
      search: '',
    }
  }

  initializeFilter() {
    this.wasInitialized = true
    this.props.batch.searchInitialized('')
  }

  handleChange = (value = '') => {
    this.setState({
      search: value,
    })

    if (!value) {
      this.props.batch.onSearchChanged(value)
    }
  }

  handleKeyPress = (e) => {
    if (e && e.key === 'Enter') {
      this.handleSubmit()
    }
  }

  handleSubmit = () => {
    const value = this.state.search.trim()
    this.props.batch.onSearchChanged(value)
    this.props.onSearchSubmit?.(value)
  }

  render() {
    return (
      <div className={classes.root}>
        <div className={classes.inputWrapper}>
          <div className={classes.inputPrefix}>
            <Icon name='search' size={24} />
          </div>
          <input
            value={this.state.search}
            onChange={(e) => this.handleChange(e.target.value)}
            onKeyPress={this.handleKeyPress}
            type={this.props.type}
            placeholder={this.props.placeholder}
            data-testid={this.props.testID}
          />
          <If condition={this.state.search}>
            <div
              className={classes.inputSuffix}
              onClick={() => {
                this.handleChange('')
              }}
            >
              <Icon name='close' size={24} />
            </div>
          </If>
        </div>
        <FlatButton
          className='tertiary'
          label='Search'
          testID={makeTestID(this.props.testID, 'searchButton')}
          onClick={this.handleSubmit}
        />
      </div>
    )
  }
}

SearchBar.defaultProps = {
  type: 'text',
  placeholder: 'Search...',
  testID: makeTestID('search'),
}

SearchBar.propTypes = {
  testID: PropTypes.string,
}
