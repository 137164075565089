import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { initializeForm, submitForm, clearForm, fieldUpdated } from 'redux/modules/forms'
import * as forms from 'libs/forms'
import { PopupComponent } from 'libs/modals'
import { Tabs, TabPanel } from 'libs/tabs'
import localisation from 'libs/localisation/localisation'
import { If } from 'libs/formats'
import FlatButton from 'components/buttons/FlatButton'

import { calculateInvoiceEntryTotals } from '../Invoices'

import { FormName, FormFieldNames } from './constants'
import classes from './Popup.module.scss'

class DiscountPopup extends PopupComponent {
  state = {
    tabIndex: 0,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.discountingItem && this.props.discountingItem !== nextProps.discountingItem) {
      const { discountingItem, dispatch } = nextProps
      if (discountingItem) {
        dispatch(
          initializeForm(FormName, {
            [FormFieldNames.Amount]: discountingItem.get('discount'),
            [FormFieldNames.Percentage]: discountingItem.get('discountPercentage'),
          })
        )

        // Switch to amount tab if discount is amount based
        if (discountingItem.get('discount') && !discountingItem.get('discountPercentage')) {
          this.setState({ tabIndex: 1 })
        }
      }
    }
  }

  getContent() {
    const { discountingItem, dispatch, onDone } = this.props
    const { subTotal } = calculateInvoiceEntryTotals(discountingItem)

    return (
      <div className={classes.root}>
        <Tabs tabIndex={this.state.tabIndex} tabChanged={(tabIndex) => this.setState({ tabIndex })}>
          <TabPanel title='Percentage' />
          <TabPanel title='Amount' />
        </Tabs>
        <forms.Form
          name={FormName}
          showHeader={false}
          showLoader={false}
          onSubmit={(values) => {
            const discountNum = parseFloat(values.get(FormFieldNames.Amount))
            const discountPercentageNum = parseFloat(values.get(FormFieldNames.Percentage))

            dispatch(clearForm(FormName))
            this.setState({ tabIndex: 0 })

            onDone?.({
              discount:
                Number.isFinite(discountNum) && discountNum > 0
                  ? parseFloat(discountNum.toFixed(2))
                  : undefined,
              discountPercentage:
                Number.isFinite(discountPercentageNum) && discountPercentageNum > 0
                  ? discountPercentageNum
                  : undefined,
            })
          }}
        >
          <If condition={this.state.tabIndex === 0}>
            <forms.TextField
              name={FormFieldNames.Percentage}
              label='Percentage'
              validators={[
                new forms.RequiredValidator('You have to provide a percentage'),
                new forms.NumberValidator(true, 0, 100, {
                  belowMinimum: 'Percentage cannot be less than 0%',
                  aboveMaximum: 'Percentage cannot be more than 100%',
                }),
              ]}
              onChange={() => {
                dispatch(fieldUpdated(FormName, FormFieldNames.Amount, undefined))
              }}
              isFullWidth
            />
            <div className={classes.quickSelectButtons}>
              <FlatButton
                label='5%'
                type='button'
                onClick={() => {
                  dispatch(fieldUpdated(FormName, FormFieldNames.Percentage, 5))
                  dispatch(fieldUpdated(FormName, FormFieldNames.Amount, undefined))
                }}
              />
              <FlatButton
                label='10%'
                type='button'
                onClick={() => {
                  dispatch(fieldUpdated(FormName, FormFieldNames.Percentage, 10))
                  dispatch(fieldUpdated(FormName, FormFieldNames.Amount, undefined))
                }}
              />
            </div>
            <div className={classes.quickSelectButtons}>
              <FlatButton
                label='20%'
                type='button'
                onClick={() => {
                  dispatch(fieldUpdated(FormName, FormFieldNames.Percentage, 20))
                  dispatch(fieldUpdated(FormName, FormFieldNames.Amount, undefined))
                }}
              />
              <FlatButton
                label='30%'
                type='button'
                onClick={() => {
                  dispatch(fieldUpdated(FormName, FormFieldNames.Percentage, 30))
                  dispatch(fieldUpdated(FormName, FormFieldNames.Amount, undefined))
                }}
              />
            </div>
          </If>
          <If condition={this.state.tabIndex === 1}>
            <forms.CurrencyTextField
              name={FormFieldNames.Amount}
              label='Amount'
              localisation={this.props.localisation}
              validators={[
                new forms.RequiredValidator('You have to provide an amount'),
                new forms.NumberValidator(true, 0, subTotal, {
                  belowMinimum: 'Amount cannot be less than 0',
                  aboveMaximum: `Amount cannot be more than ${subTotal}`,
                }),
              ]}
              onChange={() => {
                dispatch(fieldUpdated(FormName, FormFieldNames.Percentage, undefined))
              }}
              isFullWidth
            />
          </If>
          <div className={classes.submitWrapper}>
            <FlatButton
              label='Apply discount'
              type='button'
              className='blueBackground'
              onClick={() => {
                dispatch(submitForm(FormName))
              }}
            />
          </div>
        </forms.Form>
      </div>
    )
  }

  escPressed() {
    this.setState({ tabIndex: 0 })
    super.escPressed()
  }
}

DiscountPopup.propTypes = {
  localisation: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  discountingItem: PropTypes.object,
  onDone: PropTypes.func,
}

export default connect((state) => ({
  localisation: localisation(state),
}))(DiscountPopup)
