import React, { useState } from 'react'
import RadioButton from './components/RadioButton'

import styles from './styles.module.scss'

interface Props {
  options: Option[]
  onPress: (value: any) => void
}

interface Option {
  label: string
  value: any
}

const RadioButtonForm: React.FC<Props> = ({ options, onPress }) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  const handlePress = (option: Option, index: number) => {
    setSelectedIndex(index)
    onPress(option.value)
  }

  return (
    <div className={styles.container}>
      {options.map((option, index) => (
        <RadioButton
          key={option.label}
          index={index}
          option={option}
          checked={selectedIndex === index}
          onPress={handlePress}
        />
      ))}
    </div>
  )
}

export default RadioButtonForm
