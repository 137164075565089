import { callJSONApi } from 'libs/api'
import { removeItem } from 'redux/modules/tables'
import { showMessage } from 'redux/modules/notifications'

export const deliveryMethodsTable = 'deliveryMethods'

export function deleteDeliveryMethod(uuid, onSuccess) {
  return (dispatch) => {
    callJSONApi(
      `/online/deliveryMethods/${uuid}/`,
      'DELETE',
      {},
      (result) => {
        if (result.status === 200) {
          dispatch(removeItem(deliveryMethodsTable, uuid))
          onSuccess()
        }
      },
      (prettyError) => {
        dispatch(showMessage(`Error deleting delivery mettod: ${prettyError}`, 'danger'))
      }
    )
  }
}
