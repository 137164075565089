export const BANK_ACTIONS = {
  UPDATE_UPLOADED_BANK_UPDATE_REQUEST_DOCUMENTS: 'UPDATE_UPLOADED_BANK_UPDATE_REQUEST_DOCUMENTS',
  DISPLAY_BANK_DETAILS_POPUP: 'DISPLAY_BANK_DETAILS_POPUP',
  UPDATE_BANK_ACCOUNT: 'UPDATE_BANK_ACCOUNT',
  UPDATE_BANK_ACCOUNT_UPDATE_REQUEST: 'UPDATE_BANK_ACCOUNT_UPDATE_REQUEST',
  UPDATE_BANK_ACCOUNT_UPDATE_ERROR: 'UPDATE_BANK_ACCOUNT_UPDATE_ERROR',
}

export const updateBankAccount = (payload) => ({
  type: BANK_ACTIONS.UPDATE_BANK_ACCOUNT,
  payload,
})

export const updateBankAccountUpdateRequest = (payload) => ({
  type: BANK_ACTIONS.UPDATE_BANK_ACCOUNT_UPDATE_REQUEST,
  payload,
})

export const updateBankAccountUpdateError = (payload) => ({
  type: BANK_ACTIONS.UPDATE_BANK_ACCOUNT_UPDATE_ERROR,
  payload,
})

export const updateUploadedBankUpdateRequestDocuments = (payload) => ({
  type: BANK_ACTIONS.UPDATE_UPLOADED_BANK_UPDATE_REQUEST_DOCUMENTS,
  payload,
})

export const openBankDetailsPopup = () => ({
  type: BANK_ACTIONS.DISPLAY_BANK_DETAILS_POPUP,
  payload: true,
})

export const closeBankDetailsPopup = () => ({
  type: BANK_ACTIONS.DISPLAY_BANK_DETAILS_POPUP,
  payload: false,
})
