import React, { useCallback, useMemo } from 'react'
import { Icon } from '@yoco/design-system-icons/dist/react'

import classNames from 'classnames'
import classes from './notifications.module.scss'

interface Props {
  message?: string
  messageType?: 'info' | 'success' | 'danger' | 'warning'
  closable?: boolean
  className?: string
  onClose?: () => void
  testID?: string
  children?: React.ReactNode
}

const Alert: React.FC<Props> = ({
  message,
  messageType = 'success',
  closable = true,
  className,
  onClose,
  testID,
  children,
}) => {
  const handleClose = useCallback(() => {
    if (onClose) {
      onClose()
    }
  }, [onClose])
  const getCloseButton = useMemo(
    () =>
      closable && (
        <span
          role='button'
          tabIndex={0}
          onKeyDown={handleClose}
          onClick={handleClose}
          className={classes.notificationCloseButton}
        >
          <Icon name='cross' size={24} />
        </span>
      ),
    [closable, handleClose]
  )
  const getClassNames = () =>
    classNames(classes.message, className, 'alert', `alert-${messageType}`)
  return (
    <div className={getClassNames()} data-testid={testID}>
      {message}
      {children}
      {getCloseButton}
    </div>
  )
}

export default Alert
