/* eslint-disable react/no-unused-class-component-methods */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'

import CONSTANTS from 'libs/constants'
import colors from 'ui/colors'

import classes from './items.module.scss'

export default class ItemIcon extends Component {
  getImageTextStyle() {
    if (this.props.tile.get('tileType') !== 'product') {
      // If we have a collection
      return { fontSize: '30px', lineHeight: '70px' }
    }
    // If we have a product
    return { fontSize: '30px', lineHeight: '70px' }
  }

  getImageLayoutStyle(background) {
    const layoutBackground = background
    layoutBackground.transform = 'rotate(315deg)'
    layoutBackground.height = '80px'
    layoutBackground.width = '80px'
    layoutBackground.top = '-16px'
    layoutBackground.left = '-16px'
    return layoutBackground
  }

  getImageBorderRadius() {
    return '4px'
  }

  getImageStyle(tileImage) {
    const background = {
      backgroundImage: `url("${tileImage}")`,
      backgroundPosition: 'center center',
      backgroundColor: colors['Gray-300'],
      backgroundSize: 'cover',
    }
    switch (this.props.tile.get('tileType')) {
      case 'brand':
        background.borderRadius = '8px'
        return background
      case 'product_category':
        background.borderRadius = '74px'
        return background
      case 'layout':
      case 'collection':
        return this.getImageLayoutStyle(background)
      default:
        return background
    }
  }

  getBackgroundColor() {
    if (this.props.tile) {
      if (this.props.tile.get('imageURL')) {
        return 'white'
      }

      if (this.props.tile.get('backgroundColor') || this.props.tile.get('backgroundColour')) {
        const backgroundColor =
          this.props.tile.get('backgroundColor') || this.props.tile.get('backgroundColour')
        return backgroundColor.indexOf('#') === 0 ? backgroundColor : `#${backgroundColor}`
      }
    }

    return CONSTANTS.YOCO_DEFAULT_COLOR
  }

  getBackgroundClass() {
    const backgroundClass = `${classes.itemBackground} ${classes.inner}`
    switch (this.props.tile.get('tileType')) {
      case 'brand':
        return `${backgroundClass} ${classes.rounded}`
      case 'product_category':
        return `${backgroundClass} ${classes.circle}`
      case 'layout':
      case 'collection':
        return `${backgroundClass} ${classes.diamond}`
      default:
        return backgroundClass
    }
  }

  getItemBackground() {
    return `${classes.itemIcon} ${classes.smaller}`
  }

  getInnerClass(innerClass, textStyle) {
    const tileCategory = (this.props.tile || Map()).get('tileType')
    const tileImage = (this.props.tile || Map()).get('imageURL')

    if (tileImage) {
      return <div className={innerClass} style={this.getImageStyle(tileImage, tileCategory)} />
    }

    if (tileCategory === 'layout' || tileCategory === 'collection') {
      return (
        <div className={this.getImageClass(true)} style={textStyle}>
          {this.imageText()}
        </div>
      )
    }

    return (
      <div className={this.getImageClass()} style={textStyle}>
        {this.imageText()}
      </div>
    )
  }

  getTextStyle() {
    switch (this.props.tile.get('tileType')) {
      case 'brand':
        return { lineHeight: '60px' }
      case 'product_category':
        return { lineHeight: '60px' }
      default:
        return {}
    }
  }

  getImageClass(rotate = false) {
    if (rotate) {
      return `${classes.inner} ${classes.itemText} ${classes.rotate}`
    }
    return `${classes.inner} ${classes.itemText}`
  }

  getInnerBackgroundClass() {
    return `${classes.itemBackground} ${classes.inner}`
  }

  imageText() {
    let tileIcon
    let tileImage
    let tileName

    if (this.props.tile) {
      tileIcon = this.props.tile.get('imageIcon')
      tileImage = this.props.tile.get('imageText')
      tileName = this.props.tile.get('name')
    }

    if (tileIcon) {
      return <i className={`iconcollection-${tileIcon}`} style={this.getImageTextStyle()} />
    }

    let imageText = null
    if (tileImage) {
      imageText = tileImage.replace(/_/g, '')
    }

    if (imageText) {
      return imageText
    }

    return tileName ? tileName.substring(0, 2) : ''
  }

  render() {
    const backgroundStyle = {
      backgroundPosition: 'center center',
      backgroundColor: this.getBackgroundColor(),
    }

    const backgroundClass = this.getBackgroundClass()
    const innerClass = this.getInnerBackgroundClass()
    const textStyle = this.getTextStyle()
    const { quantity } = this.props

    return (
      <div className={this.getItemBackground()} style={this.props.style}>
        {quantity && <div className={classes.itemQuantity}>{quantity}</div>}
        <div className={backgroundClass} style={backgroundStyle}>
          {this.getInnerClass(innerClass, textStyle)}
        </div>
      </div>
    )
  }
}
ItemIcon.defaultProps = {
  tile: Map(),
}

ItemIcon.propTypes = {
  style: PropTypes.object,
  tile: PropTypes.object,
  quantity: PropTypes.number,
}
