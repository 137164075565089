/* eslint-disable
  @typescript-eslint/no-unused-vars,
  no-unused-vars,
  @typescript-eslint/no-explicit-any
 */
import { callJSONApi } from 'libs/api'

import ActionTypes from './actionTypes'

/**
 * Fetches dashboard insights
 *
 * @param {String} url
 * @returns {Function}
 * @private
 */
export function fetchInsights(url) {
  return new Promise((resolve, reject) => {
    callJSONApi(
      url,
      'GET',
      '',
      (response) => {
        resolve(response.data)
      },
      (error) => {
        reject(error)
      }
    )
  })
}

export function fetchTotalSalesForPeriod(period) {
  return fetchInsights(`/dashboard/totalSales?period=${period}`)
}

export function fetchAvgSalesForPeriod(period) {
  return fetchInsights(`/dashboard/averageSalesAmount?period=${period}`)
}

export function fetchAvgItemsForPeriod(period) {
  return fetchInsights(`/dashboard/averageItemsPerSale?period=${period}`)
}

export function fetchAllFacts(period) {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.FETCH_FACTS_REQUEST })
    try {
      const response = await Promise.all([
        fetchTotalSalesForPeriod(period),
        fetchAvgSalesForPeriod(period),
        fetchAvgItemsForPeriod(period),
      ])
      dispatch({ type: ActionTypes.FETCH_FACTS_SUCCESS })
      dispatch({
        type: ActionTypes.FETCH_FACTS_FULFILLED,
        payload: {
          totalSales: response[0] ? response[0] : 0,
          averageSalesAmount: response[1] ? response[1] : 0,
          averageItemsPerSale: response[2] ? response[2] : 0,
        },
      })
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_FACTS_FAILURE, error })
    }
  }
}
