import ROLES from 'libs/roles'
import FEATURES from 'libs/features'

import SalesHistoryPage from './SalesHistoryPage'
import SalesPerformancePage from './SalesPerformance/SalesPerformancePage'
import InvoicesPage from './InvoicesPage'
import SalesReportPage from './SalesReportPage'
import SettlementsPage from './SettlementsPage'

export default function getRoutes() {
  return {
    path: 'sales',
    text: 'Sales and Refunds',
    icon: 'sales-history',
    routes: [
      {
        path: 'reports',
        text: 'Reports',
        component: SalesReportPage,
        requiresOneRole: [ROLES.VIEW_FINANCIAL],
      },
      {
        path: 'performance',
        text: 'Sales Performance',
        component: SalesPerformancePage,
        requiresOneOfFeatures: [FEATURES.SALES_PERFORMANCE],
      },
      {
        path: 'history',
        text: 'History',
        component: SalesHistoryPage,
      },
      {
        path: 'invoices',
        text: 'Invoices',
        component: InvoicesPage,
        requiresOneRole: [ROLES.VIEW_FINANCIAL],
        isForCardAcceptingLocalisation: true,
      },
      {
        path: 'settlements',
        text: 'Payouts',
        component: SettlementsPage,
        requiresOneRole: [ROLES.VIEW_FINANCIAL],
        isForCardAcceptingLocalisation: true,
      },
    ],
  }
}
