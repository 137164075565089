import React from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'

import { TableRow } from 'libs/tables'

export default function DeliveryMethodRow({ deliveryMethod, index, onClick }) {
  return (
    <TableRow rowIndex={index} onClick={() => onClick(deliveryMethod)}>
      <td>{deliveryMethod.get('displayName')}</td>
      <td>{deliveryMethod.get('price')}</td>
    </TableRow>
  )
}

DeliveryMethodRow.propTypes = {
  deliveryMethod: PropTypes.instanceOf(Immutable.Map),
  index: PropTypes.number,
  onClick: PropTypes.func,
}
