import { getENV } from 'libs/utils'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const $script = require('scriptjs')

const getAddressComponent = (place, name, short) => {
  if (place.address_components) {
    const match = place.address_components.find((component) => component.types.includes(name))
    if (match) {
      return short ? match.short_name : match.long_name
    }
  }
  return ''
}

const getStructuredAddress = (place) => {
  return {
    firstLine: place.formatted_address.split(',')[0],
    secondLine: getAddressComponent(place, 'sublocality'),
    postalCode: getAddressComponent(place, 'postal_code'),
    city: getAddressComponent(place, 'locality'),
    cityGroup: getAddressComponent(place, 'administrative_area_level_2'),
    province: getAddressComponent(place, 'administrative_area_level_1'),
    country: getAddressComponent(place, 'country'),
    latitude: getAddressComponent(place).latitude,
    longitude: getAddressComponent(place).longitude,
    countryCode: getAddressComponent(place, 'country', true),
  }
}

const getAddressFromLatLng = async (lat, lng) => {
  return new Promise((resolve, reject) => {
    $script(
      [
        `https://maps.googleapis.com/maps/api/js?key=${getENV(
          'GOOGLE_MAPS_API_KEY'
        )}&libraries=places`,
      ],
      'google-places-api'
    )

    $script.ready('google-places-api', () => {
      const latlng = new window.google.maps.LatLng(lat, lng)
      const geocoder = new window.google.maps.Geocoder()
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === 'OK') {
          const address = getStructuredAddress(results[0])
          resolve(address)
        } else {
          reject(new Error(status))
        }
      })
    })
  })
}

export { getAddressFromLatLng, getStructuredAddress }
