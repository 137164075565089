import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import containers from 'ui/containers/containers.module.scss'
import layout from 'ui/layout/layout.module.scss'

function Card({ children, className }) {
  return (
    <div
      className={classnames(
        layout.p4,
        containers.rounded,
        containers.shadow,
        containers.card,
        className
      )}
    >
      {children}
    </div>
  )
}

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

export default Card
