export const defaultRoleDescriptions = [
  {
    groupName: 'Sales and Refunds',
    roles: [
      {
        role: 'userCanTransact',
        title: 'Make sales',
        description: 'Make card and cash sales and view a history of their own transactions.',
      },
      {
        role: 'userCanViewTransactions',
        title: 'View all transactions',
        description: 'View all sales, refunds, errors & aborted transactions in the business.',
      },
      {
        role: 'userCanReverseLastTransaction',
        title: 'Refund their own last sale',
        description: 'Reverse the last sale made in case of an accidental charge.',
      },
      {
        role: 'userCanRefundTransactions',
        title: 'Refund any sale',
        description: 'Refund any sale they are allowed to view.',
      },
      {
        role: 'userCanAssignSales',
        title: 'Assign sales',
        description: 'Assign sales to another staff member during a transaction.',
        note: 'They can also re-assign the 5 most recent sales they can view.',
      },
      {
        role: 'userCanAssignSalesHistorically',
        title: 'Re-assign past sales',
        description: 'Re-assign any sale they are allowed to view to another staff member.',
      },
    ],
  },
  {
    groupName: 'Managing your Business',
    roles: [
      {
        role: 'userCanManageInventory',
        title: 'Manage products',
        description: 'Add, edit and remove products from the Yoco App or Portal.',
      },
      {
        role: 'userCanManageStaff',
        title: 'Manage staff',
        description: 'Add, edit and remove staff from the Yoco App or Portal.',
        note: 'A user can only assign permissions they have to other users.',
      },
      {
        role: 'userCanViewFinancial',
        title: 'View financials',
        description: 'View account balance and payouts.',
        note: 'Invoices and detailed financials can only be accessed on the Yoco Portal.',
      },
      {
        role: 'userCanManageBusiness',
        title: 'Manage business settings',
        description: 'Change bank details & business settings such as payment methods.',
      },
      {
        role: 'userCanManageReports',
        title: 'Manage reports',
        description: 'Configure, add or remove users from reports.',
        note: 'Reports can only be accessed on the Yoco Portal.',
      },
      {
        role: 'userCanManageHardwareDevices',
        title: 'Manage external printers',
        description: 'Add or remove external printers paired to the Yoco App.',
      },
    ],
  },
]
