import React from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'

import storeClasses from 'containers/store/store.module.scss'
import ShareOnlineProduct from 'containers/online/components/ShareOnlineProduct'

function OnlineProductForm({ onlineProduct }) {
  const hashid = onlineProduct.get('hashid')
  return (
    <div className={storeClasses.viewPanel}>
      {hashid ? <ShareOnlineProduct hashid={hashid} /> : null}
    </div>
  )
}

OnlineProductForm.propTypes = {
  onlineProduct: PropTypes.instanceOf(Immutable.Map).isRequired,
}

export default OnlineProductForm
