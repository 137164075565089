import React from 'react'
import PropTypes from 'prop-types'

import yocoImage from 'assets/images/yoco.png'

import classes from './LoadingView.module.scss'
import Spinner from './Spinner'

function FullscreenLoadingPage({ message }) {
  return (
    <div className={classes.fullScreenView}>
      <div className={classes.center}>
        <img src={yocoImage} alt='Yoco logo' height={71} />
        <div className={classes.padded}>
          <Spinner blue />
        </div>
        <div>{message}</div>
      </div>
    </div>
  )
}

FullscreenLoadingPage.propTypes = {
  message: PropTypes.string,
}

FullscreenLoadingPage.defaultProps = {
  message: 'Loading the Yoco Business Portal...',
}

export default FullscreenLoadingPage
