import { getLendingPartnerDisplayName } from 'components/capital/utils'
import React from 'react'
import { LendingPartners } from 'redux/modules/capital/types'

import CapitalDetailsBlock from '../CapitalDetailsBlock'
import styles from './CapitalOfferSummary.module.scss'

interface Props {
  outstandingBalance: number
  approvedAdvance: string
  repaymentPercentage: number
  estimatedTerm: number
  processingFee: number
  totalPayable: number
  providerName: LendingPartners
}

const CapitalOfferSummary: React.FunctionComponent<Props> = ({
  outstandingBalance,
  approvedAdvance,
  repaymentPercentage,
  estimatedTerm,
  processingFee,
  totalPayable,
  providerName,
}) => {
  return (
    <section className={styles.summary}>
      <div className={styles.heading}>
        <h3>Summary</h3>
        <div>Review your offer from {getLendingPartnerDisplayName(providerName)}.</div>
      </div>
      <div className={styles.detailsContainer}>
        <div className={styles.amount}>
          <h4>Amount you&apos;ll receive</h4>
          <h1>{approvedAdvance}</h1>
        </div>
        <div className={styles.details}>
          <CapitalDetailsBlock
            outstandingBalance={outstandingBalance}
            repaymentPercentage={repaymentPercentage}
            estimatedTerm={estimatedTerm}
            processingFee={processingFee}
            totalPayable={totalPayable}
          />
        </div>
      </div>
    </section>
  )
}

export default CapitalOfferSummary
