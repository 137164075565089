import Immutable, { Map } from 'immutable'

export const ACTIONS = {
  CHANGE_SCREEN: 'CHANGE_SCREEN',
  SELECT_FILE: 'SELECT_FILE',
  FILE_UPLOADED: 'FILE_UPLOADED',
  UPLOAD_RESPONSE: 'UPLOAD_RESPONSE',
  RESET_STATE: 'RESET_STATE',
}

export function changeScreen(screen) {
  return {
    type: ACTIONS.CHANGE_SCREEN,
    screen: Immutable.fromJS(screen),
  }
}

export function selectFile(file) {
  return {
    type: ACTIONS.SELECT_FILE,
    file,
  }
}

export function fileUploaded(status, uuid) {
  return {
    type: ACTIONS.FILE_UPLOADED,
    status,
    uuid,
  }
}

export function uploadResponse(response) {
  return {
    type: ACTIONS.UPLOAD_RESPONSE,
    response: Immutable.fromJS(response),
  }
}

export function resetState() {
  return {
    type: ACTIONS.RESET_STATE,
  }
}

const initialState = Map({
  file: null,
  uploadResponse: null,
  screen: Immutable.fromJS('upload-screen'),
  fileUploaded: Immutable.fromJS(false),
  uuid: null,
})

export default function Session(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS.CHANGE_SCREEN:
      return state.set('screen', action.screen)
    case ACTIONS.SELECT_FILE:
      return state.set('file', action.file)
    case ACTIONS.FILE_UPLOADED:
      return state.set('fileUploaded', action.status).set('uuid', action.uuid)
    case ACTIONS.UPLOAD_RESPONSE:
      return state.set('uploadResponse', action.response)
    case ACTIONS.RESET_STATE:
      return initialState
    default:
      return state
  }
}
