/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { makeTestID } from 'libs/utils'

import classes from './tabs.module.scss'

export default class Tabs extends Component {
  constructor(props) {
    super(props)

    this.state = {
      toggled: false,
    }
  }

  getClass() {
    return classes.tabs
  }

  // Override this method with a signature off (title, insight, tabIndex, index)
  getTabHeader() {
    return null
  }

  indexChanged(index) {
    this.setState({
      toggled: false,
    })

    if (this.props.tabChanged) {
      this.props.tabChanged(index)
    }
  }

  mobileTabsToggled() {
    this.setState((state) => ({
      toggled: !state.toggled,
    }))
  }

  render() {
    let loopIndex = 0
    let mobileLoopIndex = 0
    let secondaryLoopIndex = 0
    const toggledClass = this.state.toggled ? ` ${classes.toggled}` : ''
    const mobileClass = ` ${classes.mobile}`
    const tabClass = this.getClass()
    const filteredChildren = this.props.children.filter((child) => child !== undefined)

    return (
      <div className={tabClass} data-testid={this.props.testID}>
        <nav className={classes.tabsNavigation}>
          <ul className={classes.tabsMenu}>
            {filteredChildren.map((child) => {
              let liClass = classes.tabsMenuItem
              if (this.props.tabIndex === loopIndex) {
                liClass += ` ${classes.isActive}`
              }
              loopIndex += 1

              const tabHeader = child.props.title ? (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a>{child.props.title}</a>
              ) : (
                this.getTabHeader(child.props.period, child.props.insight, child.props.isActive)
              )

              return (
                <li
                  className={liClass}
                  onClick={this.indexChanged.bind(this, loopIndex - 1)}
                  key={loopIndex}
                  data-testid={child.props.testID}
                >
                  {tabHeader}
                </li>
              )
            })}
          </ul>
        </nav>
        <nav
          className={classes.tabsNavigation + mobileClass + toggledClass}
          data-testid={makeTestID('brandsAndCategories', 'tab', 'mobile')}
        >
          <div className={classes.selectedTab} onClick={this.mobileTabsToggled.bind(this)}>
            {React.Children.toArray(filteredChildren)[this.props.tabIndex].props.title}
          </div>
          <div className={classes.list}>
            {filteredChildren.map((child) => {
              mobileLoopIndex += 1
              const tabHeader = child.props.title ? (
                <a>{child.props.title}</a>
              ) : (
                this.getTabHeader(
                  child.props.period,
                  child.props.insight,
                  child.props.tabIndex,
                  child.props.index
                )
              )
              return (
                <div
                  onClick={this.indexChanged.bind(this, mobileLoopIndex - 1)}
                  key={mobileLoopIndex}
                >
                  {tabHeader}
                </div>
              )
            })}
          </div>
        </nav>
        <article className={classes.tabPanel} style={{ position: 'relative', margin: 0 }}>
          {filteredChildren.map((child) => {
            let style = { display: 'none', position: 'absolute', pointerEvents: 'none' }
            if (secondaryLoopIndex === this.props.tabIndex) {
              style = { pointerEvents: 'auto' }
            }
            secondaryLoopIndex += 1

            return (
              <div style={style} key={secondaryLoopIndex}>
                {child}
              </div>
            )
          })}
        </article>
      </div>
    )
  }
}

Tabs.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  tabChanged: PropTypes.func.isRequired,
  testID: PropTypes.string,
  children: PropTypes.oneOf([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
}
