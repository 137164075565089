import React from 'react'
import BlockHeader from 'components/headers/BlockHeader'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import EditBankDetailsContent from './components/EditBankDetailsContent'

function EditBankDetailsPage() {
  return (
    <MaxWidthBlock>
      <BlockHeader title='Bank Details' />
      <EditBankDetailsContent />
    </MaxWidthBlock>
  )
}

export default EditBankDetailsPage
