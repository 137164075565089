import { combineReducers } from '@reduxjs/toolkit'
import { Record } from 'immutable'

import ActionTypes from './actionTypes'

const makeState = Record({
  pluginKeys: {},
})

const initialState = makeState()

export function hasAccount(state = false, action) {
  switch (action.type) {
    case ActionTypes.RECEIVED_KEYS:
      return true
    case ActionTypes.GET_API_KEYS_FAILURE:
      return state
    default:
      return state
  }
}

export function pluginDetails(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.RECEIVED_KEYS:
      return action.payload
    case ActionTypes.GET_API_KEYS_REQUEST:
      return state
    case ActionTypes.GET_API_KEYS_FAILURE:
      return state
    default:
      return state
  }
}

export function loaderDisplay(state = true, action) {
  switch (action.type) {
    case ActionTypes.GET_API_KEYS_REQUEST:
      return true
    case ActionTypes.GET_API_KEYS_SUCCESS:
      return false
    case ActionTypes.GET_API_KEYS_FAILURE:
      return false
    case ActionTypes.CREATE_API_KEYS_REQUEST:
      return true
    case ActionTypes.CREATE_API_KEYS_SUCCESS:
      return false
    case ActionTypes.CREATE_API_KEYS_FAILURE:
      return false
    default:
      return state
  }
}

export function thriveError(state = false, action) {
  if (action.type === ActionTypes.CREATE_API_KEYS_FAILURE) {
    return true
  }
  return state
}

export default combineReducers({
  pluginDetails,
  loaderDisplay,
  hasAccount,
  thriveError,
})
