import React, { useCallback } from 'react'
import { Icon } from '@yoco/design-system-icons/dist/react'

import { makeTestID } from 'libs/utils'
import PopupComponent from 'libs/modals/popups'
import VStack from 'ui/layout/VStack'
import typography from 'ui/typography/typography.module.scss'
import Spinner from 'components/loaders/Spinner'
import FlatButton from 'components/buttons/FlatButton'
import classNames from 'classnames'
import classes from './ConfirmSaveModal.module.scss'

interface ConfirmSaveModalProps {
  showPopup: boolean
  isSaving: boolean
  setShowPopup: (bool) => void
  noSaveCallback: () => void
}

const ConfirmSaveModal: React.FunctionComponent<ConfirmSaveModalProps> = ({
  showPopup,
  setShowPopup,
  isSaving = false,
  noSaveCallback,
}) => {
  const closePopup = useCallback(() => setShowPopup(false), [setShowPopup])
  const getPopupContent = useCallback(
    () => (
      <div
        className={classes.contentContainer}
        data-testid={makeTestID('onlineStore', 'ConfirmSaveModal')}
      >
        <Icon
          data-testid={makeTestID('onlineStore', 'ConfirmSaveModal', 'icon')}
          name='online-store'
          size={56}
        />
        {isSaving ? (
          <Spinner blue />
        ) : (
          <VStack spacing='small'>
            <>
              <h2
                data-testid={makeTestID('onlineStore', 'ConfirmSaveModal', 'title')}
                className={typography.h2}
              >
                Unsaved Changes
              </h2>
              <p
                data-testid={makeTestID('onlineStore', 'ConfirmSaveModal', 'message')}
                className={typography.textSmall}
              >
                You haven’t saved the changes made to your online store customisation. Are you sure
                you want to leave this page?
              </p>
            </>
            <div
              data-testid={makeTestID('onlineStore', 'ConfirmSaveModal', 'buttonContainer')}
              className={classes.buttonContainer}
            >
              <FlatButton
                testID={makeTestID('onlineStore', 'ConfirmSaveModal', 'cancelButton')}
                onClick={closePopup}
                type='tertiary'
                disabled={isSaving}
                className={classes.button}
                label='Cancel'
              />
              <FlatButton
                testID={makeTestID('onlineStore', 'ConfirmSaveModal', 'dontSaveButton')}
                onClick={noSaveCallback}
                type='tertiary'
                disabled={isSaving}
                className={classNames('blueBackground', classes.button, classes.deleteButton)}
                label="Yes, don't save"
              />
            </div>
          </VStack>
        )}
      </div>
    ),
    [closePopup, isSaving, noSaveCallback]
  )
  return <PopupComponent onEsc={closePopup} showing={showPopup} getContent={getPopupContent} />
}
export default ConfirmSaveModal
