/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Icon } from '@yoco/design-system-icons/dist/react'

import layout from 'ui/layout/layout.module.scss'
import ui from 'ui/ui.module.scss'

export default function RemovableImage({ onClick, alt, ...props }) {
  return (
    <span className={classnames(layout.relative, layout.inlineBlock)}>
      <img alt={alt} {...props} />
      <div
        className={classnames(ui.cursorPointer, ui.visibleOnHover, layout.cover)}
        style={{ backgroundColor: '#00000066' }}
        onClick={onClick}
      >
        <div
          style={{
            position: 'absolute',
            height: '24px',
            top: '50%',
            left: '50%',
            marginLeft: '-12px',
            marginTop: '-12px',
          }}
        >
          <Icon name='cross' size={24} color='white' />
        </div>
      </div>
    </span>
  )
}

RemovableImage.propTypes = {
  onClick: PropTypes.func,
  alt: PropTypes.string,
}
