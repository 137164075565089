import ConnectedObjectComponent from './ConnectedObjectComponent'

export default class ConnectedRow extends ConnectedObjectComponent {
  render() {
    const connectionStatus = this.getConnectionStatus()
    return this.props.renderRow(
      this.getConnectedObject(),
      this.props.index,
      this.props.groupIndex,
      this.props.lastSubrow,
      connectionStatus
    )
  }
}
