import moment from 'moment'

import { makeTestID } from 'libs/utils'

export const TableName = 'invoices'

export const TablePageSize = 20

export const InvoiceFilterNames = {
  Customer: 'customerId',
  Status: 'status',
  PaymentMethod: 'paymentMethod',
}

export const filterStatusOptions = {
  unpaid: {
    title: 'Unpaid',
    selected: false,
    testID: makeTestID(TableName, 'filterStatus', 'unpaid'),
  },
  paid: {
    title: 'Paid',
    selected: false,
    testID: makeTestID(TableName, 'filterStatus', 'paid'),
  },
  overdue: {
    title: 'Overdue',
    selected: false,
    testID: makeTestID(TableName, 'filterStatus', 'overdue'),
  },
  draft: {
    title: 'Draft',
    selected: false,
    testID: makeTestID(TableName, 'filterStatus', 'draft'),
  },
}

export const filterPaymentMethodOptions = {
  Card: {
    title: 'Card',
    selected: false,
    testID: makeTestID(TableName, 'filterPaymentMethod', 'Card'),
  },
  ManualEFT: {
    title: 'Manual EFT',
    selected: false,
    testID: makeTestID(TableName, 'filterPaymentMethod', 'ManualEFT'),
  },
  Cash: {
    title: 'Cash',
    selected: false,
    testID: makeTestID(TableName, 'filterPaymentMethod', 'Cash'),
  },
}

export const filterDateDefaultValue = [moment('2020-01-01'), moment().endOf('day')]
