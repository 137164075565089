/* eslint-disable no-restricted-syntax */
import { callInvoicesJSONAPI } from 'libs/api'
import { ApiFilter } from 'libs/filters'

export default class FilterCustomer extends ApiFilter {
  loadAvailableOptions(defaults) {
    const offset = 5 * this.pageNumber
    let url = `customers?limit=5&offset=${offset}`

    if (this.state.searchString) {
      url = `${url}&search=${this.state.searchString}`
    }

    this.requestIndex += 1
    const index = this.requestIndex

    callInvoicesJSONAPI(url, 'GET').then(
      (data) => {
        if (index === this.requestIndex) {
          this.setState({
            loading: false,
          })

          if (data.status > 299) {
            this.showError(data.message)
          } else {
            let newKeys = []
            let newOptions = {}
            if (offset > 0) {
              newKeys = this.state.availableOptionKeys
              newOptions = this.state.availableOptions
            } else {
              newOptions = this.addSelected(newKeys, newOptions)
            }
            const list = (data?.data || []).map((e) => ({
              ...e,
              uuid: e.id,
            }))

            for (const item of list) {
              newOptions = this.addDisplayableOption(item, newKeys, newOptions)
            }

            if (defaults) {
              let found = false
              const keys = Object.keys(newOptions)
              keys.forEach((key) => {
                if (defaults.indexOf(key) >= 0) {
                  found = true
                  newOptions[key].selected = true
                }
              })

              if (!found && defaults[0]) {
                const dummyItem = this.generateDummyItemFromParams(defaults[0])
                if (dummyItem) {
                  newOptions = this.addDisplayableOption(dummyItem, newKeys, newOptions)

                  newOptions[defaults[0]].selected = true
                }
              }
            }

            this.setState({
              availableOptions: newOptions,
              availableOptionKeys: newKeys,
              showLoadMore: list.length === 5,
              error: null,
            })

            this.initializeFilter(this.state.defaults)
            this.setState({
              loading: false,
            })
          }
        }
      },
      () => {
        if (index === this.requestIndex) {
          this.setState({
            loading: false,
          })

          this.showError('Failed to load Customers')
        }
      }
    )
  }

  addDisplayableOption(option, newKeys, newOptions) {
    const optionKey = option.uuid
    const updatedNewOptions = { ...newOptions }
    if (newKeys.indexOf(optionKey) === -1) {
      newKeys.push(optionKey)

      updatedNewOptions[optionKey] = {
        title: option[this.props.titleKey],
        subtext: option.email || option.phone,
        selected: false,
      }
    }

    return updatedNewOptions
  }
}
