/* eslint-disable react/no-array-index-key */
import React from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import { Table } from './Table'
import EmptyTableView from './EmptyTableView'

export class InlineTable extends Table {
  // eslint-disable-next-line consistent-return
  getHeader() {
    if (this.props.titles) {
      return (
        <tr>
          {this.props.titles.map((title, titleIndex) => {
            return <th key={`title-${titleIndex}`}>{title}</th>
          })}
        </tr>
      )
    }
  }

  getRow(immutableRow, rowIndex) {
    if (this.props.getRow) {
      return this.props.getRow(immutableRow, rowIndex)
    }
    const row = immutableRow.toJS()
    if (row instanceof Array) {
      return (
        <tr key={`row-${rowIndex}`}>
          {row.map((column, columnIndex) => {
            return <td key={`column-${rowIndex}-${columnIndex}`}>{column}</td>
          })}
        </tr>
      )
    }
    return (
      <tr>
        <td>{row}</td>
      </tr>
    )
  }

  /* Each individual row of the table, use this to render the data */
  // eslint-disable-next-line consistent-return
  getRows() {
    let rowData = this.props.table.get('data')
    if (this.props.rows) {
      rowData = this.props.rows
    }
    if (rowData) {
      return <tbody>{rowData.map((row, rowIndex) => this.getRow(row, rowIndex))}</tbody>
    }
  }
}

InlineTable.defaultProps = {
  useOffset: false,
  useSubItems: false,
  hasResponsive: false,
  emptyTable: <EmptyTableView />,
  pageSize: 5,
  showPaging: false,
  waitForFilterLoad: false,
}

export default connect((state, props) => ({
  table: state.tables.get(props.name, Map()),
  batch: state.filters.get(props.filterName || props.name, Map()),
  sortedColumns: state.tables.getIn(['sortedColumns', props.name], Map()),
}))(InlineTable)
