import React from 'react'
import PropTypes from 'prop-types'

import * as forms from 'libs/forms'
import { If } from 'libs/formats'
import ItemImageUpload from 'components/items/ItemImageUpload'
import FlatButton from 'components/buttons/FlatButton'
import Spinner from 'components/loaders/Spinner'
import logoPlaceholderUrl from 'assets/images/online/logo-placeholder.png'

import classes from './ImageUploadField.module.scss'

class ImageUploadField extends forms.FormField {
  handleUploadStart = () => {
    this.setState({ loading: true })
  }

  handleUploadComplete = (response) => {
    this.props.form.onFieldChange(this.props.name, response.data.url)
  }

  handleImageLoad = () => {
    this.setState({ loading: false })
  }

  handleRemove = () => {
    this.props.form.onFieldChange(this.props.name, null)
  }

  renderInput() {
    const { value } = this.props

    return (
      <div className={classes.root}>
        <div className={classes.preview}>
          <img src={value || logoPlaceholderUrl} onLoad={this.handleImageLoad} alt='logo' />
          <If condition={this.state.loading}>
            <div className={classes.previewSpinner}>
              <Spinner blue />
            </div>
          </If>
        </div>
        <div className={classes.upload}>
          <If condition={!!this.props.uploadDescription}>
            <div className={classes.uploadDescription}>{this.props.uploadDescription}</div>
          </If>
          <div className={classes.uploadActions}>
            <div className={classes.uploadButtonContainer}>
              <ItemImageUpload
                fullCover
                grouping={this.props.grouping}
                maxSize={this.props.maxSize || 512}
                format={this.props.format || 'image/jpg'}
                loaderStyle={classes.hideDefaultLoader}
                startUploadCallback={this.handleUploadStart}
                completeCallback={this.handleUploadComplete}
              />
              <FlatButton type='button' className='secondary' disabled={this.state.loading}>
                Upload
              </FlatButton>
            </div>
            <If condition={value && !this.state.loading}>
              <FlatButton type='button' className='destructive' onClick={this.handleRemove}>
                Remove
              </FlatButton>
            </If>
          </div>
        </div>
      </div>
    )
  }
}

ImageUploadField.propTypes = {
  grouping: PropTypes.string.isRequired,
  maxSize: PropTypes.number,
  format: PropTypes.string,
}

export default ImageUploadField
