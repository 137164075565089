import { combineReducers } from '@reduxjs/toolkit'
import { Record } from 'immutable'

import { handleRequest, initialRequestState } from 'redux/modules/helpers/requestHelper'

import ActionTypes from './actionTypes'

export const PERFORMANCE_PERIODS = {
  YEAR: 'year',
  MONTH: 'month',
  WEEK: 'week',
  DAY: 'day',
}

const makeState = Record({
  period: PERFORMANCE_PERIODS.YEAR,
  rows: [],
})

const initialState = makeState()

export function performanceRequest(state = initialRequestState(), action) {
  return handleRequest(
    ActionTypes.FETCH_SALES_PERFORMANCE_REQUEST,
    ActionTypes.FETCH_SALES_PERFORMANCE_SUCCESS,
    ActionTypes.FETCH_SALES_PERFORMANCE_FAILURE,
    state,
    action
  )
}

export function performance(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.FETCH_SALES_PERFORMANCE_FULFILLED:
      return state.mergeDeep(action.payload)
    default:
      return state
  }
}

export default combineReducers({
  performance,
  performanceRequest,
})
