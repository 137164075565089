/* eslint-disable react/no-unstable-nested-components */
import React, { Component } from 'react'
import moment from 'moment'

import BlockHeader from 'components/headers/BlockHeader'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import { Table, EmptyTableView } from 'libs/tables'
import { Amount } from 'libs/formats'

import classes from './sales.module.scss'

export default class InvoicesPage extends Component {
  getInvoicePeriod(immutableRow, format) {
    let date
    if (immutableRow.get('startDate')) {
      date = moment(immutableRow.get('startDate'))
    } else if (immutableRow.get('endDate')) {
      date = moment(immutableRow.get('endDate')).subtract(1, 'days')
    } else {
      date = moment(immutableRow.get('created'))
    }

    return date.format(format)
  }

  renderTable() {
    return (
      <Table
        api='/transactions/invoices'
        name='transaction-invoices'
        useOffset
        waitForFilterLoad={false}
        hideNoResults
        emptyTable={<EmptyTableView title='Your business currently has no invoices' />}
        getHeader={() => {
          return (
            <tr className='visible-header'>
              <th>Invoice Number</th>
              <th>Invoice Period</th>
              <th>Transaction Amount</th>
              <th>Fee Amount</th>
              <th>Download</th>
            </tr>
          )
        }}
        getRow={(immutableRow, rowIndex) => {
          let pdfFile = <span />
          if (immutableRow.get('pdf')) {
            pdfFile = (
              <a href={immutableRow.getIn(['pdf', 'url'])} rel='noreferrer' target='_blank'>
                {immutableRow.getIn(['pdf', 'filename'])}
              </a>
            )
          }

          let className = ''
          if (!(rowIndex % 2)) {
            className = ` row-background`
          }

          return (
            <tr className={className} key={`row-${rowIndex}`}>
              <td className={`${classes.invoiceNumber} hideOnMd`}>
                {immutableRow.get('invoiceNumber')}
              </td>
              <td className={classes.invoicePeriod}>
                <div className={classes.bigOne}>
                  The month of <b>{this.getInvoicePeriod(immutableRow, 'MMMM, YYYY')} </b>
                </div>
                <div className={classes.littleOne}>
                  {this.getInvoicePeriod(immutableRow, 'MMM, YYYY')}
                </div>
              </td>
              <td className={classes.settlementTransactionAmount}>
                <Amount amount={immutableRow.get('transactionAmount')} alignLeft />
              </td>
              <td className={`${classes.settlementFeeAmount} hideOnMobile`}>
                <Amount amount={immutableRow.get('feeAmount')} alignLeft />
              </td>
              <td>{pdfFile}</td>
            </tr>
          )
        }}
      />
    )
  }

  render() {
    return (
      <MaxWidthBlock>
        <BlockHeader title='Invoices' />
        {this.renderTable()}
      </MaxWidthBlock>
    )
  }
}
