/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState, useCallback, useMemo } from 'react'
import { connect } from 'react-redux'

import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import Spacer from 'ui/layout/Spacer'
import FlatButton from 'components/buttons/FlatButton'
import Alert from 'components/notifications/Alert'
import BlockHeader from 'components/headers/BlockHeader'
import * as forms from 'libs/forms'
import SplitBlock from 'components/blocks/SplitBlock'
import { EmptyTableView, Table } from 'libs/tables'
import DeliveryMethodRow from 'containers/online/components/DeliveryMethodRow'
import { refreshTable } from 'redux/modules/tables'
import { deliveryMethodsTable, deleteDeliveryMethod } from 'containers/online/actions'
import DeliveryVehicle from 'assets/images/Equipment.png'

import OnlineStoreBackButton from './components/OnlineStoreBackButton'
import DeliveryMethodForm from './components/DelieveryMethods/DeliveryMethodForm'
import { DeliveryMethodPageProps, DeliveryMethodProps } from './types'

const DeliveryMethodsPage: React.FunctionComponent<DeliveryMethodPageProps> = ({
  refreshDeliveryMethods,
  deleteDeliveryMethod,
}) => {
  const [addingDeliveryMethod, setAddingDeliveryMethod] = useState(false)
  const [editDeliveryMethod, setEditDeliveryMethod] = useState<DeliveryMethodProps | null>(null)
  const [errorMessage, setErrorMessage] = useState(null)

  const deliveryMethodUUID = useMemo(() => {
    return editDeliveryMethod ? editDeliveryMethod.uuid : ''
  }, [editDeliveryMethod])
  const closeSplitView = useCallback(() => {
    setEditDeliveryMethod(null)
    setAddingDeliveryMethod(false)
  }, [setEditDeliveryMethod, setAddingDeliveryMethod])

  return (
    <SplitBlock
      formName='deliveryMethod'
      showingSplit={!!addingDeliveryMethod || !!editDeliveryMethod}
      showDelete={!!editDeliveryMethod}
      saveLabel={addingDeliveryMethod ? 'Add' : 'Save'}
      onDismiss={() => closeSplitView()}
      deletePressed={() => {
        deleteDeliveryMethod(deliveryMethodUUID, () => {
          setEditDeliveryMethod(null)
        })
      }}
      header={addingDeliveryMethod ? 'Add delivery method' : 'Edit delivery method'}
      renderSplitContent={() => {
        if (addingDeliveryMethod || editDeliveryMethod) {
          return (
            <DeliveryMethodForm
              initialData={editDeliveryMethod}
              onSuccess={() => {
                refreshDeliveryMethods()
                closeSplitView()
              }}
            />
          )
        }

        return null
      }}
    >
      <MaxWidthBlock>
        <OnlineStoreBackButton />
        <BlockHeader
          title='Delivery Methods'
          actions={
            <FlatButton
              label='Add Delivery Method'
              className='blueBackground'
              onClick={() => {
                setAddingDeliveryMethod(true)
              }}
            />
          }
        />

        {errorMessage && (
          <Alert
            messageType='danger'
            message={errorMessage}
            closable
            onClose={() => setErrorMessage(null)}
          />
        )}

        <forms.FormBlock>
          <Table
            api='/online/deliveryMethods/'
            waitForFilterLoad={false}
            showPaging={false}
            name={deliveryMethodsTable}
            getHeader={() => (
              <tr className='visible-header'>
                <th>Name</th>
                <th>Price</th>
              </tr>
            )}
            getRow={(deliveryMethod, index) => (
              <DeliveryMethodRow
                deliveryMethod={deliveryMethod}
                index={index}
                onClick={() => {
                  setEditDeliveryMethod(deliveryMethod)
                }}
              />
            )}
            emptyTable={
              <EmptyTableView
                testID='DeliveryMethodEmptyTableTestId'
                showImage
                title="You don't have any delivery methods yet"
                image={DeliveryVehicle}
              >
                <p>Add supported delivery methods for your customers&apos; convenience.</p>
                <p>
                  <FlatButton
                    label='Add Delivery Method'
                    className='blueBackground'
                    onClick={() => {
                      setAddingDeliveryMethod(true)
                    }}
                  />
                </p>
              </EmptyTableView>
            }
          />

          <Spacer size='medium' />
        </forms.FormBlock>
      </MaxWidthBlock>
    </SplitBlock>
  )
}

const mapDispatchToProps = (dispatch) => ({
  refreshDeliveryMethods: () => dispatch(refreshTable(deliveryMethodsTable)),
  deleteDeliveryMethod: (uuid, onSuccess) => dispatch(deleteDeliveryMethod(uuid, onSuccess)),
})

export default connect(null, mapDispatchToProps)(DeliveryMethodsPage)
