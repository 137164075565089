import React from 'react'
import { withRouter } from 'react-router'
import BlockHeader from 'components/headers/BlockHeader'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import PreventBeforeFullyOnboarded from 'containers/onboarding/PreventBeforeFullyOnboarded'
import BusinessSettingsAlert from './components/BusinessSettingsAlert'

import { withOnboarding } from './components/Onboarding'
import { UpdateInvoicesSettings } from './components/Settings'
import useBusinessSettingsAlert from './components/UseBusinessSettingsAlert'

function InvoicesSettingsPage() {
  const [showAlert, onCloseAlert] = useBusinessSettingsAlert()
  return (
    <MaxWidthBlock>
      {showAlert && <BusinessSettingsAlert onClose={onCloseAlert} />}
      <BlockHeader title='Invoice Settings' />
      <div style={{ maxWidth: 540 }}>
        <UpdateInvoicesSettings />
      </div>
    </MaxWidthBlock>
  )
}

const Settings = withRouter(withOnboarding(InvoicesSettingsPage))

const SettingsPage = () => (
  <>
    <PreventBeforeFullyOnboarded />
    <Settings />
  </>
)

export default SettingsPage
