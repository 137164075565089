/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { List } from 'immutable'
import { Icon } from '@yoco/design-system-icons/dist/react'

import TransactionItem from './TransactionItem'
import classes from './transactions.module.scss'

export default class TransactionItemsSummary extends Component {
  checkIfOverflow() {
    window.requestAnimationFrame(() => {
      if (this && this !== undefined) {
        // eslint-disable-next-line react/no-find-dom-node
        const node = ReactDOM.findDOMNode(this)
        if (node !== undefined) {
          if (node.offsetWidth < node.scrollWidth) {
            node.className = `${node.className} overflowed`
          } else {
            node.className = classes.transactionItemSummary
          }
        }
      }
    })
  }

  handleResize() {
    this.checkIfOverflow()
  }

  componentDidMount() {
    if (!this.handleResizeHandler) {
      this.handleResizeHandler = () => {
        this.handleResize()
      }

      window.addEventListener('resize', this.handleResizeHandler, false)
    }

    this.checkIfOverflow()
  }

  componentWillUnmount() {
    if (this.handleResizeHandler) {
      window.removeEventListener('resize', this.handleResizeHandler, false)
      this.handleResizeHandler = null
    }
  }

  renderItems() {
    const items = this.items()

    if (items.isEmpty()) {
      return null
    }

    if (items.count() === 1) {
      if (items.first().get('name') === 'Custom amount') {
        return null
      }

      // If we only have one item, show the name of that item
      return <TransactionItem item={items.first()} />
    }

    return (
      <div className={classes.transactionItemDiv}>
        {items.count()}
        &nbsp;items
      </div>
    )
  }

  getItemPopoverInsides(items) {
    return (
      <div className={classes.itemContainer}>
        {items.map((item) => {
          let note = ''
          if (item.get('note')) {
            note = `(${item.get('note')})`
          }
          return (
            <div key={`popover-${item.get('uuid')}`}>
              {item.get('quantity')}
              &nbsp;x&nbsp;
              {item.get('name') || 'Custom Amount'}
              &nbsp;
              {note}
            </div>
          )
        })}
      </div>
    )
  }

  items() {
    return (
      this.props.transaction.get('billEntries') ||
      this.props.transaction.get('items') ||
      List()
    ).filterNot((item) => item.get('isTip'))
  }

  getNote() {
    if (this.props.transaction && this.props.transaction.hasIn(['details', 'note'])) {
      return (
        <div className={classes.noteContainer}>
          <Icon name='note' size={16} />
          {this.props.transaction.getIn(['details', 'note'])}
        </div>
      )
    }

    return null
  }

  render() {
    return (
      <div className={classes.transactionItemSummary}>
        {this.renderItems()}
        {this.getNote()}
      </div>
    )
  }
}

TransactionItemsSummary.propTypes = {
  transaction: PropTypes.object,
}
