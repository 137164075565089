/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'

import colors from 'ui/colors'
import BaseChip from 'components/chips/BaseChip'
import Tooltip from 'components/Tooltip/Tooltip'

import classes from './referrals.module.scss'

const STATUS_VALUES = {
  Pending: {
    label: 'Signed up',
    color: colors['Brand-Orange'],
  },
  Earned: {
    label: 'Complete',
    color: colors['Brand-Green'],
  },
  Awarded: {
    label: 'Paid',
    color: colors['Brand-Blue'],
  },
  Declined: {
    label: 'Declined',
    color: colors['Red-300'],
  },
}

const SIGNED_UP_STEPS = [
  {
    title: 'Sign Up',
    message: 'Referred status: Complete',
    completedAt: ['Pending', 'Earned'],
  },
  {
    title: 'Machine Purchase',
    message: 'Referred has purchased a YOCO machine',
    completedAt: ['Pending', 'Earned'],
  },
  {
    title: 'R1000 Transaction',
    message: 'Referred has transacted over R1000',
    completedAt: ['Earned'],
  },
]

function StatusChip(props) {
  const getTooltipContent = () => {
    if (props.value === 'Pending') {
      return (
        <div>
          {SIGNED_UP_STEPS.map((step, index) => (
            <div key={`signed-up-step-${index}`}>
              <div className={classes.statusChipTooltipHeader}>
                <h4>{step.title}</h4>
                {step.completedAt.includes(props.value) ? (
                  <p className={classes.completeStep}>Complete</p>
                ) : (
                  <div className={classes.incompleteStep}>
                    <p className={classes.incompleteLabel}>Incomplete</p>
                    <span className={classes.incompleteIndicator} />
                  </div>
                )}
              </div>
              <p>{step.message}</p>
              {index !== SIGNED_UP_STEPS.length - 1 ? <hr /> : null}
            </div>
          ))}
        </div>
      )
    }
    if (props.value === 'Earned') {
      return (
        <div>
          <div className={classes.statusChipTooltipHeader}>
            <h4>Status complete</h4>
          </div>
          <p>
            Referred has completed all steps. Your commission will be paid shortly. This could take
            up to 2 days.
          </p>
        </div>
      )
    }
    return (
      <div className={classes.statusChipTooltipHeader}>
        <h4>No Tooltip data provided</h4>;
      </div>
    )
  }

  return props.hasNotifyIndicator ? (
    <Tooltip
      showOnHover
      hoverClassName={classes.statusChipTooltip}
      hoverContent={getTooltipContent()}
    >
      <BaseChip
        label={STATUS_VALUES[props.value].label}
        color={STATUS_VALUES[props.value].color}
        hasNotifyIndicator={props.hasNotifyIndicator}
      />
    </Tooltip>
  ) : (
    <BaseChip
      label={STATUS_VALUES[props.value].label}
      color={STATUS_VALUES[props.value].color}
      hasNotifyIndicator={props.hasNotifyIndicator}
    />
  )
}

export default StatusChip

StatusChip.propTypes = {
  value: PropTypes.string.isRequired,
  hasNotifyIndicator: PropTypes.bool.isRequired,
}
