import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import classNames from 'classnames'

import BlockHeader from 'components/headers/BlockHeader'
import layout from 'ui/layout/layout.module.scss'
import containers from 'ui/containers/containers.module.scss'
import HStack from 'ui/layout/HStack'
import VStack from 'ui/layout/VStack'
import blocks from 'components/blocks/blocks.module.scss'
import FlatButton from 'components/buttons/FlatButton'
import { callJSONApi } from 'libs/api'
import Alert from 'components/notifications/Alert'
import PaddedContent from 'ui/layout/PaddedContent'
import formClasses from 'libs/forms/forms.module.scss'
import Label from 'ui/components/Label'
import LoadingView from 'components/loaders/LoadingView'

import { withRouter, RouteComponentProps } from 'react-router'
import { cloneDeep, isEqual } from 'lodash'
import { makeTestID } from 'libs/utils'
import HeaderDesignCard from './components/OnlineStoreDesign/HeaderDesignCard'
import { useStore } from './hooks'
import PageConfiguration from './components/OnlineStoreDesign/PageConfiguration'
import OnlineStoreBackButton from './components/OnlineStoreBackButton'
import ConfirmSaveModal from './components/OnlineStoreDesign/ConfirmSaveModal'
import { EmptyObjectType } from './types'

const OnlineStoreDesignPage: React.FunctionComponent<RouteComponentProps<never, never>> = ({
  router,
  route,
}) => {
  const store = useStore()
  const storeConfig = store.config
  const [isSaving, setIsSaving] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [pagePath, setPagePath] = useState('/')
  const [previewUrl, setPreviewUrl] = useState<string>()
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [initialConfig, setInitialConfig] = useState<typeof storeConfig | EmptyObjectType>({})
  const [askToSave, setAskToSave] = useState(false)
  const [showConfirmSaveModal, setShowConfirmSaveModal] = useState(false)
  const [leaveRoute, setLeaveRoute] = useState('')
  const [showChangesSavedAlert, setShowChangesSavedAlert] = useState(false)

  const pageConfig = useMemo(() => storeConfig.pages.find((p) => p.path === pagePath), [
    pagePath,
    storeConfig.pages,
  ])

  // 1. We store a frozen copy of the initial config object returned from core to track local changes
  useEffect(() => {
    if (store.dataFetched && !store.isLoading && isEqual(initialConfig, {})) {
      setInitialConfig(cloneDeep(store.config))
    }
  }, [store, initialConfig, store.isLoading])
  // 2. We do a deep comparison between the frozen initial config object fetched from core and the local changes
  useEffect(() => {
    if (store.dataFetched && !isEqual(store.config, initialConfig)) {
      setAskToSave(true)
    } else {
      setAskToSave(false)
    }
  }, [store.config, initialConfig, store.dataFetched])
  // 3. We need to intercept the navigation if there are unsaved changes and save the route path
  useEffect(() => {
    if (askToSave && leaveRoute === '') {
      router.setRouteLeaveHook(route, (nextLocation) => {
        if (nextLocation) {
          setLeaveRoute(nextLocation.pathname)
        }
        setShowConfirmSaveModal(true)
        return false
      })
    } else {
      router.setRouteLeaveHook(route, () => {
        return null
      })
    }
  }, [router, route, askToSave, leaveRoute])
  const noSaveCallback = useCallback(() => {
    router.push(leaveRoute)
  }, [leaveRoute, router])

  const onChangePage = useCallback(
    (event) => {
      setPagePath(event.target.value)
    },
    [setPagePath]
  )

  const setPageConfig = useMemo(() => {
    return (pageConf) => {
      const pageIndex = storeConfig.pages.findIndex((p) => p.path === pageConf.path)
      const newPages = [
        ...storeConfig.pages.slice(0, pageIndex),
        pageConf,
        ...storeConfig.pages.slice(pageIndex + 1),
      ]
      storeConfig.setPages(newPages)
    }
  }, [storeConfig])

  // Re-post the config when the iframe page changes
  const iframeOnLoad = useCallback(() => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      const json = JSON.stringify(storeConfig)
      iframeRef.current.contentWindow.postMessage(
        { messageType: 'configUpdated', storeConfig: json },
        '*'
      )
    }
  }, [storeConfig])

  useEffect(() => {
    setPreviewUrl(store.url && `${store.url + pagePath}?wysiwyg=true`)
    iframeOnLoad()
  }, [storeConfig, store.url, pagePath, iframeRef, iframeOnLoad])

  const saveConfig = useCallback(() => {
    setIsSaving(true)
    callJSONApi(
      '/store/config',
      'POST',
      storeConfig,
      () => {
        setIsSaving(false)
        setAskToSave(false)
        setInitialConfig(cloneDeep(store.config))
        setShowChangesSavedAlert(true)
        setTimeout(() => {
          setShowChangesSavedAlert(false)
        }, 2000)
      },
      (prettyError) => {
        setIsSaving(false)
        setErrorMessage(prettyError)
        setAskToSave(false)
      }
    )
  }, [store, storeConfig])

  if (store.isLoading) {
    return (
      <PaddedContent>
        <LoadingView />
      </PaddedContent>
    )
  }
  if (store.errorMessage) {
    return (
      <PaddedContent>
        <Alert message={store.errorMessage} messageType='danger' />
      </PaddedContent>
    )
  }
  return (
    <div
      data-testid='OnlineStore-DesignPage'
      className={classNames([
        blocks.paddedBlock,
        layout.flex,
        layout.flexColumn,
        layout.heightFull,
      ])}
    >
      <OnlineStoreBackButton />
      <BlockHeader
        title='Customise Your Store'
        actions={
          <FlatButton
            className='blueBackground'
            label='Save'
            onClick={saveConfig}
            disabled={isSaving}
          />
        }
      />

      {errorMessage && <Alert message={errorMessage} messageType='danger' />}
      {showChangesSavedAlert && (
        <Alert message='Your changes have been saved!' messageType='success' closable={false} />
      )}

      <HStack
        alignItems={layout.itemsStretch}
        className={classNames([layout.widthFull, layout.flexGrow])}
      >
        <VStack
          className={classNames(layout.minWidthFull, layout.smMinWidth0)}
          style={{ flexBasis: '30%', flexGrow: 1 }}
        >
          <HeaderDesignCard
            logoURL={storeConfig.header.logoURL}
            setLogoURL={storeConfig.header.setLogoURL}
            setShowBusinessName={storeConfig.header.setShowBusinessName}
            showBusinessName={storeConfig.header.showBusinessName}
            primaryColor={storeConfig.theme.primaryColor}
            setPrimaryColor={storeConfig.theme.setPrimaryColor}
          />

          <div>
            <Label>Page</Label>
            <select
              data-testid={makeTestID('onlineStore', 'designPage', 'pageSelector')}
              value={pagePath}
              className={formClasses.selectField}
              onChange={onChangePage}
            >
              {storeConfig.pages.map((page) => (
                <option key={page.path} value={page.path}>
                  {page.name ?? page.path}
                </option>
              ))}
            </select>
          </div>

          <PageConfiguration pageConfig={pageConfig} setPageConfig={setPageConfig} />
        </VStack>
        <div className={layout.hideOnMobile} style={{ flexBasis: '70%', backgroundColor: 'white' }}>
          <iframe
            ref={iframeRef}
            title='Online Store'
            id='store-preview-frame'
            src={previewUrl}
            height='100%'
            width='100%'
            onLoad={iframeOnLoad}
            className={classNames([containers.rounded, containers.border, layout.heightFull])}
          />
        </div>
      </HStack>
      <ConfirmSaveModal
        showPopup={showConfirmSaveModal}
        isSaving={false}
        setShowPopup={(value: boolean) => {
          setLeaveRoute('')
          setShowConfirmSaveModal(value)
        }}
        noSaveCallback={noSaveCallback}
      />
    </div>
  )
}

export default withRouter(OnlineStoreDesignPage)
