import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { STATUSES } from './constants'
import s from './style/line.module.scss'

function Line({ prefixClass, percent, className, status, background, trailColor }) {
  const classes = cx(s[`${prefixClass}-line`], className)
  const innerClasses = cx(s[`${prefixClass}-line-inner`], {
    [s[`${prefixClass}-line-inner-status-${status}`]]: !!status,
  })
  const progressStyle = {
    width: `${percent}%`,
    backgroundColor: background,
  }
  const trailStyle = {
    backgroundColor: trailColor,
  }

  return (
    <div className={classes} style={trailStyle}>
      <div className={innerClasses} style={progressStyle} />
    </div>
  )
}

Line.propTypes = {
  prefixClass: PropTypes.string,
  percent: PropTypes.number,
  className: PropTypes.string,
  status: PropTypes.oneOf([STATUSES.ACTIVE, STATUSES.SUCCESS, STATUSES.DEFAULT, STATUSES.ERROR]),
  background: PropTypes.string,
  trailColor: PropTypes.string,
}

export default Line
