import React from 'react'

import CapitalMerchantUseBlock from 'components/capital/partials/CapitalMerchantUseBlock'
import equipment from 'assets/images/equipment.svg'
import store from 'assets/images/new-store.svg'
import renovations from 'assets/images/renovations.svg'
import hiring from 'assets/images/hiring.svg'
import marketing from 'assets/images/marketing.svg'
import SubHeading from 'ui/components/SubHeading'
import styles from './CapitalMerchantUsesBlock.module.scss'
import CapitalLetMeKnowBlock from '../CapitalLetMeKnowBlock'

interface Props {
  optedIn: boolean
  onOptInPress: () => void
  fetchingData: boolean
}

const CapitalMerchantUsesBlock: React.FunctionComponent<Props> = ({
  optedIn,
  onOptInPress,
  fetchingData,
}) => {
  return (
    <div className={styles.container} data-testid='CapitalMerchantUsesBlock'>
      <CapitalLetMeKnowBlock
        optedIn={optedIn}
        onOptInPress={onOptInPress}
        fetchingData={fetchingData}
      />
      <SubHeading>How some of our merchants use their Yoco Capital:</SubHeading>
      <div className={styles.iconsWithDescriptionsContainer}>
        <CapitalMerchantUseBlock image={equipment} description='New stock and equipment' />
        <CapitalMerchantUseBlock image={store} description='New stores or expansion' />
        <CapitalMerchantUseBlock image={renovations} description='Renovations or upgrades' />
        <CapitalMerchantUseBlock image={hiring} description='Staff training and hiring' />
        <CapitalMerchantUseBlock image={marketing} description='Marketing campaign' />
      </div>
    </div>
  )
}

export default CapitalMerchantUsesBlock
