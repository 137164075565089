/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import * as forms from 'libs/forms'
import FormCard from 'components/cards/FormCard'
import IconSelectorField from 'components/icons/IconSelectorField'
import { refreshTable } from 'redux/modules/tables'

class AddCustomPaymentMethodForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showDeleteModal: false,
    }
  }

  onSuccess() {
    this.props.dispatch(refreshTable(this.props.formName))
    this.props.closeSplitView && this.props.closeSplitView()
  }

  processData(data) {
    return data.set('icon', data.getIn(['config', 'icon']))
  }

  render() {
    const { shownRow } = this.props
    const api = shownRow
      ? `/customPaymentMethods/${shownRow.get('uuid')}/`
      : '/customPaymentMethods/'
    return (
      <forms.Form
        action={api}
        initialData={shownRow}
        name={this.props.formName}
        onSuccess={() => this.onSuccess()}
        dataProcessor={(data) => this.processData(data)}
      >
        <FormCard header='Payment Method'>
          <IconSelectorField
            name='config.icon'
            label='Icon'
            isFullWidth
            icons={['qr-code', 'letter-1', 'coins-r', 'cashnotes', 'check', 'present']}
            validators={[new forms.RequiredValidator('Please select an icon')]}
          />
          <forms.TextField
            name='name'
            label='Name'
            placeholder='eg Snapscan'
            isFullWidth
            validators={[new forms.RequiredValidator('You must provide a payment method name')]}
          />
        </FormCard>
      </forms.Form>
    )
  }
}

AddCustomPaymentMethodForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  shownRow: PropTypes.object,
  closeSplitView: PropTypes.func,
  formName: PropTypes.string,
}

export default connect()(AddCustomPaymentMethodForm)
