import Immutable from 'immutable'

import { callJSONApi } from 'libs/api'

import ActionTypes from './actionTypes'

export function fetchSettlementSummary() {
  return (dispatch) => {
    callJSONApi(
      '/payouts/summary/',
      'GET',
      {},
      (response) => {
        if (response.status === 200 && response.data) {
          dispatch(setSettlementSummary(Immutable.fromJS(response.data)))
        } else {
          dispatch(dispatch(setFetchSettlementError(response.message)))
        }
      },
      (prettyError) => {
        dispatch(setFetchSettlementError(prettyError))
      }
    )
  }
}

export function setSettlementSummary(payload) {
  return {
    type: ActionTypes.FETCH_SETTLEMENT_SUMMARY_SUCCESS,
    payload,
  }
}

export function setFetchSettlementError(error) {
  return {
    type: ActionTypes.FETCH_SETTLEMENT_SUMMARY_FAILURE,
    error,
  }
}
