import { dueInSelectOptions as baseDueInSelectOptions } from '../constants'

export const FormNames = {
  Create: 'createInvoiceSettings',
  Update: 'updateInvoiceSettings',
}

export const FormFieldNames = {
  LogoUri: 'logoUri',
  BusinessName: 'businessName',
  Email: 'email',
  Phone: 'phone',
  VAT: 'vatNumber',
  PhysicalAddress: 'physicalAddress',
  InitialInvoiceNumber: 'initialInvoiceNumber',
  IncludeEFTDetails: 'includeEFTDetails',
  DueIn: 'dueIn',
  Terms: 'terms',
}

export const dueInSelectOptions = [
  {
    label: 'None',
    value: '',
  },
].concat(baseDueInSelectOptions)
