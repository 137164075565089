import React from 'react'
import PropTypes from 'prop-types'

import { PopupComponent } from 'libs/modals'

import PopupFailureView from './Views/Failure'

class FailurePopup extends PopupComponent {
  getContent() {
    return (
      <PopupFailureView
        title={this.props.title}
        subtitle={this.props.subtitle}
        buttonLabel='OK'
        onDismiss={this.props.onDismiss}
      />
    )
  }
}

FailurePopup.defaultProps = {
  showing: true,
  title: 'Failed',
}

FailurePopup.propTypes = {
  showing: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onDismiss: PropTypes.func,
}

export default FailurePopup
