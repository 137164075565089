import React from 'react'
import PropTypes from 'prop-types'

import FileUpload from 'components/files/FileUpload'
import classes from 'components/files/files.module.scss'

import { calculateImageSize } from './utils'

export default class ItemImageUpload extends FileUpload {
  formGetter() {
    const data = new FormData(document.getElementById('fileUploadForm'))
    data.append('file', this.state.blob)
    return data
  }

  customFormRenderer(onSubmit) {
    const style = this.props.style ? this.props.style : {}
    if (this.props.fullCover) {
      return (
        <div className={classes.fullCover}>
          <form id='fileUploadForm' />
          <input
            className={classes.fullCoverinputFileUpload}
            type='file'
            name='file'
            style={style}
            id='exampleInputFile'
            accept='image/*'
            onChange={(event) => {
              this.resize(event, onSubmit)
            }}
          />
        </div>
      )
    }
    if (this.props.isTile) {
      return (
        <div className={classes.option}>
          <form id='fileUploadForm' />
          <input
            className={classes.inputFileUpload}
            type='file'
            name='file'
            style={style}
            id='exampleInputFile'
            accept='image/*'
            onChange={(event) => {
              this.resize(event, onSubmit)
            }}
          />
        </div>
      )
    }
    return (
      <div>
        <form id='fileUploadForm' />
        <input
          className={classes.fileUploadInput}
          type='file'
          name='file'
          style={style}
          id='fileUploadInputFile'
          accept='image/*'
          onChange={(event) => {
            this.resize(event, onSubmit)
          }}
        />
      </div>
    )
  }

  customProgressRenderer(progress, hasError) {
    if (!this.state.fileUploaded && (hasError || progress > -1)) {
      const barStyle = { ...classes.progressBar }
      barStyle.width = `${progress}%`
      let message = (
        <span>
          Uploading
          {barStyle.width}
        </span>
      )
      if (progress === 100) {
        message = <span>Done</span>
      }
      if (hasError) {
        barStyle.backgroundColor = '#d9534f'
        message = <span style={{ color: '#a94442' }}>Failed to upload ...</span>
      }
      return (
        <div className={this.props.loaderStyle}>
          <div>
            <div className={classes.progressWrapper} style={barStyle} />
          </div>
          <div className={classes.messageAndCancel}>{message}</div>
        </div>
      )
    }
    return <span>{this.props.noLoadingView}</span>
  }

  dataURLToBlob(dataURL) {
    const BASE64_MARKER = ';base64,'
    if (dataURL.indexOf(BASE64_MARKER) === -1) {
      const parts = dataURL.split(',')
      const contentType = parts[0].split(':')[1]
      const raw = parts[1]

      return new Blob([raw], { type: contentType })
    }

    const parts = dataURL.split(BASE64_MARKER)
    const contentType = parts[0].split(':')[1]
    const raw = window.atob(parts[1])
    const rawLength = raw.length

    const uInt8Array = new Uint8Array(rawLength)

    for (let i = 0; i < rawLength; i += 1) {
      uInt8Array[i] = raw.charCodeAt(i)
    }

    return new Blob([uInt8Array], { type: contentType })
  }

  resize(event, onSubmit) {
    const file = event.target.files[0]
    // Ensure it's an image
    if (file && file.type.match(/image.*/)) {
      // Load the image
      const reader = new FileReader()
      reader.onload = (readerEvent) => {
        const image = new Image()
        image.onload = () => {
          // Resize the image
          const canvas = document.createElement('canvas')
          const optimalImageSize = calculateImageSize(
            image,
            this.props.maxHeight,
            this.props.shouldSquareAndCenter
          )
          canvas.width = optimalImageSize.destinationWidth
          canvas.height = optimalImageSize.destinationHeight
          canvas
            .getContext('2d')
            .drawImage(
              image,
              optimalImageSize.sourceX,
              optimalImageSize.sourceY,
              optimalImageSize.sourceWidth,
              optimalImageSize.sourceHeight,
              optimalImageSize.destinationX,
              optimalImageSize.destinationY,
              optimalImageSize.destinationWidth,
              optimalImageSize.destinationHeight
            )
          const dataUrl = canvas.toDataURL(this.props.format, this.props.encoderOptions)
          const resizedImage = this.dataURLToBlob(dataUrl)

          this.setState({
            blob: resizedImage,
            url: dataUrl,
          })

          onSubmit(event)
        }
        image.src = readerEvent.target.result
      }
      reader.readAsDataURL(file)
    } else {
      onSubmit(event)
    }
  }
}

ItemImageUpload.defaultProps = {
  maxHeight: 256,
  shouldSquareAndCenter: true,
  format: 'image/jpeg',
  encoderOptions: 0.8,
  documentType: 'image/jpeg',
}

ItemImageUpload.propTypes = {
  maxHeight: PropTypes.number,
  shouldSquareAndCenter: PropTypes.bool,
  format: PropTypes.string,
  encoderOptions: PropTypes.number,
  grouping: PropTypes.string,
  documentType: PropTypes.string,
}
