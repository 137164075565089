import keyMirror from 'libs/key-mirror'

export default keyMirror(
  {
    FETCH_FACTS_REQUEST: null,
    FETCH_FACTS_SUCCESS: null,
    FETCH_FACTS_FAILURE: null,
    FETCH_FACTS_FULFILLED: null,
  },
  '@dashboard/'
)
