export const getShouldTableUpdate = (
  nextProps,
  props,
  nextSortParamsString,
  currSortParamsString,
  filtersFinishedInitializing
) => {
  const hasSortParamStringChanged = nextSortParamsString !== currSortParamsString
  const hasFiltersUpdated = !props.waitForFilterLoad || filtersFinishedInitializing

  const hasShouldRefreshChanged =
    !!nextProps.table.get('shouldRefresh') !== !!props.table.get('shouldRefresh')
  const hasApiUpdated = props.api !== nextProps.api

  const propsSearchValueAsString = (props.batch.getIn(['search', 'value']) || '').trim()
  const nextPropsSearchValueAsString = (nextProps.batch.getIn(['search', 'value']) || '').trim()

  const hasSearchValueChanged = propsSearchValueAsString !== nextPropsSearchValueAsString

  const sortAndFilterChanged = hasSortParamStringChanged && hasFiltersUpdated

  return sortAndFilterChanged || hasShouldRefreshChanged || hasApiUpdated || hasSearchValueChanged
}
