/* eslint-disable no-param-reassign */
import { fromJS, Map } from 'immutable'

export const derivePrice = (productPrice, variantPrice) => variantPrice ?? productPrice

// exporting this for testing purposes only
export const taxCalculation = (item, variant, defaultTax) => {
  const price = derivePrice(item.defaultPrice, variant.price)
  if (item.appliedSalesTaxes && item.appliedSalesTaxes.length > 0) {
    return item.appliedSalesTaxes.map((salesTax) => ({
      salesTaxUUID: salesTax.salesTaxUUID,
      taxName: salesTax.taxName,
      taxAmount: price * salesTax.salesTax.taxPercentageOfTotal,
      taxPercentageOfTotal: salesTax.salesTax.taxPercentageOfTotal,
    }))
  }

  if (!defaultTax) {
    return []
  }
  const options = (defaultTax || new Map())
    .filter((salesTax) => salesTax.get('isEnabledByDefault'))
    .toArray()
    .map((salesTax) => {
      if (salesTax.get('isEnabledByDefault')) {
        const taxName = salesTax.get('taxName')
        const salesTaxUUID = salesTax.get('uuid')
        const taxPercentageOfTotal = salesTax.get('taxPercentageOfTotal')
        return {
          taxName,
          salesTaxUUID,
          taxPercentageOfTotal,
        }
      }
      return {}
    })
  return [
    {
      salesTaxUUID: options[0].salesTaxUUID,
      taxName: options[0].taxName,
      taxAmount: price * options[0].taxPercentageOfTotal,
      taxPercentageOfTotal: options[0].taxPercentageOfTotal,
    },
  ]
}

export const productToInvoiceEntry = (product, selectedVariant, defaultTax) => {
  // If no variant selected, get the first one possible, or create based on the product
  if (!selectedVariant) {
    selectedVariant =
      Array.isArray(product.variants) && product.variants.length
        ? product.variants.filter((variant) => variant.enabled)[0]
        : {
            uuid: `${product.uuid}-${Date.now().toString()}`,
            productUUID: product.uuid,
            skuUUID: product.skuUUID,
            tileUUID: product.tileUUID,
            choiceGroups: [],
            choiceValues: [],
            enabled: true,
          }
  }

  return fromJS({
    clientBillEntryIdentifier: `${product.uuid}-${Date.now().toString()}`,
    discount: 0,
    meta: selectedVariant.choiceValues?.length
      ? {
          variantChoices: selectedVariant.choiceValues,
        }
      : {},
    salesTaxes: taxCalculation(product, selectedVariant, defaultTax),
    price: selectedVariant.price || product.defaultPrice,
    quantity: 1,
    tile: product.tile,
    variantUUID: selectedVariant.uuid,
    variantChoiceValues: selectedVariant.choiceValues || [],
  })
}
