/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable max-classes-per-file */
import React, { Component } from 'react'
import { Map } from 'immutable'
import { Icon } from '@yoco/design-system-icons/dist/react'
import { connect } from 'react-redux'
import S from 'string'

import * as Forms from 'redux/modules/forms'
import { callJSONApi } from 'libs/api'
import { makeTestID, sanitiseData, scrollToTop, isFlagship } from 'libs/utils'
import LoadingView from 'components/loaders/LoadingView'
import Alert from 'components/notifications/Alert'
import TooltipButton from 'components/Tooltip/Tooltip'

import classes from './forms.module.scss'

function showLoader() {
  console.log('showLoader')
}

function hideLoader() {
  console.log('hideLoader')
}

class UnconnectedForm extends Component {
  constructor(props) {
    super(props)
    this.fields = []
    this.state = {}
  }

  fieldAdded(field) {
    this.fields.push(field)
  }

  fieldRemoved(field) {
    this.fields = this.fields.filter((e) => e !== field)
  }

  isFormField(component) {
    if (
      component === undefined ||
      component === null ||
      component.type === undefined ||
      typeof component.type !== 'function'
    ) {
      return false
    }

    if (navigator.appVersion.indexOf('MSIE 10') !== -1) {
      // IE 10 fails at this. This is a pretty bad way of detecting a form field
      // but I can't really find a better way

      // const p = Object.getPrototypeOf(component);
      // had to remove p._isReactElement because it returned undefined when it was
      // Had to stop using this because of minified code

      // if (p && component.type.toString().indexOf('Field') !== -1) {
      if (component.props && component.props.name) {
        return true
      }
    } else {
      if (component.type === undefined || typeof component.type !== 'function') {
        return false
      }

      let t = component.type
      while (t !== null) {
        t = Object.getPrototypeOf(t)

        if (t === FormField) {
          return true
        }
      }

      return false
    }

    return false
  }

  attachField(field) {
    let value
    let validationErrors = []
    const { form } = this.props

    if (form && field && field.props && field.props.name) {
      const namePath = field.props.name.split('.')
      namePath.unshift('data')
      value = form.getIn(namePath)
    }

    if (form && form.get('validationErrors') && field.props.name) {
      validationErrors = form.get('validationErrors').get(field.props.name)
    }

    const fieldProps = Map(field.props)
      .set('form', this)
      .set('value', value)
      .set('validationErrors', validationErrors)
      .set(
        'errorBelowInput',
        field.props.errorBelowInput ? field.props.errorBelowInput : this.props.errorBelowInput
      )
      .toObject()

    const rxElement = React.cloneElement(field, fieldProps)
    return rxElement
  }

  attachFields(parent) {
    if (parent && parent.props) {
      return React.cloneElement(parent, {
        children: React.Children.map(parent.props.children, (field) => {
          if (this.isFormField(field)) {
            return this.attachField(field)
          }

          if (field) {
            // If this is not a form field, we should check for nested fields
            return this.attachFields(field)
          }
          return null
        }),
      })
    }
    return parent
  }

  onFieldChange(fieldName, value) {
    this.props.dispatch(Forms.fieldUpdated(this.props.name, fieldName, value))

    if (this.props.onChange) {
      this.props.onChange(fieldName, value)
    }
  }

  renderFields() {
    return this.attachFields(<div>{this.props.children}</div>)
  }

  renderHeader() {
    if (this.props.showHeader) {
      const errorMessage =
        this.props.form.get('errorMessage') || this.props.form.get('transientErrorMessage')
      if (errorMessage) {
        return (
          <Alert
            messageType='danger'
            message={errorMessage}
            onClose={() => this.props.dispatch(Forms.clearErrorMessage(this.props.name))}
          />
        )
      }
      if (this.props.form.get('successMessage')) {
        return (
          <Alert
            messageType='success'
            message={this.props.form.get('successMessage')}
            onClose={() => this.props.dispatch(Forms.clearSuccessMessage(this.props.name))}
            testID={makeTestID('alert')}
          />
        )
      }
    }
    return null
  }

  renderProgress() {
    // eslint-disable-next-line no-mixed-operators
    if (
      (this.props.showLoader && this.props.form.get('transientIsSubmitting')) ||
      this.props.form.get('isLoading')
    ) {
      return <LoadingView className={this.props.loaderClass || classes.formLoader} />
    }
    return null
  }

  render() {
    let formClassname = classes.form
    if (this.props.noWrap) {
      formClassname += ` ${classes.noWrap}`
    }

    const wrapperStyle = this.props.loaderClass ? {} : { position: 'relative' }

    return (
      <div className={classes.formWrapper} style={wrapperStyle}>
        {this.renderHeader()}
        <form className={formClassname} onSubmit={this.submit.bind(this)}>
          {this.renderFields()}
        </form>
        {this.renderProgress()}
      </div>
    )
  }

  validate(form) {
    if (!form) {
      form = this.props.form
    }
    const validationErrors = {}
    const formData = form.get('data', Map())

    // eslint-disable-next-line no-restricted-syntax
    for (const field of this.fields) {
      const fieldErrors = field.validate(formData)

      if (fieldErrors) {
        if (Array.isArray(fieldErrors)) {
          if (fieldErrors.length > 0) {
            validationErrors[field.props.name] = fieldErrors
          }
        } else {
          // Allow complex objects to set validation errors for other keys
          Object.assign(validationErrors, fieldErrors)
        }
      }
    }

    let hasValidationErrors = Object.keys(validationErrors).length > 0
    let errorMessage = hasValidationErrors
      ? 'Something you entered was not right, please check the error messages below'
      : null

    if (this.props.extraFormValidator) {
      const extraErrorMessage = this.props.extraFormValidator(this)

      if (extraErrorMessage) {
        errorMessage = extraErrorMessage
        hasValidationErrors = true
      }
    }
    this.props.dispatch(Forms.showValidationErrors(this.props.name, validationErrors, errorMessage))

    if (!hasValidationErrors && this.props.onValidated) {
      this.props.onValidated()
    }

    return !hasValidationErrors
  }

  data() {
    const data = this.props.form.get('data')
    if (this.props.dataProcessor) {
      return this.props.dataProcessor(data)
    }

    return data
  }

  dispatchActions(response) {
    if (this.props.dispatchActions) {
      let actions = null
      if (Array.isArray(this.props.dispatchActions)) {
        actions = this.props.dispatchActions
      } else {
        actions = [this.props.dispatchActions]
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const action of actions) {
        this.props.dispatch({
          type: action,
          response,
        })
      }
    }
  }

  submit(event) {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
      if (this.props.scrollToTop) {
        scrollToTop(['outerBlock', 'splitView', 'splitViewContent'])
      }
    }
    // The form is valid
    if (this.validate()) {
      this.props.dispatch(Forms.startedSubmit(this.props.name))

      if (this.props.action) {
        if (!this.props.progressFillBounds) {
          showLoader()
        }

        const data = this.data()
        console.log('Post form:', sanitiseData(data).toJS())
        this.props.onBeforeSubmit && this.props.onBeforeSubmit()

        callJSONApi(
          this.props.action,
          'POST',
          data,
          (response) => {
            this.props.dispatch(Forms.submitResult(this.props.name, response))
            hideLoader()

            if (response.status === 200) {
              if (!this.props.persistData) {
                this.props.dispatch(Forms.clearForm(this.props.name))
              }
              if (this.props.onSuccess) {
                this.props.onSuccess(response)
              }
              if (this.props.showSuccess) {
                this.props.dispatch(Forms.showSuccess(this.props.name, response))
              }
            }
            if (this.props.dispatchActions) {
              this.dispatchActions(response)
            }
          },
          (prettyError, jqXHR) => {
            this.props.dispatch(Forms.submitResult(this.props.name, jqXHR.responseJSON))
            this.dispatchActions(jqXHR.responseJSON)

            if (this.props.onFail && jqXHR.responseJSON) {
              this.props.onFail(jqXHR.responseJSON)
            }
          }
        )
        this.props.onAfterSubmit && this.props.onAfterSubmit()
      } else if (this.props.onSubmit) {
        this.props.onBeforeSubmit && this.props.onBeforeSubmit()
        this.props.onSubmit(this.data())
        this.props.onAfterSubmit && this.props.onAfterSubmit()
      }
    } else {
      this.props.onValidationError && this.props.onValidationError()
    }
  }

  fetchData() {
    if (this.props && this.props.fetchAPI) {
      this.props.dispatch(Forms.dataLoading(this.props.name))
      if (!this.props.progressFillBounds) {
        showLoader('')
      }

      callJSONApi(
        this.props.fetchAPI || this.props.action,
        'GET',
        {},
        (response) => {
          this.props.dispatch(Forms.dataFetched(this.props.name, response, this.props.dataPath))
          hideLoader()
          if (this.props.dispatchActions) {
            this.dispatchActions(response)
          }
          if (this.props.callback) {
            this.props.callback(response)
          }
        },
        (error, response) => {
          console.log('err', error, response)
        },
        true
      )
    }
  }

  initializeData(incomingProps) {
    const props = incomingProps || this.props

    let data = props.initialData
    if (props.initialDataProcessor) {
      data = props.initialDataProcessor(data || Map())
    }

    props.dispatch(
      Forms.dataFetched(props.name, { status: 200, data: { data: (data || Map()).toJS() } })
    )
  }

  componentDidMount() {
    if (this.props.form.get('shouldValidateOnFieldChange')) {
      this.validate()
    }

    if (this.props.initialData || this.props.initialDataProcessor) {
      this.initializeData()
    } else {
      // See if we need to fetch data
      this.fetchData()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { form } = nextProps
    const oldForm = this.props.form

    if (form) {
      if (form.get('transientShouldSubmit') && !form.get('transientIsSubmitting')) {
        this.submit()
      } else if (form.get('transientShouldValidate')) {
        this.validate(form)
      } else if (
        form.get('data') !== oldForm.get('data') &&
        form.get('shouldValidateOnFieldChange')
      ) {
        this.validate(form)
      }
    }

    if (
      nextProps.initialData !== this.props.initialData ||
      (form.get('formJustCleared') && (nextProps.initialData || nextProps.initialDataProcessor))
    ) {
      this.initializeData(nextProps)
    }
  }
}

UnconnectedForm.defaultProps = {
  showLoader: true,
  showHeader: true,
  scrollToTop: true,
}

export class FormField extends Component {
  constructor(props) {
    super(props)

    this.state = {
      focused: false,
    }
  }

  onFocus() {
    this.setState({
      focused: true,
    })
  }

  onBlur() {
    this.setState({
      focused: false,
    })
  }

  onChange(event) {
    if (this.props.form) {
      this.props.form.onFieldChange(this.props.name, event.target.value)
    }

    if (this.props.onChange) {
      this.props.onChange(event)
    }
  }

  componentDidMount() {
    if (this.props.form) {
      this.props.form.fieldAdded(this)
      if (!this.props.value && this.props.defaultValue !== undefined) {
        this.props.form.onFieldChange(this.props.name, this.props.defaultValue)
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // If our defaultValue changed we should set it
    if (this.props.defaultValue !== nextProps.defaultValue && nextProps.form && !nextProps.value) {
      this.props.form.onFieldChange(nextProps.name, nextProps.defaultValue)
    }
  }

  componentWillUnmount() {
    if (this.props.form) {
      this.props.form.fieldRemoved(this)
    }
  }

  validate(formData) {
    const validationErrors = []

    if (this.props.validators) {
      // eslint-disable-next-line no-restricted-syntax
      for (const validator of this.props.validators) {
        const message = validator.validate(formData.getIn(this.props.name.split('.')), formData)
        if (message) {
          validationErrors.push(message)
        }
      }
    }

    if (this.props.required && !this.props.value) {
      validationErrors.push('This field is required')
    }

    return validationErrors
  }

  hasErrors() {
    return this.props.validationErrors && this.props.validationErrors.size > 0
  }

  renderHelpText() {
    if (this.hasErrors()) {
      return (
        <div className={classes.helpText}>
          <Icon name='alert-error' size={16} />
          {this.props.validationErrors.get(0)}
        </div>
      )
    }

    return undefined
  }

  renderRequired() {
    if (this.props.required && !this.hasErrors()) {
      return <span className={classes.required}> Required </span>
    }

    return undefined
  }

  renderTooltip() {
    if (this.props.tooltip && !this.hasErrors()) {
      return (
        <div className={classes.tooltip}>
          <TooltipButton
            hoverClassName={this.props.tooltipClassName}
            hoverContent={<div className={classes.tooltipContent}>{this.props.tooltip}</div>}
          >
            <div className={classes.tooltipContainer}>
              <div className={classes.tooltipQuestionMark} />
            </div>
          </TooltipButton>
        </div>
      )
    }

    return undefined
  }

  renderDescription() {
    if (this.props.description) {
      return <div className={classes.description}>{this.props.description}</div>
    }

    return undefined
  }

  getDivClassname() {
    let className = classes.field
    if (this.hasErrors()) {
      className = `${className} ${classes.fieldError}`
    }
    if (this.props.className) {
      className = `${className} ${this.props.className}`
    }
    if (this.state && this.state.focused) {
      className = `${className} ${classes.active}`
    }
    if (this.props.isFullWidth) {
      className = `${className} ${classes.isFullWidth}`
    }
    if (this.props.isHalfWidth) {
      className = `${className} ${classes.isHalfWidth}`
    }
    if (this.props.disabled) {
      className = `${className} ${classes.isDisabled}`
    }
    return className
  }

  getStyle() {
    return this.props.style
  }

  render() {
    return (
      <div className={this.getDivClassname()} style={this.getStyle()}>
        {this.props.errorBelowInput ? undefined : this.renderHelpText()}
        <div className={isFlagship ? classes.flagshipFieldLabel : classes.fieldLabel}>
          {this.props.label || S(this.props.name).humanize().s}
          {this.renderTooltip()}
          {this.renderRequired()}
        </div>
        {this.renderInput()}
        {this.renderDescription()}
        {this.props.errorBelowInput ? this.renderHelpText() : undefined}
      </div>
    )
  }

  renderInput() {}
}

/*
 So our general form will receive props for all forms, we should score the
 props to only state for this form
 */
class ScopedForm extends Component {
  form() {
    let form = Map()
    if (this.props.forms && this.props.forms.get(this.props.name)) {
      form = this.props.forms.get(this.props.name)
    }
    return form
  }

  render() {
    return <UnconnectedForm form={this.form()} {...this.props} />
  }
}

export const Form = connect((state) => ({
  forms: state.forms,
}))(ScopedForm)

// Validators

export class Validator {
  validate() {
    return 'Undefined validator'
  }
}
