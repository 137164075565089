import IconsPage from './IconsPage'
import ListPage from './ListPage'

export default function devRoutes() {
  return {
    path: 'dev',
    text: `Dev`,
    icon: 'tips',
    routes: [
      {
        path: 'icons',
        text: 'Icons',
        component: IconsPage,
      },
      {
        path: 'list',
        text: 'List',
        component: ListPage,
      },
    ],
  }
}
