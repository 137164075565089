/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import FormCard from 'components/cards/FormCard'

import classes from './PreviewIframe.module.scss'

// In order for the invoice to display visually correct it has to be 800px wide
const iframeMinWidth = 850

// The splitblock container is 600px wide so iframe needs to be scaled down.
const splitBlockWidth = 650
const splitBlockPaddingHoriz = 50
const formCardMaxWidth = 600

// Mobile breakpoint is as 735px (known from scss variables)
const mobileWidth = 735

// Scaling of the iframe needs to account for padding in card and splitblock containers
const cardPadding = 32
const iframePadding = 48

function InvoicePreviewIframe({ srcDoc }) {
  const iframeRef = useRef()
  const [iframeHeight, setIframeHeight] = useState(0)
  const [frameScale, setFrameScale] = useState(1.0)

  const calculateFrameScale = useCallback(() => {
    const viewPortWidth = window.innerWidth
    setFrameScale(
      // Mobile breakpoint is as 735px , so scaling on mobile is relative to the screenWidth
      viewPortWidth > mobileWidth
        ? (splitBlockWidth - splitBlockPaddingHoriz - cardPadding) / iframeMinWidth
        : (viewPortWidth - splitBlockPaddingHoriz - cardPadding) / iframeMinWidth
    )
  }, [setFrameScale])

  useEffect(() => {
    window.addEventListener('resize', calculateFrameScale)
    return () => {
      window.removeEventListener('resize', calculateFrameScale)
    }
  }, [calculateFrameScale])

  const onLoadHandler = useCallback(() => {
    // Reset iframe body padding
    iframeRef.current.contentWindow.document.body.style.padding = '0'

    const contentHeight = iframeRef.current.contentWindow.document.body.scrollHeight
    setIframeHeight(contentHeight + iframePadding)
    calculateFrameScale()
  }, [iframeRef, calculateFrameScale])

  const formCardMaxHeight = iframeHeight * frameScale + cardPadding
  const formCardMinWidth = iframeMinWidth * frameScale + cardPadding

  return (
    <FormCard
      style={{
        maxHeight: formCardMaxHeight,
        height: `100%`,
        minWidth: formCardMinWidth,
        maxWidth: formCardMaxWidth,
        overflow: 'hidden',
      }}
    >
      <iframe
        name='invoicePreviewIframe'
        style={{ transform: `scale(${frameScale})`, minWidth: iframeMinWidth }}
        className={classes.invoicePreviewIframe}
        ref={iframeRef}
        srcDoc={srcDoc}
        frameBorder={0}
        scrolling='no'
        width='100%'
        height={iframeHeight}
        onLoad={onLoadHandler}
      />
    </FormCard>
  )
}

InvoicePreviewIframe.propTypes = {
  srcDoc: PropTypes.string.isRequired,
}

export default InvoicePreviewIframe
