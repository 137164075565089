export const labelType = {
  NEW: 'New',
  BETA: 'Beta',
  REFERRAL: 'Earn R500',
  NOTICE: 'Notice',
}

export const labels = {
  [labelType.REFERRAL]: { cssClass: 'referralLabel', text: labelType.REFERRAL },
  [labelType.NEW]: { cssClass: 'newLabel', text: labelType.NEW },
  [labelType.BETA]: { cssClass: 'betaLabel', text: labelType.BETA },
  [labelType.NOTICE]: { cssClass: 'noticeLabel', text: labelType.NOTICE },
}
