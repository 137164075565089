import React from 'react'
import PropTypes from 'prop-types'

import { Label } from 'libs/forms'

import classes from './styles.module.scss'

function PlacesInputField({
  label,
  placeholder,
  updateFirstLine,
  updatePlacePredictions,
  firstLine,
  isRequired,
  onFocus,
  onBlur,
}) {
  return (
    <div className={classes.placesInputFieldContainer}>
      <div className={classes.placesLabelContainer}>
        <Label>
          {label}
          <span className={classes.required}>Required</span>
        </Label>
      </div>

      <div>
        <input
          id='places'
          name='places'
          type='text'
          placeholder={placeholder}
          onChange={(event) => {
            if (updateFirstLine) {
              updatePlacePredictions()
              updateFirstLine(event.target.value)
            }
          }}
          value={firstLine}
          required={isRequired}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
    </div>
  )
}

PlacesInputField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  firstLine: PropTypes.string,
  updateFirstLine: PropTypes.func,
  updatePlacePredictions: PropTypes.func,
  isRequired: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
}

export default PlacesInputField
