/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import d3 from 'd3'
import nv from 'nvd3'
import $ from 'jquery'

import Spinner from 'components/loaders/Spinner'
import { callJSONApi } from 'libs/api'

import classes from './graphs.module.scss'

export default class BaseGraph extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    this.fetchData()
    this.updateGraphHandler = () => {
      this.handleUpdate()
    }

    setTimeout(this.updateGraphHandler, 1)

    window.addEventListener('resize', this.updateGraphHandler, false)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.url !== this.props.url) {
      this.fetchData(nextProps.url)
    }
    if (nextProps.isVisible && !this.props.isVisible) {
      this.handleUpdate(true)
    }
  }

  componentWillUnmount() {
    // Clear the SVG that this chart made.
    // eslint-disable-next-line
    d3.select(this.refs.svg).selectAll('*').remove()

    // Remove the tooltip
    // all graphs seem to share the same tooltip div that is placed directly on body.
    // This could cause problems if we are displaying multiple graphs,
    // and removing one before removing all the others.
    // I can't really think of a better way to do this though.
    $('.nvtooltip').remove()

    // Remove it's window resize listener.
    window.removeEventListener('resize', this.updateGraphHandler, false)
    this.testFitsHandler = null

    this.chart = undefined
  }

  handleTimeout() {
    this.updateLabels()
  }

  handleUpdate(force = false) {
    // We have to call update twice to get the Y-Axis label to display
    // correctly
    if (!this.state.loading && this.chart && (this.props.isVisible || force)) {
      this.chart.update()
      this.updateLabels()
      this.chart.update()
    }
  }

  getType() {
    return null
  }

  getChart() {
    return null
  }

  getBaseMargins() {
    if (this.props.margin) {
      return this.props.margin
    }
    return {
      top: 10,
      right: 15,
      bottom: 45,
      left: 80,
    }
  }

  getAxisFrame() {}

  getChartCall(data, chart) {
    // eslint-disable-next-line
    d3.select(this.refs.svg).datum(data).transition().duration(350).call(chart)
  }

  addChartOptions() {}

  addChartLabels(chart, margin) {
    if (this.props.xAxisLabel) {
      chart.xAxis.axisLabel(this.props.xAxisLabel)
      // eslint-disable-next-line
      margin.bottom += 15
    }

    if (this.props.yAxisLabel) {
      chart.yAxis.axisLabelDistance(-5)
      chart.yAxis.axisLabel(this.props.yAxisLabel)
      // eslint-disable-next-line
      margin.left += 15
    }

    chart.margin(margin)
  }

  noDataReturned() {
    return null
  }

  generateChart(data) {
    this.chart = this.getChart(data)
    if (this.chart) {
      this.chart.height(this.props.height)
    }
    this.addChartOptions(this.chart, data)
    this.addChartLabels(this.chart, this.getBaseMargins())

    this.getChartCall(data, this.chart)

    this.updateLabels()
    this.postLoadData(this.chart)

    if (this.chart) {
      this.setState({
        loading: false,
      })
      this.handleUpdate()
    }
    return this.chart
  }

  updateLabels() {}

  formatData(data) {
    return data
  }

  graphDescription() {}

  fetchData(url) {
    if (!url) {
      // eslint-disable-next-line
      url = this.props.url
    }

    if (url) {
      callJSONApi(
        url,
        'GET',
        undefined,
        (response) => {
          if (response.data.data) {
            nv.addGraph(() => this.generateChart(this.formatData(response.data.data) || []))
          } else {
            this.setState({
              loading: false,
            })
          }
        },
        (prettyError) => {
          console.log(`Failed to fetch: ${prettyError}`)
          this.setState({
            loading: false,
          })
        }
      )
    }
  }

  postLoadData() {}

  renderLoader() {
    if (this.state.loading) {
      const spinnerStyle = { margin: 'auto' }
      const spinner =
        this.getType() === 'simpleLine' ? (
          <Spinner white style={spinnerStyle} />
        ) : (
          <Spinner blue style={spinnerStyle} />
        )
      const height = this.props.height ? { height: `${this.props.height}px` } : { height: '100px' }
      return (
        <div className={classes.loading} style={height}>
          <div className={classes.spacer} />
          {spinner}
          <div className={classes.spacer} />
        </div>
      )
    }

    return <span />
  }

  render() {
    const style = this.props.style ? this.props.style : {}
    const height = this.state.loading || !this.chart ? 0 : this.props.height
    const floatStyle = this.getType() === 'simpleLine' ? classes.floatStyle : ''

    /* eslint-disable */
    return (
      <div className={`${classes.graphBlock} ${floatStyle}`} style={style}>
        {this.getAxisFrame()}
        <div
          ref='root'
          className='chart keen-graph'
          style={{ height: `${height}px`, position: 'relative', zIndex: 1 }}
        >
          <svg ref='svg' id={this.getType()} />
        </div>
        {this.noDataReturned()}
        {this.renderLoader()}
        {this.graphDescription()}
      </div>
    )
    /* eslint-enable */
  }
}

BaseGraph.defaultProps = {
  height: 400,
  width: 400,
  period: 'today',
  key: 'graphKey',
  isVisible: false,
}

/* eslint-disable */
BaseGraph.propTypes = {
  margin: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  period: PropTypes.string,
  key: PropTypes.string,
  style: PropTypes.object,
  isVisible: PropTypes.bool,
  xAxisLabel: PropTypes.string,
  yAxisLabel: PropTypes.string,
  url: PropTypes.string,
}
/* eslint-enable */
