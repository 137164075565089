import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import cx from 'classnames'
import Immutable from 'immutable'

import { formatCurrency } from 'libs/utils'
import localisationSelector from 'libs/localisation/localisation'

function UnconnectedAmount({
  amount,
  showZeroAsDash,
  showRed = true,
  strike,
  strikeClass,
  localisation,
  style,
  alignLeft,
  isFriendlyAmount,
  className,
}) {
  const containerClassName = cx(className, {
    amount: true,
    'text-right': !alignLeft,
  })

  if (showZeroAsDash && !amount) {
    return <div className={containerClassName}> - </div>
  }

  const displayAmount = formatCurrency(amount, localisation.get('currency', 'R'), isFriendlyAmount)
  const textClassName = cx({
    'red-text': showRed && amount < 0,
    strikethrough: strike,
    [strikeClass]: strike,
  })

  return (
    <div className={containerClassName} style={style}>
      <span className={textClassName}>{displayAmount}</span>
    </div>
  )
}

UnconnectedAmount.propTypes = {
  amount: PropTypes.number,
  showZeroAsDash: PropTypes.bool,
  showRed: PropTypes.bool,
  strike: PropTypes.bool,
  strikeClass: PropTypes.string,
  localisation: PropTypes.instanceOf(Immutable.Map),
  style: PropTypes.object,
  alignLeft: PropTypes.bool,
  isFriendlyAmount: PropTypes.bool,
  className: PropTypes.string,
}

export const Amount = connect((state) => ({
  localisation: localisationSelector(state),
}))(UnconnectedAmount)

function UnconnectedFriendlyAmount(props) {
  return (
    <UnconnectedAmount
      isFriendlyAmount={props.localisation.get('currencyAllowsDecimals')}
      {...props}
    />
  )
}

UnconnectedFriendlyAmount.propTypes = {
  localisation: PropTypes.instanceOf(Immutable.Map),
}

export const FriendlyAmount = connect((state) => ({
  localisation: localisationSelector(state),
}))(UnconnectedFriendlyAmount)
