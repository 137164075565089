/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@yoco/design-system-icons/dist/react'

import FlatButton from 'components/buttons/FlatButton'
import * as forms from 'libs/forms'
import PopupComponent from 'libs/modals/popups'
import classes from 'libs/modals/popups.module.scss'
import { callJSONApi } from 'libs/api'
import { showMessage } from 'redux/modules/notifications'
import { connect } from 'react-redux'
import { clearForm } from 'redux/modules/forms'
import useReferralStore from '../../containers/growth/store'

const FORM_NAME = 'referralEmail'
class unConnectedReferralEmailPopup extends PopupComponent {
  constructor(props) {
    super(props)

    this.state = {
      errorMessage: null,
      success: null,
    }
  }

  onSend(recipientEmail, recipientName) {
    const { referralCode } = useReferralStore.getState()
    const { dispatch } = this.props

    callJSONApi(
      `/incentives/referral/send-referral-email`,
      'POST',
      {
        recipient_email: recipientEmail,
        recipient_name: recipientName,
        referral_code: referralCode,
      },

      (response) => {
        if (response.data.success === true) {
          this.setState({
            errorMessage: null,
            success: 'Email sent successfully!',
          })

          dispatch(
            showMessage('Email sent successfully!', 'success', 'send_referral_email_message')
          )
          this.props.dispatch(clearForm(FORM_NAME))
          this.props.onSend()
        } else {
          this.setState({
            errorMessage: response.data.message[0],
            success: null,
          })

          dispatch(
            showMessage(
              `Email failed to send, ${this.state.errorMessage}`,
              'danger',
              'send_referral_email_message'
            )
          )
        }
      },
      true,
      false
    )
  }

  onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel()

      this.setState({
        errorMessage: null,
        success: null,
      })

      this.props.dispatch(clearForm(FORM_NAME))
    }
  }

  getContent() {
    return (
      <>
        <div className={classes.emailPopupTitle}>
          New message
          <div
            className={`${classes.emailEscButton} ${classes.modifiedButton}`}
            onClick={() => this.onCancel()}
          >
            <Icon name='close' size={24} />
          </div>
        </div>
        <forms.Form
          name={FORM_NAME}
          onSubmit={(values) => {
            const recipientEmail = values.get(FORM_NAME).get('email')
            const recipientName = values.get(FORM_NAME).get('firstName')

            this.onSend(recipientEmail, recipientName)
          }}
        >
          <div className='clearfix' style={{ padding: '0px 32px', marginBottom: '20px' }}>
            <forms.TextField
              name='referralEmail.email'
              label={`Recipient's Email`}
              placeholder='e.g. friend@gmail.com'
              isFullWidth
              required
              validators={[
                new forms.RequiredValidator('Email is required'),
                new forms.EmailValidator('Please provide a valid email address'),
              ]}
            />
            <forms.TextField
              name='referralEmail.firstName'
              label='First Name'
              placeholder='e.g. John'
              isFullWidth
              required
              validators={[new forms.RequiredValidator('Name is required')]}
            />
            <hr />
          </div>
          <div
            className='clearfix'
            style={{ textAlign: 'left', padding: '0px 32px', marginBottom: '10px' }}
          >
            <div className={classes.actions}>
              <FlatButton
                label='Send'
                type='submit'
                className='blueBackground'
                style={{ minWidth: '120px' }}
              />
            </div>
          </div>
        </forms.Form>
      </>
    )
  }
}

unConnectedReferralEmailPopup.propTypes = {
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
}

const ReferralEmailPopup = connect(() => ({}))(unConnectedReferralEmailPopup)

export default ReferralEmailPopup
