import { callJSONApi } from 'libs/api'

import ActionTypes from './actionTypes'
import { formatPerformanceResultsForPeriod, getStartAndEndDates, encodeJsonParams } from './utils'

const PERFORMANCE_URL = '/sales/sales-performance'

/**
 * Fetches the Sales Performance results for a given period
 * for a given date range
 * @param {String} period
 * @param {DateString} startDate
 * @param {DateString} endDate
 */
export function fetchSalesPerformanceForPeriod(period, startDate, endDate) {
  const encodedJsonParams = encodeJsonParams({ created: [`${startDate}`, `${endDate}`] })
  return new Promise((resolve, reject) => {
    callJSONApi(
      `${PERFORMANCE_URL}?period=${period}&filters=${encodedJsonParams}`,
      'GET',
      '',
      (response) => {
        resolve(response.data)
      },
      (error) => {
        reject(error)
      }
    )
  })
}

export function fetchSalesPerformance(requestPeriod) {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.FETCH_SALES_PERFORMANCE_REQUEST })
    try {
      const { startDate, endDate } = getStartAndEndDates(requestPeriod)
      const { period, currentData } = await fetchSalesPerformanceForPeriod(
        requestPeriod,
        startDate,
        endDate
      )
      dispatch({ type: ActionTypes.FETCH_SALES_PERFORMANCE_SUCCESS })
      dispatch({
        type: ActionTypes.FETCH_SALES_PERFORMANCE_FULFILLED,
        payload: {
          period,
          rows: formatPerformanceResultsForPeriod(period, currentData),
        },
      })
    } catch (error) {
      dispatch({ type: ActionTypes.FETCH_SALES_PERFORMANCE_FAILURE, error })
    }
  }
}
