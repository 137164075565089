import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@yoco/design-system-icons/dist/react'

import { List, ListItem } from 'libs/list'
import FlatButton from 'components/buttons/FlatButton'
import Alert from 'components/notifications/Alert'
import emptyBasket from 'assets/images/icon-empty-state.png'
import LoadingView from 'components/loaders/LoadingView'
import { makeTestID } from 'libs/utils'

import classes from './List.module.scss'
import { useFilterCustomers } from './hooks'

const CustomerListWrapper = ({ children }) => <div className={classes.root}>{children}</div>
CustomerListWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

function SelectCustomerList({ onCreateCustomerClick, onSelected, testID = '' }) {
  const { customers, isLoading, error, search, setSearch, updateCustomers } = useFilterCustomers()

  useEffect(() => {
    updateCustomers()
    // disabling as we only ever want this to run once
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  const handleEnterKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        updateCustomers()
      }
    },
    [updateCustomers]
  )

  if (isLoading) {
    return (
      <CustomerListWrapper>
        <LoadingView message='Loading customers ...' />
      </CustomerListWrapper>
    )
  }

  if (error) {
    return (
      <CustomerListWrapper>
        <Alert message={error} messageType='danger' />
      </CustomerListWrapper>
    )
  }

  if (customers) {
    return (
      <CustomerListWrapper>
        <div className={classes.search}>
          <input
            placeholder='Type to filter ...'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={handleEnterKeyDown}
            data-testid={makeTestID(testID, 'search')}
          />
          <span onClick={updateCustomers} onKeyDown={updateCustomers} role='button' tabIndex={0}>
            <Icon name='search' size={24} />
          </span>
        </div>
        <List
          style={{ cursor: 'pointer' }}
          hasOutlineBorders
          data={customers}
          testID={testID}
          renderItem={(customer) => (
            <ListItem
              key={customer.id}
              title={customer.fullName}
              subTitle={customer.phone || customer.email}
              accessoryRight='chevron-right'
              onClick={() => onSelected(customer)}
              testID={makeTestID(testID, 'item')}
            />
          )}
        >
          {!customers?.length ? (
            <div className={classes.empty}>
              <img src={emptyBasket} alt='No customers found' /> <br />
              No customers were found
            </div>
          ) : null}
        </List>
      </CustomerListWrapper>
    )
  }

  return (
    <CustomerListWrapper>
      <div className={classes.empty}>
        <img src={emptyBasket} alt='No customers in your collection' /> <br />
        <b>You have no customers in your collection</b> <br />
        <br />
        <FlatButton type='button' onClick={onCreateCustomerClick}>
          Create a new one
        </FlatButton>
      </div>
    </CustomerListWrapper>
  )
}

SelectCustomerList.propTypes = {
  onSelected: PropTypes.func,
  onCreateCustomerClick: PropTypes.func,
  testID: PropTypes.string,
}

SelectCustomerList.defaultProps = {
  testID: 'SelectCustomerList',
}

export default SelectCustomerList
