import React, { Component } from 'react'
import PropTypes from 'prop-types'

import FormCard from 'components/cards/FormCard'
import Label from 'ui/components/Label'
import ShareBlock from 'ui/social/ShareBlock'
import { callJSONApi } from 'libs/api'
import Spinner from 'components/loaders/Spinner'
import Alert from 'components/notifications/Alert'
import Well from 'ui/containers/Well'
import Spacer from 'ui/layout/Spacer'

export default class ShareOnlineProduct extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
    }
  }

  componentDidMount() {
    this.loadProduct(this.props.hashid)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Unfortunately we don't have hooks in this version of react...
    if (nextProps.hashid && nextProps.hashid !== this.props.hashid) {
      this.loadProduct(nextProps.hashid)
    }
  }

  loadProduct(hashid) {
    this.setState({
      isLoading: true,
      errorMessage: null,
      url: null,
    })

    callJSONApi(
      `/online/products/${hashid}`,
      'GET',
      null,
      (data) => {
        const onlineProduct = data.data.product || {}

        if (onlineProduct) {
          // If a person has switched products while we were fetching the details
          if (onlineProduct.hashid === this.props.hashid) {
            this.setState({
              isLoading: false,
              errorMessage: null,
              url: onlineProduct.url,
              productName: onlineProduct.productName,
              businessName: onlineProduct.businessName,
            })
          }
        } else {
          this.setState({
            isLoading: false,
            errorMessage: 'Product page not yet available - please refresh',
          })
        }
      },
      (prettyError) => {
        this.setState({
          isLoading: false,
          errorMessage: prettyError,
        })
      }
    )
  }

  render() {
    if (this.state.isLoading) {
      return <Spinner />
    }

    if (this.state.errorMessage) {
      return <Alert messageType='danger' message={this.state.errorMessage} closable={false} />
    }

    return (
      <FormCard header='Share this product'>
        <Label>Product page link</Label>
        <Well>
          <a href={this.state.url}>{this.state.url}</a>
        </Well>

        <Spacer size='medium' />

        <Label>Share on social networks</Label>
        <ShareBlock
          url={this.state.url}
          title={`${this.state.productName} | ${this.state.businessName}`}
        />
      </FormCard>
    )
  }
}

ShareOnlineProduct.propTypes = {
  hashid: PropTypes.string,
}
