/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import classNames from 'classnames'
import { Icon } from '@yoco/design-system-icons/dist/react'

import layout from 'ui/layout/layout.module.scss'
import ui from 'ui/ui.module.scss'
import containers from 'ui/containers/containers.module.scss'
import HStack from 'ui/layout/HStack'
import { If } from 'libs/formats'
import classes from './ColorPicker.module.scss'
import { defaultColors } from './ColorPicker.constants'
import { Props } from './types'

const ColorPicker: React.FunctionComponent<Props> = ({
  selectedColor,
  setColor,
  colors = defaultColors,
}) => {
  return (
    <HStack spacing='xs'>
      {colors.map((color) => (
        <div
          key={color}
          className={classNames([
            ui.cursorPointer,
            layout.relative,
            containers.rounded,
            classes.color,
          ])}
          style={{
            backgroundColor: color,
          }}
          onClick={() => setColor(color)}
          data-testid='ColorPicker'
        >
          <If condition={selectedColor === color}>
            <Icon name='tick' size={24} color='white' />
          </If>
        </div>
      ))}
    </HStack>
  )
}

export default ColorPicker
