import store from 'redux/store'
import { callInvoicesJSONAPI } from 'libs/api'
import { InvoicesOnboardingActionTypes } from 'redux/modules/invoices'

export const fetchInvoiceOnboardingData = ({ showLoader } = {}) => {
  if (showLoader) {
    store.dispatch({
      type: InvoicesOnboardingActionTypes.FETCH_REQUEST,
    })
  }

  return callInvoicesJSONAPI('invoices-dashboard', 'GET')
    .then((response) => {
      store.dispatch({
        type: InvoicesOnboardingActionTypes.FETCH_SUCCESS,
        payload: response.data,
      })
    })
    .catch(() => {
      store.dispatch({
        type: InvoicesOnboardingActionTypes.FETCH_FAILURE,
        error: 'Failed to setup invoices!',
      })
    })
}
