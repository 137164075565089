/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-unstable-nested-components */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Immutable, { Map } from 'immutable'
import PropTypes from 'prop-types'

import { FirebaseTable, EmptyTableView, TableRow } from 'libs/tables'
import { ItemIcon } from 'components/items'
import SplitBlock from 'components/blocks/SplitBlock'
import ConfirmPopup from 'components/popups/ConfirmPopup'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import DropDownButton from 'components/buttons/DropDownButton'
import { Tabs, TabPanel } from 'libs/tabs'
import { clearForm } from 'redux/modules/forms'
import { clearTable, removeItem } from 'redux/modules/tables'
import { makeTestID, mouseDownHandler, mouseUpHandler } from 'libs/utils'
import EditCollectionForm from 'containers/store/EditCollectionForm'
import { FilterBatch } from 'libs/filters'
import SearchBar from 'components/filters/SearchBar'
import { callJSONApi } from 'libs/api'
import { FirebaseHandler, features } from 'libs/firebase'
import Spinner from 'components/loaders/Spinner'
import BlockHeader from 'components/headers/BlockHeader'

import classes from './store.module.scss'

const baseTestID = makeTestID('menu', 'brandsPage')

const FIREBASE_JOIN = Immutable.fromJS({ uuid: 'collection' })

export const COLLECTIONS_TABLE_NAME = 'collectionItems'

class UnconnectedCollectionsPage extends Component {
  constructor(props) {
    super(props)

    /*
      Table name is state because it changes depending on what tab is active
    */

    this.state = {
      showingSplit: false,
      showDeleteModal: false,
      shownRow: null,
      collection: 'brand',
      tableName: 'storeBrands',
      tabIndex: 0,
    }
  }

  onDeleteCollection(uuid) {
    callJSONApi(`/layout/deleteCollection/${uuid}/`, 'POST', {}, () => {
      this.splitViewClosed()
      this.hideDeletePopup()
      this.props.dispatch(removeItem(this.state.tableName, uuid))
    })
  }

  getSplitBlockHeader() {
    const collectionType =
      this.state.collection.indexOf('_') < 0
        ? this.state.collection
        : this.state.collection.substring(this.state.collection.indexOf('_') + 1)
    return this.state.shownRow
      ? `${this.state.shownRow.getIn(['tile', 'name'])}`
      : `Add ${collectionType}`
  }

  getLabels(tileType) {
    switch (tileType) {
      case 'brand':
        return 'Brands'
      case 'product_category':
        return 'Categories'
      case 'custom_group':
      case 'layout':
        return 'Custom Groups'
      default:
        return 'Collections'
    }
  }

  splitViewClosed = () => {
    this.props.dispatch(clearForm(this.state.tableName))
    this.props.dispatch(clearTable(COLLECTIONS_TABLE_NAME))
    this.setState({
      shownRow: null,
      showingSplit: false,
    })
  }

  buttonActions() {
    const actionButtons = [
      { label: 'Add Brand', onClick: this.tabChanged.bind(this, 0, true) },
      { label: 'Add Category', onClick: this.tabChanged.bind(this, 1, true) },
    ]

    if ((this.props.features || Map()).getIn(['customGroups', 'enabled'])) {
      actionButtons.push({
        label: 'Add Custom Group',
        onClick: this.tabChanged.bind(this, 2, true),
      })
    }

    return actionButtons
  }

  hideDeletePopup() {
    this.setState({
      showDeleteModal: false,
      deleteUUID: null,
    })
  }

  deletePopUp() {
    return (
      <ConfirmPopup
        showing={this.state.showDeleteModal}
        onCancel={() => this.hideDeletePopup()}
        icon='icon-delete-garbage-streamline'
        title='Remove'
        iconType={classes.deleteIconType}
        actionType='destructive'
        subtitle='Are you sure you want to remove this collection?'
        onConfirm={() => {
          this.onDeleteCollection(this.state.deleteUUID)
        }}
      />
    )
  }

  showRow(rowData) {
    this.setState((currentState) => {
      const newState = { ...currentState }

      if (newState.shownRow && newState.shownRow.get('uuid') === rowData.get('uuid')) {
        newState.shownRow = null
      } else {
        newState.shownRow = rowData
      }

      if (newState.shownRow) {
        newState.showingSplit = true
      } else {
        newState.showingSplit = false
      }

      return newState
    })
  }

  tabChanged(tabIndex, showingSplit = false) {
    let selectedTab
    switch (tabIndex) {
      case 1:
        selectedTab = 'product_category'
        break
      case 2:
        selectedTab = 'layout'
        break
      default:
        selectedTab = 'brand'
    }

    this.setState({
      tabIndex,
      showingSplit,
      collection: selectedTab,
      tableName: `store${this.getLabels(selectedTab).replace(' ', '')}`,
    })
  }

  renderTableRow(rowData, rowIndex, connectionStatus) {
    const itemLabel = this.state.collection === 'brand' ? 'Products' : 'Items'
    let itemsInList = <Spinner blue style={{ margin: 0 }} />
    if (connectionStatus) {
      // If the data has connected to firebase
      // then we check if we have any tiles or return 0 if we do not
      if (
        rowData.get('collectionType') === 'layout' &&
        rowData.getIn(['layouts', 'universal', 'layoutTiles'])
      ) {
        const layoutTiles = rowData
          .getIn(['layouts', 'universal', 'layoutTiles'], Map())
          .filter((layoutTile) => layoutTile.get('tile'))
        itemsInList = `${layoutTiles.size} ${itemLabel}`
      } else if (rowData.get('tiles')) {
        itemsInList = `${rowData.get('tiles').size} ${itemLabel}`
      } else {
        itemsInList = `0 ${itemLabel}`
      }
    }

    return (
      <TableRow
        rowIndex={rowIndex}
        key={`row${rowData.get('uuid')}`}
        onClick={() => this.showRow(rowData)}
        selected={this.state.shownRow && this.state.shownRow.get('uuid') === rowData.get('uuid')}
      >
        <td className={classes.image}>
          <ItemIcon tile={rowData.get('tile')} />
        </td>
        <td className={classes.name}>{rowData.getIn(['tile', 'name'])}</td>
        <td className='items hide-on-mobile '>{itemsInList}</td>
      </TableRow>
    )
  }

  renderSplitContent() {
    return (
      <EditCollectionForm
        closeSplitView={this.splitViewClosed}
        showButtons={this.state.showingSplit}
        localObject={this.state.shownRow}
        collectionType={this.state.collection}
        firebaseJoin={FIREBASE_JOIN}
        formName={this.state.tableName}
        mouseDownHandler={() => {
          mouseDownHandler()
        }}
        mouseUpHandler={() => {
          mouseUpHandler()
        }}
      />
    )
  }

  renderTable(collectionType) {
    const tableName = `store${this.getLabels(collectionType).replace(' ', '')}`

    return (
      <FirebaseTable
        useOffset
        waitForFilterLoad={false}
        hideNoResults
        rowsClickable
        name={tableName}
        sortPath='tile.name'
        api={`/collections/?collectionType=${collectionType}`}
        firebaseJoin={FIREBASE_JOIN}
        emptyTable={
          <EmptyTableView
            title={`Your business currently has no ${this.getLabels(
              collectionType
            ).toLowerCase()} or you may need to change your filter`}
          />
        }
        getHeader={() => {
          return (
            <tr className='visible-header'>
              <th className={classes.image} />
              <th>Name</th>
              <th className='hide-on-mobile'>Items</th>
            </tr>
          )
        }}
        getRow={(immutableRow, rowIndex, groupIndex, lastRow, connectionStatus) => {
          return this.renderTableRow(immutableRow, rowIndex, connectionStatus)
        }}
      />
    )
  }

  renderCustomGroups() {
    if ((this.props.features || Map()).getIn(['customGroups', 'enabled'])) {
      return <TabPanel title='Custom Groups'>{this.renderTable('layout')}</TabPanel>
    }
    return undefined
  }

  render() {
    return (
      <SplitBlock
        formName={this.state.tableName}
        showDelete={!!this.state.shownRow}
        header={this.getSplitBlockHeader()}
        showingSplit={this.state.showingSplit}
        saveLabel={this.state.shownRow ? 'Save' : 'Add'}
        deletePressed={() => {
          this.setState((prevState) => ({
            showDeleteModal: true,
            deleteUUID: prevState.shownRow.get('uuid'),
          }))
        }}
        onDismiss={() => this.splitViewClosed()}
        renderSplitContent={() => this.renderSplitContent()}
      >
        <MaxWidthBlock className={classes.itemsPage}>
          <BlockHeader
            title='Brands and Categories'
            actions={
              <DropDownButton
                label='Add'
                buttonClass='primary'
                actions={this.buttonActions()}
                testID={makeTestID(baseTestID, 'addButton')}
              />
            }
          />
          <FilterBatch name={this.state.tableName} showMobileInline>
            <SearchBar placeholder='Search by name' testID={makeTestID(baseTestID, 'searchBar')} />
          </FilterBatch>

          <Tabs
            tabChanged={(index) => this.tabChanged(index)}
            tabIndex={this.state.tabIndex}
            testID={makeTestID('brandsAndCategories', 'tab')}
          >
            <TabPanel title='Brands'>{this.renderTable('brand')}</TabPanel>
            <TabPanel title='Categories'>{this.renderTable('product_category')}</TabPanel>
            {this.renderCustomGroups()}
          </Tabs>
        </MaxWidthBlock>
        {this.deletePopUp()}
      </SplitBlock>
    )
  }
}

UnconnectedCollectionsPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  features: PropTypes.object,
}

const CollectionsPage = FirebaseHandler.connect(
  UnconnectedCollectionsPage,
  Map({
    features,
  })
)

export default connect(() => ({}))(CollectionsPage)
