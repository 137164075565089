import CheckboxButton from 'components/buttons/CheckboxButton'
import { getLendingPartnerDisplayName } from 'components/capital/utils'
import React from 'react'
import { LendingPartners } from 'redux/modules/capital/types'

import styles from './CapitalConsentBlock.module.scss'

interface Props {
  consented: boolean
  providerName: LendingPartners
  onClick: () => void
}

const CapitalConsentBlock: React.FunctionComponent<Props> = ({
  consented,
  providerName,
  onClick,
}) => {
  return (
    <div className={styles.container}>
      <CheckboxButton onClick={onClick} selected={consented} />
      <div className={styles.text}>
        <p>
          {`I hereby consent and confirm that Yoco is authorised to provide ${getLendingPartnerDisplayName(
            providerName,
            { extendedDisplayName: true }
          )} with certain data and information, including my personal, business and financial information that Yoco has about me. This data is used to generate your Cash Advance offer.`}
        </p>
      </div>
    </div>
  )
}

export default CapitalConsentBlock
