/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unused-class-component-methods */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import s from './style/circle.module.scss'

export default class Circle extends Component {
  getPathStyles() {
    const { percent, strokeWidth, gapDegree = 0, gapPosition } = this.props
    const radius = 50 - strokeWidth / 2
    let beginPositionX = 0
    let beginPositionY = -radius
    let endPositionX = 0
    let endPositionY = -2 * radius
    switch (gapPosition) {
      case 'left':
        beginPositionX = -radius
        beginPositionY = 0
        endPositionX = 2 * radius
        endPositionY = 0
        break
      case 'right':
        beginPositionX = radius
        beginPositionY = 0
        endPositionX = -2 * radius
        endPositionY = 0
        break
      case 'bottom':
        beginPositionY = radius
        endPositionY = 2 * radius
        break
      default:
    }
    const pathString = `M 50,50 m ${beginPositionX},${beginPositionY}
     a ${radius},${radius} 0 1 1 ${endPositionX},${-endPositionY}
     a ${radius},${radius} 0 1 1 ${-endPositionX},${endPositionY}`
    const len = Math.PI * 2 * radius
    const trailPathStyle = {
      strokeDasharray: `${len - gapDegree}px ${len}px`,
      strokeDashoffset: `-${gapDegree / 2}px`,
      transition: 'stroke-dashoffset .3s ease 0s, stroke-dasharray .3s ease 0s, stroke .3s',
      strokeLinecap: 'butt',
    }
    const strokePathStyle = {
      strokeDasharray: `${(percent / 100) * (len - gapDegree)}px ${len}px`,
      strokeDashoffset: `-${gapDegree / 2}px`,
      transition:
        'stroke-dashoffset .3s ease 0s, stroke-dasharray .3s ease 0s, stroke .3s, stroke-width .06s ease .3s', // eslint-disable-line
      strokeLinecap: 'butt',
    }
    return { pathString, trailPathStyle, strokePathStyle }
  }

  render() {
    const {
      prefixClass,
      strokeWidth,
      strokeColor,
      trailColor,
      style,
      className,
      ...restProps
    } = this.props
    const classes = cx(s[`${prefixClass}-circle`], className)
    const { pathString, trailPathStyle, strokePathStyle } = this.getPathStyles()
    delete restProps.percent
    delete restProps.gapDegree
    delete restProps.gapPosition
    return (
      <svg className={classes} viewBox='0 0 100 100' style={style} {...restProps}>
        <path
          d={pathString}
          stroke={trailColor}
          strokeWidth={strokeWidth}
          fillOpacity='0'
          style={trailPathStyle}
        />
        <path
          d={pathString}
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          fillOpacity='0'
          ref={(path) => {
            this.path = path
          }}
          style={strokePathStyle}
        />
      </svg>
    )
  }
}

Circle.propTypes = {
  prefixClass: PropTypes.string,
  percent: PropTypes.number,
  className: PropTypes.func,
  strokeWidth: PropTypes.number,
  strokeColor: PropTypes.string,
  trailColor: PropTypes.string,
  gapDegree: PropTypes.number,
  gapPosition: PropTypes.string,
  style: PropTypes.object,
}
