import { RootState } from 'redux/store'
import {
  ActionAnalyticsEventsMap,
  CapitalActions,
  CapitalAnalyticsEvent,
  CapitalAnalyticsEvents,
  CapitalOffer,
  PayoutDeductionPlan,
  PrelimOffer,
} from './types'

export interface CapitalAnalyticsSelectors {
  selectBusinessUUID: (state: any) => string
  selectPrelimOffer: (state: any) => PrelimOffer
  selectCapitalOffer: (state: any) => CapitalOffer
  selectPayoutDeductionPlan: (state: any) => PayoutDeductionPlan
  selectIsDemoAccount: (state: any) => boolean
  selectHasPayoutDeductionPlan: (state: any) => boolean
  selectFeatureFlag: (state: any, feature: string) => boolean
}

export interface CapitalAction {
  type: CapitalActions
  meta?: any
}

export const createAnalyticsEvent = ({ selectors }: { selectors: CapitalAnalyticsSelectors }) => (
  state: RootState
) => (action: CapitalAction): CapitalAnalyticsEvent | undefined => {
  const prelimOffer = selectors.selectPrelimOffer(state)
  const businessUUID = selectors.selectBusinessUUID(state)
  const capitalOffer = selectors.selectCapitalOffer(state)
  const payoutDeductionPlan = selectors.selectPayoutDeductionPlan(state)
  const isDemoAccount = selectors.selectIsDemoAccount(state)
  const hasActiveDeductionPlan = selectors.selectHasPayoutDeductionPlan(state)
  const baseInformation = {
    businessUUID,
    isDemoAccount,
  }
  const sharedPrelimOfferInformation = {
    prelimOfferUUID: prelimOffer.uuid,
    prelimOfferProviderName: prelimOffer.providerName,
  }
  const sharedCapitalOfferInformation = {
    offerUUID: capitalOffer.uuid,
    providerName: capitalOffer.providerName,
  }
  const price =
    capitalOffer.offerResponseData.offerContract.total_payable -
    capitalOffer.offerResponseData.offerContract.approved_advance

  const analyticsPayloadMap: ActionAnalyticsEventsMap = {
    [CapitalActions.CAPITAL_OPT_IN_SUCCESS]: {
      event: CapitalAnalyticsEvents.LetMeKnowClicked,
      payload: {
        ...baseInformation,
      },
    },
    [CapitalActions.FIND_OUT_MORE_CLICKED]: {
      event: CapitalAnalyticsEvents.FindOutMoreClicked,
      payload: {
        ...baseInformation,
      },
    },
    [CapitalActions.OFFERS_VIEWED_FROM_MAIN_NAV_BAR]: {
      event: CapitalAnalyticsEvents.OffersTabClicked,
      payload: {
        ...baseInformation,
        ...sharedPrelimOfferInformation,
      },
    },
    [CapitalActions.DEDUCTION_PLANS_VIEWED_FROM_MAIN_NAV_BAR]: {
      event: CapitalAnalyticsEvents.DeductionPlansTabClicked,
      payload: {
        ...baseInformation,
        hasActiveDeductionPlan,
      },
    },
    [CapitalActions.PRELIM_OFFER_PRESENTED]: {
      event: CapitalAnalyticsEvents.PrelimOfferPresented,
      payload: {
        ...baseInformation,
        ...sharedPrelimOfferInformation,
        upToAmount: prelimOffer.uptoAmount,
      },
    },
    [CapitalActions.CREATE_APPLICATION]: {
      event: CapitalAnalyticsEvents.GetCashAdvanceClicked,
      payload: {
        ...baseInformation,
        ...sharedPrelimOfferInformation,
        upToAmount: prelimOffer.uptoAmount,
      },
    },
    [CapitalActions.SEND_APPLICATION]: {
      event: CapitalAnalyticsEvents.ContinueCashAdvanceClicked,
      payload: {
        ...baseInformation,
        ...sharedPrelimOfferInformation,
        maxAmount: prelimOffer.uptoAmount,
        minAmount: prelimOffer.minimumAmount,
        repaymentPeriod: action?.meta?.repaymentPeriod,
        repaymentPercentage: action?.meta?.repaymentPercentage,
        dailyAmount: action?.meta?.dailyAmount,
        selectedAmount: action?.meta?.selectedAmount,
        hasActiveDeductionPlan,
      },
    },
    [CapitalActions.ACCEPTED_CAPITAL_OFFER]: {
      event: CapitalAnalyticsEvents.AcceptOfferClicked,
      payload: {
        ...baseInformation,
        ...sharedCapitalOfferInformation,
        ...sharedPrelimOfferInformation,
        offerState: capitalOffer.state,
        estimatedTerm: capitalOffer.offerResponseData.offerContract.estimated_term,
        processingFee: capitalOffer.offerResponseData.offerContract.processing_fee,
        totalPayable: capitalOffer.offerResponseData.offerContract.total_payable,
        approvedAdvance: capitalOffer.offerResponseData.offerContract.approved_advance,
        repaymentPercentage: capitalOffer.offerResponseData.offerContract.repayment_percentage,
        price,
        hasActiveDeductionPlan,
      },
    },
    [CapitalActions.DECLINED_CAPITAL_OFFER]: {
      event: CapitalAnalyticsEvents.DeclineOfferClicked,
      payload: {
        ...baseInformation,
        ...sharedCapitalOfferInformation,
        ...sharedPrelimOfferInformation,
        offerState: capitalOffer.state,
        estimatedTerm: capitalOffer.offerResponseData.offerContract.estimated_term,
        processingFee: capitalOffer.offerResponseData.offerContract.processing_fee,
        totalPayable: capitalOffer.offerResponseData.offerContract.total_payable,
        approvedAdvance: capitalOffer.offerResponseData.offerContract.approved_advance,
        repaymentPercentage: capitalOffer.offerResponseData.offerContract.repayment_percentage,
        price,
        hasActiveDeductionPlan,
        declineReason: action?.meta?.declineReason,
      },
    },
    [CapitalActions.SLIDER_AMOUNT_CHANGED]: {
      event: CapitalAnalyticsEvents.SliderAmountChanged,
      payload: {
        ...baseInformation,
        ...sharedPrelimOfferInformation,
        amount: action?.meta?.amount,
        hasActiveDeductionPlan,
      },
    },
    [CapitalActions.SLIDER_MONTH_CHANGED]: {
      event: CapitalAnalyticsEvents.SliderMonthChanged,
      payload: {
        ...baseInformation,
        ...sharedPrelimOfferInformation,
        term: action?.meta?.term,
        hasActiveDeductionPlan,
      },
    },
    [CapitalActions.DEDUCTIONS_VIEWED]: {
      event: CapitalAnalyticsEvents.ViewDeductionsClicked,
      payload: {
        ...baseInformation,
        deductionPlanUUID: payoutDeductionPlan.uuid,
        providerUUID: payoutDeductionPlan.providerBusinessUUID,
        principalAmount: payoutDeductionPlan.principalAmount,
        totalAmountPayable: payoutDeductionPlan.totalAmountPayable,
        totalAmountPaid: payoutDeductionPlan.totalAmountPaid,
        payoutDeductionState: payoutDeductionPlan.payoutDeductionState,
        hasActiveDeductionPlan,
      },
    },
    [CapitalActions.CONTRACT_VIEWED]: {
      event: CapitalAnalyticsEvents.ViewContractClicked,
      payload: {
        ...baseInformation,
        ...sharedCapitalOfferInformation,
      },
    },
    [CapitalActions.APPLY_FOR_RE_ADVANCE]: {
      event: CapitalAnalyticsEvents.ReApplyClicked,
      payload: {
        ...baseInformation,
        ...sharedPrelimOfferInformation,
        prelimOfferUpToAmount: prelimOffer.uptoAmount,
        offerUUID: capitalOffer.uuid,
        providerUUID: capitalOffer.payoutDeductionPlan.providerBusinessUUID,
        deductionPlanUUID: payoutDeductionPlan.uuid,
        principalAmount: payoutDeductionPlan.principalAmount,
        totalAmountPayable: payoutDeductionPlan.totalAmountPayable,
        totalAmountPaid: payoutDeductionPlan.totalAmountPaid,
        payoutDeductionState: payoutDeductionPlan.payoutDeductionState,
      },
    },
    [CapitalActions.DEDUCTION_PLAN_VIEWED]: {
      event: CapitalAnalyticsEvents.DeductionPlanClicked,
      payload: {
        ...baseInformation,
        providerUUID: action?.meta?.providerBusinessUUID,
        deductionPlanUUID: action?.meta?.uuid,
        principalAmount: action?.meta?.principalAmount,
        totalAmountPayable: action?.meta?.totalAmountPayable,
        totalAmountPaid: action?.meta?.totalAmountPaid ?? 0,
        payoutDeductionState: action?.meta?.payoutDeductionState,
        planCreated: action?.meta?.created,
      },
    },
  }

  return analyticsPayloadMap[action.type]
}
