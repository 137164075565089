/* eslint-disable react/no-unused-state */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

export class TabPanel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentIndex: 0,
    }
  }

  render() {
    return <div>{this.props.children}</div>
  }
}

TabPanel.propTypes = {
  children: PropTypes.element,
}
