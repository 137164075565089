import React from 'react'
import PropTypes from 'prop-types'

import { If } from 'libs/formats'

import classes from './sales.module.scss'

function TransactionHeaderRow({ showSavedBillsColumn }) {
  return (
    <tr className='visible-header'>
      <th className={classes.time}>Time</th>
      <th className={classes.transactionStatus}>Status</th>
      <th className={`${classes.transactionType} hide-on-phone`}>Payment method</th>
      <th className={`${classes.transactionName} hide-on-phone`}>Staff member</th>
      <If condition={showSavedBillsColumn}>
        <th className={`${classes.billName} hide-on-lg`}>Bill name</th>
      </If>
      <th className={`${classes.transactionItems} hide-on-md`}>Summary</th>
      <th className={classes.transactionAmount}>Amount</th>
    </tr>
  )
}

TransactionHeaderRow.propTypes = {
  showSavedBillsColumn: PropTypes.bool,
}

export default TransactionHeaderRow
