/* eslint-disable react/jsx-no-bind */
import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { connect } from 'react-redux'

import FlatButton from 'components/buttons/FlatButton'
import PopupComponent from 'libs/modals/popups'
import SearchBar from 'components/filters/SearchBar'
import { FilterBatch } from 'libs/filters'
import TileCheckboxRow from 'components/rows/TileCheckboxRow'
import { FirebaseTable, EmptyTableView } from 'libs/tables'
import classes from 'libs/modals/popups.module.scss'

export const TABLE_NAME = 'searchTiles'

class UnconnectedAddItemsPopup extends PopupComponent {
  onClick() {
    if (this.props.onConfirmAddCollection) {
      const selectedItems = this.getSelectedItems().map((item) => item.remove('itemIsSelected'))
      this.props.onConfirmAddCollection(selectedItems)
    }
  }

  getSelectedItems() {
    return (this.props.tableData || Map()).filter((row) => row.get('itemIsSelected') === true)
  }

  renderOnConfirm() {
    if (this.props.showCheckbox) {
      const addItemsButtonLabel =
        this.getSelectedItems().size === 1
          ? 'Add 1 Item'
          : `Add ${this.getSelectedItems().size} Items`
      return (
        <FlatButton
          label={addItemsButtonLabel}
          className='blueBackground'
          onClick={this.onClick.bind(this)}
        />
      )
    }
    return undefined
  }

  getAPI() {
    if (this.props.filter) {
      return `/tiles/?tileType=${this.props.filter}`
    }
    return '/tiles/'
  }

  getContent() {
    return (
      <div className={classes.addItemsPopupContainer}>
        <FilterBatch name={TABLE_NAME} showMobileInline>
          <SearchBar tableName={TABLE_NAME} placeholder='Search items' />
        </FilterBatch>
        <div className={classes.addItemsPopupInner}>
          <FirebaseTable
            useOffset
            api={this.getAPI()}
            firebaseJoin={Map({ uuid: 'tile' })}
            name={TABLE_NAME}
            waitForFilterLoad={false}
            sortPath='name'
            pageSize={50}
            hasSelectors
            hasNoSelectAll
            filterData={(tableData) => {
              if (this.props.itemToRemoveUUID) {
                return tableData.filterNot((row) => row.get('uuid') === this.props.itemToRemoveUUID)
              }
              return tableData
            }}
            rowsClickable
            emptyTable={
              <EmptyTableView
                title={`
                  You have no items to add or you have already added all your items to this collection
                `}
              >
                <span>Add more products or collections to view them here</span>
              </EmptyTableView>
            }
            getRow={(immutableRow, rowIndex) => {
              let className = ''
              if (!(rowIndex % 2)) {
                className = ` row-background`
              }
              return (
                <TileCheckboxRow
                  tableName={TABLE_NAME}
                  className={className}
                  tile={immutableRow}
                  key={immutableRow.get('uuid')}
                  onMouseDown={this.props.mouseDownHandler}
                  onMouseUp={this.props.mouseUpHandler}
                  showCheckbox={this.props.showCheckbox}
                  onClick={this.props.onClick}
                />
              )
            }}
          />
        </div>
        <div className={classes.buttonsContainer}>
          <FlatButton
            label='Cancel'
            type='reset'
            className='dismiss'
            onClick={this.props.onCancel}
          />
          {this.renderOnConfirm()}
        </div>
      </div>
    )
  }
}

export default connect((state, props) => ({
  tableData: state.tables.getIn([TABLE_NAME, 'data'], Map({})),
  collectionItems: state.tables
    .getIn([props.tableName, 'data'], Map({}))
    .concat(state.tables.getIn([props.tableName, 'addedItems'], Map({}))),
}))(UnconnectedAddItemsPopup)

UnconnectedAddItemsPopup.defaultProps = {
  showCheckbox: true,
}

UnconnectedAddItemsPopup.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  actionType: PropTypes.string,
  iconType: PropTypes.string,
  showCheckbox: PropTypes.bool,
}
