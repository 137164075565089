import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { SelectField } from 'libs/forms'
import { ListItem } from 'libs/list'
import { formatCurrency, makeTestID } from 'libs/utils'
import CheckboxButton from 'components/buttons/CheckboxButton'
import { FirebaseHandler } from 'libs/firebase'
import { Map } from 'immutable'
import { productToInvoiceEntry } from './utils'

function AddProductListItem({ product, selection, onSelectionChange, testID, defaultTaxes }) {
  const disabledVariants = (product.variants ?? [])
    .filter((choice) => !choice.enabled)
    .map((choice) => choice.choiceValues)

  const handleNewSelection = useCallback(
    (selectedVariant) => {
      onSelectionChange(productToInvoiceEntry(product, selectedVariant, defaultTaxes))
    },
    [onSelectionChange, product, defaultTaxes]
  )

  const handleSelectionVariantChange = useCallback(
    (choiceIndex, value) => {
      const selectedVariant = (product.variants || [])
        .filter((variant) => variant.enabled)
        .find(({ choiceValues }) =>
          choiceValues.every((choiceValue, index) =>
            index === choiceIndex
              ? choiceValue === value
              : choiceValue === selection.getIn(['variantChoiceValues', index])
          )
        )

      handleNewSelection(selectedVariant)
    },
    [product, selection, handleNewSelection]
  )

  return (
    <ListItem
      testID={makeTestID(testID, 'AddProductListItem')}
      accessoryLeft={<CheckboxButton selected={!!selection} />}
      onClick={() => {
        if (selection) {
          onSelectionChange(undefined)
        } else {
          handleNewSelection()
        }
      }}
      title={product.tile?.name}
      subTitle={
        selection ? selection.get('variantChoiceValues', []).toArray().join(', ') : undefined
      }
      description={formatCurrency(selection?.get('price') || product.defaultPrice, 'ZAR')}
    >
      {selection && product.variantGroups?.length ? (
        <div style={{ paddingLeft: 40, marginTop: 12 }}>
          {product.variantGroups.map(({ choiceName, choiceOptions }, choiceIndex) => (
            <SelectField
              key={choiceName}
              label={choiceName}
              value={selection.getIn(['variantChoiceValues', choiceIndex])}
              options={choiceOptions.map((value) => {
                let label = value
                let isDisabled = false

                if (product.variantGroups?.length === 1) {
                  label = disabledVariants[0]?.includes(value) ? `${value} (disabled)` : value
                  isDisabled = disabledVariants[0]?.includes(value)
                } else {
                  const previousChoice = selection.getIn(['variantChoiceValues', choiceIndex - 1])
                  const isPreviousChoiceDisabled = disabledVariants[0]?.includes(previousChoice)
                  isDisabled = isPreviousChoiceDisabled && disabledVariants[0]?.includes(value)
                  label = isDisabled ? `${value} (disabled)` : value
                }

                return { label, value, isDisabled }
              })}
              onChange={(selectedOption) => {
                handleSelectionVariantChange(choiceIndex, selectedOption.value)
              }}
              isFullWidth
            />
          ))}
        </div>
      ) : undefined}
    </ListItem>
  )
}

AddProductListItem.propTypes = {
  product: PropTypes.object.isRequired,
  selection: PropTypes.object,
  onSelectionChange: PropTypes.func,
  testID: PropTypes.string,
  defaultTaxes: PropTypes.object,
}

export default FirebaseHandler.connect(
  AddProductListItem,
  Map({
    defaultTaxes: {
      path: 'readOnly/store/salesTaxes',
    },
  })
)
