/* eslint-disable no-param-reassign */
import React, { useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import store from 'redux/store'
import { refreshTable, removeItem } from 'redux/modules/tables'
import SplitBlock from 'components/blocks/SplitBlock'
import { track } from 'libs/analytics'

import { InvoiceDetails } from './Details'
import { InvoicePreview } from './Preview'
import InvoiceCreate from './Create'
import InvoiceUpdate from './Update'
import InvoiceDelete from './Delete'
import InvoiceSend from './Send'
import { InvoiceActions } from './constants'

function InvoiceSingle({ invoice, setInvoice, tableName, children }) {
  const [action, _setAction] = useState()
  const previousActionRef = useRef()

  const setAction = useCallback(
    (nextStateValue) => {
      previousActionRef.current = action
      _setAction(nextStateValue)
    },
    [action, _setAction]
  )

  const handleClose = useCallback(() => {
    setAction(undefined)
    setInvoice(undefined)
  }, [setAction, setInvoice])

  const handleInvoiceSaved = useCallback(
    (nextInvoice, savedAsDraft) => {
      if (tableName) {
        store.dispatch(refreshTable(tableName))
      }

      if (savedAsDraft) {
        handleClose()
        return
      }

      setInvoice(nextInvoice)
      setAction(InvoiceActions.PreviewAndSend)
    },
    [tableName, setInvoice, setAction, handleClose]
  )

  const handleInvoiceDeleted = useCallback(
    (i) => {
      handleClose()
      setAction(undefined)
      if (tableName) {
        store.dispatch(removeItem(tableName, i.get('uuid')))
      }
    },
    [tableName, setAction, handleClose]
  )

  const handleInvoiceMarkedAsPaid = useCallback(
    (nextInvoice) => {
      setInvoice(nextInvoice)

      if (tableName) {
        store.dispatch(refreshTable(tableName))
      }
    },
    [setInvoice, tableName]
  )

  const handleInvoiceSent = useCallback(() => {
    if (tableName) {
      store.dispatch(refreshTable(tableName))
    }

    handleClose()
  }, [handleClose, tableName])

  // Patch children with Delete confirmation
  children = (
    <>
      {children}
      {invoice && invoice.get('id') && action === InvoiceActions.Delete ? (
        <InvoiceDelete
          invoice={invoice}
          onDeleted={handleInvoiceDeleted}
          onClose={() => setAction(undefined)}
        />
      ) : null}
      {invoice && invoice.get('id') && action === InvoiceActions.Send ? (
        <InvoiceSend
          showing
          invoice={invoice}
          onSent={handleInvoiceSent}
          onEsc={() => setAction(previousActionRef.current)}
        />
      ) : null}
    </>
  )

  if (!invoice) {
    // Keep returning SplitBlock component anyway
    return <SplitBlock showingSplit={false}>{children}</SplitBlock>
  }

  if (invoice.get('id')) {
    if (
      action === InvoiceActions.ViewPDF ||
      action === InvoiceActions.PreviewAndSend ||
      action === InvoiceActions.Send
    ) {
      return InvoicePreview({
        invoice,
        onSendPressed: () => setAction(InvoiceActions.Send),
        onCancelPressed: () => setAction(undefined),
        onClose: handleClose,
        children,
      })
    }

    if (invoice.get('status') === 'draft') {
      return InvoiceUpdate({
        invoice,
        onUpdated: handleInvoiceSaved,
        onDeletePressed: () => setAction(InvoiceActions.Delete),
        onClose: handleClose,
        children,
      })
    }

    return InvoiceDetails({
      invoice,
      onViewPdfPressed: () => {
        track('invoices_detals_view_pdf_button_clicked', {
          name: 'invoices_detals_view_pdf_button_clicked',
        })
        setAction(InvoiceActions.ViewPDF)
      },
      onMarkedAsPaid: handleInvoiceMarkedAsPaid,
      onClose: handleClose,
      children,
    })
  }

  return InvoiceCreate({
    invoice,
    onCreated: handleInvoiceSaved,
    onClose: handleClose,
    children,
  })
}

InvoiceSingle.propTypes = {
  invoice: PropTypes.object,
  setInvoice: PropTypes.func.isRequired,
  tableName: PropTypes.string,
  children: PropTypes.node,
}

export default InvoiceSingle
