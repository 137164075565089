import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Spacer from './Spacer'

export default function VStack({ children, spacing = 'medium', className, style }) {
  return (
    <div className={classnames(className)} style={style}>
      {React.Children.map(children, (child, index) => {
        return (
          <span>
            {child}
            {index !== children.size - 1 && child ? <Spacer size={spacing} /> : null}
          </span>
        )
      })}
    </div>
  )
}

VStack.propTypes = {
  spacing: PropTypes.oneOf(['xs', 'small', 'medium', 'large', 'xl']),
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
}
