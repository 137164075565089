import React from 'react'
import PropTypes from 'prop-types'
import { selectBankAccountUpdateRequestState } from 'redux/modules/onboarding/bankDetails/selectors'
import { connect } from 'react-redux'
import Alert from 'components/notifications/Alert'
import { selectIsRegisteredBusiness } from 'redux/modules/session/selectors'
import FlatButton from 'components/buttons/FlatButton'
import { BANK_REQUEST_STATUSES } from 'redux/modules/onboarding/bankDetails'
import ConfirmationRequiredAlert from './ConfirmationRequiredAlert'
import StuckInRequestedAlert from './StuckInRequestedAlert'
import ChangeRequiredAlert from './ChangeRequiredAlert'
import VerificationAlert from './VerificationAlert'

function UpdateStateMessageAlert({
  bankAccountUpdateRequestState,
  isRegisteredBusiness,
  onEditButtonClick,
  isFormEnabled,
}) {
  const alert = (message, messageType = 'info') => {
    return (
      <Alert messageType={messageType} message={message} closable={false}>
        {!isFormEnabled && (
          <>
            <br />
            <br />
            <FlatButton
              label='Change bank details'
              type='reset'
              className='blueBackground'
              onClick={onEditButtonClick}
            />
          </>
        )}
      </Alert>
    )
  }

  if (bankAccountUpdateRequestState === BANK_REQUEST_STATUSES.REQUESTED) {
    return <StuckInRequestedAlert />
  }

  if (bankAccountUpdateRequestState === BANK_REQUEST_STATUSES.CONFIRMATION_REQUIRED) {
    return <ConfirmationRequiredAlert />
  }

  if (
    bankAccountUpdateRequestState === BANK_REQUEST_STATUSES.CHANGE_REQUIRED ||
    bankAccountUpdateRequestState === BANK_REQUEST_STATUSES.INFORMATION_REQUIRED
  ) {
    return (
      <ChangeRequiredAlert onEditButtonClick={onEditButtonClick} isFormEnabled={isFormEnabled} />
    )
  }

  if (
    bankAccountUpdateRequestState === BANK_REQUEST_STATUSES.EXECUTED ||
    bankAccountUpdateRequestState === BANK_REQUEST_STATUSES.MANUALLY_EXECUTED
  ) {
    return <VerificationAlert />
  }

  if (!bankAccountUpdateRequestState) {
    const message = isRegisteredBusiness
      ? 'Please make sure to enter a business bank account registered to the business.'
      : `This is the account where you’ll receive the funds from all your sales.`
    return alert(message)
  }

  return null
}

UpdateStateMessageAlert.propTypes = {
  bankAccountUpdateRequestState: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  isRegisteredBusiness: PropTypes.bool.isRequired,
  onEditButtonClick: PropTypes.func,
  isFormEnabled: PropTypes.bool,
}

UpdateStateMessageAlert.defaultProps = {
  bankAccountUpdateRequestState: undefined,
}

const mapStateToProps = (state) => ({
  isRegisteredBusiness: selectIsRegisteredBusiness(state),
  bankAccountUpdateRequestState: selectBankAccountUpdateRequestState(state),
})

export default connect(mapStateToProps)(UpdateStateMessageAlert)
