/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-unused-class-component-methods */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Immutable, { Map } from 'immutable'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { FirebaseTable, EmptyTableView, TableRow } from 'libs/tables'
import ItemIcon from 'components/items/ItemIcon'
import SplitBlock from 'components/blocks/SplitBlock'
import ConfirmPopup from 'components/popups/ConfirmPopup'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import FlatButton from 'components/buttons/FlatButton'
import DropDownButton from 'components/buttons/DropDownButton'
import { showMessage } from 'redux/modules/notifications'
import { FilterBatch } from 'libs/filters'
import SearchBar from 'components/filters/SearchBar'
import EditProductForm from 'containers/store/EditProductForm'
import { removeItem } from 'redux/modules/tables'
import { makeTestID, mouseDownHandler, mouseUpHandler } from 'libs/utils'
import { callJSONApi } from 'libs/api'
import { Amount, If } from 'libs/formats'
import CONSTANTS from 'libs/constants'
import UploadProductsPopup from 'components/popups/UploadProductsPopup'
import { FirebaseHandler, features } from 'libs/firebase'
import ExportButton from 'components/buttons/ExportButton'
import layout from 'ui/layout/layout.module.scss'
import BlockHeader from 'components/headers/BlockHeader'
import DesktopOnly from 'ui/layout/DesktopOnly'
import OnlineStoreBackButton from 'containers/online/store/components/OnlineStoreBackButton'

import Tag from 'components/tag/Tag'
import classes from './store.module.scss'

const baseTestID = makeTestID('menu', 'productsPage')

const FIREBASE_JOIN = Immutable.fromJS({ uuid: 'product' })
const TABLE_NAME = 'storeProducts'

class ProductsPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showingSplit: false,
      shownRow: null,
    }
  }

  onDeleteItem(uuid) {
    callJSONApi(
      `/product/delete/${uuid}/`,
      'POST',
      {},
      (response) => {
        if (response.status === 200) {
          this.props.dispatch(removeItem(TABLE_NAME, uuid))
          this.hideDeletePopup()
          this.splitViewClosed()
        } else {
          this.props.dispatch(showMessage(`Error removing location: ${response.message}`))
          this.hideDeletePopup()
        }
      },
      (prettyError) => {
        this.props.dispatch(showMessage(`Error removing location: ${prettyError}`, 'danger'))
        this.hideDeletePopup()
      }
    )
  }

  getSplitBlockHeader() {
    return this.state.shownRow ? `${this.state.shownRow.getIn(['tile', 'name'])}` : 'Add a Product'
  }

  splitViewClosed = () => {
    this.setState({
      shownRow: null,
      showingSplit: false,
    })
  }

  addProduct() {
    this.setState({
      showingSplit: true,
    })
  }

  showRow(rowData) {
    this.setState((currentState) => {
      const newState = { ...currentState }

      if (newState.shownRow && newState.shownRow.get('uuid') === rowData.get('uuid')) {
        newState.shownRow = null
      } else {
        newState.shownRow = rowData
      }

      if (newState.shownRow) {
        newState.showingSplit = true
      } else {
        newState.showingSplit = false
      }

      return newState
    })
  }

  mouseDownHandler() {
    mouseDownHandler()
  }

  mouseUpHandler() {
    mouseUpHandler()
  }

  uploadPopup() {
    return (
      <UploadProductsPopup
        showing={this.state.showUploadModal}
        onEsc={() => this.showUploadModal(false)}
      />
    )
  }

  checkForProducts(importFeatureEnabled) {
    const { productData } = this.props
    // only display the export button if there are products to export.
    if (
      importFeatureEnabled &&
      productData &&
      productData.get('storeProducts') &&
      !productData.get('storeProducts').get('hasNoData')
    ) {
      return (
        <div className={classes.exportDropDown}>
          <DropDownButton
            label='Export Products'
            buttonClass='secondary'
            actions={this.buttonActions()}
            ButtonComponent={ExportButton}
          />
        </div>
      )
    }
    return null
  }

  buttonActions() {
    const containerStyle = {
      width: '150px',
    }

    const actionButtons = [
      {
        label: 'Export CSV',
        buttonProps: {
          buttonClass: classnames(['tertiary', layout.widthFull]),
          exportApi: '/product/export/products.csv',
          label: 'Export CSV',
          containerStyle,
          onClick: () => undefined,
        },
      },
      {
        label: 'Export XLSX',
        buttonProps: {
          buttonClass: classnames(['tertiary', layout.widthFull]),
          exportApi: '/product/export/products.xlsx',
          label: 'Export XLSX',
          containerStyle,
          onClick: () => undefined,
        },
      },
    ]

    return actionButtons
  }

  showUploadModal(bool) {
    this.setState({
      showUploadModal: bool,
    })
  }

  deletePressed() {
    this.setState((prevState) => ({
      showDeleteModal: true,
      deleteUUID: prevState.shownRow.get('uuid'),
    }))
  }

  deletePopUp() {
    return (
      <ConfirmPopup
        showing={this.state.showDeleteModal}
        onCancel={() => this.hideDeletePopup()}
        title='Remove'
        actionType='destructive'
        subtitle='Are you sure you want to remove this product?'
        onConfirm={() => {
          this.onDeleteItem(this.state.deleteUUID)
        }}
      />
    )
  }

  hideDeletePopup() {
    this.setState({
      showDeleteModal: false,
      deleteUUID: null,
    })
  }

  renderTableRow(rowData, rowIndex) {
    return (
      <TableRow
        rowIndex={rowIndex}
        key={`row-${rowIndex}`}
        onClick={() => this.showRow(rowData)}
        selected={this.state.shownRow && this.state.shownRow.get('uuid') === rowData.get('uuid')}
      >
        <td className={classes.image}>
          <ItemIcon tile={rowData.get('tile')} />
        </td>
        <td className={classes.name}>{rowData.getIn(['tile', 'name'])}</td>
        <td className={`hide-on-mobile ${classes.itemName}`}>
          {rowData.getIn(['onlineProduct', 'isEnabled']) && <Tag text='Online Product' />}
          {rowData.getIn(['tile', 'description'])}
        </td>
        <td className={classes.amount}>
          <Amount amount={rowData.get('defaultPrice')} />
        </td>
      </TableRow>
    )
  }

  renderSplitContent() {
    return (
      <div>
        <EditProductForm
          formName={TABLE_NAME}
          firebaseJoin={FIREBASE_JOIN}
          localObject={this.state.shownRow}
          showing={this.state.showingSplit}
          showButtons={this.state.showingSplit}
          mouseDownHandler={() => this.mouseDownHandler()}
          mouseUpHandler={() => this.mouseUpHandler()}
          closeSplitView={this.splitViewClosed}
        />
      </div>
    )
  }

  renderTableHeader() {
    return (
      <tr className='visible-header'>
        <th className={classes.image} />
        <th>Name</th>
        <th className='hide-on-mobile'>Description</th>
        <th className={classes.price}>Price</th>
      </tr>
    )
  }

  render() {
    const importFeatureEnabled = (this.props.features || Map()).getIn(['importProducts', 'enabled'])
    const locationState = this.props.location.state
    const response = locationState && locationState.prevPath
    return (
      <SplitBlock
        formName={TABLE_NAME}
        showDelete={!!this.state.shownRow}
        header={this.getSplitBlockHeader()}
        showingSplit={this.state.showingSplit}
        saveLabel={this.state.shownRow ? 'Save' : 'Add'}
        deletePressed={() => {
          this.setState((prevState) => ({
            showDeleteModal: true,
            deleteUUID: prevState.shownRow.get('uuid'),
          }))
        }}
        onDismiss={() => this.splitViewClosed()}
        renderSplitContent={() => this.renderSplitContent()}
      >
        <MaxWidthBlock className={classes.itemsPage}>
          {response === '/online/store' && <OnlineStoreBackButton />}
          <BlockHeader
            title='Products'
            actions={
              <div className={classes.buttonContainer}>
                <If condition={importFeatureEnabled}>
                  <DesktopOnly className={layout.inlineBlock}>
                    {this.checkForProducts(importFeatureEnabled)}
                    <FlatButton
                      className='blueBorder'
                      label='Import Products'
                      onClick={() => this.showUploadModal(true)}
                      onMouseDown={() => this.mouseDownHandler()}
                      onMouseUp={() => this.mouseUpHandler()}
                    />
                  </DesktopOnly>
                </If>
                <FlatButton
                  className='blueBackground'
                  label='Add Product'
                  onClick={() => this.addProduct()}
                  onMouseDown={() => this.mouseDownHandler()}
                  onMouseUp={() => this.mouseUpHandler()}
                  testID={makeTestID(baseTestID, 'addProduct')}
                />
              </div>
            }
          />
          <FilterBatch name={TABLE_NAME} showMobileInline>
            <SearchBar
              placeholder='Search by product name'
              testID={makeTestID(baseTestID, 'searchBar')}
            />
          </FilterBatch>

          <FirebaseTable
            useOffset
            waitForFilterLoad={false}
            hideNoResults
            rowsClickable
            name={TABLE_NAME}
            api='/product/'
            sortPath='tile.name'
            firebaseJoin={FIREBASE_JOIN}
            emptyTable={
              <EmptyTableView
                title={`
                  Your business currently has no products or you may need to change your filter'
                `}
              >
                <span>
                  Click &nbsp;
                  <b
                    style={{ color: CONSTANTS.YOCO_BLUE, cursor: 'pointer' }}
                    onClick={this.addProduct.bind(this)}
                  >
                    Add Product
                  </b>{' '}
                  to add products to your store
                </span>
              </EmptyTableView>
            }
            getHeader={this.renderTableHeader}
            getRow={(immutableRow, rowIndex) => this.renderTableRow(immutableRow, rowIndex)}
          />
        </MaxWidthBlock>

        {this.deletePopUp()}

        {this.uploadPopup()}
      </SplitBlock>
    )
  }
}

ProductsPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  productData: PropTypes.object,
  location: PropTypes.object,
  features: PropTypes.object,
}

const ReduxConnectedPage = connect((state) => ({
  productData: state.tables,
}))(ProductsPage)

export default FirebaseHandler.connect(
  ReduxConnectedPage,
  Map({
    features,
  })
)
