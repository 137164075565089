/* eslint-disable no-restricted-syntax */
export function getQueryParams(decode = true) {
  let qs = window.location.search
  qs = qs.split('+').join(' ')
  const params = {}
  let tokens
  const re = /[?&]?([^=]+)=([^&]*)/g
  // eslint-disable-next-line no-cond-assign
  while ((tokens = re.exec(qs))) {
    if (decode) {
      params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2])
    } else {
      params[tokens[1]] = params[tokens[2]]
    }
  }
  return params
}

export function getQueryParamByName(param) {
  return getQueryParams()[param]
}

export function getAllUTMParams() {
  const params = getQueryParams()
  const utms = {
    utm_source: true,
    utm_medium: true,
    utm_campaign: true,
    utm_group: true,
    utm_variation: true,
    utm_experiment: true,
    utm_term: true,
    utm_content: true,
    google_click_id: true,
    token: true,
    gclid: true,
  }
  const utmParams = {}
  for (const key in params) {
    // eslint-disable-next-line no-prototype-builtins
    if (params.hasOwnProperty(key)) {
      if (utms[key]) {
        utmParams[key] = params[key]
      }
    }
  }
  return utmParams
}

/**
 * Build a URI query string from components.
 *
 * @param {object|array} query - Object or array of pairs of query string components.
 * @return {string} URI query string including the `?`.
 */
export const buildQuery = (query) => {
  const queryPairs = (query && query.constructor === Object ? Object.entries(query) : query) || []
  const queryString = queryPairs
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&')
  return queryString.length ? `?${queryString}` : ''
}
