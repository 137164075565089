import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import * as forms from 'libs/forms'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import FlatButton from 'components/buttons/FlatButton'
import SplitBlock from 'components/blocks/SplitBlock'
import ItemImageUpload from 'components/items/ItemImageUpload'
import { fieldUpdated } from 'redux/modules/forms'
import LoadingView from 'components/loaders/LoadingView'
import { callCommonJSONAPI } from 'libs/api'
import BlockHeader from 'components/headers/BlockHeader'
import Spacer from 'ui/layout/Spacer'

import ReceiptReport from './ReceiptReport'
import classes from './receiptConfig.module.scss'

const FORM_NAME = 'receiptForm'

class ReceiptConfigPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      receipt: null,
      showingSplit: false,
    }
  }

  onClearLogo() {
    this.props.dispatch(fieldUpdated(FORM_NAME, 'receiptLogoUUID', null))
    this.props.dispatch(fieldUpdated(FORM_NAME, 'receiptLogoURL', null))
    this.props.dispatch(fieldUpdated(FORM_NAME, 'removeLogo', true))
  }

  onSuccess() {
    this.setState({
      showingSplit: false,
      receipt: null,
    })
  }

  fetchReceipt() {
    const api = '/receipts/fakeReceipt'

    let data = {}
    if (this.props.formData) {
      data = this.props.formData.toJS()
    }

    callCommonJSONAPI(
      api,
      'POST',
      data,
      (response) => {
        this.setState({
          receipt: response.data.receipt,
        })
      },
      (prettyError) => {
        this.setState({
          // eslint-disable-next-line
          error: prettyError,
        })
      },
      true
    )
  }

  previewReceipt() {
    this.setState({
      showingSplit: true,
      receipt: null,
    })
    this.fetchReceipt()
  }

  splitViewClosed() {
    this.setState({
      receipt: null,
      showingSplit: false,
    })
  }

  renderLogoRemove() {
    if (this.props.logoURL) {
      return (
        <>
          <Spacer size='small' isHorizontal />
          <FlatButton label='Remove' className='destructive' onClick={() => this.onClearLogo()} />
        </>
      )
    }
    return undefined
  }

  renderLogo() {
    if (this.props.logoURL) {
      return (
        <div style={{ marginBottom: '10px' }}>
          <img src={this.props.logoURL} width='160' alt='Company Logo' />
        </div>
      )
    }
    return undefined
  }

  renderLogoUpload() {
    return (
      <div className={classes.receiptUpload}>
        <forms.Label>Business Logo</forms.Label>
        {this.renderLogo()}

        <div className={classes.receiptLogoUploadBlock}>
          <div className={classes.receiptLogo}>
            Upload logo
            <ItemImageUpload
              // eslint-disable-next-line
              ref='receiptLogo'
              style={{ height: '40px' }}
              grouping='receipt-logo'
              isTile
              loaderStyle={classes.loaderStyle}
              completeCallback={(data) => {
                this.props.dispatch(fieldUpdated(FORM_NAME, 'receiptLogoUUID', data.data.uuid))
                this.props.dispatch(fieldUpdated(FORM_NAME, 'receiptLogoURL', data.data.url))
                this.props.dispatch(fieldUpdated(FORM_NAME, 'removeLogo', false))
                this.setState({
                  // eslint-disable-next-line
                  logoURL: data.data.url,
                })
              }}
            />
          </div>
          {this.renderLogoRemove()}
        </div>
      </div>
    )
  }

  renderSplitContent() {
    if (this.state.receipt) {
      return <ReceiptReport receipt={this.state.receipt} />
    }
    return <LoadingView message='Loading receipt' />
  }

  render() {
    const receiptTypes = [
      { label: 'Receipt', value: 'receipt' },
      { label: 'Tax Invoice', value: 'invoice' },
    ]

    return (
      <SplitBlock
        header='Receipt Preview'
        showingSplit={this.state.showingSplit}
        renderSplitContent={() => this.renderSplitContent()}
        saveLabel='Save'
        formName={FORM_NAME}
        persistData
        contentStyle={{ overflow: 'hidden', position: 'relative' }}
        onDismiss={() => this.splitViewClosed()}
      >
        <MaxWidthBlock>
          <BlockHeader title='Receipt Configuration' />
          <div className={classes.receiptForm}>
            <forms.Form
              name={FORM_NAME}
              action='/business/receiptConfig'
              fetchAPI='/business/receiptConfig'
              persistData
              onSuccess={() => this.onSuccess()}
            >
              <forms.FormBlock heading='Receipt Details' infoText=''>
                <div className={classes.imageInputUploader}>{this.renderLogoUpload()}</div>
                <forms.SelectField
                  name='receiptType'
                  label='Receipt Type'
                  isFullWidth
                  placeholder='Please select one'
                  options={receiptTypes}
                />
                <forms.TextField
                  placeholder='eg. Lets Grow'
                  name='receiptHeader'
                  maxLength={40}
                  label='Company slogan'
                  isFullWidth
                />
                <forms.TextField
                  placeholder='eg. Please retain your receipt'
                  isFullWidth
                  maxLength={1000}
                  name='receiptFooter'
                  label='Receipt Footer'
                />
                <forms.SwitchField
                  name='showPhoneInReceipt'
                  label='Show phone number'
                  isFullWidth
                />
              </forms.FormBlock>

              <forms.FormBlock heading='Social Media Handles' infoText=''>
                <forms.TextField
                  placeholder='eg. YocoZA'
                  isFullWidth
                  name='socialMediaHandles.facebookURL'
                  label='Facebook'
                />
                <forms.TextField
                  placeholder='eg. Yoco_ZA'
                  isFullWidth
                  name='socialMediaHandles.twitterURL'
                  label='Twitter'
                />
                <forms.TextField
                  placeholder='eg. Yoco_ZA '
                  isFullWidth
                  name='socialMediaHandles.instagramURL'
                  label='Instagram'
                />
              </forms.FormBlock>

              <div>
                <FlatButton
                  label='Preview receipt'
                  type='button'
                  onClick={() => this.previewReceipt()}
                />
                <FlatButton label='Save' type='submit' className='blueBackground' />
              </div>
            </forms.Form>
          </div>
        </MaxWidthBlock>
      </SplitBlock>
    )
  }
}

ReceiptConfigPage.propTypes = {
  formData: PropTypes.object,
  dispatch: PropTypes.func,
  logoURL: PropTypes.string,
}

export default connect((state) => ({
  formData: state.forms.getIn([FORM_NAME, 'data'], Map()),
  businessUUID: state.session.getIn(['business', 'uuid']),
  logoURL: state.forms.getIn([FORM_NAME, 'data', 'receiptLogoURL'], ''),
}))(ReceiptConfigPage)
