import { labelType } from 'components/navbars/RouteLabel.constants'

import ReferralPage from './ReferralPage'

export default function getRoutes() {
  return {
    path: 'lets-grow',
    text: 'Referrals',
    icon: 'referral',
    component: ReferralPage,
    label: labelType.REFERRAL,
  }
}
