/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import classnames from 'classnames'
import { connect } from 'react-redux'

import { hasFeature } from 'libs/features'
import { track } from 'libs/analytics'
import layout from 'ui/layout/layout.module.scss'
import typography from 'ui/typography/typography.module.scss'
import FlatButton from 'components/buttons/FlatButton'
import DownloadAppPopup from 'components/popups/DownloadAppPopup'

import classes from './infoCard.module.scss'
import onboardingInfo from './onboardingInfo'
import featuredSolutions from './featuredSolutions'
import moreSolutions from './moreSolutions'
import paymentPageInfo from './paymentPageInfo'
import referralInfo from './referralInfo'

function InfoCard({ title, img, blurb, linkAText, linkAHref, popUp, showPopup, tracking }) {
  const toLink = popUp ? '' : linkAHref

  const OnClick = () => {
    track('info_card_button_clicked', { name: linkAText, identifier: tracking })
    if (popUp) {
      showPopup()
    }
  }

  const target = linkAHref?.includes('http') && !popUp ? '_blank' : ''
  return (
    <div className={classnames(classes.newCardHolder, layout.flexOne, classes.cardHolder)}>
      <img alt={title} src={img} className={classes.cardHolderImage} />
      <h2 className={typography.h2}>{title}</h2>
      <p>{blurb}</p>
      {!!linkAText && (
        <span className={classes.infoButton}>
          <Link to={toLink} target={target} onlyActiveOnIndex={false}>
            <FlatButton
              label={linkAText}
              className={`blueBackground formFieldButton ${classes.button}`}
              onClick={() => OnClick()}
            />
          </Link>
        </span>
      )}
    </div>
  )
}

InfoCard.propTypes = {
  title: PropTypes.string,
  img: PropTypes.string,
  blurb: PropTypes.string,
  linkAText: PropTypes.string,
  linkAHref: PropTypes.string,
  popUp: PropTypes.bool,
  showPopup: PropTypes.func,
  tracking: PropTypes.string,
}

class UnconnectedInfoCardHolder extends Component {
  constructor(props) {
    super(props)
    const { hasManagePaymentLinksPortal } = props
    this.state = {
      showPopup: false,
      list: props.featuredSolutions
        ? featuredSolutions
        : props.referralInfo
        ? referralInfo
        : props.moreSolutions
        ? moreSolutions
        : props.paymentPageInfo
        ? paymentPageInfo
        : onboardingInfo({
            hasManagePaymentLinksPortal,
          }),
    }
    this.showPopup.bind(this)
  }

  showPopup() {
    this.setState({
      showPopup: true,
    })
  }

  close() {
    this.setState({
      showPopup: false,
    })
  }

  render() {
    const cardContainerClassNames = classnames(
      classes.newCardContainer,
      layout.itemsStretch,
      layout.flex,
      classes.cardContainer
    )
    return (
      <div>
        <div>
          <div className={cardContainerClassNames}>
            {this.state.list.map((info, key) => {
              return <InfoCard key={key} {...info} showPopup={() => this.showPopup()} />
            })}
          </div>
          <DownloadAppPopup showing={this.state.showPopup} onEsc={() => this.close()} />
        </div>
      </div>
    )
  }
}

UnconnectedInfoCardHolder.propTypes = {
  featuredSolutions: PropTypes.bool,
  moreSolutions: PropTypes.bool,
  paymentPageInfo: PropTypes.bool,
  referralInfo: PropTypes.bool,
  hasManagePaymentLinksPortal: PropTypes.bool,
}

const InfoCardHolder = connect((state) => ({
  hasManagePaymentLinksPortal: hasFeature(state, 'managePaymentLinksPortal'),
}))(UnconnectedInfoCardHolder)

export default InfoCardHolder
