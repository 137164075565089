import React from 'react'

import { FilterBatch } from 'libs/filters'
import SearchBar from 'components/filters/SearchBar'
import { makeTestID } from 'libs/utils'
import FiltersContainer from 'components/filters/FiltersContainer'

import { TableName } from './constants'

function CustomerListFilters() {
  return (
    <FiltersContainer>
      <FilterBatch name={TableName} showMobileInline>
        <SearchBar placeholder='Search directory' testID={makeTestID(TableName, 'searchBar')} />
      </FilterBatch>
    </FiltersContainer>
  )
}

export default CustomerListFilters
