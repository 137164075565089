import VouchersPageActivatedPage from 'containers/vouchers/VouchersPageActivatedPage'
import VouchersPage from 'containers/vouchers/VouchersPage'

export default function voucherRoutes() {
  return {
    path: 'vouchers',
    text: `Vouchers`,
    icon: 'voucher',
    hideInMenu: true,
    routes: [
      {
        path: 'activate',
        text: 'Gift vouchers',
        component: VouchersPage,
      },
      {
        path: 'activated',
        text: 'Voucher page activated',
        component: VouchersPageActivatedPage,
      },
    ],
  }
}
