import classnames from 'classnames'
import CheckboxButton from 'components/buttons/CheckboxButton'
import { getLendingPartnerDisplayName } from 'components/capital/utils'
import React from 'react'
import { LendingPartners } from 'redux/modules/capital/types'
import Spacer from 'ui/layout/Spacer'

import styles from './CapitalCashAdvanceAgreement.module.scss'

interface Props {
  providerName: LendingPartners
  agreedToTerms: boolean
  contractURL: string
  setAgreedToTerms: (agreedToTerms: boolean) => void
}

const CapitalCashAdvanceAgreement: React.FunctionComponent<Props> = ({
  providerName,
  agreedToTerms,
  contractURL,
  setAgreedToTerms,
}) => {
  const handleCheckboxClick = () => setAgreedToTerms(!agreedToTerms)

  return (
    <section>
      <h4>Cash Advance Agreement</h4>
      <p className={styles.firstParagraph}>
        The terms below outline your agreement with {getLendingPartnerDisplayName(providerName)}.
        Your agreement will always be available here. If you have any questions contact us on{' '}
        <a className={styles.email} href='mailto:capital@yoco.co.za'>
          capital@yoco.co.za
        </a>
        .
      </p>
      <hr />
      <p className={styles.secondParagraph}>
        Acceptance of this offer constitutes a binding contract between{' '}
        {getLendingPartnerDisplayName(providerName)} and yourself. Before you accept this offer you
        must agree to the terms of our Sales and Purchase Agreement, by ticking the box next to “I
        agree”. The person accepting the offer on your behalf warrants that he/she is authorised to
        contract on behalf of the Company.
      </p>
      <div>
        <a href={contractURL} target='_blank' rel='noopener noreferrer'>
          Read the full agreement
        </a>
      </div>
      <div
        className={styles.checkboxAndDownloadContractContainer}
        aria-label='checkbox and download contract'
      >
        <div className={classnames([styles.checkboxContainer])}>
          <CheckboxButton onClick={handleCheckboxClick} selected={agreedToTerms} />
          <Spacer isHorizontal size='xs' />
          <div>I agree</div>
        </div>
        <a href={contractURL} target='_blank' rel='noopener noreferrer'>
          Download contract
        </a>
      </div>
    </section>
  )
}

export default CapitalCashAdvanceAgreement
