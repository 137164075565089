import React, { useEffect } from 'react'

import useReferralStore from '../store'

import ReferralSummary from './ReferralSummary'
import ShareCard from './ShareCard'
import Commissions from './Commissions'
import classes from './referrals.module.scss'
import ReferralCard from './ReferralCard'

const useStore = () => ({
  fetchReferralCode: useReferralStore((state) => state?.fetchReferralCode),
  fetchReferrals: useReferralStore((state) => state?.fetchReferrals),
  fetchSummary: useReferralStore((state) => state?.fetchSummary),
  referralLink: useReferralStore((state) => state?.referralLink),
})

function Referrals() {
  const { fetchReferralCode, fetchReferrals, fetchSummary } = useStore()

  useEffect(() => {
    fetchReferralCode()
    fetchReferrals()
    fetchSummary()
  }, [fetchSummary, fetchReferralCode, fetchReferrals])

  return (
    <div className={classes.referralsContainer}>
      <ReferralSummary />
      <ReferralCard />
      <ShareCard />
      <Commissions />
    </div>
  )
}

export default Referrals
