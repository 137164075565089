/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react'
import { List } from 'immutable'
import { Icon } from '@yoco/design-system-icons/dist/react'

import DismissComponent from 'libs/DismissComponent'
import Spinner from 'components/loaders/Spinner'
import { makeTestID } from 'libs/utils'
import FlatButton from 'components/buttons/FlatButton'
import PaddedContent from 'ui/layout/PaddedContent'

import classes from './filters.module.scss'

export default class Filter extends DismissComponent {
  constructor(props) {
    super(props)
    this.requestIndex = 0
    this.pageNumber = 0
    this.wasInitialized = false

    const defaults = props.defaults || []
    const filterOptions = this.loadAvailableOptions(defaults)
    const availableFilterOptionKeys = []
    const acceptsCardPayments = props.localisation
      ? props.localisation.get('acceptsCardPayments', true)
      : true

    if (filterOptions) {
      const filterOptionKeys = Object.keys(filterOptions)
      filterOptionKeys.forEach((filterOptionKey) => {
        if (acceptsCardPayments) {
          // currently we show all filter options for card accepting localisation.
          availableFilterOptionKeys.push(filterOptionKey)
        } else if (!filterOptions[filterOptionKey].isForCardAcceptingLocalisation) {
          availableFilterOptionKeys.push(filterOptionKey)
        }
      })
    }

    this.state = {
      availableOptions: filterOptions,
      availableOptionKeys: availableFilterOptionKeys,
      multiSelectEnabled: this.props.multipleSelectAsDefault,
      loading: this.props.startLoading,
    }
  }

  componentDidMount() {
    if (this.props.batch) {
      this.initializeFilter(this.props.defaults)
    }
  }

  initializeFilter(defaults) {
    this.wasInitialized = true
    this.props.batch.filterInitialized(this.props.filterKey, defaults)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.batch && !this.wasInitialized) {
      this.initializeFilter(nextProps.defaults || [])
      this.searchInitialized(nextProps.search || '')
    }
  }

  loadAvailableOptions(defaults) {
    if (this.props.loadAvailableOptions) {
      return this.props.loadAvailableOptions(defaults)
    }

    return undefined
  }

  isSelected(key) {
    return (this.props.values || List()).contains(key)
  }

  getSelectedString() {
    if (this.getFilterColumns() > 1) {
      const selectedString = this.state.availableOptionKeys
        .map((key) => {
          if (this.isSelected(key)) {
            const option = this.state.availableOptions[key]
            return option.shortTitle ? option.shortTitle : option.title
          }

          return undefined
        })
        .filter(Boolean)
        .join(', ')

      return selectedString || this.props.emptySelectedString
    }

    return ' '
  }

  isNoneSelected() {
    if ((this.props.values || List()).size > 0) {
      return false
    }

    return true
  }

  optionSelectionChanged(key) {
    this.props.batch.onFilterChanged(this.props.filterKey, key, this.state.multiSelectEnabled)
  }

  getOptionButtonClass(key = null) {
    let className = classes.optionButton
    if ((key && this.isSelected(key)) || (!key && this.isNoneSelected())) {
      className = `${className} ${classes.selectedOptionButton}`
    }

    return className
  }

  getOptionButton(key = null) {
    return (
      <div className={this.getOptionButtonClass(key)}>
        <button type='button' />
      </div>
    )
  }

  searchBoxTextChanged = (event) => {
    this.setState({
      searchString: event.target.value,
    })

    this.pageNumber = 0

    this.loadAvailableOptions(null)
  }

  getSearchbox() {
    if (this.props.searchable) {
      return (
        <div className={classes.searchBox} data-testid={makeTestID('sales', 'staff', 'search')}>
          <input
            placeholder={this.props.placeholder}
            value={this.state.searchString}
            onKeyUp={this.searchBoxTextChanged}
            onChange={this.searchBoxTextChanged}
          />
        </div>
      )
    }

    return undefined
  }

  loadMore() {
    this.pageNumber += 1

    this.loadAvailableOptions(null)
  }

  getDropdownContent() {
    let load
    if (this.state.loading) {
      load = <Spinner blue />
    } else if (this.state.showLoadMore) {
      load = (
        <div className='text-center'>
          <FlatButton label='Load more' onClick={this.loadMore.bind(this)} />
        </div>
      )
    }

    return (
      <div className={classes.optionsContainer}>
        {this.state.availableOptionKeys.map((key) => {
          const availableOption = this.state.availableOptions[key]
          let optionClass = classes.option
          if (
            this.state.availableOptionKeys.indexOf(key) ===
            this.state.availableOptionKeys.length - 1
          ) {
            optionClass = `${optionClass} ${classes.lastOption}`
          }

          let icon
          if (availableOption.icon) {
            icon = <Icon name={availableOption.icon} size={24} />
          }

          let subtext
          if (availableOption.subtext) {
            subtext = <div className={classes.subtext}>{availableOption.subtext}</div>
          }

          return (
            <div
              className={optionClass}
              onClick={this.optionSelectionChanged.bind(this, key)}
              key={key}
              data-testid={availableOption.testID}
            >
              {icon}
              <div className={`${classes.textBlock} selectable`}>
                <div className={classes.title}>{availableOption.title}</div>
                {subtext}
              </div>
              {this.getOptionButton(key)}
            </div>
          )
        })}
        {load}
      </div>
    )
  }

  getErrorContent() {
    if (this.state.error) {
      return (
        <div className='alert alert-danger' onClick={this.retry.bind(this)}>
          There was an error fetching results, click to retry
        </div>
      )
    }

    return undefined
  }

  retry() {
    this.loadAvailableOptions(null)
  }

  getFilterBoxIcon() {
    return 'icon-arrow-65'
  }

  getDropdownClassName() {
    let className = `${classes.dropdown} unselectable`
    if (!this.props.shown) {
      className += ' hidden'
    }
    if (this.props.showLeft) {
      className += ` ${classes.leftDropdown}`
    }

    return className
  }

  multipleSelectChanged = () => {
    this.setState((state) => {
      return {
        ...state,
        multiSelectEnabled: !state.multiSelectEnabled,
      }
    })
  }

  getDropdown() {
    return (
      <div className={this.getDropdownClassName()}>
        <div>
          <div className={classes.closeButton} onClick={this.toggle.bind(this)}>
            <Icon name='close' size={24} />
          </div>
          {this.getSearchbox()}
          {this.getErrorContent()}
          {this.getDropdownContent()}
        </div>
        <div className={classes.optionsFooter}>
          <div
            className={`${classes.option} ${classes.emptyOption}`}
            onClick={this.optionSelectionChanged.bind(this, null)}
            data-testid={makeTestID(this.props.testID, 'allTransactions')}
          >
            {this.getOptionButton()}
            <div className={classes.textBlock}>
              <div className={classes.title}>{this.props.emptySelectedString}</div>
            </div>
          </div>
        </div>
        <PaddedContent className='text-right'>
          <FlatButton
            className='blueBackground'
            label='Done'
            testID={makeTestID(this.props.testID, 'done')}
            onClick={() => {
              this.toggle()
            }}
          />
        </PaddedContent>
      </div>
    )
  }

  getFilterColumns() {
    if (this.props.filterColumns) {
      return this.props.filterColumns
    }

    return 2
  }

  getFilterClass() {
    let baseClass = `${classes.filter} unselectable`
    if (this.props.shown) {
      baseClass += ` ${classes.toggledFilter}`
    }
    if (this.props.noPosition) {
      baseClass += ` ${classes.noPosition}`
    }

    return baseClass
  }

  getFilterIcon() {
    if (this.props.filterIcon) {
      return this.props.filterIcon
    }

    return 'check-circle'
  }

  render() {
    const selectedClass = this.props.shown
      ? `${classes.selected} ${classes.toggled}`
      : classes.selected
    return (
      <div className={this.getFilterClass()} data-testid={this.props.testID}>
        <div className={selectedClass}>
          <div
            className={classes.clickableContainer}
            onClick={this.toggle.bind(this)}
            data-testid={makeTestID('reports', 'calendar', 'dropdown')}
          >
            <div className={classes.leftFloat}>
              <Icon name={this.getFilterIcon()} size={24} />
            </div>
            <Icon name={this.getFilterBoxIcon()} size={24} />
            <div className={classes.selectedContainer}>
              <button type='button' className={classes.filterButton}>
                {this.getSelectedString()}
              </button>
            </div>
          </div>
          <div
            onMouseDown={this.mouseDownHandler.bind(this)}
            onMouseUp={this.mouseUpHandler.bind(this)}
          >
            {this.getDropdown()}
          </div>
        </div>
      </div>
    )
  }

  isToggled() {
    return this.props.shown
  }

  toggle() {
    this.props.batch.filterToggled(this.props.filterKey, !this.props.shown, this.props.isMobile)
  }

  onDismiss() {
    if (!this.props.isMobile && this.props.batch) {
      this.props.batch.filterToggled(this.props.filterKey, false, this.props.isMobile)
    }
  }
}
