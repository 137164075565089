/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-param-reassign */
import React, { Component } from 'react'
import $ from 'jquery'

import classes from './receiptConfig.module.scss'

export default class ReceiptReport extends Component {
  componentDidMount() {
    this.setIframeContent()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setIframeContent(nextProps)
  }

  setIframeContent(props) {
    if (!props) {
      props = this.props
    }

    if (props.receipt) {
      // eslint-disable-next-line react/no-string-refs
      const frameDoc = this.refs.iFrame.contentWindow.document
      frameDoc.write(props.receipt)
      frameDoc.close()

      // disable all the links in the report
      $('iFrame')
        .contents()
        .find('a')
        .each(function () {
          $(this).on('click', (event) => {
            event.preventDefault()
            event.stopPropagation()
          })
        })
    }
  }

  render() {
    return (
      <div className={classes.iframeContainer}>
        <iframe
          // eslint-disable-next-line react/no-string-refs
          ref='iFrame'
        />
      </div>
    )
  }
}
