import React from 'react'
import styles from './CapitalApplicationProgress.module.scss'
import { ApplicationProgressSteps } from './types'
import { determineChooseOfferClass, determineReviewAndAcceptTermsClass } from './utils'

interface Props {
  activeStep: ApplicationProgressSteps
}

const circleComponent = () => (
  <div className={styles.circleContainer}>
    <div className={styles.circle} />
    <i className='icon2-done' />
  </div>
)

const CapitalPrelimApplicationProgress: React.FunctionComponent<Props> = ({ activeStep }) => {
  const chooseOfferClass = determineChooseOfferClass(activeStep)
  const reviewAndAcceptTermsClass = determineReviewAndAcceptTermsClass(activeStep)

  return (
    <div className={styles.progressBar} data-testid='CapitalApplicationProgress'>
      <div className={styles.stepContainer}>
        <div className={`${styles.step}  ${chooseOfferClass}`}>
          <div className={chooseOfferClass} />
          {circleComponent()}
        </div>
        <div className={styles.text}>{ApplicationProgressSteps.chooseOffer}</div>
      </div>
      <div className={styles.stepContainer}>
        <div className={`${styles.step}  ${reviewAndAcceptTermsClass}`}>
          <div className={`${reviewAndAcceptTermsClass} ${styles.dash}`} />
          {circleComponent()}
        </div>
        <div className={styles.text}>{ApplicationProgressSteps.reviewAndAcceptTerms}</div>
      </div>
    </div>
  )
}

export default CapitalPrelimApplicationProgress
