import React from 'react'

import { PopupComponent } from 'libs/modals'

import PopupHeader from './components/PopupHeader'
import PopupBody from './components/PopupBody'
import PopupFooter from './components/PopupFooter'
import PopupErrorAlert from './components/PopupErrorAlert'

export default class PopupContentLayout extends PopupComponent {
  popoverStyles() {
    if (window.innerWidth > 500) {
      // Is large device
      return this.props.wider ? { width: '100%', maxWidth: 1140 } : { width: 800 }
    }
    return null
  }

  getContent() {
    const {
      headerTitle,
      onBackArrow,
      onClosePopup,
      popupBodyContent,
      buttonLabel,
      footerButtonIsDisabled,
      onFooterButtonClick,
      popupFooterContent,
      hideFooterContent,
      popupErrorMessage,
    } = this.props
    return (
      <div>
        <PopupHeader headerTitle={headerTitle} onCancel={onClosePopup} onBackArrow={onBackArrow} />
        {popupErrorMessage ? <PopupErrorAlert errorMessage={popupErrorMessage} /> : null}

        <PopupBody>
          {popupBodyContent}

          <PopupFooter
            buttonLabel={buttonLabel}
            submitButtonIsDisabled={footerButtonIsDisabled}
            onFooterButtonClick={onFooterButtonClick}
            footerContent={popupFooterContent}
            hideFooterContent={hideFooterContent}
          />
        </PopupBody>
      </div>
    )
  }
}
