import React from 'react'
import PropTypes from 'prop-types'

import classes from './tag.module.scss'

function Tag({ text }) {
  return (
    <div className={classes.box}>
      <div className={classes.innerText}>{text}</div>
    </div>
  )
}

Tag.propTypes = {
  text: PropTypes.string.isRequired,
}

export default Tag
