import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import { track } from 'libs/analytics'
import { makeTestID } from 'libs/utils'
import layout from 'ui/layout/layout.module.scss'
import DropDownButton from 'components/buttons/DropDownButton'

import { TableName } from './constants'
import { exportFile } from './Export.utils'

const dropdownActionContainerStyle = {
  width: '200px',
}

function InvoicesExport({ batch }) {
  return (
    <DropDownButton
      label='Export'
      buttonClass='secondary'
      onClick={() => {
        track('invoices_invoice_export', { name: 'invoices_invoice_export' })
      }}
      actions={[
        {
          label: 'Export Excel',
          onClick: () => {
            track('invoices_invoice_export_excel_clicked', {
              name: 'invoices_invoice_export_excel_clicked',
            })
            exportFile('invoices-excel', batch)
          },
          buttonProps: {
            containerStyle: dropdownActionContainerStyle,
            buttonClass: classNames(['tertiary', layout.widthFull]),
            name: 'invoices',
            tooltip: 'Export your transactions to Excel using these filters',
          },
        },
        {
          label: 'Export CSV',
          onClick: () => {
            track('invoices_invoice_export_csv', { name: 'invoices_invoice_export_csv' })
            exportFile('invoices-csv', batch)
          },
          buttonProps: {
            containerStyle: dropdownActionContainerStyle,
            buttonClass: classNames(['tertiary', layout.widthFull]),
            name: 'invoices',
            tooltip: 'Export your transactions to CSV using these filters',
          },
        },
      ]}
      testID={makeTestID(TableName, 'export')}
    />
  )
}

InvoicesExport.propTypes = {
  batch: PropTypes.object.isRequired,
}

export default connect((state) => ({
  batch: state.filters.get(TableName, Map()),
}))(InvoicesExport)
