import { Map } from 'immutable'
import { callJSONApi } from 'libs/api'
import {
  updateUserUpdateError,
  updateUserUpdateRequest,
  clearUserUpdateRequest,
  PROFILE_ACTIONS,
} from './actions'
import {
  selectUserUpdateRequest,
  selectUUID,
  selectErrorMessage,
  selectUpdatedFields,
  selectUpdateState,
  selectConfirmationSentTo,
  selectReferenceNumber,
} from './selectors'

const fetchLatestUserUpdateRequest = () => (dispatch) => {
  callJSONApi(
    '/user/account/updates/latest',
    'GET',
    {},
    (response) => dispatch(updateUserUpdateRequest(response.data.userUpdateRequest)),
    (error) => dispatch(updateUserUpdateError(error))
  )
}

const resendUserUpdateRequest = (uuid) => (dispatch) => {
  callJSONApi(
    `/user/account/updates/${uuid}/resend`,
    'POST',
    {},
    (response) => {
      dispatch(updateUserUpdateRequest(response.data.userUpdateRequest))
    },
    (error) => {
      dispatch(updateUserUpdateError(error))
    }
  )
}

const cancelUserUpdateRequest = (uuid, onSuccess) => (dispatch) => {
  callJSONApi(
    `/user/account/updates/${uuid}/cancel`,
    'PATCH',
    {},
    () => {
      onSuccess()
    },
    (error) => {
      dispatch(updateUserUpdateError(error))
    }
  )
}

const confirmUserUpdateRequest = (token, onSuccess) => (dispatch) => {
  callJSONApi(
    `/user/account/updates/${token}/confirm`,
    'PATCH',
    {},
    () => {
      onSuccess()
    },
    (error) => {
      dispatch(updateUserUpdateError(error))
    }
  )
}

const fetchUserUpdateRequestByToken = (token) => (dispatch) => {
  callJSONApi(
    `/user/account/updates/${token}`,
    'GET',
    {},
    (response) => {
      dispatch(updateUserUpdateRequest(response.data.userUpdateRequest))
    },
    (error) => {
      dispatch(updateUserUpdateError(error))
    }
  )
}

const initialState = Map({
  userUpdateRequest: Map({
    errorMessage: '',
  }),
})

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case PROFILE_ACTIONS.CLEAR:
      return initialState
    case PROFILE_ACTIONS.UPDATE_USER_UPDATE_REQUEST:
      return state.mergeIn(['userUpdateRequest'], Map(action.payload))
    case PROFILE_ACTIONS.UPDATE_USER_UPDATE_ERROR:
      return state.mergeIn(['userUpdateRequest', 'errorMessage'], action.payload)
    default:
      return state
  }
}

export {
  selectUserUpdateRequest,
  selectUUID,
  selectErrorMessage,
  selectUpdatedFields,
  selectConfirmationSentTo,
  selectUpdateState,
  selectReferenceNumber,
  cancelUserUpdateRequest,
  confirmUserUpdateRequest,
  fetchLatestUserUpdateRequest,
  fetchUserUpdateRequestByToken,
  resendUserUpdateRequest,
  updateUserUpdateError,
  updateUserUpdateRequest,
  clearUserUpdateRequest,
}
