import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import typography from 'ui/typography/typography.module.scss'
import layout from 'ui/layout/layout.module.scss'

export default function Label({ children, htmlFor, className }) {
  return (
    <label className={classnames(layout.block, typography.label, className)} htmlFor={htmlFor}>
      {children}
    </label>
  )
}

Label.propTypes = {
  children: PropTypes.string,
  htmlFor: PropTypes.string,
  className: PropTypes.string,
}
