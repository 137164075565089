import React from 'react'
import FlatButton from 'components/buttons/FlatButton'
import { ModalProps } from '../types'
import classes from './Modal.module.scss'

const Modal: React.FunctionComponent<ModalProps> = ({
  header,
  body,
  primary,
  secondary,
  image,
  primaryAction,
  secondaryAction,
}) => {
  return (
    <div className={classes.container}>
      <div className={classes.contentContainer} role='dialog'>
        {image}
        <h3 className={classes.body}>{header}</h3>
        <p className={classes.body}>{body}</p>
        <div className={classes.buttonContainer}>
          <FlatButton
            label={secondary}
            className={classes.button}
            testID='pbfo-secondary-action'
            onClick={secondaryAction}
          />
          <FlatButton
            label={primary}
            testID='pbfo-primary-action'
            className={`${classes.button} blueBackground`}
            onClick={primaryAction}
          />
        </div>
      </div>
    </div>
  )
}

export default Modal
