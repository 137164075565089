import React from 'react'
import PropTypes from 'prop-types'

import playStore from 'assets/images/google_play_logo.png'
import appStore from 'assets/images/app_store_logo.png'
import appGallery from 'assets/images/huawei_app_gallery.png'
import classes from 'components/onboarding/onboarding.module.scss'

export default function AppDownloadButtons({
  buttonAlignment,
  trackAndroid,
  trackIOS,
  trackHuawei,
}) {
  return (
    <div className={buttonAlignment}>
      <a
        className={classes.appDownloadLink}
        rel='noopener noreferrer'
        target='_blank'
        href='https://play.google.com/store/apps/details?id=za.co.yoco&hl=en_ZA'
        onClick={trackAndroid}
      >
        <img className={classes.appDownloadImage} src={playStore} alt='Google Play' />
      </a>
      <a
        className={classes.appDownloadLink}
        rel='noopener noreferrer'
        target='_blank'
        href='https://itunes.apple.com/za/app/yoco-point-of-sale/id892816357?mt=8'
        onClick={trackIOS}
      >
        <img className={classes.appDownloadImage} src={appStore} alt='Apple App Store' />
      </a>
      <a
        className={classes.appDownloadLink}
        rel='noopener noreferrer'
        target='_blank'
        href='https://appgallery.huawei.com/#/app/C102766851'
        onClick={trackHuawei}
      >
        <img src={appGallery} alt='Huawei App Gallery' />
      </a>
    </div>
  )
}

AppDownloadButtons.propTypes = {
  buttonAlignment: PropTypes.string,
  trackAndroid: PropTypes.func,
  trackIOS: PropTypes.func,
  trackHuawei: PropTypes.func,
}
