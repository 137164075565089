/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-unused-class-component-methods */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import moment from 'moment'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { bindActionCreators } from '@reduxjs/toolkit'

import { Table, EmptyTableView, TableRow } from 'libs/tables'
import BlockHeader from 'components/headers/BlockHeader'
import FormCard from 'components/cards/FormCard'
import SplitBlock from 'components/blocks/SplitBlock'
import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import { FilterBatch } from 'libs/filters'
import DateFilter from 'components/filters/DateFilter'
import ExportButton from 'components/buttons/ExportButton'
import * as misc from 'libs/formats'
import SettlementOnboardingWarning from 'components/settlements/SettlementOnboardingWarning'
import layout from 'ui/layout/layout.module.scss'
import { wasRoutedFromApp, isTablet } from 'redux/modules/routes'
import SettlementCard from 'components/settlements/SettlementCard'
import { fetchSettlementSummary } from 'redux/modules/sales/settlement/actions'
import { refreshTable } from 'redux/modules/tables'
import {
  processingPayout,
  processingPayoutAmount,
  lastPayout,
  lastPayoutAmount,
  lastPayoutDate,
  processingPayoutExpectedDate,
  balance,
  balanceAmount,
  balanceRawAmount,
} from 'redux/modules/sales/settlement/selectors'
import localisation from 'libs/localisation/localisation'
import { callJSONApi } from 'libs/api'
import { track } from 'libs/analytics'

import { selectHasCompletedOnboarding } from 'redux/modules/session/selectors'
import InstantPayoutPopup from './InstantPayoutPopup'
import classes from './sales.module.scss'

const SETTLEMENT_TABLE_NAME = 'settlements'

class SettlementsPage extends Component {
  instantPayoutFee = 10

  constructor(props) {
    super(props)

    this.state = {
      showingSplit: false,
      shownRow: null,
      showDeductions: false,
      instantPayoutAlert: null,
      instantPayoutLoading: false,
    }
  }

  UNSAFE_componentWillMount() {
    this.props.fetchSettlementSummary()
  }

  getTitle() {
    if (this.state.shownRow) {
      return (
        <div className={classes.tableStyle}>
          <misc.Amount amount={this.state.shownRow.get('amount')} style={{ textAlign: 'left' }} />
          <span>&nbsp;Payout&nbsp;on&nbsp;</span>
          <misc.FriendlyDate date={this.state.shownRow.get('created')} />
        </div>
      )
    }

    return 'No payout details'
  }

  hideInstantPayoutPopup = () => {
    this.setState({
      showingInstantPayoutPopup: false,
    })
  }

  showInstantPayoutPopup = () => {
    track('InstantPayoutRequested', {
      balance: this.props.balanceRawAmount,
      fees: this.instantPayoutFee,
    })
    this.setState({
      showingInstantPayoutPopup: true,
    })
  }

  instantPayoutAlertClose = () => {
    this.setState({
      instantPayoutAlert: null,
    })
  }

  actionInstantPayout = () => {
    this.setState({
      instantPayoutLoading: true,
    })
    callJSONApi(
      '/payouts/instant',
      'POST',
      {},
      (response) => {
        this.hideInstantPayoutPopup()
        this.props.fetchSettlementSummary()
        this.props.refreshTable()
        this.setState({
          instantPayoutAlert: {
            type: 'success',
            message: response.message,
          },
          instantPayoutLoading: false,
        })
      },
      (error) => {
        this.hideInstantPayoutPopup()
        this.props.fetchSettlementSummary()
        this.props.refreshTable()
        this.setState({
          instantPayoutAlert: {
            type: 'danger',
            message: error,
          },
          instantPayoutLoading: false,
        })
      }
    )
  }

  /*
   * We show the fees column only when either viewed on desktop, or on a tablet within pos
   */
  shouldShowFees() {
    return !this.props.isInApp || (this.props.isInApp && this.props.isTablet)
  }

  dataLoaded(data) {
    let showDeductionsColumn = false
    data.get('payouts', List()).forEach((payout) => {
      if (payout.get('deductionEntry')) {
        showDeductionsColumn = true
      }
    })
    this.setState({
      showDeductions: showDeductionsColumn,
    })
  }

  // TODO: Replace the handles below.
  mouseDownHandler() {
    // eslint-disable-next-line react/no-string-refs
    this.refs.splitBlock.mouseIsDown = true
  }

  mouseUpHandler() {
    // eslint-disable-next-line react/no-string-refs
    this.refs.splitBlock.mouseIsDown = false
  }

  splitViewClosed() {
    this.setState({
      shownRow: null,
      showingSplit: false,
    })
  }

  /* eslint-disable no-param-reassign */
  showRow(rowData) {
    this.setState((currentState) => {
      if (currentState.shownRow && currentState.shownRow.get('uuid') === rowData.get('uuid')) {
        currentState.shownRow = null
      } else {
        currentState.shownRow = rowData
      }

      currentState.showingSplit = !!currentState.shownRow

      return currentState
    })
  }
  /* eslint-enable no-param-reassign */

  rowClicked(rowData) {
    this.showRow(rowData)
  }

  renderSplitContent() {
    return (
      <FormCard>
        <SettlementTable item={this.state.shownRow} closeSplitView={() => this.splitViewClosed()} />
        {!this.state.shownRow ? (
          <div />
        ) : (
          <ExportButton
            name='settlementsDetails'
            label='Export'
            exportApi={`/settlement/payouts/${this.state.shownRow.get(`uuid`)}/transactions.csv`}
          />
        )}
      </FormCard>
    )
  }

  renderTableRow(rowData, rowIndex) {
    const deductionAmount = rowData.getIn(['deductionEntry', 'amount'], 0)
    return (
      <TableRow
        rowIndex={rowIndex}
        key={`row-${rowIndex}`}
        onClick={() => this.rowClicked(rowData)}
        selected={this.state.shownRow && this.state.shownRow.get('uuid') === rowData.get('uuid')}
      >
        <td>
          <misc.OnlyDate date={rowData.get('created')} />
          <misc.PayoutType payoutType={rowData.get('payoutType')} />
        </td>
        <td>
          <misc.Amount
            amount={rowData.get('amount', 0) + rowData.get('feeAmount', 0) + deductionAmount}
          />
        </td>
        {this.shouldShowFees() && (
          <td>
            <FeeDisplay item={rowData} />
          </td>
        )}
        {this.state.showDeductions && (
          <td>
            <misc.Amount amount={deductionAmount} />
          </td>
        )}
        <td>
          <misc.Amount amount={rowData.get('amount')} />
        </td>
      </TableRow>
    )
  }

  render() {
    const {
      nextPayout,
      nextPayoutAmount,
      nextPayoutDate,
      lastPayoutAmount,
      lastPayout,
      balance,
      balanceAmount,
    } = this.props

    const balanceIntegerAmount = balance?.get('amount')

    return (
      <SplitBlock
        showSave={false}
        showingSplit={this.state.showingSplit}
        header={this.getTitle()}
        renderSplitContent={() => this.renderSplitContent()}
        onDismiss={() => this.splitViewClosed()}
        showCancel
        cancelLabel='Done'
      >
        {balanceIntegerAmount && (
          <InstantPayoutPopup
            onConfirm={this.actionInstantPayout}
            onCancel={this.hideInstantPayoutPopup}
            onEsc={this.hideInstantPayoutPopup}
            balance={balanceIntegerAmount}
            showing={this.state.showingInstantPayoutPopup}
            fee={this.instantPayoutFee}
            localisation={this.props.localisation}
            loading={this.state.instantPayoutLoading}
          />
        )}
        <MaxWidthBlock
          // eslint-disable-next-line react/no-string-refs
          ref='bigBlock'
        >
          {!this.props.hasCompletedOnboarding && (
            <SettlementOnboardingWarning isInApp={this.props.isInApp} />
          )}
          {(balance || nextPayout || lastPayout) && (
            <BlockHeader
              title='Payouts'
              style={!this.props.hasCompletedOnboarding ? { paddingTop: '0px' } : {}}
            />
          )}
          <div className={classes.salesReport}>
            <div className={classes.payoutBlocks}>
              {balance && (
                <div className={classes.payoutBlock}>
                  <SettlementCard header='Balance' amount={balanceAmount} />
                </div>
              )}
              {nextPayout && (
                <div className={classes.payoutBlock}>
                  <div>
                    <SettlementCard header='Processing' amount={nextPayoutAmount} />
                  </div>
                  <p className={classes.settlementHistoryInfo}>
                    Estimated to show in your bank account on{' '}
                    {moment(nextPayoutDate).format('D MMM YYYY')}. We&apos;ll action a payout at 7pm
                    every day for all Yoco card and online payments before this time. &nbsp;
                    <a
                      href='https://get.yoco.help/hc/en-za/articles/360000245389-Settlement-guidelines'
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      Learn more
                    </a>
                  </p>
                </div>
              )}
              {lastPayout && (
                <div className={classes.payoutBlock}>
                  <SettlementCard
                    header='Last Payout'
                    date={this.props.lastPayoutDate}
                    amount={lastPayoutAmount}
                  />
                </div>
              )}
            </div>
          </div>
          <BlockHeader
            title='Payout History'
            style={{ paddingTop: 0 }}
            actions={
              !this.props.isInApp && (
                // Hide export button when showing this from within yocopos because it doesnt work
                <ExportButton
                  name='settlements'
                  label='Export'
                  exportApi='/settlement/payouts.csv'
                />
              )
            }
          />
          <FilterBatch name={SETTLEMENT_TABLE_NAME} showMobileInline>
            <div className={layout.flexGrow} />
            <DateFilter
              defaults={[moment('2014-01-01'), moment().endOf('month').add(1, 'day')]}
              onToggle={() => {
                track('PayoutHistory> Date Picker Clicked')
              }}
              onFilterChange={(filterState) => {
                // Returns the key where the value is true
                const selected = Object.keys(filterState).filter((key) => filterState[key])[0]
                track('PayoutHistory> Date Picker Changed', { selected })
              }}
            />
          </FilterBatch>
          <Table
            api='/settlement/payouts-list/'
            name={SETTLEMENT_TABLE_NAME}
            useOffset
            waitForFilterLoad={false}
            hideNoResults
            rowsClickable
            pageSize={10}
            emptyTable={
              <EmptyTableView title='No payouts found'>
                <span>There are no payouts.</span>
              </EmptyTableView>
            }
            getHeader={() => {
              return (
                <tr className='visible-header'>
                  <th>Date</th>
                  <th className={classes.headerStyle}>Transaction Amount</th>
                  {this.shouldShowFees() ? <th className={classes.headerStyle}>Fees</th> : null}
                  {this.state.showDeductions && (
                    <th className={classes.headerStyle}>Capital Repayment</th>
                  )}
                  <th className={classes.headerStyle}>Payout Amount</th>
                </tr>
              )
            }}
            getRow={(immutableRow, rowIndex) => this.renderTableRow(immutableRow, rowIndex)}
            dataLoaded={(data) => {
              this.dataLoaded(data)
            }}
          />
        </MaxWidthBlock>
      </SplitBlock>
    )
  }
}

function SettlementTable({ item }) {
  if (item) {
    return (
      <Table
        className='settlements-table'
        api={`/settlement/payouts/${item.get('uuid')}/`}
        name='settlementItem'
        useOffset
        waitForFilterLoad={false}
        hideNoResults
        canLoadMore
        pageSize={10}
        rowsClickable={false}
        showPaging
        dataPath='transactions'
        getHeader={() => {
          return (
            <tr className='visible-header'>
              <th className={classes.headerDateStyle}>Time</th>
              <th
                className={classes.headerStyle}
                style={{ textAlign: 'right', paddingRight: '24px' }}
              >
                Transaction Amount
              </th>
              <th
                className={classes.headerStyle}
                style={{ textAlign: 'right', paddingRight: '24px' }}
              >
                Fee
              </th>
            </tr>
          )
        }}
        getRow={(immutableRow, rowIndex) => {
          return (
            <tr className='underlined-row' key={`row-${rowIndex}`}>
              <td>
                <misc.SimpleDateTime date={immutableRow.get('created')} />
              </td>
              <td>
                <misc.Amount amount={immutableRow.getIn(['transaction', 'amount'])} />
              </td>
              <td>
                <FeeDisplay item={immutableRow} />
              </td>
            </tr>
          )
        }}
      />
    )
  }
  return <div />
}

SettlementTable.propTypes = {
  item: PropTypes.object.isRequired,
}

function FeeDisplay({ item }) {
  if (item.get('feeTaxAmount', 0) === 0) {
    return (
      <span style={{ display: 'flex', float: 'right' }}>
        {`( `}
        <misc.Amount amount={item.get('feeAmount')} />
        {` )`}
      </span>
    )
  }

  const overlay = (
    <Popover title='Fee prices shown are incl VAT' id='Fee calculated from'>
      <table className={classes.feePopoverTable}>
        <tbody>
          <tr>
            <td>
              <b>
                <misc.Amount
                  amount={item.get('feeAmount')}
                  style={{ display: 'block', textAlign: 'center' }}
                />
              </b>
            </td>
            <td style={{ paddingLeft: '8px' }}>Total fees charged</td>
          </tr>
          <tr>
            <td style={{ textAlign: 'center' }}>
              <b>=</b>
            </td>
            <td />
          </tr>
          <tr>
            <td>
              <b>
                <misc.Amount
                  amount={item.get('feeAmount', 0) - item.get('feeTaxAmount', 0)}
                  style={{ display: 'block', textAlign: 'center' }}
                />
              </b>
            </td>
            <td style={{ paddingLeft: '8px' }}>Fee amount</td>
          </tr>
          <tr>
            <td style={{ textAlign: 'center' }}>
              <b>+</b>
            </td>
            <td />
          </tr>
          <tr>
            <td>
              <b>
                <misc.Amount
                  amount={item.get('feeTaxAmount', 0)}
                  style={{ display: 'block', textAlign: 'center' }}
                />
              </b>
            </td>
            <td style={{ paddingLeft: '8px' }}>Tax amount</td>
          </tr>
        </tbody>
      </table>
    </Popover>
  )

  return (
    <div className={classes.overlayStyle}>
      <OverlayTrigger trigger={['hover', 'focus']} placement='left' overlay={overlay}>
        <div>
          <misc.Amount amount={item.get('feeAmount')} style={{ display: 'inline-block' }} />*
        </div>
      </OverlayTrigger>
    </div>
  )
}

FeeDisplay.propTypes = {
  item: PropTypes.object.isRequired,
}

SettlementsPage.propTypes = {
  fetchSettlementSummary: PropTypes.func,
  nextPayout: PropTypes.object,
  nextPayoutAmount: PropTypes.string,
  nextPayoutDate: PropTypes.string,
  lastPayout: PropTypes.object,
  lastPayoutAmount: PropTypes.string,
  lastPayoutDate: PropTypes.string,
  balance: PropTypes.object,
  balanceAmount: PropTypes.string,
  balanceRawAmount: PropTypes.number,
  isInApp: PropTypes.bool,
  isTablet: PropTypes.bool,
  hasCompletedOnboarding: PropTypes.bool,
  refreshTable: PropTypes.func,
  localisation: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    isInApp: wasRoutedFromApp(state),
    isTablet: isTablet(state),
    hasCompletedOnboarding: selectHasCompletedOnboarding(state),
    nextPayout: processingPayout(state),
    nextPayoutAmount: `${processingPayoutAmount(state)}`,
    nextPayoutDate: processingPayoutExpectedDate(state),
    lastPayout: lastPayout(state),
    lastPayoutDate: lastPayoutDate(state),
    lastPayoutAmount: `${lastPayoutAmount(state)}`,
    balance: balance(state),
    balanceAmount: `${balanceAmount(state)}`,
    balanceRawAmount: balanceRawAmount(state),
    localisation: localisation(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      {
        fetchSettlementSummary,
        refreshTable: () => refreshTable(SETTLEMENT_TABLE_NAME),
      },
      dispatch
    ),
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettlementsPage)
