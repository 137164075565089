/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@yoco/design-system-icons/dist/react'

import PopupComponent from 'libs/modals/popups'
import PopupClasses from 'libs/modals/popups.module.scss'
import ReferralQrCodeGenerator from './ReferralQRCodeGenerator'
import classes from './ReferralQRCodePopup.module.scss'

export default class QRCodePopup extends PopupComponent {
  getContent() {
    return (
      <div>
        <span className={classes.popupTitle}>
          Yoco referral code for {this.props.businessName}
          <div className={`${PopupClasses.roundedButton}`} onClick={this.onCancel.bind(this)}>
            <Icon name='close' size={17} />
          </div>
        </span>
        <ReferralQrCodeGenerator url={this.props.referralLink} />
      </div>
    )
  }
}

QRCodePopup.propTypes = {
  showModal: PropTypes.bool,
  referralLink: PropTypes.string,
  onEsc: PropTypes.func,
  businessName: PropTypes.string,
  rounded: PropTypes.bool,
}
