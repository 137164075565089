import React, { useState } from 'react'
import { Icon } from '@yoco/design-system-icons/dist/react'

import { If } from 'libs/formats'
import styles from './CapitalFrequentlyAskedQuestionBlock.module.scss'
import { determineArrowDirection } from './utils'

interface Props {
  title: string
  description: string
}

const CapitalFrequentlyAskedQuestionBlock: React.FunctionComponent<Props> = ({
  title,
  description,
}) => {
  const [showFAQ, setShowFAQ] = useState(false)

  return (
    <div className={styles.container} data-testid='CapitalFrequentlyAskedQuestionBlock'>
      <button type='button' onClick={() => setShowFAQ(!showFAQ)} className={styles.block}>
        <div className={styles.title}>{title}</div>
        <Icon name={determineArrowDirection(showFAQ)} size={24} />
      </button>

      <If condition={showFAQ}>
        <div className={styles.description}>{description}</div>
      </If>
    </div>
  )
}

export default CapitalFrequentlyAskedQuestionBlock
