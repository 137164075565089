import { ApplicationProgressSteps } from './types'
import styles from './CapitalApplicationProgress.module.scss'

export const determineChooseOfferClass = (activeStep: ApplicationProgressSteps): string =>
  activeStep === ApplicationProgressSteps.chooseOffer ? styles.active : styles.completed

export const determineReviewAndAcceptTermsClass = (
  activeStep: ApplicationProgressSteps
): string | undefined =>
  activeStep === ApplicationProgressSteps.reviewAndAcceptTerms ? styles.active : undefined
