import React from 'react'
import classNames from 'classnames'

import { formatCurrency } from 'libs/utils'

import useReferralStore from '../store'
import trueFriendsReferYoco from '../images/yoco-referral-share.png'

import ShareButtons from './ShareButtons'
import classes from './referrals.module.scss'

const useStore = () => ({
  rewardAmount: useReferralStore((state) => state?.rewardAmount),
  rewardCurrency: useReferralStore((state) => state?.rewardCurrency),
  tpvThreshold: useReferralStore((state) => state?.tpvThreshold),
})

function ShareCard() {
  const { rewardAmount, rewardCurrency, tpvThreshold } = useStore()
  const amountToReceive = formatCurrency(rewardAmount, rewardCurrency, false, 0)
  const amountToTransact = formatCurrency(tpvThreshold, rewardCurrency, false, 0)
  const amountOff = formatCurrency(100, 'ZAR', false, 0)
  const isAmountsKnown = rewardAmount && rewardCurrency && tpvThreshold

  return (
    <div className={classNames(classes.shareCard, 'card-shadow')}>
      <img
        className={classes.referYocoImage}
        src={trueFriendsReferYoco}
        alt='True friends refer Yoco'
      />
      <div className={classes.shareCardBlock}>
        <h1 className={classes.sharpGroteskSemiBold}>Yoco Referral Program</h1>
        <h5 className={classes.sharpGroteskMedium}>Refer a friend. Get rewarded!</h5>
        <p>
          Got friends who need to get paid? Use the social share buttons to invite them to Yoco with
          your unique referral link.{' '}
          {isAmountsKnown ? (
            <>
              You&apos;ll get{' '}
              <strong>
                <span className={classes.noWrap}>{amountToReceive}</span>
              </strong>{' '}
              for everyone that buys a card machine, signs up and transacts over{' '}
              <span className={classes.noWrap}>{amountToTransact}</span>. And they&apos;ll get{' '}
              {amountOff} off their device. It&apos;s on us!
            </>
          ) : null}
        </p>
        <a
          className={classes.shareCardHelp}
          href='https://support.yoco.help/s/article/Refer-a-friend-to-Yoco'
          target='_blank'
          rel='noopener noreferrer'
        >
          Teach me how
        </a>
      </div>
      <div className={classNames(classes.shareCardBlock, classes.shareButtonsContainer)}>
        <ShareButtons />
      </div>
    </div>
  )
}

export default ShareCard
