import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { callCommonJSONAPI } from 'libs/api'
import Spinner from 'components/loaders/Spinner'

export default class ReceiptPage extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    this.fetchReceipt()
  }

  fetchReceipt() {
    callCommonJSONAPI(
      `/receipts/${this.props.params.uuid}/`,
      'GET',
      {},
      (response) => {
        this.setState({
          receipt: response.data.receipt,
        })
      },
      (prettyError) => {
        this.setState({
          error: prettyError,
        })
      }
    )
  }

  render() {
    if (this.state.receipt) {
      return <div dangerouslySetInnerHTML={{ __html: this.state.receipt }} />
    }
    if (this.state.error) {
      return (
        <div style={{ padding: '50px' }}>
          <div className='alert alert-danger danger'>Error loading receipt: {this.state.error}</div>
          It is likely that you can fix this issue by refreshing, otherwise contact us at{' '}
          <a href='mailto:support@yoco.co.za'>support@yoco.co.za</a>
        </div>
      )
    }

    return (
      <div style={{ textAlign: 'center', marginTop: '150px' }}>
        <Spinner blue />
        Loading receipt...
      </div>
    )
  }
}

ReceiptPage.propTypes = {
  params: PropTypes.object,
}
