import React from 'react'

import * as forms from 'libs/forms'
import { makeTestID } from 'libs/utils'

import CustomerSelect from './CustomerSelect'

const baseTestID = 'customerSelectField'

class CustomerSelectField extends forms.FormField {
  renderInput() {
    return (
      <div
        data-testid={makeTestID(this.props.testID, baseTestID)}
        className={forms.classes.inputWrapper}
        style={{ height: 'auto' }}
      >
        <CustomerSelect
          value={this.props.value}
          onChange={(value) => {
            this.props.form.onFieldChange(this.props.name, value)
          }}
          placeholder={this.props.placeholder}
          trackingEvents={this.props.trackingEvents}
          testID={makeTestID(this.props.testID, baseTestID)}
        />
      </div>
    )
  }
}

export default CustomerSelectField
