export default {
  YOCO_DEFAULT_COLOR: '#005570',
  YOCO_BLUE: '#00A9E0',
  GREEN: '#019472',
  RED: '#FA6D71',
  ORANGE: '#FF9E00',
  BILL_BLUE: '#00A9E0',
  FADED_BACKGROUND: '#F8F9FB',
  VARIANT_COLORS: ['rgb(174, 158, 246)', '#EE4200', '#FF9E00'],
  TOGGLE_WIDTH: 1020,
}
