import $ from 'jquery'
import { push } from 'react-router-redux'
import Cookie from 'js-cookie'

import { getQueryParamByName } from 'libs/queryParams'
import { getENV } from 'libs/utils'
import { castle } from 'libs/castle'

let unauthorizedHandler

export function initializeUnauthorizedHandler(store) {
  unauthorizedHandler = function (message) {
    store.dispatch(push(`/account/logout?message=${message}`))
  }
}

export function getToken() {
  const queryToken = getQueryParamByName('token')
  const cookieToken = Cookie.get('sessionToken')
  return queryToken || cookieToken || window?.yocoStorage?.getItem('token')
}

function getCORSHeaders() {
  return getENV('CORE_URL') && getENV('CORE_URL').includes('localhost')
    ? { 'X-Requested-With': 'XMLHttpRequest' }
    : {}
}

export function doApi(
  url,
  method,
  data,
  successCallback,
  failedCallback,
  withCredentials = true,
  leadingPath = getENV('CORE_URL'),
  logoutOn401 = false
) {
  let headers = {
    // This may only be necessary for cors-anywhere
    ...getCORSHeaders(),
  }

  const token = getToken()

  if (token && withCredentials) {
    headers = {
      'X-Auth-Token': token,
      userUUID: window?.yocoStorage?.getItem('userUUID'),
      businessUUID: window?.yocoStorage?.getItem('businessUUID'),
      // This may only be necessary for cors-anywhere
      ...getCORSHeaders(),
    }
  }

  const request = {
    url: `${leadingPath}${url}`,
    method,
    dataType: 'json',
    contentType: 'application/json; charset=utf-8',
    headers,
    statusCode: {
      307(jqXHR, textStatus) {
        if (successCallback) {
          successCallback(jqXHR.responseJSON, textStatus)
        }
      },
      401(jqXHR) {
        if (logoutOn401) {
          if (unauthorizedHandler) {
            unauthorizedHandler(jqXHR.responseJSON.message)
          }
        }
      },
    },
  }

  if (data && method !== 'GET') {
    request.data = JSON.stringify(data)
  }

  const doRequest = () => {
    $.ajax(request)
      .done((response, textStatus) => {
        if (successCallback) {
          successCallback(response, textStatus)
        }
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        // We handle 307 as success
        if (!(jqXHR && jqXHR.status === 307 && jqXHR.status === 401 && logoutOn401)) {
          if (failedCallback) {
            console.log('textStatus', textStatus, errorThrown, jqXHR)
            let prettyError = textStatus
            if (prettyError) {
              if (errorThrown === 'Not Found') {
                prettyError = 'Unexpected error: Not found'
              } else if (prettyError === 'error') {
                prettyError = 'Connection failed, please check your internet connection'
              }
            }

            if (jqXHR && jqXHR.responseJSON) {
              prettyError = jqXHR.responseJSON.message
            }

            if (!jqXHR.responseJSON) {
              // eslint-disable-next-line no-param-reassign
              jqXHR.responseJSON = {
                status: 0,
                message: prettyError || 'An unknown error occurred',
              }
            }
            failedCallback(prettyError, jqXHR, textStatus, errorThrown)
          }
        }
      })
  }

  if (castle) {
    castle.createRequestToken().then(
      (requestToken) => {
        request.headers['X-Request-Token'] = requestToken
        doRequest()
      },
      () => doRequest()
    )

    return
  }

  doRequest()
}

export function downloadFile(url, filename) {
  const link = document.createElement('a')
  link.href = url
  if (typeof filename === 'string') {
    link.download = filename
  }

  document.body.appendChild(link)
  link.click()
  link.remove()
}
/*
We have added a CoreResponseEnvelope type for responses from core for use with TS
for when this component gets converted to TS
*/
export function callJSONApi(
  path,
  method,
  data,
  successCallback,
  failedCallback,
  withCredentials = true,
  logoutOn401 = true
) {
  doApi(
    getENV('API_ROOT_PATH') + path,
    method,
    data,
    successCallback,
    failedCallback,
    withCredentials,
    getENV('CORE_URL'),
    logoutOn401
  )
}

export function callOnboardingAPI(
  path,
  method,
  data,
  successCallback,
  failedCallback,
  withCredentials = true,
  logoutOn401 = true
) {
  doApi(
    getENV('ONBOARDING_ROOT_PATH') + path,
    method,
    data,
    successCallback,
    failedCallback,
    withCredentials,
    getENV('ONBOARDING_API_URL'),
    logoutOn401
  )
}

export function callCommonJSONAPI(
  path,
  method,
  data,
  successCallback,
  failedCallback,
  withCredentials = false
) {
  doApi(
    getENV('API_COMMON_PATH') + path,
    method,
    data,
    successCallback,
    failedCallback,
    withCredentials
  )
}

export function callInvoicesJSONAPI(path, method, data) {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: `${getENV('INVOICES_BASE_URL')}/api/${path}`,
      method,
      data: data ? JSON.stringify(data) : undefined,
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',

      headers: {
        'X-Auth-Token': getToken(),
        ...getCORSHeaders(),
      },
    })
      .done(resolve)
      // eslint-disable-next-line prefer-promise-reject-errors
      .fail((jqXHR, textStatus, errorThrown) => reject({ jqXHR, textStatus, errorThrown }))
  })
}
