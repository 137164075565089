import React from 'react'
import PropTypes from 'prop-types'

import { isFlagship } from 'libs/utils'

import { getAddressFromLatLng } from './utils'
import classes from './styles.module.scss'

class UseMyLocation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      displayError: undefined,
      searching: undefined,
    }
  }

  getCurrentLatLng = async () => {
    return new Promise((resolve, reject) => {
      const positionOption = { timeout: 5000, enableHighAccuracy: true }
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position)
        },
        (error) => {
          reject(new Error('Could not get current lat lng', error))
        },
        positionOption
      )
    })
  }

  locateAddress = async () => {
    this.setState({ searching: true })
    try {
      const position = await this.getCurrentLatLng()
      const address = await getAddressFromLatLng(
        position.coords.latitude,
        position.coords.longitude
      )
      this.props.onLocateAddress(address)
      this.setState({ displayError: undefined })
      this.setState({ searching: false })
    } catch (error) {
      this.setState({ displayError: 'Could not retrieve your location' })
      this.setState({ searching: false })
    }
  }

  render() {
    if (!('geolocation' in navigator) || isFlagship) {
      return null
    }
    return (
      <div>
        <button
          type='button'
          className={
            this.state.searching ? classes.locationButtonSearching : classes.locationButton
          }
          onClick={this.locateAddress}
        >
          {this.state.searching ? 'Searching' : 'Use my current location'}
        </button>
        <br hidden={!this.state.displayError} />
        <span className={classes.errorMessage}>{this.state.displayError}</span>
      </div>
    )
  }
}

UseMyLocation.propTypes = {
  onLocateAddress: PropTypes.func,
}

export default UseMyLocation
