/* eslint-disable no-nested-ternary */
import React from 'react'
import numeral from 'numeral'
import moment from 'moment'
import d3 from 'd3'
import nv from 'nvd3'

import { formatCurrency, isNewTheme } from 'libs/utils'

import BaseGraph from './base'
import classes from './graphs.module.scss'

export default class LineGraph extends BaseGraph {
  getType() {
    return 'line'
  }

  getChart() {
    return nv.models.lineChart().useInteractiveGuideline(true).showLegend(false)
  }

  addChartOptions(chart, data) {
    const format = this.getDateFormat(this.props.period)
    const { period } = this.props
    const { localisation } = this.props
    const currency = localisation.get('currency', 'R')
    numeral.language('en')
    chart.interactiveLayer.tooltip.valueFormatter((value) => {
      return formatCurrency(value, currency)
    })
    chart.interactiveLayer.tooltip.headerFormatter((d) => {
      const dateFormat =
        period === 'last7days'
          ? moment(data[0].values[d].xAxis).format('dddd')
          : period === 'last30days'
          ? moment(data[0].values[d].xAxis).format('ddd DD MMM')
          : moment(data[0].values[d].xAxis).format(format)
      const dateLabel = period === 'today' || period === 'yesterday' ? '@' : 'on'
      return `Transaction ${dateLabel} ${dateFormat}`
    })
    chart.interpolate('monotone')
    chart.x((d) => {
      return d.x
    })
    chart.y((d) => {
      return d.y
    })
    chart.height(400)
    chart.color(['#95A8B5'])

    if (isNewTheme()) {
      chart.pointSize(40)
    } else {
      chart.pointSize(120)
    }
    chart.id(this.props.key)
    chart.forceY([0])
    chart.noData('')

    chart.interactiveLayer.tooltip.gravity('s')
    chart.interactiveLayer.tooltip.hideDelay(50)

    /* eslint-disable */
    const xFormatter = function (x) {
      return moment(data[0].values[x].xAxis).format(format)
    }

    const yFormatter = function (y) {
      numeral.language('en')
      const formattedCurrency = formatCurrency(y, currency)
      return formattedCurrency
    }
    /* eslint-enable */

    chart.xAxis.tickFormat(xFormatter)
    chart.xAxis.showMaxMin(false)
    chart.xAxis.tickPadding(35)

    chart.yAxis.tickFormat(yFormatter)
    chart.yAxis.showMaxMin(false)
    chart.yAxis.tickPadding(15)

    const ticks = chart.yAxis.scale().ticks(5)
    chart.yAxis.tickValues(ticks)
  }

  getChartCall(data, chart) {
    d3.select(this.refs.svg).datum(data).transition().duration(350).call(chart)
  }

  getBaseMargins() {
    if (this.props.margin) {
      return this.props.margin
    }
    return {
      top: 20,
      right: 40,
      bottom: 65,
      left: 95,
    }
  }

  updateLabels() {
    d3.select(this.refs.svg)
      .select('g')
      .select('.nv-x.nv-axis')
      .selectAll('text')
      .attr('transform', `translate (0, ${(this.getBaseMargins().bottom = 25)})`)

    d3.select(this.refs.svg)
      .select('g')
      .select('.nv-x.nv-axis')
      .selectAll('text')
      .attr('transform', `translate (0, ${(this.getBaseMargins().left = -100)})`)
  }

  /* eslint-disable */
  postLoadData(chart) {
    chart.legend.dispatch.legendClick = function (d, i) {
      setTimeout(function () {
        this.updateLabels()
        chart.update()
      })
    }

    const ticks = chart.yAxis.scale().ticks(4)
    chart.yAxis.tickValues(ticks)
  }
  /* eslint-enable */

  getAxisFrame() {
    if (this.chart) {
      return <div className={classes.frame} />
    }
    return null
  }

  getDateFormat(period) {
    if (period) {
      switch (period) {
        case 'last7days':
          return 'ddd'
        case 'last30days':
          return 'DD MMM'
        default:
          return 'HH:mm'
      }
    }

    return 'HH:mm'
  }
}
