/**
 * Created by mandisa on 2016/10/21.
 */

export function getAbbreviation(name, imageText) {
  if (imageText || imageText === '') {
    return imageText.replace(/_/g, '').substr(0, 4)
  }

  return (name || '').substr(0, 2)
}
