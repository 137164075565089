import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import classes from './layout.module.scss'

function Spacer({ size, isHorizontal }) {
  if (isHorizontal) {
    return (
      <span
        className={classnames(
          size === 'xs' ? classes.pl1 : null,
          size === 'small' ? classes.pl2 : null,
          size === 'medium' ? classes.pl3 : null,
          size === 'large' ? classes.pl4 : null,
          size === 'xl' ? classes.pl8 : null
        )}
      />
    )
  }
  return (
    <div
      className={classnames(
        size === 'xs' ? classes.pt1 : null,
        size === 'small' ? classes.pt2 : null,
        size === 'medium' ? classes.pt3 : null,
        size === 'large' ? classes.pt4 : null,
        size === 'xl' ? classes.pt8 : null
      )}
    />
  )
}

Spacer.propTypes = {
  size: PropTypes.oneOf(['xs', 'small', 'medium', 'large', 'xl']),
  isHorizontal: PropTypes.bool,
}

Spacer.defaultProps = {
  isHorizontal: false,
}

export default Spacer
