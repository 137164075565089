import React from 'react'
import Immutable from 'immutable'

import * as forms from 'libs/forms'
import { PopupComponent } from 'libs/modals'
import popupClasses from 'libs/modals/popups.module.scss'
import FlatButton from 'components/buttons/FlatButton'
import { track } from 'libs/analytics'

export default class BillNotePopup extends PopupComponent {
  constructor(props) {
    super(props)
    this.onSaveNotePress = this.onSaveNotePress.bind(this)
  }
  onSaveNotePress(response) {
    track('history_note_save_note_clicked', { note: response.data.note })
    this.props.onSetBillNoteSuccess(response)
  }
  getContent() {
    return (
      <div>
        <div className={popupClasses.popupTitle}>
          <i className='icon-note-paper' />
          Enter Bill Note
        </div>
        <div className={popupClasses.popupSubtitle} />
        <forms.Form
          action={`/bills/${this.props.billUUID}/setNote`}
          name='billNote'
          initialData={Immutable.fromJS({ note: this.props.value })}
          onSuccess={(response) => {
            this.onSaveNotePress(response)
          }}
        >
          <forms.TextareaField
            name='note'
            label='Note'
            placeholder={this.props.placeholder || null}
            isFullWidth
            rows='5'
            disableResize
          />
          <div className={popupClasses.actions}>
            <FlatButton label='Save note' className='primary' />
          </div>
        </forms.Form>
      </div>
    )
  }
}
