/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import moment from 'moment'
import BaseGraph from './base'

/* eslint-disable */
export default class SimpleLineGraph extends BaseGraph {
  getType() {
    return 'simpleLine'
  }

  getChart() {
    return nv.models
      .lineChart()
      .useInteractiveGuideline(false)
      .showLegend(false)
      .showYAxis(false)
      .showXAxis(false)
  }

  addChartOptions(chart, data) {
    const value = this.props.value ? this.props.value : null
    const period = this.props.period ? this.props.period : null
    const format = this.getDateFormat(period)
    const periodText = this.getInterval(period)
    const title = this.props.title

    numeral.language('en')
    chart.interpolate('monotone')
    chart.x((d) => {
      return d.x
    })
    chart.y((d) => {
      return d.y
    })
    chart.height(80)
    chart.width(120)
    chart.color(['#FFF'])
    chart.pointSize(60)
    chart.id(this.props.key)
    chart.noData('')

    chart.tooltip.gravity('s')
    chart.tooltip.hideDelay(50)

    const xFormatter = function (x) {
      if (title) {
        return (
          '<p><span class="title">' +
          `${title}` +
          '</span><br/>' +
          `For ${periodText} ${moment(data[0].values[x].xAxis).format(format)}` +
          '</p>'
        )
      }
      return moment(data[0].values[x].xAxis).format(format)
    }

    const yFormatter = function (y) {
      numeral.language('en')
      switch (value) {
        case 'count':
          return numeral(y).format('0,0')
          break
        case 'amount':
          return `R ${numeral(y).format('0,0.00')}`
          break
        case 'average':
          return numeral(y).format('0,0.0')
          break
        default:
          return numeral(y).format('0,0.00')
      }
    }

    chart.xAxis.tickFormat(xFormatter)
    chart.xAxis.showMaxMin(false)

    chart.yAxis.tickFormat(yFormatter)
    chart.yAxis.showMaxMin(false)

    const ticks = chart.yAxis.scale().ticks(1)
    chart.yAxis.tickValues(ticks)

    d3.select(this.refs.svg).selectAll('.nv-point').classed('hidden-point', true)
  }

  getBaseMargins() {
    if (this.props.margin) {
      return this.props.margin
    }
    return {
      top: 20,
      right: 10,
      bottom: 20,
      left: 10,
    }
  }

  postLoadData(chart) {
    chart.legend.dispatch.legendClick = function (d, i) {
      setTimeout(function () {
        this.updateLabels()
        chart.update()
      })
    }

    const ticks = chart.yAxis.scale().ticks(1)
    chart.yAxis.tickValues(ticks)
  }

  getDateFormat(period) {
    if (period) {
      switch (period) {
        case 'last30days':
          return 'DD MMM YYYY'
        default:
          return 'ddd DD MMM'
      }
    }
  }

  getInterval(period) {
    if (period) {
      switch (period) {
        case 'last7days':
          return '7 Days ending on'
        case 'last30days':
          return '30 Days ending on'
        default:
          return ''
      }
    }
  }
}

SimpleLineGraph.propTypes = {
  value: PropTypes.string,
}

/* eslint-enable */
