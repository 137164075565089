export type permissionType = {
  'Sales and Refunds': Array<string>
  'Managing your Business': Array<string>
}

export const permissionsMapping = {
  admin: {
    'Sales and Refunds': [
      'userCanTransact',
      'userCanViewTransactions',
      'userCanReverseLastTransaction',
      'userCanRefundTransactions',
      'userCanAssignSales',
      'userCanAssignSalesHistorically',
    ],
    'Managing your Business': [
      'userCanManageInventory',
      'userCanManageStaff',
      'userCanViewFinancial',
      'userCanManageBusiness',
      'userCanManageReports',
      'userCanManageHardwareDevices',
      'userCanManageStockAndSuppliers',
    ],
  },
  staff: {
    'Sales and Refunds': ['userCanTransact', 'userCanReverseLastTransaction', 'userCanAssignSales'],
  },
  manager: {
    'Sales and Refunds': [
      'userCanTransact',
      'userCanViewTransactions',
      'userCanReverseLastTransaction',
      'userCanRefundTransactions',
      'userCanAssignSales',
    ],
    'Managing your Business': [
      'userCanManageInventory',
      'userCanManageStaff',
      'userCanManageHardwareDevices',
      'userCanManageStockAndSuppliers',
    ],
  },
  supervisor: {
    'Sales and Refunds': [
      'userCanTransact',
      'userCanViewTransactions',
      'userCanRefundTransactions',
      'userCanReverseLastTransaction',
      'userCanAssignSales',
    ],
    'Managing your Business': ['userCanManageHardwareDevices', 'userCanManageStockAndSuppliers'],
  },
}
