import React from 'react'
import styles from './NotificationBar.module.scss'

interface Props {
  content: string
}

const NotificationBar: React.FunctionComponent<Props> = ({ content }) => {
  return (
    <div className={styles.container} role='status' aria-label='NotificationBar'>
      {content}
    </div>
  )
}

export default NotificationBar
