/* eslint-disable @typescript-eslint/no-shadow */
import { useCallback } from 'react'
import $ from 'jquery'

import { getENV } from 'libs/utils'
import { getToken } from 'libs/api'
import store from 'redux/store'
import { showError } from 'redux/modules/forms'
import { addItem, itemUpdated, removeItem } from 'redux/modules/tables'

import { FormName } from '../Form/constants'
import { TableName } from '../List/constants'

export const useDeleteCustomer = (customer, onSuccess, onError) => {
  const onDelete = useCallback(() => {
    $.ajax({
      url: `${getENV('INVOICES_BASE_URL')}/api/customers/${customer.get('id')}`,
      method: 'DELETE',
      headers: {
        'X-Auth-Token': getToken(),
      },
    })
      .done(() => {
        onSuccess?.(customer)
      })
      .fail(() => {
        store.dispatch(showError(FormName, 'Failed to delete!'))
        onError?.()
      })
  }, [customer, onError, onSuccess])

  return { onDelete }
}

export const useCustomerViewActions = (customer, setCustomer) => {
  const onDeleted = useCallback(() => {
    setCustomer(undefined)
    store.dispatch(removeItem(TableName, customer.get('uuid')))
  }, [customer, setCustomer])

  const onSaved = useCallback(
    (customer) => {
      store.dispatch(
        customer.get('isNewItem') ? addItem(TableName, customer) : itemUpdated(TableName, customer)
      )
      setCustomer(customer.set('isSaved', true))
    },
    [setCustomer]
  )

  const onFailure = useCallback(
    (error) => {
      setCustomer(customer.set('error', error))
    },
    [customer, setCustomer]
  )

  const onCloseConfirmation = useCallback(() => {
    setCustomer(customer.delete('confirmingDelete'))
  }, [customer, setCustomer])

  const onCloseSuccess = useCallback(() => {
    setCustomer(undefined)
  }, [setCustomer])

  const onCloseFailure = useCallback(() => {
    setCustomer(customer.delete('error'))
  }, [customer, setCustomer])

  return {
    onCloseConfirmation,
    onCloseSuccess,
    onCloseFailure,
    onDeleted,
    onFailure,
    onSaved,
  }
}
