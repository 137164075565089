/* eslint-disable import/namespace, @typescript-eslint/no-unused-vars, no-unused-vars */
import { combineReducers } from '@reduxjs/toolkit'
import { List, Record } from 'immutable'
import { handleRequest, initialRequestState } from 'redux/modules/helpers/requestHelper'
import ActionTypes from './actionTypes'
import {
  ApplicationDataRecordFactory,
  BankAccountRecordFactory,
  CapitalOfferRecordFactory,
  InitialApplicationDataRecordFactory,
  OfferContractRecordFactory,
  OfferQuoteRecordFactory,
  OfferResponseDataRecordFactory,
  PayoutDeductionPlanRecordFactory,
  PrelimOfferRecordFactory,
  QualificationRecordFactory,
  QuoteRecordFactory,
  CapitalQualifyingBusinessFactory,
} from './records'
import * as selectors from './Capital.selectors'
import * as capitalAnalyticsUtils from './Capital.analytics.utils'
import * as middleware from './Capital.middleware'

const State = Record({
  qualification: QualificationRecordFactory(),
  prelimOffer: PrelimOfferRecordFactory(),
  applyForReAdvance: false,
  capitalQualifyingBusiness: CapitalQualifyingBusinessFactory(),
  capitalOffer: CapitalOfferRecordFactory(),
})
const initialState = State()

export function applyForReAdvance(state = initialState.applyForReAdvance, action) {
  switch (action.type) {
    case ActionTypes.DECLINED_CAPITAL_OFFER: {
      return false
    }
    case ActionTypes.RE_APPLY:
      return true
    default:
      return state
  }
}

export function qualification(state = initialState.qualification, action) {
  switch (action.type) {
    case ActionTypes.DECLINED_CAPITAL_OFFER: {
      return initialState.qualification
    }
    case ActionTypes.RECEIVED_QUALIFICATIONS:
      return state.withMutations((map) => {
        map.mergeDeep(action.payload)
      })
    default:
      return state
  }
}

export function prelimOffer(state = initialState.prelimOffer, action) {
  switch (action.type) {
    case ActionTypes.RECEIVED_PRELIM_OFFERS:
      return PrelimOfferRecordFactory(List(action.payload).first())
    default:
      return state
  }
}

export function prelimOffersRequest(state = initialRequestState(), action) {
  return handleRequest(
    ActionTypes.FETCH_PRELIM_OFFERS_REQUEST,
    ActionTypes.FETCH_PRELIM_OFFERS_SUCCESS,
    ActionTypes.FETCH_PRELIM_OFFERS_FAILURE,
    state,
    action
  )
}

export function qualificationRequest(state = initialRequestState(), action) {
  return handleRequest(
    ActionTypes.FETCH_QUALIFICATIONS_REQUEST,
    ActionTypes.FETCH_QUALIFICATIONS_SUCCESS,
    ActionTypes.FETCH_QUALIFICATIONS_FAILURE,
    state,
    action
  )
}

export function capitalOfferRequest(state = initialRequestState(), action) {
  return handleRequest(
    ActionTypes.PROGRESS_OFFER_REQUEST,
    ActionTypes.PROGRESS_OFFER_SUCCESS,
    ActionTypes.PROGRESS_OFFER_FAILURE,
    state,
    action
  )
}

export function capitalQualifyingBusiness(state = initialState.capitalQualifyingBusiness, action) {
  switch (action.type) {
    case ActionTypes.RECEIVED_CAPITAL_QUALIFYINGBUSINESS: {
      return CapitalQualifyingBusinessFactory(action.data)
    }
    case ActionTypes.CAPITAL_OPT_IN_REQUEST: {
      return state.setIn(['fetchingData'], true)
    }
    case ActionTypes.CAPITAL_OPT_IN_SUCCESS:
      return state.withMutations((map) => {
        map.setIn(['notifyMe'], true)
        map.setIn(['fetchingData'], false)
      })
    default:
      return state
  }
}

export function capitalOffer(state = initialState.capitalOffer, action) {
  switch (action.type) {
    case ActionTypes.DECLINED_CAPITAL_OFFER: {
      return initialState.capitalOffer
    }
    case ActionTypes.RECEIVED_CAPITAL_OFFER: {
      if (!action.data) {
        return state
      }

      const data = CapitalOfferRecordFactory(action.data)
      return data.withMutations((map) => {
        const offerQuotes = List(map.offerResponseData.offerQuotes).map((offerQuote) => {
          const quotes = List(offerQuote.quotes).map((quote) => {
            return QuoteRecordFactory({
              repaymentPeriod: quote[0],
              repaymentPercentage: quote[1],
              dailyAmount: quote[2],
            })
          })
          return OfferQuoteRecordFactory(offerQuote).setIn(['quotes'], quotes)
        })
        map
          .setIn(
            ['offerResponseData'],
            OfferResponseDataRecordFactory({
              offerContract: OfferContractRecordFactory(data.offerResponseData.offerContract),
              offerQuotes,
              earlySettlementAmount: data.offerResponseData.earlySettlementAmount,
              discountOnCurrentAdvanceOutstandingBalance:
                data.offerResponseData.discountOnCurrentAdvanceOutstandingBalance,
            })
          )
          .setIn(
            ['applicationData'],
            ApplicationDataRecordFactory({
              initialApplicationData: InitialApplicationDataRecordFactory(
                data.applicationData.initialApplicationData
              ),
            })
          )
          .set('bankAccount', BankAccountRecordFactory(data.bankAccount))
          .set('payoutDeductionPlan', PayoutDeductionPlanRecordFactory(data.payoutDeductionPlan))
      })
    }
    default:
      return state
  }
}

export { selectors, middleware, capitalAnalyticsUtils }

export default combineReducers({
  qualification,
  qualificationRequest,
  prelimOffer,
  prelimOffersRequest,
  applyForReAdvance,
  capitalOfferRequest,
  capitalOffer,
  capitalQualifyingBusiness,
})
