/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react'
import { Iterable } from 'immutable'
import PropTypes from 'prop-types'
import { Progress } from 'react-sweet-progress'

import FileUpload from 'components/files/FileUpload'
import UserAlert from 'components/notifications/UserAlert'
import classes from 'components/files/GenericUploadComponent.module.scss'
import { isFlagship } from 'libs/utils'

export default class GenericUploadComponent extends FileUpload {
  constructor(props) {
    super(props)
    this.state = {
      filename: '',
      progress: 0,
      errorDisplayed: false,
      errorMessage: null,
    }
  }

  selectFile = () => {
    const { fileUploadID, disabled } = this.props
    const fileElement = document.getElementById(fileUploadID)
    if (fileElement && !disabled) {
      fileElement.click()
    }
  }

  formGetter() {
    const data = new FormData(document.getElementById('customForm'))
    data.append('file', document.getElementById(this.props.fileUploadID).files[0])
    return data
  }

  renderIcon(uploadedDocument) {
    const iconColor = !this.props.disabled ? '#00AEE4' : uploadedDocument && '#cfd8dc'
    const iconStyle = { color: iconColor }
    const uploadSuccessIconName = isFlagship ? 'icon2-check-circle-2' : 'icon2-file-success'
    const uploadIconName = isFlagship ? 'icon2-camera-front' : 'icon2-add-files'

    return (
      <i
        className={`${
          uploadedDocument ? classes.submittedFileUploadIcon : classes.fileUploadIcon
        } ${this.state.filename || uploadedDocument ? uploadSuccessIconName : uploadIconName}`}
        style={iconStyle}
      />
    )
  }

  renderProgress(progress) {
    const isLargeDevice = window.innerWidth > 500
    const progressSymbol = isLargeDevice ? `${Math.round(progress)}%` : null
    const status = progress < 100 ? 'active' : 'success'
    return (
      <Progress
        type='line'
        theme={{
          active: {
            symbol: progressSymbol,
            color: '#8ACE40',
          },
          success: {
            symbol: progressSymbol,
            color: !this.props.disabled ? '#00AEE4' : '#cfd8dc',
          },
        }}
        percent={progress}
        width='100%'
        status={status}
      />
    )
  }

  customFormRenderer(onSubmit) {
    const { uploadedDocument, isImage } = this.props

    const uploadLabel = uploadedDocument ? 'Attach a different file' : 'Browse'
    const flagshipUploadLabel = uploadedDocument ? 'Take a new photo' : 'Take a photo'
    const uploadText = uploadedDocument
      ? null
      : `through your files to upload ${isImage ? 'an image' : 'a document'}`
    const errorMessage = this.displayError()
    const fileUploadClassName = !this.props.disabled
      ? classes.documentFileUploadEnabled
      : classes.documentFileUploadDisabled

    const flagshipfileUploadClassName = !this.props.disabled
      ? classes.flagshipDocumentFileUploadEnabled
      : classes.flagshipDocumentFileUploadDisabled

    return (
      <div
        className={`${isFlagship ? flagshipfileUploadClassName : fileUploadClassName} ${
          uploadedDocument ? classes.hasDocument : ''
        }`}
      >
        {errorMessage}
        <div
          className={classes.uploadButtonBlockInnerContainer}
          onClick={this.selectFile}
          onKeyPress={this.selectFile}
        >
          {this.renderIcon(uploadedDocument)}
          <div className={isFlagship ? classes.flagShipUploadButtonText : classes.uploadButtonText}>
            <div className={isFlagship ? classes.flagshipUploadedFileName : ''}>
              {this.state.filename
                ? this.state.filename
                : uploadedDocument && uploadedDocument.filename}
            </div>
            {!isFlagship ? (
              <div>
                {this.state.fileUploading
                  ? this.renderProgress(this.state.progress)
                  : uploadedDocument
                  ? this.renderProgress(100)
                  : null}
              </div>
            ) : null}
            <form id='customForm' />
            <input
              type='file'
              capture={!!isFlagship}
              name='file'
              accept={this.props.acceptedFileRE}
              id={this.props.fileUploadID}
              style={{ display: 'none' }}
              onChange={this.onFileSelected(onSubmit)}
            />
            <span
              style={{
                fontWeight: 700,
                color:
                  !this.props.disabled &&
                  (this.state.filename ||
                    uploadedDocument ||
                    !(this.state.filename || uploadedDocument))
                    ? '#00A2E0'
                    : 'grey',
              }}
              onClick={this.onBrowseClicked}
            >
              {isFlagship ? flagshipUploadLabel : uploadLabel}
            </span>
            <span>
              &nbsp;
              {isFlagship ? '' : uploadText}
            </span>
          </div>
        </div>
      </div>
    )
  }

  onBrowseClicked = () => {
    if (this.props.onBrowseCallback) {
      this.props.onBrowseCallback()
    }
  }

  onFileSelected = (onSubmit) => (event) => {
    const file = event.target.files[0]
    if (file) {
      if (!this.isFileTypeAccepted(file.type)) {
        this.setState({
          errorDisplayed: true,
          errorMessage: this.props.acceptedFileTypeError,
        })
        return false
      }

      const fileSize = Math.round(file.size / 1024 / 1024)
      const allowedMaxFileSize = 5
      if (fileSize > allowedMaxFileSize) {
        this.setState({
          errorDisplayed: true,
          errorMessage: `File size ${fileSize}MB is too big, please upload file size less than ${allowedMaxFileSize}MB`,
        })
        return false
      }
      onSubmit(event)
      this.setState({
        filename: event.target.files[0].name,
      })
    }
    return true
  }

  displayError() {
    if (this.state.errorDisplayed) {
      return (
        <UserAlert
          userAlert={{
            htmlMessage: this.state.errorMessage,
            alertType: 'warning',
          }}
          dismissUserAlert={this.updateErrorState}
          style={{ top: '67px' }}
        />
      )
    }
    return null
  }

  isFileTypeAccepted(type) {
    return this.props.acceptedFileTypes.includes(type)
  }

  updateErrorState = () => {
    this.setState({ errorDisplayed: false })
  }
}

GenericUploadComponent.defaultProps = {
  acceptedFileRE: ['image/*', 'application/*'],
  acceptedFileTypes: ['image/jpeg', 'image/png', 'application/pdf'],
  acceptedFileTypeError: 'Only JPEG, PNG and PDF files are supported for document uploading.',
}

GenericUploadComponent.propTypes = {
  acceptedFileRE: PropTypes.string,
  acceptedFileTypes: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(Iterable),
  ]),
  acceptedFileTypeError: PropTypes.string,
  onBrowseCallback: PropTypes.func,
  fileUploadID: PropTypes.string,
  disabled: PropTypes.bool,
}
