import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import layout from './layout.module.scss'

function PaddedContent({ children, isTextCentered, className, limitWidth, style }) {
  return (
    <div
      className={classnames(layout.p4, isTextCentered ? layout.textCentered : null, className)}
      style={style}
    >
      {limitWidth ? (
        <div className={classnames(layout.marginCentered, layout.maxWidth)}>{children}</div>
      ) : (
        children
      )}
    </div>
  )
}

PaddedContent.propTypes = {
  children: PropTypes.node,
  isTextCentered: PropTypes.bool,
  limitWidth: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default PaddedContent
