const colors = {
  'Brand-Orange': '#EE4200',
  'Brand-Yellow': '#FFA400',
  'Brand-Green': '#014737',
  'Brand-Blue': '#00A9E0',
  'Brand-Black': '#222222',
  White: '#FFFFFF',
  'Gray-900': '#4C4E52',
  'Gray-700': '#878B90',
  'Gray-500': '#C3C8D1',
  'Gray-300': '#CED4DE',
  'Gray-200': '#E4E9F1',
  'Gray-100': '#F8F9FB',
  'Blue-900': '#005570',
  'Blue-700': '#0087B3',
  'Blue-500': '#00A9E0',
  'Blue-300': '#66CBEC',
  'Blue-100': '#CCEEF9',
  'Yellow-900': '#CC8615',
  'Yellow-700': '#E69717',
  'Yellow-500': '#FFA81A',
  'Yellow-300': '#FFCB76',
  'Yellow-100': '#FFEED1',
  'Green-900': '#015944',
  'Green-700': '#018567',
  'Green-500': '#019472',
  'Green-400': '#48AF97',
  'Green-300': '#67BFAA',
  'Green-100': '#CCEAE3',
  'Red-900': '#190102',
  'Red-700': '#7C060A',
  'Red-500': '#F70c13',
  'Red-100': '#FDCED0',
  'Red-300': '#FA6D71',
  'Orange-800': '#FC4C02',
}

export default colors

export const semanticColors = {
  danger: colors['Red-500'],
  sentimentPositive: colors['Green-400'],
  sentimentNeutral: colors['Brand-Black'],
  sentimentNegative: colors['Red-300'],
}
