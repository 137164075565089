export const PROFILE_ACTIONS = {
  CLEAR: 'CLEAR',
  UPDATE_USER_UPDATE_REQUEST: 'UPDATE_USER_UPDATE_REQUEST',
  UPDATE_USER_UPDATE_ERROR: 'UPDATE_USER_UPDATE_ERROR',
}

export const updateUserUpdateRequest = (payload) => ({
  type: PROFILE_ACTIONS.UPDATE_USER_UPDATE_REQUEST,
  payload,
})

export const updateUserUpdateError = (payload) => ({
  type: PROFILE_ACTIONS.UPDATE_USER_UPDATE_ERROR,
  payload,
})

export const clearUserUpdateRequest = () => ({
  type: PROFILE_ACTIONS.CLEAR,
})
