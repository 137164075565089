/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-shadow */
import { useCallback, useEffect } from 'react'
import { Map } from 'immutable'

import { track } from 'libs/analytics'
import { callInvoicesJSONAPI } from 'libs/api'
import store from 'redux/store'
import { initializeForm, submitResult } from 'redux/modules/forms'

import { FormName } from './constants'

export const useSaveCustomer = (customer, onSuccess, onFailure) => {
  useEffect(() => {
    // Reset null-ish fields to undefined
    store.dispatch(
      initializeForm(
        FormName,
        customer.map((v) => v || undefined)
      )
    )
  }, [customer])

  const onSubmit = useCallback(
    (data) => {
      track('invoices_customers_save_button_clicked', {
        name: 'invoices_customers_save_button_clicked',
      })

      const customerId = customer.get('id')
      callInvoicesJSONAPI(
        `customers${customerId ? `/${customerId}` : ''}`,
        customerId ? 'PATCH' : 'POST',
        data
      )
        .then(({ data: customer }) => {
          store.dispatch(submitResult(FormName, customer))
          onSuccess?.(
            new Map({
              ...customer,
              uuid: customer.id,
              isNewItem: !customerId,
            })
          )
        })
        .catch(() => {
          store.dispatch(submitResult(FormName, {}))
          onFailure?.("We are unable to save the customer's details. Please try again.")
        })
    },
    [customer, onSuccess, onFailure]
  )
  return { onSubmit }
}
