import moment from 'moment'

import { PERFORMANCE_PERIODS } from './index'

export const getStartAndEndDates = (period) => {
  return {
    startDate: moment().startOf(period).format(),
    endDate: moment().format(),
  }
}

export const encodeJsonParams = (jsonParams) => {
  return encodeURIComponent(JSON.stringify(jsonParams))
}

/**
 * Sorts via the period start date.
 * @param {Array<Object>} results
 */
export const orderResults = (results) => {
  return results.sort((resultA, resultB) => {
    return moment(resultA.periodStart) - moment(resultB.periodStart)
  })
}

/**
 * Formats the results of the response for state
 * for a year period.
 * @param {Array<Object>} performanceResults
 */
export const formatPerformanceResultsForYear = (performanceResults) => {
  const orderedResults = orderResults(performanceResults)
  return orderedResults.map((performanceResult) => ({
    period: moment.utc(performanceResult.periodStart).format('MMM'),
    ...performanceResult,
  }))
}

/**
 * Formats the results of the response for state
 * for the given period and records
 * @param {String} period
 * @param {Array<Object>} performanceResults
 */
export const formatPerformanceResultsForPeriod = (period, performanceResults) => {
  switch (period) {
    case PERFORMANCE_PERIODS.YEAR:
      return formatPerformanceResultsForYear(performanceResults)
    default:
      throw new Error(`Unsupported Period Type ${period}`)
  }
}
