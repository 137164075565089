import React, { Component } from 'react'
import { Link } from 'react-router'

import FlatButton from 'components/buttons/FlatButton'
import * as forms from 'libs/forms'
import { isShopify } from 'libs/utils'

import classes from './account.module.scss'

export default class ForgotPasswordPage extends Component {
  nextUrlParam = window && window.location ? window.location.search || '' : ''

  render() {
    return (
      <div className={`${classes.formBlock} clearfix`}>
        <forms.Form
          name='reset'
          action='/user/reset'
          noWrap
          showSuccess
          addUTM
          loaderClass={classes.formLoader}
        >
          <forms.FormBlock
            infoText={`
              If you have forgotten your password then please enter your email address and we will send you an email that will allow you to reset it
            `}
          >
            <forms.TextField
              name='email'
              label='Email address'
              placeholder='Please enter your email address'
              type='email'
              validators={[
                new forms.RequiredValidator('You must provide a email address'),
                new forms.EmailValidator('You must provide an email address'),
              ]}
            />
          </forms.FormBlock>
          <span style={{ marginTop: '12px', display: 'inline-block' }}>
            <Link to={`/account/login${isShopify() ? this.nextUrlParam : ''}`}>Back to login</Link>
          </span>
          <FlatButton
            label='Send reset email'
            className={`${classes.loginButton} blueBackground`}
            type='submit'
          />
        </forms.Form>
      </div>
    )
  }
}
