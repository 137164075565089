import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Map } from 'immutable'

import MaxWidthBlock from 'components/blocks/MaxWidthBlock'
import BlockHeader from 'components/headers/BlockHeader'
import ReferralLink from 'components/buttons/ReferralLink'
import * as forms from 'libs/forms'
import FlatButton from 'components/buttons/FlatButton'
import Alert from 'components/notifications/Alert'

class WifiReaderSettingsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  getMessage() {
    if (
      this.props.queryParams.message &&
      this.props.queryParams.message.length > 0 &&
      !this.state.hideMessage
    ) {
      return (
        <Alert
          message={this.props.queryParams.message}
          messageType={this.props.queryParams.success ? 'success' : 'danger'}
          onClose={() => {
            this.setState({ hideMessage: true })
          }}
        />
      )
    }

    return undefined
  }

  authenticateDesktop(event) {
    event.stopPropagation()
    window.location = `http://localhost:9626/wifiConfig?save=true&ssid=${this.props.formData.get(
      'ssid'
    )}&psk=${this.props.formData.get('password')}`
  }

  render() {
    return (
      <MaxWidthBlock>
        <BlockHeader title='WIFI Card Machine Settings' actions={<ReferralLink />} />
        {this.getMessage()}
        <forms.Form name='wifiReaders'>
          <forms.FormBlock
            heading='WIFI Card Machines'
            infoText='Configure your WIFI card machines from here.'
          >
            <forms.TextField
              name='ssid'
              label='SSID'
              placeholder='This is the name of your network'
              isFullWidth
            />
            <forms.TextField
              name='password'
              label='Password'
              placeholder='This is the password of your network'
              type='password'
              isFullWidth
            />
            <FlatButton
              label='Configure'
              type='submit'
              className='blueBackground'
              // eslint-disable-next-line react/jsx-no-bind
              onClick={this.authenticateDesktop.bind(this)}
            />
          </forms.FormBlock>
        </forms.Form>
      </MaxWidthBlock>
    )
  }
}

WifiReaderSettingsPage.propTypes = {
  queryParams: PropTypes.object,
  formData: PropTypes.objectOf(Map),
}

export default connect((state) => ({
  session: state.session,
  queryParams: state.routing.locationBeforeTransitions.query,
  formData: state.forms.getIn(['wifiReaders', 'data']),
}))(WifiReaderSettingsPage)
