/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable eqeqeq */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/*
From https://gist.github.com/remy/350433
*/

if (typeof window !== 'undefined') {
  let domain = window.location.hostname
  if (domain.indexOf('.') > -1) {
    domain = window.location.hostname.substr(domain.indexOf('.'))
  }
  let hasLocalStorage = true
  // Simply accessing localStorage can throw an excpetion
  try {
    window.yocoStorage = window.localStorage
  } catch (e) {
    window.yocoStorage = undefined
    hasLocalStorage = false
  }

  if (
    hasLocalStorage &&
    (typeof window.localStorage === 'undefined' || typeof window.sessionStorage === 'undefined')
  ) {
    hasLocalStorage = false
  } else if (hasLocalStorage) {
    try {
      localStorage.setItem('safari_private_browsing_check', 'false')
    } catch (e) {
      hasLocalStorage = false
    }
  }

  window.hasLocalStorage = hasLocalStorage

  if (!hasLocalStorage)
    (function () {
      const Storage = function (type) {
        function createCookie(name, value, days) {
          let date
          let expires

          if (days) {
            date = new Date()
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
            expires = `; expires=${date.toGMTString()}`
          } else {
            expires = ''
          }
          document.cookie = `${name}=${value}${expires}; domain=${domain}; path=/`
        }

        function readCookie(name) {
          const nameEQ = `${name}=`
          const ca = document.cookie.split(';')
          let i
          let c

          for (i = 0; i < ca.length; i++) {
            c = ca[i]
            while (c.charAt(0) == ' ') {
              c = c.substring(1, c.length)
            }

            if (c.indexOf(nameEQ) == 0) {
              return c.substring(nameEQ.length, c.length)
            }
          }
          return null
        }

        function setData(data) {
          data = JSON.stringify(data)
          if (type == 'session') {
            window.name = data
          } else {
            createCookie('localStorage', data, 365)
          }
        }

        function clearData() {
          if (type == 'session') {
            window.name = ''
          } else {
            createCookie('localStorage', '', 365)
          }
        }

        function getData() {
          const data = type == 'session' ? window.name : readCookie('localStorage')
          return data ? JSON.parse(data) : {}
        }

        // initialise if there's already data
        let data = getData()

        return {
          length: 0,
          clear() {
            data = {}
            this.length = 0
            clearData()
          },
          getItem(key) {
            return data[key] === undefined ? null : data[key]
          },
          key(i) {
            // not perfect, but works
            let ctr = 0
            for (const k in data) {
              if (ctr == i) return k
              ctr++
            }
            return null
          },
          removeItem(key) {
            delete data[key]
            this.length--
            setData(data)
          },
          setItem(key, value) {
            // forces the value to a string
            data[key] = `${value}`
            this.length++
            setData(data)
          },
        }
      }

      window.yocoStorage = new Storage('local')
    })()
}
