import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

export function Time({ time }) {
  return <div>{moment(time).format('h:mm a')}</div>
}

export function Day({ date }) {
  return <div>{moment(date).format('dddd Do [of] MMMM')}</div>
}

export function DateAndTime({ date }) {
  return <div>{moment(date).format('h:mm a dddd Do [of] MMMM')}</div>
}

export function SimpleDateTime({ date }) {
  return <div>{moment(date).format('MM/DD HH:mm')}</div>
}

export function SmallDateAndTime({ date }) {
  return <div>{moment(date).format('h:mm a Do MMM')}</div>
}

export function SimpleDate({ date }) {
  return <div>{moment(date).format('YYYY/MM/DD hh:mm:ss')}</div>
}

export function LongDate({ date }) {
  return <div>{moment(date).format('D MMMM YYYY')}</div>
}

export function FriendlyDate({ date }) {
  return <div>{moment(date).format('Do MMMM YYYY')}</div>
}

export function OnlyDate({ date }) {
  return <span>{moment(date).format('YYYY/MM/DD')}</span>
}

export function CustomDateTime({ date, format }) {
  return <span>{moment(date).format(format)}</span>
}

Time.propTypes = {
  time: PropTypes.string,
}
Day.propTypes = {
  date: PropTypes.string,
}
DateAndTime.propTypes = {
  date: PropTypes.string,
}
SimpleDateTime.propTypes = {
  date: PropTypes.string,
}
SmallDateAndTime.propTypes = {
  date: PropTypes.string,
}
SimpleDate.propTypes = {
  date: PropTypes.string,
}
LongDate.propTypes = {
  date: PropTypes.string,
}
OnlyDate.propTypes = {
  date: PropTypes.string,
}
FriendlyDate.propTypes = {
  date: PropTypes.string,
}
CustomDateTime.propTypes = {
  date: PropTypes.string,
  format: PropTypes.string,
}
