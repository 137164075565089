import { useCallback, useMemo, useState } from 'react'
import { callInvoicesJSONAPI } from 'libs/api'
import { UseFilterCustomers } from './types'

export const useFilterCustomers: UseFilterCustomers = () => {
  const [customers, setCustomers] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<undefined | string>()
  const [search, setSearch] = useState<undefined | string>()

  const path = useMemo(() => {
    return search ? `customers?search=${search}` : 'customers'
  }, [search])

  const updateCustomers = useCallback(() => {
    setError(undefined)
    setIsLoading(true)

    callInvoicesJSONAPI(path, 'GET')
      .then(({ data }) => {
        setCustomers(data || [])
      })
      .catch(() => setError('Failed to fetch customers'))
      .finally(() => setIsLoading(false))
  }, [path])

  return {
    customers,
    isLoading,
    error,
    search,
    setSearch,
    updateCustomers,
  }
}
