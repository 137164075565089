import MigratePage from './MigratePage'

export default function getRoutes() {
  return {
    path: 'newportal',
    text: 'Migrate',
    icon: 'star',
    component: MigratePage,

    // We opt out of showing this in the menu,
    // as the menu item is unconventional
    hideInMenu: true,
  }
}
