import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { FilterBatch } from 'libs/filters'
import DateFilter from 'components/filters/DateFilter'

import BlockHeader from './BlockHeader'
import classes from './dateHeader.module.scss'

function DateHeader({ name, title, children }) {
  return (
    <FilterBatch
      title={title}
      name={name}
      renderContent={(props, renderedFilters) => {
        return (
          <BlockHeader title={props.title}>
            <div className={classes.filterContainer}>{renderedFilters}</div>
            {children}
          </BlockHeader>
        )
      }}
    >
      <DateFilter
        noPosition
        defaults={[moment().startOf('day'), moment().startOf('day').add(1, 'day')]}
      />
    </FilterBatch>
  )
}

DateHeader.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.element,
}

export default DateHeader
