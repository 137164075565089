import React from 'react'
import ProgressBar from 'react-progress-bar-plus'
import * as utils from './utils'

import styles from './CapitalQualificationCriteriaBlock.module.scss'
import { QualificationType } from './types'

interface Props {
  title: string
  actual: number
  target: number
  type: QualificationType
  isMiddleBlock?: boolean
}

const CapitalQualificationCriteriaBlock: React.FunctionComponent<Props> = ({
  title,
  actual,
  target,
  isMiddleBlock,
  type,
}) => {
  const capitalFilled = Math.min((actual / target) * 99.9, 99.9)
  const capitalFilledRounded = Math.ceil(capitalFilled)
  const remainingProgress = 99.9 - capitalFilled

  return (
    <div
      className={`${styles.container} ${utils.determineBorderStyle(isMiddleBlock)}`}
      data-testid='CapitalQualificationCriteriaBlock'
    >
      <p className={styles.title}>{title}</p>
      <h1 className={utils.determineHeadingStyle(capitalFilled)}>{utils.typeMap[type](actual)}</h1>
      <h4 className={styles.target}>{`${utils.typeMap[type](target)} required`}</h4>
      <div
        className={styles.progressBarPercentage}
        style={{ marginRight: `${remainingProgress}%` }}
      >
        <span className={styles.progressBarPercentageText}>{`${capitalFilledRounded}%`}</span>
      </div>
      <ProgressBar
        percent={capitalFilled}
        className={utils.determineProgressBarStyle(capitalFilled)}
      />
    </div>
  )
}

export default CapitalQualificationCriteriaBlock
