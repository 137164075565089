/* eslint-disable react/jsx-no-bind */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Immutable, { List, Map } from 'immutable'
import PropTypes from 'prop-types'
import { Icon } from '@yoco/design-system-icons/dist/react'

import BillNotePopup from 'components/popups/BillNotePopup'
import * as misc from 'libs/formats'
import { callJSONApi } from 'libs/api'
import { itemUpdated } from 'redux/modules/tables'
import { clearForm } from 'redux/modules/forms'
import FormCard from 'components/cards/FormCard'
import colors from 'ui/colors'
import SubHeading from 'ui/components/SubHeading'

import classes from './transactions.module.scss'
import BasketItem from './BasketItem'
import { transactionStateColor, transactionStateIcon, paymentMethodIcon } from './utils'

const TABLE_NAME = 'salesHistory'

class TransactionReceipt extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showBillNoteModal: false,
      billNote: '',
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.bill) {
      this.setState({
        billNote:
          nextProps.bill.getIn(['details', 'note']) &&
          nextProps.bill.getIn(['details', 'note']).trim().length
            ? nextProps.bill.getIn(['details', 'note'])
            : '',
      })
    } else if (nextProps.transaction) {
      this.setState({
        billNote:
          nextProps.transaction.getIn(['details', 'note']) &&
          nextProps.transaction.getIn(['details', 'note']).trim().length
            ? nextProps.transaction.getIn(['details', 'note'])
            : '',
      })
    }
  }

  getItems() {
    let basketItems = []
    let hasInventoryData = false

    // Prefer billEntries from a transaction or bill by default
    let billEntries = null
    if (this.props.transaction && this.props.transaction.get('billEntries')) {
      billEntries = this.props.transaction.get('billEntries')
    }
    if (this.props.bill && this.props.bill.get('billEntries')) {
      billEntries = this.props.bill.get('billEntries')
    }

    if (billEntries) {
      basketItems = billEntries
      hasInventoryData = basketItems.some((basketItem) => {
        return basketItem.get('tile')
      })
    } else if (this.props.transaction && this.props.transaction.get('items')) {
      basketItems = this.props.transaction.get('items')
      hasInventoryData = basketItems.some((basketItem) => {
        return basketItem.get('inventoryItemData')
      })
    }

    if (basketItems.size > 0) {
      const tableClass = hasInventoryData
        ? `${classes.section} ${classes.tableContainer}`
        : `${classes.section} ${classes.tableContainer} ${classes.noInventoryData}`
      return (
        <div className={tableClass}>
          <table>
            <tbody>
              {basketItems.map((item) => (
                <BasketItem key={item.get('uuid')} item={item} />
              ))}
            </tbody>
          </table>
        </div>
      )
    }

    return undefined
  }

  onSetBillNoteClick() {
    this.setState({
      showBillNoteModal: true,
    })
  }

  onSetBillNoteCancel() {
    this.setState({
      showBillNoteModal: false,
    })
    this.props.dispatch(clearForm('billNote'))
  }

  onSetBillNoteSuccess(response) {
    this.setState({
      showBillNoteModal: false,
      billNote: response.data.note ? response.data.note : '',
    })
    const billUUID = this.props.transaction
      ? this.props.transaction.get('billUUID')
      : this.props.bill.get('uuid')
    callJSONApi(`/bills/${billUUID}`, 'GET', {}, (response) => {
      if (response.status === 200) {
        if (this.props.transaction) {
          // Foreach payment/transaction, we need to update the bill note on each row.
          response.data.bill.payments.map((transaction) => {
            callJSONApi(`/transactions/${transaction.uuid}`, 'GET', {}, (response) => {
              if (response.status === 200) {
                this.props.dispatch(
                  itemUpdated(TABLE_NAME, Immutable.fromJS(response.data.transaction))
                )
              }
            })
          })
        } else {
          this.props.dispatch(itemUpdated(TABLE_NAME, Immutable.fromJS(response.data.bill)))
        }
      }
    })
  }

  getTransactionNote() {
    const { transaction } = this.props
    const { bill } = this.props
    return (
      <div>
        <div
          className={`${classes.section} ${classes.transactionNote}`}
          onClick={() => this.onSetBillNoteClick()}
          style={this.props.toggled ? { cursor: 'pointer' } : null}
        >
          <Icon name='note' size={24} />
          <div>{this.state.billNote.length ? this.state.billNote : 'Add note'}</div>
        </div>
        <BillNotePopup
          showing={this.state.showBillNoteModal}
          onEsc={this.onSetBillNoteCancel.bind(this)}
          onSetBillNoteSuccess={this.onSetBillNoteSuccess.bind(this)}
          billUUID={transaction ? transaction.get('billUUID') : bill ? bill.get('uuid') : null}
          value={this.state.billNote}
          placeholder={!this.state.billNote.length ? 'Add note' : null}
        />
      </div>
    )
  }

  getTotals() {
    if (this.props.transaction) {
      const totalAmount = this.props.transaction.getIn(['bill', 'amounts', 'totalAmount'])
      const tipAmount = this.props.transaction.get('tipAmount')
      const vatAmount = this.props.transaction.get('vatAmount')
      const billDiscount = this.props.transaction.getIn(
        ['billDetails', 'amounts', 'discountAmount'],
        0
      )

      const tipPercentage = ((tipAmount / totalAmount) * 100).toFixed(1)
      const billDiscountPercentage = ((billDiscount / (totalAmount + billDiscount)) * 100).toFixed(
        1
      )

      let taxes = Map()
      this.props.transaction
        .get('billDetails', Map())
        .get('billEntries', List())
        .toList()
        .map((billEntry) =>
          billEntry.get('salesTaxes').map((tax) => {
            const taxAmount = tax.get('taxAmount', 0)
            const updatedTaxAmount = Math.round(taxAmount * (1 - billDiscountPercentage) || 0)

            return tax.set('taxAmount', updatedTaxAmount)
          })
        )
        .flatten(true)
        .forEach((taxEntry) => {
          const taxName = taxEntry.get('taxName')
          const taxPercentage = this.props.salesTaxes.getIn([
            taxEntry.get('salesTaxUUID'),
            'taxPercentage',
          ])
          taxes = taxes.set(
            taxName,
            taxEntry
              .set('taxAmount', taxes.getIn([taxName, 'taxAmount'], 0) + taxEntry.get('taxAmount'))
              .set('taxPercentage', taxPercentage)
          )
        })

      return (
        <div className={`${classes.section} ${classes.totals}`}>
          {tipPercentage > 0 && (
            <div className={classes.total}>
              <div style={{ flex: 1 }}>Tip</div>
              <div className={classes.percent}>{`${tipPercentage} %`}</div>
              <div className={classes.amount}>
                <misc.Amount amount={tipAmount} />
              </div>
            </div>
          )}
          {billDiscountPercentage > 0 && (
            <div className={classes.total}>
              <div style={{ flex: 1 }}>Bill Discount</div>
              <div className={classes.percent}>{`${billDiscountPercentage} %`}</div>
              <div className={classes.amount}>
                <misc.Amount amount={billDiscount} />
              </div>
            </div>
          )}
          <div className={classes.total}>
            <div style={{ flex: 1 }}>VAT</div>
            <div className={classes.percent}>
              {`${(taxes.get('VAT', Map()).get('taxPercentage', 0) * 100).toFixed(1)} %`}
            </div>
            <div className={classes.amount}>
              <misc.Amount amount={vatAmount} />
            </div>
          </div>
        </div>
      )
    }

    if (this.props.bill && this.props.bill.get('amounts')) {
      const tipAmount = this.props.bill.getIn(['amounts', 'tipAmount'], 0)
      const discountAmount = this.props.bill.getIn(['amounts', 'discountAmount'], 0)
      const taxAmount = this.props.bill.getIn(['amounts', 'taxAmount'], 0)
      const billAmount = this.props.bill.getIn(['amounts', 'totalAmount'], 0) - taxAmount

      const totalLightClass = `${classes.total} ${classes.light}`

      return (
        <div className={`${classes.section} ${classes.totals}`}>
          <div className={classes.total}>
            Bill (excluding VAT)
            <div className={classes.amount}>
              <misc.Amount amount={billAmount} />
            </div>
          </div>
          <div className={totalLightClass}>
            Tip
            <div className={classes.amount}>
              <misc.Amount amount={tipAmount} />
            </div>
          </div>
          <div className={totalLightClass}>
            Discount
            <div className={classes.amount}>
              <misc.Amount amount={discountAmount} />
            </div>
          </div>
          <div className={totalLightClass}>
            VAT
            <div className={classes.amount}>
              <misc.Amount amount={taxAmount} />
            </div>
          </div>
        </div>
      )
    }

    return undefined
  }

  render() {
    const typeDict = {
      credit_card: 'Card',
      cash: 'Cash',
      refund: 'Refund',
    }

    let transactionType = ''
    let amount = 0
    let date = ''

    const receiptNameStyle = {}
    const footerStyle = {}
    let message = ''
    let cardNumber = ''

    let billStatusIcon
    let billPaymentMethodIcon

    if (this.props.transaction) {
      transactionType = typeDict[this.props.transaction.get('transactionType')]
      amount = this.props.transaction.get('amount')
      date = this.props.transaction.get('created')

      const transactionSimpleState = this.props.transaction.get('transactionSimpleState')
      const transactionState = this.props.transaction.get('transactionState')

      footerStyle.backgroundColor = transactionStateColor(transactionState)
      billStatusIcon = transactionStateIcon(transactionState)
      billPaymentMethodIcon = paymentMethodIcon(this.props.transaction.get('paymentMethod'))

      if (transactionSimpleState) {
        message = transactionSimpleState.get('message')
      }

      if (this.props.transaction.get('payworksTransaction')) {
        // pad with stars to ensure substring doesn't get out of index
        cardNumber = `*****${this.props.transaction.getIn(['payworksTransaction', 'maskedCard'])}`
        cardNumber = `**** **** **** ${cardNumber.substring(cardNumber.length - 4)}`
      }
    } else if (this.props.bill) {
      const billIndex = this.props.bill.get('payments').size - 1

      transactionType = this.props.bill.get('isSplitBill')
        ? 'Split Bill'
        : this.props.bill.getIn(['payments', billIndex, 'transactionType'])
      transactionType = transactionType.replace('_', ' ')
      amount = this.props.bill.getIn(['amounts', 'paidAmount'])
      date = this.props.bill.get('created')

      if (this.props.bill.get('isSplitBill')) {
        /*
          Check if we have refunded payments in the split bill
          We have three cases: no refunds, partially refunded, completely refunded
          We do this by comparing the bill total with the amount paid
          */
        const payments = this.props.bill.get('payments')
        const billAmount = this.props.bill.getIn(['amounts', 'totalAmount'])
        let totalPaid = 0
        payments.forEach((payment) => {
          totalPaid += payment.get('amount')
        })

        billStatusIcon = 'payment-success'
        billPaymentMethodIcon = 'split-bill'
        footerStyle.backgroundColor = transactionStateColor('approved')
        message = 'Split bill payment complete'

        if (totalPaid < billAmount && totalPaid !== 0) {
          // We have a partial refund
          footerStyle.backgroundColor = colors['Yellow-500']
          message = 'Split bill partially paid'
          billStatusIcon = 'alert-warning'
        }

        if (totalPaid === 0) {
          // All the payments have been refunded
          footerStyle.backgroundColor = colors['Blue-300']
          message = 'No payments'
          billStatusIcon = 'payment-error'
        }
      } else {
        const transaction = this.props.bill.getIn(['payments', billIndex])
        footerStyle.backgroundColor = transactionStateColor(transaction.get('transactionState'))
        billStatusIcon = transactionStateIcon(transaction.get('transactionState'))
        billPaymentMethodIcon = paymentMethodIcon(transaction.get('paymentMethod'))

        const billSimpleState = this.props.bill.getIn([
          'payments',
          billIndex,
          'transactionSimpleState',
        ])
        if (billSimpleState) {
          message = billSimpleState.get('message')
        }
      }
    }

    const headerStyle = `${classes.section} ${classes.receiptHeader}`
    return (
      <FormCard style={{ padding: '0' }} hideHeader>
        <div className={classes.receiptPanel}>
          <div className={classes.receiptContainer}>
            <div className={headerStyle} style={receiptNameStyle}>
              <div className={classes.left}>
                <SubHeading>Receipt</SubHeading>
                <div className={classes.date}>
                  <misc.DateAndTime date={date} />
                </div>
              </div>
              <div className={classes.right}>
                <div>{transactionType}</div>
                <div className={classes.card}>{cardNumber}</div>
                <div className={classes.billName}>
                  {this.props.transaction ? this.props.transaction.getIn(['bill', 'name']) : null}
                </div>
              </div>
            </div>

            {this.getItems()}
            {this.getTransactionNote()}
            {this.getTotals()}

            <div
              className={`${classes.section} ${classes.totals} ${classes.final}`}
              style={receiptNameStyle}
            >
              <div>Total (incl. tax)</div>
              <div className={classes.amount}>
                <misc.Amount amount={amount} />
              </div>
            </div>
            <div className={`${classes.section} ${classes.footer}`} style={footerStyle}>
              <Icon name={billStatusIcon} size={24} />
              <div className={classes.footerMessage}>{message}</div>
              <Icon name={billPaymentMethodIcon} size={24} />
            </div>
          </div>
        </div>
      </FormCard>
    )
  }
}

TransactionReceipt.propTypes = {
  dispatch: PropTypes.func.isRequired,
  transaction: PropTypes.object,
  bill: PropTypes.object,
  toggled: PropTypes.bool,
  salesTaxes: PropTypes.object,
}

export default connect((state) => ({
  toggled: state.filters.getIn([TABLE_NAME, 'toggle', 'value']),
}))(TransactionReceipt)
