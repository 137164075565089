export const colors = {
  GREY: '#6B7280',
  RED: '#EF4444',
  GOLD: '#F59E0B',
  GREEN: '#10B981',
  BLUE: '#3B82F6',
  PURPLE: '#6366F1',
  VIOLET: '#8B5CF6',
  PINK: '#EC4899',
}

export const defaultColors = Object.values(colors)
