import React from 'react'
import styles from './CapitalInformationDetailsRow.module.scss'

interface Props {
  title: string
  titleDisplay: string
}

const CapitalInformationDetailsRow: React.FunctionComponent<Props> = ({ title, titleDisplay }) => {
  return (
    <div className={styles.row}>
      <p>{title}</p>
      <b>{titleDisplay}</b>
    </div>
  )
}

export default CapitalInformationDetailsRow
