import React from 'react'

export type RouterContextType = {
  currentRoute: string
  routeIndex: number
  prevRoute: string | undefined
  userInfo: { firstName: string; lastName: string }
  selectedRoles: {
    'Managing your Business': Array<string>
    'Sales and Refunds': Array<string>
  }
}

export function routerReducer(
  state: RouterContextType,
  action: { type: string; values?: any }
): RouterContextType {
  switch (action.type) {
    case 'userInfo':
      return {
        ...state,
        currentRoute: 'userPermissions',
        routeIndex: 2,
        prevRoute: 'userInfo',
        userInfo: {
          ...state.userInfo,
          firstName: action.values.firstName ?? state.userInfo.firstName,
          lastName: action.values.lastName ?? state.userInfo.lastName,
        },
      }

    case 'userPermissions':
      return { ...state, selectedRoles: action.values, prevRoute: 'userPermissions' }

    case 'clear':
      return {
        ...state,
        currentRoute: 'userInfo',
        routeIndex: 1,
        prevRoute: undefined,
        userInfo: { firstName: '', lastName: '' },
        selectedRoles: {
          'Managing your Business': [],
          'Sales and Refunds': [],
        },
      }

    case 'back':
      return { ...state, currentRoute: state.prevRoute ?? '', prevRoute: undefined }

    default:
      return state
  }
}

export const initState = {
  currentRoute: 'userInfo',
  routeIndex: 1,
  userInfo: { firstName: '', lastName: '' },
  selectedRoles: {
    'Managing your Business': [],
    'Sales and Refunds': [],
  },
}
export const RouteDispatchContext = React.createContext<{ dispatch: React.Dispatch<any> }>({
  dispatch: () => null,
})

export const RouteContext = React.createContext<RouterContextType>({
  currentRoute: 'userInfo',
  routeIndex: 1,
  prevRoute: undefined,
  userInfo: { firstName: '', lastName: '' },
  selectedRoles: {
    'Managing your Business': [],
    'Sales and Refunds': [],
  },
})
