/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import classnames from 'classnames'

import FormCard from 'components/cards/FormCard'
import Spacer from 'ui/layout/Spacer'
import FlatButton from 'components/buttons/FlatButton'
import { track } from 'libs/analytics'
import layout from 'ui/layout/layout.module.scss'
import InfoPopup from 'containers/online/InfoPopup'
import apple from 'assets/images/apple.png'
import play from 'assets/images/play.png'
import hauwei from 'assets/images/hauwei-1.png'
import { If } from 'libs/formats'

import classes from './paymentLinks.module.scss'

class ShareLink extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showSuccessful: false,
      showPopup: false,
    }
  }

  copyPaymentLinkUrl = (url) => {
    this.copyUrl(url)
    track('copy_payment_link_url', { name: 'Copy' })
  }

  showPopup() {
    this.setState({
      showPopup: true,
    })
    track('payment_link_info_clicked', { name: 'Info' })
  }

  close() {
    this.setState({
      showPopup: false,
    })
  }

  trackAppLink(os) {
    track('app_download_link_clicked', { name: os })
  }

  copyUrl(url) {
    const copyTextarea = document.querySelector('#copyUrl')
    copyTextarea.select()

    try {
      const successful = document.execCommand('copy')
      if (successful) {
        this.setState({
          showSuccessful: true,
        })
        setTimeout(() => {
          this.setState({
            showSuccessful: false,
          })
        }, 2000)
      } else {
        window.prompt(
          `To copy to the clipboard right click and select "copy" or press: Ctrl+C (Cmd + C on a mac)`,
          url
        )
      }
    } catch (err) {
      window.prompt(
        `To copy to the clipboard right click and select "copy" or press: Ctrl+C (Cmd + C on a mac)`,
        url
      )
    }
  }

  render() {
    const url = this.props.shownRow ? this.props.shownRow.get('url') : ''
    const ref = this.props.shownRow ? this.props.shownRow.get('ref') : ''

    return (
      <div>
        <InfoPopup
          showing={this.state.showPopup}
          onEsc={() => this.close()}
          email={this.props.ownerEmail}
        />
        <FormCard header='Copy the link below'>
          <div className='clearfix'>
            <div className={classes.urlBox}>
              <input id='copyUrl' value={url} style={{ position: 'absolute', top: '-1000px' }} />
              <a
                id='copyUrl'
                href={url}
                target='_blank'
                rel='noopener noreferrer'
                className={classes.linkText}
              >
                {url}
              </a>
              <br />
              <span className={classes.secure}>
                <Spacer size='small' />
                <i className='icon2-lock-2-1' /> Secure payment link
              </span>
            </div>
            <Spacer size='small' />
            <FlatButton
              className='blueBorder'
              href={url}
              label='Preview link'
              target='_blank'
              style={{ float: 'right' }}
              onClick={() => track('payment_link_preview', { name: 'preview payment link' })}
            />
            <FlatButton
              className='blueBackground'
              label='Copy link'
              onClick={() => this.copyPaymentLinkUrl(url)}
              style={{ float: 'right' }}
            />
            <If condition={this.state.showSuccessful}>
              <div className={classes.copyText}>Copied to clipboard!</div>
            </If>
          </div>
        </FormCard>
        <FormCard header={`Send the payment link to ${ref} `}>
          <div className='clearfix'>
            <p>You can use email, WhatsApp, SMS or any social media messenger.</p>
            <a onClick={() => this.showPopup()}>How will I know when my customer has paid?</a>
          </div>
        </FormCard>
        <FormCard header='Get the Yoco app'>
          <p>Send payment links from your mobile phone or tablet.</p>
          <div className={classnames(layout.flex)}>
            <a
              className={classnames(layout.flex, layout.pr2)}
              href='https://itunes.apple.com/za/app/yoco/id892816357?mt=8'
              target='_blank'
              rel='noopener noreferrer'
              onClick={() => this.trackAppLink('apple')}
            >
              <img height='35px' src={apple} alt='Apple' />
            </a>
            <a
              className={classnames(layout.flex, layout.pl2)}
              href='https://play.google.com/store/apps/details?id=za.co.yoco&hl=en'
              target='_blank'
              rel='noopener noreferrer'
              onClick={() => this.trackAppLink('google')}
            >
              <img height='35px' src={play} alt='Play' />
            </a>
            <a
              className={classnames(layout.flex, layout.pl2)}
              href='https://appgallery.huawei.com/#/app/C102766851'
              target='_blank'
              rel='noopener noreferrer'
              onClick={() => this.trackAppLink('huawei')}
            >
              <img height='35px' src={hauwei} alt='Hauwei' />
            </a>
          </div>
        </FormCard>
      </div>
    )
  }
}

export default connect((state) => ({
  ownerEmail: state.session.getIn(['user', 'email'], Map({})),
}))(ShareLink)

ShareLink.propTypes = {
  shownRow: PropTypes.object,
  ownerEmail: PropTypes.string,
}
