/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState, useRef, useMemo } from 'react'
import { init } from 'pell'
import showdown from 'showdown'

import { makeTestID } from 'libs/utils'
import store from '../../store.module.scss'

import DesignCard from './DesignCard'
import { ContentDesignProps } from './types'
import { markdownConverter } from './utils'

const ContentDesignCard: React.FunctionComponent<ContentDesignProps> = ({
  sectionConfig,
  setSectionConfig,
}) => {
  const editorRef = useRef<HTMLDivElement>(null)
  const converter = useMemo(() => {
    const converter = new showdown.Converter()
    converter.setFlavor('original')
    return converter
  }, [])

  const [content, setContent] = useState(sectionConfig.content)

  useEffect(() => {
    const editor = init({
      element: editorRef.current,
      onChange: (html) => {
        setContent(markdownConverter(html, converter))
      },
      defaultParagraphSeparator: 'p',
      actions: [
        'paragraph',
        'heading1',
        'heading2',
        'bold',
        'italic',
        {
          name: 'olist',
          // eslint-disable-next-line max-len
          icon:
            '<div style="writing-mode: vertical-lr; text-orientation: upright; font-size: 6px; font-weight: 600">123</div>',
        },
        {
          name: 'ulist',
          icon: '<i class=yocodsicon-list style="font-size:20px; height:20px" />',
        },
        {
          name: 'link',
          icon: '<i class=yocodsicon-link style="font-size:20px; height:20px" />',
        },
      ],
    })

    if (content) {
      editor.content.innerHTML = converter.makeHtml(content)
    }
    // eslint-disable-next-line
  }, [editorRef, converter])

  // Because we do not want to re-initialise this editor whenever the section config changes
  // we add a layer of indirection to setting the config using a `useEffect`
  useEffect(() => {
    if (sectionConfig.content !== content) {
      setSectionConfig({ ...sectionConfig, content })
    }
  }, [content, sectionConfig, setSectionConfig])

  return (
    <DesignCard
      testID={makeTestID('onlineStore', 'designPage', 'contentDesignCard')}
      title='Content'
    >
      <div
        ref={editorRef}
        data-testid={makeTestID('onlineStore', 'designPage', 'designCard', 'pellEditor')}
        className={store.pageEditor}
      />
    </DesignCard>
  )
}

export default ContentDesignCard
