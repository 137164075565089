import React from 'react'
import PropTypes from 'prop-types'

import { selectBankAccountUpdateRequest } from 'redux/modules/onboarding/bankDetails/selectors'
import { connect } from 'react-redux'
import Alert from 'components/notifications/Alert'
import { BANK_ACCOUNT_FAILURE_REASONS } from 'redux/modules/onboarding/bankDetails'
import FlatButton from 'components/buttons/FlatButton'
import { selectBusinessName, selectIsRegisteredBusiness } from 'redux/modules/session/selectors'

function ChangeRequiredAlert({
  bankAccountUpdateRequest,
  isRegisteredBusiness,
  businessName,
  onEditButtonClick,
  isFormEnabled,
}) {
  const getSpecificReason = () => {
    switch (bankAccountUpdateRequest.reason) {
      case BANK_ACCOUNT_FAILURE_REASONS.ACCOUNT_INVALID:
        return (
          <>
            <b>The account number or bank you provided is not valid.</b>
            <br />
            <br />
            Please make sure you enter the correct bank account number and bank name.
          </>
        )
      case BANK_ACCOUNT_FAILURE_REASONS.ACCOUNT_NOT_OPEN:
        return (
          <>
            <b>The account you provided is not active.</b>
            <br />
            <br />
            Please provide an active bank account.
          </>
        )
      case BANK_ACCOUNT_FAILURE_REASONS.NON_TRANSACTIONAL_ACCOUNT:
        return (
          <>
            <b>The account you provided does not accept debits/credits.</b>
            <br />
            <br />
            Please provide an account which accepts debits/credits.
          </>
        )
      case BANK_ACCOUNT_FAILURE_REASONS.CHURCH:
      case BANK_ACCOUNT_FAILURE_REASONS.CLUB:
      case BANK_ACCOUNT_FAILURE_REASONS.NPO:
      case BANK_ACCOUNT_FAILURE_REASONS.SCHOOL:
        return (
          <>
            <b>The account you provided is registered to an NPO.</b>
            <br />
            <br />
            Please upload a bank statement or account confirmation letter.
          </>
        )
      case BANK_ACCOUNT_FAILURE_REASONS.TRUST:
        return (
          <>
            <b>The account you provided is registered to a trust.</b>
            <br />
            <br />
            Please upload a bank statement or account confirmation letter.
          </>
        )
      case BANK_ACCOUNT_FAILURE_REASONS.DOCUMENT_DOES_NOT_MATCH_BANK_ACCOUNT:
      case BANK_ACCOUNT_FAILURE_REASONS.NOT_A_BANK_ACCOUNT_DOCUMENT:
        return (
          <>
            <b>
              The document you uploaded does not match that of the provided bank account details.
            </b>
            <br />
            <br />
            Please ensure that the uploaded document matches the provided bank account details.
          </>
        )
      case BANK_ACCOUNT_FAILURE_REASONS.MISSING_REQUIRED_DOCUMENTS:
        return (
          <>
            <b>We need to verify that you have the right to use the provided bank account.</b>
            <br />
            <br />
            Please upload a bank statement or account confirmation letter.
          </>
        )
      default:
        if (isRegisteredBusiness) {
          return (
            <>
              <b>
                The account you provided does not match the business registration number for{' '}
                {businessName}.
              </b>
              <br />
              <br />
              Please provide an account in the name of your business.
            </>
          )
        }

        return (
          <>
            <b>The account you provided does not match your ID/passport number.</b>
            <br />
            <br />
            Please provide an account in your own name.
          </>
        )
    }
  }

  return (
    <Alert messageType='danger' closable={false}>
      We couldn&apos;t verify your bank details.
      <br />
      <br />
      {getSpecificReason()}
      {!isFormEnabled && (
        <>
          <br />
          <br />
          <FlatButton
            label='Try again'
            type='reset'
            className='secondary'
            onClick={() => {
              onEditButtonClick()
            }}
          />
        </>
      )}
      <br />
      <br />
      Until this is resolved, you will receive your funds in the account below.
    </Alert>
  )
}

ChangeRequiredAlert.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  bankAccountUpdateRequest: PropTypes.object.isRequired,
  isRegisteredBusiness: PropTypes.bool.isRequired,
  businessName: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  onEditButtonClick: PropTypes.func,
  isFormEnabled: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  bankAccountUpdateRequest: selectBankAccountUpdateRequest(state).toJS(),
  isRegisteredBusiness: selectIsRegisteredBusiness(state),
  businessName: selectBusinessName(state),
})

export default connect(mapStateToProps)(ChangeRequiredAlert)
