import React from 'react'
import PropTypes from 'prop-types'

import errorImage from 'assets/images/error.svg'

import PopupResult from './PopupResult'

function PopupFailureView({ title, subtitle, buttonLabel, onDismiss }) {
  return (
    <PopupResult
      image={errorImage}
      buttonType='reset'
      title={title}
      subtitle={subtitle}
      buttonLabel={buttonLabel}
      onDismiss={onDismiss}
    />
  )
}

PopupFailureView.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  buttonLabel: PropTypes.string,
  onDismiss: PropTypes.func,
}

export default PopupFailureView
