/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unused-class-component-methods */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'

import Spinner from 'components/loaders/Spinner'

import InsightSymbol from './InsightSymbol'
import InsightAmountText from './InsightAmountText'
import classes from './insights.module.scss'

export default class Insight extends Component {
  getHeaderText() {
    if (this.props.insightResult) {
      return this.props.insightResult.insight.title
    }
    return this.props.title
  }

  getComparisonDatesDiv(startDate, endDate, period) {
    let formatString = 'Do MMM YYYY'

    // eslint-disable-next-line default-case
    switch (period) {
      case 'day': {
        return (
          <div className={classes.dates}>
            <b>{moment(startDate).format('ddd Do MMM')}</b>
          </div>
        )
      }
      case 'week': {
        formatString = 'ddd Do MMM'
        break
      }
      case 'month': {
        formatString = 'Do MMM YYYY'
        break
      }
    }

    return (
      <div className={classes.dates}>
        <b>{moment(startDate).format(formatString)}</b>
        <br />
        until
        <br />
        <b>{moment(endDate).format(formatString)}</b>
      </div>
    )
  }

  getFooterText() {
    if (this.props.insightResult) {
      return this.props.insightResult.insight.footer
    }
    return 'loading...'
  }

  getBlockStyle() {
    if (this.props.insightResult) {
      if (this.props.insightResult.insight.isPositive) {
        return { background: '#42af5f' }
      }
      if (this.props.insightResult.insight.value > 0) {
        // negative
        return { background: '#fe465c' }
      }
    }

    return { background: '#03b0ea' }
  }

  getBlockSentiment() {
    if (this.props.insightResult) {
      if (this.props.insightResult.insight.isPositive) {
        return classes.insightPositive
      }
      if (this.props.insightResult.insight.value > 0) {
        return classes.insightNegative
      }
    }

    return classes.insightNeutral
  }

  getContent() {
    if (this.props.insightResult) {
      return [
        <div className={classes.insightValues} key='main'>
          <InsightSymbol insightResult={this.props.insightResult} />
          <span className={classes.valueText}>
            <InsightAmountText insightResult={this.props.insightResult} />
          </span>
        </div>,
        <div className={classes.insightFooter} key='footer'>
          {this.getFooterText()}
        </div>,
      ]
    }
    return (
      <div className={classes.loadingView}>
        <div className={classes.spacer} />
        <Spinner spinnerName='pulse' noFadeIn />
        <div className={classes.spacer} />
      </div>
    )
  }

  showInfo() {
    this.props.showCalculation(this.props.insightResult)
  }

  render() {
    return (
      <div className={classNames([classes.insight, this.getBlockSentiment()])}>
        <div className={classes.insightHeader}>
          {this.getHeaderText()}
          <i
            className='icon-information'
            onClick={this.showInfo.bind(this)}
            onMouseDown={this.props.mouseDownEvent}
            onMouseUp={this.props.mouseUpEvent}
          />
        </div>
        {this.getContent()}
      </div>
    )
  }
}

Insight.propTypes = {
  insightResult: PropTypes.object,
  title: PropTypes.string,
  showCalculation: PropTypes.func,
  mouseDownEvent: PropTypes.func,
  mouseUpEvent: PropTypes.func,
}
