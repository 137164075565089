/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import PropTypes from 'prop-types'

import FlatButton from 'components/buttons/FlatButton'
import ItemImageUpload from 'components/items/ItemImageUpload'

import classes from './items.module.scss'

class UnconnectedItemPopover extends Component {
  handleClick(color) {
    this.props.iconChanged({
      backgroundColor: color.substr(1),
      backgroundColour: color.substr(1),
    })
  }

  imageTextChanged(event) {
    this.props.iconChanged({
      imageText: event.target.value,
    })
  }

  removeClicked() {
    this.props.iconChanged({
      imageFileUUID: null,
      imageURL: null,
      removeImage: true,
    })
  }
  renderDesignSelectors() {
    return (
      <div>
        <label>Label: </label>
        <input
          className={`form-control ${classes.nameInput}`}
          maxLength='2'
          value={this.props.itemData.get('imageText')}
          onKeyUp={this.imageTextChanged.bind(this)}
          onChange={this.imageTextChanged.bind(this)}
        />
        <div>
          <h3 className={classes.popoverTitle}>Upload</h3>
          <ItemImageUpload
            // eslint-disable-next-line react/no-string-refs
            ref='imageUpload'
            completeCallback={(data) => {
              this.props.handleFileChanged(data)
            }}
          />
        </div>
      </div>
    )
  }

  render() {
    const tileColorTitle = 'Select a colour and label for your item'
    const { colorPicker } = classes
    const { colors } = this.props

    const actions = (
      <div className={`${classes.actions}`}>
        <FlatButton
          label='Done'
          style={{ margin: '6px' }}
          className='blueBackground'
          type='button'
          onClick={() => {
            this.props.hideItemPopover()
          }}
        />
      </div>
    )

    if (this.props.itemData.get('imageFileUUID')) {
      return (
        <div className={classes.editItemIcon}>
          <p>If you remove the image you can set a colour and label, or select a new image</p>
          <FlatButton
            label='Remove'
            type='button'
            className='destructive'
            style={{ margin: '6px' }}
            onClick={this.removeClicked.bind(this)}
          />
          {actions}
        </div>
      )
    }

    return (
      <div className={classes.editItemIcon}>
        <p style={{ fontSize: 'small' }}>{tileColorTitle}</p>
        <div className={colorPicker}>
          {colors.map((color, index) => {
            const className = classes.choice
            return (
              <a
                key={index}
                onClick={this.handleClick.bind(this, color)}
                className={className}
                style={{ backgroundColor: color }}
              />
            )
          })}
        </div>
        {this.renderDesignSelectors()}
        {actions}
      </div>
    )
  }
}

const ItemPopover = connect((state, props) => ({
  reduxItem: state.forms.getIn([props.formName, 'data'], Map({})),
}))(UnconnectedItemPopover)

export default ItemPopover

UnconnectedItemPopover.propTypes = {
  itemData: PropTypes.object,
  colors: PropTypes.arrayOf(PropTypes.string),
  iconChanged: PropTypes.func,
  hideItemPopover: PropTypes.func,
  handleFileChanged: PropTypes.func,
}

UnconnectedItemPopover.defaultProps = {
  colors: [
    '#0694DC',
    '#1ABC9C',
    '#2ECC71',
    '#9B59B6',
    '#34495E',
    '#F1C40F',
    '#E67E22',
    '#E74C3C',
    '#BDC3C7',
    '#95A5A6',
  ],
  itemData: Map({}),
  dispatch: PropTypes.func,
}
