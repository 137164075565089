import React from 'react'
import PropTypes from 'prop-types'

import classes from 'components/keyBlock/keyBlock.module.scss'

function KeyBlock({ id, children }) {
  return (
    <span id={`copy-${id}`} className={classes.keyBlock}>
      {children}
    </span>
  )
}

KeyBlock.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
}

export default KeyBlock
