import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { track } from 'libs/analytics'
import { callInvoicesJSONAPI } from 'libs/api'
import { PopupComponent } from 'libs/modals'
import popupClasses from 'libs/modals/popups.module.scss'
import layout from 'ui/layout/layout.module.scss'
import FlatButton from 'components/buttons/FlatButton'
import DropDownButton from 'components/buttons/DropDownButton'
import errorImage from 'assets/images/error.svg'
import { If } from 'libs/formats'

const buttonProps = {
  containerStyle: { width: '200px' },
  buttonClass: classNames(['tertiary', layout.widthFull]),
  name: 'invoices',
  tooltip: 'Has your invoice been paid in Cash? Mark it as paid here.',
}

class MarkAsPaidErrorPopup extends PopupComponent {
  getContent() {
    return (
      <>
        <div className={popupClasses.popupImageContainer}>
          <img src={errorImage} alt='error' style={{ maxWidth: 48 }} />
        </div>
        <div className={popupClasses.popupTitle} style={{ fontSize: 20, margin: 0 }}>
          Error
        </div>
        <div className={popupClasses.popupSubtitle} style={{ fontSize: 16 }}>
          {this.props.message}
        </div>
        <div className={popupClasses.actions}>
          <FlatButton label='Close' onClick={() => this.props.onEsc?.()} />
        </div>
      </>
    )
  }
}

function MarkAsPaidDropDown({ invoice, onMarkedAsPaid }) {
  const [isSaving, setIsSaving] = useState(false)
  const [error, setError] = useState()

  const handleMarkAsPaid = useCallback(
    (paymentMethod) => {
      setIsSaving(true)
      setError(undefined)

      callInvoicesJSONAPI(`invoices/${invoice.get('id')}/pay`, 'POST', { paymentMethod })
        .then(() => {
          setIsSaving(false)

          onMarkedAsPaid?.(
            invoice.merge(invoice, {
              paymentMethod,
              status: 'paid',
            })
          )
        })
        .catch(() => {
          setIsSaving(false)

          const invoiceNumber = invoice.get('invoiceNumbexr')
          setError(`Failed to mark Invoice${invoiceNumber ? ` #${invoiceNumber}` : ''} as paid!`)
        })
    },
    [invoice, onMarkedAsPaid]
  )

  return (
    <>
      <If
        condition={isSaving}
        fallback={
          <DropDownButton
            label='Mark As Paid'
            buttonClass='secondary'
            onClick={() => {
              track('invoices_details_mark_as_paid_button_clicked', {
                name: 'invoices_details_mark_as_paid_button_clicked',
              })
            }}
            actions={[
              {
                label: 'Cash',
                onClick: () => {
                  track('invoices_details_mark_as_cash_clicked', {
                    name: 'invoices_details_mark_as_cash_clicked',
                  })
                  handleMarkAsPaid('Cash')
                },
                buttonProps,
              },
              {
                label: 'Manual EFT',
                onClick: () => {
                  track('invoices_details_mark_as_manualeft_clicked', {
                    name: 'invoices_details_mark_as_manualeft_clicked',
                  })
                  handleMarkAsPaid('ManualEFT')
                },
                buttonProps,
              },
            ]}
          />
        }
      >
        <FlatButton label='Processing ...' className='secondary' disabled />
      </If>
      <MarkAsPaidErrorPopup showing={!!error} message={error} onEsc={() => setError(undefined)} />
    </>
  )
}

MarkAsPaidDropDown.propTypes = {
  invoice: PropTypes.object.isRequired,
  onMarkedAsPaid: PropTypes.func,
}

export default MarkAsPaidDropDown
