/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-key */
import React, { useMemo } from 'react'
import { useTable, useGlobalFilter, useFilters } from 'react-table'
import moment from 'moment'
import classNames from 'classnames'

import { formatCurrency, toTitleCase, isPhoneWidth } from 'libs/utils'

import useReferralStore from '../store'

import StatusFilter from './StatusFilter'
import StatusChip from './StatusChip'
import GlobalFilter from './GlobalFilter'
import classes from './referrals.module.scss'

const useStore = () => ({
  referrals: useReferralStore((state) => state?.referrals),
})

function CommissionsTable() {
  const { referrals } = useStore()

  const data = useMemo(() => {
    return referrals.map((referral) => {
      const tempCommission =
        referral.status !== 'declined' && referral.status !== 'pending'
          ? referral.rewardAmount && referral.rewardAmount !== 0
            ? formatCurrency(referral.rewardAmount, referral.rewardCurrency, false, 0)
            : '-'
          : '-'

      return {
        ...referral,
        business: {
          ...referral.business,
          owner: {
            fullName: `${referral.business.ownerFirstName} ${referral.business.ownerLastName}`,
            email: referral.business.email,
          },
          createdAt: moment(referral.business.createdAt).format('DD MMM YY'),
        },
        rewardAmount: `${tempCommission}${
          tempCommission !== '-' && referral.awardedAmount === 0 ? ' Pending' : ''
        }`,
        awardedAt: `${referral.awardedAt ? moment(referral.awardedAt).format('DD MMM YY') : '-'}`,
        status: toTitleCase(referral.status),
      }
    })
  }, [referrals])

  const columns = useMemo(() => {
    const desktopColumns = [
      {
        Header: 'Merchant',
        accessor: 'business.owner.fullName',
      },
      {
        Header: 'Email address',
        accessor: 'business.owner.email',
      },
      {
        Header: 'Date signed up',
        accessor: 'business.createdAt',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: StatusFilter,
        filter: 'includes',
      },
      {
        Header: 'Commission',
        accessor: 'rewardAmount',
      },
      {
        Header: 'Date paid',
        accessor: 'awardedAt',
      },
    ]

    const mobileColumns = [
      {
        Header: 'Merchant',
        accessor: 'business.owner.fullName',
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: StatusFilter,
        filter: 'includes',
      },
    ]

    return isPhoneWidth() ? mobileColumns : desktopColumns
  }, [])

  const getRowClassName = (header) => {
    if (header.includes(' ')) {
      return `${header.substring(0, header.indexOf(' ')).toLowerCase()}TableCell`
    }
    return `${header.toLowerCase()}TableCell`
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter
  )

  return (
    <>
      <div className={classes.tableSearchContainer}>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </div>
      <div className={classNames(classes.tableWrapper, 'card-shadow')}>
        <table {...getTableProps()} className={classes.table}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} className={classes.tableHeader}>
                    {column.Header === 'Status' && column.canFilter ? (
                      <div className={classes.statusTableHeader}>
                        {column.render('Header')}
                        {column.render('Filter')}
                      </div>
                    ) : (
                      column.render('Header')
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows && rows.length !== 0 ? (
              rows.map((row) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()} className={classes.tableRow}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={classNames(
                            classes.tableCell,
                            classes[`${getRowClassName(cell.column.Header)}`],
                            cell.value.includes('Pending') || cell.value === '-'
                              ? classes.commissionPendingTableCell
                              : null
                          )}
                        >
                          {cell.column.Header === 'Status' ? (
                            <StatusChip
                              value={cell.value}
                              hasNotifyIndicator={
                                cell.value === 'Pending' || cell.value === 'Earned'
                              }
                            />
                          ) : (
                            cell.render('Cell')
                          )}
                        </td>
                      )
                    })}
                  </tr>
                )
              })
            ) : referrals !== undefined ? (
              <tr className={classes.tableRow}>
                <td
                  colSpan={headerGroups?.[0]?.headers.length}
                  className={classes.tableIsEmptyMessage}
                >
                  There are no referred merchants that match your search.
                </td>
              </tr>
            ) : (
              <tr className={classes.tableRow}>
                <td
                  colSpan={headerGroups?.[0]?.headers.length}
                  className={classes.tableIsEmptyMessage}
                >
                  You have not referred any merchants yet.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default CommissionsTable
